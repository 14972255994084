import React from "react";
import PropTypes from "prop-types";

import { PageHeading } from "../../styled/layout";

const Subheading = ({ children, icon }) => (
  <PageHeading flex={1} width="100%">
    {icon && <i className="material-icons">{icon}</i>}
    <h1>{children}</h1>
  </PageHeading>
);

Subheading.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.string,
};
Subheading.defaultProps = {
  icon: undefined,
};

export default Subheading;
