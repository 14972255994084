import React, { Component } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import * as Yup from "yup";
import { ModalContent, ExtendClientLicence } from "../../../styled/modal";
import { Button } from "../../../styled/buttons";
import { Icon, Layout, P } from "../../../styled";
import { ModalFooterOuter } from "../../../styled/createnewuser";
import { LoadingDIv } from "../../../styled";

class ExtendNewClientLicence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reference_text: null,
      client_licence_id: null,
      client_id: null,
      user_id: null,
    };
  }

  fetchClientLicence = async () => {
    await this.props.store.clientLicenceGet.fetch({ clientId: this.props.clientId });
    this.setState({
      reference_text: this.props.store.clientLicenceGet.data.reference_text,
      client_licence_id: this.props.store.clientLicenceGet.data.client_licence_id,
      client_id: this.props.store.clientLicenceGet.data.client_id,
      user_id: this.props.store.clientLicenceGet.data.user_id,
    });
  };
  componentDidMount() {
    this.fetchClientLicence();
  }
  handleSubmit = async () => {
    const { reference_text, client_licence_id, client_id, user_id } = this.state;
    await this.props.store.clientExtendLicence.fetch({
      reference_text,
      client_licence_id,
      client_id,
      user_id,
    });
    this.props.store.clients.fetch();
    this.props.store.clientsList.fetch();
    this.props.store.modal.remove();
  };

  render() {
    return (
      <>
        <Layout alignCenter justifyCenter column displayFlex fullWidth spacing={40}>
          <P>
            Die Lizenz wird reaktiviert. Ist die Lizenzperiode noch gültig, wird nur wieder aktiviert. Ist die Lizenz abgelaufen, wird
            wieder ein neues Abonnement mit Laufzeit eines Jahres erstellt.
          </P>
          <P textAlign={"center"}> Referenztext der Lizenz: {this.state.reference_text} </P>
          {this.props.store.clientExtendLicence.loading && <LoadingDIv>&nbsp;</LoadingDIv>}
        </Layout>
        <Layout fullWidth alignCenter justifyCenter>
          <ModalFooterOuter>
            <Layout displayFlex justifyCenter rowToColumn={500}>
              <Button onClick={() => this.props.store.modal.remove()} color="grey">
                Abbrechen
              </Button>
              <Button disabled={this.props.store.clientExtendLicence.loading} onClick={this.handleSubmit} color="red">
                Ja
              </Button>
            </Layout>
          </ModalFooterOuter>
        </Layout>
      </>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(ExtendNewClientLicence);
