import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";

import { P, Layout, Icon } from "../styled";
import UserProfileMandantenNumber from "../components/UserProfileCard/UserProfileMandatenNumber";
import ProgressSlider from "../components/ProgressSlider";
import NewItemSearch from "../components/NewItemSearch";
// import AssetList from "../components/AssetList";
import AssetEditList from "../components/AssetList/AssetsEditList";
import { LineBreak } from "../styled/sidebar";
import Userphoto from "../assets/images/empty.png";
import config from "../lib/config";
import { PageWrapper } from "../styled/layout";
import { PageTitleContainer } from "../styled/layout";
import { PageTitle } from "../styled/layout";

class AssetsEdit extends Component {
  componentDidMount() {
    const {
      store: { client, clientStore },
      // client
    } = this.props;

    client.fetch({
      clientId: clientStore.selectedClientId,
    });
  }
  render() {
    const {
      store: { client, assetEdit },
    } = this.props;

    if (!assetEdit.REVISION_ID) {
      return null;
    }
    let possible = 0;
    let completed = 0;
    if (assetEdit.data) {
      assetEdit.data.assets.forEach(asset => {
        if (!!asset.beneficiary && asset.status !== "open") {
          completed++;
        }
      });
      possible = assetEdit.data.assets.length;
    }

    const imageSource =
      client.data.profile_image_url === "uploads/empty.png" ? Userphoto : `${config.endpoints.api}/${client.data.profile_image_url}`;
    const titleDate = assetEdit.data ? moment(assetEdit.data.lastUpdate).format("YYYYMMDD") : "";
    const titleFullDate = assetEdit.data ? moment(assetEdit.data.lastUpdate).format("DD.MM.YYYY") : "";
    return (
      <PageWrapper>
        <PageTitleContainer>
          <Layout displayFlex column alignCenter justifyCenter>
            <PageTitle>{`Vermögensverzeichnis-${titleDate}`}</PageTitle>
            <P type="type-4">(Eingefrorenes Vermögensverzeichnis Stand {titleFullDate})</P>
          </Layout>
        </PageTitleContainer>
        <React.Fragment>
          <Layout overflowHidden>
            <Layout
              boxShadow="type_1"
              displayFlex
              row
              alignCenter
              ustifyStart
              spacingLeft={55}
              spacingTop={40}
              spacingBottom={60}
              zIndex={100}
              relative
              background="white"
              mediaSidePadding={600}
            >
              {client.data && (
                <UserProfileMandantenNumber
                  firstName={client.data.first_name}
                  imageSource={imageSource}
                  title={client.data.title || ""}
                  lastName={client.data.last_name}
                  mandantenNumber={client.data.client_number}
                  birthday={client.data.birth_date}
                  showDate={assetEdit.data}
                  updatedAt={assetEdit.data ? assetEdit.data.lastUpdate : null}
                />
              )}
            </Layout>
            <ProgressSlider possible={possible} completed={completed} />
            {client.loaded && <AssetEditList client={client.data} />}
            {(!assetEdit.data || assetEdit.data.assets.length === 0) && (
              <React.Fragment>
                <Layout spacingTop={60} spacingBottom={24} displayFlex alignCenter justifyCenter>
                  <P fontWeight="bold">Vermögensgegenstände</P>
                </Layout>
                <LineBreak />
              </React.Fragment>
            )}
          </Layout>
        </React.Fragment>
      </PageWrapper>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(AssetsEdit);
