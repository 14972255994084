import styled from "styled-components";
import { COLORS, BORDER_RADIUS, FONTWEIGHT, BOX_SHADOW } from "./constants";
import SearchIcon from "../assets/images/search.svg";

export const MandantOuter = styled.div`
  background: #fff;
  box-shadow: ${BOX_SHADOW.BS_1};
  border: 1px solid ${COLORS.LIGHT_GREY_TWO};
  border-radius: 4px;
`;
export const PageTitle = styled.div`
  text-align: center;
  padding: 38px 10px 20px 10px;
  border-bottom: 1px solid rgb(220, 225, 214);
  font-size: 29px;
  line-height: 29px;
  color: rgb(62, 75, 46);
  font-weight: bold;
`;
export const UserFilterOptions = styled.div`
  width: 100%;
  padding: 47px 39px;
  @media screen and (max-width: 1300px) {
    padding: 30px 20px;
  }
  @media screen and (max-width: 1200px) {
    > div {
      flex-flow: column;
      align-items: flex-start;
    }
  }
`;
export const FilterRight = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 1200px) {
    text-align: left;
    width: 100%;
  }
  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
  input {
    border: 2px solid ${COLORS.WHITE};
    max-width: 67%;
    border-radius: ${BORDER_RADIUS.BR_1};
    height: 59px;
    padding: 12px 16px 12px 67px;
    color: ${COLORS.GREEN_GREY};
    font-size: 0.9rem;
    margin-right: 20px;
    background: url(${SearchIcon}) no-repeat 4% 50%;
    background-size: 19px 19px;
    box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.05);
    width: 100%;
    @media only screen and (max-width: 1500px) {
      max-width: 67%;
    }
    @media only screen and (max-width: 1210px) {
      max-width: 60%;
      background-position: 3% 50%;
    }
    @media only screen and (max-width: 950px) {
      max-width: 51%;
      padding-left: 35px;
    }
    @media only screen and (max-width: 700px) {
      max-width: 100%;
      padding-left: 35px;
      margin-bottom: 20px;
    }
  }
  button {
    background: ${COLORS.TURTLE_GREEN_TWO};
    padding: 7px 30px;
    text-align: center;
    color: #fff;
    font-size: 0.9rem;
    font-weight: ${FONTWEIGHT.FW_6};
    height: 59px;
    line-height: 3px;
    border-radius: ${BORDER_RADIUS.BR_1};
    cursor: pointer;
    border: 1px solid ${COLORS.TURTLE_GREEN_TWO};
    box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
    @media only screen and (max-width: 950px) {
      padding: 7px 10px;
    }
    i {
      font-size: 19px;
      margin-left: 8px;
      line-height: 0;
      position: relative;
      top: 4px;
    }
  }
  ${props =>
    props.searchFilter
      ? `
      display: flex;
  flex-direction: row!important;
  input {
    min-width: auto;
    width: auto;
    flex: 1;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column!important;
    input {
      width: 100%;
      max-width: 100%;
      margin-bottom: 10px;
    }
    button { 
      max-height: auto;
      height: 60px;
      line-height: unset;
    }
  }
  `
      : ``}
`;

export const MandantDataOuter = styled.div`
  margin: 15px 0 30px 0;
  width: 100%;
  > div {
    > div {
      border-top: none;
      box-shadow: 0px 1px 18px 0 rgba(70, 84, 84, 0.2);
      & + div {
        box-shadow: 0px 1px 18px 0 rgba(70, 84, 84, 0.2);
        div div {
          border-color: #e6e6e6;
        }
      }
    }
  }
  ${props =>
    props.scrollableX
      ? `
  overflow-x: scroll;
  z-index: 0;
//   -ms-overflow-style: none;  // IE 10+
//   scrollbar-width: none;  // Firefox
//   ::-webkit-scrollbar { 
//     display: none;  // Safari and Chrome
// }
  `
      : ``}
`;
