import AssetsAktuell from "./AssetsAktuell";
import AssetsEdit from "./AssetsEdit";
import AssetsRevisions from "./AssetsRevisions";
import AssetsVerteiltes from "./AssetsVerteiltes";
import BalanceSheet from "./BalanceSheet";
import { compose } from "recompose";
import withTabsWrapper from "../hoc/Tabs";

import AssetFooter from "../components/AssetList/AssetFooter";
import AssetEditFooter from "../components/AssetList/AssetEditFooter";
import RevisionFooter from "../components/AssetList/RevisionFooter";

import { store } from "../App";

export default compose(
  withTabsWrapper([
    {
      key: "tab_1",
      pageTitle: "Vermögensverzeichnis",
      title: "Aktuell",
      component: AssetsAktuell,
      footer: AssetFooter,
    },
    {
      key: "tab_2",
      pageTitle: "Vorgängerversionen",
      title: "Vorgängerversionen",
      component: AssetsRevisions,
      footer: RevisionFooter,
    },
    {
      key: "tab_3",
      pageTitle: "Verteiltes Vermögen",
      title: "Verteiltes Vermögen",
      component: AssetsVerteiltes,
    },
    {
      key: "tab_4",
      pageTitle: "Vermögensübersicht",
      title: "Vermögensübersicht",
      component: BalanceSheet,
    },
    {
      key: "edit_assets",
      pageTitle: "Vermögensübersicht",
      title: "Version ergänzen",
      component: AssetsEdit,
      hideWrapper: true,
      footer: AssetEditFooter,
    },
  ]),
)(() => null);
