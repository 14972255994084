import ActionModalLicenceWarning from "../../components/Modal/ActionModalLicenceWarning";
import ChangeAdminRoleWarning from "../../components/Modal/ActionModalLicenceWarning/changeAdminRole";

export const userAdministrationOrderAdapter = {
  first_name: {
    key: "row_1",
    order: 0,
    scale: 1,
    title: "Vorname",
  },
  last_name: {
    key: "row_2",
    order: 1,
    scale: 1,
    title: "Nachname",
  },
  email: {
    key: "row_3",
    order: 2,
    scale: 1,
    title: "E-Mail",
  },
  licence_type: {
    key: "row_4",
    order: 3,
    scale: 1,
    title: "Lizenz",
  },
  client_access: {
    key: "row_5",
    order: 4,
    scale: 1.5,
    title: "Mandanten Zugriff",
  },
};

export const userAdministrationHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

const updateUserRole = async (event, store, index) => {
  const { userAdministration, updateAdminRole, licenceCount, modal } = store;
  const user = userAdministration.data[index];

  const data = {
    licence_type: event === "User" ? "user" : event === "Administrator" ? "admin" : "Deaktiviert",
  };

  let responseMessage = await updateAdminRole.fetch({
    user_id: user.id,
    ...data,
  });

  if (responseMessage.data.meta === "You do not have enough licence!") {
    modal.add({
      title: "",
      component: ActionModalLicenceWarning,
    });
    userAdministration.fetch();
  } else if (responseMessage.data.meta === "You do not have permissions!") {
    modal.add({
      title: "",
      component: ChangeAdminRoleWarning,
    });
  } else {
    await userAdministration.fetch();
    licenceCount.fetch();
  }
};

const updateClientAccess = async (event, store, index, onChange) => {
  await onChange(event, store, index);
};

/*****modal*****/

export const userAdministrationRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          case "licence_type":
            if (item["current_user"]) {
              row[orderItem.order] = {
                key: `foo_${itemIndex}`,
                type: "text",
                data: item[itemKey] === "admin" ? "Administrator" : "User",
              };
            } else {
              item[itemKey] = item[itemKey] === "admin" ? "Administrator" : item[itemKey] === "user" ? "User" : "Deaktiviert";
              row[orderItem.order] = {
                key: `foo_${itemIndex}`,
                type: "select",
                options: ["Administrator", "User", "Deaktiviert"],
                value: item[itemKey],
                action: (store, index) => e => updateUserRole(e, store, index),
                noAdapter: true,
              };
            }

            break;
          case "client_access":
            item[itemKey] =
                item[itemKey] === "all"
                  ? "Alle Kunden"
                  : item[itemKey] === "individual"
                    ? "Individueller Mandantenzugriff"
                    : "Eigene Kunden";
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "select",
              options: ["Alle Kunden", "Individueller Mandantenzugriff", "Eigene Kunden"],
              value: item[itemKey],
              action: (store, index, onChange) => e => updateClientAccess(e, store, index, onChange),
              noAdapter: true,
            };
            break;
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          }
        }
      });
      rows[index] = row;
    });
  }
  return rows;
};
