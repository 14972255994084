const question_categories = [
    "Unterbringung",
    "Pflege / Gesundheit / persönliche Versorgung",
    "Kontakte / Personen",
    "Vermögen / Geld / Kontoführung",
    "Liquidität / Einkünfte / Bezüge / Renten",
    "Steuern und Abgaben",
    "Versicherungen / Verträge / Sonstige Rechte",
    "Haushalt / Fahrzeuge / Freizeit / Hobby",
    "Immobilien",
    "Unternehmen / Beteiligungen",
    "Zusatzfragen"
]

export default question_categories;