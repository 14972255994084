import styled from "styled-components";
import { COLORS, FONTWEIGHT } from "./constants";

export const DeleteClientOuter = styled.div`
  padding: 25px 55px 0 55px;
  h5 {
    text-decoration: underline;
    font-size: 1.12rem;
    font-weight: ${FONTWEIGHT.FW_6};
    color: ${COLORS.PINE};
    margin-right: 27px;
    @media only screen and (max-width: 600px) {
      margin-bottom: 15px;
    }
  }
  @media only screen and (max-width: 1100px) {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 0;
  }
`;

export const DeleteClientInformation = styled.p`
width: 443px;
  height: 64px;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.41;
  text-align: center;
  color: var(--pine);
  ${props => props.paddingTop && `padding-top: ${props.paddingTop}`}
}
`;

export const DeleteClientConfirmMessage = styled.p`
  font-size: 17px;
  font-weight: bold;
  line-height: 1.59;
  color: #cd443d;
  padding-top: 17px;
`;

export const CheckBoxLicence = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 20px;
  color: white;
  font-weight: 600;
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor}`}
`;
