import styled from "styled-components";
import { COLORS, FONTWEIGHT } from "./constants";

export const CreateNewReportOuter = styled.div`
  width: 980px;
  padding: 25px 55px 0 55px;
  h5 {
    text-decoration: underline;
    font-size: 1.12rem;
    font-weight: ${FONTWEIGHT.FW_6};
    color: ${COLORS.PINE};
    margin-right: 27px;
    @media only screen and (max-width: 600px) {
      margin-bottom: 15px;
    }
  }
  @media only screen and (max-width: 1100px) {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 0;
  }
`;
export const FormFieldItems = styled.div`
  width: 100%;
  padding-bottom: 15px;
  > div {
    > div {
      @media only screen and (max-width: 600px) {
        flex-flow: column;
      }
    }
  }
  label {
    color: ${COLORS.GREYISH_BROWN_TWO};
    font-size: 1rem;
    width: 180px;
    padding-right: 20px;
    white-space: nowrap;
  }
  input {
    max-width: 411px;
    padding: 6px 11px;
    height: 43px;
  }
  .level1 {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    > div {
      width: 100%;
    }
    input[type="text"] {
      max-width: 210px;
    }
    label {
      color: ${COLORS.PINE};
      font-size: 0.9rem;
      font-weight: ${FONTWEIGHT.FW_6};
      // margin-left: 17px;
      @media only screen and (max-width: 500px) {
        font-size: 0.8rem;
        letter-spacing: 0;
        width: auto;
        // margin-left: 10px;
      }
    }
  }
  .level2 {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 34px;
    label {
      color: ${COLORS.PINE};
      font-size: 0.9rem;
      font-weight: ${FONTWEIGHT.FW_6};
      margin-left: 17px;
    }
  }
  @media only screen and (max-width: 600px) {
    padding-bottom: 0;
  }
`;
export const ReportContent = styled.div`
  padding: 20px 0 0 0;
  .level1 {
    margin: 13px 0;
    @media only screen and (max-width: 600px) {
      margin: 7px 0;
    }
    input[type="text"] {
      max-width: 395px;
      color: ${COLORS.DARK_SAGE_SIX};
      font-weight: ${FONTWEIGHT.FW_6};
    }
  }
  .level2 {
    margin-top: 25px;
    &:last-child {
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 600px) {
    padding-top: 0;
  }
`;
export const ReportModalFooterOuter = styled.div`
  button {
    position: relative;
    min-width: 223px;
    padding-right: 20px;
    i {
      position: absolute;
      right: 23px;
      top: 15px;
    }
  }
`;
export const FromTo = styled.div`
  padding: 25px 0 0 0;
  width: 100%;
  margin-bottom: 50px;
  input {
    color: ${COLORS.GREYISH_BROWN_THREE};
    font-weight: ${FONTWEIGHT.FW_6};
  }
  label {
    margin-right: 19px;
    font-weight: ${FONTWEIGHT.FW_6};
    color: ${COLORS.GREYISH_BROWN_TWO};
    font-size: 1.06rem;
    & + div {
      margin-right: 40px;
      position: relative;
      i {
        position: absolute;
        right: 10px;
        top: 0;
        color: ${COLORS.TURTLE_GREEN};
        height: 37px;
        padding-left: 7px;
        line-height: 37px;
        border-left: 2px solid ${COLORS.WHITE};
      }
      & + label + div {
        margin-right: 0;
      }
    }
  }
  input {
    max-width: 177px;
  }
  @media only screen and (max-width: 800px) {
    padding-left: 0;
    margin-bottom: 20px;
    padding-top: 0;
    > div {
      flex-flow: column;
      align-items: flex-start;
      label {
        margin-bottom: 10px;
        & + div {
          margin-right: 0;
          width: 100%;
        }
      }
      input {
        margin-bottom: 10px;
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
`;
