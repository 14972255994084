import React from "react";
import PropTypes from "prop-types";
import { Layout } from "../../styled";
import { FormInputLabel, TextInput, Error } from "../../styled/form";

const FormInput = React.forwardRef(
  ({ label, required, placeholder, error, errorOffset, noOffset, errorBorder, marginLeft, ...rest }, ref) => (
    <Layout relative={true} column flex={1} displayFlex justifyEnd offsetBottom={!noOffset ? 21 : 0}>
      <Layout displayFlex row flex={1} alignCenter>
        {label && (
          <FormInputLabel error={error}>
            {label}
            {required && <span>*</span>}
          </FormInputLabel>
        )}
        <Layout flex={1}>
          <TextInput marginLeft errorBorder={errorBorder} ref={ref} placeholder={placeholder || label} {...rest} error={error} />
        </Layout>
      </Layout>
      <Error textAlign="right" type="type-5" className="form_input_error" errorOffset={errorOffset}>
        {error}
      </Error>
    </Layout>
  ),
);

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
};

FormInput.defaultProps = {
  required: false,
  placeholder: "",
  error: "",
};

export default FormInput;
