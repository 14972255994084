import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../components/Sidebar";
import { Layout } from "../styled";
import { ContentWrapper } from "../styled/layout";

const Wrapper = ({ children, ...rest }) => {
  return (
    <Layout displayFlex>
      <Layout>
        <Sidebar />
      </Layout>
      <ContentWrapper>{children}</ContentWrapper>
    </Layout>
  );
};

Wrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Wrapper;
