import React from "react";
import { P, Layout } from "../../styled";
import { StatusBox } from "../../styled/MandantenDashboard";
import { IndicatorItem } from "../../styled/currentstatus";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import Select from "../Form/Select";
import ColoredSelect from "../Form/ColoredSelect";

const StatusOption = props => (
  <Layout displayFlex>
    <i className={props.icon}>{props.iconame}</i>
    <div>
      <P type="type-4_1">{props.title}</P>
      <ColoredSelect options={props.options} selected={props.selected} onChange={props.onChange} />
      {/* <IndicatorItem>
        <span className={props.class}>
          <input
            type="text"
            style={{
              backgroundColor: props.color.background,
              color: props.color.letters
            }}
            name={props.name}
            id={props.id}
            value={props.info}
            readOnly
          />
        </span>
      </IndicatorItem> */}
    </div>
  </Layout>
);

const OPTIONS = [
  {
    title: "Foo",
  },
];

class CurrentStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const {
      store: { client, clientStore },
    } = this.props;

    await client.fetch({
      clientId: clientStore.selectedClientId,
    });
  }

  getPhaseColor(color) {
    switch (color) {
    case "Blau":
      color = "blau";
      break;
    case "Grün":
      color = "grün";
      break;
    case "Schwarz":
      color = "schwarz";
      break;
    case "Rot":
      color = "rot";
      break;
    case "Grau":
      color = "grau";
      break;
    case "Gelb":
      color = "gelb";
      break;
    default:
    }
    return color;
  }

  getBackgroundColor = color => {
    switch (color) {
    case "Blau":
      color = { background: "#71b0e3", color: "white" };
      break;
    case "Grün":
      color = { background: "#95cc53", color: "white" };
      break;
    case "Schwarz":
      color = { background: "#626262", color: "white" };
      break;
    case "Rot":
      color = { background: "#ff8a65", color: "white" };
      break;
    case "Grau":
      color = { background: "#cacaca", color: "white" };
      break;
    case "Gelb":
      color = { background: "#f4d166", color: "black" };
      break;
    default:
    }
    return color;
  };

  handleClientStatusChange = type => async e => {
    const {
      store: { updateClients, client, clientStore },
    } = this.props;

    await updateClients.fetch({
      clientId: clientStore.selectedClientId,
      [type]: e.title,
    });
    await client.fetch({
      clientId: clientStore.selectedClientId,
    });
  };

  render() {
    const {
      store: { client },
    } = this.props;

    if ((client.loading || !client.data) && !client.success) {
      return null;
    }

    const options = ["Blau", "Grün", "Gelb", "Rot", "Schwarz", "Grau"].map(c => ({
      title: c,
      props: {
        style: {
          ...this.getBackgroundColor(c),
        },
      },
    }));

    return (
      <StatusBox>
        <Layout displayFlex>
          <StatusOption
            icon="material-icons-outlined"
            iconame="how_to_reg"
            name="currentColor"
            id="currentColor"
            title="Mandantenstatus aktuell"
            color={this.getBackgroundColor(client.data.current_phase)}
            className={this.getPhaseColor(client.data.current_phase)}
            info={this.getPhaseColor(client.data.current_phase)}
            options={options}
            selected={options.find(o => o.title === client.data.current_phase)}
            onChange={this.handleClientStatusChange("current_phase")}
          />
          <StatusOption
            icon="material-icons-outlined"
            iconame="record_voice_over"
            name="expectedColor"
            id="expectedColor"
            color={this.getBackgroundColor(client.data.expected_phase)}
            title="Mandantenstatus erwartet"
            className={this.getPhaseColor(client.data.expected_phase)}
            info={this.getPhaseColor(client.data.expected_phase)}
            options={options}
            selected={options.find(o => o.title === client.data.expected_phase)}
            onChange={this.handleClientStatusChange("expected_phase")}
          />
        </Layout>
        <P type="type-6">
          Legende: blau = Beratung, grün = inaktive Vollmacht, gelb = bedingte Vollmacht, rot = Vollmachtsfall, schwarz =
          Testamentsvollstreckung, grau = archiviert
        </P>
      </StatusBox>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(CurrentStatus);
