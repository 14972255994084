import React, { Component } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

import { Layout } from "../styled";
import { ReportsOuter } from "../styled/reports";
import FilterOptions from "../components/Reports/FilterOptions";
import CreateNewReport from "../components/Modal/CreateNewReport";
import Userphoto from "../assets/images/empty.png";
import config from "../lib/config";
import Table from "../components/Table";
import ResponseWarning from "../components/Response";

class Reports extends Component {
  async componentDidMount() {
    const clientId = this.props.store.client.data.client_id;
    await this.props.store.getReports.fetch({
      clientId: clientId,
    });
  }

  onPressUpload = () => {
    const {
      store: { modal },
    } = this.props;
    modal.add({
      title: "Neuen Report erstellen",
      component: CreateNewReport,
    });
  };

  render() {
    const {
      store: { client, getReports },
    } = this.props;

    const imageSource =
      client.data.profile_image_url === "uploads/empty.png" ? Userphoto : `${config.endpoints.api}/${client.data.profile_image_url}`;

    let namePrefix = client.data.salutation;
    let firstName = client.data.first_name;
    let lastName = client.data.last_name;

    return (
      <ReportsOuter>
        <Layout displayFlex alignCenter>
          <FilterOptions
            imageSource={imageSource}
            fullName={namePrefix + " " + firstName + " " + lastName}
            clientNumber={client.data.client_number}
            onPressUpload={this.onPressUpload}
            search
          />
        </Layout>
        {getReports.constructedTable.rows[0] ? <Table tableStore={getReports} /> : <ResponseWarning />}
      </ReportsOuter>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(Reports);
