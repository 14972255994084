import React from "react";

import { Layout } from "../../styled";
import Axios from "axios";
import FileDownload from "js-file-download";
// import TextInput from '../Inputs/TextInput/Index';
import FormInput from "../Form/Input";
import ButtonActions from "../Mandates/ButtonActions";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AssetTableButton } from "../../styled/buttons";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { ToastsStore, ToastsContainer } from "react-toasts";
import config from "../../lib/config";
import { toJS } from "mobx";

class RevisionFooter extends React.Component {
  downloadAssetDir = async () => {
    const {
      store: { assetsRevisions, getRevisionFiles, assetRevisionCreate, clientStore },
    } = this.props;

    const token = window.localStorage.getItem(config.keys.local_storage_key);

    const selected = toJS(assetsRevisions.selected || {});

    const list = Object.keys(selected)
      .filter(k => !!selected[k])
      .map(el => el);

    Axios({
      method: "POST",
      url: config.endpoints.api + `/api/assets/client/${clientStore.selectedClientId}/revisions/download`,
      responseType: "application/json",
      headers: { Authorization: `${token}` },
      data: { revisionIds: list.length > 0 ? list : undefined },
    }).then(res => {
      const array = res.data || [];
      array.forEach((uri, i) => {
        setTimeout(() => {
          window.open(`${config.endpoints.api}/reportdownload/${uri}`);
        }, i * 500);
      });

      assetsRevisions.clearSelected();
    });
  };

  render() {
    return (
      <Layout offsetTop={20} displayFlex row alignEnd justifyEnd rowToColumn={1100} mediaCenter={1100}>
        <AssetTableButton style={{ height: 45 }} width={170} marginLeft={20} mediaFullWidth={1100} onClick={this.downloadAssetDir}>
          Herunterladen
          <i class="material-icons" style={{ marginLeft: 10 }}>
            save_alt
          </i>
        </AssetTableButton>
        <ToastsContainer lightBackground store={ToastsStore} />
      </Layout>
    );
  }
}

export default compose(
  observer,
  inject("store"),
)(RevisionFooter);
