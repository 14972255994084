import styled from "styled-components";
import { COLORS, FONTWEIGHT } from "./constants";

export const LicenseManagementOuter = styled.div`
  width: 100%;
`;
export const AssignedLicenses = styled.div`
  width: 100%;
  padding: 21px 39px;
  p {
    margin-right: 30px;
  }
  @media only screen and (max-width: 650px) {
    padding: 21px 10px;
  }
`;
export const OrderLicense = styled.div`
  margin: 40px 0 0 0;
  padding: 21px 53px;
  border: 1px solid ${COLORS.LIGHT_GREY_FOURTEEN};
  background: ${COLORS.LIGHT_GREY_FIFTEEN};
  color: ${COLORS.PINE};
  font-weight: ${FONTWEIGHT.FW_6};
  font-size: 1.43rem;
  margin-bottom: 50px;
`;
