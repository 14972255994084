import qs from 'qs';
import { observable, action, decorate, computed, toJS } from "mobx";
import Axios from "axios";
import config from '../lib/config';
import { browserHistory } from '../routes/routes';
import {isTimerRunning} from '../utils/helper';
import { TIMER_ID as APP_TIMER_ID } from "../components/Activity/Appointment";
import { TIMER_ID as CORR_TIMER_ID } from "../components/Activity/Correspondence";
import { TIMER_ID as RES_TIMER_ID } from "../components/Activity/Research";
import { TIMER_ID as REV_TIMER_ID } from "../components/Activity/Review";
import { TIMER_ID as SETT_TIMER_ID } from "../components/Activity/Settlement";
import {routes} from '../routes/routes';

const generateFullUri = (uri, data = null) => {
  return `${config.endpoints.api}${uri}`.replace(/\{(.*?)\}/g, (token, name) => {
    let value = token;
    if (data && data[name]) {
      value = data[name];
      delete data[name];
    }
    return value;
  });;
};

class PromiseStore {
  loading = false;
  loaded = false;
  success = false;
  response = null;
  error = false;
  stale = false;

  constructor(endpoint, options, root, filter) {
    this.endpoint = endpoint;
    this.options = {
      ...options,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(options && options.headers ? options.headers : {})
      },
    };
    this.root = root;

    if(filter) {
      this.filter = filter;
    }
  }

  clear() {
    this._doUpdate(false, false, false, null, null, false);
  }

  async fetch(data) {

    this.stale = true;
    this.loading = true;
    return await this._doFetch(data);
  }

  get data(){
    const d = toJS(this.response);
    if(this.filter) {
      return {
        ...d,
        assets: d.assets.filter(this.filter)
      };
    }
    return toJS(this.response);
  }
  
  async _doFetch(extra) {
    const { method } = this.endpoint;
    let fullUri = generateFullUri(this.endpoint.uri, extra);

    let requestOptions = {
      ...this.options,
      method,
    };
    
    if (method === 'GET') {
      const queryString = qs.stringify(extra);
      if (queryString) {
        fullUri += `?${queryString}`;
      }
    } else if (extra && Object.keys(extra).length > 0) {
      requestOptions.data = extra;
    }

    requestOptions.url = fullUri;
    const token = window.localStorage.getItem(config.keys.local_storage_key);
    if(token) {  
      requestOptions.headers = {
        ...(requestOptions.headers ? requestOptions.headers : {}),
        Authorization: `${token}`
      };
    }
    await this.testInactivity();
    try {
      const response = await Axios(requestOptions);
      
      this._doUpdate(false, true, true, response.data.data, null, false);
      // this._doUpdate(false, true, true, response.data.data, null, false);
      return response;

    } catch (error) {
      // if(error.response && (error.response.status === 401 || error.response.status === "401")){
      //   browserHistory.push("/login");
      //   browserHistory.go();
      //   return;
      // }
      if(error.response && error.response.status === 403){
        window.localStorage.setItem(config.keys.local_storage_key, "");
        window.localStorage.setItem("profile_image_url", " ");
        window.localStorage.removeItem(config.keys.local_storage_key);
        window.localStorage.removeItem(config.keys.logout_timeout_key);
        [APP_TIMER_ID, CORR_TIMER_ID, RES_TIMER_ID, REV_TIMER_ID, SETT_TIMER_ID].forEach(t => {
          window.localStorage.removeItem(t);
        });
        browserHistory.replace(routes.LOGIN);
        browserHistory.go();
      }
      this._doUpdate(false, false, false, null, error, false);
      return error.response ? error.response : error;
      // throw new Error(error);
    }
  }

  _update(loading, loaded, success, response, error, extra) {
    if (!this.loading) {
      return;
    }
    this._doUpdate(loading, loaded, success, response, error, extra);
  }

  _doUpdate(loading, loaded, success, response, error, stale) {
    this.loading = loading;
    this.loaded = loaded;
    this.success = success;
    this.response = response;
    this.error = error;
    this.stale = stale;
  }

  //added function
  testInactivity = () => {
    if(this && this.root) {

      const token = window.localStorage.getItem(config.keys.local_storage_key);
      if(!token)return;
      const time = window.localStorage.getItem(config.keys.logout_timeout_key);
      if(this && this.root && this.root.inactive){
        clearTimeout(this.root.inactive.timeout);
      }
      this.root.inactive = {};
    
      this.root.inactive.timeout = setTimeout(() => {
        const isTimerActive = isTimerRunning();
        if(isTimerActive){
          return this.testInactivity();
        }
        window.localStorage.removeItem(config.keys.local_storage_key);
        browserHistory.push("/login");
        browserHistory.go();
      }, 900000);
      window.localStorage.setItem(config.keys.logout_timeout_key, Date.now() + 15*1000); // 5 min = 300000
    }
  }
}

export default decorate(PromiseStore, {
  loading: observable,
  loaded: observable,
  success: observable,
  response: observable,
  error: observable,
  stale: observable,
  data: computed,
  clear: action,
  fetch: action,
  testInactivity: action,
  _doFetch: action,
  _update: action,
  _doUpdate: action
});
