import React, { Component } from "react";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";
import * as Yup from "yup";

import { Layout } from "../../../styled";
import TextareaInput from "../../../components/Form/Textarea";
// import { TextArea } from "../../../styled/form";
import FormInput from "../../Form/Input";
import Select from "../../Form/Select";
import { Button } from "../../../styled/buttons";
import { ModalContent, ModalFooterOuter } from "../../../styled/modal";
import { AddNewQuestionOuter, FormFieldItems, ResponseOption, QuestionModalFooterOuter } from "../../../styled/addnewquestion";
import CheckBox from "../../Form/CheckBox";
import question_categories from "../../../seeds/question_categories";

class AddNewQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: "",
      hauptfrage: false,
      folgefrage: false,
      freitext: false,
      auswahlmöglichkeit: false,
      blendet: false,
      verursacht: false,
      text: "",
      textCheckbox: "",
      errorAnswer: "",
      errorMessage: "",
      fields: {},
      errors: {},
      questionNo: "",
      mainQuestions: [],
      questionsByCategory: [],
      selectedQuestion: "",
    };
  }

  getNumberAndLetters = questionNo => {
    const objToReturn = {};
    const numberOfQuestion = Number(questionNo);
    if (isNaN(numberOfQuestion)) {
      objToReturn.letters = questionNo.match(/[a-zA-Z]+/)[0];
      objToReturn.number = Number(questionNo.replace(/[a-zA-Z]+/, ""));
    } else {
      objToReturn.number = Number(questionNo);
    }

    return objToReturn;
  };

  sortQuestions = questions => {
    const sortedQuestions = questions.sort((val1, val2) => {
      let number1 = this.getNumberAndLetters(val1.question_no);
      let number2 = this.getNumberAndLetters(val2.question_no);

      if (number1.number > number2.number) {
        return 1;
      }
      if (number1.number < number2.number) {
        return -1;
      }
      if (!number1.letters) {
        return -1;
      }

      if (!number2.letters) {
        return 1;
      }

      return number1.letters < number2.letters ? 1 : -1;
    });

    return sortedQuestions;
  };

  async componentDidMount() {
    const clientId = this.props.store.client.data && this.props.store.client.data.client_id;
    try {
      const response = await this.props.store.getAllQuestions.fetch({
        clientId,
      });

      const sorted = this.sortQuestions(response.data.data);

      this.setState({
        questions: sorted,
      });
    } catch (e) {
      this.setState({ questions: [] });
    }
  }

  handleCategoryChange = category => {
    const clientId = this.props.store.client.data && this.props.store.client.data.client_id;

    this.props.store.getQuestionsByCategory
      .fetch({
        clientId,
        category,
      })
      .then(response => {
        this.setState({
          category: category,
          questionsByCategory: response.data.data,
          errors: {},
          errorAnswer: "",
          errorMessage: "",
        });
      });
  };

  handleQuestionSelect = question => {
    let quesNo = "";
    this.state.questionsByCategory.map((item, i) => {
      if (item.title === question) {
        quesNo = item.question_no;
      }
    });

    this.setState({
      questionNo: quesNo,
      selectedQuestion: question,
    });
  };

  handleCheckboxChange = name => () => {
    this.setState(state => ({
      [name]: !state[name],
      errorAnswer: "",
      errorMessage: "",
    }));
  };

  onTextCheckboxChange = event => {
    this.setState({
      textCheckbox: event.target.value,
    });
  };

  handleTypeChange = name => () => {
    if (name === "hauptfrage") {
      this.setState(state => ({
        hauptfrage: !state.hauptfrage,
        folgefrage: false,
        errorAnswer: "",
        errorMessage: "",
      }));
    } else {
      this.setState(state => ({
        hauptfrage: false,
        folgefrage: !state.folgefrage,
        errorAnswer: "",
        errorMessage: "",
      }));
    }
  };

  handleTypeInputChange = name => () => {
    if (name === "freitext") {
      this.setState(state => ({
        freitext: !state.freitext,
        auswahlmöglichkeit: false,
        errorAnswer: "",
        errorMessage: "",
      }));
    } else {
      this.setState(state => ({
        freitext: false,
        auswahlmöglichkeit: !state.auswahlmöglichkeit,
        errorAnswer: "",
        errorMessage: "",
      }));
    }
  };

  changeText = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let errorMessage = "";
    let errorAnswer = "";
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";

    const schema = Yup.object().shape({
      text: Yup.string().required(requiredError),
      category: Yup.string().required(requiredError),
    });

    try {
      schema.validateSync(fields, { abortEarly: false });
    } catch (err) {
      err.inner.forEach(e => {
        errors[e.path] = e.message;
      });
    }

    formIsValid = schema.isValidSync(fields);

    if (!this.state.hauptfrage && !this.state.folgefrage) {
      errorMessage = "Du musst eine dieser beiden Dateien auswählen.";
    }

    if (!this.state.freitext && !this.state.auswahlmöglichkeit) {
      errorAnswer = "Du musst eine dieser beiden Dateien auswählen.";
    }

    if (this.state.auswahlmöglichkeit && this.state.verursacht) {
      errors["verursacht"] = "Das Pflichtfeld darf nicht leer sein!";
    }

    if (this.state.folgefrage && this.state.selectedQuestion === "") {
      errors["selectedQuestion"] = "Das Pflichtfeld darf nicht leer sein!";
    }

    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (this[key]) {
        this[key].focus();
      }
    }

    this.setState({ errors: errors, errorAnswer: errorAnswer, errorMessage: errorMessage });
    return formIsValid && this.state.errorMessage === "" && this.state.errorAnswer === "";
  }

  submit = async () => {
    const { text, textCheckbox, category, freitext, hauptfrage, blendet, verursacht } = this.state;
    const {
      store: { client, addQuestion, updateQuestion },
    } = this.props;

    if (!this.handleValidation()) {
      return false;
    }

    const lastNumber = parseInt(this.state.questions[this.state.questions.length - 1].question_no);
    let checkboxText = textCheckbox.split(",");
    let options = '["' + checkboxText[0] + '","' + checkboxText[1] + '"]';

    const data = {
      show: hauptfrage,
      client_id: client.data.client_id,
      title: text,
      question_no: (lastNumber + 1).toString(),
      category: category,
      answer: "",
      type: freitext ? "text" : "radio",
      options: options,
      followUp: "",
      isMain: hauptfrage,
      hasFollowUp: verursacht,
    };

    try {
      if (!hauptfrage) {
        await updateQuestion.fetch({
          clientId: client.data.client_id,
          questionId: this.state.questionNo,
          followUp: (lastNumber + 1).toString(),
        });
      }

      await addQuestion.fetch({
        ...data,
        clientId: client.data.client_id,
      });

      if (options !== "") {
        const newData = {
          show: false,
          client_id: client.data.client_id,
          title: text,
          question_no: (lastNumber + 1).toString() + "a",
          category: category,
          answer: "",
          followUp: "",
          type: blendet ? "text" : "radio",
          isMain: false,
        };

        await addQuestion.fetch({
          ...newData,
          clientId: client.data.client_id,
        });
      }

      this.props.shouldReload(true);
      this.props.closeModal();
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      category,
      hauptfrage,
      folgefrage,
      freitext,
      auswahlmöglichkeit,
      blendet,
      verursacht,
      text,
      textCheckbox,
      errorAnswer,
      errorMessage,
      questionNo,
      selectedQuestion,
      questionsByCategory,
    } = this.state;

    return (
      <React.Fragment>
        <ModalContent>
          <AddNewQuestionOuter>
            <Layout displayFlex column>
              <FormFieldItems>
                <Layout row displayFlex alignCenter>
                  <h5>Kategorie und Frage</h5>
                  <Select
                    noAdapter
                    onChange={this.handleCategoryChange}
                    selected={category}
                    options={question_categories}
                    placeholder="Kategorie wählen"
                    error={this.state.errors && this.state.errors["category"]}
                  />
                </Layout>
              </FormFieldItems>
              <FormFieldItems>
                <p style={{ color: "#ef5b2e", marginTop: "10px" }}>{errorMessage}</p>
                <Layout column displayFlex alignCenter>
                  <div className="level1">
                    <Layout row displayFlex alignCenter>
                      <CheckBox onClick={this.handleTypeChange("hauptfrage")} checked={hauptfrage} />
                      <label>Hauptfrage</label>
                    </Layout>
                  </div>
                  <div className="level2">
                    <Layout row displayFlex alignCenter>
                      <CheckBox onClick={this.handleTypeChange("folgefrage")} checked={folgefrage} />
                      <label>Folgefrage</label>
                    </Layout>
                    {folgefrage && (
                      <Select
                        noAdapter
                        onChange={this.handleQuestionSelect}
                        selected={selectedQuestion}
                        options={questionsByCategory.map(a => {
                          return a.title;
                        })}
                        // options={questionsByCategory.filter(q => q.title)}
                        placeholder="Frage wählen"
                        error={this.state.errors && this.state.errors["selectedQuestion"]}
                      />
                    )}
                  </div>
                </Layout>
              </FormFieldItems>
              <FormFieldItems>
                <Layout displayFlex>
                  <TextareaInput
                    // label="Notiz"
                    value={text}
                    error={this.state.errors && this.state.errors["text"]}
                    // style={{ height: 131 }}
                    position
                    offsetBottom="0px"
                    marginBottom="0px"
                    name="text"
                    placeholder="Text für Frage hinterlegen"
                    onChange={this.changeText}
                    width={350}
                    mediaWidth={250}
                  />
                </Layout>
              </FormFieldItems>
              <h5>Antwortmöglichkeit</h5>
              <FormFieldItems>
                <Layout column displayFlex>
                  <ResponseOption>
                    <p style={{ color: "#ef5b2e" }}>{errorAnswer}</p>
                    <div className="level1">
                      <Layout row displayFlex alignCenter>
                        <CheckBox onClick={this.handleTypeInputChange("freitext")} checked={freitext} />
                        <label>Freitext</label>
                      </Layout>
                    </div>
                    <div className="level1 flexmobile">
                      <Layout row displayFlex alignCenter>
                        <div className="QuestionSplitOuter">
                          <CheckBox onClick={this.handleTypeInputChange("auswahlmöglichkeit")} checked={auswahlmöglichkeit} />
                          <label>Auswahlmöglichkeit</label>
                        </div>
                        <FormInput
                          name={textCheckbox}
                          type="text"
                          value={textCheckbox}
                          error={this.state.errors && this.state.errors["textCheckbox"]}
                          onChange={this.onTextCheckboxChange}
                          placeholder="Antwort - 1, Antwort - 2 ( Bspw. Ja, Nein )"
                        />
                      </Layout>
                    </div>

                    <Layout column displayFlex alignLeft>
                      <div className="level2">
                        <Layout row displayFlex alignCenter>
                          <CheckBox onClick={this.handleCheckboxChange("blendet")} checked={blendet} />
                          <label>Antwort 1 blendet Textbox ein</label>
                        </Layout>
                      </div>
                      <div className="level2">
                        <Layout row displayFlex alignCenter>
                          <CheckBox onClick={this.handleCheckboxChange("verursacht")} checked={verursacht} />
                          <label>Antwort 1 verursacht Folgefrage</label>
                        </Layout>
                      </div>
                    </Layout>
                  </ResponseOption>
                </Layout>
              </FormFieldItems>
            </Layout>
          </AddNewQuestionOuter>
        </ModalContent>
        <ModalFooterOuter>
          <QuestionModalFooterOuter>
            <Button onClick={this.submit} color="green">
              Frage hinzufügen
              <i className="material-icons">add_circle</i>
            </Button>
          </QuestionModalFooterOuter>
        </ModalFooterOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(AddNewQuestion);
