export const clientOrderAdapter = {
  last_name: {
    key: "row_1",
    order: 0,
    scale: 1,
    title: "Nachname",
  },
  first_name: {
    key: "row_2",
    order: 1,
    scale: 1,
    title: "Vorname",
  },
  postcode: {
    key: "row_3",
    order: 2,
    scale: 1,
    title: "PLZ",
  },
  place: {
    key: "row_4",
    order: 3,
    scale: 1,
    title: "Ort",
  },
  address: {
    key: "row_5",
    order: 4,
    scale: 1,
    title: "Straße",
  },
  birth_date: {
    key: "row_6",
    order: 5,
    scale: 1,
    title: "Geburtsdatum",
  },
};

export const clientHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

export const clientRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          }
        }
      });
      rows[index] = row;
    });
  }
  return rows;
};
