import React, { Component } from "react";
import ReactDOM from "react-dom";

import {
  SelectInnerContainer,
  SelectContainer,
  SelectText,
  SelectOptionText,
  SelectOptions,
  SelectOptionItem,
} from "../../styled/formcustomselect";
import { Icon } from "../../styled";

class CustomFormSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected: props.selected ? props.selected : null,
      itemWidth: 50,
      // itemColor:""
    };

    this.node = React.createRef();

    (props.options || []).forEach(option => {
      this[`ref_${option}`] = React.createRef();
    });
  }

  componentDidMount() {
    let minWidth = 50;
    const { options } = this.props;
    options.forEach(option => {
      const w = ReactDOM.findDOMNode(this[`ref_${option}`].current).offsetWidth;
      minWidth = minWidth < w ? w : minWidth;
    });
    const nodeWidth = ReactDOM.findDOMNode(this.node.current).offsetWidth;

    this.setState({
      itemWidth: minWidth,
      nodeWidth,
      itemColor: "",
    });
  }

  handleToggle = () => {
    const { open } = this.state;
    if (open) {
      this.handleClose();
    } else {
      this.handleOpen();
    }
  };
  handleOpen = () => {
    document.addEventListener("click", this._onClickListener, false);
    this.setState({ open: true });
  };
  handleClose = () => {
    document.removeEventListener("click", this._onClickListener, false);
    this.setState({ open: false });
  };

  setSelected = selected => () => {
    const { onChange } = this.props;
    document.removeEventListener("click", this._onClickListener, false);
    this.setState({ selected, open: false });
    if (onChange) {
      onChange(selected);
    }
  };

  _onClickListener = e => {
    if (!this.node.current.contains(e.target)) {
      this.handleClose();
      return;
    }
  };

  componentWillUnmount() {
    document.removeEventListener("click", this._onClickListener, false);
  }

  adaptOption = option => {
    const { noAdapter } = this.props;
    if (noAdapter) {
      return option;
    }
    switch (option) {
    case "assigned":
      return "Zugeordnet";
    case "open":
      return "Offen";
    case "sold":
      return "Veräußert";
    case "not_sold":
      return "Nicht veräußert";
    case "handing_over":
      return "Übergeben";
    case "disposal":
      return "Entsorgen";
    case "donation":
      return "Freigiebige Zuwendungen";
    case "legacy":
      return "Vermächtnis";
    case "sell":
      return "Verkaufen";
    case "dispute":
      return "Auseinandersetzen";
    case "administration":
      return "Verwaltung";
    case "asset":
      return "Aktiva";
    case "liability":
      return "Passiva";
    default:
      return undefined;
    }
  };

  render() {
    const { options, placeholder, value } = this.props;
    const { open, itemWidth, selected, nodeWidth } = this.state;

    const safePlaceholder = this.adaptOption(value) || placeholder || "Select";

    return (
      <SelectContainer ref={this.node} open={open}>
        <SelectInnerContainer onClick={this.handleToggle}>
          <SelectText>{selected ? this.adaptOption(selected) : safePlaceholder}</SelectText>
          <Icon size={16}>expand_more</Icon>
        </SelectInnerContainer>
        <SelectOptions minWidth={nodeWidth} open={open}>
          {(options || []).map(item => (
            <SelectOptionItem
              elemWidth={itemWidth}
              minWidth={nodeWidth}
              key={item.key || item}
              disabled={item.disabled}
              onClick={this.setSelected(item)}
            >
              <SelectOptionText ref={this[`ref_${item}`]}>{this.adaptOption(item)}</SelectOptionText>
              {/* <SelectOptionText ref={this[`ref_${item}`]}>
                {this.OptionColor(item)}
              </SelectOptionText> */}
            </SelectOptionItem>
          ))}
        </SelectOptions>
      </SelectContainer>
    );
  }
}

export default CustomFormSelect;
