import React from "react";
import qs from "qs";
import { Layout } from "../../../styled";
import FormInput from "../../Form/Input";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { ModalFooterOuter } from "../../../styled/createnewuser";
import { Button } from "../../../styled/buttons";

import Select from "../../Form/Select";

class CreateNewBeneficiary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      linkedPerson: "",
      uploading: false,
    };
  }

  componentDidMount() {
    const {
      store: {
        clientStore: { selectedClientId },
        personsListingCondition,
      },
    } = this.props;
    personsListingCondition.fetch({
      clientId: selectedClientId,
      isBeneficiary: false,
    });
  }

  componentWillUnmount() {
    const {
      store: {
        clientStore: { selectedClientId },
        personsListingCondition,
      },
    } = this.props;
    personsListingCondition.fetch({
      clientId: selectedClientId,
      isBeneficiary: true,
    });
  }
  submit = async () => {
    const { updateData } = this.props;
    if (!this.state.linkedPerson || !this.state.linkedPerson.value) {
      return this.onCloseModal();
    }
    this.setState({
      uploading: true,
    });
    const data = {
      is_beneficiary: true,
    };
    await this.props.store.updatePerson.fetch({
      personId: this.state.linkedPerson.value,
      data,
    });
    this.setState({
      uploading: false,
    });
    this.onCloseModal();
    updateData();
  };
  handleChange = e => this.setState({ name: e.target.value });
  handleLinkedPersonChange = person => {
    this.setState({ linkedPerson: person });
  };
  onCloseModal = async () => {
    this.props.closeModal();
  };
  render() {
    const { uploading } = this.state;
    const {
      store: { personsListingCondition },
    } = this.props;
    const { linkedPerson } = this.state;
    return (
      <React.Fragment>
        <Layout column displayFlex fullWidth spacingLeft={40} spacingRight={40} spacingTop={40}>
          <Layout minWidth="500px" displayFlex row alignCenter mediaWidth={800}>
            {/* 
            <FormInput
              placeholder="Begünstigter"
              value={name}
              onChange={this.handleChange}
            />
            */}
            {personsListingCondition.data && (
              <Select
                noAdapter
                withNameAndValue
                changeActionFlag={this.state.activityActionFlag}
                selected={linkedPerson}
                onChange={this.handleLinkedPersonChange}
                options={personsListingCondition.data.map(a => ({
                  name: `${a.first_name} ${a.last_name}`,
                  value: a.person_id,
                  key: a.person_id,
                }))}
                placeholder="Person / Organisation verknüpfen"
              />
            )}
          </Layout>
        </Layout>
        <ModalFooterOuter>
          <Layout displayFlex justifyEnd rowToColumn={500}>
            <Button onClick={this.onCloseModal} color="grey">
              Abbrechen
            </Button>
            <Button onClick={!uploading && this.submit} color="green">
              {uploading ? "Ihre Datei wird verarbeitet" : "Speichern"}
            </Button>
          </Layout>
        </ModalFooterOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(CreateNewBeneficiary);
