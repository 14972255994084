import styled from "styled-components";
import { COLORS, BORDER_RADIUS, FONTWEIGHT } from "./constants";

// export const DeviatingInvoiceAddressOuter = styled.div`
//   width: 100%;
//   padding: 50px 53px;
//   > p {
//     padding-bottom: 5px;
//     color: ${COLORS.BLACK};
//     border-bottom: 1px solid rgba(0, 0, 0, 0.05);
//     margin-bottom: 10px;
//   }
//   h5 {
//     color: ${COLORS.BLACK};
//     font-size: 1rem;
//     font-weight: ${FONTWEIGHT.FW_6};
//   }
//   > div {
//     > div {
//       padding-top: 0;
//       border-right: none;
//       &:first-child {
//       }
//       &:last-child {
//         // padding-left: 10%;
//         > p {
//           padding-bottom: 5px;
//           border-bottom: 1px solid rgba(0, 0, 0, 0.05);
//           margin-bottom: 30px;
//           margin-top: 20px;
//         }
//       }
//     }
//   }
//   .InvoiceAddress {
//     padding: 35px 0 60px 0;
//     label {
//       color: ${COLORS.GREYISH_BROWN_TWO};
//       font-size: 1rem;
//       padding-right: 25px;
//     }
//   &+p{
//     border-bottom:1px solid rgba(0, 0, 0, 0.05);
//     margin-bottom:10px;
//     span{
//       font-size:1rem;
//     }
//   }
//   }
// `;
export const AdditionalLicense = styled.div`
  width: 100%;
  margin: 0;
  .disabled{
    border: solid 1px ${COLORS.WHITE};
    border-radius: ${BORDER_RADIUS.BR_1};
    box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.15);
    width:100%;
    padding:10px 11px;
    height:36px;
    max-width:143px;
    font-weight:${FONTWEIGHT.FW_6};
    font-size:0.87rem;
    color:#999;
    opacity:0.4;
    position:relative;
    i{
      position:absolute;
      right:11px;
      top:9px;
    }
  }
  > div {
    margin-bottom: 0;
    > div {
      padding: 0 0 30px 0;
      border: none;
      display: flex;
      align-items: center;
      // > div{
        // &+label+div{
        //   position:relative;
        //   opacity:0.4;
          // &:after{
          //   width:100%;
          //   position:absolute;
          //   left:0;
          //   top:0;
          //   content:"";
          //   z-index:1;
          //   height:100%;
          // }
        // }
        // &.checked{
        //   &+label+div{
        //     opacity:1;
        //   &:after{
        //     display:none;
        //   }
        //   }
        }
      }
      @media only screen and (max-width: 700px) {
        flex-flow: column;
        align-items: flex-start;
      }
      label {
        margin-left: 15px;
        width: 71%;
        @media only screen and (max-width: 700px) {
          // margin: 5px 0 5px 0;
          width: 100%;
        }
      }
    }
  }
  label {
    color: ${COLORS.GREYISH_BROWN_TWO};
    font-size: 1rem;
  }
  select {
    float: right;
    padding: 8px 19px;
    border: 2px solid ${COLORS.WHITE};
    border-radius: ${BORDER_RADIUS.BR_1};
    font-size: 0.9rem;
    font-weight: ${FONTWEIGHT.FW_6};
    color: ${COLORS.GREYISH_BROWN_THREE};
    height: 43px;
    min-width: 177px;
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }
  > div{

  }
`;
export const Order = styled.div`
  width: 100%;
  box-shadow: 0px 1px 8px 0 ${COLORS.GUNMETAL_20};
  border: 1px solid ${COLORS.LIGHT_GREY_SIXTEEN};
  margin: 30px 0;
  h5 {
    border: 1px solid ${COLORS.LIGHT_GREY_TEN};
    background: ${COLORS.LIGHT_GREY_SEVENTEEN};
    padding: 29px 33px;
    color: ${COLORS.BLACK};
    font-size: 1.43rem;
    font-weight: ${FONTWEIGHT.FW_6};
  }
  > div {
    padding: 30px 33px;
    > div {
      padding: 0 0 25px 0;
      font-size: 1rem;
      color: ${COLORS.GREYISH_BROWN_TWO};
      border: none;
      span {
        float: right;
      }
      &:last-child {
        margin-top: 50px;
        padding: 30px 0 20px 0;
        border-top: 2px solid ${COLORS.LIGHT_GREY_SIXTEEN};
        font-size: 1.06rem;
        font-weight: ${FONTWEIGHT.FW_6};
      }
    }
  }
  & + div {
    display: block;
    button {
      float: right;
    }
  }
`;
export const InvoiceAddressForm = styled.div`
  width: 100%;
  > div {
    > div {
      > div {
        position: relative;
      }
    }
  }
  > label {
    width: 30%;
    max-width: 170px;
  }
  @media only screen and (max-width: 1050px) {
    > div {
      flex-flow: column;
      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
    @media only screen and (max-width: 700px) {
      > div {
        > div {
          > div {
            > div {
              flex-flow: column;
              label {
                width: 100%;
                text-align: left;
                max-width: 100%;
                padding-right: 0;
              }
              > div {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
`;
export const DeviatingInvoiceAddressOuter = styled.div`
  width: 100%;
  padding: 0 50px;
  @media only screen and (max-width: 1600px) {
    padding: 0 20px;
  }
  > div {
    width: 100% !important;
    height: auto;
    > label {
      max-width: none !important;
      width: auto !important;
      margin-right: 25px;
    }
  }
  p {
    padding-bottom: 12px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.11);
    width: 100%;
    margin-bottom: 30px;
  }
`;
export const LicenseModalSelectionOptions = styled.div`
  width: 100%;
  padding: 60px 50px;
  .spacing {
    display: block;
    height: 20px;
    width: 100%;
  }
  @media only screen and (max-width: 1600px) {
    padding: 0 20px;
  }
  @media only screen and (max-width: 1400px) {
    > div {
      flex-flow: column;
      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  p {
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    margin-bottom: 40px;
    i {
      font-size: 1rem;
    }
  }
`;
