import React, { Component } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

import { Layout } from "../styled";
import { ReportsOuter } from "../styled/reports";
import FilterOptions from "../components/Policen/FilterOptions";
import CreateNewPolicen from "../components/Modal/CreateNewPolicen";
import Userphoto from "../assets/images/empty.png";
import config from "../lib/config";
import Table from "../components/Table";
import ResponseWarning from "../components/Response";

class Policen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: "",
    };
  }

  async componentDidMount() {
    const clientId = this.props.store.client.data.client_id;
    await this.props.store.getPolicies.fetch({
      clientId: clientId,
    });
    await this.props.store.personsListing.fetch({
      clientId: clientId,
    });
  }

  onPressUpload = () => {
    const {
      store: { modal },
    } = this.props;
    modal.add({
      title: "Neuen Policen erstellen",
      component: CreateNewPolicen,
    });
  };

  onSearch = async search => {
    const {
      store: { searchPolicies, client },
    } = this.props;

    const searchQuery = search.target.value;

    try {
      await searchPolicies.fetch({
        searchQuery,
        client_id: client.data.client_id,
      });

      this.setState({ searchQuery });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      store: { client, getPolicies, personsListing, searchPolicies },
    } = this.props;
    const { searchQuery } = this.state;

    const imageSource =
      client.data.profile_image_url === "uploads/empty.png" ? Userphoto : `${config.endpoints.api}/${client.data.profile_image_url}`;

    let namePrefix = client.data.salutation;
    let firstName = client.data.first_name;
    let lastName = client.data.last_name;
    return (
      <ReportsOuter>
        <Layout displayFlex alignCenter>
          <FilterOptions
            onPressUpload={this.onPressUpload}
            fullName={namePrefix + " " + firstName + " " + lastName}
            clientNumber={client.data.client_number}
            imageSource={imageSource}
            search
            onChange={this.onSearch}
          />
        </Layout>
        {searchQuery === "" &&
          (personsListing.data && getPolicies.constructedTable.rows[0] ? (
            <Table
              personsListing={personsListing.data
                .filter(b => b.is_beneficiary)
                .map((p, i) => {
                  return { name: `${p.first_name || ""} ${p.last_name || ""}`.trim(), beneficiary_id: p.person_id };
                })}
              tableStore={getPolicies}
            />
          ) : (
            <ResponseWarning />
          ))}
        {searchQuery !== "" &&
          (personsListing.data && searchPolicies.constructedTable.rows[0] ? (
            <Table
              personsListing={personsListing.data
                .filter(b => b.is_beneficiary)
                .map((p, i) => {
                  return { name: `${p.first_name || ""} ${p.last_name || ""}`.trim(), beneficiary_id: p.person_id };
                })}
              tableStore={searchPolicies}
            />
          ) : (
            <ResponseWarning />
          ))}
      </ReportsOuter>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(Policen);
