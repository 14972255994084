import { ToastsStore } from "react-toasts";
import fileDownload from "js-file-download";

import document_categories from "../../seeds/document_categories";

export const documentOrderAdapter = {
  document_name: {
    key: "row_1",
    order: 0,
    scale: 4,
    title: "Dokumentenname",
    notSortable: false,
  },
  file_type: {
    key: "row_2",
    order: 1,
    scale: 3,
    title: "Dateityp",
    notSortable: false,
  },
  document_category: {
    key: "row_3",
    order: 2,
    scale: 4,
    title: "Dokumentenkategorie",
    notSortable: true,
  },
  asset_category_name: {
    key: "row_4",
    order: 3,
    scale: 4,
    title: "Zugeordnete Kategorie Vermögensverzeichnis",
    notSortable: true,
  },
  document_notes: {
    key: "row_5",
    order: 4,
    scale: 7,
    title: "Notizen",
    notSortable: false,
  },
  is_declared: {
    key: "row_6",
    order: 5,
    scale: 1,
    title: "Dokument deklarieren",
    notSortable: true,
  },
};

export const documentHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

const declareDocument = async (event, store, index) => {
  event.stopPropagation();
  const { documents, editDocument } = store;
  const doc = documents.data[index];

  const data = {
    is_declared: !doc.is_declared,
  };

  await editDocument.fetch({
    documentId: doc.document_id,
    ...data,
  });
  await documents.fetch({
    client_id: store.client.data.client_id,
  });
};

const downloadDocument = (e, store, index) => {
  const { documents } = store;
  let xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var myReader = new FileReader();
    myReader.readAsArrayBuffer(xhr.response);

    myReader.addEventListener("loadend", function(e) {
      var buffer = e.srcElement.result; //arraybuffer object
      const fileUrlSplit = documents.data[index].file_url.split(".");
      const extension = fileUrlSplit[fileUrlSplit.length - 1];
      fileDownload(buffer, documents.data[index].document_name + "." + extension);
    });
  };
  xhr.open("GET", documents.data[index].file_url);
  xhr.send();

  xhr.responseType = "blob";
};

const getCategory = async (category, store, index) => {
  const { editDocument, documents } = store;
  const data = { document_category: category };
  await editDocument.fetch({
    documentId: documents.data[index].document_id,
    ...data,
  });
  ToastsStore.success("Speichern erfolgreich!");
  await documents.fetch({
    client_id: store.client.data.client_id,
  });
};

const getAssetCategory = async (asset_id, store, index) => {
  const { assets, clientStore } = store;
  assets.fetch({
    clientId: clientStore.selectedClientId,
  });
};

export const documentRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          case "id":
            break;
          case "is_declared":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "icon",
              active: "bookmark",
              inactive: "bookmark_border",
              action: (store, index) => e => declareDocument(e, store, index),
              data: item[itemKey],
            };
            break;
          case "options":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "select",
              options: item[itemKey],
            };
            break;
          case "document_category":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "select",
              action: (store, index) => e => getCategory(e, store, index),
              options: document_categories.map(e => e),
              value: item[itemKey],
              noAdapter: true,
            };
            break;
          case "asset_id":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
              noAdapter: true,
            };
            break;
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
              hasOnClick: true,
              onClick: (store, index) => e => downloadDocument(e, store, index),
            };
            break;
          }
          }
        }
      });
      rows[index] = row;
    });
  }
  return rows;
};
