import React, { Component } from "react";
import { compose } from "recompose";
import { LoadingDIv } from "../../styled";
import Table from "../../components/Table";
import { inject, observer } from "mobx-react";
import NewAppointment from "./NewAppointment";

import { NextAppointmentOuter, NewItemBlock } from "../../styled/MandantenDashboard";
import moment from "moment";
import _ from "lodash";

class NextAppointment extends Component {
  async componentDidMount() {
    const {
      store: {
        clientTask,
        clientStore: { selectedClientId },
      },
    } = this.props;

    await clientTask.fetch({
      clientId: selectedClientId,
    });
  }
  daysUntilNext = (month, day) => {
    const tday = new Date(),
      y = tday.getFullYear(),
      next = new Date(y, month - 1, day);
    tday.setHours(0, 0, 0, 0);
    if (tday > next) next.setFullYear(y + 1);
    return Math.round((next - tday) / 8.64e7);
  };
  render() {
    const {
      store: { clientTask, client },
    } = this.props;

    // var year = new Date().getFullYear();

    const birthday = _.get(client, "data.birth_date", null);
    // const birthday = _.get(c, "birth_date", null);
    // let birthday = client.data.birth_date;
    var year = new Date().getFullYear();
    const daysBetween = this.daysUntilNext(Number(moment(birthday).format("MM")), Number(moment(birthday).format("DD")));
    const nextBirthday = moment()
      .add(daysBetween, "days")
      .format("DD.MM.YYYY");
    if ((moment().isSame(birthday, "month") && moment().isAfter(birthday, "day")) || moment().isAfter(birthday, "month")) year++;

    // const tableElem = birthday
    //   ? moment(birthday)
    //     .year(year)
    //     .format("DD.MM.YYYY")
    //   : "";

    const extraMappableData = [
      [
        {
          action: () => () => null,
          active: "chrome_reader_mode",
          data: "chrome_reader_mode",
          inactive: "chrome_reader_mode",
          key: "foo___0",
          type: "icon",
        },
        { key: "foo_0", type: "text", data: "Geburtstag Mandant" },
        { key: "foo_1", type: "text", data: nextBirthday },
        { key: "foo_1", type: "text", data: "" },
      ],
    ];

    return (
      <React.Fragment>
        <NextAppointmentOuter>
          {clientTask.loading && !clientTask.loaded && (
            <div className="loading">
              <LoadingDIv>&nbsp;</LoadingDIv>
            </div>
          )}
          {clientTask.loaded && (clientTask.constructedTable.rows[0] || Object.keys(extraMappableData).length > 0) ? (
            <Table
              hideHeader
              hideFooter
              small
              onClickRow={this.handleRowClick}
              tableStore={clientTask}
              extraMappableData={extraMappableData}
            />
          ) : (
            <NewAppointment />
          )}
          <div className="newItemIcon">
            <NewItemBlock>
              <a href="/activities">
                <i className="material-icons">add_circle</i>
              </a>
            </NewItemBlock>
          </div>
        </NextAppointmentOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(NextAppointment);
