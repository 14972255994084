import React, { Component } from "react";
import { P, Icon } from "../../styled";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";

import { PieChart, Pie, Cell, Tooltip } from "recharts";
import {
  GraphInfo,
  IndicatorItem,
  GraphColumn,
  GraphTitle,
  GraphsOuter,
  Graphitem,
  IndicatorList,
  IndicatorListAge,
} from "../../styled/graphs";
import { Layout, LayoutIcon } from "../../styled";
import { ResponseMessageGraph } from "../../styled/layout";
import AgeGraph from "./AgeGraph";
import SummeAktuellGraph from "./SummeAktuellGraph";
import SummeErwartetGraph from "./SummeErwartetGraph";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Indicator = props => (
  <IndicatorItem>
    <span color={props.color} className={props.className}>
      <i className="material-icons props.class">stop</i>
    </span>
    <label>{props.info}</label>
  </IndicatorItem>
);

// const warningMessage = `Aktuell haben Sie noch keine Mandanten angelegt. Bitte legen Sie
// zunächst Ihre Mandanten an, um eine Übersicht über Ihre Mandanten
//  zu generieren.`;
const warningMessage = `Aktuell liegen zu dieser Ansicht keine Informationen vor `;
class Graph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      store: { getClientAgePhases },
    } = this.props;
    getClientAgePhases.fetch();
  }

  handleGraphOpen = (e, name) => {
    const {
      store: { modal },
    } = this.props;
    switch (name) {
    case "alter":
      return modal.add({
        title: "Alter meiner Mandanten",
        component: AgeGraph,
      });
    case "summe_aktuell":
      return modal.add({
        title: "Summe Mandanten je Phase (aktuell)",
        component: SummeAktuellGraph,
      });
    case "summe_erwartet":
      return modal.add({
        title: "Summe Mandanten je Phase (erwartet)",
        component: SummeErwartetGraph,
      });
    default:
      return null;
    }
  };

  render() {
    const {
      store: { getClientAgePhases },
    } = this.props;

    if (getClientAgePhases.loading || !getClientAgePhases.data) {
      return null;
    }

    const yearsDetails = {
      twenty: "20-29 Jahre",
      thirty: "30-39 Jahre",
      fourty: "40-49 Jahre",
      fifty: "50-59 Jahre",
      sixty: "60-69 Jahre",
      seventy: "70-79 Jahre",
      eighty: "80-89 Jahre",
      ninty: "90-99 Jahre",
      hundred: "Über 100 Jahre",
    };

    const statusDetails = {
      blue: "Blau",
      green: "Grün",
      black: "Schwarz",
      red: "Rot",
      grey: "Grau",
      gray: "Grau",
      gold: "Gelb",
      yellow: "Gelb",
    };

    let ageArray = getClientAgePhases.data.ageObj
      ? getClientAgePhases.data.ageObj.map(e => ({
        ...e,
        name: yearsDetails[e.name],
      }))
      : [];

    let currentArray = getClientAgePhases.data.currentArray
      ? getClientAgePhases.data.currentArray.map(e => {
        return {
          ...e,
          name: statusDetails[e.name],
        };
      })
      : [];

    let expectedArray = getClientAgePhases.data.expectedArray
      ? getClientAgePhases.data.expectedArray.map(e => ({
        ...e,
        name: statusDetails[e.name],
      }))
      : [];

    const currentObj = getClientAgePhases.data.currentPhaseObj ? getClientAgePhases.data.currentPhaseObj : [];
    const expectedObj = getClientAgePhases.data.expectedPhaseObj ? getClientAgePhases.data.expectedPhaseObj : [];

    return (
      <React.Fragment>
        <div>
          <GraphInfo>
            <Layout displayFlex>
              <GraphColumn>
                <h6>Summe Phase aktuell : </h6>
                <Layout displayFlex row>
                  <Indicator info={(currentObj.blue ? currentObj.blue : 0) + " blau"} className="case1" />
                  <Indicator info={(currentObj.green ? currentObj.green : 0) + " grün"} className="case2" />
                  <Indicator info={(currentObj.black ? currentObj.black : 0) + " schwarz"} className="case3" />
                  <Indicator info={(currentObj.red ? currentObj.red : 0) + " rot"} className="case4" />
                  <Indicator info={(currentObj.gray ? currentObj.gray : 0) + " grau"} className="case5" />
                  <Indicator info={(currentObj.yellow ? currentObj.yellow : 0) + " gelb"} className="case6" />
                </Layout>
              </GraphColumn>
              <GraphColumn>
                <h6> Summe Phase erwartet : </h6>
                <Layout displayFlex row>
                  <Indicator info={(expectedObj.blue ? expectedObj.blue : 0) + " blau"} className="case1" />
                  <Indicator info={(expectedObj.green ? expectedObj.green : 0) + " grün"} className="case2" />
                  <Indicator info={(expectedObj.black ? expectedObj.black : 0) + " schwarz"} className="case3" />
                  <Indicator info={(expectedObj.red ? expectedObj.red : 0) + " rot"} className="case4" />
                  <Indicator info={(expectedObj.gray ? expectedObj.gray : 0) + " grau"} className="case5" />
                  <Indicator info={(expectedObj.yellow ? expectedObj.yellow : 0) + " gelb"} className="case6" />
                </Layout>
              </GraphColumn>
            </Layout>
            <P type="type-6">
              Legende: blau = Beratung, grün = inaktive Vollmacht, gelb = bedingte Vollmacht, rot = Vollmachtsfall, schwarz =
              Testamentsvollstreckung, grau = archiviert
            </P>
          </GraphInfo>

          <GraphsOuter style={{ overflow: "scroll" }}>
            <Graphitem>
              <GraphTitle marginBottom="0">Alter meiner Mandanten:</GraphTitle>
              <Layout fullWidth displayFlex row justifyEnd height={10} style={{ position: "absolute" }}>
                <LayoutIcon>
                  <Icon absolute size={16} pointer type="material-icons" style={{ paddingRight: "20px", paddingTop: "3px" }}>
                    close
                  </Icon>
                </LayoutIcon>
                <LayoutIcon>
                  <Icon absolute pointer onClick={e => this.handleGraphOpen(e, "alter")} type="material-icons">
                    reorder
                  </Icon>
                </LayoutIcon>
              </Layout>
              {ageArray.length > 0 ? (
                <PieChart width={260} height={350}>
                  <Pie
                    data={ageArray}
                    innerRadius={90}
                    outerRadius={130}
                    // fill={entry.color}
                    paddingAngle={2}
                    labelLine={true}
                    label={renderCustomizedLabel}
                    dataKey="value"
                  >
                    {ageArray.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              ) : (
                <ResponseMessageGraph>
                  <div className="response success">
                    <i className="material-icons">warning</i> {warningMessage}
                  </div>
                </ResponseMessageGraph>
              )}
              <IndicatorListAge>
                <Indicator info="20-29 Jahre" className="twenty" />
                <Indicator info="30-39 Jahre" className="thirty" />
                <Indicator info="40-49 Jahre" className="fourty" />
                <Indicator info="50-59 Jahre" className="fifty" />
                <Indicator info="60-69 Jahre" className="sixty" />
                <Indicator info="70-79 Jahre" className="seventy" />
                <Indicator info="80-89 Jahre" className="eighty" />
                <Indicator info="90-99 Jahre" className="ninety" />
                <Indicator info="Über 100 Jahre" className="hundred" />
              </IndicatorListAge>
            </Graphitem>
            <Graphitem>
              <GraphTitle marginBottom="0">Summe Mandanten je Phase (aktuell)</GraphTitle>
              <Layout fullWidth displayFlex row justifyEnd height={10} style={{ position: "absolute" }}>
                <LayoutIcon>
                  <Icon absolute size={16} pointer type="material-icons" style={{ paddingRight: "20px", paddingTop: "3px" }}>
                    close
                  </Icon>
                </LayoutIcon>
                <LayoutIcon>
                  <Icon absolute pointer onClick={e => this.handleGraphOpen(e, "summe_aktuell")} type="material-icons">
                    reorder
                  </Icon>
                </LayoutIcon>
              </Layout>
              {currentArray.length > 0 ? (
                <PieChart width={260} height={350}>
                  <Pie
                    data={currentArray}
                    innerRadius={90}
                    outerRadius={130}
                    fill="#8bbf4c"
                    paddingAngle={2}
                    labelLine={true}
                    label={renderCustomizedLabel}
                    dataKey="value"
                  >
                    {currentArray.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              ) : (
                <ResponseMessageGraph>
                  <div className="response success">
                    <i className="material-icons">warning</i> {warningMessage}
                  </div>
                </ResponseMessageGraph>
              )}
              <IndicatorList>
                <Layout displayFlex row>
                  <Indicator info="blau" className="case1" />
                  <Indicator info="grün" className="case2" />
                  <Indicator info="schwarz" className="case3" />
                  <Indicator info="rot" className="case4" />
                  <Indicator info="grau" className="case5" />
                  <Indicator info="gelb" className="case6" />
                </Layout>
              </IndicatorList>
            </Graphitem>
            <Graphitem>
              <GraphTitle marginBottom="0">Summe Mandanten je Phase (erwartet)</GraphTitle>
              <Layout fullWidth displayFlex row justifyEnd height={10} style={{ position: "absolute" }}>
                <LayoutIcon>
                  <Icon absolute size={16} pointer type="material-icons" style={{ paddingRight: "20px", paddingTop: "3px" }}>
                    close
                  </Icon>
                </LayoutIcon>
                <LayoutIcon>
                  <Icon absolute pointer onClick={e => this.handleGraphOpen(e, "summe_erwartet")} type="material-icons">
                    reorder
                  </Icon>
                </LayoutIcon>
              </Layout>
              {expectedArray.length > 0 ? (
                <PieChart width={260} height={350}>
                  <Pie
                    data={expectedArray}
                    innerRadius={90}
                    outerRadius={130}
                    fill="#8bbf4c"
                    paddingAngle={2}
                    labelLine={true}
                    label={renderCustomizedLabel}
                    dataKey="value"
                  >
                    {expectedArray.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              ) : (
                <ResponseMessageGraph>
                  <div className="response success">
                    <i className="material-icons">warning</i> {warningMessage}
                  </div>
                </ResponseMessageGraph>
              )}
              <IndicatorList>
                <Layout displayFlex row>
                  <Indicator info="blau" className="case1" />
                  <Indicator info="grün" className="case2" />
                  <Indicator info="schwarz" className="case3" />
                  <Indicator info="rot" className="case4" />
                  <Indicator info="grau" className="case5" />
                  <Indicator info="gelb" className="case6" />
                </Layout>
              </IndicatorList>
            </Graphitem>
          </GraphsOuter>
        </div>
      </React.Fragment>
    );
  }
}
export default compose(
  inject("store"),
  observer,
)(Graph);
