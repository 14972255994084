import { compose } from "recompose";
import withTabsWrapper from "../hoc/Tabs";
import VorlagenTable from "./VorlagenTable";

export default compose(
  withTabsWrapper([
    {
      key: "tab_1",
      pageTitle: "Vorlagen",
      title: "Vorlagen",
      component: VorlagenTable,
    },
  ]),
)(() => null);
