import React from "react";
import _ from "lodash";
import ReactDOM from "react-dom";
import { autorun } from "mobx";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactResizeDetector from "react-resize-detector";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  TableContainer,
  TableHeaderRow,
  HeaderItemText,
  TableOverflowContainer,
  TableRow,
  TableItem,
  TableItemText,
  TableFooter,
  LineFooter,
  ListSelector,
  ListContainer,
  ListNumber,
} from "../../styled/table";
import { TableHeaderItem } from "../../styled/table";
import { Icon, Layout } from "../../styled";
import Select from "../Form/Select";
import CheckBox from "../Form/CheckBox";
import Pagination from "./Pagination";
import { TableMeta } from "../../styled/table";
import FormInput from "../../components/Form/Input";
import { Button } from "../../styled/buttons";
import { TableBlankButton } from "../../styled/table";
import { FilesContainer } from "../../styled/assets-table";
import TableFile from "../TableFile";

// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle, extra) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",
  ...extra,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "rgb(134,186,71)" : "rgb(134,186,71)",
  display: "flex",
  // overflow: "auto",
  height: 60,
  width: "100%",
});

class Table extends React.Component {
  // static defaultProps = {
  //   show: 5,
  // };
  DATA_TO_RENDER = [];
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      widths: [],
      page: 0,
      file: null,
      openList: false,
      show: 10,
      orderFilter: null,
    };

    this.upload = {};

    props.tableStore.constructedTable.header.forEach(item => {
      this[`header_${item.key}`] = React.createRef();
    });
  }
  onDragEnd = header => result => {
    const { tableStore } = this.props;
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(header, result.source.index, result.destination.index);

    tableStore.setSorted(items);
  };

  componentDidMount() {
    // const widths = [];

    // const data = passedData || tableStore.constructedTable;
    // data.header.forEach(item => {
    //   widths.push(
    //     ReactDOM.findDOMNode(this[`header_${item.key}`].current).offsetWidth
    //   );
    // });

    // this.setState({ widths });

    this.autorun = autorun(this.findSizes);
  }

  findSizes = () => {
    const { tableStore, data: passedData } = this.props;
    const widths = [];

    const data = passedData || tableStore.constructedTable;
    data.header.forEach(item => {
      widths.push(ReactDOM.findDOMNode(this[`header_${item.key}`].current).offsetWidth);
    });

    this.setState({ widths });
  };

  componentWillReceiveProps(nextProps) {
    const { tableStore, data: passedData } = nextProps;
    const widths = [];

    const data = passedData || tableStore.constructedTable;
    data.header.forEach(item => {
      widths.push(ReactDOM.findDOMNode(this[`header_${item.key}`].current).offsetWidth);
    });

    this.setState({ widths });
  }

  componentWillUnmount = () => {
    this.autorun();
  };

  onUploadFile = (e, item, store, row_key) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    item.action(store, row_key, e.target.files[0], this.upload)(e);
  };

  onClickUpload = row_key => () => {
    this.upload[row_key].click();
  };
  onOpenList = () => {
    const { openList } = this.state;
    this.setState({
      openList: !openList,
    });
  };
  onClickPage = page => this.setState({ page });

  render() {
    const { widths, page } = this.state;
    const {
      store,
      tableStore,
      hideFooter,
      small,
      onClickRow,
      onChange,
      hideHeader,
      loading,
      data: passedData,
      extraMappableData,
      containerStyle,
      footerStyle,
      handleRowClickSortable,
      sortable,
    } = this.props;
    const { show, orderFilter } = this.state;
    const { assetEdit, getUserFromToken } = store;
    const showExtraColumn = getUserFromToken.data && getUserFromToken.data.payment_model === "new";

    let data = passedData || tableStore.constructedTable;
    const indexOfExtraColumn = data.header.findIndex(t => t.key === "row_10");
    if (!showExtraColumn && indexOfExtraColumn > -1) {
      data.header.splice(indexOfExtraColumn, 1);
      data.rows[0].splice(indexOfExtraColumn, 1);
    }

    const mappable = {};

    if (extraMappableData) {
      extraMappableData.forEach((d, i) => {
        const index = Object.keys(data.rows || {}).length + Object.keys(mappable).length;

        mappable[index] = d;
      });

      data = tableStore.construct(extraMappableData) || passedData || tableStore.constructedTable;
    }

    const rows = data.rows;
    const header = orderFilter || data.header;
    const tableWidth = widths.reduce((acc, current) => acc + current, 0);

    const rowCount = Object.keys(rows || {}).length;

    const firstLimit = show * page + 1;
    const lastLimit = Math.min(show * (page + 1), rowCount);
    const ScrollContainer = window.innerWidth < 1680 ? PerfectScrollbar : Layout;

    const isMobile = window.innerWidth < 1440;
    const shouldSort = !isMobile && sortable;

    return (
      <TableContainer style={containerStyle || {}}>
        <ReactResizeDetector handleWidth handleHeight onResize={this.findSizes} />
        {!hideHeader && (
          <TableMeta>
            {firstLimit} bis {lastLimit} von {rowCount}
          </TableMeta>
        )}
        <ScrollContainer style={{ boxShadow: "none" }}>
          <TableOverflowContainer>
            {!shouldSort ? (
              <TableHeaderRow small={small}>
                {data.header.map((item, index) => (
                  <TableHeaderItem elemWidth={widths[index]} small={small} key={index} ref={this[`header_${item.key}`]} scale={item.scale}>
                    <HeaderItemText>{item.title}</HeaderItemText>
                    {!item.notSortable && (
                      <Icon onClick={() => tableStore.sort(index)}>
                        {tableStore.sortBy === index && tableStore.sortOrder === "ASC" ? "arrow_drop_up" : "arrow_drop_down"}
                      </Icon>
                    )}
                  </TableHeaderItem>
                ))}
              </TableHeaderRow>
            ) : (
              <TableHeaderRow small={small}>
                <DragDropContext onDragEnd={this.onDragEnd(data.header)}>
                  <Droppable style={{ height: 60 }} droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                        {header.map((item, index) => (
                          <Draggable key={item.key} draggableId={item.key} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, {
                                  width: widths[index],
                                  flex: item.scale,
                                })}
                              >
                                <TableHeaderItem
                                  elemWidth={widths[index]}
                                  small={small}
                                  key={index}
                                  ref={this[`header_${item.key}`]}
                                  scale={item.scale}
                                >
                                  <HeaderItemText>{item.title}</HeaderItemText>
                                  {!item.notSortable && (
                                    <Icon onClick={() => tableStore.sort(index)}>
                                      {tableStore.sortBy === index && tableStore.sortOrder === "ASC" ? "arrow_drop_up" : "arrow_drop_down"}
                                    </Icon>
                                  )}
                                </TableHeaderItem>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </TableHeaderRow>
            )}
            {loading && (
              <TableRow small={small}>
                <TableItem elemWidth={tableWidth}>
                  <Layout displayFlex alignCenter justifyCenter flex={1}>
                    <Icon type="material-icons" spinning>
                      donut_small
                    </Icon>
                  </Layout>
                </TableItem>
              </TableRow>
            )}

            {!loading &&
              Object.keys(rows)
                .filter((__, i) => i >= firstLimit - 1 && i < lastLimit)
                .map((row_key, index) => (
                  <TableRow
                    onClick={
                      onClickRow
                        ? () => onClickRow(row_key)
                        : handleRowClickSortable
                          ? () => handleRowClickSortable(rows[row_key])
                          : () => null
                    }
                    small={small}
                    key={index}
                  >
                    {rows[row_key].map((item, index) => {
                      switch (item.type) {
                      case "key":
                        return null;
                      case "id":
                        return null;
                      case "blankButton":
                        const elem = rows[row_key][0].data.split(" ")[1];
                        return (
                          <TableItem key={item.key} elemWidth={widths[index]}>
                            <Layout flex={1} displayFlex alignCenter justifyCenter>
                              <TableBlankButton onClick={() => assetEdit.selectRevision(item.revisionId)}>{item.data}</TableBlankButton>
                            </Layout>
                          </TableItem>
                        );
                      case "icon":
                        return (
                          <TableItem small={small} key={item.key} elemWidth={widths[index]}>
                            <Layout displayFlex flex={1} alignCenter justifyCenter>
                              <Icon
                                isDisabled={item.isDisabled}
                                type={item.active ? "material-icons active" : "material-icons inactive"}
                                onClick={item.action(store, row_key)}
                              >
                                {item.data ? item.active : item.inactive}
                              </Icon>
                            </Layout>
                          </TableItem>
                        );
                      case "select":
                        return (
                          <TableItem small={small} key={index} elemWidth={widths[index]}>
                            <Select table {...item} onChange={item.action(store, row_key, onChange)} />
                          </TableItem>
                        );
                      case "select-persons":
                        return (
                          <TableItem small={small} key={index} elemWidth={widths[index]}>
                            <Select
                              {...item}
                              options={this.props.personsListing}
                              withNameAndValue={true}
                              onChange={item.action(store, row_key, onChange)}
                            />
                          </TableItem>
                        );
                      case "checkbox":
                        return (
                          <TableItem small={small} key={index} elemWidth={widths[index]}>
                            <CheckBox checked={item.checked} {...item} onClick={item.action(store, row_key)} />
                          </TableItem>
                        );
                      case "link":
                        return (
                          <TableItem
                            small={small}
                            key={index}
                            onClick={item.hasOnClick ? item.onClick(store, row_key) : null}
                            elemWidth={widths[index]}
                          >
                            <TableItemText small={small} underline>
                              {item.data}
                            </TableItemText>
                          </TableItem>
                        );
                      case "upload":
                        return (
                          <TableItem small={small} key={item.key} elemWidth={widths[index]}>
                            <Layout displayFlex flex={1} alignCenter justifyCenter>
                              <FormInput
                                ref={input => (this.upload[row_key] = input)}
                                style={{ display: "none" }}
                                label=""
                                onChange={e => this.onUploadFile(e, item, store, row_key)}
                                name="file"
                                type="file"
                              />
                              <Icon
                                type={item.active ? "material-icons active" : "material-icons inactive"}
                                onClick={this.onClickUpload(row_key)}
                                // onClick={item.action(store, row_key)}
                              >
                                {item.data ? item.active : item.inactive}
                              </Icon>
                            </Layout>
                          </TableItem>
                        );
                      case "file-tag":
                        return (
                          <TableItem noPadding small={small} key={item.key} elemWidth={widths[index]}>
                            <Layout displayFlex flex={1} alignCenter justifyCenter>
                              <FilesContainer width>
                                <TableFile
                                  download={item.file_path}
                                  width={true}
                                  href={`${item.file_path}`}
                                  target="_blank"
                                  title={item.name}
                                  onClick={item.deleteFile(store, row_key)}
                                />
                              </FilesContainer>
                            </Layout>
                          </TableItem>
                        );
                      case "newPayment":
                        if (!item.data) return null;
                        return (
                          <TableItem
                            small={small}
                            key={index}
                            onClick={item.hasOnClick ? item.onClick(store, row_key) : null}
                            elemWidth={widths[index]}
                          >
                            <TableItemText small={small} color={item.color} textAlign={item.textAlign}>
                              {item.data}
                            </TableItemText>
                          </TableItem>
                        );
                      case "text":
                      default:
                        return (
                          <TableItem
                            small={small}
                            key={index}
                            onClick={item.hasOnClick ? item.onClick(store, row_key) : null}
                            elemWidth={widths[index]}
                          >
                            <TableItemText small={small} color={item.color} textAlign={item.textAlign}>
                              {item.data}
                            </TableItemText>
                          </TableItem>
                        );
                      }
                    })}
                  </TableRow>
                ))}
          </TableOverflowContainer>
        </ScrollContainer>
        <React.Fragment>
          {hideFooter || loading ? (
            <LineFooter style={{ ...(footerStyle || {}), boxShadow: "none" }} />
          ) : (
            <TableFooter style={{ boxShadow: "none", position: "relative" }}>
              <Pagination {...{ page, rowCount, show, onClickPage: this.onClickPage }} />
              <Layout style={{ position: "absolute", right: "20px" }}>
                <ListContainer onClick={this.onOpenList} open={this.state.openList}>
                  list
                  <i className="material-icons">keyboard_arrow_down</i>
                </ListContainer>
              </Layout>
              <ListSelector open={this.state.openList}>
                <ListNumber onClick={() => this.setState({ show: 10 })} selected={show === 10}>
                  0 - 10
                </ListNumber>
                <ListNumber onClick={() => this.setState({ show: 20 })} selected={show === 20}>
                  10 - 20
                </ListNumber>
                <ListNumber onClick={() => this.setState({ show: 30 })} selected={show === 30}>
                  21 - 30
                </ListNumber>
                <ListNumber onClick={() => this.setState({ show: 40 })} selected={show === 40}>
                  31 - 40
                </ListNumber>
                <ListNumber onClick={() => this.setState({ show: 50 })} selected={show === 50}>
                  41 - 50
                </ListNumber>
              </ListSelector>
            </TableFooter>
          )}
        </React.Fragment>
      </TableContainer>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(Table);
