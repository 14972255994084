import PromiseStore from "./PromiseStore";
import { decorate, observable, action } from 'mobx';

class ActivityDateStore extends PromiseStore {
  activityIsSelected = false;
  dateOfSelectedActivity = null;
  files = [];

  onActivityIsSelectedClick = (atriubute = true) => {
    this.activityIsSelected = atriubute;
  }

  onChangeDate = (date = null) => {
    this.dateOfSelectedActivity = date;
  }

  setActivityFiles = (files) => {
    this.files = files;
  }
}

export default decorate(ActivityDateStore, {
  selectedClientId: observable,
  globalClientId:observable,
  onActivityIsSelectedClick: action,
  onChangeDate: action,
  setActivityFiles: action,
  files: observable,
});
