import React, { Component } from "react";
import { Layout, P, Icon, Label } from "../../styled";
import { compose } from "recompose";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withRouter } from "react-router-dom";

import { AdministrationOuter } from "../../styled/administration";
import { inject, observer } from "mobx-react";
import { TextInput, TextArea } from "../../styled/form";
import { Speichem } from "../../styled/buttons";
import { UserFilterOptions } from "../../styled/administration";
import { AdministrationInfo } from "../../styled/administration";
import { NewsandEventsOuter, NewsItem } from "../../styled/myoverview";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  let j = 0;
  for (let i = result.length - 1; i >= 0; i--) {
    result[j].order = i;
    j++;
  }

  return result;
};

const getItemStyle = (isDragging, draggableStyle, extra) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "wheat" : "white",
  ...extra,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const NewsList = props => (
  <NewsItem isAdmin>
    <Icon floatRight onClick={e => props.onClick(e, props.news_id)} className="material-icons">
      clear
    </Icon>
    <Icon
      floatRight
      onClick={e => props.onEdit(e, props.news_id, props.title, props.description, props.hyperlink)}
      className="material-icons"
    >
      edit
    </Icon>
    <h6>{props.title}</h6>
    <Label>
      <a href={`http://${props.hyperlink}`} target="popup" onclick={`window.open(http://${props.hyperlink},'name','width=600,height=400')`}>
        {props.hyperlink}
      </a>
    </Label>
    <P offsetTop="20">{props.description}</P>
  </NewsItem>
);
class UserAdministration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      text: "",
      showButton: false,
      isEdit: false,
      newsId: 0,
      hyperlink: "",
      news: [],
      uploading: false,
    };
  }
  componentDidMount() {
    this.getNews();
    if (this.props.store.getUserFromToken.data.email === "info@innovation-wist.de") this.setState({ showButton: true });
  }

  getNews = async () => {
    const { getNews } = this.props.store;
    try {
      await getNews.fetch().then(result => {
        this.setState({
          news: result.data.data,
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  changeState = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  createNews = async () => {
    const { createNews, getNews } = this.props.store;

    const today = new Date();
    const todayString = today.toISOString();
    try {
      this.setState({
        uploading: true,
      });
      await createNews.fetch({
        title: this.state.title,
        text: this.state.text,
        date: todayString,
        hyperlink: this.state.hyperlink,
      });
      await getNews.fetch().then(result => {
        this.setState({
          news: result.data.data,
        });
      });
      this.clearFields();
      this.setState({
        uploading: false,
      });
      // this.props.history.push("/my-overview");
    } catch (e) {
      console.log(e);
    }
  };

  clearFields = () => {
    this.setState({
      title: "",
      text: "",
      hyperlink: "",
      showButton: false,
      isEdit: false,
    });
  };

  deleteNews = async (event, news_id) => {
    event.preventDefault();
    const { deleteNews, getNews } = this.props.store;

    try {
      await deleteNews.fetch({
        newsId: news_id,
      });
      await getNews.fetch().then(result => {
        this.setState({
          news: result.data.data,
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  editNews = (event, newsId, title, notes, hyperlink) => {
    event.preventDefault();
    this.setState({
      title: title,
      text: notes,
      hyperlink: hyperlink,
      showButton: false,
      isEdit: true,
      newsId: newsId,
    });
  };

  updateNews = async (event, newsId) => {
    event.preventDefault();
    const { updateNews, getNews } = this.props.store;
    const { title, text, hyperlink } = this.state;
    const data = { title: title, text: text, hyperlink: hyperlink };
    try {
      this.setState({
        uploading: true,
      });
      await updateNews.fetch({
        newsId: newsId,
        ...data,
      });
      await getNews.fetch().then(result => {
        this.setState({
          news: result.data.data,
        });
      });
      this.clearFields();
      this.setState({
        uploading: false,
      });
      // this.props.history.push("/my-overview");
    } catch (e) {
      console.log(e);
    }
  };

  onDragEnd = async result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(this.state.news, result.source.index, result.destination.index);

    items.map(async item => {
      await this.props.store.updateNews.fetch({
        newsId: item.news_id,
        ...item,
      });
    });

    this.setState({
      news: items,
    });
  };

  render() {
    const { title, text, isEdit, newsId, hyperlink, news, uploading } = this.state;
    const arrayOfNews = news ? news : [];
    return (
      <AdministrationOuter>
        <Layout
          displayFlex
          alignCenter
          column
          justifyAround
          flex={1}
          // height="700"
        >
          <React.Fragment>
            <UserFilterOptions>
              <Layout displayFlex alignCenter>
                <P type="type-10">Neuigkeiten</P>
              </Layout>
              <AdministrationInfo>
                <Layout displayFlex offsetTop={30}>
                  <Label>Titel</Label>
                  <TextInput placeholder="Titel" value={title} name="title" onChange={this.changeState} width={350} mediaWidth={250} />
                </Layout>
              </AdministrationInfo>
              <AdministrationInfo>
                <Layout displayFlex offsetTop={30}>
                  <Label>Link</Label>
                  <TextInput
                    placeholder="Hyperlink"
                    value={hyperlink}
                    name="hyperlink"
                    onChange={this.changeState}
                    width={350}
                    mediaWidth={250}
                  />
                </Layout>
              </AdministrationInfo>
              <AdministrationInfo>
                <Layout displayFlex>
                  <Label>Notiz</Label>
                  <TextArea
                    placeholder="Notiz"
                    value={text}
                    style={{ height: 250 }}
                    name="text"
                    onChange={this.changeState}
                    width={350}
                    mediaWidth={250}
                  />
                </Layout>
              </AdministrationInfo>

              {(this.state.showButton || true) && (
                <Layout displayFlex justifyEnd alignEnd width={"65%"} mediaFullWidth={700}>
                  <Speichem onClick={!uploading && (isEdit ? e => this.updateNews(e, newsId) : this.createNews)} mediaFullWidth={700}>
                    {uploading ? "Ihre Datei wird verarbeitet" : isEdit ? "Aktualisierung" : "Speichern"}
                  </Speichem>
                </Layout>
              )}
            </UserFilterOptions>
          </React.Fragment>
          <NewsandEventsOuter>
            <P type="type-10">Erstellte Neuigkeiten</P>
            <div>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} style={{ display: "flex", flexDirection: "column" }}>
                      {arrayOfNews.map((element, index) => (
                        <Draggable key={element.news_id} draggableId={element.news_id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            >
                              <NewsList
                                onEdit={this.editNews}
                                onClick={this.deleteNews}
                                news_id={element.news_id}
                                hyperlink={element.hyperlink}
                                title={element.title}
                                description={element.text}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </NewsandEventsOuter>
        </Layout>
      </AdministrationOuter>
    );
  }
}

export default compose(
  withRouter,
  inject("store"),
  observer,
)(UserAdministration);
