import React from "react";
import withPageWrapper from "../hoc/Page";
import { compose } from "recompose";
import * as Yup from "yup";
import Axios from "axios";
import { inject, observer } from "mobx-react";
import { ToastsStore, ToastsContainer } from "react-toasts";
import { ResponseMessage, ResponseMessageCustom } from "../styled/layout";
import { Speichem, Abbrechen } from "../styled/buttons";
import FormInput from "../components/Form/Input";
import FormSelect from "../components/Form/InputSelect";
import DatePicker from "../components/Form/DatePicker";
import UserEmptyImage from "../assets/images/empty.png";
import config from "../lib/config";
import Userphoto from "../assets/images/empty.png";
import { FormInputLabel } from "../styled/form";

import { Layout } from "../styled";
import { UserInformation, PrimaryInfo, ActionsOuter, MainActionsInner, MainActionsOuter, UserPictureEdit } from "../styled/userinformation";
import { UserInformationRow } from "../styled/signup";
import { DatePickerItem } from "../styled/myinformation";

const UPDATE_MESSAGE_OPTIONS = {
  SUCCESS_UPDATE: "SUCCESS_UPDATE",
  FAILURE_UPDATE: "FAILURE_UPDATE",
  NULL: null,
};

class MyInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      newPassword: "",
      confirmPassword: "",
      uploading: false,
    };

    this.upload = React.createRef();
  }

  async componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const {
      store: { userDetials },
    } = this.props;

    let getUserData = await userDetials.fetch();
    let currentValues = getUserData.data.data;
    let data = {
      salutation: currentValues.salutation,
      email: currentValues.email,
      firstName: currentValues.first_name,
      lastName: currentValues.last_name,
      postCode: currentValues.postcode,
      number: currentValues.number,
      place: currentValues.place,
      street: currentValues.street,
      nameAffix: currentValues.name_affix,
      firm: currentValues.company_name,
      mobileNumber: currentValues.mobile_number,
      phoneNumber: currentValues.phone_number,
      title: currentValues.title,
      dateOfBirth: new Date(currentValues.date_of_birth),
      profileImage: currentValues.profile_image_url ? config.endpoints.api + "/" + currentValues.profile_image_url : UserEmptyImage,
    };
    this.setState({ ...data });
  }

  reloadData = () => {
    this.fetchData();
  };

  resetPasswordFields = () => {
    this.setState({
      newPassword: "",
      confirmPassword: "",
    });
  };

  handleChange = field => e => {
    this.setState({ [field]: e.target.value });
  };

  handleSelected = category => value => {
    this.setState({ [category]: value });
  };

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    const schema = Yup.object().shape({
      lastName: Yup.string()
        .nullable()
        .required(requiredError),
      firstName: Yup.string()
        .nullable()
        .required(requiredError),
      number: Yup.string()
        .matches(/\d/, {
          message: "Muss mindestens eine Nummer eingeben",
          path: "number",
          excludeEmptyString: true,
        })
        .nullable(),
      dateOfBirth: Yup.date(requiredError)
        .typeError(requiredError)
        .required(requiredError),
      postCode: Yup.string()
        .matches(/^[0-9]+$/, "Nur Zahlen")
        .required(requiredError),
      place: Yup.string()
        .nullable()
        .required(requiredError),
      street: Yup.string()
        .nullable()
        .required(requiredError),
      email: Yup.string()
        .nullable()
        .email("E-Mail ist nicht gültig!")
        .required(requiredError),
      salutation: Yup.string(requiredError)
        .nullable()
        .required(requiredError),
    });

    try {
      schema.validateSync(fields, { abortEarly: false });
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(e => {
          errors[e.path] = e.message;
        });
      }
    }

    formIsValid = schema.isValidSync(fields);

    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (this[key]) {
        this[key].focus();
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleDateChange = data => {
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    if (new Date(data) >= new Date(yesterday)) {
      this.setState({
        dateOfBirth: "",
        dateOfBirthError: "Sie können kein Datum in der Zukunft oder heute eingeben!",
      });
      return;
    }
    this.setState({ dateOfBirth: new Date(data), dateOfBirthError: "" });
  };

  handleUpload = async event => {
    // TODO: move this to promise store and set upload when save button is clicked!!
    const file = this.upload.current.files[0];
    if (event) {
      const body = new FormData();
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = e => {
          this.setState({ profileImage: e.target.result });
        };
        reader.readAsDataURL(event.target.files[0]);
      }
      body.append("profileImage", file);
      const token = window.localStorage.getItem(config.keys.local_storage_key);
      Axios({
        method: "POST",
        url: config.endpoints.api + "/api/admin/profile_image_upload",
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      }).then(file => {
        this.props.store.user.setCurrentUser({
          ...this.props.store.user.currentUser,
          profile_image_url: `${file.data.data}`,
        });
      });
    }
  };

  handleSubmit = async () => {
    const {
      store: { updateUser },
    } = this.props;
    // this.getPhoto();
    // return;
    const {
      salutation,
      firstName,
      street,
      place,
      email,
      lastName,
      number,
      postCode,
      nameAffix,
      firm,
      phoneNumber,
      mobileNumber,
      dateOfBirth,
      title,
    } = this.state;

    if (!this.handleValidation()) {
      this.setState({
        message: "",
      });
      return false;
    }

    this.setState({
      uploading: true,
    });
    const data = {
      salutation,
      email,
      first_name: firstName,
      last_name: lastName,
      postcode: postCode,
      number,
      place,
      street,
      title,
      name_affix: nameAffix,
      company_name: firm,
      mobile_number: mobileNumber,
      phone_number: phoneNumber,
      date_of_birth: new Date(dateOfBirth),
    };

    let responseMessage = await updateUser.fetch(data);
    this.props.store.user.setCurrentUser({
      ...this.props.store.user.currentUser,
      ...data,
    });
    this.setState({
      uploading: false,
    });
    this.showResponseMessage(responseMessage);
  };

  handlePasswordSubmit = async () => {
    const {
      store: { changePassword },
    } = this.props;

    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    let regExp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-_=+])[a-zA-Z0-9!@#$%^&*-_=+]{12,20}$/;

    if (!fields["newPassword"]) {
      formIsValid = false;
      errors["newPassword"] = requiredError;
      this.newPassword.focus();
    }

    if (!fields["confirmPassword"]) {
      formIsValid = false;
      errors["confirmPassword"] = requiredError;
      this.confirmPassword.focus();
    }

    if (fields["newPassword"] && !regExp.test(fields["newPassword"])) {
      formIsValid = false;
      // errors["newPassword"] = "Das Passwort muss im gültigen Format vorliegen";
      errors["newPassword"] =
        "Das Passwort muss aus mindestens 12-20 Zeichen bestehen und mindestens einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.";
      this.newPassword.focus();
    }

    if (fields["newPassword"] !== fields["confirmPassword"]) {
      formIsValid = false;
      errors["confirmPassword"] = "Bitte Überprüfen Sie Ihre Eingaben.!";
      this.confirmPassword.focus();
    }

    this.setState({ errors: errors });

    if (!formIsValid) {
      this.setState({
        message: "",
      });
      return;
    }

    let data = {
      password: fields.newPassword,
    };

    let response = await changePassword.fetch(data);
    this.showResponseMessage(response.data.data);
  };

  showResponseMessage = async responseMessage => {
    if (responseMessage) {
      this.setState({
        message: UPDATE_MESSAGE_OPTIONS.SUCCESS_UPDATE,
      });
      ToastsStore.success("Ihre Änderungen wurden erfolgreich gespeichert");
    } else {
      this.setState({
        message: UPDATE_MESSAGE_OPTIONS.FAILURE_UPDATE,
      });
      ToastsStore.error(UPDATE_MESSAGE_OPTIONS.FAILURE_UPDATE);
    }
    // setTimeout(
    //   function() {
    //     this.setState({ message: "" });
    //   }.bind(this),
    //   5000
    // );
  };

  render() {
    const {
      store: { userDetials },
    } = this.props;
    const { uploading } = this.state;

    if (userDetials.loading || !userDetials.data) {
      return null;
    }

    return (
      <React.Fragment>
        <UserInformation>
          <UserPictureEdit>
            <div className="imageholder">
              <img src={this.state.profileImage || Userphoto} alt="user" />
            </div>
            <Speichem>
              <input ref={this.upload} type="file" onChange={this.handleUpload} name="myFile" />
              Bild ändern
            </Speichem>
            {/* <Speichem>Bild ändern</Speichem> */}
          </UserPictureEdit>
          <PrimaryInfo>
            <Layout displayFlex spacingLeft={20} spacingRight={20} flex={1} width="100%" column>
              <FormSelect
                noAdapter
                required
                label="Anrede"
                onChange={this.handleSelected("salutation")}
                placeholder="Anrede"
                options={["Frau", "Herr"].map(a => a)}
                selected={userDetials.data.salutation}
                error={this.state.errors["salutation"]}
              />

              <FormInput label="Titel" onChange={this.handleChange("title")} value={this.state.title} placeholder="Titel" />
              <FormInput
                label="Vorname"
                value={this.state.firstName}
                onChange={this.handleChange("firstName")}
                required
                error={this.state.errors["firstName"]}
                ref={el => {
                  this.firstName = el;
                }}
              />
              <FormInput
                label="Straße"
                value={this.state.street}
                onChange={this.handleChange("street")}
                required
                error={this.state.errors["street"]}
                ref={el => {
                  this.street = el;
                }}
              />
              <FormInput
                label="Ort"
                value={this.state.place}
                required
                onChange={this.handleChange("place")}
                error={this.state.errors["place"]}
                ref={el => {
                  this.place = el;
                }}
              />
              <FormInput
                label="Telefonnummer"
                value={this.state.phoneNumber}
                onChange={this.handleChange("phoneNumber")}
                placeholder="Telefonnummer"
              />
              <FormInput
                name="email"
                label="E-Mailadresse"
                value={this.state.email}
                readonly
                onChange={this.handleChange("email")}
                required
                error={this.state.errors["email"]}
                ref={el => {
                  this.email = el;
                }}
              />
            </Layout>
            <Layout displayFlex spacingLeft={20} spacingRight={20} flex={1} width="100%" column>
              <FormInput
                label="Namenszusatz"
                value={this.state.nameAffix}
                onChange={this.handleChange("nameAffix")}
                placeholder="Namenszusatz"
              />
              <FormInput label="Firma" value={this.state.firm} onChange={this.handleChange("firm")} placeholder="Firma" />
              <FormInput
                required
                label="Nachname"
                onChange={this.handleChange("lastName")}
                value={this.state.lastName}
                error={this.state.errors["lastName"]}
                ref={el => {
                  this.lastName = el;
                }}
              />
              <FormInput
                // required
                label="Hausnr"
                onChange={this.handleChange("number")}
                value={this.state.number}
                error={this.state.errors["number"]}
                ref={el => {
                  this.number = el;
                }}
              />
              <FormInput
                required
                label="PLZ"
                value={this.state.postCode}
                onChange={this.handleChange("postCode")}
                error={this.state.errors["postCode"]}
                ref={el => {
                  this.postCode = el;
                }}
              />
              <FormInput
                label="Mobilfunknummer"
                placeholder="Mobilfunknummer"
                value={this.state.mobileNumber}
                onChange={this.handleChange("mobileNumber")}
              />

              <DatePickerItem>
                <FormInputLabel>
                  Geburtsdatum<span>*</span>
                </FormInputLabel>
                <DatePicker value={this.state.dateOfBirth} onChange={this.handleDateChange} error={this.state.errors.dateOfBirth} />
              </DatePickerItem>
            </Layout>
          </PrimaryInfo>
          <ActionsOuter>
            <Layout displayFlex>
              <Abbrechen onClick={this.reloadData} responsiveMargin={600}>
                Abbrechen
              </Abbrechen>
              <Speichem onClick={!uploading && this.handleSubmit} style={{ marginRight: "20px" }} responsiveMargin={600}>
                {uploading ? "Ihre Datei wird verarbeitet" : "Speichern"}
              </Speichem>
            </Layout>
            {/* <ResponseMessageCustom>
              {" "}
              <div>
                {this.state.message === UPDATE_MESSAGE_OPTIONS.SUCCESS_UPDATE && (
                  <ResponseMessage>
                    <div className="response success">
                      {" "}
                      <i className="material-icons">check_box</i> Ihre Änderungen wurden erfolgreich gespeichert
                    </div>
                  </ResponseMessage>
                )}
                {this.state.message === UPDATE_MESSAGE_OPTIONS.FAILURE_UPDATE && (
                  <div className="response">
                    <i className="material-icons">error</i> Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
                  </div>
                )}
              </div>{" "}
            </ResponseMessageCustom> */}
            <ToastsContainer lightBackground store={ToastsStore} />
          </ActionsOuter>
          <Layout offsetBottom={30} offsetTop={40} spacingBottom={12}>
            <h6>Passwort ändern</h6>
          </Layout>
          <Layout spacingRight={15} spacingLeft={15}>
            <PrimaryInfo>
              <div className="SetPasswordField">
                {/* <Layout displayFlex spacingLeft={20} spacingRight={20} flex={1} width="100%" column> */}
                <FormInput
                  type="password"
                  label="Neues Passwort"
                  placeholder="Neues Passwort"
                  value={this.state.newPassword}
                  onChange={this.handleChange("newPassword")}
                  required
                  error={this.state.errors["newPassword"]}
                  ref={el => {
                    this.newPassword = el;
                  }}
                />
                {/* </Layout> */}
                {/* <Layout displayFlex spacingLeft={20} spacingRight={20} flex={1} width="100%" column> */}
                <FormInput
                  label="Passwort bestätigen"
                  type="password"
                  value={this.state.confirmPassword}
                  placeholder="Passwort bestätigen"
                  onChange={this.handleChange("confirmPassword")}
                  required
                  error={this.state.errors["confirmPassword"]}
                  ref={el => {
                    this.confirmPassword = el;
                  }}
                />
                {/* </Layout> */}
              </div>
            </PrimaryInfo>
          </Layout>
        </UserInformation>
        <MainActionsOuter responsivePadding>
          <MainActionsInner>
            <Abbrechen onClick={this.resetPasswordFields} responsiveMargin={600}>
              Abbrechen
            </Abbrechen>
            <Speichem onClick={this.handlePasswordSubmit} responsiveMargin={601}>
              Passwort ändern
            </Speichem>
          </MainActionsInner>
        </MainActionsOuter>
      </React.Fragment>
    );
  }
}
export default compose(
  withPageWrapper("Meine Information"),
  inject("store"),
  observer,
)(MyInformation);
