import React, { Component } from "react";
import ReactDOM from "react-dom";

import { SelectInnerContainer, SelectContainer, SelectText, SelectOptionText, SelectOptions, SelectOptionItem } from "../../styled/select";
import { Icon } from "../../styled";

class ColoredSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected: props.selected ? props.selected : null,
      itemWidth: 50,
    };

    this.node = React.createRef();

    (props.options || []).forEach(option => {
      this[`ref_${option.title}`] = React.createRef();
    });
  }

  componentDidMount() {
    let minWidth = 50;
    const { options } = this.props;
    options.forEach(option => {
      const w = ReactDOM.findDOMNode(this[`ref_${option.title}`].current).offsetWidth;
      minWidth = minWidth < w ? w : minWidth;
    });
    const nodeWidth = ReactDOM.findDOMNode(this.node.current).offsetWidth;

    this.setState({
      itemWidth: minWidth,
      nodeWidth,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changeActionFlag !== this.props.changeActionFlag) {
      this.setState({
        selected: nextProps.selected,
      });
    }
  }

  handleToggle = () => {
    const { open } = this.state;
    if (open) {
      this.handleClose();
    } else {
      this.handleOpen();
    }
  };
  handleOpen = () => {
    document.addEventListener("click", this._onClickListener, false);
    this.setState({ open: true });
  };
  handleClose = () => {
    document.removeEventListener("click", this._onClickListener, false);
    this.setState({ open: false });
  };

  setSelected = (selected, index, skip) => () => {
    const shouldSkip = (skip || []).find(s => s === selected);

    if (shouldSkip) {
      return;
    }
    const { onChange } = this.props;
    document.removeEventListener("click", this._onClickListener, false);
    this.setState({ selected, open: false });
    if (onChange) {
      onChange(selected, index);
    }
  };

  _onClickListener = e => {
    if (!this.node.current.contains(e.target)) {
      this.handleClose();
      return;
    }
  };

  componentWillUnmount() {
    document.removeEventListener("click", this._onClickListener, false);
  }

  adaptOption = option => option;

  render() {
    const { options, placeholder, value, skip } = this.props;

    const { open, itemWidth, selected, nodeWidth } = this.state;

    const safePlaceholder = this.adaptOption(value) || placeholder || "Select";

    const selectedProps = { ...(selected ? selected.props || {} : {}) };

    return (
      <SelectContainer ref={this.node} open={open}>
        <SelectInnerContainer onClick={this.handleToggle} colorProps={selectedProps.style}>
          <SelectText colorProps={selectedProps.style}>{selected ? this.adaptOption(selected.title) : safePlaceholder}</SelectText>
          <Icon size={16}>expand_more</Icon>
        </SelectInnerContainer>
        <SelectOptions minWidth={nodeWidth} open={open}>
          {(options || []).map((item, index) => (
            <SelectOptionItem
              elemWidth={itemWidth}
              minWidth={nodeWidth}
              key={item.key || item.title}
              disabled={item.disabled}
              onClick={this.setSelected(item, index, skip)}
              {...(item.props || {})}
            >
              <SelectOptionText disabled={(skip || []).find(s => s === item.title)} ref={this[`ref_${item.title}`]} {...(item.props || {})}>
                {this.adaptOption(item.title)}
              </SelectOptionText>
            </SelectOptionItem>
          ))}
        </SelectOptions>
      </SelectContainer>
    );
  }
}

export default ColoredSelect;
