import React from "react";
import ReactDOM from "react-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  TableContainer,
  TableMeta,
  TableHeaderRow,
  HeaderItemText,
  TableOverflowContainer,
  TableRow,
  TableItem,
  TableItemText,
  TableFooter,
  LineFooter,
  TableHeaderItem,
} from "../../styled/table";
import CustomCheckBox from "../Form/CustomCheckBox";
import { Icon, Layout } from "../../styled";
import Pagination from "./Pagination";
import { compose } from "recompose";
import { inject } from "mobx-react";

class CustomTable extends React.Component {
  static defaultProps = {
    show: 10,
  };
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      widths: [],
      page: 0,
      file: null,
    };

    props.data.header.forEach(item => {
      this[`header_${item.key}`] = React.createRef();
    });
  }

  onClickPage = page => this.setState({ page });

  componentDidMount() {
    const { data } = this.props;
    const widths = [];
    data.header.forEach(item => {
      widths.push(ReactDOM.findDOMNode(this[`header_${item.key}`].current).offsetWidth);
    });

    this.setState({ widths });
  }

  componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    const widths = [];
    data.header.forEach(item => {
      widths.push(ReactDOM.findDOMNode(this[`header_${item.key}`].current).offsetWidth);
    });

    this.setState({ widths });
  }

  render() {
    const { widths, page } = this.state;
    const { store, tableStore, show, hideHeader, hideFooter, small, onClickRow } = this.props;

    const data = tableStore.constructedTable;

    const mappable = {};

    const rows = { ...(data.rows || {}), ...(mappable || {}) };

    const rowCount = Object.keys(rows || {}).length;
    const firstLimit = show * page + 1;
    const lastLimit = Math.min(show * (page + 1), rowCount);
    const ScrollContainer = window.innerWidth < 1680 ? PerfectScrollbar : Layout;

    return (
      <TableContainer>
        {!hideHeader && (
          <TableMeta>
            {firstLimit} bis {lastLimit} von {rowCount}
          </TableMeta>
        )}
        <ScrollContainer style={{ boxShadow: "none" }}>
          <TableOverflowContainer>
            <TableHeaderRow small={small}>
              {data.header.map((item, index) => (
                <TableHeaderItem elemWidth={widths[index]} small={small} key={index} ref={this[`header_${item.key}`]} scale={item.scale}>
                  <HeaderItemText>{item.title}</HeaderItemText>
                  <Icon onClick={() => tableStore.sort(index)}>
                    {tableStore.sortBy === index && tableStore.sortOrder === "ASC" ? "arrow_drop_up" : "arrow_drop_down"}
                  </Icon>
                </TableHeaderItem>
              ))}
            </TableHeaderRow>
            {Object.keys(data.rows).map(row_key => (
              <TableRow onClick={onClickRow ? () => onClickRow(row_key) : () => null} small={small} key={data.rows[row_key][0].key}>
                {data.rows[row_key].map((item, index) => {
                  switch (item.type) {
                  case "key":
                    return null;

                  case "checkbox":
                    return (
                      <TableItem small={small} key={item.key} elemWidth={widths[index]}>
                        <CustomCheckBox
                          id={item.client_id}
                          {...item}
                          store={store}
                          item={item}
                          checked={item.has_access === 1}
                          onClick={e => this.props.onCheckboxClick(e, item, item.has_access === 1)}
                          // onClick={item.action(store, row_key)}
                          client_id={item.client_id}
                        />
                      </TableItem>
                    );

                  case "text":
                  default:
                    return (
                      <TableItem small={small} key={item.key} elemWidth={widths[index]}>
                        <TableItemText small={small}>{item.data}</TableItemText>
                      </TableItem>
                    );
                  }
                })}
              </TableRow>
            ))}
          </TableOverflowContainer>
        </ScrollContainer>
        {hideFooter ? (
          <LineFooter />
        ) : (
          <TableFooter>
            <Pagination {...{ page, rowCount, show, onClickPage: this.onClickPage }} />
          </TableFooter>
        )}
      </TableContainer>
    );
  }
}

export default compose(inject("store"))(CustomTable);
