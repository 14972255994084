import _ from "lodash";
import PromiseStore from "./PromiseStore";
import { decorate, computed, observable, action } from "mobx";
import { observer, inject } from "mobx-react";
import { compose } from "recompose";
import moment from "moment";

class TableStore extends PromiseStore {
  constructor(data, headerAdapter, orderAdapter, rowAdapter, tableKey) {
    super(data);
    let order = orderAdapter;
    try {
      const orderJSON = window.localStorage.getItem(tableKey);
      const parsed = JSON.parse(orderJSON);
      if (parsed) {
        order = parsed;
      }
    } catch (__) {}
    this.adapter = headerAdapter;
    this.orderAdapter = order || orderAdapter;
    this.rowAdapter = rowAdapter;
    this.searchValue = "";
    this.sortBy = null;
    this.sortOrder = "DESC";
    this.selected = {};
    this.sortedHeader = null;
    this.tableKey = tableKey;
  }

  setSorted = header => {
    const newOrder = {};
    Object.keys(this.orderAdapter).forEach(key => {
      const elemIndex = header.findIndex(h => {
        return h.key === this.orderAdapter[key].key;
      });
      const elem = header[elemIndex];
      newOrder[key] = {
        ...elem,
        order: elemIndex,
      };
    });

    this.sortedHeader = header;
    this.orderAdapter = newOrder;

    const newOrderJSON = JSON.stringify(newOrder);
    window.localStorage.setItem(this.tableKey, newOrderJSON);
  };

  select = version => {
    if (this.selected && this.selected[version]) {
      this.selected[version] = false;
    } else {
      this.selected[version] = true;
    }
  };

  clearSelected = () => {
    this.selected = {};
  };

  sort = index => {
    if (this.sortBy === index) {
      if (this.sortOrder === "DESC") {
        this.sortOrder = "ASC";
      } else {
        this.sortOrder = "DESC";
      }
    }
    this.sortBy = index;
  };

  get constructedTable() {
    const header = this.adapter(this.orderAdapter);

    const rows = this.rowAdapter(this.data, this.orderAdapter);

    const constructedRow = {};

    Object.keys(rows).forEach((rowKey, rowIndex) => {
      const rowString = rows[rowKey].reduce((acc, current) => acc + (typeof current.data === "string" ? current.data : ""), "");
      const lastIndex = Object.keys(constructedRow).length;
      if (this.searchValue !== "" && rowString.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1) {
        constructedRow[lastIndex] = rows[rowKey];
      } else if (this.searchValue === "") {
        constructedRow[lastIndex] = rows[rowKey];
      }
    });

    const sorted = {};

    Object.values(constructedRow)
      .sort((a, b) => {
        if (this.sortBy === null) {
          return 0;
        }
        // console.log('aaa iiii bbbbb', a , ' ', b);
        let r1 = a[this.sortBy].data ? a[this.sortBy].data : '';
        let r2 = b[this.sortBy].data ? b[this.sortBy].data : '';

        const number1 = Number(r1);
        const number2 = Number(r2);
        const date1 = moment(r1, 'DD.MM.YYYY');
        const date2 = moment(r2, 'DD.MM.YYYY');
        if(number1 && number2){
          r1=number1;
          r2=number2;
        }
        else if (date1.isValid() && date2.isValid())
        {
          r1 = date1;
          r2 = date2;
        }
        else {
          r1 = (''+r1).toLowerCase();
          r2 = (''+r2).toLowerCase();
        }

        const result = this.sortOrder === "DESC" ? r1 > r2 : r1 < r2;
        return !!result ? 1 : -1;
      })
      .forEach((e, i) => {
        sorted[i] = e;
      });

    return {
      header,
      rows: sorted,
    };
  }

  construct = extra => {
    const header = this.adapter(this.orderAdapter);

    const rows = this.rowAdapter(this.data, this.orderAdapter);

    const len = Object.keys(rows).length;

    extra.forEach((k, i) => {
      rows[len + i] = k;
    });

    const constructedRow = {};

    Object.keys(rows).forEach((rowKey, rowIndex) => {
      const rowString = rows[rowKey].reduce((acc, current) => acc + (typeof current.data === "string" ? current.data : ""), "");
      const lastIndex = Object.keys(constructedRow).length;
      if (this.searchValue !== "" && rowString.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1) {
        constructedRow[lastIndex] = rows[rowKey];
      } else if (this.searchValue === "") {
        constructedRow[lastIndex] = rows[rowKey];
      }
    });

    const sorted = {};

    Object.values(constructedRow)
      .sort((a, b) => {
        if (!this.sortBy) {
          return 0;
        }

        const r1 = a[this.sortBy].data || a[this.sortBy].value || "";
        const r2 = b[this.sortBy].data || b[this.sortBy].value || "";
        const result = this.sortOrder === "DESC" ? r1 > r2 : r1 < r2;
        return !!result ? 1 : -1;
      })
      .forEach((e, i) => {
        sorted[i] = e;
      });
    return {
      header,
      rows: sorted,
    };
  };

  search = e => {
    this.searchValue = e.target.value;
  };
}

export default decorate(TableStore, {
  orderAdapter: observable,
  constructedTable: computed,
  searchValue: observable,
  sortedHeader: observable,
  sortBy: observable,
  sortOrder: observable,
  selected: observable,
  select: action,
  clearSelected: action,
  setSorted: action,
});
