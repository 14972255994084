import styled from "styled-components";
import { COLORS, BORDER_RADIUS, FONTWEIGHT } from "./constants";
import CameraIcon from "../assets/images/camera.png";

export const UserInformation = styled.div`
  width: 100%;
  padding: 45px 75px 0 75px;
  h6 {
    font-family: Sansation;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: 0.1px;
    padding-bottom: 12px;
    margin-bottom: 30px;
    margin-top: 40px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.11);
    text-align: left;
    color: ${COLORS.PINE};
    @media only screen and (max-width: 900px) {
      font-size: 1.1rem;
    }
  }
  @media only screen and (max-width: 1400px) {
    padding: 30px 20px 0 20px;
  }
  @media only screen and (max-width: 1050px) {
    > div {
      flex-flow: column;
      padding-bottom: 30px;
    }
  }
`;
export const PrimaryInfo = styled.div`
  padding-bottom: 30px;
  display: flex;
  > div {
    > div {
      max-height: 40px;
      position: relative;
      @media only screen and (max-width: 700px) {
        max-height: none;
        // margin-bottom: 10px;
        > div {
          flex-flow: column;
          align-items: flex-start;
          label {
            max-width: 100%;
            width: 100%;
            padding-right: 0;
            text-align: left;
          }
          > div {
            width: 100%;
          }
        }
      }
    }
    > label {
      width: 30%;
      max-width: 170px;
      @media only screen and (max-width: 1600px) {
        width: 170px;
      }
    }
  }
  .SetPasswordField {
    display: flex;
    width: 100%;
    > div {
      padding-right: 0;
      > div {
        bottom: -30px;
      }
      &: last-child {
        > div 
        {
          bottom: -16px;
        }
    }
      label {
        line-height: 1;
        letter-spacing: -0.3px;
      }
    }

    @media screen and (max-width: 1270px) {
      > div {
        > div {
          bottom: -40px;
        }
      }
    }
    @media screen and (max-width: 985px) {
      flex-direction: column;
      > div {
        > div {
          bottom: -22px;
        }
      }
  }

  @media screen and (max-width: 585px) {
    > div {
      margin-bottom: 45px;
      > div {
        bottom: -32px;
      }
    }
}
@media screen and (max-width: 500px) {
  > div {
    margin-bottom: 50px;
    > div {
      bottom: -45px;
    }
  }
}
`;
export const DatePickerItem = styled.div`
  width: 100%;
  display: flex;
  > label {
    max-width: 170px;
    width: 38%;
    &.errorStyleDP {
      width: 100% !important;
      padding-bottom: 20px;
      position: relative;
      max-width: 61%;
      height: 65px;
      span.error {
        position: absolute;
        bottom: -16px;
        right: 0;
        width: 100%;
        line-height: 14px;
        white-space: pre-wrap;
      }
    }
  }
  @media only screen and (max-width: 700px) {
    width: auto;
    position: relative;
    > div {
      position: relative;
    }
    .react-datepicker-wrapper {
      width: auto;
    }
    i {
      position: absolute;
      right: 0;
      top: 0;
      width: 36px;
      text-align: center;
      border-left: 2px solid #ccc;
      height: 34px;
      line-height: 31px;
    }
  }
  label {
    font-size: 1rem;
    color: ${COLORS.GREYISH_BROWN_TWO};
    text-align: right;
    padding-right: 20px;
    line-height: 42px;
    letter-spacing: 0.2px;
    span {
      color: ${COLORS.TOMATO};
      font-size: 0.85rem !important;
    }
  }
  @media only screen and (max-width: 700px) {
    height: auto;
    display: flex;
    flex-flow: column;
    label {
      width: 100% !important;
      max-width: 100% !important;
      padding-right: 0;
      text-align: left;
    }
  }
`;
export const DeviatingInvoiceAddress = styled.div`
  width: 100%;
  padding: 0 50px;
  @media only screen and (max-width: 1600px) {
    padding: 0 20px;
  }
  > div {
    width: 100% !important;
    height: auto;
    > label {
      max-width: none !important;
      width: auto !important;
      margin-right: 25px;
    }
  }
  p {
    padding-bottom: 12px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.11);
    width: 100%;
    margin-bottom: 30px;
  }
`;
export const InvoiceAddressForm = styled.div`
  width: 100%;
  > div {
    > div {
      > div {
        position: relative;
      }
    }
  }
  > label {
    width: 30%;
    max-width: 170px;
  }
  @media only screen and (max-width: 1050px) {
    > div {
      flex-flow: column;
      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
    @media only screen and (max-width: 700px) {
      > div {
        > div {
          > div {
            > div {
              flex-flow: column;
              label {
                width: 100%;
                text-align: left;
                max-width: 100%;
                padding-right: 0;
              }
              > div {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
`;
export const LicenseModalSelectionOptions = styled.div`
  width: 100%;
  padding: 60px 50px;
  @media only screen and (max-width: 1600px) {
    padding: 0 20px;
  }
  @media only screen and (max-width: 1400px) {
    > div {
      flex-flow: column;
      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  p {
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    margin-bottom: 40px;
    i {
      font-size: 1rem;
    }
  }
`;
export const AdditionalLicense = styled.div`
  width: 100%;
  margin: 0;
  .disabled{
    border: solid 1px ${COLORS.WHITE};
    border-radius: ${BORDER_RADIUS.BR_1};
    box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.15);
    width:100%;
    padding:10px 11px;
    height:36px;
    max-width:176px;
    font-weight:${FONTWEIGHT.FW_6};
    font-size:0.87rem;
    color:#999;
    opacity:0.4;
    position:relative;
    i{
      position:absolute;
      right:11px;
      top:9px;
    }
  }
  > div {
    margin-bottom: 0;
    > div {
      padding: 0 0 30px 0;
      border: none;
      display: flex;
      align-items: center;
      // > div{
        // &+label+div{
        //   position:relative;
        //   opacity:0.4;
          // &:after{
          //   width:100%;
          //   position:absolute;
          //   left:0;
          //   top:0;
          //   content:"";
          //   z-index:1;
          //   height:100%;
          // }
        // }
        // &.checked{
        //   &+label+div{
        //     opacity:1;
        //   &:after{
        //     display:none;
        //   }
        //   }
        }
      }
      @media only screen and (max-width: 700px) {
        flex-flow: column;
        align-items: flex-start;
      }
      label {
        margin-left: 15px;
        width: 71%;
        @media only screen and (max-width: 700px) {
          // margin: 5px 0 5px 0;
          width: 100%;
        }
      }
    }
  }
  label {
    color: ${COLORS.GREYISH_BROWN_TWO};
    font-size: 1rem;
  }
  select {
    float: right;
    padding: 8px 19px;
    border: 2px solid ${COLORS.WHITE};
    border-radius: ${BORDER_RADIUS.BR_1};
    font-size: 0.9rem;
    font-weight: ${FONTWEIGHT.FW_6};
    color: ${COLORS.GREYISH_BROWN_THREE};
    height: 43px;
    min-width: 177px;
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }
  > div{

  }
`;
export const ActionsOuter = styled.div`
  width: 100%;
  background: transparent;
  padding: 0 0 20px 0;
  text-align:right;
  > div{
    display:inline-block;
    text-align:right;
  }
  button {
    max-width: 227px;
    height: 53px;
    border-radius: ${BORDER_RADIUS.BR_1};
    box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
    // border: 1px solid ${COLORS.TURTLE_GREEN_TWO};
    padding: 11px 20px;
  }
`;
export const MainActionsOuter = styled.div`
  width: 100%;
  background: #f5f5f5;
  padding: 49px 95px;
  text-align:right;
  > div{
    display:inline-block;
    text-align:right;
  }
  button {
    max-width: 227px;
    height: 53px;
    border-radius: ${BORDER_RADIUS.BR_1};
    box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
    // border: 1px solid ${COLORS.TURTLE_GREEN_TWO};
    padding: 11px 20px;
  }
  ${props =>
    props.responsivePadding
      ? `
  padding-left: 20px;`
      : ``}
  @media only screen and (max-width: 1400px) {
    padding: 30px 40px 49px 20px;
  }    
  @media only screen and (max-width: 600px) {
    padding: 30px 20px 49px 20px;
  }  
`;

export const MainActionsInner = styled.div`
  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

export const UserPictureEdit = styled.div`
 width:100%
 margin-bottom:60px;
 padding:0 13%;
 display:inline-block;
 max-height:none !important;
.imageholder{
  width:80px;
  height:80px;
  float:left;
  overflow:hidden;
  border-radius:100%;
  margin-right:20px;
  box-shadow:0px 1px 8px #ddd;
  position:relative;
  &:before{
    width:100%;
    height:100%;
    // background:url(${CameraIcon}) no-repeat center center rgba(0,0,0,0.5);
    content:"";
    position:absolute;
    left:0;
    top:0;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
button{
  margin-top:18px;
  height:40px;
  min-width:143px;
  padding:6px 0;
  position:relative;
  cursor:pointer;
[type='file'] {
  position:absolute;
  left:0;
  top:0;
  width:140px;
  height:40px;
  opacity:0;
  z-index:5;
  cursor:pointer;
}
}
`;
