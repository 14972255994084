import React, { Component } from "react";
import LeftSidebar from "./LeftSidebar";
import { MainContainer } from "../../styled/sidebar";

class Sidebar extends Component {
  render() {
    return (
      <MainContainer>
        <LeftSidebar />
      </MainContainer>
    );
  }
}

export default Sidebar;
