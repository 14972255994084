import { RouterStore } from "mobx-react-router";

import UserStore from "./UserStore";
import TableStore from "./TableStore";
import { endpoints } from "../lib/endpoints";
import ModalStore from "./ModalStore";
import PromiseStore from "./PromiseStore";
import AssetStore from "./AssetStore";
import AssetEditStore from "./AssetEditStore";
import ClientsStore from "./ClientsStore";
import DecliningStore from "./DecliningStore";
import NotificationStore from "./NotificationStore";
import ActivityDateStore from "./ActivityDateStore";
import { clientHeaderAdapter, clientOrderAdapter, clientRowAdapter } from "./adapters/get-all-clients";
import { clientListHeaderAdapter, clientListOrderAdapter, clientListRowAdapter } from "./adapters/client-list";
import { documentHeaderAdapter, documentOrderAdapter, documentRowAdapter } from "./adapters/get-documents";

import { activityHistoryHeaderAdapter, activityHistoryOrderAdapter, activityHistoryRowAdapter } from "./adapters/activity-history";

import { licenseManagementHeaderAdapter, licenseManagementOrderAdapter, licenseManagementRowAdapter } from "./adapters/license-management";
import {
  userAdministrationHeaderAdapter,
  userAdministrationOrderAdapter,
  userAdministrationRowAdapter,
} from "./adapters/user-administration";
import {
  individualClientAccessOrderAdapter,
  individualClientAccessRowAdapter,
  individualClientAccessHeaderAdapter,
} from "./adapters/individual-client-access";
import { userDashboardOrderAdapter, userDashboardRowAdapter, userDashboardHeaderAdapter } from "./adapters/user-dashboard";
import { nextAppointmentOrderAdapter, nextAppointmentRowAdapter, nextAppointmentHeaderAdapter } from "./adapters/next-appointment";
import { licenseSettlementOrderAdapter, licenseSettlementRowAdapter, licenseSettlementHeaderAdapter } from "./adapters/license-settlements";
import { personListingOrderAdapter, personListingRowAdapter, personListingHeaderAdapter } from "./adapters/get-persons";

import { templatesHeaderAdapter, templatesOrderAdapter, templatesRowAdapter } from "./adapters/get-templates";

import { clientTaskHeaderAdapter, clientTaskOrderAdapter, clientTaskRowAdapter } from "./adapters/get-client-tasks";

import { templateRowAdapter, templateHeaderAdapter, templateOrderAdapter } from "./adapters/template";

import { assetsRevisionRowAdapter, assetsRevisionHeaderAdapter, assetsRevisionOrderAdapter } from "./adapters/get-assets-revisions";

import { reportsRowAdapter, reportsHeaderAdapter, reportsOrderAdapter } from "./adapters/get-reports";

import { policyRowAdapter, policyHeaderAdapter, policyOrderAdapter } from "./adapters/get-policen";
import { decliningRevisionRowAdapter } from "./adapters/get-assets-revisions";
import { decliningRevisionOrderAdapter } from "./adapters/get-assets-revisions";
import { decorate, observable, action } from "mobx";

class RootStore {
  constructor() {
    const sidebarOpen = window.localStorage.getItem("@KSQ_SIDEBAR_OPEN");
    this.sidebarOpen = !!sidebarOpen || false;
    this.user = new UserStore(null, null, this);
    this.router = new RouterStore(null, null, this);
    this.clientStore = new ClientsStore(null, null, this);
    this.decliningStore = new DecliningStore(null, null, this);
    this.documents = new TableStore(endpoints.get_documents, documentHeaderAdapter, documentOrderAdapter, documentRowAdapter, "documents");
    this.userTemplates = new TableStore(
      endpoints.get_user_templates,
      templateHeaderAdapter,
      templateOrderAdapter,
      templateRowAdapter,
      "userTemplates",
    );
    this.editDocument = new PromiseStore(endpoints.edit_document, null, this);
    this.searchDocuments = new TableStore(
      endpoints.search_documents,
      documentHeaderAdapter,
      documentOrderAdapter,
      documentRowAdapter,
      "searchDocuments",
    );
    this.searchClients = new ClientsStore(endpoints.search_clients, clientListHeaderAdapter, clientListOrderAdapter, clientListRowAdapter);
    this.modal = new ModalStore(null, null, this);
    this.client = new PromiseStore(endpoints.get_client, null, this);
    this.deleteClient = new PromiseStore(endpoints.delete_client, null, this);
    this.clients = new ClientsStore(endpoints.get_all_clients, clientHeaderAdapter, clientOrderAdapter, clientRowAdapter);
    this.assetModifier = new PromiseStore(endpoints.edit_asset, null, this);
    this.newAsset = new AssetStore(endpoints.new_asset, null, this);
    this.assetCategoryModifier = new PromiseStore(endpoints.edit_asset_category, null, this);
    this.deleteAssetFile = new PromiseStore(endpoints.delete_asset_file, null, this);
    this.deleteAsset = new PromiseStore(endpoints.delete_asset, null, this);
    this.newAssetCategory = new AssetStore(endpoints.new_asset_category, null, this);
    this.newBeneficiary = new AssetStore(endpoints.new_beneficiary, null, this);
    this.newBeneficiaryWithoutAsset = new AssetStore(endpoints.new_beneficiary_without_asset, null, this);
    this.newActivity = new AssetStore(endpoints.new_activity, null, this);
    this.activityHistory = new TableStore(
      endpoints.activity_history,
      activityHistoryHeaderAdapter,
      activityHistoryOrderAdapter,
      activityHistoryRowAdapter,
      "activityHistory",
    );
    this.licenseManagement = new TableStore(
      endpoints.license_management,
      licenseManagementHeaderAdapter,
      licenseManagementOrderAdapter,
      licenseManagementRowAdapter,
      "licenseManagement",
    );
    this.allLicenseManagement = new TableStore(
      endpoints.get_all_licences,
      licenseManagementHeaderAdapter,
      licenseManagementOrderAdapter,
      licenseManagementRowAdapter,
      "allLicenseManagement",
    );
    this.userAdministration = new TableStore(
      endpoints.user_administration,
      userAdministrationHeaderAdapter,
      userAdministrationOrderAdapter,
      userAdministrationRowAdapter,
      "userAdministration",
    );
    this.individualClientAccess = new TableStore(
      endpoints.individual_client_access,
      individualClientAccessHeaderAdapter,
      individualClientAccessOrderAdapter,
      individualClientAccessRowAdapter,
      "individualClientAccess",
    );
    this.licenceCount = new PromiseStore(endpoints.get_licence_count, null, this);
    this.userRegistration = new PromiseStore(endpoints.add_user, null, this);
    this.userDashboard = new TableStore(
      endpoints.user_dashboard,
      userDashboardHeaderAdapter,
      userDashboardOrderAdapter,
      userDashboardRowAdapter,
      "userDashboard",
    );
    this.nextAppointment = new TableStore(
      endpoints.next_appointment,
      nextAppointmentHeaderAdapter,
      nextAppointmentOrderAdapter,
      nextAppointmentRowAdapter,
      "nextAppointment",
    );
    this.licenseSettlement = new TableStore(
      endpoints.license_settlement,
      licenseSettlementHeaderAdapter,
      licenseSettlementOrderAdapter,
      licenseSettlementRowAdapter,
      "licenseSettlement",
    );
    this.userDetials = new PromiseStore(endpoints.get_user, null, this);
    this.licencePurchase = new PromiseStore(endpoints.licence_purchase, null, this);
    this.userSignup = new PromiseStore(endpoints.user_signup, null, this);
    this.updateUser = new PromiseStore(endpoints.update_user, null, this);
    this.forgotPassword = new PromiseStore(endpoints.forgotpassword, null, this);
    this.resetPassword = new PromiseStore(endpoints.resetpassword, null, this);
    this.getClientAgePhases = new PromiseStore(endpoints.client_age_phases, null, this);
    this.addNewClient = new PromiseStore(endpoints.add_new_client, null, this);
    this.changePassword = new PromiseStore(endpoints.change_password, null, this);
    this.clientAutoSearch = new PromiseStore(endpoints.client_auto_search, null, this);
    this.personsListing = new TableStore(
      endpoints.get_persons,
      personListingHeaderAdapter,
      personListingOrderAdapter,
      personListingRowAdapter,
      "personsListing",
    );
    this.updatePerson = new PromiseStore(endpoints.update_persons, null, this);
    this.clientsList = new ClientsStore(endpoints.get_all_clients, clientListHeaderAdapter, clientListOrderAdapter, clientListRowAdapter);
    this.createPerson = new PromiseStore(endpoints.create_persons, null, this);
    this.downloadLicenceInvoice = new PromiseStore(endpoints.download_licence_invoice, null, this);
    this.downloadTemplate = new PromiseStore(endpoints.download_template, null, this);
    this.templates = new TableStore(
      endpoints.get_templates,
      templatesHeaderAdapter,
      templatesOrderAdapter,
      templatesRowAdapter,
      "templates",
    );
    this.clientTask = new TableStore(
      endpoints.get_client_task,
      clientTaskHeaderAdapter,
      clientTaskOrderAdapter,
      clientTaskRowAdapter,
      "clientTask",
    );
    this.createClientNotes = new PromiseStore(endpoints.create_client_notes, null, this);
    this.updateClientNotes = new PromiseStore(endpoints.update_client_notes, null, this);
    this.getClientNotes = new PromiseStore(endpoints.get_client_notes, null, this);
    this.updateAdminRole = new PromiseStore(endpoints.update_admin_role, null, this);
    this.cancelLicence = new PromiseStore(endpoints.cancel_licence, null, this);
    this.updateClients = new PromiseStore(endpoints.update_client, null, this);
    this.getUserFromToken = new PromiseStore(endpoints.get_me, null, this);
    this.getActivityById = new PromiseStore(endpoints.get_activity_by_id, null, this);
    this.updateActivity = new PromiseStore(endpoints.update_activity_by_id, null, this);
    this.assets = new AssetStore(endpoints.get_assets, null, this, (asset) => !asset.deleted);
    this.editingAsset = new AssetStore(endpoints.get_assets, null, this);
    this.declining = new DecliningStore(endpoints.get_last_asset_revision, null, this);
    this.billingAndItems = new PromiseStore(endpoints.getBills, null, this);
    this.getClientExpensesByDates = new PromiseStore(endpoints.getClientExpenses, null, this);
    this.changeActivitiesCalculated = new PromiseStore(endpoints.changeActivitiesCalculated, null, this);
    this.activitiesBetweenDates = new TableStore(
      endpoints.act_between_dates,
      activityHistoryHeaderAdapter,
      activityHistoryOrderAdapter,
      activityHistoryRowAdapter,
      "activitiesBetweenDates",
    );
    this.notificationsStore = new NotificationStore(null, null, this);
    this.personsListingCondition = new TableStore(
      endpoints.get_persons_condition,
      personListingHeaderAdapter,
      personListingOrderAdapter,
      personListingRowAdapter,
      "personsListingCondition",
    );
    this.activityStartVomInfo = new ActivityDateStore(null, null, this);
    this.personsListingSearch = new TableStore(
      endpoints.get_persons_search,
      personListingHeaderAdapter,
      personListingOrderAdapter,
      personListingRowAdapter,
      "personsListingSearch",
    );
    this.assetsRevisions = new TableStore(
      endpoints.get_assets_revisions,
      assetsRevisionHeaderAdapter,
      assetsRevisionOrderAdapter,
      assetsRevisionRowAdapter,
      "assetsRevisions",
    );
    this.decliningRevisions = new TableStore(
      endpoints.get_assets_revisions,
      assetsRevisionHeaderAdapter,
      decliningRevisionOrderAdapter,
      decliningRevisionRowAdapter,
      "decliningRevisions",
    );
    this.assetRevisionCreate = new PromiseStore(endpoints.create_asset_revision, null, this);
    this.allFavouriteClients = new PromiseStore(endpoints.get_fav_clients, null, this);
    this.makeFavouriteClient = new PromiseStore(endpoints.make_fav_client, null, this);
    this.removeFavouriteClient = new PromiseStore(endpoints.remove_fav_client, null, this);
    this.getAllQuestions = new PromiseStore(endpoints.get_all_questions, null, this);
    this.updateQuestions = new PromiseStore(endpoints.update_questions, null, this);
    this.addQuestion = new PromiseStore(endpoints.add_question, null, this);
    this.updateQuestion = new PromiseStore(endpoints.update_question, null, this);
    this.getQuestionsByCategory = new PromiseStore(endpoints.get_questions_by_category, null, this);
    this.createReport = new PromiseStore(endpoints.create_report, null, this);
    this.getReports = new TableStore(endpoints.get_reports, reportsHeaderAdapter, reportsOrderAdapter, reportsRowAdapter, "getReports");
    this.deleteReport = new PromiseStore(endpoints.delete_report, null, this);
    this.deletePolicyFile = new PromiseStore(endpoints.delete_policy_file, null, this);
    this.getPolicies = new TableStore(endpoints.get_policies, policyHeaderAdapter, policyOrderAdapter, policyRowAdapter, "getPolicies");
    this.createPolicy = new PromiseStore(endpoints.create_policy, null, this);
    this.getPolicy = new PromiseStore(endpoints.get_policy, null, this);
    this.downloadPolicy = new PromiseStore(endpoints.download_policy, null, this);
    this.updatePolicy = new PromiseStore(endpoints.update_policy, null, this);
    this.searchPolicies = new TableStore(endpoints.search_policies, policyHeaderAdapter, policyOrderAdapter, policyRowAdapter, "searchPolicies");
    this.updateBeneficiaryAmount = new PromiseStore(endpoints.update_beneficiary_amount, null, this);
    this.createNews = new PromiseStore(endpoints.create_news, null, this);
    this.deleteNews = new PromiseStore(endpoints.delete_news, null, this);
    this.getNews = new PromiseStore(endpoints.get_news, null, this);
    this.updateNews = new PromiseStore(endpoints.edit_news, null, this);
    this.assetEdit = new AssetEditStore(endpoints.get_single_asset_revision, null, this);
    this.activateAccount = new PromiseStore(endpoints.activate_account, null, this);
    this.getRevisionFiles = new PromiseStore(endpoints.get_revision_files, null, this);
    this.clientLicenceCreate = new PromiseStore(endpoints.client_licence_create,null, this);
    this.clientLicenceGet = new PromiseStore(endpoints.client_licence_get, null, this);
    this.clientExtendLicence = new PromiseStore(endpoints.client_licence_extend, null, this);
    this.cancelClientLicence = new PromiseStore(endpoints.client_licence_cancel, null, this);
    this.inactive = { timeout: null };

    this.tab = {};
  }

  async init() {
    // this.test.fetch();
  }

  toggleSidebar = () => {
    this.sidebarOpen = !this.sidebarOpen;

    if (!!this.sidebarOpen) {
      window.localStorage.setItem("@KSQ_SIDEBAR_OPEN", true);
    } else {
      window.localStorage.removeItem("@KSQ_SIDEBAR_OPEN");
    }
  };
  closeSidebar = () => {
    this.sidebarOpen = false;
    if (!!this.sidebarOpen) {
      window.localStorage.setItem("@KSQ_SIDEBAR_OPEN", true);
    } else {
      window.localStorage.removeItem("@KSQ_SIDEBAR_OPEN");
    }
  };
  setTab = data => {
    this.tab = data;
  };
}

export default decorate(RootStore, {
  sidebarOpen: observable,
  toggleSidebar: action,
  closeSidebar: action,
});
