import styled from "styled-components";
import { COLORS } from "./constants";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TableMeta = styled.div`
  border-top: 1px solid ${COLORS.LIGHT_GREY_TWO};
  padding: 24px 34px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${COLORS.GREEN_GREY_THREE};
`;

export const TableHeaderRow = styled.div`
  width: 100%;
  height: ${props => (props.small ? 43 : 60)}px;
  display: flex;
  flex-direction: row;
`;

export const TableHeaderItem = styled.div`
  background-color: ${COLORS.LIGHT_GREY};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: ${props => props.scale || 1};
  padding: 0px ${props => (props.small ? "15" : "22")}px;
  border-top: 1px solid ${COLORS.WHITE_SIX};
  border-right: 1px solid ${COLORS.LIGHT_GREY_TWO};
  width: ${props => props.elemWidth}px;
  &:last-child {
    border-right: none;
  }
  @media only screen and (max-width: 1700px) {
    flex-basis: 0;
    flex-shrink: 3;
  }
`;

export const HeaderItemText = styled.div`
  line-height: 14px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.1px;
  text-align: left;
  color: black;
  margin-right: 10px;
`;

export const TableOverflowContainer = styled.div`
  width: 100%;
  height: 100% !important;
`;
// overflow-x: scroll;
// overflow-y: visible;

export const TableRow = styled.div`
  width: 100%;
  min-height: ${props => (props.small ? 43 : 60)}px;
  display: flex;
  flex-direction: row;
`;

export const TableItem = styled.div`
  background-color: white;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${props => (props.noPadding ? `` : `padding: 5px 22px;`)}
  overflow-wrap: break-word;
  border-top: 1px solid ${COLORS.WHITE_SIX};
  border-right: 1px solid ${COLORS.LIGHT_GREY_TWO};
  min-width: ${props => props.elemWidth}px;
  max-width: ${props => props.elemWidth}px;
  width: ${props => props.elemWidth}px;
  ${props => props.flex && `flex: ${props.flex};`}
  ${props =>
    !props.ignoreLastStyle &&
    `
    &:last-child {
      border-right-color: transparent;
    }
  `}
  .material-icons {
    &.inactive {
      color: ${COLORS.COOL_GREY_TWO};
    }
    &.active {
      color: ${COLORS.NASTY_GREEN};
    }
  }
`;

export const TableItemText = styled.div`
  font-size: ${props => (props.small ? 14 : 15)}px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  ${props => (props.textAlign ? `text-align: ${props.textAlign};` : `text-align: left;`)}
  ${props => (props.color ? `color: ${props.color};` : `color: ${COLORS.GREYISH_BROWN_TWO};`)}
  width: 100%;
  ${props => props.underline && `text-decoration: underline; cursor: pointer;`}
`;

export const TableFooter = styled.div`
  width: 100%;
  min-height: 46px;
  background-color: ${COLORS.LIGHT_GREY};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LineFooter = styled.div`
  width: 100%;
  border-top: 1px solid ${COLORS.LIGHT_GREY_TWO};
  margin-bottom: 60px;
`;

export const PaginationItem = styled.span`
  margin-left: 6px;
  margin-right: 6px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
  text-align: right;
  color: rgb(153, 176, 129);
  display: block;
  margin-top: -3px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: rgb(153, 176, 129);
  }

  ${props =>
    props.selected &&
    `
    font-weight: bold;
    color: rgb(153, 176, 129);
    text-decoration: underline;
  `}
`;

export const TableBlankButton = styled.button`
  box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.18);
  border: solid 1px #d9e5ca;
  background-color: #ffffff;
  border-radius: 3px;
  padding: 5px 20px;
  font-size: 14px;
  box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px var(--turtle-green-two);
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: 0.14px;
  text-align: left;
  color: #4b5a38;
  cursor: pointer;
  padding: 5px;
  text-align: center;
  font-size: 11px;

  @media screen and (max-width: 700px) {
  }
`;

export const ListContainer = styled.div`
  box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px #86ba47;
  display: flex;
  align-items: center;
  background: #86ba47;
  color: white;
  font-size: 15px;
  padding: 3px 7px;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  &:active {
    opacity: 0.9;
  }
  p {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: 0.14px;
    text-align: left;
    color: #ffffff;
  }
  i {
    transition: 0.6s ease-in-out all;
    font-size: 24px;
    ${props =>
    props.open &&
      `
      transform: rotate(180deg);
  `}
  }
`;

export const ListSelector = styled.div`
  position: absolute;
  width: 101px;
  height: 0;
  box-shadow: 0px -1px 21px 0 rgba(0, 0, 0, 0.34);
  background: white;
  border-radius: 4px;
  bottom: 42px;
  right: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  opacit: 0;
  transition: 0.7s ease all;
  align-items: center;
  ${props =>
    props.open &&
    `
    height: 180px;
    opacity: 1;
    padding: 10px;
  `}
`;

export const ListNumber = styled.div`
  font-size: 14px;
  line-height: 1.93;
  letter-spacing: 0.14px;
  // color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.34);
  text-align: center;
  cursor: pointer;
  margin-bottom: 5px;
  &:last-child {
    border-bottom: unset;
  }
  width: 100%;
  ${props =>
    props.selected &&
    `
    box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
    border: solid 1px #86ba47;
    background-color: #8bbf4c;
    border-bottom: unset;
    border-radius: 4px;
    color: white;
  `}
`;
