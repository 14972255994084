import React from "react";
import { Layout } from "../../styled";
import { UserFilterOptions, UserPicture, FilterRight } from "../../styled/clientpersons";

const UserFilter = ({ search, onPressUpload, fullName, imageSource, searchValue, onSearchChange }) => (
  <UserFilterOptions>
    <Layout displayFlex alignCenter>
      <UserPicture>
        <img src={imageSource} alt="user" />
        <h5>{fullName}</h5>
      </UserPicture>
      {search && (
        <FilterRight>
          <input type="search" placeholder="Suche..." value={searchValue || ""} onChange={onSearchChange} />
          <button onClick={onPressUpload}>
            <i className="material-icons">group_add</i>
            Neue Person erstellen
          </button>
        </FilterRight>
      )}
    </Layout>
  </UserFilterOptions>
);

export default UserFilter;
