import React, { Component } from "react";
import { Layout } from "../../styled";
import { UserFilterOptions, FilterRight } from "../../styled/mandant";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import ClientLicence from "../Modal/ClientLicence";
import CreateNewClient from "../Modal/CreateNewClient";
import _ from "lodash";

class UserFilter extends Component {
  componentDidMount() {}

  onPressUpload = () => {
    const {
      store: { modal },
    } = this.props;
    const paymentModelLevel = _.pick(this.props.store.getUserFromToken.data, ["payment_model"]);
    if (paymentModelLevel && paymentModelLevel.payment_model === "new") {
      modal.add({
        title: "Neuen Mandanten erstellen & Lizenz kaufen",
        component: ClientLicence,
      });
    }
    if (paymentModelLevel && paymentModelLevel.payment_model === "old") {
      modal.add({
        title: "Neuen Mandanten erstellen & Lizenz kaufen",
        component: CreateNewClient,
      });
    }
  };

  render() {
    const { search, onChange } = this.props;
    const lt = _.pick(this.props.store.getUserFromToken.data, ["licence_type"]);
    const pm = _.pick(this.props.store.getUserFromToken.data, ["payment_model"]);
    const dontShowButton = lt.licence_type == "user" && pm.payment_model == "new";
    return (
      <UserFilterOptions>
        <Layout displayFlex alignCenter>
          {search && (
            <FilterRight searchFilter>
              <input type="search" placeholder="Suche..." onChange={e => onChange(e)} className="search_head_input" />
              {!dontShowButton && (
                <button onClick={this.onPressUpload} className="search_head_button">
                  Neuen Mandanten erstellen
                </button>
              )}
            </FilterRight>
          )}
        </Layout>
      </UserFilterOptions>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(UserFilter);
