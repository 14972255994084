import React from "react";
import { CustomCheckboxOuetr } from "../../styled/clientaccess";

let checkedArray = [];
class CustomCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: props.checked };
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck(e) {
    if (e.target.checked) {
      checkedArray.push({
        client_id: e.target.id,
        access_status: true,
      });
    } else {
      checkedArray = this.removeByAttr(checkedArray, "client_id", e.target.id);
    }

    this.setState({
      checked: e.target.checked,
    });
    this.props.onClick(checkedArray);
  }

  removeByAttr = function(arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && (arguments.length > 2 && arr[i][attr] === value)) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };

  render() {
    return (
      <CustomCheckboxOuetr>
        <input id={this.props.id} value={this.props.client_id} type="checkbox" checked={this.state.checked} onChange={this.handleCheck} />
        <label htmlFor={this.props.id}></label>
      </CustomCheckboxOuetr>
    );
  }
}

export default CustomCheckbox;
