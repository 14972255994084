import React from "react";
import { EditorInputContainer, EditorInput, EditorFormatBlock } from "../../styled/editor";
import { Layout, Icon } from "../../styled";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { Editor, RichUtils } from "draft-js";
import { getSelectionInlineStyle } from "draftjs-utils";
import { FilesContainer } from "../../styled/assets-table";
import TableFile from "../TableFile";
import config from "../../lib/config";
import Axios from "axios";

class TESEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
  }
  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  _onBoldClick = e => {
    e.preventDefault();
    const newState = RichUtils.toggleInlineStyle(this.props.editorState, "BOLD");
    if (newState) {
      this.onChange(newState);
    }
  };
  _onItalicClick = e => {
    e.preventDefault();
    const newState = RichUtils.toggleInlineStyle(this.props.editorState, "ITALIC");
    if (newState) {
      this.onChange(newState);
    }
  };
  _onUnderlineClick = e => {
    e.preventDefault();
    const newState = RichUtils.toggleInlineStyle(this.props.editorState, "UNDERLINE");
    if (newState) {
      this.onChange(newState);
    }
  };

  _onBlockChange = block => e => {
    e.preventDefault();
    const newState = RichUtils.toggleBlockType(this.props.editorState, block);

    if (newState) {
      this.onChange(newState);
    }
  };

  _toggleBulletPoints = type => e => {
    e.preventDefault();
    const newState = RichUtils.toggleBlockType(this.props.editorState, type);

    if (newState) {
      this.onChange(newState);
    }
  };

  onChange = editorState => this.props.onChange(editorState);

  getBlockStyle = block => {
    switch (block.getType()) {
    case "left":
      return "align-left";
    case "center":
      return "align-center";
    case "right":
      return "align-right";
    case "justify":
      return "align-justify";
    default:
      return null;
    }
  };

  handleFileUpload = e => {
    const {
      store: { activityStartVomInfo },
    } = this.props;
    const files = Array.from(e.target.files);
    activityStartVomInfo.setActivityFiles(files);
  };

  handleDeleteFile = file => async event => {
    event.preventDefault();
    const {
      store: { activityStartVomInfo },
    } = this.props;
    const files = activityStartVomInfo.files;
    const deletedItemIndex = files.indexOf(files.find(item => item.name === file.name));
    files.splice(deletedItemIndex, 1);
    activityStartVomInfo.setActivityFiles(files);
  };

  render() {
    const {
      editorState,
      store: { activityStartVomInfo },
    } = this.props;
    const currentSelectionStyle = editorState ? getSelectionInlineStyle(editorState) : {};
    return (
      <Layout>
        <React.Fragment>
          <EditorInputContainer>
            <EditorInput>
              <Editor
                style={{ backgroundColor: "white" }}
                editorState={editorState}
                onChange={this.onChange}
                handleKeyCommand={this.handleKeyCommand}
                blockStyleFn={this.getBlockStyle}
                placeholder="Notiz (keine Zeichenbegrenzung)"
              />
            </EditorInput>
          </EditorInputContainer>
          <Layout displayFlex justifyStart>
            <EditorFormatBlock>
              <Icon selected={currentSelectionStyle.BOLD} onMouseDown={this._onBoldClick}>
                format_bold
              </Icon>
              <Icon selected={currentSelectionStyle.ITALIC} onMouseDown={this._onItalicClick}>
                format_italic
              </Icon>
              <Icon selected={currentSelectionStyle.UNDERLINE} onMouseDown={this._onUnderlineClick}>
                format_underlined
              </Icon>
            </EditorFormatBlock>
            <EditorFormatBlock>
              <Icon onMouseDown={this._onBlockChange("left")}>format_align_left</Icon>
              <Icon onMouseDown={this._onBlockChange("center")}>format_align_center</Icon>
              <Icon onMouseDown={this._onBlockChange("right")}>format_align_right</Icon>
              <Icon onMouseDown={this._onBlockChange("justify")}>format_align_justify</Icon>
            </EditorFormatBlock>
            <EditorFormatBlock>
              <Icon onMouseDown={this._toggleBulletPoints("unordered-list-item")}>format_list_bulleted</Icon>
              <Icon onMouseDown={this._toggleBulletPoints("ordered-list-item")}>format_list_numbered</Icon>
            </EditorFormatBlock>
            <EditorFormatBlock>
              <Layout>
                <label for="file-input">
                  <Icon>attach_file</Icon>
                </label>
                <input id="file-input" type="file" multiple style={{ display: "none" }} onChange={this.handleFileUpload} />
              </Layout>
            </EditorFormatBlock>
          </Layout>
        </React.Fragment>
        {activityStartVomInfo.files.length ? (
          <Layout background="white" spacingLeft={10} spacingTop={10} spacingRight={10}>
            <FilesContainer>
              {activityStartVomInfo.files.map(file => (
                <TableFile
                  download={file.name}
                  href={`${file.src}`}
                  target="_blank"
                  title={file.name}
                  onClick={this.handleDeleteFile(file)}
                />
              ))}
            </FilesContainer>
          </Layout>
        ) : (
          <React.Fragment />
        )}
      </Layout>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(TESEditor);
