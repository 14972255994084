import React, { Component } from "react";
import { LineBreak, UserPicture } from "../../../../styled/sidebar";
import { LogoutButton } from "../../../../styled/auth";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { routes } from "../../../../routes/routes";
import { browserHistory } from "../../../../routes/routes";
import config from "../../../../lib/config";
import { TIMER_ID as APP_TIMER_ID } from "../../../Activity/Appointment";
import { TIMER_ID as CORR_TIMER_ID } from "../../../Activity/Correspondence";
import { TIMER_ID as RES_TIMER_ID } from "../../../Activity/Research";
import { TIMER_ID as REV_TIMER_ID } from "../../../Activity/Review";
import { TIMER_ID as SETT_TIMER_ID } from "../../../Activity/Settlement";

class UserInfo extends Component {
  handleSubmit = () => {
    try {
      window.localStorage.setItem(config.keys.local_storage_key, "");
      window.localStorage.setItem("profile_image_url", " ");
      window.localStorage.removeItem(config.keys.local_storage_key);
      window.localStorage.removeItem(config.keys.logout_timeout_key);
      [APP_TIMER_ID, CORR_TIMER_ID, RES_TIMER_ID, REV_TIMER_ID, SETT_TIMER_ID].forEach(t => {
        window.localStorage.removeItem(t);
      });
      browserHistory.replace(routes.LOGIN);
      browserHistory.go();
    } catch (error) {
      console.log("error", error);
    }
  };
  render() {
    const {
      open,
      store: {
        user: { currentUser },
      },
    } = this.props;

    if (!currentUser) {
      return null;
    }

    return (
      <React.Fragment>
        <div style={{ minHeight: "150px", width: "100%" }}>
          <UserPicture className={open ? "expanded" : "collapsed"} open={open}>
            <a href="/my-overview">
              <img src={`${config.endpoints.api}/${currentUser.profile_image_url}`} alt="" />
            </a>
            <h3>{`${currentUser.first_name} ${currentUser.last_name}`}</h3>
            <span>{currentUser.licence_type === "user" ? "Benutzer" : "Administrator"}</span>
            <LogoutButton onClick={this.handleSubmit} type="submit">
              <i className="material-icons">power_settings_new</i> Logout
            </LogoutButton>
          </UserPicture>
          <LineBreak />
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(UserInfo);
