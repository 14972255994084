import styled from "styled-components";
import { COLORS } from "./constants";

export const NewItemSearchContainer = styled.div`
  height: 58px;
  box-shadow: 0px 1px 7px 0 rgba(31, 42, 16, 0.16);
  border: solid 2px ${COLORS.WHITE_SEVEN};
  width: 100%;
  max-width: 910px;
  border-radius: 3px;
  padding: 0 22px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NewItemSearchInput = styled.input`
  font-size: 17px;
  height: 100%;
  line-height: 58px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.59;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${COLORS.GREY_GREEN};
  flex: 1;
  border: none;
  padding-left: 20px;
  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

export const NewItemResultsContainer = styled.div`
  box-shadow: 0px 1px 7px 0 rgba(31, 42, 16, 0.16);
  border: solid 1px ${COLORS.WHITE_SEVEN};
  width: 100%;
  max-width: 910px;
  border-radius: 3px;
  background-color: ${COLORS.LIGHT_GREY_TEN};
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  transform: translateY(-50px);
  ${props =>
    props.open &&
    `
    transform: translateY(0px);
    opacity: 1;
    pointer-events: all;
  `}
`;

export const NewItemResultsStatic = styled.div`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: solid 1px ${COLORS.WHITE_SEVEN};
  background-color: white;
  overflow-y: scroll;
  z-index: 0;
  //   -ms-overflow-style: none;  // IE 10+
  //   scrollbar-width: none;  // Firefox
  //   ::-webkit-scrollbar {
  //     display: none;  // Safari and Chrome
  // }
  max-height: 116px;
`;

export const NewItemResult = styled.span`
  min-height: 58px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${COLORS.PINE};
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.1s ease;
  ${props =>
    props.onClick &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.WHITE_SEVEN};
    }
  `}
`;
