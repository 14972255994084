import React, { Component } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { Layout, P } from "../../styled";
import { Speichem } from "../../styled/buttons";
import Radio from "../Form/Radio";
import { RoyaltyStatementOptions, RightOptions, SelectedBillingPeriod } from "../../styled/administration";

class UserFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
    };
  }
  changeValue = async index => {
    this.setState({ selectedIndex: index });
  };

  componentDidMount() {
    const {
      store: { userDetials },
    } = this.props;
    userDetials.fetch();
    //this.setState({sele})
  }

  handleSubmit = async () => {
    const {
      store: { updateUser, userDetials },
    } = this.props;
    const data = {
      payment_mode: this.state.selectedIndex,
    };
    await updateUser.fetch(data);
    userDetials.fetch();
  };

  render() {
    const {
      store: { userDetials },
    } = this.props;

    if (userDetials.loading || !userDetials.data) {
      return null;
    }

    return (
      <React.Fragment>
        <RoyaltyStatementOptions>
          <Layout displayFlex alignCenter>
            <P type="type-10">Lizenzabrechnung</P>
          </Layout>
          <SelectedBillingPeriod>
            <Layout displayFlex alignLeft>
              <P type="type-3_1">Abrechnungszeitraum wählen : </P>
              <P type="type-4">
                <Radio options={["Jährlich", "Monatlich"]} selectedIndex={userDetials.data.payment_mode} onClick={this.changeValue} />
              </P>
            </Layout>
            <RightOptions>
              <Speichem onClick={this.handleSubmit}>Ändern</Speichem>
            </RightOptions>
          </SelectedBillingPeriod>
        </RoyaltyStatementOptions>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(UserFilter);
