import React from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import AddNewCatalogEntry from "../Modal/AddNewCatalogEntry";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Layout } from "../../styled";
import { AssetTableButton } from "../../styled/buttons";
import { TableFooterTextArea } from "../../styled/assets-table";

class AssetEditFooter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
    };
  }

  handleChange = e => this.setState({ value: e.target.value });

  onAddAsset = () => {
    const {
      store: { modal, assetEdit, assetCategory, clientStore },
    } = this.props;

    modal.add({
      title: "Neuen Katalogeintrag erstellen",
      component: AddNewCatalogEntry,
      props: {
        activeStore: assetEdit,
        onSubmit: assetEdit.onAddAsset,
      },
    });
  };
  render() {
    const { store } = this.props;
    const { value } = this.state;
    return (
      <PerfectScrollbar>
        <Layout fullWidth displayFlex row spacingTop={40}>
          <Layout flex={1} displayFlex>
            <TableFooterTextArea value={value} onChange={this.handleChange} placeholder="Kommentar für Änderung" />
          </Layout>
          <Layout flex={1} displayFlex alignEnd justifyEnd>
            <AssetTableButton
              style={{
                height: 45,
                marginLeft: 20,
                width: 195,
                backgroundColor: "#a7acb1",
                boxShadow: "0px 1px 6px 0 rgba(0, 0, 0, 0.18)",
                borderColor: "#9fa6ad",
              }}
              onClick={store.assetEdit.onClear}
            >
              Verwerfen
            </AssetTableButton>
            <Layout>
              <AssetTableButton
                style={{
                  height: 45,
                  marginLeft: 20,
                  marginBottom: 25,
                  width: 195,
                }}
                onClick={this.onAddAsset}
              >
                Eintrag ergänzen
              </AssetTableButton>
              <AssetTableButton style={{ height: 45, marginLeft: 20, width: 195 }} onClick={store.assetEdit.onSaveRevision(value)}>
                Speichern
              </AssetTableButton>
            </Layout>
          </Layout>
        </Layout>
      </PerfectScrollbar>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(AssetEditFooter);
