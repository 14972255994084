import React from "react";
import withPageWrapper from "./Page";
import _ from "lodash";

import { Tab, TabContainer, TabSlider } from "../styled/tabs";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

const withTabsWrapper = tabs => () => {
  class TabsWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        activeIndex: 0,
      };

      this.tabs = tabs.map(() => React.createRef());

      this.active = {
        left: 0,
        width: 0,
      };
    }
    componentDidMount() {
      const { store } = this.props;

      if (this.props.activeIndex) {
        this.setTab(this.props.activeIndex)();
        return;
      }

      store.setTab(this.setTab);
      const { activeIndex } = this.state;
      const activeRef = this.tabs[activeIndex].current;

      this.setState({
        left: activeRef.offsetLeft,
        width: activeRef.offsetWidth,
      });
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.activeIndex !== this.props.activeIndex) {
        this.setTab(nextProps.activeIndex)();
      }
    }

    setTab = index => () => {
      const activeRef = this.tabs[index].current;

      this.setState({
        activeIndex: index,
        left: activeRef.offsetLeft,
        width: activeRef.offsetWidth,
      });
    };

    setTabWithProps = index => () => {
      if (this.props.onTabChange) {
        this.props.onTabChange(index);
      } else {
        this.setTab(index)();
      }
    };

    render() {
      const { activeIndex } = this.state;
      const { store, afterTabContentChange, changeTabContent } = this.props;
      const activatedIndex = activeIndex === 4 && !store.assetEdit.REVISION_ID ? 1 : activeIndex;
      let Rendered = tabs[activatedIndex].hideWrapper
        ? tabs[activatedIndex].component
        : withPageWrapper(tabs[activatedIndex].pageTitle)(tabs[activatedIndex].component);

      if (changeTabContent && afterTabContentChange) {
        Rendered = () => null;
        setTimeout(() => {
          afterTabContentChange();
        }, 0);
      }

      const Footer = tabs[activatedIndex].footer;

      return (
        <div>
          <TabContainer>
            {tabs.map((tab, index) => {
              if (tab.key === "edit_assets" && !store.assetEdit.REVISION_ID) {
                return null;
              }
              if (tab.key === "news" && _.get(store, "getUserFromToken.data.email", "") !== "info@innovation-wist.de") {
                return null;
              }
              return (
                <Tab selected={index === activatedIndex} ref={this.tabs[index]} key={tab.key} onClick={this.setTabWithProps(index)}>
                  {tab.title}
                </Tab>
              );
            })}
            <TabSlider left={this.state.left} width={this.state.width} />
          </TabContainer>
          <Rendered />
          {Footer && <Footer />}
        </div>
      );
    }
  }

  return compose(
    inject("store"),
    observer,
  )(TabsWrapper);
};

export default withTabsWrapper;
