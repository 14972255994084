import styled from "styled-components";
import SearchIcon from "../assets/images/search.svg";
import { COLORS, BORDER_RADIUS } from "./constants";
import Logo from "../assets/images/logo.PNG";

export const MainContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin: auto;
  display: flex;
  height: 100%;
`;
export const SidebarWrapper = styled.div`
  width: 348px;
  max-width: 348px;
  box-shadow: 1px 0 18px 0 rgba(0, 0, 0, 0.05);
  background: ${COLORS.OFF_WHITE};
  padding: 10px 0 0 0;
  height: 100%;
  min-height: 100vh;
  transition: all 0.5s ease;
  z-index: 500;
  position: fixed;
  overflow-y: scroll;
  @media screen and (max-width: 1440px) {
    overflow-y: scroll;
    overflow-x: hidden;
    top: 0;
    bottom: 0;
  }

  &.collapsed {
    width: 120px;
    overflow-y: scroll;

    > div {
      &:first-child {
        margin-left: 13px;
        width: 72px;
        overflow: hidden;
        img {
          max-width: 300%;
        }
      }
    }
    // .suggestions{
    //   opacity:0;
    //   visibility:hidden;
    // }
    [type="search"] {
      background-position: 20px 50%;
      &:focus {
        width: 290px;
        background-color: #fff;
        & + .suggestions {
          width: 290px;
          opacity: 1;
          visibility: visible;
          box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.09);
          border: solid 1px ${COLORS.WHITE_FOUR};
        }
      }
    }
    a {
      padding-left: 0;
      padding-right: 0;
      transition: all 0.5s ease;
      position: relative;
      > i {
        margin: auto;
      }
      > span {
        position: absolute;
        background: #fff;
        left: 130px;
        border-radius: ${BORDER_RADIUS.BR_1};
        color: ${COLORS.NASTY_GREEN_TWO};
        visibility: hidden;
        opacity: 0;
        font-size: 11px;
        padding: 3px 10px;
        letter-spacing: -0.3px;
        width: 100%;
        white-space: nowrap;
        text-align: center;
        top: 10px;
        box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.17);
        &:before {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 6px 6px 0;
          border-color: transparent #fff transparent transparent;
          position: absolute;
          left: -6px;
          top: 9px;
        }
      }
      &:hover {
        span {
          opacity: 1;
          visibility: visible;
          transition: all 0.5s ease;
        }
      }
    }
    @media screen and (max-width: 768px) {
      max-width: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &.expanded {
    width: 348px;
    .suggestions {
      width: 290px;
    }
  }
  .__react_component_tooltip.type-light {
    color: rgb(118, 164, 63);
    box-shadow: 0 0 4px #0800003b;
    background-color: #fff;
    font-size: 12px;
    opacity: 1;
    letter-spacing: -0.3px;
    box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.17);
    white-space: nowrap;
    text-align: center;
  }
`;

export const SidebarPlaceholder = styled.div`
  width: 348px;
  max-width: 348px;
  padding: 10px 0 0 0;
  height: 100%;
  min-height: 100vh;
  z-index: 500;
  position: relative;
  transition: all 0.5s ease;

  @media screen and (max-width: 1440px) {
    position: unset;
  }
  &.collapsed {
    width: 120px;
    @media screen and (max-width: 768px) {
      width: 60px;
      max-width: 60px;
    }
  }
  &.expanded {
    width: 348px;
    .suggestions {
      width: 290px;
    }
    @media screen and (max-width: 1440px) {
      width: 120px;
    }
    @media screen and (max-width: 768px) {
      width: 60px;
      max-width: 60px;
    }
  }
`;
export const ColumnRight = styled.div`
  width: 80%;
  height: 100%;
`;
export const UserTopTabs = styled.div`
  width: 100%;
  margin-bottom: 26px;
`;
export const UserInfoBlock = styled.div`
  width: 100%;
  box-shadow: 0px 1px 18px 0 rgba(70, 84, 84, 0.2);
  border: 1px solid ${COLORS.LIGHT_GREY_TWO};
  background: #fff;
`;
export const UserPicture = styled.div`
  display:inline-block;
  width:100%;
  padding:0 30px 28px 30px;
  > a{
    width:80px;
    height:80px;
    border-radius:100%;
    display:inline-block;
    object-fit:cover;
    float:left;
    margin-right:25px;
  }
  img {
    margin-right:25px;
    float:left;
    border-radius:100%;
    object-fit: cover;
    max-height: 80px;
    min-height: 80px;
    width: 80px;
  }
  h3 {
    color:color:${COLORS.GREYISH_BROWN_TWO};
    font-size:1.12rem;
    font-weight:600;
    margin-top:10px;
    margin-bottom:10px;
  }
  span {
    display:block;
    font-size:0.87rem;
    font-weight:normal;
    color: ${COLORS.WARM_GREY};
  }
&.collapsed{
  padding:0 12px 15px 12px;
  text-align:center;

  img{
    width:55px;
    height:55px;
    min-width:55px;
    min-height: 55px;
    margin-right:0;
    float:none;
  }
  h3{
    font-size:12px;
    margin-top:10px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    letter-spacing:-0.5px;
    margin-bottom:5px;
  }
  span{
    font-size:12px;
    @media screen and (max-width: 768px) {
      font-size: 9px;
    }
  }
  @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin: 0;
    }
    h3 {
      display: none;
    }
    a {
      margin-right: 0;
    }
    
  }
}
  `;
export const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.WHITE_THREE};
  margin: 10px 0;
`;
export const Hamburger = styled.div`
  padding: 0 28px 37px 30px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    img {
      opacity: 0.8;
    }
  }
  img {
    margin-right: 33px;
    transition: all 0.2s ease;
  }
  i {
    font-size: 1.1rem;
    color: ${COLORS.GREYISH_BROWN_TWO};
    letter-spacing: 0.2px;
    margin-top: 3px;
  }
  &.collapsed {
    @media screen and (max-width: 768px) {
      width: unset;
      padding-left: 0;
      padding-right: 0;
      img {
        margin: 0;
      }
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
`;
export const Sidemenugroup = styled.div`
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  a {
    display: flex;
    width: 100%;
    padding: 15px 28px 15px 30px;
    color: ${COLORS.DARK_SAGE};
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 24px;
    i {
      margin-right: 24px;
      font-size: 1.375rem;
      width: 22px;
      direction: rtl;
      transition: all 0.8s ease;
    }
    &:hover {
      ${props =>
    props.disabled
      ? ``
      : `
              color: ${COLORS.NASTY_GREEN_TWO};
              box-shadow: 0px 1px 13px 0 rgba(0, 0, 0, 0.17);
              background: #fff;
              i {
                color: ${COLORS.NASTY_GREEN_TWO};
              }
            `}
    }
    &.activeRoute {
      min-width: 60px;
      ${props =>
    props.disabled
      ? ``
      : `
      color: ${COLORS.NASTY_GREEN_TWO};
      box-shadow: 0px 1px 13px 0 rgba(0, 0, 0, 0.17);
      background: #fff;
      i {
        color: ${COLORS.NASTY_GREEN_TWO};
      }
                  `}
    }
  }
  &.collapsed {
    a {
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      align-items: center;
      position: relative;
      i {
        margin: auto;
      }
      span {
        position: absolute;
        right: 0;
        background: #fff;
        color: ${COLORS.NASTY_GREEN_TWO};
        border-radius: ${BORDER_RADIUS.BR1};
        box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.17);
      }
    }
  }
`;
export const SearchBox = styled.div`
  width: 100%;
  padding: 0 28px 0 30px;
  margin-bottom: 25px;
  margin-top: 30px;
  position: relative;
  z-index: 50;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
  [type="search"] {
    width: 100%;
    -webkit-appearance: push-button;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.09);
    border: solid 1px ${COLORS.WHITE_FOUR};
    height: 49px;
    padding: 15px 14px 15px 46px;
    background: url(${SearchIcon}) no-repeat 10px 50%;
    background-size: 24px 24px;
    letter-spacing: 0.2px;
    font-family: Sansation;
    font-size: 0.93rem;
    color: ${COLORS.SAGE};
    &::-webkit-input-placeholder {
      color: ${COLORS.SAGE};
      opacity: 1;
      letter-spacing: 0.2px;
      font-family: Sansation;
      font-size: 0.93rem;
    }
    &::-moz-placeholder {
      color: ${COLORS.SAGE};
      opacity: 1;
      letter-spacing: 0.2px;
      font-family: Sansation;
      font-size: 0.93rem;
    }
  }
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }

  .suggestions {
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    -ms-overflow-style: none; // IE 10+
    scrollbar-width: none; // Firefox
    ::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
    background: #fff;
    padding-left: 0;
    width: calc(274px + 1rem);
    position: absolute;
    top: 48px;
    left: 30px;
  }

  .suggestions li {
    padding: 12px 28px;
    color: #444;
    font-size: 0.9rem;
  }

  .suggestion-active,
  .suggestions li:hover {
    background-color: ${COLORS.TURTLE_GREEN};
    color: #fff;
    cursor: pointer;
    font-weight: 700;
  }

  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #fff;
  }
`;

export const SidebarTitleController = styled.div`
  transition: all 0.1s ease;
  opacity: 0;
  transform: translateX(-10px);
  ${props =>
    props.open &&
    `
    opacity: 1;
    transform: translateX(0px);
  `}
  &.collapsed {
    display: none;
  }
`;
export const LogoHolder = styled.div`
  width: 86%;
  text-align: left;
  margin: 0 7%;
  height: 60px;
  margin-bottom: 13px;
  background: url(${Logo}) no-repeat 0 0;
  background-size: 180px auto;
  img {
    display: none;
  }
`;
