import styled from "styled-components";
import { COLORS, BORDER_RADIUS, FONTWEIGHT } from "./constants";

export const ActionMessage = styled.div`
  ${props => {
    switch (props.message) {
    case "success":
      return `
      background: ${COLORS.NASTY_GREEN_TWO};
      color:${COLORS.LIGHT_GREY_THIRTEEN};
      border:1px solid ${COLORS.SAGE};
    `;
    case "error":
    default:
      return `
      background: ${COLORS.LIGHT_TAN};
      color:${COLORS.DUSTY_ORANGE};
      border:1px solid ${COLORS.VERY_LIGHT_PINK};
    `;
    }
  }};
  padding: 21px 31px;
  margin: 20px 0;
  text-align: left;
  font-size: 1.03rem;
  font-weight: ${FONTWEIGHT.FW_6};
  border-radius: ${BORDER_RADIUS.BR_1};
  line-height: 25px;
  i {
    margin-right: 10px;
    float: left;
    @media only screen and (max-width: 600px) {
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 600px) {
    font-size: 13px;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    line-height: 15px;
  }
`;
