import React, { Component } from 'react';

class Accordion extends Component {
  render() {
    const { titleComponent, open, children, onClickHeader } = this.props;
    return (
      <div>
        <div onClick={onClickHeader}>
          {titleComponent()}
        </div>
        {open && children}
      </div>
    );
  }
}

export default Accordion;
