import React from 'react';
import Axios from 'axios';
import { compose } from 'recompose';
import { observer, inject } from 'mobx-react';
import * as Yup from 'yup';

import { ModalFooterOuter } from "../../../styled/modal";
import { Button } from '../../../styled/buttons';
import { Layout, FormOuter } from '../../../styled';
import FormInput from '../../Form/Input';
import config from '../../../lib/config';
import FormSelect from "../../../components/Form/InputSelect";
import document_categories from '../../../seeds/document_categories';

class UploadDokument extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      document_name: '',
      document_category: '',
      document_notes: '',
      docFile: null,
      errors: {},
      fields: {},
      uploading: false,
    };

    this.upload = React.createRef();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  addCategory = (category) => {
    this.setState({
      document_category: category
    });
  }

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    const file = this.upload.current.files[0];
    const schema = Yup.object().shape({
      document_name: Yup.string().required(requiredError),
      document_category: Yup.string().required(requiredError),
    });

    try {
      schema.validateSync(fields, { abortEarly: false });
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(e => {
          errors[e.path] = e.message;
        });
      }
    }

    formIsValid = schema.isValidSync(fields);

    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (this[key]) {
        this[key].focus();
      }
    }

    if (!file) {
      formIsValid = false;
      errors["docFile"] = requiredError;
    }
    if (file && file.type.split('/').length <= 1) {
      formIsValid = false;
      errors["docFile"] = 'Das Dokumentenformat wird nicht unterstützt, oder ist nicht zulässig. Bei weiteren Fragen wenden Sie sich bitte an den Protevos Support';
    }

    this.setState({ errors: errors });
    return formIsValid;

  }

  submit = () => {

    const {
      document_name,
      document_category,
      document_notes,
      uploading,
    } = this.state;
    const file = this.upload.current.files[0];

    if (!this.handleValidation()) {
      return false;
    }


    const token = window.localStorage.getItem(config.keys.local_storage_key); 
    let fileTypeIs = file.type.split('/')[1].toUpperCase();
    if(file.name.includes(".docx")){
      fileTypeIs = 'MSWORD';
    }
    const body = new FormData();
    body.set('document_name', document_name);
    body.set('document_category', document_category);
    body.set('document_notes', document_notes);
    body.set('client_id', this.props.store.client.data.client_id);
    body.set('file_type', fileTypeIs);
    body.append('docFile', file);

    Axios({
      method: 'POST',
      url: config.endpoints.api + '/api/document/upload',
      data: body,
      headers: { 'Content-Type': 'multipart/form-data', Authorization: `${token}` }
    }).then(() => {
      this.props.store.documents.fetch({
        client_id: this.props.store.client.data.client_id
      });
      this.props.closeModal();
    });
    this.setState({
      uploading: true
    });
  }

  render() {
    const { closeModal } = this.props;
    const { uploading } = this.state;
    return (
      <React.Fragment>
        <Layout spacingTop={40} fullWidth spacingRight={40} style={{ position: 'relative'}}>
          {/* {uploading && 
          <Layout style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'red'}}>
          uploada se sineee
          </Layout>
          } */}
          <FormOuter>
            <FormInput required onChange={this.handleChange} name="document_name" error={this.state.errors["document_name"]} label="Dokumentenname" />
            <FormSelect
              required
              noAdapter
              label="Kategorie"
              onChange={this.addCategory}
              options={document_categories.map(a => a)}
              error={this.state.errors["document_category"]}
            />
            <FormInput onChange={this.handleChange} name="document_notes" label="Notizen" />
            <FormInput ref={this.upload} onChange={this.handleChange} name="docFile" type="file" label="Datei" error={this.state.errors["docFile"]} />
            {/* <span>{this.state.errors["docFile"]}</span> */}
          </FormOuter>
        </Layout>
        <ModalFooterOuter>
          <Button onClick={closeModal} color="grey">
            Abbrechen
          </Button>
          <Button onClick={!uploading && this.submit} color="green">
            {uploading ? "Ihre Datei wird verarbeitet" : "Speichern" }
          </Button>
        </ModalFooterOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject('store'),
  observer
)(UploadDokument);
