import React, { Component } from "react";
import { compose } from "recompose";
import withTabsWrapper from "../hoc/Tabs";
import Mandates from "../components/Mandates";
import MandantenInformation from "../routes/MandantenInformation";
import ClientPersons from "../routes/PersontoClient";
import { inject, observer } from "mobx-react";
import { autorun } from "mobx";

const TABS_WITH_COMPONENTS = [
  {
    key: "tab_1",
    // pageTitle: "Mandanten Dashboard",
    pageTitle: "Mandanten Übersicht",
    // title: "Mandanten Dashboard",
    title: "Mandanten Übersicht",
    component: Mandates,
    hash: "#dashboard",
  },
  {
    key: "tab_2",
    pageTitle: "Mandanten Stammdaten",
    title: "Mandanten Stammdaten",
    component: MandantenInformation,
    hash: "#stammdaten",
  },
  {
    key: "tab_3",
    pageTitle: "Personen oder Institutionen zum Mandanten",
    title: "Personen zu Mandanten",
    component: ClientPersons,
    hash: "#persons",
  },
];

const CWith = withTabsWrapper(TABS_WITH_COMPONENTS)();

class MandantenDashboardWithTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: this.getActiveIndexByHashtag(),
      selectedClientId: props.store.clientStore.selectedClientId,
      changeTabContent: false,
    };
  }
  componentDidMount() {
    this.oldSelectedClientId = this.props.store.clientStore.selectedClientId;
    this.autorun = autorun(this.onStoreUpdate);
  }

  onStoreUpdate = () => {
    const newClientId = this.props.store.clientStore.selectedClientId;

    if (newClientId !== this.state.selectedClientId) {
      this.setState({
        selectedClientId: newClientId,
        changeTabContent: true,
      });
    }
  };

  getActiveIndexByHashtag = () => {
    const indexOfHashtag = TABS_WITH_COMPONENTS.findIndex(t => t.hash === this.props.history.location.hash);
    return indexOfHashtag >= 0 ? indexOfHashtag : 0;
  };

  onTabChange = indexOfTab => {
    window.location.hash = TABS_WITH_COMPONENTS[indexOfTab].hash;
    this.setState({
      activeIndex: indexOfTab,
    });
  };
  // this.props.store.clientStore.selectedClientId

  render() {
    this.getActiveIndexByHashtag();
    return (
      <CWith
        activeIndex={this.state.activeIndex}
        afterTabContentChange={() => {
          this.setState({ changeTabContent: false });
        }}
        changeTabContent={this.state.changeTabContent}
        onTabChange={this.onTabChange}
      />
    );
  }
}

export default compose(
  observer,
  inject("store"),
)(MandantenDashboardWithTabs);
