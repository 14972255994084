import styled from "styled-components";
import { COLORS, FONTWEIGHT } from "./constants";

export const TotalClientsOuter = styled.div`
  @media only screen and (max-width: 768px) {
    text-align: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    div {
      h5 {
        font-size: 0.7rem;
        margin-top: 10px;
        margin-right: 35px;
      }
      h6 {
        font-size: 0.7rem;
        text-align: center;
        margin-top: 7px;
      }
      h5 i {
        position: inherit;
      }
      h6 i {
        position: inherit;
      }
    }
  }
  width: 100%;
  background: ${COLORS.LIGHT_GREY_EIGHTEEN};
  padding: 38px 58px;
  border: 1px solid ${COLORS.WHITE_SIX};
  h5 {
    width: 50%;
    font-weight: ${FONTWEIGHT.FW_6};
    font-size: 1rem;
    color: ${COLORS.GREYISH_BROWN_TWO};
    span {
      color: ${COLORS.AVOCADO_GREEN};
    }
    i {
      margin-right: 5px;
      font-size: 1.8rem;
      color: ${COLORS.AVOCADO_GREEN};
      position: relative;
      top: 8px;
    }
  }
  h6 {
    text-align: right;
    width: 50%;
    font-weight: ${FONTWEIGHT.FW_6};
    font-size: 1rem;
    color: ${COLORS.GREYISH_BROWN_TWO};
    span {
      color: ${COLORS.AVOCADO_GREEN};
    }
    i {
      margin-right: 5px;
      font-size: 1.8rem;
      color: ${COLORS.AVOCADO_GREEN};
      position: relative;
      top: 8px;
    }
  }
`;
export const GraphImg = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
  #container {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    width: 500px;
    height: 500px;
    background-color: #fff;
  }
  .recharts-wrapper {
    // border-bottom:1px solid #e6e6e6;
    margin-top: 30px;
    svg {
      text {
        background: blue;
        padding: 4px;
        color: red;
        font-size: 14px;
        border: 1px solid #ccc;
      }
    }
  }
`;
export const AppointmentOuter = styled.div`
  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
  width: 100%;
  padding: 44px 47px;
  background: ${COLORS.WHITE_FIVE};
  > div {
    @media only screen and (max-width: 1200px) {
      flex-flow: column;
      align-items: center;
    }
    > div {
      border-right: none;
      padding-top: 0;
      &:first-child {
        margin-right: 35px;
        width: 30%;
        max-width: 350px;
        @media only screen and (max-width: 1200px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
      &:last-child {
        background: #fff;
        box-shadow: 0px 1px 18px 0 ${COLORS.MILITARY_GREEN};
        width: 70%;
        @media only screen and (max-width: 1200px) {
          width: 100%;
        }
        > div {
          > div {
            > div {
              height: 44px;
              &:first-child {
                height: 56px;
                border: 1px solid ${COLORS.LIGHT_GREY_TEN};
              }
              &:nth-child(2n + 1) {
                background: ${COLORS.LIGHT_GREY_NINE};
                > div {
                  background: ${COLORS.LIGHT_GREY_NINE};
                }
              }
              > div {
                border: none;
                @media only screen and (max-width: 1350px) {
                  padding: 0 9px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const Calendar = styled.div`
  width: 100%;
  box-shadow: 0px 1px 18px 0 rgba(82, 100, 58, 0.11);
  background: #fff;
  padding: 20px;
  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
`;
export const NewsandEventsOuter = styled.div`
  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
  width: 100%;
  padding: 50px 53px;
  border-top: 1px solid ${COLORS.WHITE_THREE};
  > p {
    color: ${COLORS.BLACK};
    margin-bottom: 29px;
  }
  > div {
    @media screen and (max-width: 1000px) {
      flex-flow: column;
    }
  }
`;
export const NewsItem = styled.div`
${props =>
    props.hasHover
      ? `&:hover {
    opacity: 0.2;
  }`
      : ``}
  ${props =>
    props.isAdmin
      ? `width: 100%;`
      : `width: 300px;
         height: 300px;}`}
  background: ${COLORS.LIGHT_GREY_NINETEEN};
  padding: 30px 30px 60px 30px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow-wrap: break-word;
  text-align: center;
  margin-top: 18px;
  float: left;
  border: 1px solid ${COLORS.LIGHT_GREY_NINE};
  margin-right: 18px;
  @media screen and (max-width: 1000px) {
    margin-right: 0;
    margin-bottom: 15px;
  }
  h6 {
    color: ${COLORS.CHARCOAL_TWO};
    font-weight: ${FONTWEIGHT.FW_6};
    line-height: 1.22;
    font-size: 1.12rem;
    margin-bottom: 25px;
  }
  p {
    color: ${COLORS.CHARCOAL_THREE};
    font-weight: ${FONTWEIGHT.FW_4};
    line-height: 1.22;
    font-size: 0.93rem;
  }
  &:last-child {
    margin-right: 0;
  }
`;
const CALENDAR_STYLE = `

  border: none;

  .react-calendar__month-view__weekdays {
    border-bottom: 1px solid ${COLORS.MILITARY_GREEN};
    &__weekday {
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.08;
      letter-spacing: 0.1px;
      text-align: center;
      color: rgb(177, 177, 177);
    }
  }
  .react-calendar__navigation__prev-button {
    order: 2;
  }
  .react-calendar__navigation__next-button {
    order: 3;
  }
  .react-calendar__navigation__label {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: 0.1px;
    text-align: left;
    padding-left: 10px;
  }
  .react-calendar__month-view__days__day {
    border-radius: 50%;
    padding: 0;
    position: relative;
    * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.08;
      letter-spacing: 0.1px;
      text-align: center;
      color: ${COLORS.GREYISH_BROWN_TWO};
    }
    &:after {
      content: '';
      padding-top: 100%;
      display: block;
    }
  }
  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    background: ${COLORS.TURTLE_GREEN};
    &:focus {
      background: ${COLORS.TURTLE_GREEN};
    }
    &:hover {
      background: ${COLORS.TURTLE_GREEN};
    }
    * {
        font-size: 13px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.08;
        letter-spacing: 0.1px;
        text-align: center;
        color: rgb(255, 255, 255);
  
    }
  }
`;
export const DatePickerOuter = styled.div`
  width: 100%;
  box-shadow: 0px 1px 18px 0 rgba(82, 100, 58, 0.11);
  background: #fff;
  // max-width: 307px;
  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: 100%;
    // width: auto;
    margin-bottom: 20px;
  }

  .custom-calendar {
    ${CALENDAR_STYLE}
  }
`;
