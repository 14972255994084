import React, { Component } from "react";
import { FragenKatalogOuter } from "../../styled/questioncatalogue";
import FragenHead from "./FragenHead";
import BearbeitungsStatus from "./BearbeitungsStatus";
import FragenList from "./FragenList";
import ActionsBottom from "./ActionBottom";

class FragenKatalog extends Component {
  constructor() {
    super();

    this.state = {
      shouldReload: false,
    };
  }

  shouldReload = should => {
    this.setState({
      shouldReload: should,
    });
  };

  render() {
    return (
      <FragenKatalogOuter>
        <FragenHead />
        <BearbeitungsStatus />
        <FragenList shouldReload />
        <ActionsBottom shouldReload={this.shouldReload} />
      </FragenKatalogOuter>
    );
  }
}

export default FragenKatalog;
