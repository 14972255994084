import styled from "styled-components";
import { BORDER_RADIUS, COLORS, P_2, FONTWEIGHT } from "./constants";

export const FormInputLabel = styled.label`
  ${P_2}
  flex: 1;
  max-width: 180px;
  min-width: 180px;
  text-align: right;
  padding-right: 5px;
  color: ${COLORS.GREYISH_BROWN_TWO};
  font-weight: normal;
  span {
    color: ${COLORS.TOMATO};
    font-size: 0.85rem !important;
  }
  ${props =>
    props.maxWidth &&
    ` max-width: ${props.maxWidth}px
  `}
`;

export const TextInput = styled.input`
  width: 100%;
  padding: 4px 11px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  ${props => (props.errorBorder ? `border: solid 2px ${COLORS.TOMATO};` : `border: solid 2px ${COLORS.WHITE};`)}
  border-radius: ${BORDER_RADIUS.BR_1};
  font-size: 15px;
  line-height: 27px;
  text-align: left;
  height:41px;
  color: #999999;
  height:40px;
  ${props =>
    props.error &&
    `
    // border-color: ${COLORS.TOMATO}
  `}
  ${props => props.marginLeft && `margin-left: 7px`}

  
  ::placeholder {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.8;
    letter-spacing: 0.2px;
    text-align: left;
    color: #999999;
  }
  
  ${props =>
    props.footer &&
    `
    box-shadow: inset 0.5px 0.9px 10px 0 rgba(72, 84, 56, 0.21);
    border: solid 1px ${COLORS.LIGHT_GREY_EIGHT};
    background-color: white;

    ::placeholder {
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.8;
      letter-spacing: 0.2px;
      text-align: left;
      color: ${COLORS.DARK_SAGE_FIVE};
    }
  `}

  // Spacing
  ${props => (props.spacingTop ? `padding-top: ${props.spacingTop}${props.unit || "px"};` : "")};
  ${props => (props.spacingBottom ? `padding-bottom: ${props.spacingBottom}${props.unit || "px"};` : "")};
  ${props => (props.spacingLeft ? `padding-left: ${props.spacingLeft}${props.unit || "px"};` : "")};
  ${props => (props.spacingRight ? `padding-right: ${props.spacingRight}${props.unit || "px"};` : "")};

  // Margin
  ${props => (props.offsetTop ? `margin-top: ${props.offsetTop}${props.unit || "px"};` : "")};
  ${props => (props.offsetBottom ? `margin-bottom: ${props.offsetBottom}${props.unit || "px"};` : "")};
  ${props => (props.offsetLeft ? `margin-left: ${props.offsetLeft}${props.unit || "px"};` : "")};
  ${props => (props.offsetRight ? `margin-right: ${props.offsetRight}${props.unit || "px"};` : "")};
  ${props => (props.marginAuto ? "margin: auto;" : "")};
  ${props => (props.maxWidth ? `max-width: ${props.maxWidth}px;` : "")};
  ${props => (props.width ? `width: ${props.width}px;` : "")};
  ${props =>
    props.mediaWidth
      ? `@media only screen and (max-width: 600px){
      width: ${props.mediaWidth}px;
    }`
      : ""}
  `;

export const InputLabelStyle = styled.label`
  display: flex;
  ${props => (props.marginRight ? `margin-right: ${props.marginRight}${props.unit || "px"};` : "")};
  ${props => (props.marginBottom ? `margin-bottom: ${props.marginBottom}${props.unit || "px"};` : "")};
  ${props => (props.maxWidthLabel ? `max-width: ${props.maxWidthLabel}${props.unit || "px"};` : "")};
  ${props => (props.widthLabel ? `width: ${props.widthLabel}${props.unit || "%"};` : "")};
  ${props => (props.justifyContentLabel ? `justify-content: ${props.justifyContentLabel};` : "")};
`;

export const InputSelectStyle = styled.select`
  max-width: 411px;
  width: 100%;
  height: 43px;
  padding: 0 17px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  border: solid 2px ${COLORS.WHITE};
  border-radius: ${BORDER_RADIUS.BR_1};
  background: transparent;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: 0.2px;
  text-align: left;
  color: #999999;
  -webkit-appearance: none;
  postion: relative;
  ${props => (props.marginBottom ? `margin-bottom: ${props.marginBottom}${props.unit || "px"};` : "")};
`;

export const InputOptionStyle = styled.option`
  background: blue;
`;

export const Divider = styled.hr`
  border: solid 1px rgba(70, 84, 84, 0.1);
  width: 100%;
`;

export const CheckBoxStyle = styled.input`
  width: 20px;
  height: 20px;
`;

export const TableContainer = styled.div`
  max-width: 564px;
  width: 100%;
  height: auto;
  box-shadow: 0px 1px 8px 0 rgba(70, 84, 84, 0.2);
  border: solid 1px #e4e7e0;
`;

export const TableHeader = styled.div`
  height: 67px;
  width: 100%;
  border: solid 1px #dee2d7;
  background-color: #eff2ec;
  padding: 30px 0px 20px 33px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const TableContentContainer = styled.div`
  padding: 37px 42px 46px 33px;
`;

export const CheckBoxContainer = styled.div`
  min-width: 18px;
  max-width: 18px;
  height: 18px;
  border-radius: 2px;
  max-width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${COLORS.NASTY_GREEN};
  cursor: pointer;
  transition: all 0.3s ease;
  ${props => (props.disabled ? `pointer-events: none;` : "")}
  ${props =>
    props.checked
      ? `box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
    border: solid 2px ${COLORS.NASTY_GREEN};
    background: ${COLORS.NASTY_GREEN};
    `
      : ""}

  &:hover {
    border: solid 2px ${COLORS.NASTY_GREEN};
    background: ${COLORS.NASTY_GREEN};
  }

  i {
    font-size: 17px;
    color: white !important;
  }
  @media only screen and (max-width: 800px) {
    min-width: 18px;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${props => (props.column ? `flex-direction: column;` : ``)}
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  margin-right: 20px;
  i {
    margin-right: 10px !important;
    ${props => (props.column ? `margin-right: 0 !important;` : ``)}
  }
`;
export const Error = styled.div`
  width: 100%;
  text-align: right;
  color: ${COLORS.TOMATO};
  font-size: 0.85rem;
  font-weight: ${FONTWEIGHT.FW_4};
  ${props => (props.position ? `` : `position: absolute;`)}
  right: 0;
  bottom: -16px;
  ${props => (props.errorOffset ? `bottom: ${props.errorOffset}px` : ``)}
  @media screen and (max-width: 985px) {
    font-size: 0.7rem;
  }
`;
export const TextArea = styled.textarea`
  width: 100%;
  padding: 12px 11px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  border: solid 2px ${COLORS.WHITE};
  border-radius: ${BORDER_RADIUS.BR_1};
  font-size: 15px;
  line-height: 20px;
  text-align: left;
  height:193px;
  color: #999999;
  ${props =>
    props.error &&
    `
    // border-color: ${COLORS.TOMATO}
  `}

  
  ::placeholder {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.8;
    letter-spacing: 0.2px;
    text-align: left;
    color: #999999;
  }
  
  ${props =>
    props.footer &&
    `
    box-shadow: inset 0.5px 0.9px 10px 0 rgba(72, 84, 56, 0.21);
    border: solid 1px ${COLORS.LIGHT_GREY_EIGHT};
    background-color: white;

    ::placeholder {
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.8;
      letter-spacing: 0.2px;
      text-align: left;
      color: ${COLORS.DARK_SAGE_FIVE};
    }
  `}

  // Spacing
  ${props => (props.spacingTop ? `padding-top: ${props.spacingTop}${props.unit || "px"};` : "")};
  ${props => (props.spacingBottom ? `padding-bottom: ${props.spacingBottom}${props.unit || "px"};` : "")};
  ${props => (props.spacingLeft ? `padding-left: ${props.spacingLeft}${props.unit || "px"};` : "")};
  ${props => (props.spacingRight ? `padding-right: ${props.spacingRight}${props.unit || "px"};` : "")};

  // Margin
  ${props => (props.offsetTop ? `margin-top: ${props.offsetTop}${props.unit || "px"};` : "")};
  ${props => (props.offsetBottom ? `margin-bottom: ${props.offsetBottom}${props.unit || "px"};` : "")};
  ${props => (props.offsetLeft ? `margin-left: ${props.offsetLeft}${props.unit || "px"};` : "")};
  ${props => (props.offsetRight ? `margin-right: ${props.offsetRight}${props.unit || "px"};` : "")};
  ${props => (props.marginAuto ? "margin: auto;" : "")};
  ${props => (props.maxWidth ? `max-width: ${props.maxWidth}px;` : "")};
  ${props => (props.width ? `width: ${props.width}px;` : "")};
  ${props =>
    props.mediaWidth
      ? `@media only screen and (max-width: 600px){
      width: ${props.mediaWidth}px;
    }`
      : ""}
  `;
