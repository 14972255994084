import React, { Component } from "react";
import { Layout } from "../../../styled";
import { ConfirmationOuter } from "../../../styled/modal";
import { Button } from "../../../styled/buttons";
import { ModalFooterOuter } from "../../../styled/actionmodalconfirmation";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

class ActionModalConfirmation extends Component {
  handleDelete = async () => {
    const {
      store: { cancelLicence, licenseManagement },
    } = this.props;
    let licenceId = this.props.licenceId;
    const data = {
      licence_id: licenceId,
      is_auto_renewal: false,
    };
    await cancelLicence.fetch(data);
    licenseManagement.fetch();
    this.props.closeModal();
  };

  render() {
    const { closeModal } = this.props;
    return (
      <Layout displayFlex alignCenter column>
        <ConfirmationOuter>
          <i className="material-icons">error</i>
          <h5>Sind Sie sicher, dass Sie diese Lizenz löschen wollen?</h5>
        </ConfirmationOuter>
        <ModalFooterOuter>
          <Button onClick={closeModal} color="grey">
            Abbrechen
          </Button>
          <Button onClick={this.handleDelete} color="green">
            Löschen
          </Button>
        </ModalFooterOuter>
      </Layout>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(ActionModalConfirmation);
