import React from "react";
import * as Yup from "yup";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { ToastsContainer, ToastsStore } from "react-toasts";

import { PageRow } from "../../styled/layout";
import { Layout, P, LineBreak } from "../../styled";
import {
  DeviatingInvoiceAddressOuter,
  AdditionalLicense,
  Order,
  InvoiceAddressForm,
  LicenseModalSelectionOptions,
} from "../../styled/deviatinginvoiceaddress";
// import PaymentOptions from "./PaymentOptions";
import CheckBox from "../Form/CheckBox";
import Select from "../Form/Select";
import FormInput from "../Form/Input";
import { Icon, Label } from "../../styled";
import PaymentOptions from "../Signup/PaymentOptions";

const INITIAL_STATE = {
  adminLicenceCost: 0,
  userLicenceCost: 0,
  adminLicenceCount: 0,
  userLicenceCount: 0,
  licenceTotalCost: 0,
  adminLicence: false,
  userLicence: false,
  isAnotherAddress: false,
  billingFirstName: "",
  billingLastName: "",
  billingStreet: "",
  billingPlace: "",
  billingHouseNumber: "",
  billingPostCode: "",
};

class InvoiceAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      fields: {},
      errors: {},
    };
  }

  componentDidMount() {
    const {
      store: { userDetials },
    } = this.props;
    userDetials.fetch();
  }

  handleChange = field => e => {
    this.setState({
      [field]: e.target.value,
      errors: { ...this.state.errors, [field]: "" },
    });
  };

  onInputChage = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  handleCheckboxChange = name => () => {
    this.setState(state => ({
      [name]: !state[name],
    }));

    if (name === "adminLicence") {
      this.setState({ adminLicenceCount: 0, adminLicenceCost: 0 });
    } else if (name === "userLicence") {
      this.setState({ userLicenceCount: 0, userLicenceCost: 0 });
    }
  };

  handleSelected = category => value => {
    this.setState({ [category]: value });
    if (category === "userCount") {
      if (this.props.store.getUserFromToken.data && this.props.store.getUserFromToken.data.payment_model === "new") {
        let userCost = 180 * value;
        this.setState({ userLicenceCost: userCost, userLicenceCount: value });
      } else {
        let userCost = 12 * 59 * value;
        this.setState({ userLicenceCost: userCost, userLicenceCount: value });
      }
    } else if (category === "adminCount") {
      let adminCost = 12 * 99 * value;
      this.setState({ adminLicenceCost: adminCost, adminLicenceCount: value });
    }
  };

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    let schema = "";

    if (this.state.isAnotherAddress) {
      schema = Yup.object().shape({
        billingPostCode: Yup.string().required(requiredError),
        billingHouseNumber: Yup.string().required(requiredError),
        billingLastName: Yup.string().required(requiredError),
        billingPlace: Yup.string().required(requiredError),
        billingStreet: Yup.string().required(requiredError),
        billingFirstName: Yup.string().required(requiredError),
      });

      try {
        schema.validateSync(fields, { abortEarly: false });
      } catch (err) {
        if (err.inner) {
          err.inner.forEach(e => {
            errors[e.path] = e.message;
          });
        }
      }

      formIsValid = schema.isValidSync(fields);

      if (Object.keys(errors).length > 0) {
        const key = Object.keys(errors)[0];
        if (this[key]) {
          this[key].focus();
        }
      }

      this.setState({ errors: errors });
      return formIsValid;
    }

    return formIsValid;
  }

  handleSubmit = async paymentValid => {
    const {
      store: {
        licencePurchase,
        userDetials,
        licenseManagement,
        router: { history },
        licenceCount,
      },
    } = this.props;

    const {
      isAnotherAddress,
      billingFirstName,
      billingLastName,
      billingStreet,
      billingPlace,
      billingHouseNumber,
      billingPostCode,
      cardHolder,
      cardNumber,
      cvv,
      mmyy,
      adminLicenceCost,
      userLicenceCost,
      adminLicenceCount,
      userLicenceCount,
    } = this.state;

    const totalAmount = adminLicenceCost + userLicenceCost + userDetials.data.one_time_setup_fee;
    let netCost = totalAmount + (totalAmount * 19) / 100;

    if (!this.handleValidation() || !paymentValid) {
      return false;
    }

    const data = {
      card_holder: cardHolder,
      card_number: cardNumber,
      cvc: cvv,
      exp_month: mmyy ? mmyy[0] + mmyy[1] : "",
      exp_year: mmyy ? mmyy[3] + mmyy[4] : "",
      amount: Number(netCost.toFixed(2)),
      billing_postcode: billingPostCode,
      billing_place: billingPlace,
      billing_house_number: billingHouseNumber,
      billing_street: billingStreet,
      billing_last_name: billingLastName,
      billing_first_name: billingFirstName,
      is_different_address: isAnotherAddress,
      admin_licence_number: adminLicenceCount,
      user_licence_number: userLicenceCount,
    };

    await licencePurchase.fetch(data);
    licenseManagement.fetch();
    licenceCount.fetch();
    history.replace("/administration");

    return history.go();
  };

  render() {
    const {
      adminLicenceCost,
      userLicenceCost,
      userLicenceCount,
      adminLicenceCount,
      adminLicence,
      userLicence,
      isAnotherAddress,
    } = this.state;
    const {
      store: { userDetials, getUserFromToken },
    } = this.props;

    if (userDetials.loading || !userDetials.data) {
      return null;
    }

    let totalLicenceCost = adminLicenceCost + userLicenceCost + userDetials.data.one_time_setup_fee;
    let totalAmount = totalLicenceCost + (totalLicenceCost * 19) / 100;
    let totalCost = Number(totalAmount.toFixed(2));
    const paymentModel = getUserFromToken.data.payment_model;

    return (
      <React.Fragment>
        <DeviatingInvoiceAddressOuter>
          <P type="type-7">Abweichende Rechnungsanschrift</P>

          <Layout displayFlex>
            <label> Abweichende Rechnungsanschrift </label>{" "}
            <CheckBox onClick={this.handleCheckboxChange("isAnotherAddress")} checked={isAnotherAddress} />
          </Layout>
          {isAnotherAddress ? (
            <InvoiceAddressForm>
              <Layout displayFlex spacingTop={30}>
                <Layout displayFlex flex={1} spacingLeft={20} spacingRight={20} width="100%" column>
                  <FormInput
                    required
                    label="Vorname"
                    placeholder="Vorname"
                    onChange={this.handleChange("billingFirstName")}
                    error={this.state.errors["billingFirstName"]}
                    ref={el => {
                      this.billingFirstName = el;
                    }}
                  />
                  <FormInput
                    required
                    label="Straße"
                    placeholder="Straße"
                    onChange={this.handleChange("billingStreet")}
                    error={this.state.errors["billingStreet"]}
                    ref={el => {
                      this.billingStreet = el;
                    }}
                  />
                  <FormInput
                    required
                    label="Ort"
                    onChange={this.handleChange("billingPlace")}
                    placeholder="Ort"
                    error={this.state.errors["billingPlace"]}
                    ref={el => {
                      this.billingPlace = el;
                    }}
                  />
                </Layout>
                <Layout displayFlex flex={1} spacingLeft={20} spacingRight={20} width="100%" column>
                  <FormInput
                    required
                    label="Nachname"
                    placeholder="Nachname"
                    onChange={this.handleChange("billingLastName")}
                    error={this.state.errors["billingLastName"]}
                    ref={el => {
                      this.billingLastName = el;
                    }}
                  />
                  <FormInput
                    required
                    label="Hausnummer"
                    onChange={this.handleChange("billingHouseNumber")}
                    placeholder="Hausnummer"
                    error={this.state.errors["billingHouseNumber"]}
                    ref={el => {
                      this.billingHouseNumber = el;
                    }}
                  />
                  <FormInput
                    required
                    label="PLZ"
                    placeholder="PLZ"
                    onChange={this.handleChange("billingPostCode")}
                    error={this.state.errors["billingPostCode"]}
                    ref={el => {
                      this.billingPostCode = el;
                    }}
                  />
                </Layout>
              </Layout>
            </InvoiceAddressForm>
          ) : (
            ""
          )}
        </DeviatingInvoiceAddressOuter>
        <LicenseModalSelectionOptions>
          <Layout displayFlex column>
            <Layout displayFlex spacingRight={50} flex={1} width="100%" column>
              <P type="type-7">
                Auswahl Lizenzmodel <span>(Lizenzbedingungen)</span>
              </P>
              <AdditionalLicense>
                <Layout displayFlex column>
                  <PageRow>
                    <CheckBox onClick={this.handleCheckboxChange("userLicence")} checked={userLicence} />
                    <label>Weitere User Lizenzen ( 1 Jahr Laufzeit ) </label>
                    {userLicence ? (
                      <Select
                        noAdapter
                        onChange={this.handleSelected("userCount")}
                        options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(a => a)}
                        placeholder="0"
                      />
                    ) : (
                      <div className="disabled">
                        0 <Icon size={16}>expand_more</Icon>
                      </div>
                    )}
                  </PageRow>
                  {paymentModel === "old" && (
                    <PageRow>
                      <CheckBox onClick={this.handleCheckboxChange("adminLicence")} checked={adminLicence} />
                      <label>Weitere Admin-Lizenzen ( 1 Jahr Laufzeit )</label>
                      {adminLicence ? (
                        <Select
                          noAdapter
                          onChange={this.handleSelected("adminCount")}
                          options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(a => a)}
                          placeholder="0"
                        />
                      ) : (
                        <div className="disabled">
                          0 <Icon size={16}>expand_more</Icon>
                        </div>
                      )}
                    </PageRow>
                  )}
                </Layout>
              </AdditionalLicense>
              <Order>
                <h5>Bestellung</h5>
                <Layout displayFlex column>
                  {paymentModel === "old" && (
                    <PageRow>
                      <Label>{adminLicenceCount} x Admin-Lizenz (Jährlich) </Label>
                      <span>
                        <Label>{adminLicenceCost.toLocaleString("de-DE", { minimumFractionDigits: 2 })} € </Label>
                      </span>
                    </PageRow>
                  )}
                  <PageRow>
                    <Label>{userLicenceCount} x User-Lizenz (Jährlich) </Label>
                    <span>
                      <Label>{userLicenceCost.toLocaleString("de-DE", { minimumFractionDigits: 2 })} € </Label>
                    </span>
                  </PageRow>
                  <LineBreak />

                  <div className="spacing"></div>
                  <PageRow>
                    <Label>{paymentModel === "old" ? "Gesamtbetrag" : "Rechnungsbetrag"} netto</Label>
                    <span>
                      <Label>{totalLicenceCost.toLocaleString("de-DE", { minimumFractionDigits: 2 })} € </Label>
                    </span>
                  </PageRow>
                  <PageRow>
                    <Label>zzgl. 19% gesetzlicher Mehrwertsteuer</Label>
                    <span>
                      <Label>{((totalLicenceCost * 19) / 100).toLocaleString("de-DE", { minimumFractionDigits: 2 })} € </Label>
                    </span>
                  </PageRow>
                  {/* <PageRow>- Rabatt-Code <span>XXX € </span></PageRow> */}
                  <PageRow>
                    <Label>{paymentModel === "old" ? "Gesamtbetrag" : "Rechnungsbetrag"} brutto</Label>
                    <span>
                      <Label>{totalCost.toLocaleString("de-DE", { minimumFractionDigits: 2 })} € </Label>
                    </span>
                  </PageRow>
                </Layout>
              </Order>
              <Layout displayFlex alignRight>
                {/* <Speichem> Rabatt-Code eingeben </Speichem> */}
              </Layout>
            </Layout>
          </Layout>
          <Layout displayFlex spacingLeft={0} flex={1} width="100%" column>
            <P type="type-7">Auswahl Bezahlmethode</P>
            <PaymentOptions onChange={this.onInputChage} parentMethod={this.handleSubmit} />
          </Layout>
          <ToastsContainer lightBackground store={ToastsStore} />
        </LicenseModalSelectionOptions>
      </React.Fragment>
    );
  }
}
export default compose(
  inject("store"),
  observer,
)(InvoiceAddress);
