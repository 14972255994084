import React, { Component } from "react";
// import _ from "lodash";
import * as Yup from "yup";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

import { ResponseMessageForgot } from "../../../styled/layout";
import { FormBox, LoginButton } from "../../../styled/auth";
import UsernameIcon from "../../../assets/images/user.png";
import LoginInput from "../../../components/Form/LoginInput";

const INITIAL_STATE = {};

class PasswordForgotForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      fields: {},
      errors: {},
      email: "",
    };
  }

  // handleChange = e => {
  //   this.setState({
  //     [e.target.name]: e.target.value
  //   });
  // };
  handleChange = field => e => {
    this.setState({
      [field]: e.target.value,
      errors: { ...this.state.errors, [field]: "" },
    });
  };

  onInputChage = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    const schema = Yup.object().shape({
      email: Yup.string()
        .email("E-Mail ist nicht gültig!")
        .required(requiredError),
    });

    try {
      schema.validateSync(fields, { abortEarly: false });
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(e => {
          errors[e.path] = e.message;
        });
      }
    }

    formIsValid = schema.isValidSync(fields);

    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (this[key]) {
        this[key].focus();
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  handleSubmit = async () => {
    const {
      store: { forgotPassword },
    } = this.props;
    const { email } = this.state;

    if (!this.handleValidation()) {
      return false;
    }

    const data = {
      email: email,
    };

    let responseMessage = await forgotPassword.fetch(data);
    if (responseMessage.data) {
      if (responseMessage.status < 400) {
        this.setState({
          message: (
            <div className="response success">
              {" "}
              <i className="material-icons">check_box</i> Überprüfen Sie Ihre E-Mail, um das Passwort zurückzusetzen.
            </div>
          ),
        });
      } else if (responseMessage.status === 400) {
        this.setState({
          message: (
            <div className="response">
              <i className="material-icons">error</i> Dein Konto konnte leider nicht gefunden werden
            </div>
          ),
        });
      } else {
        this.setState({
          message: (
            <div className="response">
              <i className="material-icons">error</i> Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut
            </div>
          ),
        });
      }
    }
    // setTimeout(
    //   function() {
    //     this.setState({ message: "" });
    //   }.bind(this),
    //   5000
    // );
  };
  render() {
    return (
      <React.Fragment>
        <FormBox>
          <LoginInput
            name="email"
            placeholder="E-Mail eingeben"
            id="email"
            type="text"
            label="E-Mail eingeben"
            img={UsernameIcon}
            onChange={this.handleChange("email")}
            error={this.state.errors["email"]}
          />
          <LoginButton onClick={this.handleSubmit} type="submit">
            Link zum Zurücksetzen senden
          </LoginButton>
        </FormBox>
        <ResponseMessageForgot>
          {" "}
          <div> {this.state.message} </div>{" "}
        </ResponseMessageForgot>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(PasswordForgotForm);
