import styled from "styled-components";
import { COLORS, BORDER_RADIUS } from "./constants";

export const VorlagenOuter = styled.div`
  width: 100%;
  background: #fff;
  > div {
    width: 100%;
    margin: auto;
  }
`;
export const UserFilterOptions = styled.div`
  width: 100%;
  padding: 21px 39px;
  border-bottom: 1px solid rgb(220, 225, 214);
  @media screen and (max-width: 500px) {
    padding: 5px;
  }
`;
export const FilterRight = styled.div`
  width: 100%;
  text-align: right;
  @media screen and (max-width: 500px) {
    text-align: center;
  }
  button {
    background: ${COLORS.TURTLE_GREEN_TWO};
    padding: 7px 24px;
    text-align: center;
    color: ${COLORS.WHITE_FOUR};
    font-size: 0.9rem;
    height: 43px;
    line-height: 3px;
    border-radius: ${BORDER_RADIUS.BR_1};
    cursor: pointer;
    border: 1px solid ${COLORS.TURTLE_GREEN_TWO};
    box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
    @media screen and (max-width: 500px) {
      font-size: 0.8rem;
      padding: 5px;
    }
    i {
      font-size: 19px;
      margin-left: 8px;
      line-height: 0;
      position: relative;
      top: 4px;
    }
  }
`;
export const TemplatePagination = styled.div`
  padding: 24px 39px;
  text-align: right;
  color: ${COLORS.GREEN_GREY_THREE};
  font-size: 0.87rem;
  width: 100%;
`;
