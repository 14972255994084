import React from "react";
import PropTypes from "prop-types";
import { IconButtonContainer } from "../../styled/buttons";

const IconButton = ({ onClick, title, float, icon, active, small }) => (
  <IconButtonContainer {...{ onClick, active, float, small }}>
    {title}
    {icon && <i className="material-icons">{icon}</i>}
  </IconButtonContainer>
);

IconButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  active: PropTypes.bool,
  float: PropTypes.bool,
};
IconButton.defaultProps = {
  icon: undefined,
  active: false,
  float: false,
};

export default IconButton;
