import styled from "styled-components";
import { COLORS, BORDER_RADIUS, FONTWEIGHT } from "./constants";
import checkmark from "../assets/images/baseline.png";

export const PaymentOptionsOuter = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .PaymentMethodItem {
    position: relative;
    margin: 0 20px 20px 0;
    padding: 39px 20px;
    width: 48%;
    text-align: left;
    @media only screen and (max-width: 1440px) {
      margin-right: 4%;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    p {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding-bottom: 0;
      border: none;
    }
    input {
      &:checked,
      &:not(checked) {
        position: absolute;
        left: -9999px;
      }
      &:not(:checked) + label,
      &:checked + label {
        position: absolute;
        padding-left: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
        h6 {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          border-radius: ${BORDER_RADIUS.BR_1};
          padding-left: 2rem;
          @media only screen and (max-width: 700px) {
            padding-left: 1rem;
          }
          i {
            width: 60px;
            @media only screen and (max-width: 1400px) {
              width: 45px;
            }
            @media only screen and (max-width: 700px) {
              width: 40px;
            }
          }
          img {
            margin-right: 20px;
            max-width: 40px;
            height: auto;
            @media only screen and (max-width: 1400px) {
              max-width: 33px;
            }
            @media only screen and (max-width: 700px) {
              max-width: 28px;
            }
          }
        }
        span {
          color: ${COLORS.BLACK};
          font-size: 1rem;
          font-weight: ${FONTWEIGHT.FW_6};
          position: relative;
          z-index: 5;
          @media only screen and (max-width: 1400px) {
            font-size: 0.9rem;
          }
        }
      }
      &:not(:checked) + label:before,
      &:checked + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: ${BORDER_RADIUS.BR_1};
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 1px 8px 0 ${COLORS.GUNMETAL_20};
        border: 1px solid ${COLORS.LIGHT_GREY_FIFTEEN};
        background: #fff;
      }
      &:not(:checked) + label:after,
      &:checked + label:after {
        content: "";
        position: absolute;
        top: 0.22em;
        right: 0.22em;
        font-size: 1.3em;
        line-height: 0.8;
        color: #09ad7e;
        transition: all 0.2s;
        width: 22px;
        height: 22px;
        background: url(${checkmark}) no-repeat center center #fff;
        border-radius: ${BORDER_RADIUS.BR_5};
        background-size: 15px 12px;
      }
      &:checked + label {
        h6 {
          background-image: linear-gradient(to top, #6a9832, #77a63d, #84b549, #91c454, #9fd360);
          span {
            color: #fff;
          }
          img {
            filter: brightness(0) invert(1);
            opacity: 0.7;
          }
        }
      }
      &:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
      }
      &:checked + label:after {
        opacity: 1;
        transform: scale(1);
      }
      &:disabled:not(:checked) + label:before,
      &:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
      &:disabled:checked + label:after {
        color: #999;
      }
      &:disabled + label {
        color: #aaa;
      }
      &:checked:focus + label:before,
      &:not(:checked):focus + label:before {
        // border: 2px dotted blue;
      }
    }
    label:hover:before {
      //  border: 2px solid #4778d9!important;
    }
  }
`;
export const PaymentDetails = styled.div`
  width: 100%;
  padding: 50px 68px;
  background: #fff;
  border: 1px solid ${COLORS.WHITE_EIGHT};
  margin-top: 40px;
  position: relative;
  @media only screen and (max-width: 1400px) {
    padding: 40px 30px;
  }
  @media only screen and (max-width: 700px) {
    padding: 10px;
  }
  &:before {
    content: "";
    height: 5px;
    max-width: 289px;
    position: absolute;
    left: 0;
    top: -5px;
    width: 100%;
    background: ${COLORS.TURTLE_GREEN_THREE};
  }
  > div > div {
    > div {
      padding: 0 0 25px 0;
      border: none;
      position: relative;
      > div {
        position: relative;
        & + span {
          color: ${COLORS.TOMATO};
          font-size: 0.85rem;
          position: relative;
          top: 1px;
        }
      }
      label {
        padding-bottom: 18px;
        color: ${COLORS.GREYISH_BROWN_TWO};
        font-size: 1rem;
        display: block;
      }
    }
  }
  button {
    width: 43%;
    @media only screen and (max-width: 700px) {
      font-size: 13px;
      margin-right: 10px;
    }
    @media only screen and (max-width: 1600px) {
      min-width: 140px;
      width: 40%;
    }
    @media only screen and (max-width: 700px) {
      min-width: 110px;
      width: 40%;
    }
    @media only screen and (max-width: 500px) {
      min-width: 95px;
      width: 19%;
    }
    &:last-child {
      // width: 52%;
      width: 50%;
      float: right;
    }
  }
`;
export const ColTwo = styled.div`
  width: 100%;
  padding-top: 0;
  > div {
    display: flex;
    flex-flow: row;
    > div {
      width: 45%;
      padding: 0;
      border: none;
      > div {
        position: relative;
      }
      &:last-child {
        padding-left: 10%;
      }
    }
  }
`;
export const DebitCardOptions = styled.div`
  width: 100%;
  > div {
    padding-bottom: 0 !important;
    > div {
      padding: 0 0 25px 0;
      border: none;
      position: relative;
    }
  }
`;
export const CreditCardOptions = styled.div`
  width: 100%;
  > div {
    padding: 0 0 25px 0;
    border: none;
    position: relative;
  }
`;
export const Captcha = styled.div`
  width: 100%;
  > div {
    padding: 0;
    @media only screen and (max-width: 500px) {
      > div > div > div {
        transform: scale(0.77);
        transform-origin: 0 0;
      }
    }
  }
`;
export const AdditionalItems = styled.div`
  width: 100%;
  margin-bottom: 20px;
  > div {
    display: flex;
    label {
      color: ${COLORS.GREYISH_BROWN_TWO};
      font-size: 1rem;
      font-weight: 400;
      margin-left: 20px;
      padding-bottom: 0 !important;
    }
  }
  span.agbError {
    color: ${COLORS.TOMATO};
    font-size: 0.86em;
  }
`;
