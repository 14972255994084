import React from "react";
import { ModalFooterOuter, PrimaryTemplateInfo } from "../../../styled/modal";
import { Button } from "../../../styled/buttons";
import { Layout } from "../../../styled";
import FormInput from "../../Form/Input";
import config from "../../../lib/config";
import Axios from "axios";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";
// import { PrimaryInfo } from "../../../styled/signup";
import { UserDetailsRow } from "../../../styled/personalinfo";

class UploadTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      temFile: null,
      notes: "",
      uploading: false,
    };

    this.upload = React.createRef();
  }

  handleChange = e => {
    this.setState({
      notes: e.target.value,
    });
  };

  submit = () => {
    if (this.submit && this.submit.loading) {
      return null;
    }
    const file = this.upload.current.files[0];
    const token = window.localStorage.getItem(config.keys.local_storage_key);

    const body = new FormData();
    body.set("file_type", file.name.split(".")[1].toUpperCase());
    body.set("template_name", file.name);
    body.set("print", true);
    body.set("description", this.state.notes);
    body.set("download", true);
    body.set("delete", true);
    body.append("temFile", file);

    Axios({
      method: "POST",
      url: config.endpoints.api + "/api/template/upload",
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    }).then(() => {
      this.props.store.userTemplates.fetch();
      this.props.closeModal();
    });
    this.setState({
      uploading: true,
    });
  };

  render() {
    const { closeModal } = this.props;
    const { uploading } = this.state;

    return (
      <React.Fragment>
        <Layout spacingTop={40} fullWidth spacingRight={40} spacingLeft={40}>
          <PrimaryTemplateInfo>
            <UserDetailsRow>
              <FormInput ref={this.upload} name="temFile" type="file" label="Datei" />
            </UserDetailsRow>
            <UserDetailsRow>
              <FormInput onChange={this.handleChange} name="template_notes" label="Notizen" />
            </UserDetailsRow>
          </PrimaryTemplateInfo>
        </Layout>
        <ModalFooterOuter>
          <Button onClick={closeModal} color="grey">
            Abbrechen
          </Button>
          <Button onClick={!uploading && this.submit} color="green">
            {uploading ? "Ihre Datei wird verarbeitet" : "Speichern"}
          </Button>
        </ModalFooterOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(UploadTemplate);
