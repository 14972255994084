import React from "react";
import { P, Layout } from "../../styled";
import { NewAppointmentOuter, NewItemBlock } from "../../styled/MandantenDashboard";
import NewAppointmentImage from "../../assets/images/new-appointment.png";

const NewAppointment = () => (
  <NewAppointmentOuter>
    <table>
      <thead>
        <tr>
          <th>
            Nächster Termin / Aufgabe <i />
          </th>
          <th>
            Datum <i />
          </th>
        </tr>
      </thead>
    </table>
    <Layout displayFlex>
      <img src={NewAppointmentImage} alt="New Appointment" />
      <NewItemBlock>
        <P type="type-5">Sie haben keinen aktiven Eintrag</P>
        <a href="/activities">
          <i className="material-icons">add_circle</i>
        </a>
      </NewItemBlock>
    </Layout>
  </NewAppointmentOuter>
);

export default NewAppointment;
