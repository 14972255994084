import React from "react";
import { Layout, P } from "../../../styled";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { Button } from "../../../styled/buttons";
import { ModalFooterOuter, ModalContent } from "../../../styled/modal";
import { FormInputLabel } from "../../../styled/form";
import FormInput from "../../../components/Form/Input";
import DatePicker from "../../../components/Form/DatePicker";
import { DatePickerItem } from "../../../styled/signup";
import { ClientLicenceSpan } from "../../../styled/clientlicence";
import * as Yup from "yup";
import moment from "moment";
import _ from "lodash";

const INITIAL_STATE = {
  firstName: null,
  lastName: null,
  dateOfBirth: null,
  referencetext: null,
};

class ClientLicence extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      fields: {},
      errors: {},
    };
  }

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";

    const schema = Yup.object().shape({
      referencetext: Yup.string(requiredError)
        .required(requiredError)
        .nullable(),
      dateOfBirth: Yup.date(requiredError)
        .required(requiredError)
        .nullable(),
      lastName: Yup.string()
        .required(requiredError)
        .nullable(),
      firstName: Yup.string()
        .required(requiredError)
        .nullable(),
    });

    try {
      schema.validateSync(fields, { abortEarly: false });
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(e => {
          errors[e.path] = e.message;
        });
      }
    }

    formIsValid = schema.isValidSync(fields);

    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (this[key]) {
        this[key].focus();
      }
    }

    if (!this.state.dateOfBirth || moment(this.state.dateOfBirth).isAfter(moment(new Date()))) {
      errors["dateOfBirth"] = "Sie können kein Datum in der Zukunft oder heute eingeben!";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  changePage = async clientId => {
    const { clientStore, updateUser, user, declining } = this.props.store;
    clientStore.setSelectedClientId(clientId);
    declining.fetch({
      clientId: clientStore.selectedClientId,
    });
    await updateUser.fetch({
      id: user.currentUser.id,
      selected_client: clientId,
    });
    window.location.href = "/mandanten#stammdaten";
  };
  handleSubmit = async () => {
    const validate = this.handleValidation();
    const valuesToCreateClient = _.pick(this.state, ["firstName", "lastName", "dateOfBirth", "referencetext"]);
    if (!validate) {
      return null;
    }
    this.props.store.clientLicenceCreate &&
      (await this.props.store.clientLicenceCreate.fetch({
        first_name: valuesToCreateClient.firstName,
        last_name: valuesToCreateClient.lastName,
        birth_date: valuesToCreateClient.dateOfBirth,
        reference_text: valuesToCreateClient.referencetext,
      }));
    if (this.props.store.clientLicenceCreate && this.props.store.clientLicenceCreate.success) {
      this.props.closeModal();
      this.changePage(this.props.store.clientLicenceCreate.data.client.client_id);
    } else {
      this.setState({
        errors: { ...this.state.errors, referencetext: "FEHLER BEIM ERSTELLEN DER LIZENZ" },
      });
    }
  };

  handleDateChange = category => data => {
    let today = new Date();
    let yesterday = new Date(today);
    if (category === "dateOfBirth") {
      yesterday.setDate(today.getDate() - 1);
      if (new Date(data) >= new Date(yesterday)) {
        this.setState({
          [category]: null,
          // errors:{
          //   ...this.state.errors,
          //   [category]: "Sie können kein Datum in der Zukunft oder heute eingeben!",
          // }
        });
        return;
      }
      this.setState({
        [category]: new Date(data),
        errors: {
          ...this.state.errors,
          [category]: "",
        },
      });
    }
  };

  handleChange = field => e => {
    this.setState({
      [field]: e.target.value === "" ? null : e.target.value,
      errors: { ...this.state.errors, [field]: "" },
    });
  };

  render() {
    const { closeModal } = this.props;
    const { uploading } = this.state;

    return (
      <React.Fragment>
        <ModalContent>
          <Layout spacingRight={40} spacingLeft={10} mediaPadding={600}>
            <Layout displayFlex flex={1} width="100%" row rowToColumn={1000}>
              <FormInput
                label="Vorname"
                placeholder="Vorname"
                onChange={this.handleChange("firstName")}
                required
                error={this.state.errors["firstName"]}
                ref={el => {
                  this.firstName = el;
                }}
              />
              <FormInput
                required
                label="Nachname"
                onChange={this.handleChange("lastName")}
                placeholder="Nachname"
                error={this.state.errors["lastName"]}
                ref={el => {
                  this.lastName = el;
                }}
              />
            </Layout>
            <Layout displayFlex flex={1} width="100%" row rowToColumn={1000}>
              <DatePickerItem>
                <FormInputLabel>
                  Geburtsdatum<span style={{ color: "#ef5b2e" }}>*</span>
                </FormInputLabel>
                <div style={{ width: "100%", display: "flex", marginLeft: "8px" }}>
                  <DatePicker
                    value={this.state.dateOfBirth}
                    onChange={this.handleDateChange("dateOfBirth")}
                    error={this.state.errors.dateOfBirth}
                  />
                </div>
              </DatePickerItem>
              <FormInput
                required
                label="Referenztext Mandantenlizenz"
                onChange={this.handleChange("referencetext")}
                placeholder="Referenztext Mandantenlizenz"
                error={this.state.errors["referencetext"]}
              />
            </Layout>
          </Layout>
        </ModalContent>
        <ModalFooterOuter>
          <ClientLicenceSpan>
            <bold>Hinweis:</bold> Die Mandantenlizenz wird mit einem Rechnungsbetrag von 30€ pro Monat mit einer Laufzeit von einem Jahr in
            einer Einzelrechnung der hinterlegten Zahlungsweise belastet.
          </ClientLicenceSpan>
          <Button onClick={closeModal} color="grey">
            Abbrechen
          </Button>
          <Button onClick={!uploading ? this.handleSubmit : undefined} color="green">
            {uploading ? "Ihre Datei wird verarbeitet" : "Bestellen"}
          </Button>
        </ModalFooterOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(ClientLicence);
