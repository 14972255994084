const document_categories = [
    'Urkunden',
	'Diplom',
	'Ausweis',
	'Geburtsurkunde',
	'Stammbuch',
	'Vollmacht',
	'Bericht' ,
	'Sonstige'
]

export default document_categories;