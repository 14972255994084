import _ from "lodash";
import moment from "moment";
import DeleteClient from "../../components/Modal/DeleteClient";
import ExtendNewClientLicence from "../../components/Modal/ExtendNewClientLicence";
import React from "react";
import { CheckBoxLicence } from "../../styled/deleteclient";
import { CancelLicence } from "../../components/Modal/CancelLicence";

export const clientListOrderAdapter = {
  client_number: {
    key: "row_1",
    order: 0,
    scale: 1,
    title: "Mandanten ID",
  },
  first_name: {
    key: "row_2",
    order: 1,
    scale: 1,
    title: "Vorname",
  },
  last_name: {
    key: "row_3",
    order: 2,
    scale: 1,
    title: "Nachname",
  },
  postcode: {
    key: "row_4",
    order: 3,
    scale: 1,
    title: "PLZ",
  },
  place: {
    key: "row_5",
    order: 4,
    scale: 1,
    title: "Ort",
  },
  street: {
    key: "row_6",
    order: 5,
    scale: 1,
    title: "Straße",
  },
  number: {
    key: "row_7",
    order: 6,
    scale: 1,
    title: "Hausnummer",
  },
  birth_date: {
    key: "row_8",
    order: 7,
    scale: 1,
    title: "Geburtsdatum",
  },
  // client_id: {
  //   key: "id",
  //   order: 8,
  //   scale: 0,
  //   title: "ID",
  //   hide: true,
  // },
  is_deleted: {
    key: "row_9",
    order: 8,
    scale: 1,
    title: "Löschen",
  },
  option_selected: {
    key: "row_10",
    order: 9,
    scale: 1,
    title: "Lizenz verlängern",
  },
};

export const clientListHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    if (order[key].hide) return;
    const item = order[key];
    sortedHeader[item.order] = item;
  });
  return sortedHeader;
};

const deleteClient = async (event, store, client_id) => {
  const { modal } = store;
  modal.add({
    title: "Mandanten löschen",
    component: DeleteClient,
    props: {
      clientId: client_id,
    },
  });
};
const updateLicence = async (event, store, item, color) => {
  const { modal } = store;
  if (color === "green") {
    modal.add({
      title: "Lizenz für Mandant kündigen?",
      component: CancelLicence,
      props: {
        clientId: item.client_id,
        store,
      },
    });
  } else {
    modal.add({
      title: "Lizenz des Mandanten neu aktivieren?",
      component: ExtendNewClientLicence,
      props: {
        clientId: item.client_id,
      },
    });
  }
};

const onClientSelect = async (event, store, client_id, disableFunction) => {
  if (disableFunction) {
    return null;
  }
  const { clientStore, updateUser, user, declining } = store;
  const clientId = client_id;
  clientStore.setSelectedClientId(clientId);
  declining.fetch({
    clientId: clientStore.selectedClientId,
  });
  await updateUser.fetch({
    id: user.currentUser.id,
    selected_client: clientId,
  });

  window.location.href = "/mandanten";
};

export const clientListRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      let oldPaymentSystem = true;
      if (item.clientLicence) {
        oldPaymentSystem = false;
      }
      const row = [];
      const beginDate = moment(new Date());
      const endDate = moment(item.clientLicence && item.clientLicence.valid_to);
      const daysBetween = endDate.diff(beginDate, "days");
      let color = "green";
      const isCancelled = _.get(item, "clientLicence.cancelled");
      const licenceStatus = _.get(item, "clientLicence.status");
      if (isCancelled || daysBetween < 0 || licenceStatus !== "active") {
        color = "red";
      }
      let disableFunction = moment().isAfter(endDate);

      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              hasOnClick: true,
              onClick: (store, index) => e => onClientSelect(e, store, item.client_id, disableFunction),
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          case "birth_date": {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              hasOnClick: true,
              onClick: (store, index) => e => onClientSelect(e, store, item.client_id, disableFunction),
              type: "text",
              data: moment(item[itemKey]).format("DD.MM.YYYY"),
            };
            break;
          }
          case "option_selected": {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              hasOnClick: true,
              onClick: (store, index) => e => updateLicence(e, store, item, color),
              type: "newPayment",
              data: oldPaymentSystem ? null : <CheckBoxLicence backgroundColor={color}>V</CheckBoxLicence>,
            };
            break;
          }
          // case "client_id": {
          //   row[orderItem.order] = {
          //     key: `foo_${itemIndex}`,
          //     type: "id",
          //     data: item[itemKey],
          //   };
          //   break;
          // }
          case "is_deleted": {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "icon",
              active: "delete",
              action: (store, index) => e => deleteClient(e, store, item.client_id),
              data: true,
            };
            break;
          }
          }
        }
      });
      rows[index] = row;
    });
  }
  return rows;
};
