import React, { Component } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import * as Yup from "yup";

import { Layout, LineBreak, P } from "../../../styled";
import { PageColumn, ResponseMessage } from "../../../styled/layout";
import { Button } from "../../../styled/buttons";
import { ModalContent } from "../../../styled/modal";
import Select from "../../Form/Select";
import FormInput from "../..//Form/Input";
import ActionModalLicenceWarning from "../../../components/Modal/ActionModalLicenceWarning";
import { IndividualClientAccessOuter } from "../../../styled/createnewuser";
import { ModalFooterOuter } from "../../../styled/actionmodalconfirmation";
import ResponseWarning from "../../../components/Response";
import CustomTable from "../../../components/Table/ClientAccess";

import { CreateNewUserOuter, FormFieldItems, ModalActions, FormWrapper } from "../../../styled/createnewuser";

const INITIAL_STATE = {
  heading: "",
  billable: true,
  relevanceMonthly: false,
  relevanceQuarterly: false,
  linkedPerson: "",
  selectRole: "admin",
  clientaccess: "all",
};
// const FormFieldItem = props => (
//   <FormFieldItems>
//     <Layout column displayFlex alignLeft>
//       <label>{props.labelname}</label>
//       <TextInput placeholder={props.placeholder} type="text" />
//     </Layout>
//   </FormFieldItems>
// );

class CreateNewUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      errors: {},
      fields: {},
      individualClients: [],
      uploading: false,
    };
  }
  componentDidMount() {
    const {
      user,
      store: { individualClientAccess },
    } = this.props;
    individualClientAccess.fetch();
  }

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    const schema = Yup.object().shape({
      lastName: Yup.string().required(requiredError),
      firstName: Yup.string().required(requiredError),
      email: Yup.string()
        .email("E-Mail ist nicht gültig!")
        .required(requiredError),
    });

    try {
      schema.validateSync(fields, { abortEarly: false });
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(e => {
          errors[e.path] = e.message;
        });
      }
    }

    formIsValid = schema.isValidSync(fields);

    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (this[key]) {
        this[key].focus();
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit = async () => {
    const {
      store: { userRegistration, userAdministration, licenceCount },
    } = this.props;

    const { email, firstName, lastName, selectRole, clientaccess } = this.state;

    if (!this.handleValidation()) {
      return false;
    }

    this.setState({
      uploading: true,
    });

    const payM =
      this.props.store.getUserFromToken && this.props.store.getUserFromToken.data && this.props.store.getUserFromToken.data.payment_model;
    const data = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      licence_type: selectRole,
      client_access: clientaccess,
      individual_clients: this.state.individualClients,
      payment_model: payM,
    };

    let responseMessage = await userRegistration.fetch(data);

    if (responseMessage.data.data) {
      this.setState({
        message: (
          <div className="response success">
            {" "}
            <i className="material-icons">check_box</i> Nachricht erfolgreich gesendet.
          </div>
        ),
      });

      userAdministration.fetch();
      licenceCount.fetch();
      this.setState({
        uploading: false,
      });
      this.props.closeModal();
    } else {
      if (responseMessage.data.meta === "You do not have enough licence!") {
        const {
          store: { modal },
        } = this.props;
        modal.add({
          title: "",
          component: ActionModalLicenceWarning,
        });
      }
      this.setState({
        uploading: false,
      });
      if (responseMessage.data.meta === "User already exists with that email!") {
        this.setState({
          message: (
            <div className="response">
              <i className="material-icons">error</i> Die angegebene E-Mail Adresse ist bereits in Verwendung. Bitte wählen Sie eine andere
              E-Mail Adresse.
            </div>
          ),
        });
      } else {
        this.setState({
          message: (
            <div className="response">
              <i className="material-icons">error</i> Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
            </div>
          ),
        });
      }
    }
  };

  handleSelected = category => value => {
    if (category === "clientaccess") {
      if (value === "Individueller Mandantenzugriff") {
        value = "individual";
      } else if (value === "Eigene Kunden") {
        value = "own";
      } else {
        value = "all";
      }
    } else if (category === "selectRole") {
      if (value === "User") {
        value = "user";
      } else if (value === "Administrator") {
        value = "admin";
      } else {
        value = "Deaktiviert";
      }
    }
    this.setState({ [category]: value });
  };

  handleChange = field => e => {
    this.setState({
      [field]: e.target.value,
      errors: {
        ...this.state.errors,
        [field]: "",
      },
    });
  };

  onCheckboxClick = data => {
    this.setState({ individualClients: data });
  };

  render() {
    const {
      closeModal,
      store: { individualClientAccess },
    } = this.props;
    const { uploading } = this.state;
    const payment_model =
      this.props.store.getUserFromToken && this.props.store.getUserFromToken.data && this.props.store.getUserFromToken.data.payment_model;
    const options = payment_model == "new" ? ["User", "Deaktiviert"] : ["Administrator", "User", "Deaktiviert"];
    return (
      <React.Fragment>
        <ModalContent>
          <CreateNewUserOuter>
            <Layout displayFlex row>
              <PageColumn flex={5} left>
                <FormWrapper>
                  <FormInput
                    label="Vorname"
                    placeholder="Vorname"
                    onChange={this.handleChange("firstName")}
                    error={this.state.errors["firstName"]}
                    ref={el => {
                      this.firstName = el;
                    }}
                  />
                </FormWrapper>
                <FormWrapper>
                  <FormInput
                    label="E-Mail"
                    placeholder="E-Mail"
                    onChange={this.handleChange("email")}
                    error={this.state.errors["email"]}
                    ref={el => {
                      this.email = el;
                    }}
                  />
                </FormWrapper>
                <FormFieldItems>
                  <Layout column displayFlex alignLeft>
                    <label>Mandantenzugriff</label>
                    <div className="selectCustomStyle">
                      <Select
                        noAdapter
                        onChange={this.handleSelected("clientaccess")}
                        options={["Alle Kunden", "Individueller Mandantenzugriff", "Eigene Kunden"].map(a => a)}
                        placeholder="Alle Kunden"
                      />
                    </div>
                  </Layout>
                </FormFieldItems>
              </PageColumn>
              <PageColumn flex={5} right>
                <FormWrapper>
                  <FormInput
                    label="Nachname"
                    placeholder="Nachname"
                    onChange={this.handleChange("lastName")}
                    error={this.state.errors["lastName"]}
                    ref={el => {
                      this.lastName = el;
                    }}
                  />
                </FormWrapper>
                <FormFieldItems>
                  <Layout column displayFlex alignRight>
                    <label>Lizenz wählen</label>
                    <div className="selectCustomStyle">
                      <Select
                        noAdapter
                        onChange={this.handleSelected("selectRole")}
                        options={options.map(a => a)}
                        placeholder={payment_model == "new" ? "User" : "Administrator"}
                      />
                    </div>
                  </Layout>
                </FormFieldItems>

                {this.state.clientaccess !== "individual" && (
                  <ModalActions>
                    <Button onClick={closeModal} color="grey">
                      Abbrechen
                    </Button>
                    <Button onClick={!uploading && this.handleSubmit} color="green">
                      {uploading ? "Ihre Datei wird verarbeitet" : "Speichern"}
                    </Button>
                  </ModalActions>
                )}
              </PageColumn>
            </Layout>
            <ResponseMessage>
              {" "}
              <div> {this.state.message} </div>{" "}
            </ResponseMessage>
          </CreateNewUserOuter>

          <LineBreak />
          {this.state.clientaccess === "individual" ? (
            <IndividualClientAccessOuter>
              <P type="type-9">Individueller Mandantenzugriff</P>
              {individualClientAccess.constructedTable.rows[0] ? (
                <Layout>
                  <CustomTable
                    tableStore={individualClientAccess}
                    onCheckboxClick={this.onCheckboxClick}
                    small
                    data={individualClientAccess.constructedTable}
                  />
                </Layout>
              ) : (
                <ResponseWarning />
              )}
            </IndividualClientAccessOuter>
          ) : (
            ""
          )}
          {this.state.clientaccess === "individual" && (
            <ModalFooterOuter floatRight background>
              <Button onClick={closeModal} color="grey">
                Abbrechen
              </Button>
              <Button onClick={!uploading && this.handleSubmit} color="green">
                {uploading ? "Ihre Datei wird verarbeitet" : "Speichern"}
              </Button>
            </ModalFooterOuter>
          )}
        </ModalContent>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(CreateNewUserModal);
