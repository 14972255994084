import styled from "styled-components";
import { COLORS, P_2 } from "./constants";

export const PersonalInfoOuter = styled.div`
  width: 1300px;
  padding: 0 55px;
  > div {
    > div {
      border: none;
      padding: 0 4% 0 0;
      &:last-child {
        padding-right: 0;
        label {
          max-width: 178px;
        }
      }
      &:first-child {
        @media only screen and (max-width: 1400px) {
          padding: 0 10px 0 0;
        }
      }
    }
  }
  @media only screen and (max-width: 1400px) {
    width: 1100px;
  }
  @media only screen and (max-width: 1200px) {
    width: 1000px;
  }
  @media only screen and (max-width: 1000px) {
    width: 900px;
    padding: 0 25px;
  }
  @media only screen and (max-width: 900px) {
    width: 600px;
  }
  @media only screen and (max-width: 700px) {
    width: 550px;
  }
  @media only screen and (max-width: 600px) {
    width: 500px;
  }
  @media only screen and (max-width: 500px) {
    // width: 350px;
    width: 400px;
    > div {
      flex-flow: column;
    }
  }
  @media only screen and (max-width: 400px) {
    width: 375px;
  }
`;
export const FormFieldItems = styled.div`
  width: 100%;
  padding-bottom: 18px;
  > div {
    @media only screen and (max-width: 900px) {
      flex-flow: column;
      align-items: flex-start;
    }
  }
  label {
    color: ${COLORS.GREYISH_BROWN_TWO};
    font-size: 1rem;
    width: 180px;
    padding-right: 20px;
    @media only screen and (max-width: 1200px) {
      font-size: 0.8rem;
    }
    @media only screen and (max-width: 900px) {
      padding-bottom: 10px;
    }
  }
  input {
    max-width: 411px;
    padding: 6px 11px;
    height: 43px;
    @media only screen and (max-width: 1400px) {
      max-width: 340px;
    }
    @media only screen and (max-width: 900px) {
      max-width: 260px;
    }
    @media only screen and (max-width: 450px) {
      max-width: 100%;
    }
  }
`;
export const FormInputCustom = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 21px;
  position: relative;
  label {
    ${P_2}
    flex: 1;
    max-width: 140px;
    text-align: right;
    padding-right: 20px;
    color: ${COLORS.GREYISH_BROWN_TWO};
    font-weight: normal;
  }
`;
export const FormCheckboxCustom = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 21px;
  position: relative;
  label {
    ${P_2}
    flex: 1;
    max-width: 140px;
    text-align: right;
    padding-right: 20px;
    color: ${COLORS.GREYISH_BROWN_TWO};
    font-weight: normal;
  }
  > div {
    margin-top: 5px;
  }
`;
export const InfoFormWrapper = styled.div`
  > div {
    position: relative;
  }
`;

export const UserDetailsRow = styled.div`
  display: flex;

  ${props =>
    typeof props.rowToColumn === "number"
      ? `
    flex-direction: column;
    @media all and (max-width: ${props.columnToRow}px)){
      flex-direction: column;
    }
  `
      : ""}
`;
