import React from "react";
import { Layout, P } from "../../styled";
import { Speichem } from "../../styled/buttons";
import { UserFilterOptions, FilterRight } from "../../styled/administration";

const UserFilter = ({ onPressUpload }) => (
  <React.Fragment>
    <UserFilterOptions>
      <Layout displayFlex alignCenter>
        <P type="type-10">User Verwaltung</P>
        <FilterRight>
          <Speichem onClick={onPressUpload}>
            Neuen User anlegen
            <i className="material-icons">person_add</i>
          </Speichem>
        </FilterRight>
      </Layout>
    </UserFilterOptions>
  </React.Fragment>
);

export default UserFilter;
