import React, { Component } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { ToastsContainer, ToastsStore } from "react-toasts";

import { Layout, LoadingDIv } from "../../styled";
import { DokumenteOuter } from "../../styled/dokumente";
import UserDetail from "../../components/Dokumente/UserDetail";
import Table from "../../components/Table";
import ResponseWarning from "../Response";
import UploadDokument from "../Modal/UploadDokument";
import Userphoto from "../../assets/images/empty.png";
import config from "../../lib/config";

class Dokument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: "",
    };
  }
  componentDidMount() {
    this.props.store.documents.fetch({
      client_id: this.props.store.client.data.client_id,
    });
  }

  onPressUpload = () => {
    const {
      store: { modal },
    } = this.props;
    modal.add({
      title: "Neues Dokument hochladen",
      component: UploadDokument,
    });
  };

  onChange = async search => {
    const {
      store: { searchDocuments, client },
    } = this.props;
    const searchQuery = search.target.value;
    try {
      await searchDocuments.fetch({
        searchQuery,
        client_id: client.data.client_id,
      });
      this.setState({ searchQuery });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      store,
      store: { documents, searchDocuments, client },
    } = this.props;
    const { searchQuery } = this.state;
    let namePrefix = client.data.salutation;
    let firstName = client.data.first_name;
    let lastName = client.data.last_name;
    const imageSource =
      client.data.profile_image_url === "uploads/empty.png" ? Userphoto : `${config.endpoints.api}/${client.data.profile_image_url}`;

    return (
      <DokumenteOuter>
        <Layout displayFlex alignCenter>
          <UserDetail
            onPressUpload={this.onPressUpload}
            onChange={this.onChange}
            imageSource={imageSource}
            fullName={namePrefix + " " + firstName + " " + lastName}
            search
          />
        </Layout>
        {documents.loading && !documents.loaded && <LoadingDIv>&nbsp;</LoadingDIv>}

        {searchQuery === "" &&
          (documents.loaded && documents.constructedTable.rows[0] ? <Table tableStore={documents} /> : <ResponseWarning />)}
        {searchQuery !== "" &&
          (searchDocuments.loaded && searchDocuments.constructedTable.rows[0] ? (
            <Table tableStore={searchDocuments} />
          ) : (
            <ResponseWarning />
          ))}
        <ToastsContainer lightBackground store={ToastsStore} />
      </DokumenteOuter>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(Dokument);
