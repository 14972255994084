
import _ from 'lodash';
import PromiseStore from "./PromiseStore";
import { decorate, computed, action, observable, toJS } from "mobx";
import { endpoints } from "../lib/endpoints";
import config from "../lib/config";

const calculateValue = (rows, field) => {
  let value = 0;
  rows.forEach(row => {
    value += Number(row[field] || 0 );
  });
  // {parseFloat(asset.asset_value || 0).toLocaleString('de-DE', {currency: 'EUR'})

  return value.toLocaleString('de-DE', { currency: 'EUR'});
};

const generateValue = value => `${parseFloat(value || 0).toLocaleString('de-DE', {currency: 'EUR'})}`;
class DecliningStore extends PromiseStore {
  constructor(query, __, root) {
    super(query);
    this.root = root;

    this.changedFields = {};
    this.changedValues = {};
    this.beneficiaryAmounts = {};
    this.timeouts = {};
    this.selected = {};
    this.updater = new PromiseStore(endpoints.update_asset_field);
  }

  select = (version) => {
    if(this.selected && this.selected[version]) {
      this.selected[version] = false;
    } else {
      this.selected[version] = true;
    }
  }

  clearSelected = () => {
    this.selected = {};
  }

  async fetch(data) {
    const response = await super.fetch(data);
    if(Object.keys(toJS(this.beneficiaryAmounts) || {}).length === 0) {
      this.beneficiaryAmounts = _.get(response, 'data.data.beneficiaryValues');
    }
  };

  onChangeFieldValue = (item, field) => e => {
    this.changedValues[`${item.assetId}_${field}`] = e.target.value;

    clearTimeout(this.timeouts[`${item.assetId}_${field}`]);

    this.timeouts[`${item.assetId}_${field}`] = setTimeout(async () => {

      const v = this.changedValues[`${item.assetId}_${field}`];
      await this.root.assetModifier.fetch({
        assetId: item.assetId,
        [field]: v || 0
      });
      this.root.assets.fetch({
        clientId: this.root.clientStore.selectedClientId
      });
    });
  }

  onChangeInputValue = (item) => (e) => {
    this.changedFields[item.id] = e.target.value;
    
    clearTimeout(this.timeouts[item.id]);
    this.timeouts[item.id] = setTimeout(() => {
      this.updater.fetch({
        fieldId: item.id,
        value: this.changedFields[item.id],
      });
    }, 2000);
  }

  onChangeBeneficiaryAmount = (row, beneficiary) => e => {
    const clientId = this.root.clientStore.selectedClientId;
    let value = e.target.value.replace(/\D/g, "");

    if(parseInt(value, 10) < 0) {
      value = 0;
    }
    if(!_.isObject(this.beneficiaryAmounts[clientId])) {
      this.beneficiaryAmounts[clientId] = {};
    }

    if(!_.isObject(this.beneficiaryAmounts[clientId][row.assetId])) {
      this.beneficiaryAmounts[clientId][row.assetId] = {};
    }
    this.beneficiaryAmounts[clientId][row.assetId][beneficiary] = value;
    
    
    let maxValue = Number(row.valueSold);
    
    Object.keys(this.beneficiaryAmounts[clientId][row.assetId]).forEach(k => {
      if(k !== beneficiary) {
        const singleValue = this.beneficiaryAmounts[clientId][row.assetId][k];
        maxValue -= singleValue;
      }
    });
    if(Number(value) > Number(maxValue)) {
      value = maxValue;
      this.beneficiaryAmounts[clientId][row.assetId][beneficiary] = value;
    }

    clearTimeout(this.timeouts[`${clientId.assetId}_${row.assetId}_${beneficiary}`]);

    this.timeouts[`${clientId.assetId}_${row.assetId}_${beneficiary}`] = setTimeout(() => {
      this.root.updateBeneficiaryAmount.fetch({
        clientId,
        assetId: row.assetId,
        beneficiaryId: beneficiary,
        amount: value
      });
    }, 300);

  }

  getBeneficiaryAmount = (assetId, beneficiaryId) => {
    const clientId = this.root.clientStore.selectedClientId;
    return _.get(this, `beneficiaryAmounts.${clientId}.${assetId}.${beneficiaryId}`);
  }

  
  get constructed() {
    if(!this.data) {
      return null;
    }
    const header = [{
      title: 'Vermögensgegenstände',
      large: true,
      scale: 0.85,
    }, {
      title: 'Wert in €',
      scale: 0.15,
    },
    ];

    const sections = [{
      title: 'Aktiva',
      scale: 0.45,
      value: 0,
      valueSold: 0,
      categories: []
    }, {
      title: 'Passiva',
      scale: 0.45,
      value: 0,
      valueSold: 0,
      categories: []
    }];


    this.data.assetCategories.forEach(assetCategory => {
      let index = 0;
      if(assetCategory.asset_category_type === 'liability') {
        index = 1;
      }

      const categoryAssets = this.data.assets.filter(parsedAsset => parsedAsset.asset_category_id === assetCategory.asset_category_id);
      
      sections[index].categories.push({
        asset_category_id: assetCategory.asset_category_id,
        title: assetCategory.asset_list_name,
        value: calculateValue(categoryAssets, 'asset_value'),
        value_sold: calculateValue(categoryAssets, 'asset_value_sold'),
        scales: [0.06, 0.21, 0.58, 0.15],
        determination: assetCategory.determination,
        determination_placeholder: 'Bestimmung wählen',
        determination_options: ['disposal', 'donation', 'legacy', 'sell', 'dispute', 'administration'],
        status_placeholder: 'Status',
        status_options: ['assigned', 'open', 'sold', 'not_sold', 'handing_over'],
        status: assetCategory.status,
        beneficiary: assetCategory.beneficiary ? assetCategory.beneficiary.split(',') : [],
        beneficiary_placeholder: 'Begünstigter',
        beneficiary_options: (this.root.personsListing.data || []).filter(b => b.is_beneficiary).map(beneficiary => beneficiary.person_id),
        rows: categoryAssets
          // .filter(asset => asset.resolution === 'unknown' || asset.status !== 'sold' )
          .filter(a => {
            
            return !a.deleted && (a.resolution === "unknown" || (a.status === "open" || a.status === "not_sold"));
          })
          .map(asset => {
            const beneficiaryOptions = this.root.personsListing.data || [];
            return {
              assetId: asset.asset_id,
              title: asset.asset_name,
              value: this.changedValues[`${asset.asset_id}_asset_value`] || asset.asset_value,
              status_placeholder: 'Status', 
              status_options: ['assigned', 'open', 'sold', 'not_sold', 'handing_over'],
              status: asset.status,
              beneficiary: asset.beneficiary ? asset.beneficiary.split(',') : [],
              beneficiary_placeholder: 'Begünstigter',
              beneficiary_options: beneficiaryOptions.filter(b => b.is_beneficiary).map(beneficiary => beneficiary.person_id),
              determination: asset.resolution,
              determination_placeholder: 'Bestimmung wählen',
              determination_options: ['disposal', 'donation', 'legacy', 'sell', 'dispute', 'administration'],
              valueSold: this.changedValues[`${asset.asset_id}_asset_value_sold`] || asset.asset_value_sold || 0,
              fields: {
                inputs: (asset.fields || []).map(field => ({
                  id: field.field_id,
                  name: field.field_id,
                  placeholder: field.title,
                  value: this.changedFields[field.field_id] || field.value || ''
                })),
                files: (asset.files || []).map(file => {
                  const n = file.file_url.split('/');
                  const title = n.length > 0 ? n[n.length - 1] : 'File';
                  return {
                    ...file,
                    title,
                    src: `${config.endpoints.api}/${file.file_url}`
                  };}),
              }
            };}),
      });
    });

    sections.forEach((s, si) => {
      let sValue = 0;
      let sValueSold = 0;
      s.categories.forEach((c, ci) => {
        let value = 0;
        let value_sold = 0;
        c.rows.forEach((r, ri) => {
          value += Number(r.value);
          value_sold += Number(r.valueSold);
        });

        sections[si].categories[ci].value = value;
        sections[si].categories[ci].value_sold = value_sold;

        sValue += Number(value);
        sValueSold += Number(value_sold);
      });

      sections[si].value = Number(sValue);
      sections[si].valueSold = Number(sValueSold);
    });

    return {
      header,
      sections
    };
  }
}
export default decorate(DecliningStore, {
  changedFields: observable,
  changedValues: observable,
  beneficiaryAmounts: observable,
  constructed: computed,
  onChangeInputValue: action,
  onChangeFieldValue: action,
  selected: observable,
  select: action,
  clearSelected: action,
});
