import styled from "styled-components";
import { COLORS, BORDER_RADIUS, LINEHEIGHT, FONTWEIGHT } from "./constants";

export const MandantenshboardOuter = styled.div`
  width: 100%;
  background: #fff;
`;
export const UserInfo = styled.div`
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    display: unset;
  }
  @media only screen and (max-width: 768px) {
    padding: 0;
    > p {
      text-align: center;
    }
  }
  padding: 45px 66px 30px 53px;
  width: 100%;
  p {
    margin-bottom: 50px;
  }
`;
export const UserPicture = styled.div`
  width: 95px;
  height: 95px;
  border-radius: ${BORDER_RADIUS.BR_6};
`;
export const InfoRight = styled.div`
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    display: unset;
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 15px;
    width: 50%;
    > div > div > div > p {
      width: 100%;
    }
  }
  margin-left: 67px;
  width: 90%;
  p {
    margin-bottom: 35px;
  }
  > div {
    > div {
      display: flex;
      flex-basis: 31%;
      margin-bottom: 30px;
      margin-right: 3%;
      &:nth-child(3n) {
        margin-right: 0;
      }
      p {
        margin-bottom: 0;
      }
      i {
        width: 35px;
        height: 35px;
        background: ${COLORS.SILVER};
        border-radius: ${BORDER_RADIUS.BR_6};
        text-align: center;
        line-height: ${LINEHEIGHT.LH_2};
        color: ${COLORS.NASTY_GREEN};
        font-size: 1rem;
        margin-right: 18px;
        min-width: 35px;
      }
    }
  }
`;
export const StatusBox = styled.div`
  width: 100%;
  padding: 45px 66px 30px 53px;
  > div {
    > div {
      flex-basis: 40%;
      > div {
        width: 80%;
      }
    }
    i {
      margin-right: 25px;
      color: ${COLORS.NASTY_GREEN};
    }
    p {
      margin-bottom: 17px;
    }
  }
  button {
    height: 43px;
    padding: 0px 15px;
    font-size: 0.9rem;
    font-weight: ${FONTWEIGHT.FW_6};
    border-radius: ${BORDER_RADIUS.BR_1};
    box-shadow: none;
    border: none;
    max-width: 270px;
    align-self: end;
    letter-spacing: 0;
    line-height: 40px;
    i {
      color: #fff;
      margin-right: 13px;
      position: relative;
      top: 7px;
    }
  }
`;
export const SelectStyle = styled.select`
  width: 100%;
  border: 2px solid ${COLORS.WHITE};
  height: 43px;
  padding: 10px 18px;
  color: ${COLORS.MEDIUM_GREY_TWO};
`;
