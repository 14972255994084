import moment from "moment";
export const userDashboardOrderAdapter = {
  client_number: {
    key: "row_1",
    order: 0,
    scale: 2,
    title: "Mandanten-ID",
  },
  first_name: {
    key: "row_2",
    order: 1,
    scale: 2,
    title: "Vorname",
  },
  last_name: {
    key: "row_3",
    order: 2,
    scale: 1,
    title: "Nachname",
  },
  birth_date: {
    key: "row_4",
    order: 3,
    scale: 1,
    title: "Alter",
  },
  current_phase: {
    key: "row_5",
    order: 4,
    scale: 1,
    title: "Phase aktuell",
  },
  expected_phase: {
    key: "row_6",
    order: 5,
    scale: 1,
    title: "Phase erwartet",
  },
};

export const userDashboardHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

// const declareDocument = async (event, store, index) => {
//   event.stopPropagation();
//   const { documents, editDocument } = store;
//   const doc = documents.data[index];

//   const data = {
//     is_delete: !doc.is_delete
//   };

//   await editDocument.fetch({
//     documentId: doc.document_id,
//     ...data
//   });
//   await documents.fetch();
//   console.log(store, index);
// };

/*****modal*****/

export const userDashboardRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];

        if (orderItem) {
          switch (itemKey) {
          case "birth_date":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: moment().diff(item[itemKey], "years", false),
            };
            break;
            // case 'has_access':
            // row[orderItem.order] = {
            //   key: `foo_${itemIndex}`,
            //   type: 'checkbox',
            //   options: item[itemKey]
            // };
            // break;
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          }
        }
      });
      rows[index] = row;
    });
  }
  return rows;
};
