import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Layout, P } from "../../styled";
import { FormInputLabel, TextInput, Error } from "../../styled/form";
import { UploadButton } from "../../styled/upload-button";

class AssetUploadButton extends React.Component {
  constructor(props) {
    super(props);

    this._input = React.createRef();

    this.state = {
      filename: null,
    };
  }

  onChange = async event => {
    const { onChange } = this.props;
    const filename = _.get(event, "target.files.0.name");

    this.setState({ filename });

    await onChange(event);
    this.setState({ filename: null });
  };

  render() {
    const { label, placeholder, assetId, onChange, ...rest } = this.props;
    const { filename } = this.state;
    return (
      <Layout column flex={1} displayFlex alignEnd justifyEnd offsetBottom={21}>
        <UploadButton onChange={this.onChange} ref={this._input} type="file" name={assetId} id={assetId} {...rest} />
        <label for={assetId}>
          {filename || label}
          <i className="material-icons">baseline_publish</i>
        </label>
      </Layout>
    );
  }
}

export { AssetUploadButton };
