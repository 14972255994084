import React from "react";
import styled from "styled-components";
import { COLORS } from "./constants";

export const AssetTableContainer = styled.div`
  width: 1750px;

  @media screen and (min-width: 1440px) {
    ${props => props.mobile};
  }
`;

export const AssetTableContainerItem = styled.div``;

export const AssetTableHeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: 0.2px;
  text-align: left;
  font-size: 11px;
  width: ${props => props.scale * 100}%;
  border-right: 1px solid ${COLORS.GUNMETAL_20};
  ${props =>
    props.large &&
    `
    font-size: 21px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: 0.2px;
    text-align: left;
  `}

  &:last-child {
    border-right: none;
  }
`;

export const AssetTableItem = styled.div`
  padding: 0px 15px 10px 0;
  display: flex;
  align-items: center;
  width: ${props => props.scale * 100}%;
  border-right: 1px solid ${COLORS.GUNMETAL_20};
  border-bottom: 1px solid ${COLORS.GUNMETAL_20};
  min-height: 52px;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: 0.2px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  word-break: break-word;
  ${props =>
    props.small &&
    `
    font-size: 11px;
  `}
  ${props =>
    props.vAlign === "top" &&
    `
    align-items: flex-start;
  `}
  ${props =>
    props.align === "end" &&
    `
    justify-content: flex-end;
  `}
  color: ${COLORS.GREYISH_BROWN_TWO};
  ${props =>
    props.heading &&
    `
    min-height: 42px;
  `}
  &:last-child {
    border-right: none;
  }
  i {
    font-size: 18px;
    margin-right: 20px;
    &:hover {
      opacity: 1;
      color: ${COLORS.GREYISH_BROWN_TWO};
    }
  }
`;

export const AssetTableRow = styled.div`
  display: flex;
  flex-direction: row;
  ${props =>
    props.heading &&
    `
    background-color: ${COLORS.LIGHT_GREY_TEN};
  `}
  ${props =>
    props.closed &&
    `
    height: 0px;
    overflow: hidden;
  `}
  ${props =>
    props.fade &&
    `
    background: #eaefe3;
    background: -moz-linear-gradient(top,  #eaefe3 3%, #cdd6c1 100%);
    background: -webkit-linear-gradient(top,  #eaefe3 3%,#cdd6c1 100%);
    background: linear-gradient(to bottom,  #eaefe3 3%,#cdd6c1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaefe3', endColorstr='#cdd6c1',GradientType=0 );  
  `}
`;

export const AssetTableInput = styled.input`
  width: 100%;
  height: 41px;
  border: solid 2px ${COLORS.WHITE_TWO};
  border-radius: 4px;
  font-family: Sansation;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${COLORS.GREEN_GREY_TWO};
  margin-bottom: 12px;
  ${props =>
    props.assetInput &&
    `
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.2px;
    color: rgb(62,62,62);
    text-align: right;
    margin-right: -10px;
    margin-left: -10px;
    padding-right: 10px;
    border: solid 2px rgba(232, 232, 232, 0.2);
    transition: all .1s ease;
    &:focus {
      border: solid 2px rgba(232, 232, 232, 1);
    }
  `}
`;

export const FilesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const FileContainer = styled.a`
  position: relative;
  ${props => (props.width ? `width: 100%;` : `width: calc(50% - 5px);`)}
  height: 40px;
  border: solid 1px ${COLORS.LIGHT_GREY_TEN};
  border-radius: 5px;
  background-color: rgb(239, 242, 236);
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0px 0 16px;
  overflow: hidden;

  span {
    position: absolute;
    left: 45px;
    white-space: nowrap;
    font-size: 14px;
  }
  &:nth-child(2n + 1) {
    margin-right: 5px;
  }
  &:nth-child(2n + 2) {
    margin-left: 5px;
  }
  i {
    margin-right: 0 !important;
    background-color: rgb(239, 242, 236);
    z-index: 50;
    &:nth-child(2) {
      padding-right: 10px;
      box-shadow: -5px 0px 5px rgb(239, 242, 236);
    }
  }
`;

// border: solid 1px #d0d8c7;
export const FileUploadButtonContainer = styled.input`
  box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.15);
  border: none;
  font-family: Sansation;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: 0.14px;
  text-align: left;
  border-radius: 3px;
  padding: 0 10px;
  color: ${COLORS.GREYISH_BROWN_TWO};
  margin-bottom: 15px;
  cursor: pointer;
`;

export const FileUploadButton = props => <FileUploadButtonContainer type="file" {...props} />;

export const TableFooterTextArea = styled.textarea`
  box-shadow: inset 0px 1px 10px 0 rgba(72, 84, 56, 0.21);
  border: solid 1px #d7dcd0;
  background-color: #eff2eb;
  flex: 1;
  padding: 23px 27px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: 0.15px;
  text-align: left;
  color: #7c8571;
`;
