import styled from "styled-components";
import { BORDER_RADIUS, COLORS, BOX_SHADOW, P_1 } from "./constants";

export const ContentWrapper = styled.div`
  flex: 1;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 0;
  //   -ms-overflow-style: none;  // IE 10+
  //   scrollbar-width: none;  // Firefox
  //   ::-webkit-scrollbar {
  //     display: none;  // Safari and Chrome
  // }
  overflow-x: hidden;
  padding: 60px;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 800px) {
    padding: 25px;
  }
`;

export const PageWrapper = styled.div`
  background: #fff;
  box-shadow: ${BOX_SHADOW.BS_1};
  border: 1px solid ${COLORS.LIGHT_GREY_TWO};
  border-radius: 4px;
`;

export const PageTitleContainer = styled.div`
  padding: 38px 10px 20px 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${COLORS.LIGHT_GREY_TWO};
`;

export const ContentContainer = styled.div`
  max-width: 1407px;
  width: 100%;
  height: auto;
  box-shadow: 0px 1px 18px 0 rgba(70, 84, 84, 0.2);
  background-color: white;
  border-radius: ${BORDER_RADIUS.BR_1};
  padding: 45px 41px 45px 38px;
  flex-wrap: wrap;
`;

export const PageTitle = styled.div`
  ${P_1}
  font-weight: bold;
  color: ${COLORS.PINE};
  letter-spacing: 0.29px;
  font-size: 29px;
  line-height: 29px;
`;

export const PageRow = styled.div`
  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
  padding: 34px 0px 34px 44px;
  width: 100%;
  border-bottom: 1px solid ${COLORS.LIGHT_GREY_TWO};
  ${props =>
    props.scroll &&
    ` overflow-x: scroll; 
      z-index: 0;
      // -ms-overflow-style: none;  // IE 10+
      // scrollbar-width: none;  // Firefox
    `}
`;
export const PageColumn = styled.div`
  flex: ${props => props.flex};
  ${props =>
    props.left &&
    `
    border-right: 1px solid ${COLORS.LIGHT_GREY_TWO};
  `}
  padding-top: 34px;
  ${props =>
    props.padding &&
    `
    padding-right: 50px;
    padding-left: 50px;
  `}
`;

export const PageHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 30px;
  margin-bottom: 30px;

  i {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 24px;
    color: ${COLORS.NASTY_GREEN};
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: 0.2px;
    text-align: left;
    color: ${COLORS.PINE};
    text-align: center;
  }
`;

export const PageFooter = styled.div`
  border-top: 1px solid ${COLORS.LIGHT_GREY_SEVEN};
  background-color: #eff2ec;
  padding: 40px 48px 60px 52px;
`;

export const Callout = styled.div`
  border: solid 1px ${COLORS.LIGHT_GREY_THREE};
  background-color: ${COLORS.LIGHT_GREY};
  border-radius: 4px;
  padding: 15px 20px 12px 20px;
  display: flex;
  alignitems: center;

  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.59;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${COLORS.PINE};

  margin-bottom: 30px;

  i {
    margin-right: 20px;
  }
`;

export const SumContainer = styled.div`
  padding: 0 15px;
  box-shadow: 0px 1px 5px 0 rgba(70, 84, 84, 0.12);
  border: solid 1px ${COLORS.LIGHT_GREY_TWO};
  background: white;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: 0.1px;
  text-align: left;
  color: rgb(71, 83, 56);
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  i {
    margin-right: 15px;
    color: rgb(161, 176, 140);
  }
`;
export const SumContainerCount = styled.div`
  width: 130px;
  height: 41px;
  display: flex;
  align-items: center;
  padding: 0 18px;
  // border-left: 1px solid ${COLORS.LIGHT_GREY_TWO};
`;
export const ResponseMessage = styled.div`
  width: 100%;
  texta-lign: center;
  .response {
    padding: 15px;
    border: solid 1px #f8d3c7;
    background: #feeae3;
    color: #f16c42;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 2px;
    margin-bottom: 30px;
    margin-top: 15px;
    text-align: center;
    line-height: 30px;
    &.success {
      border: solid 1px ${COLORS.TURTLE_GREEN};
      background: rgb(199, 224, 169);
      color: rgb(92, 146, 26);
    }
    i {
      position: relative;
      top: 4px;
      margin-right: 6px;
    }
  }
`;
export const ResponseMessageCustom = styled.div`
  width: 100%;
  .response {
    padding: 15px;
    border: solid 1px #f8d3c7;
    background: #feeae3;
    color: #f16c42;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 2px;
    margin-bottom: 30px;
    margin-top: 15px;
    line-height: 30px;
    text-align: center;
    &.success {
      border: solid 1px ${COLORS.TURTLE_GREEN};
      background: rgb(199, 224, 169);
      color: rgb(92, 146, 26);
    }
    i {
      position: relative;
      top: 4px;
      margin-right: 6px;
    }
  }
`;
export const ResponseMessageForgot = styled.div`
  width: 100%;
  .response {
    padding: 10px;
    border: solid 1px #f8d3c7;
    background: #feeae3;
    color: #f16c42;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 2px;
    margin-bottom: 30px;
    margin-top: 15px;
    line-height: 30px;
    &.success {
      border: solid 1px ${COLORS.TURTLE_GREEN};
      background: rgb(199, 224, 169);
      color: rgb(92, 146, 26);
    }
    i {
      position: relative;
      top: 6px;
      margin-right: 6px;
    }
  }
`;
export const ResponseMessageGraph = styled.div`
  width: 100%;
  padding: 0 5%;
  .response {
    padding: 15px;
    border: solid 1px #e1c86c;
    background: #ffeaa7;
    color: #8e681d;
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 2px;
    margin-bottom: 30px;
    margin-top: 30px;
    line-height: 23px;
    text-align: center;
    i {
      position: relative;
      top: 4px;
      margin-right: 6px;
      display: none;
      font-size: 1.4rem;
    }
    @media only screen and (min-width: 1000px) and (max-width: 1200px) {
      font-size: 0.8rem;
    }
  }
`;
export const ResponseWarning = styled.div`
  width: 100%;
  padding: 0 5%;
  .response {
    padding: 15px;
    border: solid 1px #e1c86c;
    background: #ffeaa7;
    color: #8e681d;
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 2px;
    margin-bottom: 30px;
    margin-top: 30px;
    line-height: 23px;
    text-align: center;
    i {
      position: relative;
      top: 4px;
      margin-right: 6px;
      display: none;
      font-size: 1.4rem;
    }
  }
`;

export const DoubleInputsWrapper = styled.div`
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  flex: 1;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  .error {
    line-height: 1;
  }
  ${props => (!!props.column ? `flex-direction: column;` : ``)}
  ${props => (!!props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ``)}

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
  @media screen and (max-width: 700px) {
    .form_input_error {
      bottom: 3px !important;
    }
  }
`;

export const DateContainer = styled.div`
  // <Layout
  // displayFlex
  // row
  // alignCenter
  // offsetBottom={80}
  // rowToColumn={700}
  // // centerToStart={800}
  // >
  display: flex;
  align-content: center;
  margin-bottom: 80px;
  // @media screen and (max-width: 900px) {
  //   flex-direction: column;
  // }
  @media screen and (min-width: 1500px) and (max-width: 1700px) {
    flex-direction: column;
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const InfoContainer = styled.div`
  padding-bottom: 60px;
  ${props => (props.spacingBottom ? `padding-bottom: ${props.spacingBottom}${props.unit || "px"};` : "")};

  display: flex;
  flex-direction: column;
  ${props => (props.fullWidth ? "width: 100%;" : "")};
  > div {
    > div {
      max-height: 200px;
      position: relative;
      max-height: none;
      // margin-bottom: 10px;
      flex-direction: column;
      align-items: flex-start;
      label {
        text-align: left;
      }
    }
    label {
      width: 30%;
      max-width: 180px;
      min-width: 180px;
      &.errorStyleDP {
        max-width: 100%;
        text-align: end;
        width: 100%;
      }
    }
  }
`;

export const LayoutPieChart = styled.div`
  border-top: 1px solid rgb(220, 225, 214);
`;
