import styled from "styled-components";

export const IndicatorItem = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  span {
    width: 100%;
    input {
      position: relative;
      width: 100%;
      max-width: 411px;
      height: 43px;
      background: #ccc;
      padding: 10px 12px;
      color: #282626;
      border: none;
      outline: none;
      border-radius: 5px;
      text-transform: capitalize;
    }
    &.blau {
      input {
        background: #71b0e3;
      }
    }
    &.grun {
      input {
        background: #95cc53;
      }
    }
    &.schwarz {
      input {
        background: #626262;
      }
    }
    &.rot {
      input {
        background: #ff8a65;
      }
    }
    &.grau {
      input {
        background: #cacaca;
      }
    }
    &.gelb {
      input {
        background: #f4d166;
      }
    }
  }
`;
