import React, { PureComponent } from "react";
import { P, Icon } from "../../styled";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";

import { PieChart, Pie, Cell, Tooltip } from "recharts";
import {
  GraphitemModal,
  GraphInfo,
  IndicatorItem,
  GraphColumn,
  GraphTitle,
  GraphsOuterModal,
  Graphitem,
  IndicatorList,
  IndicatorListAge,
} from "../../styled/graphs";
import { Layout } from "../../styled";
import { ResponseMessageGraph } from "../../styled/layout";
import AgeGraph from "./AgeGraph";
import { Label } from "../../styled";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Indicator = props => (
  <IndicatorItem style={{ display: "flex", justifyContent: props.justifyContent }}>
    <span color={props.color} className={props.className}>
      <i style={{ fontSize: "24px" }} className="material-icons props.class">
        stop
      </i>
    </span>
    <label style={{ fontSize: "15px", width: "50px" }}>{props.info}</label>
  </IndicatorItem>
);

// const warningMessage = `Aktuell haben Sie noch keine Mandanten angelegt. Bitte legen Sie
// zunächst Ihre Mandanten an, um eine Übersicht über Ihre Mandanten
//  zu generieren.`;
const warningMessage = `Aktuell liegen zu dieser Ansicht keine Informationen vor `;
class SummeAktuellGraph extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      store: { getClientAgePhases },
    } = this.props;
    getClientAgePhases.fetch();
  }

  render() {
    const {
      store: { getClientAgePhases },
    } = this.props;

    if (getClientAgePhases.loading || !getClientAgePhases.data) {
      return null;
    }

    const statusDetails = {
      blue: "Blau",
      green: "Grün",
      black: "Schwarz",
      red: "Rot",
      grey: "Grau",
      gray: "Grau",
      gold: "Gelb",
      yellow: "Gelb",
    };

    let currentArray = getClientAgePhases.data.currentArray
      ? getClientAgePhases.data.currentArray.map(e => {
        return {
          ...e,
          name: statusDetails[e.name],
        };
      })
      : [];

    return (
      <GraphsOuterModal>
        <GraphitemModal style={{ width: "100%" }}>
          {currentArray.length > 0 ? (
            <PieChart width={600} height={400}>
              <Pie
                data={currentArray}
                innerRadius={90}
                outerRadius={130}
                fill="#8bbf4c"
                paddingAngle={2}
                labelLine={true}
                label={renderCustomizedLabel}
                dataKey="value"
              >
                {currentArray.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          ) : (
            <ResponseMessageGraph>
              <div className="response success">
                <i className="material-icons">warning</i> {warningMessage}
              </div>
            </ResponseMessageGraph>
          )}
          <IndicatorList style={{ width: "350px", paddingLeft: "0px" }}>
            <Layout displayFlex row width={"500px"} justifyCenter>
              <Indicator justifyContent="flex-start" info="blau" className="case1" />
              <Indicator justifyContent="flex-end" info="grün" className="case2" />
              <Indicator justifyContent="flex-start" info="schwarz" className="case3" />
              <Indicator justifyContent="flex-end" info="rot" className="case4" />
              <Indicator justifyContent="flex-start" info="grau" className="case5" />
              <Indicator justifyContent="flex-end" info="gelb" className="case6" />
            </Layout>
          </IndicatorList>
        </GraphitemModal>
      </GraphsOuterModal>
    );
  }
}
export default compose(
  inject("store"),
  observer,
)(SummeAktuellGraph);
