import React from "react";
import moment from "moment";
import { Layout, Icon } from "../../styled";
import { UserPicture } from "../../styled/dokumente";
import {
  MandatenNumberLabel,
  UserProfileImageContainer
} from "../../styled/userprofilecard";
import { UserDetailsRow } from "../../styled/personalinfo";

const UserProfileMandantenNumber = ({
  mandantenNumber,
  birthday,
  updatedAt,
  firstName,
  lastName,
  salutation,
  imageSource,
  showDate
}) => (
  <Layout rowToColumn={1024} displayFlex>
    <UserProfileImageContainer>
      <UserPicture>
        <img src={imageSource} alt="user" />
        <h5>{`${salutation ? salutation : ""} ${firstName} ${lastName}`}</h5>
      </UserPicture>
    </UserProfileImageContainer>
    {mandantenNumber && (
      <Layout displayFlex row alignCenter justifyStart spacingRight={110} spacingLeft={20}>
        <Layout spacingTop={10} spacingRight={17}>
          <Icon type="material-icons">pages</Icon>
        </Layout>
        <Layout>
          <MandatenNumberLabel>Mandantennummer</MandatenNumberLabel>
          <Layout>{mandantenNumber}</Layout>
        </Layout>
      </Layout>
    )}
    {birthday && (
      <Layout displayFlex row alignCenter justifyStart spacingRight={110} offsetBottom={20} spacingLeft={20}>
        <Layout spacingTop={10} spacingRight={17}>
          <Icon type="material-icons">cake</Icon>
        </Layout>
        <Layout displayFlex column>
          <MandatenNumberLabel>Geburtsdatum</MandatenNumberLabel>
          <Layout>{moment(birthday).format("DD-MM-YYYY")}</Layout>
        </Layout>
      </Layout>
    )}
    {showDate && updatedAt && (
      <Layout displayFlex row alignCenter justifyStart offsetBottom={20} spacingLeft={20}> 
        <Layout spacingTop={10} spacingRight={17}>
          <Icon type="material-icons">date_range</Icon>
        </Layout>
        <Layout>
          <MandatenNumberLabel>Stand vom</MandatenNumberLabel>
          <Layout>{moment(updatedAt).format("DD-MM-YYYY")}</Layout>
        </Layout>
      </Layout>
    )}
  </Layout>
);

export default UserProfileMandantenNumber;
