import React from "react";
import { Layout } from "../../styled";
import { UserFilterOptions, UserPicture, FilterRight, ClientNumber } from "../../styled/reports";

const UserFilter = ({ search, onPressUpload, imageSource, fullName, clientNumber }) => (
  <UserFilterOptions>
    <Layout displayFlex alignCenter>
      <UserPicture>
        <img src={imageSource} alt="user" />
        <h5>{fullName}</h5>
      </UserPicture>
      <ClientNumber>
        <i className="material-icons">pages</i>
        <Layout displayFlex column>
          <h6>Mandantennummer</h6>
          <span>{clientNumber}</span>
        </Layout>
      </ClientNumber>
      {search && (
        <FilterRight>
          <input type="search" placeholder="Suche..." />
          <button onClick={onPressUpload}>
            Report erstellen
            <i className="material-icons">ballot</i>
          </button>
        </FilterRight>
      )}
    </Layout>
  </UserFilterOptions>
);

export default UserFilter;
