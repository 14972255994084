import Axios from "axios";
import React from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import _ from "lodash";

import { Layout } from "../../styled";
import { AssetTableButton } from "../../styled/buttons";
import { ToastsStore, ToastsContainer } from "react-toasts";
import config from "../../lib/config";

class PolicyFooter extends React.Component {
  handleExport = () => {
    const {
      store: { getReports, getRevisionFiles, assetRevisionCreate, clientStore },
    } = this.props;

    const clientId = clientStore.selectedClientId;

    const token = window.localStorage.getItem(config.keys.local_storage_key);

    Axios({
      method: "GET",
      url: config.endpoints.api + `/api/policy/list/${clientId}/export`,
      responseType: "application/json",
      headers: { Authorization: `${token}` },
    }).then(res => {
      // const array = ["1", "2"];
      // const array = res.data || [];
      // array.forEach((uri, i) => {
      //   setTimeout(() => {
      window.open(`${config.endpoints.api}/reportdownload/${res.data}`);
      //   }, i * 500);
      // });

      // getReports.clearSelected();
    });
  };

  render() {
    // const { value, errors } = this.state;
    // const {
    //   store: { client },
    // } = this.props;

    // const assets_updated_at = client.data ? client.data.assets_updated_at : "";
    // const user_updated = client.data ? client.data.user_updated : "";

    // var dateString = moment(new Date(assets_updated_at)).format("DD/MM/YYYY HH:mm");
    // const timestamp_value = user_updated + " / " + dateString;
    return (
      <Layout width="100%" offsetTop={20} displayFlex row alignEnd justifyEnd rowToColumn={1100} mediaCenter={1100}>
        <AssetTableButton style={{ height: 45 }} width={200} marginLeft={20} mediaFullWidth={1100} onClick={this.handleExport}>
          Exportieren
          <i style={{ marginLeft: 20 }} className="material-icons">
            save_alt
          </i>
        </AssetTableButton>
        <ToastsContainer lightBackground store={ToastsStore} />
      </Layout>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(PolicyFooter);
