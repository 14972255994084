import React from "react";
import PropTypes from "prop-types";
import { FormInputLabel, Error } from "../../styled/form";

const LoginInput = props => (
  <div>
    <i>
      <img src={props.img} alt={props.label} />
    </i>
    <span>
      {" "}
      <img src={props.icon} alt="" />{" "}
    </span>
    <label htmlFor="username">{props.label}</label>
    <input {...props} />
  </div>
);
const LoginFormInput = React.forwardRef(({ label, required, placeholder, error, ...rest }, ref) => (
  <div>
    {/* <i>
        <img src={img} alt={label} />
      </i>
      <span>
        <img src={icon} alt="" />
      </span> */}
    {label && (
      <FormInputLabel error={error}>
        {label}
        {required && <span>*</span>}
      </FormInputLabel>
    )}
    <LoginInput ref={ref} placeholder={placeholder || label} {...rest} error={error} />
    <Error textAlign="right" type="type-5">
      {error}
    </Error>
  </div>
));

LoginFormInput.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  img: PropTypes.img,
  icon: PropTypes.icon,
};

LoginFormInput.defaultProps = {
  required: false,
  placeholder: "",
  error: "",
};

export default LoginFormInput;
