import React, { Component } from "react";
import { compose } from "recompose";
import { Layout } from "../../styled";
import { inject, observer } from "mobx-react";
import { TotalClientsOuter } from "../../styled/myoverview";
import MandantenIcon from "../../assets/images/group.png";
import AverageAgeIcon from "../../assets/images/user-review.png";
import moment from "moment";

class TotalClients extends Component {
  componentDidMount() {
    const {
      store: { userDashboard },
    } = this.props;

    userDashboard.fetch();
  }

  render() {
    const {
      store: { userDashboard },
    } = this.props;

    if (userDashboard.loading || !userDashboard.data) {
      return null;
    }

    let ageArray = [];
    let numberOfelementsForAge = userDashboard.data ? userDashboard.data.length : 1;
    userDashboard.data.forEach(element => {
      if (element.death_date || element.birth_date === null) {
        if (numberOfelementsForAge > 1) numberOfelementsForAge -= 1;
      } else {
        let age = moment().diff(element.birth_date, "years", false);
        ageArray.push(age);
      }
    });

    let totalAge = ageArray.reduce((a, b) => a + b, 0);
    return (
      <React.Fragment>
        <TotalClientsOuter>
          <Layout displayFlex>
            <h5>
              <i>
                <img src={MandantenIcon} alt="mandanten" />
              </i>{" "}
              Mandanten gesamt : <span>{userDashboard.data.length}</span>
            </h5>
            <h6>
              <i>
                <img src={AverageAgeIcon} alt="Client Average Age" />
              </i>{" "}
              Durchschnittliches Alter meiner Mandanten :{" "}
              <span>{totalAge > 0 ? (totalAge / numberOfelementsForAge).toFixed(0) : 0 + " Jahre"} </span>{" "}
            </h6>
          </Layout>
        </TotalClientsOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(TotalClients);
