import React, { Component } from "react";
// import { observable } from 'mobx';
import { Provider } from "mobx-react";

import AppRouter from "./routes/routes";
import RootStore from "./store/RootStore";

export const store = new RootStore();

class App extends Component {
  componentDidMount() {
    store.init();
  }

  render() {
    return (
      <Provider store={store}>
        <AppRouter store={store} />
      </Provider>
    );
  }
}

export default App;
