import React from "react";
import { Layout } from "../../styled";
import { EditorContainer } from "../../styled/editor";
import SelectButton from "./SelectButton";

import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import Editor from "./Editor";

class ActivityEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: "public",
      editorState: null,
    };
  }

  componentDidMount() {
    this.setSelected("public")();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.notifyActionFlag !== this.props.notifyActionFlag) {
      const edValue = nextProps.value[this.state.active]
        ? EditorState.createWithContent(convertFromRaw(nextProps.value[this.state.active]))
        : EditorState.createEmpty();
      this.setState({
        editorState: edValue,
      });
    }
  }

  setSelected = type => () => {
    const { value, onChange } = this.props;
    const emptyState = EditorState.createEmpty();
    if (!value[type]) {
      onChange({
        ...value,
        [type]: convertToRaw(emptyState.getCurrentContent()),
      });
      this.setState({
        active: type,
        editorState: emptyState,
      });
    } else {
      this.setState({
        editorState: value[type] ? EditorState.createWithContent(convertFromRaw(value[type])) : emptyState,
        active: type,
      });
    }
  };

  setEditorState = state => {
    const { active } = this.state;
    const { onChange, value } = this.props;
    onChange({
      ...value,
      [active]: convertToRaw(state.getCurrentContent()),
    });
    this.setState({ editorState: state });
  };

  checkHasText = rawEdState =>
    !!(
      rawEdState &&
      EditorState.createWithContent(convertFromRaw(rawEdState))
        .getCurrentContent()
        .hasText()
    );

  render() {
    const { editorState, active } = this.state;
    const {
      value: { public: public_note, internal, billing, report },
    } = this.props;
    return (
      <EditorContainer>
        <Layout width="100%" displayFlex justifyStart scroll>
          <SelectButton
            onClick={this.setSelected("public")}
            selected={active === "public"}
            checked={this.checkHasText(public_note)}
            title="Öffentlich"
          />
          <SelectButton
            onClick={this.setSelected("internal")}
            selected={active === "internal"}
            checked={this.checkHasText(internal)}
            title="Intern"
          />
          <SelectButton
            onClick={this.setSelected("billing")}
            selected={active === "billing"}
            checked={this.checkHasText(billing)}
            title="Abrechnungsinfo"
          />
          <SelectButton
            onClick={this.setSelected("report")}
            selected={active === "report"}
            lastElement
            title="Berichtsinfo"
            checked={this.checkHasText(report)}
          />
        </Layout>
        {editorState && <Editor onChange={this.setEditorState} editorState={editorState} />}
      </EditorContainer>
    );
  }
}
export default ActivityEditor;
