import React, { Component } from "react";
import { P, Icon } from "../../styled";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";

import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { IndicatorItem, GraphsOuter, Graphitem, IndicatorListAge } from "../../styled/graphs";
import { ResponseMessageGraph } from "../../styled/layout";
import { GraphsOuterModal } from "../../styled/graphs";
import { GraphitemModal } from "../../styled/graphs";
import { Layout } from "../../styled";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Indicator = props => (
  <IndicatorItem style={{ display: "flex", justifyContent: props.justifyContent, width: props.width }}>
    <span color={props.color} className={props.className}>
      <i style={{ fontSize: "24px" }} className="material-icons props.class">
        stop
      </i>
    </span>
    <label style={{ fontSize: "15px", width: "50px" }}>{props.info}</label>
  </IndicatorItem>
);

const warningMessage = `Aktuell liegen zu dieser Ansicht keine Informationen vor `;
class AgeGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      store: { getClientAgePhases },
    } = this.props;
    getClientAgePhases.fetch();
  }

  render() {
    const {
      store: { getClientAgePhases },
    } = this.props;

    if (getClientAgePhases.loading || !getClientAgePhases.data) {
      return null;
    }

    const yearsDetails = {
      twenty: "20-29 Jahre",
      thirty: "30-39 Jahre",
      fourty: "40-49 Jahre",
      fifty: "50-59 Jahre",
      sixty: "60-69 Jahre",
      seventy: "70-79 Jahre",
      eighty: "80-89 Jahre",
      ninty: "90-99 Jahre",
      hundred: "Über 100 Jahre",
    };

    let ageArray = getClientAgePhases.data.ageObj
      ? getClientAgePhases.data.ageObj.map(e => ({
        ...e,
        name: yearsDetails[e.name],
      }))
      : [];

    return (
      <GraphsOuterModal>
        <GraphitemModal style={{ width: "100%" }}>
          {ageArray.length > 0 ? (
            <PieChart width={600} height={400}>
              <Pie
                data={ageArray}
                innerRadius={90}
                outerRadius={130}
                fill="#8bbf4c"
                paddingAngle={2}
                labelLine={true}
                label={renderCustomizedLabel}
                dataKey="value"
              >
                {ageArray.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          ) : (
            <ResponseMessageGraph>
              <div className="response success">
                <i className="material-icons">warning</i> {warningMessage}
              </div>
            </ResponseMessageGraph>
          )}
          <IndicatorListAge style={{ width: "350px", paddingLeft: "0px" }}>
            {/* <Layout displayFlex row width={"500px"} justifyCenter> */}
            <Indicator width="50%" justifyContent="flex-start" info="20-29 Jahre" className="twenty" />
            <Indicator width="50%" justifyContent="flex-end" info="30-39 Jahre" className="thirty" />
            <Indicator width="50%" justifyContent="flex-start" info="40-49 Jahre" className="fourty" />
            <Indicator width="50%" justifyContent="flex-end" info="50-59 Jahre" className="fifty" />
            <Indicator width="50%" justifyContent="flex-start" info="60-69 Jahre" className="sixty" />
            <Indicator width="50%" justifyContent="flex-end" info="70-79 Jahre" className="seventy" />
            <Indicator width="50%" justifyContent="flex-start" info="80-89 Jahre" className="eighty" />
            <Indicator width="50%" justifyContent="flex-end" info="90-99 Jahre" className="ninety" />
            <Indicator width="100%" justifyContent="flex-start" info="Über 100 Jahre" className="hundred" />
            {/* </Layout> */}
          </IndicatorListAge>
        </GraphitemModal>
      </GraphsOuterModal>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(AgeGraph);
