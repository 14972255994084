import React, { Component } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

import { ModalContent, ModalFooterOuter } from "../../../styled/modal";
import { DeleteClientOuter, DeleteClientInformation, DeleteClientConfirmMessage } from "../../../styled/deleteclient";
import { Abbrechen, Speichem } from "../../../styled/buttons";
import FormInput from "../../../components/Form/Input";

const CONFIRMATION_MESSAGE = "ich möchte diesen mandanten löschen";

class DeleteClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmationMessage: "",
      fields: {},
      errors: {},
    };
  }

  changeState = event => {
    this.setState({
      confirmationMessage: event.target.value,
      errors: { ...this.state.errors, confirmationMessage: "" },
    });
  };

  onDeleteClient = async () => {
    const {
      clientId,
      store: { deleteClient, clientsList },
    } = this.props;
    const { confirmationMessage } = this.state;

    if (confirmationMessage.toLowerCase() === CONFIRMATION_MESSAGE) {
      await deleteClient.fetch({
        clientId: clientId,
      });

      await clientsList.fetch();
      this.props.closeModal();
    } else {
      let errors = {};

      errors["confirmationMessage"] = "Falscher Text";

      if (Object.keys(errors).length > 0) {
        const key = Object.keys(errors)[0];
        if (this[key]) {
          this[key].focus();
        }
      }

      this.setState({ errors: errors });
    }
  };

  render() {
    return (
      <React.Fragment>
        <ModalContent>
          <DeleteClientOuter>
            <DeleteClientInformation>
              Sind Sie sicher, dass Sie den Mandanten löschen wollen? Wenn ja, dann geben Sie bitte in das grüne Textfeld folgenden Text
              ein:
            </DeleteClientInformation>
            <DeleteClientConfirmMessage color={"#cd443d"} spacingTop={"17"}>
              "Ich möchte diesen Mandanten löschen"
            </DeleteClientConfirmMessage>
            <FormInput
              label={""}
              placeholder="Geben Sie hier den Text ein"
              onChange={this.changeState}
              ref={el => {
                this.confirmationMessage = el;
              }}
              mediaWidth={250}
              error={this.state.errors.confirmationMessage}
            />
            <Speichem margin={"10px"} onClick={this.onDeleteClient}>
              Löschen bestätigen
            </Speichem>
            <DeleteClientInformation paddingTop={"35px"}>
              Wenn Sie den Mandanten nicht löschen möchten, klicken Sie auf die Abbrechen Schaltfläche.{" "}
            </DeleteClientInformation>
          </DeleteClientOuter>
        </ModalContent>
        <ModalFooterOuter align={"center"}>
          <Abbrechen margin={"20px 15px"} onClick={this.props.closeModal}>
            Abbrechen
          </Abbrechen>
        </ModalFooterOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(DeleteClient);
