import React from "react";
import PropTypes from "prop-types";

const FooterLink = ({ children, ...props }) => (
  <div className="FooterLinks">
    <a {...props}>{children}</a>
  </div>
);

FooterLink.propTypes = {
  children: PropTypes.string.isRequired,
};

export default FooterLink;
