import React from "react";
import _ from "lodash";
import { Layout } from "../../styled";
// import TextInput from '../Inputs/TextInput/Index';
import FormInput from "../Form/Input";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AssetTableButton } from "../../styled/buttons";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { TextInput } from "../../styled/form";
import { ToastsStore, ToastsContainer } from "react-toasts";
import * as Yup from "yup";
import { PrimaryInfo } from "../../styled/signup";
import moment from "moment";
import { autorun } from "mobx";

const INITIAL_STATE = {
  user_updated: "",
  assets_updated_at: new Date(),
};

class AssetFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      errors: {},
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.autorun = autorun(this.update);
  }

  componentWillUnmount() {
    this.autorun();
  }

  update = () => this.forceUpdate();

  onCreateRevision = isDecliningDir => async () => {
    const {
      store: { assetRevisionCreate, clientStore, declining },
    } = this.props;
    const { value } = this.state;
    this.setState({
      errors: {},
    });
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    const schema = Yup.object().shape({
      comment: Yup.string().required(requiredError),
    });
    try {
      const x = schema.validateSync(
        {
          comment: value,
        },
        { abortEarly: false },
      );
    } catch (e) {
      let errors = {};
      if (e.inner) {
        e.inner.forEach(e1 => {
          errors[e1.path] = e1.message;
        });
      }
      return this.setState({
        errors,
      });
    }

    await assetRevisionCreate.fetch({
      clientId: clientStore.selectedClientId,
      comment: value,
      isDecliningDir,
    });
    if (isDecliningDir) {
      ToastsStore.success("Sie haben das Nachlassverzeichnis erfolgreich erstellt");
      declining.fetch({
        clientId: clientStore.selectedClientId,
      });
    } else {
      ToastsStore.success("Sie haben das Vermögensverzeichnis erfolgreich eingefroren!");
    }
  };
  handleChangeValue = e => this.setState({ value: e.target.value });

  render() {
    const { value, errors } = this.state;
    const {
      store: { client },
    } = this.props;

    const assets_updated_at = client.data ? client.data.assets_updated_at : "";
    const user_updated = client.data ? client.data.user_updated : "";

    var dateString = moment(new Date(assets_updated_at)).format("DD/MM/YYYY HH:mm");
    const timestamp_value = user_updated + " / " + dateString;
    return (
      <PerfectScrollbar style={{ height: "100px" }}>
        <Layout width="100%" offsetTop={20} displayFlex row alignEnd justifyEnd rowToColumn={1100} mediaCenter={1100}>
          <Layout width="450px" mediaFullWidth={1100} offsetRight={20}>
            <TextInput name="user_timestamp" value={timestamp_value} maxWidth={420} footer placeholder="User / Timestamp" disabled />
          </Layout>
          <PrimaryInfo style={{ padding: "0" }}>
            <Layout width="350px" mediaFullWidth={1100}>
              <FormInput
                name="comment"
                value={value}
                error={errors && errors["comment"]}
                noOffset
                placeholder="Kommentar zum Einfrieren"
                onChange={this.handleChangeValue}
              />
            </Layout>
          </PrimaryInfo>
          <AssetTableButton style={{ height: 45 }} width={280} marginLeft={20} mediaFullWidth={1100} onClick={this.onCreateRevision(false)}>
            Vermögensverzeichnis einfrieren{" "}
            <i class="material-icons" style={{ marginLeft: 10 }}>
              filter_tilt_shift
            </i>
          </AssetTableButton>
          <AssetTableButton style={{ height: 45 }} width={280} marginLeft={20} mediaFullWidth={1100} onClick={this.onCreateRevision(true)}>
            Nachlassverzeichnis erstellen{" "}
            <i class="material-icons" style={{ marginLeft: 10 }}>
              pages
            </i>
          </AssetTableButton>
          <ToastsContainer lightBackground store={ToastsStore} />
        </Layout>
      </PerfectScrollbar>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(AssetFooter);
