import React from "react";
import { Icon } from "../../styled";
import { NewFilterSearchContainer, NewItemSearchInput, SearchBox } from "../../styled/newfiltersearch";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

class NewFilterSearch extends React.Component {
  render() {
    return (
      <SearchBox>
        <NewFilterSearchContainer>
          <Icon>search</Icon>
          <NewItemSearchInput
            // value={value}
            placeholder="Filtern"
            value={this.props.value}
            onChange={this.onTextChange}
          />
        </NewFilterSearchContainer>
      </SearchBox>
    );
  }

  onTextChange = e => this.props.onChange(e.target.value);
}

export default compose(
  inject("store"),
  observer,
)(NewFilterSearch);
