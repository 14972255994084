import React from "react";
import { Icon, Layout } from "../../styled";
import { PaginationItem } from "../../styled/table";

const constructPagination = (count, page) => {
  const pages = [];
  if (count <= 7) {
    const safeCount = count > 0 ? count : 1;
    return [...Array(safeCount).keys()];
  }
  if (page <= 3) {
    return [...Array(7).keys()];
  }
  if (page >= 3) {
    for (let i = page - 3; i < page; i++) {
      pages.push(i);
    }
    if (page + 3 <= count) {
      for (let i = page; i < page + 3; i++) {
        pages.push(i);
      }
    } else {
      for (let i = page; i < count; i++) {
        pages.push(i);
      }
    }
  }

  return pages;
};

const Pagination = ({ page, rowCount, show, onClickPage }) => {
  const pageCount = Math.ceil(rowCount / show);
  let pagination = constructPagination(pageCount, page);
  if (pagination.length === 0) {
    pagination = [0];
  }
  return (
    <Layout displayFlex row alignCenter justifyCenter>
      <Layout offsetRight={15}>
        <Icon onClick={() => (page > 0 ? onClickPage(page - 1) : null)}>chevron_left</Icon>
      </Layout>
      {pagination.map(p => (
        <Layout>
          <PaginationItem onClick={() => onClickPage(p)} selected={p === page}>
            {p + 1}
          </PaginationItem>
        </Layout>
      ))}
      <Layout offsetLeft={15}>
        <Icon onClick={() => (page + 1 < pageCount ? onClickPage(page + 1) : null)}>chevron_right</Icon>
      </Layout>
    </Layout>
  );
};

export default Pagination;
