import React, { Component } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import qs from "qs";

import withPageWrapper from "../hoc/Page";

import { Layout, P } from "../styled";
import { PageRow } from "../styled/layout";
import UserProfileMandatenNumber from "../components/UserProfileCard/UserProfileMandatenNumber";
import IconButton from "../components/IconButton";

import Review, { TIMER_ID as ReviewTimerId } from "../components/Activity/Review";
import Appointment, { TIMER_ID as AppointmentId } from "../components/Activity/Appointment";
import Correspondence, { TIMER_ID as CorrespondenceId } from "../components/Activity/Correspondence";
import Settlement, { TIMER_ID as SettlementId } from "../components/Activity/Settlement";
import Research, { TIMER_ID as ResearchId } from "../components/Activity/Research";

class Activities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "review",
      activeActivityId: null,
    };
  }
  async componentDidMount() {
    if (window.location.hash) {
      this.setState({ active: window.location.hash.substr(1) });
    }
    const {
      store: {
        client,
        clients,
        clientStore: { selectedClientId },
      },
    } = this.props;

    await clients.fetch();

    if (clients.data && clients.data.length > 0) {
      await client.fetch({
        clientId: selectedClientId,
      });
      this.forceUpdate();
    }

    if (window.location.search) {
      const parsed = qs.parse(window.location.search.substr(1));
      if (parsed && parsed.activity_id) {
        this.setState({ activeActivityId: parsed.activity_id });
      }
    }
  }

  setActive = type => () => {
    window.location.hash = "#" + type;
    this.setState({ active: type, activeActivityId: null });
  };

  setActiveWithId = (type, activeActivityId) => {
    window.location.hash = "#" + type;
    this.setState({ active: type, activeActivityId });
  };

  render() {
    const {
      store: { client, activityStartVomInfo },
    } = this.props;
    const { active, activeActivityId } = this.state;

    if (client.loading && !client.data) {
      return <P>Loading</P>;
    }
    return (
      <React.Fragment>
        <PageRow>
          <Layout displayFlex row alignStart rowToColumn={700}>
            {client.data && (
              <UserProfileMandatenNumber
                firstName={client.data.first_name}
                lastName={client.data.last_name}
                salutation={client.data.salutation}
                mandantenNumber={client.data.client_number}
                imageSource={client.data.profile_image_url}
                birthday={client.data.birth_date}
                createdAt={activityStartVomInfo.dateOfSelectedActivity}
                showDate={activityStartVomInfo.activityIsSelected}
              />
            )}
          </Layout>
        </PageRow>
        <PageRow scroll>
          <Layout displayFlex offsetRight={40}>
            <IconButton onClick={this.setActive("review")} active={active === "review"} title="Besprechung" icon="supervisor_account" />
            <IconButton
              onClick={this.setActive("appointment")}
              active={active === "appointment"}
              title="Aufgabe / Termin / Tätigkeit"
              icon="chrome_reader_mode"
            />
            <IconButton
              onClick={this.setActive("correspondence")}
              active={active === "correspondence"}
              title="Korrespondenz"
              icon="contact_mail"
            />
            <IconButton onClick={this.setActive("research")} active={active === "research"} title="Recherche" icon="search" />
            <IconButton
              onClick={this.setActive("settlement_activity")}
              active={active === "settlement_activity"}
              title="Aktivitäten Abrechnung"
              icon="euro_symbol"
            />
          </Layout>
        </PageRow>
        {active === "review" && client.data && (
          <Review client={client.data} activeActivityId={activeActivityId} changeActiveId={this.setActiveWithId} />
        )}
        {active === "appointment" && client.data && (
          <Appointment client={client.data} activeActivityId={activeActivityId} changeActiveId={this.setActiveWithId} />
        )}
        {active === "correspondence" && client.data && (
          <Correspondence client={client.data} activeActivityId={activeActivityId} changeActiveId={this.setActiveWithId} />
        )}
        {active === "research" && client.data && (
          <Research client={client.data} activeActivityId={activeActivityId} changeActiveId={this.setActiveWithId} />
        )}
        {active === "settlement_activity" && client.data && (
          <Settlement client={client.data} activeActivityId={activeActivityId} changeActiveId={this.setActiveWithId} />
        )}
      </React.Fragment>
    );
  }
}
export default compose(
  inject("store"),
  observer,
  withPageWrapper("Aktivitäten"),
)(Activities);
