import React from "react";
import { Layout, P } from "../../../styled";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import * as Yup from "yup";
import moment from "moment";

import { Button } from "../../../styled/buttons";
import { ModalFooterOuter, ModalContent } from "../../../styled/modal";
import { ResponseMessage } from "../../../styled/layout";
import TextareaInput from "../../../components/Form/Textarea";
import { TextInput } from "../../../styled/form";
import FormInput from "../../../components/Form/Input";
import DatePicker from "../../../components/Form/DatePicker";
import FormSelect from "../../../components/Form/InputSelect";
import { DatePickerItem, PrimaryInfo } from "../../../styled/signup";
import { PrimaryInfoTxt, SetStatusOptions } from "../../../styled/mandanteninformation";
import { UserInformation, InputHolder } from "../../../styled/createnewclient";
import { FormInputLabel } from "../../../styled/form";

const INITIAL_STATE = {
  expectedPhase: null,
  actualPhase: null,
  salutation: null,
  firstName: null,
  street: null,
  place: null,
  email: null,
  lastName: null,
  number: null,
  postCode: null,
  nameAffix: null,
  phoneNumber: null,
  title: null,
  mobileNumber: null,
  mandantenNumber: null,
  fax: null,
  taxNumber: null,
  taxOffice: null,
  taxIdentification: null,
  furtherPostcode: null,
  furtherLocation: null,
  furtherStreet: null,
  houseNumber: null,
  comment: null,
  dateOfBirth: null,
  dateOfDeath: null,
  uploading: false,
};

class CreateNewClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      fields: {},
      errors: {},
    };
  }
  handleChange = field => e => {
    this.setState({
      [field]: e.target.value === "" ? null : e.target.value,
      errors: { ...this.state.errors, [field]: "" },
    });
  };
  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";

    const schema = Yup.object().shape({
      actualPhase: Yup.string(requiredError)
        .required(requiredError)
        .nullable(),
      expectedPhase: Yup.string(requiredError)
        .required(requiredError)
        .nullable(),
      lastName: Yup.string()
        .required(requiredError)
        .nullable(),
      firstName: Yup.string()
        .required(requiredError)
        .nullable(),
      number: Yup.number("Nur Zahlen").nullable(),
      phoneNumber: Yup.number("Nur Zahlen").nullable(),
      postCode: Yup.number("Nur Zahlen").nullable(),
      mobileNumber: Yup.number("Nur Zahlen").nullable(),
      email: Yup.string()
        .email("E-Mail ist nicht gültig!")
        .nullable(),
    });

    try {
      schema.validateSync(fields, { abortEarly: false });
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(e => {
          if (e.message.includes("must be a `number` type, but the final value was: `NaN` (cast from the value")) {
            errors[e.path] = "Nur Zahlen";
          } else {
            errors[e.path] = e.message;
          }
        });
      }
    }

    formIsValid = schema.isValidSync(fields);

    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (this[key]) {
        this[key].focus();
      }
    }

    if (moment(this.state.dateOfBirth).isAfter(this.state.dateOfDeath)) {
      errors["dateOfDeath"] = "Date of death has to be after date of birth!";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleDateChange = category => data => {
    let today = new Date();
    let yesterday = new Date(today);
    if (category === "dateOfBirth") {
      yesterday.setDate(today.getDate() - 1);
      if (new Date(data) >= new Date(yesterday)) {
        this.setState({
          [category]: "",
          dateOfBirthError: "Sie können kein Datum in der Zukunft oder heute eingeben!",
        });
        return;
      }
      this.setState({ [category]: new Date(data), dateOfBirthError: " " });
    } else {
      if (new Date(data) >= new Date(yesterday)) {
        let errors;
        errors["dateOfDeath"] = "Sie können kein Datum in der Zukunft eingeben!!";
        this.setState({
          [category]: "",
          errors: errors,
        });
        return;
      }
      if (data === null) {
        this.setState({
          dateOfDeath: null,
        });
        return;
      }
      let errors;
      errors["dateOfDeath"] = "";
      this.setState({ [category]: new Date(data), errors: errors });
    }
  };

  handleSelected = category => value => {
    this.setState({ [category]: value });
  };
  handleSubmit = async () => {
    const {
      store: {
        addNewClient,
        clientStore,
        updateUser,
        user,
        declining,
        router: { history },
      },
    } = this.props;

    const {
      salutation,
      firstName,
      street,
      place,
      email,
      lastName,
      number,
      postCode,
      nameAffix,
      phoneNumber,
      title,
      mobileNumber,
      dateOfBirth,
      dateOfDeath,
      acceptanceDate,
      mandantenNumber,
      fax,
      taxNumber,
      taxOffice,
      taxIdentification,
      furtherPostcode,
      furtherLocation,
      furtherStreet,
      houseNumber,
      comment,
      actualPhase,
      expectedPhase,
    } = this.state;

    if (!this.handleValidation()) {
      return false;
    }
    //true
    this.setState({
      uploading: true,
    });
    const data = {
      salutation: salutation,
      e_mail_address: email,
      first_name: firstName,
      last_name: lastName,
      name_affix: nameAffix,
      street: street,
      number: number,
      place: place,
      postcode: postCode,
      phone_number: phoneNumber,
      title: title,
      mobile_number: mobileNumber,
      birth_date: dateOfBirth,
      death_date: dateOfDeath,
      acceptance_date: acceptanceDate,
      client_number: mandantenNumber,
      fax_number: fax,
      tax_number: taxNumber,
      tax_office: taxOffice,
      tax_identification_characteristic: taxIdentification,
      other_post_code: furtherPostcode,
      other_place: furtherLocation,
      other_street: furtherStreet,
      other_house_number: houseNumber,
      comment: comment,
      current_phase: actualPhase,
      expected_phase: expectedPhase,
      user_timestamp:
        "" +
        moment().format("DD.MM.YYYY, HH:mm, ") +
        this.props.store.getUserFromToken.data.first_name +
        " " +
        this.props.store.getUserFromToken.data.last_name,
    };

    let responseMessage = await addNewClient.fetch(data);
    if (responseMessage.data.data === "Client number already in use") {
      //false
      this.setState({
        uploading: false,
      });
      let errors = {};
      errors["mandantenNumber"] = "Kundennummer bereits verwendet";
      this.setState({
        errors: errors,
        message: (
          <div className="response">
            <i className="material-icons">error</i> Kundennummer bereits verwendet.
          </div>
        ),
      });
    } else {
      if (responseMessage.data) {
        const clientId = responseMessage.data.data.client_id;
        await updateUser.fetch({
          id: user.currentUser.id,
          selected_client: clientId,
        });
        await clientStore.setSelectedClientId(clientId);
        declining.fetch({
          clientId: clientStore.selectedClientId,
        });
        history.replace("/mandanten");
        return history.go();
      } else {
        //false
        this.setState({
          uploading: false,
        });
        this.setState({
          message: (
            <div className="response">
              <i className="material-icons">error</i> Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
            </div>
          ),
        });
      }
    }
  };

  render() {
    const { closeModal } = this.props;
    const { uploading } = this.state;
    return (
      <React.Fragment>
        <ModalContent>
          {/* <PersonalInfoOuter> */}
          <Layout spacingRight={40} spacingLeft={40} mediaPadding={600}>
            <UserInformation>
              <PrimaryInfo style={{ flexDirection: "column" }}>
                <Layout
                  displayFlex
                  // spacingLeft={20}
                  spacingRight={20}
                  flex={1}
                  width="100%"
                  row
                  rowToColumn={1000}
                >
                  <FormSelect
                    // required
                    noAdapter
                    label="Anrede"
                    onChange={this.handleSelected("salutation")}
                    options={["Frau", "Herr"].map(a => a)}
                    error={this.state.errors["salutation"]}
                  />
                  <FormInput
                    label="Mandantennummer"
                    onChange={this.handleChange("mandantenNumber")}
                    placeholder="Mandantennummer"
                    error={this.state.errors["mandantenNumber"]}
                  />
                </Layout>
                <Layout
                  displayFlex
                  // spacingLeft={20}
                  spacingRight={20}
                  flex={1}
                  width="100%"
                  row
                  rowToColumn={1000}
                >
                  <FormInput label="Titel" onChange={this.handleChange("title")} placeholder="Titel" />

                  <FormInput label="Namenszusatz" onChange={this.handleChange("nameAffix")} placeholder="Namenszusatz" />
                </Layout>
                <Layout
                  displayFlex
                  // spacingLeft={20}
                  spacingRight={20}
                  flex={1}
                  width="100%"
                  row
                  rowToColumn={1000}
                >
                  <FormInput
                    label="Vorname"
                    placeholder="Vorname"
                    onChange={this.handleChange("firstName")}
                    required
                    error={this.state.errors["firstName"]}
                    ref={el => {
                      this.firstName = el;
                    }}
                  />

                  <FormInput
                    required
                    label="Nachname"
                    onChange={this.handleChange("lastName")}
                    placeholder="Nachname"
                    error={this.state.errors["lastName"]}
                    ref={el => {
                      this.lastName = el;
                    }}
                  />
                </Layout>
                <Layout
                  displayFlex
                  // spacingLeft={20}
                  spacingRight={20}
                  flex={1}
                  width="100%"
                  row
                  rowToColumn={1000}
                >
                  <FormInput
                    label="Straße"
                    placeholder="Straße"
                    onChange={this.handleChange("street")}
                    // required
                    error={this.state.errors["street"]}
                    ref={el => {
                      this.street = el;
                    }}
                  />
                  <FormInput
                    // required
                    label="Hausnr"
                    onChange={this.handleChange("number")}
                    placeholder="Hausnr"
                    error={this.state.errors["number"]}
                    ref={el => {
                      this.number = el;
                    }}
                  />
                </Layout>
                <Layout
                  displayFlex
                  // spacingLeft={20}
                  spacingRight={20}
                  flex={1}
                  width="100%"
                  row
                  rowToColumn={1000}
                >
                  <FormInput
                    label="Ort"
                    placeholder="Ort"
                    // required
                    onChange={this.handleChange("place")}
                    error={this.state.errors["place"]}
                    ref={el => {
                      this.place = el;
                    }}
                  />

                  <FormInput
                    // required
                    label="PLZ"
                    placeholder="PLZ"
                    onChange={this.handleChange("postCode")}
                    error={this.state.errors["postCode"]}
                    ref={el => {
                      this.postCode = el;
                    }}
                  />
                </Layout>
                <Layout
                  displayFlex
                  // spacingLeft={20}
                  spacingRight={20}
                  flex={1}
                  width="100%"
                  row
                  rowToColumn={1000}
                >
                  <FormInput
                    label="Mobilfunknummer"
                    ref={el => {
                      this.mobileNumber = el;
                    }}
                    error={this.state.errors.mobileNumber}
                    placeholder="Mobilfunknummer"
                    onChange={this.handleChange("mobileNumber")}
                  />
                  <FormInput
                    // required
                    label="Faxnummer"
                    placeholder="Faxnummer"
                    onChange={this.handleChange("fax")}
                    error={this.state.errors["fax"]}
                    ref={el => {
                      this.fax = el;
                    }}
                  />
                </Layout>
                <Layout displayFlex spacingRight={20} flex={1} width="100%" row rowToColumn={1000}>
                  {/* <Layout
                    flex={1}
                    // spacingLeft={40}
                    spacingRight={20}
                    offsetBottom={30}
                    mediaPaddingLarge={1000}
                    mediaNoPadding={600}
                  > */}
                  <FormInput
                    label="Telefonnummer"
                    ref={el => {
                      this.phoneNumber = el;
                    }}
                    error={this.state.errors.phoneNumber}
                    onChange={this.handleChange("phoneNumber")}
                    placeholder="Telefonnummer"
                  />
                  {/* </Layout> */}
                  {/* <Layout
                    flex={1}
                    // spacingLeft={20}
                    spacingRight={40}
                    offsetBottom={30}
                    // style={{ transform: "translateX(-20px" }}
                  > */}
                  <DatePickerItem>
                    <FormInputLabel>
                      Geburtsdatum<span></span>
                    </FormInputLabel>
                    <div style={{ width: "100%", display: "flex" }}>
                      <DatePicker
                        value={this.state.dateOfBirth}
                        onChange={this.handleDateChange("dateOfBirth")}
                        error={this.state.errors.dateOfBirth}
                      />
                    </div>
                  </DatePickerItem>
                  {/* </Layout> */}
                </Layout>
                <Layout
                  displayFlex
                  // spacingLeft={20}
                  spacingRight={20}
                  flex={1}
                  width="100%"
                  row
                  rowToColumn={1000}
                >
                  <FormInput
                    label="E-Mailadresse"
                    placeholder="E-Mailadresse"
                    onChange={this.handleChange("email")}
                    error={this.state.errors["email"]}
                    ref={el => {
                      this.email = el;
                    }}
                  />

                  <DatePickerItem>
                    <FormInputLabel>
                      Amtsannahme<span></span>
                    </FormInputLabel>
                    <div style={{ width: "100%", display: "flex" }}>
                      <DatePicker
                        value={this.state.acceptanceDate}
                        onChange={this.handleDateChange("acceptanceDate")}
                        error={this.state.errors.acceptanceDate}
                      />
                    </div>
                  </DatePickerItem>

                  {/* </DoubleInputsWrapper> */}
                  {/* <Layout
                  displayFlex
                  spacingLeft={20}
                  spacingRight={20}
                  flex={1}
                  width="100%"
                  row
                >
                </Layout> */}
                  {/* <Layout
                  displayFlex
                  spacingLeft={20}
                  spacingRight={20}
                  flex={1}
                  width="100%"
                  column
                >
                </Layout> */}
                </Layout>
              </PrimaryInfo>
              <h6>Sterbedaten</h6>
              <PrimaryInfo>
                <Layout>
                  <DatePickerItem>
                    <FormInputLabel>Todestag</FormInputLabel>
                    <DatePicker
                      value={this.state.dateOfDeath}
                      onChange={this.handleDateChange("dateOfDeath")}
                      error={this.state.errors.dateOfDeath}
                    />
                  </DatePickerItem>
                </Layout>
              </PrimaryInfo>
              <h6 style={{ marginTop: "20px" }}>Steuerdaten</h6>
              <PrimaryInfo>
                <Layout displayFlex flex={1} width="100%" column>
                  <FormInput label="Steuernummer" placeholder="Steuernummer" onChange={this.handleChange("taxNumber")} />
                  <FormInput label="Finanzamt" placeholder="Finanzamt" onChange={this.handleChange("taxOffice")} />
                </Layout>
                <Layout displayFlex flex={1} width="100%" column offsetBottom={40}>
                  {/* <div className="customlabel"> */}
                  <FormInput
                    label="Steuerliches
                Identifikationsmerkmal"
                    placeholder="Steuerliches Identifikationsmerkmal"
                    onChange={this.handleChange("taxIdentification")}
                    error={this.state.errors["taxIdentification"]}
                    ref={el => {
                      this.taxIdentification = el;
                    }}
                  />
                  {/* </div> */}
                </Layout>
              </PrimaryInfo>
              <h6>Weitere Anschrift</h6>
              <PrimaryInfo>
                <Layout displayFlex flex={1} width="100%" column>
                  <FormInput label="PLZ" placeholder="PLZ" onChange={this.handleChange("furtherPostcode")} />
                  <FormInput label="Ort" placeholder="Ort" onChange={this.handleChange("furtherLocation")} />
                </Layout>
                <Layout displayFlex flex={1} width="100%" column>
                  <FormInput label="Straße" placeholder="Straße" onChange={this.handleChange("furtherStreet")} />
                  <FormInput label="Hausnummer " placeholder="Hausnummer " onChange={this.handleChange("houseNumber")} />
                </Layout>
              </PrimaryInfo>
              <h6>Mandantenstatus </h6>
              <SetStatusOptions>
                <PrimaryInfo>
                  {/* <DoubleInputsWrapper
                    displayFlex
                    spacingLeft={20}
                    spacingRight={20}
                    offsetBottom={30}
                    flex={1}
                    width="100%"
                    column
                    marginBottom={"30px"}
                  > */}
                  {/* <div className="customLineHeight"> */}
                  <Layout
                    displayFlex
                    // spacingLeft={20}
                    spacingRight={20}
                    flex={1}
                    width="100%"
                    row
                    rowToColumn={1000}
                  >
                    <FormSelect
                      required
                      noAdapter
                      style={{ background: "yellow" }}
                      label="Mandantenstatus aktuell"
                      options={["Blau", "Grün", "Gelb", "Rot", "Schwarz", "Grau"].map(a => a)}
                      error={this.state.errors["actualPhase"]}
                      onChange={this.handleSelected("actualPhase")}
                    />
                  </Layout>

                  {/* </div> */}
                  {/* </DoubleInputsWrapper>
                  <DoubleInputsWrapper
                    displayFlex
                    spacingLeft={20}
                    spacingRight={20}
                    offsetBottom={30}
                    flex={1}
                    width="100%"
                    column
                    marginBottom={"30px"}
                  > */}
                  {/* <div className="customLineHeight"> */}
                  <Layout
                    displayFlex
                    // spacingLeft={20}
                    spacingRight={20}
                    flex={1}
                    width="100%"
                    row
                    rowToColumn={1000}
                    style={{ marginTop: "10px" }}
                  >
                    <FormSelect
                      required
                      noAdapter
                      hasColor
                      label="Mandantenstatus erwartet"
                      options={["Blau", "Grün", "Gelb", "Rot", "Schwarz", "Grau"].map(a => a)}
                      error={this.state.errors["expectedPhase"]}
                      onChange={this.handleSelected("expectedPhase")}
                      // options={}
                      // ref={el => {
                      //   this.salutation = el;
                      // }}
                    />
                  </Layout>
                  {/* </div> */}
                  {/* </DoubleInputsWrapper> */}
                </PrimaryInfo>
                <P type="type-6">
                  Legende: blau = Beratung, grün = inaktive Vollmacht, gelb = bedingte Vollmacht, rot = Vollmachtsfall, schwarz =
                  Testamentsvollstreckung, grau = archiviert
                </P>
              </SetStatusOptions>
              <h6>Kommentar</h6>
              <PrimaryInfoTxt>
                <Layout displayFlex spacingLeft={20} spacingRight={20} flex={1} width="100%" column>
                  <TextareaInput label="Kommentar" placeholder="Kommentar" onChange={this.handleChange("comment")} />
                </Layout>
              </PrimaryInfoTxt>
              <ResponseMessage>
                {" "}
                <div> {this.state.message} </div>{" "}
              </ResponseMessage>
            </UserInformation>
            {/* </PersonalInfoOuter> */}
          </Layout>
        </ModalContent>
        <ModalFooterOuter>
          <InputHolder>
            <TextInput defaultValue="Timestamp last saved and user" />
          </InputHolder>
          <Button onClick={closeModal} color="grey">
            Abbrechen
          </Button>
          <Button onClick={!uploading ? this.handleSubmit : undefined} color="green">
            {uploading ? "Ihre Datei wird verarbeitet" : "Speichern"}
          </Button>
        </ModalFooterOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(CreateNewClient);
