import styled from "styled-components";
import { COLORS, BORDER_RADIUS, FONTWEIGHT } from "./constants";

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  z-index: 5000;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  ${props =>
    props.visible &&
    `
    transform: translateY(0px);
    opacity: 1;
    pointer-events: all;
  `}
`;
export const ModalBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  padding: 0;
  transition: all 0.3s ease;
  transform: translateY(50px);
  ${props =>
    props.visible &&
    `
    transform: translateY(0px);
  `}
  > div {
    border: 1px solid ${COLORS.WARM_GREY_FOUR};
    box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.24);
    background: #fff;
    margin: auto;
    border-radius: ${BORDER_RADIUS.BR_1};
    position: relative;
    max-height: 95vh;
    > i {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 15px;
      color: ${COLORS.LICHEN};
      cursor: pointer;
      @media only screen and (max-width: 600px) {
        right: 5px;
        top: 10px;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    width: 94%;
    margin: auto;
  }
`;
export const ModalContent = styled.div`
  width: 100%;
  padding: 35px 0;
  headmax-height: 500px;
  // overflow-y: auto;
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
  // overflow-x: hidden;
  // max-height: 600px;
  // overflow-y: auto;
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
  // overflow-x: hidden;
  @media only screen and (max-width: 800px) {
    padding: 25px 0;
  }
  @media only screen and (max-width: 600px) {
    padding: 15px 0;
    max-height: 400px;
    // overflow: auto;
    -ms-overflow-style: none; // IE 10+
    scrollbar-width: none; // Firefox
    ::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
  // overflow: visible;
  // position: relative;
`;
export const ModalFooterOuter = styled.div`
  width: 100%;
  background: ${COLORS.LIGHT_GREY};
  padding: 42px 28px;
  position: sticky;
  bottom: 0;
  ${props => (props.align ? `text-align: ${props.align}` : `text-align: end;`)}
  // @media only screen and (max-width: 800px) {
  //   padding: 25px;
  // }
  @media only screen and (max-width: 800px) {
    padding: 15px;
  }
  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > div {
      width: 100%;
      > input {
        max-width: none;
      }
    }
    > button {
      margin: 10px 0 0 0;
    }
  }
`;
export const ModalHeaderBox = styled.div`
  width: 100%;
  padding: 37px 0 28px 0;
  text-align: center;
  font-size: 1.68rem;
  font-weight: ${FONTWEIGHT.FW_6};
  box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.24);
  background: ${COLORS.LIGHT_GREY};
  color: ${COLORS.PINE};
  &.inactive {
    display: none;
  }
  @media only screen and (max-width: 800px) {
    padding: 25px 0;
    font-size: 1.2rem;
  }
`;
export const ActionModalBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  padding: 0;
  > div {
    border: 1px solid ${COLORS.WARM_GREY_FOUR};
    box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.24);
    background: #fff;
    width: 453px;
    margin: auto;
    border-radius: ${BORDER_RADIUS.BR_1};
    position: relative;
    @media only screen and (max-width: 800px) {
      width: 100%;
    }
    > i {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 15px;
      color: ${COLORS.LICHEN};
      cursor: pointer;
    }
  }
`;
export const ActionModalFooterOuter = styled.div`
  width: 100%;
  background: ${COLORS.LIGHT_GREY};
  padding: 30px 40px;
  text-align: center;
  button {
    min-width: 166px;
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
    @media only screen and (max-width: 800px) {
      min-width: auto;
    }
  }
  @media only screen and (max-width: 800px) {
    padding: 10px 25px;
  }
`;
export const WarningOuter = styled.div`
  width: 100%;
  text-align: center;
  padding: 45px 55px;
  i {
    color: ${COLORS.PINKISH_ORANGE};
    font-size: 2.8rem;
    margin-bottom: 25px;
  }
  h5 {
    color: ${COLORS.GREYISH_BROWN_TWO};
    font-size: 1.12rem;
    font-weight: ${FONTWEIGHT.FW_6};
  }
`;
export const SuccessOuter = styled.div`
  width: 100%;
  text-align: center;
  padding: 45px 55px;
  i {
    color: ${COLORS.NASTY_GREEN};
    font-size: 2.8rem;
    margin-bottom: 25px;
  }
  h5 {
    color: ${COLORS.GREYISH_BROWN_TWO};
    font-size: 1.12rem;
    font-weight: ${FONTWEIGHT.FW_6};
  }
`;
export const ConfirmationOuter = styled.div`
  width: 100%;
  text-align: center;
  padding: 45px 20px;
  i {
    color: ${COLORS.PINKISH_ORANGE};
    font-size: 2.8rem;
    margin-bottom: 25px;
  }
  h5 {
    color: ${COLORS.GREYISH_BROWN_TWO};
    font-size: 1.12rem;
    font-weight: ${FONTWEIGHT.FW_6};
  }
`;
export const ModalCopyBriefInfo = styled.div`
  width: 100%;
  min-width: 400px;
  text-align: center;
  .descCopy {
    padding: 0 4%;
    width: 100%;
    font-size: 1.01em;
    color: ${COLORS.GREYISH_BROWN_THREE};
    font-weight: bold;
    line-height: 20px;
    text-align: center;
  }
`;
export const NotizenForm = styled.div`
  width: 100%;
  > div {
    > div {
      > div > div {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        > div {
          width: 100%;
        }
      }
      label {
        width: 100%;
        padding-right: 0;
        max-width: 100%;
        text-align: left;
        margin-bottom: 10px;
      }

      input {
        height: 70px;
      }
    }
  }
`;

export const PrimaryTemplateInfo = styled.div`
  padding-bottom: 60px;
  ${props => (props.spacingBottom ? `padding-bottom: ${props.spacingBottom}${props.unit || "px"};` : "")};

  display: flex;
  flex-direction: column;
  ${props => (props.fullWidth ? "width: 100%;" : "")};
  > div {
    > div {
      max-height: 40px;
      ${props => (props.maxHeight ? `max-height: ${props.maxHeight}${props.unit || "px"};` : "")};
      position: relative;
      @media only screen and (max-width: 500px) {
        max-height: none;
        flex-direction: column;
        label {
          text-align: left;
        }
        > div {
          flex-flow: column;
          align-items: flex-start;
          label {
            max-width: 100%;
            width: 100%;
            padding-right: 0;
            text-align: left;
          }
          > div {
            width: 100%;
          }
        }
      }
    }
    label {
      width: 30%;
      max-width: 70px;
      min-width: 70px;
      &.errorStyleDP {
        max-width: 100%;
        text-align: end;
        width: 100%;
      }
      @media only screen and (max-width: 1600px) {
        width: 70px;
        max-width: 70px;
      }
    }
  }
`;

export const MobileInfo = styled.div`
  @media only screen and (max-width: 600px) {
    > div {
      flex-direction: column;
      > label {
        max-width: none !important;
        width: 100%;
        text-align: left;
      }
      > div {
        display: flex;
        flex-direction: column;
        > label {
          max-width: none !important;
          width: 100%;
          text-align: left;
        }
        > div {
          width: 100%;
        }
      }
    }
  }
`;
