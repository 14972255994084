import moment from "moment";
import ActionModalConfirmation from "../../components/Modal/ActionModalConfirmation";
import ChangeLicence from "../../components/Modal/ChangeLicence";
// import DownloadOptionModal from "../../components/Modal/Downloading";

export const licenseManagementOrderAdapter = {
  no: {
    key: "row_1",
    order: 0,
    scale: 0,
    title: "Nr.",
  },
  admin_role: {
    key: "row_2",
    order: 1,
    scale: 3,
    title: "Lizenzart",
  },
  valid_from: {
    key: "row_3",
    order: 2,
    scale: 2,
    title: "Lizenz gültig seit",
  },
  valid_to: {
    key: "row_4",
    order: 3,
    scale: 2,
    title: "Lizenz gültig bis",
  },
  status: {
    key: "row_5",
    order: 4,
    scale: 2,
    title: "Lizenz kündigen",
  },
  edit: {
    key: "row_6",
    order: 5,
    scale: 2,
    title: "Bezahlmethode ändern",
  },
};

export const licenseManagementHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

/*****modal*****/

const onPressUpload = (e, store) => {
  const { modal } = store;
  modal.add({
    title: "Bearbeiten Lizenz",
    component: ChangeLicence,
  });
};
const onPressDelete = (e, store, index) => {
  const { modal, licenseManagement } = store;
  let licenceRow = licenseManagement.data[index];
  let licenceId = licenceRow.licence_id;
  modal.add({
    title: "",
    component: ActionModalConfirmation,
    props: {
      licenceId,
    },
  });
};

export const licenseManagementRowAdapter = (data, order) => {
  const rows = {};
  if (data) {
    data.forEach((item, index) => {
      const row = [];
      row[0] = {
        key: `foo_${index}`,
        type: "text",
        data: [index + 1],
      };
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          case "id":
            break;
          case "client_licence_id":
            {
              row[0] = {
                key: `foo_${itemIndex}`,
                type: "text",
                data: "client",
              };
              row[1] = {
                key: `foo_${itemIndex}`,
                type: "text",
                data: "client",
              };
              row[2] = {
                key: `foo_${itemIndex}`,
                type: "text",
                data: "client",
              };
              row[3] = {
                key: `foo_${itemIndex}`,
                type: "text",
                data: "client",
              };
              row[4] = {
                key: `foo_${itemIndex}`,
                type: "text",
                data: "client",
              };
              row[5] = {
                key: `foo_${itemIndex}`,
                type: "text",
                data: "client",
              };
            }
            break;
          case "status":
            if (item.is_auto_renewal) {
              row[orderItem.order] = {
                key: `foo_${itemIndex}`,
                type: "icon",
                active: "cancel_presentation",
                inactive: "cancel_presentation",
                action: (store, index) => e => onPressDelete(e, store, index),
                data: item[itemKey],
              };
            } else {
              row[orderItem.order] = {
                key: `foo_${itemIndex}`,
                type: "text",
                color: "red",
                textAlign: "center",
                data: "CANCELED!",
              };
            }
            break;
          case "valid_from":
            {
              row[orderItem.order] = {
                key: `foo_${itemIndex}`,
                type: "text",
                data: moment(item[itemKey]).format("DD. MM. YYYY"),
              };
            }
            break;
          case "valid_to":
            {
              row[orderItem.order] = {
                key: `foo_${itemIndex}`,
                type: "text",
                data: moment(item[itemKey]).format("DD. MM. YYYY"),
              };
            }
            break;
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          }
        }
        row[5] = {
          key: `foo_${itemIndex}`,
          type: "icon",
          active: "edit",
          inactive: "edit",
          action: (store, index) => e => onPressUpload(e, store),
          data: item[itemKey],
        };
      });
      rows[index] = row;
    });
  }
  return rows;
};
