import React from "react";
import PropTypes from "prop-types";
import { Layout } from "../../styled";
import { FormInputLabel, TextArea, Error } from "../../styled/form";

const TextareaInput = React.forwardRef(({ label, required, placeholder, error, maxlength, position, ...rest }, ref) => (
  <Layout column flex={1} displayFlex justifyEnd>
    <Layout displayFlex row flex={1} alignCenter centerToStart={500}>
      {label && (
        <FormInputLabel error={error}>
          {label}
          {required && <span>*</span>}
        </FormInputLabel>
      )}
      <Layout fullWidth displayFlex alignStart flex={1}>
        <TextArea ref={ref} placeholder={placeholder || label} {...rest} error={error} maxlength={maxlength} />
      </Layout>
    </Layout>
    <Error position={position} textAlign="right" type="type-5" className="form_input_error">
      {error}
    </Error>
  </Layout>
));

TextareaInput.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
};

TextareaInput.defaultProps = {
  required: false,
  placeholder: "",
  error: "",
};

export default TextareaInput;
