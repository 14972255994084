import React from "react";
import { PageWrapper, PageTitleContainer, PageTitle } from "../styled/layout";

const withPageWrapper = title => Component => props => (
  <PageWrapper>
    <PageTitleContainer>
      <PageTitle>{title}</PageTitle>
    </PageTitleContainer>
    <Component {...props} />
  </PageWrapper>
);

export default withPageWrapper;
