import styled from "styled-components";

export const NotificationContainer = styled.div`
  background: black;
  color: white;
  position: fixed;
  z-index: 123333;
  right: 25%;
  top: 0px;
  box-shadow: 0px 1px 6px 0 rgba(0, 0, 0, 0.64);
  padding: 10px;
  border-radius: 3px;
  & p {
    font-size: 15px;
    line-height: 11px;
    letter-spacing: 0.15px;
  }
`;
