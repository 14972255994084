import React from "react";
import { ClientsContainer } from "../styled/clients";
import { P } from "../styled";
import withPageWrapper from "../hoc/Page";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import Table from "../components/Table";

class Clients extends React.Component {
  componentDidMount() {
    const {
      store: { clients },
    } = this.props;

    clients.fetch();
  }

  render() {
    const {
      store: { clients },
    } = this.props;
    return (
      <ClientsContainer>
        {clients.loading ? (
          <P offsetBottom={20}>Loading</P>
        ) : (
          <Table hideHeader noMarginBottom hideFooter tableStore={clients} onClickRow={clients.onSelectClient} />
        )}
      </ClientsContainer>
    );
  }
}

export default compose(
  inject("store"),
  observer,
  withPageWrapper("Meine Übersicht"),
)(Clients);
