import React from "react";
import { Layout, P } from "../styled";
import queryString from "query-string";
import { Speichem } from "../styled/buttons";

import { compose } from "recompose";
import { inject, observer } from "mobx-react";

import { routes } from "../routes/routes";
import { browserHistory } from "../routes/routes";

class Activate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      code: null,
    };
  }
  async componentDidMount() {
    const { activateAccount } = this.props.store;
    const parsed = queryString.parse(this.props.location.search);
    await activateAccount.fetch({
      email: parsed.email,
      code: parsed.code,
    });
    browserHistory.push(routes.LOGIN);
    browserHistory.go();
  }

  render() {
    return null;

    // return(
    //     <Layout displayFlex flex={1} alignCenter justifyCenter height="100%">
    //         <Layout>
    //             <Speichem onClick={this.activateAccount}>Aktivieren</Speichem>
    //         </Layout>
    //     </Layout>
    // );
  }
}
export default compose(
  inject("store"),
  observer,
)(Activate);
