import EditTemplate from "../../components/Modal/EditTemplate";

export const templatesOrderAdapter = {
  document_name: {
    key: "row_1",
    order: 0,
    scale: 4,
    title: "Vorlage",
  },
  file_type: {
    key: "row_2",
    order: 1,
    scale: 3,
    title: "Dateityp",
  },
  document_category: {
    key: "row_3",
    order: 2,
    scale: 4,
    title: "Beschreibung",
  },
  document_notes: {
    key: "row_5",
    order: 3,
    scale: 7,
    title: "Drucken",
  },
  is_declared: {
    key: "row_6",
    order: 4,
    scale: 1,
    title: "Vorlage überarbeiten",
  },
};

export const templatesHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

const declareDocument = async (event, store, index) => {
  event.stopPropagation();
  const { templates } = store;

  await templates.fetch();
};

const onPressUpload = (e, store) => {
  const { modal } = store;
  modal.add({
    title: "Vorlage bearbeiten",
    component: EditTemplate,
  });
};

export const templatesRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          case "id":
            break;
          case "is_declared":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "icon",
              active: "bookmark",
              inactive: "bookmark_border",
              action: (store, index) => e => declareDocument(e, store, index),
              data: item[itemKey],
            };
            break;
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          }
        }
        row[4] = {
          key: `foo_${itemIndex}`,
          type: "icon",
          active: "print",
          inactive: "print",
          action: (store, index) => e => onPressUpload(e, store),
          data: item[itemKey],
        };
        row[5] = {
          key: `foo_${itemIndex}`,
          type: "icon",
          active: "edit",
          inactive: "edit",
          action: (store, index) => e => onPressUpload(e, store),
          data: item[itemKey],
        };
      });
      rows[index] = row;
    });
  }
  return rows;
};
