import React from "react";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
import Axios from "axios";

import { Layout } from "../../styled";
import { BearbeitungsStatusOuter } from "../../styled/questioncatalogue";
import { Speichem } from "../../styled/buttons";
import DatePicker from "../Form/DatePicker";
import config from "../../lib/config";

class BearbeitungsStatus extends React.Component {
  constructor() {
    super();
    this.state = {
      value: "Offen",
    };
  }

  async componentWillMount() {
    await this.props.store.client.fetch({
      clientId: this.props.store.clientStore.selectedClientId,
    });

    let option = this.props.store.client && this.props.store.client.data && this.props.store.client.data.option_selected;
    if (option) {
      this.setState({
        value: option,
      });
    }
  }

  onClick = async event => {
    let clientId = this.props.store.client && this.props.store.client.data && this.props.store.client.data.client_id;
    const value = event.target.value;

    await this.props.store.updateClients.fetch({
      clientId: clientId,
      option_selected: value,
    });

    this.setState({
      value: value,
    });
  };

  handleExport = async () => {
    const {
      store: { clientStore },
    } = this.props;

    const clientId = clientStore.selectedClientId;

    const token = window.localStorage.getItem(config.keys.local_storage_key);

    Axios({
      method: "GET",
      url: config.endpoints.api + `/api/question/${clientId}/export`,
      responseType: "application/json",
      headers: { Authorization: `${token}` },
    }).then(res => {
      window.open(`${config.endpoints.api}/reportdownload/${res.data}`);
    });
  };

  render() {
    return (
      <BearbeitungsStatusOuter>
        <Layout displayFlex>
          <h3>Bearbeitungsstatus Fragenkatalog</h3>
          <div className="ProcessingOptions">
            <Layout displayFlex alignRight>
              <Layout offsetBottom={10} offsetRight={20} color={"transparent"}>
                <select onChange={this.onClick} value={this.state.value}>
                  <option value="Offen">Offen</option>
                  <option value="In Arbeit">In Arbeit</option>
                  <option value="Abgeschlossen">Abgeschlossen</option>
                </select>
              </Layout>
              {/* <Layout offsetBottom={10} color={"transparent"}>
            <DatePicker value={""} onChange={""} defaultValue="Final ausgefüllt am" />
          </Layout> */}
              <Speichem margin={"0 0 10px 0"} onClick={this.handleExport}>
                <i className="material-icons-outlined">save_alt</i>
                Exportieren
              </Speichem>
            </Layout>
          </div>
        </Layout>
      </BearbeitungsStatusOuter>
    );
  }
}
export default compose(
  inject("store"),
  observer,
)(BearbeitungsStatus);
