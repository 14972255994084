import React, { Component } from "react";
import { compose } from "recompose";
import { Layout, LoadingDIv } from "../styled";
import { MandantOuter, PageTitle, MandantDataOuter } from "../styled/mandant";
import SearchHead from "../components/MandantList/SearchHead";
import Table from "../components/Table";
import { inject, observer } from "mobx-react";
import ResponseWarning from "../components/Response";
import { withRouter } from "react-router-dom";
import { nullLiteral } from "@babel/types";
import { autorun } from "mobx";

class MandantList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: "",
    };
  }
  async componentDidMount() {
    await this.props.store.clientsList.fetch();
    this.forceUpdate();
  }

  async componentWillUnmount() {
    const {
      store: { searchClients },
    } = this.props;

    await searchClients.fetch({
      searchQuery: "",
    });
    this.setState({ searchQuery: "" });
  }

  onChange = async search => {
    const {
      store: { searchClients },
    } = this.props;
    const searchQuery = search.target.value.toLowerCase();
    try {
      await searchClients.fetch({
        searchQuery,
      });
      this.setState({ searchQuery });
    } catch (e) {
      console.log(e);
    }
  };

  handleRowClick = async row => {
    const {
      history,
      store: { clientStore, updateUser, user, declining },
    } = this.props;
    const rowClient = row.find(function(column) {
      return column.type === "id";
    });
    const rowClientId = rowClient ? rowClient.data : row[-1] ? row[-1].data : "";
    const client = this.props.store.clientsList.data.find(function(client) {
      return rowClientId ? client.client_id === rowClientId : null;
    });
    const clientId = client ? client.client_id : null;
    clientStore.setSelectedClientId(clientId);
    declining.fetch({
      clientId: clientStore.selectedClientId,
    });
    await updateUser.fetch({
      id: user.currentUser.id,
      selected_client: clientId,
    });
    history.push("/mandanten#stammdaten");
  };

  render() {
    const {
      store: { clientsList, searchClients },
    } = this.props;
    const { searchQuery } = this.state;

    return (
      <React.Fragment>
        <MandantOuter>
          <PageTitle>Mandanten Übersicht</PageTitle>
          <Layout displayFlex alignCenter>
            <SearchHead onPressUpload={this.onPressUpload} onChange={this.onChange} search />
          </Layout>
        </MandantOuter>
        <MandantDataOuter>
          {searchQuery === "" &&
            (clientsList.constructedTable.rows[0] ? (
              <Table
                sortable
                // handleRowClickSortable={this.handleRowClick}
                tableStore={clientsList}
                loading={clientsList.loading}
                show={10}
              />
            ) : (
              <ResponseWarning />
            ))}
          {searchQuery !== "" &&
            (searchClients.constructedTable.rows[0] ? (
              <Table
                sortable
                // handleRowClickSortable={this.handleRowClick}
                tableStore={searchClients}
                loading={clientsList.loading}
                show={10}
              />
            ) : (
              <ResponseWarning />
            ))}
        </MandantDataOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
  withRouter,
  // withPageWrapper("Mandanten Übersicht")
)(MandantList);
