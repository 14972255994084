import React from "react";
import moment from "moment";
import { InfoRight } from "../../styled/mandates";
import { P, Layout, Icon } from "../../styled";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { Speichem } from "../../styled/buttons";
import { UserPictureEdit } from "../../styled/clientmasterdata";
import config from "../../lib/config";
import Axios from "axios";

import PROFILE_PLACEHOLDER from "../../assets/images/empty.png";

const InfoItem = props => (
  <div>
    <i className={`${props.class} material-icons`}>{props.name}</i>
    <div>
      <P type="type-5">{props.title}</P>
      <P type="type-4">{props.info}</P>
    </div>
  </div>
);

class UserInfoRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileImage: "",
      isItFav: null,
    };
    this.upload = React.createRef();
  }

  async componentDidMount() {
    const {
      store: { client, clientStore, allFavouriteClients },
    } = this.props;
    if (!clientStore.selectedClientId) {
      return; // TODO: redirect somewhere
    }

    try {
      await client.fetch({ clientId: clientStore.selectedClientId });
      await allFavouriteClients.fetch();
      const x = allFavouriteClients.data.find(element => element.client_id === client.data.client_id);
      this.setState({
        profileImage: client.data.profile_image_url,
        isItFav: x ? true : false,
      });
    } catch (e) {
      // TODO: redirect somewhere
    }
  }
  handleUpload = async event => {
    const file = this.upload.current.files[0];
    const {
      store: {
        clientStore: { selectedClientId },
      },
    } = this.props;
    if (event) {
      const body = new FormData();
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = e => {
          this.setState({ profileImage: e.target.result });
        };
        reader.readAsDataURL(event.target.files[0]);
      }
      body.set("path", `./uploads/clients/images/` + selectedClientId);
      body.set("client_id", selectedClientId);
      body.append("TempFile", file);

      const token = window.localStorage.getItem(config.keys.local_storage_key);
      Axios({
        method: "POST",
        url: config.endpoints.api + "/api/clients/profile_image_upload",
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      }).then(file => {
        this.setState({
          profileImage: file.data.data,
        });
      });
    }
  };

  addFavourite = async () => {
    const { store } = this.props;

    if ((store.client.loading || !store.client.data) && !store.client.success) {
      return null;
    }
    await store.makeFavouriteClient.fetch({
      client_id: store.client.data.client_id,
    });
    if (store.makeFavouriteClient.success === true) {
      this.setState({ isItFav: true });
    }
  };

  removeFavourite = async () => {
    const { store } = this.props;

    if ((store.client.loading || !store.client.data) && !store.client.success) {
      return null;
    }
    await store.removeFavouriteClient.fetch({
      client_id: store.client.data.client_id,
    });
    if (store.removeFavouriteClient.success === true) {
      this.setState({ isItFav: false });
    }
  };
  render() {
    const {
      store: { client, getUserFromToken },
    } = this.props;
    const { profileImage } = this.state;

    if ((client.loading || !client.data) && !client.success) {
      return null;
    }

    let namePrefix = client.data.salutation;
    let firstName = client.data.first_name;
    let lastName = client.data.last_name;
    return (
      <div>
        <UserPictureEdit>
          <div className="imageholder">
            <img src={profileImage || PROFILE_PLACEHOLDER} alt="user" />
          </div>
          <Speichem>
            <input ref={this.upload} type="file" onChange={this.handleUpload} name="myFile" />
            Bild ändern
          </Speichem>
          {this.state.isItFav === true && getUserFromToken.data.number_of_fav_clients < 10 && (
            <Icon onClick={this.removeFavourite} type="material-icons">
              star
            </Icon>
          )}
          {this.state.isItFav === false && (
            <Icon onClick={this.addFavourite} type="material-icons">
              star_border
            </Icon>
          )}
        </UserPictureEdit>
        <InfoRight>
          <P type="type-3">{`${namePrefix ? `${namePrefix} ` : ""} ${firstName} ${lastName}`}</P>
          <Layout displayFlex flexWrap rowReverse>
            <InfoItem className="material-icons-outlined" name="room" title="Ort" info={client.data.place ? client.data.place : ""} />
            <InfoItem className="material-icons-outlined" name="account_box" title="Mandantennummer" info={client.data.client_number} />
            <InfoItem className="material-icons-outlined" name="phone" title="Telefonnummer" info={client.data.phone_number} />

            <InfoItem
              className="material-icons-outlined"
              name="cake"
              title="Geburtsdatum"
              info={client.data.birth_date ? moment(client.data.birth_date).format("DD-MM-YYYY") : ""}
            />
            <InfoItem
              className="material-icons-outlined"
              name="location_city"
              title="Straße und Hausnr"
              info={client.data.street && client.data.number ? client.data.street + " " + client.data.number : " "}
            />
            <InfoItem className="material-icons-outlined" name="mobile_friendly" title="Mobilfunknummer" info={client.data.mobile_number} />

            <InfoItem
              className="material-icons-outlined"
              name="flare"
              title="Todestag"
              info={client.data.death_date ? moment(client.data.death_date).format("DD-MM-YYYY") : "--"}
            />
            <InfoItem className="material-icons-outlined" name="move_to_inbox" title="PLZ" info={client.data.postcode} />
            <InfoItem className="material-icons-outlined" name="mail" title="E-Mailadresse" info={client.data.e_mail_address} />

            <InfoItem
              className="material-icons-outlined"
              name="flare"
              title="Amtsannahme"
              info={client.data.acceptance_date ? moment(client.data.acceptance_date).format("DD-MM-YYYY") : "--"}
            />
          </Layout>
        </InfoRight>
      </div>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(UserInfoRight);
