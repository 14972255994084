import _ from "lodash";
import React from "react";
import qs from "qs";
import { Layout, Icon } from "../../styled";
import { AssetTableContainer, AssetTableItem, AssetTableHeaderItem, AssetTableInput } from "../../styled/assets-table";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AssetTableRow } from "../../styled/assets-table";
import Select from "../Form/Select";
import TableFile from "../TableFile";
import { FilesContainer } from "../../styled/assets-table";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { AssetUploadButton } from "../Form/UploadButton";
import config from "../../lib/config";
import Axios from "axios";
import { Button } from "../../styled/buttons";
import { AssetTableButton } from "../../styled/buttons";
import DownloadingDiv from "../Form/Loader";

export const adaptOption = option => {
  switch (option) {
  case "assigned":
    return "Zugeordnet";
  case "open":
    return "Offen";
  case "sold":
    return "Veräußert";
  case "not_sold":
    return "Nicht veräußert";
  case "handing_over":
    return "Übergeben";
  case "disposal":
    return "Entsorgen";
  case "donation":
    return "Freigiebige Zuwendungen";
  case "legacy":
    return "Vermächtnis";
  case "sell":
    return "Verkaufen";
  case "dispute":
    return "Auseinandersetzen";
  case "administration":
    return "Verwaltung";
  case "asset":
    return "Aktiva";
  case "liability":
    return "Passiva";
  case "Rot":
    return "red";
  case "Blue":
    return "blue";
  case "Grün":
    return "green";
  case "Grau":
    return "gray";
  case "Schwarz":
    return "black";
  case "Gelb":
    return "yellow";
  default:
    return undefined;
  }
};

class DistributedAssetList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetBeneficiaries: {},
      assetCategoryBeneficiaries: {},
      closedRows: {},
    };
  }

  async componentDidMount() {
    const {
      store: { assets, clientStore, personsListing },
      // client
    } = this.props;
    await personsListing.fetch({
      clientId: clientStore.selectedClientId,
    });
    assets.fetch({
      clientId: clientStore.selectedClientId,
    });
  }

  getPersonName = beneficiaryId => {
    const {
      store: { personsListing },
    } = this.props;

    const person = personsListing.data.find(item => item.person_id === beneficiaryId);

    return !person ? "" : `${person.first_name || ""} ${person.last_name || ""}`;
  };

  removeBeneficiary = (asset, beneficiaryId) => e => {
    const {
      store: { assets, assetModifier, clientStore },
    } = this.props;
    if (asset.beneficiary.length === 0) {
      return this.setState({ assetBeneficiaries: {} });
    }
    const beneficiaries = _.get(asset, "beneficiary", []);
    const filtered = beneficiaries.filter(b => b !== beneficiaryId);

    assetModifier
      .fetch({
        assetId: asset.assetId,
        beneficiary: filtered.join(","),
      })
      .then(() => {
        assets.fetch({
          clientId: clientStore.selectedClientId,
        });
      });
  };

  closeRow = categoryId => () => {
    this.setState(state => ({
      closedRows: {
        ...state.closedRows,
        [categoryId]: !state.closedRows[categoryId],
      },
    }));
  };

  handleDeleteFile = file => async event => {
    event.preventDefault();
    const {
      store: { deleteAssetFile, assets, clientStore },
    } = this.props;
    const response = window.confirm("Are you sure");
    if (response) {
      await deleteAssetFile.fetch({
        fileId: file.file_id,
      });
      assets.fetch({
        clientId: clientStore.selectedClientId,
      });
    }
  };
  handleChangeAssetCategory = assetCategory => body => {
    const {
      store: { assets, assetCategoryModifier, clientStore },
    } = this.props;

    assetCategoryModifier
      .fetch({
        assetCategoryId: assetCategory.asset_category_id,
        ...body,
      })
      .then(() => {
        assets.fetch({
          clientId: clientStore.selectedClientId,
        });
      });
  };

  handleChangeAssetStatus = asset => status => {
    const {
      store: { assets, assetModifier, clientStore },
    } = this.props;

    assetModifier
      .fetch({
        assetId: asset.assetId,
        status,
      })
      .then(() => {
        assets.fetch({
          clientId: clientStore.selectedClientId,
        });
      });
  };

  render() {
    const {
      store: { assets, personsListing, sidebarOpen },
    } = this.props;

    if ((assets.loading && !assets.loaded) || !assets.constructed) {
      return (
        <Layout style={{ marginTop: "20px" }}>
          <DownloadingDiv />
        </Layout>
      );
    }

    if (assets.data && assets.data.assets.length === 0) {
      return null;
    }

    const { assetBeneficiaries, assetCategoryBeneficiaries, closedRows } = this.state;

    const isWindows = config.isWindows;
    console.log(assets.distributed.sections);
    return (
      <PerfectScrollbar>
        <AssetTableContainer
          mobile={
            sidebarOpen
              ? `max-width: calc(100vw - ${468 + (isWindows ? 19 : 2)}px)`
              : `max-width: calc(100vw - ${240 + (isWindows ? 19 : 2)}px)`
          }
        >
          <AssetTableRow>
            {assets.distributed.header.map(({ title, ...rest }) => (
              <AssetTableHeaderItem {...rest}>{title}</AssetTableHeaderItem>
            ))}
          </AssetTableRow>
          {assets.distributed.sections.map((section, sectionIndex) => (
            <React.Fragment>
              <AssetTableRow heading fade>
                <AssetTableItem heading scale={section.scale}>
                  <Icon type="material-icons" onClick={this.closeRow(section.title)}>
                    {!closedRows[section.title] ? "indeterminate_check_box" : "add_to_photos"}
                  </Icon>
                  {`${section.title}`}
                </AssetTableItem>
                {section.blanks.map(blank => (
                  <AssetTableItem heading scale={blank} />
                ))}
              </AssetTableRow>
              {section.categories.map((cat, catIndex) => {
                if (cat.rows.length === 0) {
                  return null;
                }
                return (
                  <React.Fragment>
                    <AssetTableRow closed={closedRows[section.title]}>
                      <AssetTableItem scale={cat.scales[0]}>
                        <Icon type="material-icons" onClick={this.closeRow(cat.asset_category_id)}>
                          {!closedRows[cat.asset_category_id] ? "indeterminate_check_box" : "add_to_photos"}
                        </Icon>
                        {catIndex + 1}
                      </AssetTableItem>
                      <AssetTableItem scale={cat.scales[1]}>{cat.title}</AssetTableItem>
                      <AssetTableItem scale={cat.scales[2]} />
                      <AssetTableItem heading align="end" scale={cat.scales[3]}>
                        {cat.value}
                      </AssetTableItem>
                      <AssetTableItem heading scale={cat.scales[4]}>
                        <Layout fullWidth column displayFlex alignCenter justifyCenter>
                          {/* {adaptOption(cat.status)} */}
                          {/* <AssetTableButton onClick={() => this.handleChangeAssetCategory(cat)({ status: 'open' })}>Rückgängig</AssetTableButton> */}
                        </Layout>
                      </AssetTableItem>
                      <AssetTableItem heading scale={cat.scales[5]}>
                        {(cat.beneficiary || []).map(b => (
                          <React.Fragment>
                            {this.getPersonName(b)}
                            <br />
                          </React.Fragment>
                        ))}
                      </AssetTableItem>
                      <AssetTableItem heading align="end" scale={cat.scales[6]}>
                        {cat.value_sold}
                      </AssetTableItem>
                    </AssetTableRow>
                    {cat.rows.map((row, rowIndex) => (
                      <AssetTableRow closed={closedRows[section.title] || closedRows[cat.asset_category_id]}>
                        <AssetTableItem vAlign="top" align="end" scale={cat.scales[0]}>
                          {`${catIndex + 1}.${rowIndex + 1}`}
                        </AssetTableItem>
                        <AssetTableItem vAlign="top" scale={cat.scales[1]}>
                          {row.title}
                        </AssetTableItem>
                        <AssetTableItem vAlign="top" scale={cat.scales[2]}>
                          <Layout displayFlex column fullWidth>
                            {row.fields && row.fields.inputs && (
                              <Layout fullWidth offsetBottom={20}>
                                {row.fields.inputs.map(input => (
                                  <Layout>{`${input.placeholder}: ${input.value}`}</Layout>
                                ))}
                              </Layout>
                            )}
                          </Layout>
                        </AssetTableItem>
                        <AssetTableItem vAlign="top" align="end" scale={cat.scales[3]}>
                          {row.value}
                        </AssetTableItem>
                        <AssetTableItem vAlign="top" scale={cat.scales[4]}>
                          <Layout fullWidth column displayFlex alignCenter justifyCenter>
                            {adaptOption(row.status)}
                            <AssetTableButton onClick={() => this.handleChangeAssetStatus(row)("open")} style={{ marginTop: 10 }}>
                              Rückgängig
                            </AssetTableButton>
                          </Layout>
                        </AssetTableItem>
                        <AssetTableItem vAlign="top" scale={cat.scales[5]}>
                          {(row.beneficiary || []).map(b => (
                            <React.Fragment>
                              {this.getPersonName(b)}
                              <br />
                            </React.Fragment>
                          ))}
                        </AssetTableItem>
                        <AssetTableItem vAlign="top" align="end" scale={cat.scales[6]}>
                          {row.valueSold}
                        </AssetTableItem>
                      </AssetTableRow>
                    ))}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          ))}
        </AssetTableContainer>
      </PerfectScrollbar>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(DistributedAssetList);
