import React from "react";
import { Layout } from "../../../styled";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { Speichem } from "../../../styled/buttons";
import { ActionModalFooterOuter, ModalCopyBriefInfo } from "../../../styled/modal";

class PersonalBriefInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //
  }

  render() {
    const { closeModal } = this.props;
    return (
      <React.Fragment>
        <ModalCopyBriefInfo>
          <Layout displayFlex row spacingTop={40} fullWidth spacingBottom={40}>
            <div className="descCopy">{this.props.store.clientStore.personBriefInfo}</div>
          </Layout>
        </ModalCopyBriefInfo>
        <ActionModalFooterOuter>
          <Speichem onClick={closeModal}>OK</Speichem>
        </ActionModalFooterOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(PersonalBriefInfo);
