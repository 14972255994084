import React, { Component } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import moment from "moment";

import { Layout } from "../../styled";
import { ActionsOuter, InputHolder } from "../../styled/questioncatalogue";
import { TextInput } from "../../styled/form";
import { Speichem } from "../../styled/buttons";
import AddNewQuestion from "../Modal/AddNewQuestion";

class Actions extends Component {
  constructor() {
    super();

    this.state = {
      updated: "Timestamp last saved and user",
    };
  }
  openModal = (e, shouldReload) => {
    e.preventDefault();
    this.props.store.modal.add({
      title: "Neue Frage hinterlegen",
      component: AddNewQuestion,
      props: {
        shouldReload: shouldReload,
      },
    });
  };

  async componentDidMount() {
    const clientId = this.props.store.client.data && this.props.store.client.data.client_id;
    try {
      const response = await this.props.store.getAllQuestions.fetch({
        clientId,
      });

      const questions = response.data.data.sort(function(a, b) {
        if (moment(a.updatedAt) > moment(b.updatedAt)) {
          return -1;
        }
        if (moment(a.updatedAt) < moment(b.updatedAt)) {
          return 1;
        }
        return 0;
      });
      this.setState({
        updated:
          moment(questions[0].updatedAt).format("DD.MM.YYYY, HH:mm, ") +
          this.props.store.getUserFromToken.data.first_name +
          " " +
          this.props.store.getUserFromToken.data.last_name,
      });
    } catch (e) {
      this.setState({ questions: [] });
    }
  }

  render() {
    return (
      <ActionsOuter>
        <Layout mediaPadding={900} spacingRight={40} spacingLeft={40}>
          <Layout displayFlex rowToColumn={900} mediaPadding={900}>
            <InputHolder margin={"0 0 20px 0"}>
              <TextInput value={this.state.updated} />
            </InputHolder>
            <Speichem onClick={e => this.openModal(e, this.props.shouldReload)}>Neue Frage erstellen</Speichem>
          </Layout>
        </Layout>
      </ActionsOuter>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(Actions);
