import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";

import { Layout } from "../styled";
import { ResponseWarning } from "../styled/layout";
import { ClientPersonOuter } from "../styled/clientpersons";
import UserDetail from "../components/Mandates/UserDetail";
import Table from "../components/Table";
import config from "../lib/config";
import Userphoto from "../assets/images/empty.png";
import PersonalInformation from "../components/Modal/PersonalInfo";
import moment from "moment";

// const warningMessage = `Aktuell haben Sie noch keine Mandanten angelegt. Bitte legen Sie
// zunächst Ihre Mandanten an, um eine Übersicht über Ihre Mandanten
//  zu generieren.`;

const warningMessage = `Aktuell liegen zu dieser Ansicht keine Informationen vor `;
class ClientPersons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };
  }

  async componentDidMount() {
    const {
      store: {
        personsListingSearch,
        personsListing,
        client,
        clientStore: { selectedClientId },
      },
    } = this.props;
    personsListing.fetch({
      // clientId: this.props.store.clientStore.globalClientId
      clientId: selectedClientId,
    });
    personsListingSearch.fetch({
      clientId: selectedClientId,
      search: "",
    });
    await client.fetch({
      clientId: selectedClientId,
    });
  }

  handleRowClick = rows => {
    const { personsListingSearch } = this.props.store;
    // const object = {
    //   ...personsListingSearch.data[1],
    // };

    const rowPerson = rows.find(function(column) {
      return column.type === "id";
    });
    const personId = rowPerson ? rowPerson.data : rows[-1] ? rows[-1].data : "";
    const person = personsListingSearch.data.find(item => {
      return item.person_id === personId;
    });
    const object = {
      ...person,
    };
    const {
      store: { modal },
    } = this.props;
    modal.add({
      title: "Informationen zur Person",
      component: PersonalInformation,
      props: {
        object,
      },
    });
  };

  onPressUpload = () => {
    const {
      store: { modal },
    } = this.props;
    modal.add({
      title: "Informationen zur Person",
      component: PersonalInformation,
    });
  };

  onSearchChange = async e => {
    this.setState({ search: e.target.value });
    const searchClue = e.target.value;
    await this.props.store.personsListingSearch.fetch({
      clientId: this.props.store.clientStore.selectedClientId,
      search: searchClue,
    });
  };
  render() {
    const {
      store: { personsListing, client, personsListingSearch },
    } = this.props;

    //if (personsListingSearch.loading || !personsListingSearch.data) {
    //  return null;
    //}

    if (client.loading || !client.data) {
      return null;
    }

    const imageSource =
      client.data.profile_image_url === "uploads/empty.png" ? Userphoto : `${config.endpoints.api}/${client.data.profile_image_url}`;

    let namePrefix = client.data.salutation;
    let firstName = client.data.first_name;
    let lastName = client.data.last_name;
    return (
      <ClientPersonOuter>
        <Layout displayFlex alignCenter>
          <UserDetail
            onPressUpload={this.onPressUpload}
            imageSource={imageSource}
            search
            onSearchChange={personsListing.search}
            searchValue={personsListing.searchValue}
            fullName={namePrefix + " " + firstName + " " + lastName}
            onSearchChange={this.onSearchChange}
            searchValue={this.state.search}
          />
        </Layout>
        <Layout>
          {personsListingSearch.constructedTable.rows[0] ? (
            <Table sortable hideHeader hideFooter small tableStore={personsListingSearch} handleRowClickSortable={this.handleRowClick} />
          ) : (
            <ResponseWarning>
              <div className="response success">
                <i className="material-icons">warning</i> {warningMessage}
              </div>
            </ResponseWarning>
          )}
        </Layout>
      </ClientPersonOuter>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(ClientPersons);
