import styled from "styled-components";
import { COLORS } from "./constants";
import CameraIcon from "../assets/images/camera.png";

export const DatePickerItem = styled.div`
  width: 100%;
  display: flex;
  height: 44px !important;
  > label {
    max-width: 170px;
    width: 32%;
    &.errorStyleDP {
      width: 74%;
      position: relative;
      height: 80px;
      max-width: 100%;
      span.error {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        line-height: 14px;
      }
    }
  }
  @media only screen and (max-width: 700px) {
    width: auto;
    position: relative;
    > div {
      position: relative;
    }
    .react-datepicker-wrapper {
      width: auto;
    }
    i {
      position: absolute;
      right: 0;
      top: 0;
      width: 36px;
      text-align: center;
      border-left: 2px solid #ccc;
      height: 34px;
      line-height: 31px;
    }
  }
  label {
    font-size: 1rem;
    color: ${COLORS.GREYISH_BROWN_TWO};
    text-align: right;
    padding-right: 20px;
    line-height: 42px;
    letter-spacing: 0.2px;
    span {
      color: ${COLORS.TOMATO};
      font-size: 0.85rem !important;
    }
  }
  @media only screen and (max-width: 700px) {
    height: auto;
    display: flex;
    flex-flow: column;
    label {
      width: 100% !important;
      max-width: 100% !important;
      padding-right: 0;
      text-align: left;
    }
  }
`;
export const UserPictureEdit = styled.div`
 width:100%
 margin-bottom:60px;
 padding:0 13%;
 display:inline-block;
 max-height:none !important;
.imageholder{
  width:80px;
  height:80px;
  float:left;
  overflow:hidden;
  border-radius:100%;
  margin-right:20px;
  box-shadow:0px 1px 8px #ddd;
  position:relative;
  &:before{
    width:100%;
    height:100%;
    // background:url(${CameraIcon}) no-repeat center center rgba(0,0,0,0.5);
    content:"";
    position:absolute;
    left:0;
    top:0;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
button{
  margin-top:18px;
  height:40px;
  min-width:143px;
  padding:6px 0;
  position:relative;
  cursor:pointer;
[type='file'] {
  position:absolute;
  left:0;
  top:0;
  width:140px;
  height:40px;
  opacity:0;
  z-index:5;
  cursor:pointer;
}
}
`;
