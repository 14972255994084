import styled from "styled-components";
import { COLORS } from "./constants";

// export const DatePickerItem = styled.div`
//   width: 100%;
//   display: flex;
//   height: 44px !important;
//   > label {
//     max-width: 170px;
//     width: 26%;
//     &.errorStyleDP {
//       width: 100% !important;
//       padding-bottom: 20px;
//       position: relative;
//       max-width: 61%;
//       height: 65px;
//       span.error {
//         position: absolute;
//         bottom: -16px;
//         right: 0;
//         width: 100%;
//         line-height: 14px;
//         white-space: pre-wrap;
//       }
//     }
//   }
//   @media only screen and (max-width: 700px) {
//     width: auto;
//     position: relative;
//     > div {
//       position: relative;
//     }
//     .react-datepicker-wrapper {
//       width: auto;
//     }
//     i {
//       position: absolute;
//       right: 0;
//       top: 0;
//       width: 36px;
//       text-align: center;
//       border-left: 2px solid #ccc;
//       height: 34px;
//       line-height: 31px;
//     }
//   }
//   label {
//     font-size: 1rem;
//     color: ${COLORS.GREYISH_BROWN_TWO};
//     text-align: right;
//     padding-right: 20px;
//     line-height: 42px;
//     letter-spacing: 0.2px;
//     span {
//       color: ${COLORS.TOMATO};
//       font-size: 0.85rem !important;
//     }
//   }
//   @media only screen and (max-width: 700px) {
//     height: auto;
//     display: flex;
//     flex-flow: column;
//     label {
//       width: 100% !important;
//       max-width: 100% !important;
//       padding-right: 0;
//       text-align: left;
//     }
//   }
// `;

export const DatePickerItem = styled.div`
  width: 50%;
  display: flex;
  height: 44px !important;
  position: relative;
  > label.errorStyleDP {
    // width: 100% !important;
    padding-bottom: 20px;
    position: relative;
    max-width: 70%;
    height: 65px;
    width: 100%;
    span.error {
      position: absolute;
      bottom: 0px;
      right: 0;
      width: 100%;
      line-height: 14px;
      white-space: pre-wrap;
      color: #ef5b2e;
      font-size: 0.85rem;
    }
  }
  @media only screen and (max-width: 700px) {
    height: auto;
    display: flex;
    flex-direction: column;
    label {
      width: 100% !important;
      max-width: 100% !important;
      padding-right: 0;
      text-align: left;
    }
  }
`;
