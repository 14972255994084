import React from "react";
import { Layout, P } from "../../styled";
import { UserFilterOptions } from "../../styled/administration";

const LicenseUserFilter = () => (
  <React.Fragment>
    <UserFilterOptions>
      <Layout displayFlex alignCenter>
        <P type="type-10">Lizenzverwaltung</P>
      </Layout>
    </UserFilterOptions>
  </React.Fragment>
);

export default LicenseUserFilter;
