import React from "react";
import * as Yup from "yup";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { ToastsContainer, ToastsStore } from "react-toasts";

import { Layout, P } from "../styled";
import FormInput from "../components/Form/Input";
import TextareaInput from "../components/Form/Textarea";
import FormSelect from "../components/Form/InputSelect";
import { TextInput, FormInputLabel } from "../styled/form";
import { ResponseMessage } from "../styled/layout";
import { Speichem, Abbrechen } from "../styled/buttons";
import DatePicker from "../components/Form/DatePicker";
import moment from "moment";

import { PrimaryInfo, PrimaryInfoTxt, ActionsOuter, SetStatusOptions, ExpiredButton, ExpiredWrapper } from "../styled/mandanteninformation";
import { DatePickerItem } from "../styled/signup";
import ColoredSelect from "../components/Form/ColoredSelect";
import { COLORS } from "../styled/constants";
import { UserInformationRow, UserInformation } from "../styled/signup";

import ExtendNewClientLicence from "../components/Modal/ExtendNewClientLicence";

const INITIAL_STATE = {
  expectedPhase: "",
  actualPhase: "",
  salutation: "",
  firstName: "",
  street: "",
  place: "",
  email: "",
  lastName: "",
  number: "",
  postCode: "",
  nameAffix: "",
  phoneNumber: "",
  title: "",
  mobileNumber: "",
  mandantenNumber: "",
  fax: "",
  taxNumber: "",
  taxOffice: "",
  taxIdentification: "",
  furtherPostcode: "",
  furtherLocation: "",
  furtherStreet: "",
  houseNumber: "",
  comment: "",
  dateOfBirth: "",
  dateOfDeath: "",
  acceptanceDate: "",
  isEdit: false,
  user_timestamp: "",
};

class MandantenInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      fields: {},
      errors: {},
      uploading: false,
      paymentModel: "old",
      validTo: null,
      licenceType: null,
    };
  }

  async componentDidMount() {
    const {
      store: {
        client,
        clientStore: { selectedClientId },
        getUserFromToken,
        clientLicenceGet,
      },
    } = this.props;
    const pay_mod = getUserFromToken && getUserFromToken.data && getUserFromToken.data.payment_model;
    const lic_typ = getUserFromToken && getUserFromToken.data && getUserFromToken.data.licence_type;

    if (selectedClientId) {
      let getClientData = await client.fetch({
        clientId: selectedClientId,
      });
      let currentValues = getClientData.data.data;
      let data = {
        salutation: currentValues.salutation,
        mandantenNumber: currentValues.client_number,
        email: currentValues.e_mail_address,
        firstName: currentValues.first_name,
        lastName: currentValues.last_name,
        postCode: currentValues.postcode,
        number: currentValues.number,
        place: currentValues.place,
        street: currentValues.street,
        nameAffix: currentValues.name_affix,
        firm: currentValues.company_name,
        mobileNumber: currentValues.mobile_number,
        phoneNumber: currentValues.phone_number,
        title: currentValues.title,
        dateOfBirth: currentValues.birth_date ? new Date(currentValues.birth_date) : "",
        dateOfDeath: currentValues.death_date ? new Date(currentValues.death_date) : "",
        acceptanceDate: currentValues.acceptance_date ? new Date(currentValues.acceptance_date) : "",
        fax: currentValues.fax_number,
        taxNumber: currentValues.tax_number,
        taxOffice: currentValues.tax_office,
        taxIdentification: currentValues.tax_identification_characteristic,
        furtherPostcode: currentValues.other_post_code,
        furtherLocation: currentValues.other_place,
        furtherStreet: currentValues.other_street,
        houseNumber: currentValues.other_house_number,
        comment: currentValues.comment,
        actualPhase: currentValues.current_phase,
        expectedPhase: currentValues.expected_phase,
        isEdit: true,
        user_timestamp: currentValues.user_timestamp,
      };
      let validTo = moment(new Date()).format("DD.MM.YYYY");
      if (pay_mod == "new" && selectedClientId) {
        await clientLicenceGet.fetch({ clientId: selectedClientId });
        validTo = moment(clientLicenceGet.data.valid_to).format("DD.MM.YYYY");
      }
      this.setState({ ...data, paymentModel: pay_mod, validTo: validTo, licenceType: lic_typ });
    }
  }

  handleChange = field => e => {
    this.setState({
      [field]: e.target.value === "" ? null : e.target.value,
      errors: {
        ...this.state.errors,
        [field]: "",
      },
    });
  };

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";

    const schema = Yup.object().shape({
      actualPhase: Yup.string(requiredError),
      expectedPhase: Yup.string(requiredError),
      lastName: Yup.string().required(requiredError),
      firstName: Yup.string().required(requiredError),
      number: Yup.number("Nur Zahlen").nullable(),
      phoneNumber: Yup.number("Nur Zahlen").nullable(),
      postCode: Yup.number("Nur Zahlen").nullable(),
      mobileNumber: Yup.number("Nur Zahlen").nullable(),
      email: Yup.string()
        .email("E-Mail ist nicht gültig!")
        .nullable(),
    });
    try {
      schema.validateSync(fields, { abortEarly: false });
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(e => {
          if (e.message.includes("must be a `number` type, but the final value was: `NaN` (cast from the value")) {
            errors[e.path] = "Nur Zahlen";
          } else {
            errors[e.path] = e.message;
          }
        });
      }
    }

    formIsValid = schema.isValidSync(fields);
    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (this[key]) {
        this[key].focus();
      }
    }

    if (moment(this.state.dateOfBirth).isAfter(this.state.dateOfDeath)) {
      errors["dateOfDeath"] = "Date of death has to be after date of birth!";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleDateChange = category => data => {
    let today = new Date();
    let yesterday = new Date(today);
    if (data === null) {
      this.setState({
        [category]: "",
      });
    } else {
      if (category === "dateOfBirth") {
        yesterday.setDate(today.getDate() - 1);
        if (new Date(data) >= new Date(yesterday)) {
          this.setState({
            [category]: "",
            dateOfBirthError: "Sie können kein Datum in der Zukunft oder heute eingeben!",
          });
          return;
        }
        this.setState({ [category]: new Date(data), dateOfBirthError: " " });
      } else if (category === "acceptanceDate") {
        yesterday.setDate(today.getDate() - 1);
        if (new Date(data) >= new Date(yesterday)) {
          this.setState({
            [category]: "",
            acceptanceDateError: "Sie können kein Datum in der Zukunft oder heute eingeben!",
          });
          return;
        }
        this.setState({ [category]: new Date(data), acceptanceDateError: " " });
      } else {
        if (new Date(data) >= new Date(yesterday)) {
          let errors = {};
          errors["dateOfDeath"] = "Sie können kein Datum in der Zukunft eingeben!!";
          this.setState({
            [category]: "",
            errors: errors,
          });
          return;
        }
        if (data === null) {
          this.setState({
            dateOfDeath: null,
          });
          return;
        }
        let errors = {};
        errors["dateOfDeath"] = "";
        this.setState({ [category]: new Date(data), errors: errors });
      }
    }
  };

  handleSelected = category => value => {
    this.setState({ [category]: value });
  };

  updateLicence = () => {
    const {
      store: { client, modal, clientLicenceGet },
    } = this.props;
    modal.add({
      title: "Extend New Client Licence",
      component: ExtendNewClientLicence,
      props: {
        clientId: client.data.client_id,
      },
    });
  };

  handleSubmit = async () => {
    const {
      store: { addNewClient, user, updateUser, updateClients, client, clientStore, declining },
      history,
    } = this.props;

    const {
      salutation,
      firstName,
      street,
      place,
      email,
      lastName,
      number,
      postCode,
      nameAffix,
      phoneNumber,
      title,
      mobileNumber,
      dateOfBirth,
      dateOfDeath,
      acceptanceDate,
      mandantenNumber,
      fax,
      taxNumber,
      taxOffice,
      taxIdentification,
      furtherPostcode,
      furtherLocation,
      furtherStreet,
      houseNumber,
      comment,
      actualPhase,
      expectedPhase,
    } = this.state;

    if (!this.handleValidation()) {
      return false;
    }

    this.setState({
      uploading: true,
    });
    const data = {
      salutation: salutation,
      e_mail_address: email,
      first_name: firstName,
      last_name: lastName,
      name_affix: nameAffix,
      street: street,
      number: number,
      place: place,
      postcode: postCode,
      phone_number: phoneNumber,
      title: title,
      mobile_number: mobileNumber,
      birth_date: dateOfBirth === "" ? null : dateOfBirth,
      death_date: dateOfDeath === "" ? null : dateOfDeath,
      acceptance_date: acceptanceDate === "" ? null : acceptanceDate,
      client_number: mandantenNumber,
      fax_number: fax,
      tax_number: taxNumber,
      tax_office: taxOffice,
      tax_identification_characteristic: taxIdentification,
      other_post_code: furtherPostcode,
      other_place: furtherLocation,
      other_street: furtherStreet,
      other_house_number: houseNumber,
      comment: comment,
      current_phase: actualPhase,
      expected_phase: expectedPhase,
      user_timestamp:
        "" +
        moment().format("DD.MM.YYYY, HH:mm, ") +
        this.props.store.getUserFromToken.data.first_name +
        " " +
        this.props.store.getUserFromToken.data.last_name,
    };
    this.setState({
      uploading: true,
    });
    if (this.state.isEdit) {
      const responseMessage = await updateClients.fetch({
        clientId: clientStore.selectedClientId,
        ...data,
      });
      if (responseMessage.data.data === "Client number already in use") {
        this.setState({
          uploading: false,
          message: (
            <div className="response">
              <i className="material-icons">error</i> Kundennummer bereits verwendet.
            </div>
          ),
        });
      } else {
        await client.fetch({
          clientId: clientStore.selectedClientId,
        });
        this.setState(
          {
            uploading: false,
            user_timestamp:
              "" +
              moment().format("DD.MM.YYYY, HH:mm, ") +
              this.props.store.getUserFromToken.data.first_name +
              " " +
              this.props.store.getUserFromToken.data.last_name,
          },
          () => {
            ToastsStore.success("Speichern erfolgreich!");
          },
        );
      }
    } else {
      let responseMessage = await addNewClient.fetch(data);
      if (responseMessage.data.data) {
        if (responseMessage.data.data === "Client number already in use") {
          this.setState({
            uploading: false,
            message: (
              <div className="response">
                <i className="material-icons">error</i> Kundennummer bereits verwendet.
              </div>
            ),
          });
        } else {
          const clientId = responseMessage.data.data.client_id;
          await updateUser.fetch({
            id: user.currentUser.id,
            selected_client: clientId,
          });
          clientStore.setSelectedClient(clientId);
          declining.fetch({
            clientId: clientStore.selectedClientId,
          });
          this.setState({
            uploading: false,
          });
          history.push("/mandanten");
        }
      } else {
        this.setState({
          uploading: false,
          message: (
            <div className="response">
              <i className="material-icons">error</i> Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
            </div>
          ),
        });
      }
    }
  };

  getBackgroundColor = color => {
    switch (color) {
    case "Blau":
      color = { background: "#71b0e3", color: "white" };
      break;
    case "Grün":
      color = { background: "#95cc53", color: "white" };
      break;
    case "Schwarz":
      color = { background: "#626262", color: "white" };
      break;
    case "Rot":
      color = { background: "#ff8a65", color: "white" };
      break;
    case "Grau":
      color = { background: "#cacaca", color: "white" };
      break;
    case "Gelb":
      color = { background: "#f4d166", color: "black" };
      break;
    default:
    }
    return color;
  };

  render() {
    const {
      store: { client, clientStore },
    } = this.props;
    const { uploading } = this.state;

    if (clientStore.selectedClientId) {
      if (client.loading || !client.data) {
        return null;
      }
    }

    const options = ["Blau", "Grün", "Gelb", "Rot", "Schwarz", "Grau"].map(c => ({
      title: c,
      props: {
        style: {
          ...this.getBackgroundColor(c),
        },
      },
    }));

    return (
      <React.Fragment>
        <UserInformation>
          <PrimaryInfo style={{ flexDirection: "column" }}>
            <UserInformationRow rowToColumn={1000}>
              <FormSelect
                noAdapter
                label="Anrede"
                onChange={this.handleSelected("salutation")}
                options={["Frau", "Herr"].map(a => a)}
                selected={client.data ? client.data.salutation : ""}
                error={this.state.errors["salutation"]}
              />
              <FormInput
                label="Mandantennummer"
                value={this.state.mandantenNumber}
                onChange={this.handleChange("mandantenNumber")}
                placeholder="Mandantennummer"
              />
            </UserInformationRow>
            <UserInformationRow rowToColumn={1000}>
              <FormInput label="Titel" onChange={this.handleChange("title")} value={this.state.title} placeholder="Titel" />
              <FormInput
                label="Namenszusatz"
                value={this.state.nameAffix}
                onChange={this.handleChange("nameAffix")}
                placeholder="Namenszusatz"
              />
            </UserInformationRow>
            <UserInformationRow rowToColumn={1000}>
              <FormInput
                label="Vorname"
                placeholder="Vorname"
                value={this.state.firstName}
                onChange={this.handleChange("firstName")}
                required
                error={this.state.errors["firstName"]}
                ref={el => {
                  this.firstName = el;
                }}
              />
              <FormInput
                required
                label="Nachname"
                value={this.state.lastName}
                onChange={this.handleChange("lastName")}
                placeholder="Nachname"
                error={this.state.errors["lastName"]}
                ref={el => {
                  this.lastName = el;
                }}
              />
            </UserInformationRow>
            <UserInformationRow rowToColumn={1000}>
              <FormInput
                label="Straße"
                placeholder="Straße"
                value={this.state.street}
                onChange={this.handleChange("street")}
                error={this.state.errors["street"]}
                ref={el => {
                  this.street = el;
                }}
              />
              <FormInput
                label="Hausnr"
                value={this.state.number}
                onChange={this.handleChange("number")}
                placeholder="Hausnr"
                error={this.state.errors["number"]}
                ref={el => {
                  this.number = el;
                }}
              />
            </UserInformationRow>
            <UserInformationRow rowToColumn={1000}>
              <FormInput
                label="Ort"
                placeholder="Ort"
                // required
                value={this.state.place}
                onChange={this.handleChange("place")}
                error={this.state.errors["place"]}
                ref={el => {
                  this.place = el;
                }}
              />
              <FormInput
                // required
                label="PLZ"
                placeholder="PLZ"
                value={this.state.postCode}
                onChange={this.handleChange("postCode")}
                error={this.state.errors["postCode"]}
                ref={el => {
                  this.postCode = el;
                }}
              />
            </UserInformationRow>
            <UserInformationRow rowToColumn={1000}>
              <FormInput
                label="Mobilfunknummer"
                placeholder="Mobilfunknummer"
                value={this.state.mobileNumber}
                error={this.state.errors.mobileNumber}
                onChange={this.handleChange("mobileNumber")}
              />
              <FormInput
                label="Faxnummer"
                placeholder="Faxnummer"
                value={this.state.fax}
                onChange={this.handleChange("fax")}
                ref={el => {
                  this.fax = el;
                }}
              />
            </UserInformationRow>
            <UserInformationRow rowToColumn={1000}>
              <FormInput
                label="Telefonnummer"
                value={this.state.phoneNumber}
                onChange={this.handleChange("phoneNumber")}
                error={this.state.errors.phoneNumber}
                placeholder="Telefonnummer"
              />
              <DatePickerItem>
                <FormInputLabel>
                  Geburtsdatum<span> </span>
                </FormInputLabel>
                <DatePicker
                  value={this.state.dateOfBirth}
                  onChange={this.handleDateChange("dateOfBirth")}
                  error={this.state.errors.dateOfBirth}
                />
              </DatePickerItem>
            </UserInformationRow>
            <UserInformationRow rowToColumn={1000} mediaMarginTop={600}>
              <FormInput
                label="E-Mailadresse"
                value={this.state.email}
                placeholder="E-Mailadresse"
                onChange={this.handleChange("email")}
                // required
                error={this.state.errors["email"]}
                ref={el => {
                  this.email = el;
                }}
              />
              <ExpiredWrapper>
                {this.state.paymentModel == "new" && this.state.licenceType == "admin" && (
                  <FormInputLabel>
                    Mandantenlizenz gültig bis<span> </span>
                  </FormInputLabel>
                )}
                {this.state.paymentModel == "new" && this.state.licenceType == "admin" && (
                  <FormInputLabel style={{ color: "red" }}>
                    {this.state.validTo}
                    <span> </span>
                  </FormInputLabel>
                )}
                {this.state.paymentModel == "new" && this.state.licenceType == "admin" && (
                  <ExpiredButton onClick={this.updateLicence}>Verlängern</ExpiredButton>
                )}
              </ExpiredWrapper>
            </UserInformationRow>
          </PrimaryInfo>
          <h6>Sterbedaten</h6>
          <PrimaryInfo>
            <UserInformationRow>
              <DatePickerItem>
                <FormInputLabel>Todestag</FormInputLabel>
                <DatePicker
                  defaultValue=""
                  value={this.state.dateOfDeath}
                  onChange={this.handleDateChange("dateOfDeath")}
                  error={this.state.errors.dateOfDeath}
                />
              </DatePickerItem>

              <DatePickerItem>
                <FormInputLabel>Amtsannahme</FormInputLabel>
                <DatePicker
                  defaultValue=""
                  value={this.state.acceptanceDate}
                  onChange={this.handleDateChange("acceptanceDate")}
                  error={this.state.acceptanceDateError}
                />
              </DatePickerItem>
            </UserInformationRow>
          </PrimaryInfo>
          <h6>Steuerdaten</h6>
          <PrimaryInfo>
            <UserInformationRow>
              <FormInput
                label="Steuernummer"
                placeholder="Steuernummer"
                value={this.state.taxNumber}
                onChange={this.handleChange("taxNumber")}
              />
            </UserInformationRow>
            <UserInformationRow>
              <FormInput label="Finanzamt" placeholder="Finanzamt" value={this.state.taxOffice} onChange={this.handleChange("taxOffice")} />
            </UserInformationRow>
            <UserInformationRow>
              <div className="customlabel">
                <FormInput
                  label="Steuerliches
                Identifikationsmerkmal"
                  placeholder="Steuerliches Identifikationsmerkmal"
                  onChange={this.handleChange("taxIdentification")}
                  error={this.state.errors["taxIdentification"]}
                  value={this.state.taxIdentification}
                  ref={el => {
                    this.taxIdentification = el;
                  }}
                />
              </div>
            </UserInformationRow>
          </PrimaryInfo>
          <h6>Weitere Anschrift</h6>
          <PrimaryInfo>
            <UserInformationRow>
              <FormInput label="PLZ" placeholder="PLZ" value={this.state.furtherPostcode} onChange={this.handleChange("furtherPostcode")} />
            </UserInformationRow>
            <UserInformationRow>
              <FormInput label="Ort" placeholder="Ort" value={this.state.furtherLocation} onChange={this.handleChange("furtherLocation")} />
            </UserInformationRow>
            <UserInformationRow>
              <FormInput
                label="Straße"
                placeholder="Straße"
                value={this.state.furtherStreet}
                onChange={this.handleChange("furtherStreet")}
              />
            </UserInformationRow>
            <UserInformationRow>
              <FormInput
                label="Hausnummer "
                placeholder="Hausnummer "
                value={this.state.houseNumber}
                onChange={this.handleChange("houseNumber")}
              />
            </UserInformationRow>
          </PrimaryInfo>
          <h6>Mandantenstatus </h6>
          <SetStatusOptions>
            <PrimaryInfo>
              <Layout rowToColumn={768} displayFlex style={{ width: "100%" }}>
                <Layout displayFlex spacingLeft={20} spacingRight={20} flex={1} width="100%" column>
                  <div className="customLineHeight" style={{ display: "flex", flexDirection: "row" }}>
                    <P
                      style={{
                        lineHeight: "19px",
                        fontSize: "16px",
                        paddingRight: 20,
                        maxWidth: 160,
                      }}
                    >
                      Mandantenstatus aktuell <span style={{ color: COLORS.TOMATO }}>*</span>
                    </P>
                    <ColoredSelect
                      label="foo"
                      options={options}
                      selected={client.data ? options.find(o => o.title === client.data.current_phase) : ""}
                      onChange={val => this.handleSelected("actualPhase")(val.title)}
                    />
                  </div>
                  <Layout width="100%" displayFlex alignEnd justifyEnd spacingRight={5}>
                    {this.state.errors["actualPhase"] && <P style={{ color: COLORS.TOMATO }}>{this.state.errors["actualPhase"]}</P>}
                  </Layout>
                </Layout>

                <Layout displayFlex spacingLeft={20} spacingRight={20} flex={1} width="100%" column>
                  <div className="customLineHeight" style={{ display: "flex", flexDirection: "row" }}>
                    <P
                      style={{
                        lineHeight: "19px",
                        fontSize: "16px",
                        paddingRight: 20,
                        maxWidth: 160,
                      }}
                    >
                      Mandantenstatus erwartet <span style={{ color: COLORS.TOMATO }}>*</span>
                    </P>
                    <ColoredSelect
                      label="foo"
                      options={options}
                      selected={client.data ? options.find(o => o.title === client.data.expected_phase) : ""}
                      onChange={val => this.handleSelected("expectedPhase")(val.title)}
                    />
                  </div>
                  <Layout width="100%" displayFlex alignEnd justifyEnd spacingRight={5}>
                    {this.state.errors["expectedPhase"] && <P style={{ color: COLORS.TOMATO }}>{this.state.errors["expectedPhase"]}</P>}
                  </Layout>
                </Layout>
              </Layout>
            </PrimaryInfo>
            <P type="type-6">
              Legende: blau = Beratung, grün = inaktive Vollmacht, gelb = bedingte Vollmacht, rot = Vollmachtsfall, schwarz =
              Testamentsvollstreckung, grau = archiviert
            </P>
          </SetStatusOptions>
          <h6>Kommentar</h6>
          <PrimaryInfoTxt>
            <UserInformationRow>
              <TextareaInput label="Kommentar" placeholder="Kommentar" onChange={this.handleChange("comment")} value={this.state.comment} />
            </UserInformationRow>
          </PrimaryInfoTxt>
          <ActionsOuter>
            <Layout displayFlex rowToColumn={1100}>
              <Layout minWidth={"351px"} mediaFullWidth={1100} offsetBottom={20} displayFlex justifyCenter>
                <TextInput defaultValue={this.state.user_timestamp} value={this.state.user_timestamp} disabled />
              </Layout>
              <Layout fullWidth displayFlex justifyEnd offsetBottom={20} mediaJustifyCenter={1100} mediaCenter={550} rowToColumn={550}>
                <Abbrechen responsiveMargin={985}>Abbrechen</Abbrechen>
                <Speichem onClick={!uploading && this.handleSubmit} responsiveMargin={985}>
                  {uploading ? "Ihre Datei wird verarbeitet" : "Speichern"}
                </Speichem>
              </Layout>
            </Layout>
          </ActionsOuter>
          <ResponseMessage>
            {" "}
            <div> {this.state.message} </div>{" "}
          </ResponseMessage>
        </UserInformation>
        <ToastsContainer lightBackground store={ToastsStore} />
      </React.Fragment>
    );
  }
}
export default compose(
  inject("store"),
  observer,
)(MandantenInformation);
