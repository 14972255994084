import _ from "lodash";
import { PieChart, Pie, Tooltip, ResponsiveContainer } from "recharts";
import React, { Component } from "react";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";
import ReactResizeDetector from "react-resize-detector";
import PerfectScrollbar from "react-perfect-scrollbar";

import UserProfileMandantenNumber from "../components/UserProfileCard/UserProfileMandatenNumber";
import { Layout, P } from "../styled/";

import Userphoto from "../assets/images/empty.png";
import config from "../lib/config";
import { LayoutPieChart } from "../styled/layout";

import {
  TableContainer,
  TableHeaderRow,
  HeaderItemText,
  TableOverflowContainer,
  TableRow,
  TableItem,
  TableItemText,
  TableFooter,
  LineFooter,
  TableHeaderItem,
} from "../styled/table";
import { autorun } from "mobx";
import { COLORS } from "../styled/constants";

class BalanceSheet extends Component {
  constructor(props) {
    super(props);

    this.headers = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];

    this.state = {
      widths: [0, 0, 0, 0],
    };
  }
  async componentDidMount() {
    const {
      store: { client, clientStore, assets, personsListing },
      // client
    } = this.props;

    await assets.fetch({
      clientId: clientStore.selectedClientId,
    });
    await personsListing.fetch({
      clientId: clientStore.selectedClientId,
    });
    this.forceUpdate();
    this.autorun = autorun(this.findSizes);
  }

  componentWillUnmount() {
    if (this.autorun) {
      this.autorun();
    }
  }

  findSizes = () => {
    const widths = [];
    this.headers.forEach((ref, i) => {
      if (ref.current) {
        widths[i] = ref.current.offsetWidth;
      }
    });

    this.setState({ widths });
  };

  render() {
    const {
      store: { client, assets, personsListing, clientStore },
    } = this.props;
    const imageSource =
      client.data.profile_image_url === "uploads/empty.png" ? Userphoto : `${config.endpoints.api}/${client.data.profile_image_url}`;

    const assetsCategories = _.get(assets, "constructed.sections.0.categories", []).map(c => ({
      title: c.title,
      value: c.value_sold,
    }));
    const assetsValue = assetsCategories.reduce((acc, current) => acc + current.value, 0);

    const liabilityCategories = _.get(assets, "constructed.sections.1.categories", []).map(c => ({
      title: c.title,
      value: c.value_sold,
    }));
    const liabilityValue = liabilityCategories.reduce((acc, current) => acc + current.value, 0);

    let countMissingAssetRows = Math.max(0, liabilityCategories.length - assetsCategories.length);
    let countMissingLiabilityRows = Math.max(0, assetsCategories.length - liabilityCategories.length);

    const liabilityBlanks = [...Array(countMissingLiabilityRows).keys()].map(() => ({
      title: "--",
      value: "--",
    }));
    const assetBlanks = [...Array(countMissingAssetRows).keys()].map(() => ({
      title: "--",
      value: "--",
    }));

    const table = {
      header: [
        {
          scale: 3,
          title: "Vermögenswerte",
        },
        {
          scale: 1,
          title: "Wert in  €",
        },
        {
          scale: 3,
          title: "Verbindlichkeiten",
        },
        {
          scale: 1,
          title: "Wert in  €",
        },
      ],
      assets: [
        ...assetsCategories,
        ...assetBlanks,
        {
          title: "Summe Vermögenswerte",
          value: assetsValue,
        },
      ],

      liabilities: [
        ...liabilityCategories,
        ...liabilityBlanks,
        {
          title: "Summe Verbindlichkeiten",
          value: liabilityValue,
        },
      ],
    };

    const greens = [
      "#d0f59f",
      "#b8e37f",
      "#a5d665",
      "#90c24f",
      "#87c437",
      "#7cbf24",
      "#6bb010",
      "#65ad05",
      "#d0f59f",
      "#b8e37f",
      "#a5d665",
      "#90c24f",
      "#87c437",
      "#7cbf24",
      "#6bb010",
      "#65ad05",
      "#90c24f",
      "#87c437",
      "#7cbf24",
      "#6bb010",
      "#65ad05",
      "#a5d665",
      "#90c24f",
      "#87c437",
      "#7cbf24",
      "#6bb010",
      "#65ad05",
    ];
    const reds = [
      "#deaa9b",
      "#dea08e",
      "#cf907e",
      "#bf816f",
      "#ad705e",
      "#9e6554",
      "#8a5646",
      "#7a4737",
      "#deaa9b",
      "#dea08e",
      "#cf907e",
      "#bf816f",
      "#ad705e",
      "#9e6554",
      "#8a5646",
      "#7a4737",
      "#ad705e",
      "#9e6554",
      "#8a5646",
      "#7a4737",
      "#deaa9b",
      "#dea08e",
    ];
    const liabilityPieData = liabilityCategories
      .filter(i => Number(i.value) > 0)
      .map((c, i) => ({
        value: c.value,
        name: c.title,
        title: c.title,
        fill: reds[i],
      }));
    const assetPieData = assetsCategories
      .filter(i => Number(i.value) > 0)
      .map((c, i) => ({
        value: c.value,
        name: c.title,
        fill: greens[i],
      }));

    const { widths } = this.state;

    const distributedCategories = _.get(assets, "distributed");

    const assetCat = {
      header: [
        {
          scale: 3,
          title: "Vermögenskategorien",
        },
        {
          scale: 1,
          title: "Wert in  €",
        },
      ],
      rows: distributedCategories.sections
        .map((s, i) => ({
          name: s.title,
          value: (s.categories || []).reduce((acc, curr) => {
            return Number(acc) + Number(curr.value_sold.replace(".", "").replace(",", "."));
          }, 0),
          fill: greens[i],
        }))
        .filter(r => r.value > 0),
    };

    console.log("ASETI", assets);

    const beneficiaries = _.get(assets, `data.beneficiaryValues.${clientStore.selectedClientId}`, {});
    const beneficiaryRows = {};
    Object.keys(beneficiaries).forEach(a => {
      Object.keys(beneficiaries[a]).forEach(b => {
        if (!_.get(beneficiaryRows, `${b}`, null)) {
          beneficiaryRows[b] = beneficiaries[a][b];
        } else {
          beneficiaryRows[b] += beneficiaries[a][b];
        }
      });
    });

    const benRows = (personsListing.data || []).map((p, i) => {
      return {
        name: `${p.first_name || ""} ${p.last_name || ""}`.trim(),
        fill: greens[i],
        value: beneficiaryRows[p.person_id],
      };
    });
    const beneficiary = {
      header: [
        {
          scale: 3,
          title: "Verteilung Begünstigte",
        },
        {
          scale: 1,
          title: "Wert in  €",
        },
      ],
      rows: benRows,
    };

    return (
      <Layout>
        <ReactResizeDetector handleWidth handleHeight onResize={this.findSizes} />
        <Layout
          displayFlex
          row
          alignCenter
          justifyStart
          spacingLeft={55}
          spacingTop={40}
          spacingBottom={60}
          zIndex={100}
          relative
          boxShadow="border_only"
          background="white"
          mediaSidePadding={600}
        >
          {client.data && (
            <UserProfileMandantenNumber
              firstName={client.data.first_name}
              imageSource={imageSource}
              title={client.data.title || ""}
              lastName={client.data.last_name}
              mandantenNumber={client.data.client_number}
              birthday={client.data.birth_date}
              showDate={assets.data}
              updatedAt={assets.data ? assets.data.lastUpdate : null}
            />
          )}
        </Layout>
        <Layout offsetTop={30} spacingBottom={20} displayFlex alignCenter justifyCenter>
          <P type={"type-9"} bold>
            Vermögensstatus
          </P>
        </Layout>

        <Layout
          displayFlex
          row
          style={{
            borderBottom: "1px solid rgb(220,225,214)",
          }}
          rowToColumn={1450}
        >
          <Layout flex={2} mediaFullWidth={1750}>
            <TableContainer>
              <PerfectScrollbar>
                <TableHeaderRow>
                  {table.header.map((item, index) => (
                    <TableHeaderItem ref={this.headers[index]} key={item.title} scale={item.scale}>
                      <HeaderItemText>{item.title}</HeaderItemText>
                    </TableHeaderItem>
                  ))}
                </TableHeaderRow>
                <Layout displayFlex row>
                  <Layout flex={1} displayFlex column spacingRight={1}>
                    {table.assets.map(a => (
                      <Layout displayFlex>
                        <TableItem style={{ maxHeight: 63 }} elemWidth={widths[0]}>
                          <Layout spacingTop={22} spacingBottom={22}>
                            <TableItemText>{a.title}</TableItemText>
                          </Layout>
                        </TableItem>
                        <TableItem style={{ maxHeight: 63 }} ignoreLastStyle elemWidth={widths[1]}>
                          <Layout spacingTop={22} spacingBottom={22}>
                            <TableItemText>
                              {typeof a.value === "number" ? a.value.toLocaleString("de-DE", { minimumFractionDigits: 2 }) : a.value}
                            </TableItemText>
                          </Layout>
                        </TableItem>
                      </Layout>
                    ))}
                  </Layout>
                  <Layout flex={1} displayFlex column>
                    {table.liabilities.map(a => (
                      <Layout displayFlex>
                        <TableItem style={{ maxHeight: 63 }} elemWidth={widths[2]}>
                          <Layout spacingTop={22} spacingBottom={22}>
                            <TableItemText>{a.title}</TableItemText>
                          </Layout>
                        </TableItem>
                        <TableItem style={{ maxHeight: 63 }} ignoreLastStyle elemWidth={widths[3]}>
                          <Layout spacingTop={22} spacingBottom={22}>
                            <TableItemText>
                              {typeof a.value === "number" ? a.value.toLocaleString("de-DE", { minimumFractionDigits: 2 }) : a.value}
                            </TableItemText>
                          </Layout>
                        </TableItem>
                      </Layout>
                    ))}
                  </Layout>
                </Layout>
              </PerfectScrollbar>
              <TableHeaderItem style={{ borderRight: "1px solid rgb(220,225,214)" }}>
                <Layout column width="100%" spacingTop={25} spacingBottom={25} displayFlex alignCenter justifyCenter>
                  <P
                    bold
                    style={{
                      fontSize: 15,
                      color: assetsValue - liabilityValue < 0 ? "#9d6150" : COLORS.GREYISH_BROWN_TWO,
                      fontWeight: "bold",
                      marginBottom: 10,
                    }}
                  >
                    Saldo: {(Number(assetsValue) - Number(liabilityValue)).toLocaleString("de-DE", { minimumFractionDigits: 2 })}
                  </P>
                  {assetsValue - liabilityValue < 0 && (
                    <P style={{ fontSize: 14, color: "#644e48" }}>Achtung, Überschuldung und Zahlungsunfähigkeit prüfen!</P>
                  )}
                </Layout>
              </TableHeaderItem>
            </TableContainer>
          </Layout>
          <Layout flex={1} style={{ minWidth: "300px", maxWidth: "400px" }}>
            <TableContainer style={{ borderLeft: "1px solid rgb(220,225,214)" }}>
              <TableHeaderRow>
                <TableHeaderItem>
                  <HeaderItemText>Vermögenswerte</HeaderItemText>
                </TableHeaderItem>
              </TableHeaderRow>
            </TableContainer>
            <Layout displayFlex column alignCenter justifyCenter style={{ borderTop: "1px solid rgb(220,225,214)" }}>
              <ResponsiveContainer height={320}>
                <PieChart>
                  <Pie
                    data={[...assetPieData, ...liabilityPieData]}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={50}
                    outerRadius={100}
                    fill="#8884d8"
                    label={({ payload }) => {
                      return Number(payload.payload.value).toLocaleString("de-DE", { minimumFractionDigits: 2 });
                    }}
                    // label
                  />
                  <Tooltip
                    formatter={value => {
                      return Number(value).toLocaleString("de-DE", { minimumFractionDigits: 2 });
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
              <Layout spacingRight={20} displayFlex alignStart justifyCenter column>
                {assetPieData.map(item => (
                  <Layout displayFlex row alignCenter offsetBottom={10}>
                    <div
                      style={{
                        minWidth: 10,
                        height: 10,
                        backgroundColor: item.fill,
                        marginRight: 10,
                      }}
                    />
                    {item.name}
                  </Layout>
                ))}
                {liabilityPieData.map(item => (
                  <Layout displayFlex row alignCenter offsetBottom={10}>
                    <div
                      style={{
                        minWidth: 10,
                        height: 10,
                        backgroundColor: item.fill,
                        marginRight: 10,
                      }}
                    />
                    {item.name}
                  </Layout>
                ))}
              </Layout>
            </Layout>
          </Layout>
        </Layout>

        <Layout spacingTop={80} spacingBottom={25} displayFlex alignCenter justifyCenter style={{ backgroundColor: "#F7F7F7" }}>
          <P type={"type-9"} bold>
            Vermögensstatus
          </P>
        </Layout>

        <Layout displayFlex row rowToColumn={1450}>
          <Layout displayFlex width={"50%"} mediaFullWidth={1750} rowToColumn={700}>
            <Layout
              flex={1}
              displayFlex
              alignCenter
              column
              style={{
                borderRight: "1px solid rgb(220,225,214)",
                maxWidth: "63%",
              }}
              mediaFullWidth={700}
            >
              <TableContainer>
                <TableHeaderRow style={{ borderRightColor: "transparent" }}>
                  {assetCat.header.map((item, index) => (
                    <TableHeaderItem ref={this.headers[4 + index]} key={item.title} scale={item.scale}>
                      <HeaderItemText>{item.title}</HeaderItemText>
                    </TableHeaderItem>
                  ))}
                </TableHeaderRow>
                {assetCat.rows.map((r, i) => (
                  <TableRow
                    style={{
                      borderRightColor: "transparent",
                      borderBottom: assetCat.rows.length - 1 === i ? "1px solid rgb(220,225,214)" : "none",
                    }}
                  >
                    <Layout displayFlex row>
                      <Layout flex={1} displayFlex column spacingRight={1}>
                        <Layout displayFlex>
                          <TableItem elemWidth={widths[4]}>
                            <Layout spacingTop={22} spacingBottom={22}>
                              <TableItemText>{r.name}</TableItemText>
                            </Layout>
                          </TableItem>
                          <TableItem ignoreLastStyle elemWidth={widths[5]} style={{ borderRightColor: "transparent" }}>
                            <Layout spacingTop={22} spacingBottom={22}>
                              <TableItemText>{(r.value || 0).toLocaleString("de-DE", { minimumFractionDigits: 2 })}</TableItemText>
                            </Layout>
                          </TableItem>
                        </Layout>
                      </Layout>
                    </Layout>
                  </TableRow>
                ))}
              </TableContainer>
            </Layout>

            <Layout
              flex={0.6}
              style={{
                minHeight: 350,
                borderRight: "1px solid rgb(220,225,214)",
                maxWidth: "37%",
              }}
              minWidth={200}
              mediaFullWidth={700}
            >
              <TableHeaderItem style={{ maxHeight: 60 }} />
              <Layout>
                <LayoutPieChart>
                  <ResponsiveContainer height={200}>
                    <PieChart>
                      <Pie
                        data={assetCat.rows}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={30}
                        outerRadius={80}
                        fill="#8884d8"
                        label={({ payload }) => {
                          return Number(payload.payload.value).toLocaleString("de-DE", { minimumFractionDigits: 2 });
                        }}
                      />
                      <Tooltip
                        formatter={value => {
                          return Number(value).toLocaleString("de-DE", { minimumFractionDigits: 2 });
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </LayoutPieChart>
                <Layout spacingLeft={20} displayFlex alignStart justifyCenter column>
                  {assetCat.rows.map(item => (
                    <Layout displayFlex row alignCenter offsetBottom={10}>
                      <div
                        style={{
                          minWidth: 10,
                          height: 10,
                          backgroundColor: item.fill,
                          marginRight: 10,
                        }}
                      />
                      {item.name}
                    </Layout>
                  ))}
                </Layout>
              </Layout>
            </Layout>
          </Layout>

          <Layout displayFlex width={"50%"} mediaFullWidth={1750} rowToColumn={700}>
            <Layout
              alignCenter
              flex={1}
              displayFlex
              column
              style={{
                borderRight: "1px solid rgb(220,225,214)",
                maxWidth: "63%",
              }}
              mediaFullWidth={700}
            >
              <TableContainer>
                <TableHeaderRow style={{ borderRightColor: "transparent" }}>
                  {beneficiary.header.map((item, index) => (
                    <TableHeaderItem ref={this.headers[4 + index]} key={item.title} scale={item.scale}>
                      <HeaderItemText>{item.title}</HeaderItemText>
                    </TableHeaderItem>
                  ))}
                </TableHeaderRow>
                {beneficiary.rows.map((r, i) => (
                  <TableRow
                    style={{
                      borderRightColor: "transparent",
                      borderBottom: assetCat.rows.length - 1 === i ? "1px solid rgb(220,225,214)" : "none",
                    }}
                  >
                    <Layout displayFlex row>
                      <Layout flex={1} displayFlex column spacingRight={1}>
                        <Layout displayFlex>
                          <TableItem elemWidth={widths[4]}>
                            <Layout spacingTop={22} spacingBottom={22}>
                              <TableItemText>{r.name}</TableItemText>
                            </Layout>
                          </TableItem>
                          <TableItem ignoreLastStyle elemWidth={widths[5]} style={{ borderRightColor: "transparent" }}>
                            <Layout spacingTop={22} spacingBottom={22}>
                              <TableItemText>{(r.value || 0).toLocaleString("de-DE", { minimumFractionDigits: 2 })}</TableItemText>
                            </Layout>
                          </TableItem>
                        </Layout>
                      </Layout>
                    </Layout>
                  </TableRow>
                ))}
              </TableContainer>
            </Layout>

            <Layout flex={0.6} style={{ minHeight: 350, maxWidth: "37%" }} mediaFullWidth={700} minWidth={200}>
              <TableHeaderItem style={{ maxHeight: 60 }} />
              <Layout>
                <LayoutPieChart>
                  <ResponsiveContainer height={200}>
                    <PieChart>
                      <Pie
                        data={beneficiary.rows}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={30}
                        outerRadius={80}
                        fill="#8884d8"
                        label={({ payload }) => {
                          return Number(payload.payload.value).toLocaleString("de-DE", { minimumFractionDigits: 2 });
                        }}
                      />
                      <Tooltip
                        formatter={value => {
                          return Number(value).toLocaleString("de-DE", { minimumFractionDigits: 2 });
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </LayoutPieChart>
                <Layout spacingLeft={20} displayFlex alignStart justifyCenter column>
                  {beneficiary.rows.map(item => (
                    <Layout displayFlex row alignCenter offsetBottom={10}>
                      <div
                        style={{
                          minWidth: 10,
                          height: 10,
                          backgroundColor: item.fill,
                          marginRight: 10,
                        }}
                      />
                      {item.name}
                    </Layout>
                  ))}
                </Layout>
              </Layout>
            </Layout>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export default compose(
  observer,
  inject("store"),
)(BalanceSheet);
