import React, { Component } from "react";
import { compose } from "recompose";
import { Layout, P, Label } from "../../styled";

import { inject, observer } from "mobx-react";
import { NewsandEventsOuter, NewsItem } from "../../styled/myoverview";

const NewsList = props => (
  <NewsItem>
    <h6>{props.title}</h6>
    <Label>
      <a href={`http://${props.hyperlink}`} target="popup" onclick={`window.open(http://${props.hyperlink},'name','width=600,height=400')`}>
        {props.hyperlink}
      </a>
    </Label>
    <P>{props.description}</P>
  </NewsItem>
);
class TotalClients extends Component {
  componentDidMount() {
    this.getNews();
  }

  getNews = async () => {
    const { getNews } = this.props.store;
    try {
      await getNews.fetch();
    } catch (e) {}
  };
  render() {
    const { getNews } = this.props.store;
    const arrayOfNews = getNews.data ? getNews.data : [];
    return (
      <NewsandEventsOuter>
        <P type="type-10">Neuigkeiten</P>
        <Layout displayFlex row>
          {arrayOfNews.slice(0, 3).map(element => (
            <NewsList hyperlink={element.hyperlink} title={element.title} description={element.text} />
          ))}
        </Layout>
      </NewsandEventsOuter>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(TotalClients);
