import TableStore from './TableStore';
import { decorate, observable, action } from 'mobx';

class ClientsStore extends TableStore {
  selectedClientId = null;
  globalClientId = null;
  personBriefInfo = "";

  onSelectClient = (index) => {
    this.selectedClient = this.data[index];
    window.location.href = `/assets?clientId=${this.selectedClient.client_id}`;
  }

  setSelectedClientId = (clientId) => {
    this.selectedClientId = clientId;
  }
}

export default decorate(ClientsStore, {
  selectedClientId: observable,
  onSelectClient: action,
  personBriefInfo:observable,
  globalClientId:observable,
  setSelectedClientId: action
});
