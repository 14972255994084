import moment from "moment";
import Axios from "axios";
import config from "../../lib/config";
import DownloadingLoader from "../../components/Modal/Downloading";
import FileDownload from "js-file-download";

import { saveAs } from "file-saver";

export const licenseSettlementOrderAdapter = {
  invoice_number: {
    key: "row_1",
    order: 0,
    scale: 2,
    title: "Rechnungsnummer",
  },
  invoice_description: {
    key: "row_2",
    order: 1,
    scale: 3,
    title: "Rechnung",
  },
  createdAt: {
    key: "row_3",
    order: 2,
    scale: 3,
    title: "Rechnungsdatum",
  },
  download: {
    key: "row_4",
    order: 3,
    scale: 1,
    title: "Download",
  },
};

export const licenseSettlementHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

/*****modal*****/

const onPressDownload = async (e, store, index) => {
  const { licenseSettlement, downloadLicenceInvoice, modal } = store;
  const licence = licenseSettlement.data[index];

  const token = window.localStorage.getItem(config.keys.local_storage_key);

  Axios({
    method: "GET",
    url: config.endpoints.api + `/api/admin/download_licence_invoice/${licence.transaction_id}`,
    responseType: "blob",
    headers: { Authorization: `${token}` },
  }).then(res => {
    FileDownload(res.data, "licence.docx");
  });
};

export const licenseSettlementRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          case "createdAt":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: moment(item[itemKey]).format("YYYY-MM-DD"),
            };
            break;
          case "invoice_description":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: `Lizenzabrechnung-${item["invoice_number"]}`,
            };
            break;
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          }
        }
        row[3] = {
          key: `foo_${itemIndex}`,
          type: "icon",
          active: "get_app",
          inactive: "get_app",
          action: (store, index) => e => onPressDownload(e, store, index),
          data: item[itemKey],
        };
      });
      rows[index] = row;
    });
  }
  return rows;
};
