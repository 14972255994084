import React from "react";
import { SearchBox } from "../../../../styled/sidebar";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
const Suggestions = props => {
  let className;
  const options = props.results.map(r => (
    <li className={className} onClick={() => props.onClick(r.client_id, r.first_name + " " + r.last_name)} key={r.client_id}>
      {r.first_name + " " + r.last_name}
    </li>
  ));
  return <ul className="suggestions">{options}</ul>;
};

class LiveSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      searchField: "",
      globalClientId: "",
      liveSearch: "Mandantensuche...",
      open: false,
    };

    this.search = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("click", this.handleClickaway);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClickaway);
  }
  handleClickaway = e => {
    if (this.search && !this.search.current.contains(e.target)) {
      this.setState({ open: false });
    }
  };

  handleInputChange = field => e => {
    this.setState({ searchField: e.target.value }, () => {
      this.handleClientSearch(this.state.searchField);
    });
  };

  handleClientSearch = async value => {
    const {
      store: { clientAutoSearch },
    } = this.props;

    const searchResults = await clientAutoSearch.fetch({
      params: value,
    });
    let data = searchResults.data.data;
    this.setState({
      results: data.length > 0 ? data : [{ first_name: "Kein Ergebnis ", last_name: "gefunden!" }],
    });
  };

  handleOnclick = async clientId => {
    const { store } = this.props;
    if (clientId) {
      this.setState({
        globalClientId: clientId,
      });
      store.clientStore.setSelectedClientId(clientId);
      store.declining.fetch({
        clientId: store.clientStore.selectedClientId,
      });
      await store.updateUser.fetch({
        id: store.user.currentUser.id,
        selected_client: clientId,
      });
      this.props.history.push("/mandanten");
      // this.props.store.clientStore.globalClientId = clientId;
      // window.location.href = "/mandanten";
    }
  };

  handleFocus = () => {
    const { searchField } = this.state;
    this.handleClientSearch(searchField || "");

    this.setState({ open: true });
  };

  handleKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  render() {
    const { open, searchField } = this.state;
    return (
      <SearchBox ref={this.search}>
        <form autoComplete="off">
          <input
            type="search"
            name="search"
            id="search"
            placeholder="Mandantensuche..."
            autoComplete="off"
            onChange={this.handleInputChange("search")}
            onFocus={this.handleFocus}
            value={searchField}
            onKeyDown={this.handleKeyDown}
          />
        </form>
        {open && <Suggestions onClick={this.handleOnclick} results={this.state.results} />}
      </SearchBox>
    );
  }
}

export default compose(
  inject("store"),
  observer,
  withRouter,
)(LiveSearch);
