import React from "react";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";
import * as Yup from "yup";

import { ModalFooterOuter, NotizenForm } from "../../../styled/modal";
import { Button } from "../../../styled/buttons";
import { Layout, FormOuter } from "../../../styled";
import FormInput from "../../Form/Input";
import TextareaInput from "../../Form/Textarea";

class EditNotizen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      fields: {},
      uploading: false,
    };
  }

  componentDidMount() {
    const {
      store: { clientStore },
    } = this.props;
    this.setState({
      notesDescription: clientStore.clientNotes,
    });
  }

  handleChange = field => e => {
    this.setState({
      [field]: e.target.value,
    });
  };

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    const schema = Yup.object().shape({
      notesDescription: Yup.string().required(requiredError),
    });

    try {
      schema.validateSync(fields, { abortEarly: false });
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(e => {
          errors[e.path] = e.message;
        });
      }
    }

    formIsValid = schema.isValidSync(fields);

    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (this[key]) {
        this[key].focus();
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit = async () => {
    const {
      store: {
        updateClientNotes,
        getClientNotes,
        clientStore: { selectedClientId },
      },
    } = this.props;

    if (!this.handleValidation()) {
      return false;
    }

    const { notesDescription } = this.state;

    this.setState({
      uploading: true,
    });
    let data = {
      note_description: notesDescription,
      client_id: selectedClientId,
    };

    await updateClientNotes.fetch({ ...data });
    getClientNotes.fetch({
      clientId: selectedClientId,
    });
    this.setState({
      uploading: false,
    });
    this.props.closeModal();
  };

  render() {
    const { closeModal } = this.props;
    const { uploading } = this.state;

    return (
      <React.Fragment>
        <Layout spacingTop={40} fullWidth spacingLeft={40} spacingRight={40}>
          <NotizenForm>
            <FormOuter>
              <Layout column displayFlex>
                <TextareaInput
                  label="Neue Notizen"
                  placeholder="Neue Notizen"
                  error={this.state.errors["notesDescription"]}
                  value={this.state.notesDescription}
                  onChange={this.handleChange("notesDescription")}
                  ref={el => {
                    this.notesDescription = el;
                  }}
                />
              </Layout>
            </FormOuter>
          </NotizenForm>
        </Layout>
        <ModalFooterOuter>
          <Button onClick={closeModal} color="grey">
            Abbrechen
          </Button>
          <Button onClick={!uploading && this.handleSubmit} color="green">
            {uploading ? "Ihre Datei wird verarbeitet" : "Speichern"}
          </Button>
        </ModalFooterOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(EditNotizen);
