import styled from "styled-components";
import { COLORS } from "./constants";

export const GraphTitle = styled.div`
  color: ${COLORS.BLACK};
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 50px;
  margin-top: 50px;
  ${props => (props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ``)}
  @media only screen and (min-width: 1000px) and (max-width: 1200px) {
    font-size: 0.85rem;
  }
`;
export const GraphInfo = styled.div`
  @media only screen and (max-width: 1100px) {
    padding: 20px 10px;
    padding-left: 20px;
    > div {
      width: 100%;
      display: -webkit-inline-box;
      flex-direction: column;
      align-items: center;
      > div > div {
        display: flex;
        flex-direction: column;
        margin-left: -10px;
        > div {
          width: 100%;
        }
      }
    }
  }
  width: 100%;
  padding: 30px 54px;
  border-bottom: 1px solid #d3d3d3;
  > p {
    margin-top: 30px;
  }
  h6 {
    color: #3e3e3e;
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 20px;
  }
`;
export const IndicatorItem = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 27%;
  span {
    margin-right: 12px;
    width: 20px;
    height: 15px;
    &.case1 {
      i {
        color: #71b0e3;
      }
    }
    &.case2 {
      i {
        color: #95cc53;
      }
    }
    &.case3 {
      i {
        color: #626262;
      }
    }
    &.case4 {
      i {
        color: #ff8a65;
      }
    }
    &.case5 {
      i {
        color: #cacaca;
      }
    }
    &.case6 {
      i {
        color: #f4d166;
      }
    }
    &.twenty {
      i {
        color: #c0e98f;
      }
    }
    &.thirty {
      i {
        color: #94c658;
      }
    }
    &.fourty {
      i {
        color: #749153;
      }
    }
    &.fifty {
      i {
        color: #5b753c;
      }
    }
    &.sixty {
      i {
        color: #f0c3b5;
      }
    }
    &.seventy {
      i {
        color: #b98372;
      }
    }
    &.eighty {
      i {
        color: #a56b59;
      }
    }
    &.ninety {
      i {
        color: #8e5e4f;
      }
    }
    &.hundred {
      i {
        color: #593428;
      }
    }
    i {
      position: relative;
      top: -5px;
      color: red;
    }
  }
  label {
    font-size: 0.9rem;
    color: #3e3e3e;
  }
`;
export const GraphColumn = styled.div`
  width: 50%;
  > div {
    flex-flow: wrap;
  }
`;
export const GraphsOuter = styled.div`
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    display: flex;
    > div {
      width: 100%;
      > div > div {
        width: 100%;
      }
      > div > div > div {
        width: 100%;
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    > div > div > div {
      width: 100%;
    }
  }
  width: 100%;
  display: flex;
  border-bottom: 1px solid #d3d3d3;
`;
export const Graphitem = styled.div`
  position: relative;
  @media only screen and (max-width: 3000px) {
    flex-direction: column;
    display: flex;
    > div {
      width: 100%;
      display: flex;
      justify-content: center;
      > svg {
        display: flex;
        padding-left: 20px;
        width: 50%;
        overflow: visible;

        @media only screen and (max-width: 450px) {
          width: 65%;
        }
        @media only screen and (min-width: 450px) and (max-width: 600px) {
          width: 80%;
        }
        @media only screen and (min-width: 600px) and (max-width: 900px) {
          width: 100%;
        }
        @media only screen and (min-width: 900px) and (max-width: 1040px) {
          width: 55%;
        }
        @media only screen and (min-width: 1040px) and (max-width: 1200px) {
          width: 70%;
        }
        @media only screen and (min-width: 1200px) {
          width: 80%;
        }
      }
    }
  }

  border-right: 1px solid #d3d3d3;
  width: 33%;
  text-align: center;
  align-items: center;
  &:last-child {
    border: none;
  }
`;
export const IndicatorList = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;
  width: 45%;
  padding-left: 5%;
  margin: auto;
  padding-bottom: 10%;
  > div {
    flex-wrap: wrap;
    width: auto;
    > div {
      width: 50%;
    }
  }
  span {
    width: 10px;
    height: 10px;
    i {
      font-size: 13px;
      top: -3px;
    }
  }
  label {
    font-size: 11px;
  }
`;
export const IndicatorListAge = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row;
  flex-wrap: wrap;
  width: 74%;
  padding-left: 5%;
  margin: auto;
  padding-bottom: 10%;
  > div {
    flex-wrap: wrap;
    width: 50%;
    flex-flow: row;
    > div {
      width: 50%;
    }
  }
  span {
    width: 10px;
    height: 10px;
    i {
      font-size: 13px;
      top: -3px;
    }
  }
  label {
    font-size: 11px;
  }
`;

export const GraphitemModal = styled.div`
  position: relative;
  @media only screen and (max-width: 3000px) {
    flex-direction: column;
    display: flex;
    > div {
      width: 100%;
      display: flex;
      justify-content: center;
      > svg {
        display: flex;
        // padding-left: 20px;
        width: 100%;
        // @media only screen and (max-width: 450px) {
        //   width: 65%;
        // }
        // @media only screen and (min-width: 450px) and (max-width: 600px) {
        //   width: 80%;
        // }
        // @media only screen and (min-width: 600px) and (max-width: 900px) {
        //   width: 100%;
        // }
        // @media only screen and (min-width: 900px) and (max-width: 1040px) {
        //   width: 55%;
        // }
        // @media only screen and (min-width: 1040px) and (max-width: 1200px) {
        //   width: 70%;
        // }
        // @media only screen and (min-width: 1200px) {
        //   width: 80%;
        // }
      }
    }
  }
`;

export const GraphsOuterModal = styled.div`
  // @media only screen and (max-width: 1000px) {
  //   flex-direction: column;
  //   display: flex;
  //   > div {
  //     width: 100%;
  //     > div > div {
  //       width: 100%;
  //     }
  //     > div > div > div {
  //       width: 100%;
  //     }
  //   }
  // }
  // @media only screen and (min-width: 768px) and (max-width: 1024px) {
  //   > div > div > div {
  //     width: 100%;
  //   }
  // }
  width: 700px;
  display: flex;
  > div {
    display: flex;
    align-items: center;
  }
`;
