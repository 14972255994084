import React, { Component } from "react";
import { compose } from "recompose";
import { Layout, LoadingDIv } from "../styled";
import { VorlagenOuter } from "../styled/vorlagen";
import AddNewVorlagen from "../components/Vorlagen/FilterOptions";
import { inject, observer } from "mobx-react";
import Table from "../components/Table";
import UploadTemplate from "../components/Modal/NeueVorlage";

class VorlageTable extends Component {
  onPressUpload = () => {
    const {
      store: { modal },
    } = this.props;
    modal.add({
      title: "Neue Vorlage hochladen",
      component: UploadTemplate,
    });
  };

  async componentDidMount() {
    await this.props.store.userTemplates.fetch();
  }

  render() {
    const {
      store: { userTemplates },
    } = this.props;

    return (
      <VorlagenOuter>
        <Layout displayFlex alignCenter>
          <AddNewVorlagen onPressUpload={this.onPressUpload} />
        </Layout>
        {userTemplates.loading && !userTemplates.loaded && <LoadingDIv>&nbsp;</LoadingDIv>}
        {userTemplates.loaded && <Table onClickRow={this.handleRowClick} tableStore={userTemplates} />}
      </VorlagenOuter>
    );
  }
}

export default compose(
  // withPageWrapper("Vorlagen"),
  inject("store"),
  observer,
)(VorlageTable);
