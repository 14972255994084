import React, { Component } from "react";
import InputMask from "react-input-mask";
import FormInput from "../Form/Input";
import { Layout, Icon, Label } from "../../styled";
import { TimeSpinnerContainer, TimeSpinnerInner, RotationStyle, TimerContainer, TimerInputContainer } from "../../styled/timer";

import { TextInput } from "../../styled/form";
import DatePicker from "../Form/DatePicker";
import moment from "moment";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { replaceStringWithNumber } from "../../utils/helper";
import { FormInputLabel } from "../../styled/form";

const TIME_NOTIFICATION_MS = 60 * 60 * 1000;

function msToHMS(duration) {
  // milliseconds = parseInt((duration % 1000) / 100),
  let seconds = parseInt((duration / 1000) % 60),
    minutes = parseInt((duration / (1000 * 60)) % 60),
    hours = parseInt((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + ":" + minutes;
}

function crazyConversionToHundred(duration) {
  let seconds = parseInt((duration / 1000) % 60),
    minutes = parseInt((duration / (1000 * 60)) % 60),
    hours = parseInt(duration / (1000 * 60) / 60);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + ":" + minutes;
}

function durationToHundred(duration) {
  duration = duration.toString();
  const numberDuration = Number(duration && duration.replace("_", "0"));
  return crazyConversionToHundred(numberDuration * 60000).replace(":", ".");
}

class Timer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      started: false,
      elapsed: 0,
      time: "",
      duration: "",
      hiddenDuration: "",
      date: new Date(),
    };
  }

  componentDidMount() {
    const { timerId } = this.props;
    const existingTimer = window.localStorage.getItem(`${timerId}`);
    if (existingTimer) {
      const safeTimer = JSON.parse(existingTimer);
      const timer = JSON.parse(existingTimer);

      window.localStorage.setItem(`${timerId}`, JSON.stringify({ ...timer, pageOpened: true }));
      if (timer.isStopped) {
        this.setState({
          startDate: timer.startDate,
          elapsed: timer.elapsed,
          started: false,
        });
      } else {
        this.handleStart(new Date(safeTimer.startDate));
      }
    }
    this.props.onChange(this.state);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.disabled) {
      clearInterval(this.interval);
    }
    if (
      // nextProps.value &&
      nextProps.changeActionFlag !== this.props.changeActionFlag
    ) {
      window.localStorage.removeItem(`${nextProps.timerId}`);
      if (this.interval) {
        clearInterval(this.interval);
      }
      const newDuration = nextProps.value && nextProps.value.duration ? durationToHundred(nextProps.value.duration) : "";
      const newState = {
        startDate: new Date(),
        elapsed: 0,
        time: "",
        date: new Date(),
        ...nextProps.value,
        duration: newDuration,
        started: false,
      };
      newState.hiddenDuration = newState.duration;
      this.setState(newState);
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    const currentTimer = window.localStorage.getItem(`${this.props.timerId}`);
    if (currentTimer) {
      window.localStorage.setItem(`${this.props.timerId}`, JSON.stringify({ ...JSON.parse(currentTimer), pageOpened: false }));
    }

    this.unmountNotifications();
  }

  unmountNotifications = () => {
    const oldTimeout = window.localStorage.getItem(`${this.props.timerId}-timeout`);
    if (oldTimeout) {
      clearTimeout(Number(oldTimeout));
    }
    const timerValues = window.localStorage.getItem(`${this.props.timerId}`);
    if (this.state.started && timerValues && !JSON.parse(timerValues).isStopped && !JSON.parse(timerValues).pageOpened) {
      const elapsedTime = this.state.elapsed % TIME_NOTIFICATION_MS;
      const whenToTrigger = TIME_NOTIFICATION_MS - elapsedTime;
      const timeout = setTimeout(() => {
        this.props.store.notificationsStore.addNotification("Sie nehmen immer noch Zeit auf");
        this.unmountNotifications();
      }, whenToTrigger);
      window.localStorage.setItem(`${this.props.timerId}-timeout`, timeout);
    }
  };

  handleChange = date => {
    const newState = { ...this.state, startDate: date };
    this.setState(newState);
    if (this.props.onChange) {
      this.props.onChange(newState);
    }
  };

  handleStop = () => {
    const { timerId } = this.props;
    const { elapsed, started } = this.state;
    if (started) {
      if (this.interval) {
        clearInterval(this.interval);
      }
      if (window.localStorage.getItem(`${timerId}`)) {
        window.localStorage.setItem(
          `${timerId}`,
          JSON.stringify({
            startDate: this.state.startDate,
            isStopped: true,
            elapsed: this.state.elapsed,
            pageOpened: true,
          }),
        );
      }
      this.setState(
        {
          started: false,
          duration: crazyConversionToHundred(this.state.elapsed).replace(":", "."),
          hiddenDuration: crazyConversionToHundred(this.state.elapsed).replace(":", "."),
        },
        () => {
          console.log(elapsed);
        },
      );
    } else {
      // TODO: Add action modal
      this.setState({
        started: false,
        elapsed: 0,
      });
      window.localStorage.removeItem(`${timerId}`);
    }
  };
  handleStart = start => {
    if (this.props.disabled) {
      clearInterval(this.interval);
      return;
    }
    const { timerId, onChange } = this.props;
    if (this.state.started) {
      return;
    }

    this.setState(
      {
        started: true,
        startDate: start || new Date(),
      },
      () => {
        window.localStorage.setItem(
          `${timerId}`,
          JSON.stringify({
            startDate: this.state.startDate,
            isStopped: false,
            pageOpened: true,
          }),
        );
        this.interval = setInterval(() => {
          const newDate = new Date();
          this.setState(
            state => ({
              elapsed: newDate - state.startDate,
              hiddenDuration: replaceStringWithNumber((newDate - state.startDate) / 60000),
              duration: "",
            }),
            () => {
              const elapsedTime = this.state.elapsed % TIME_NOTIFICATION_MS;
              if (elapsedTime < 1000 && this.state.elapsed > 999) {
                this.props.store.notificationsStore.addNotification("Sie nehmen immer noch Zeit auf");
              }
              if (onChange) {
                onChange(this.state);
              }
            },
          );
        }, 1000);
      },
    );
  };

  handleChangeInput = e => {
    const { onChange } = this.props;
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (onChange) {
        onChange(this.state);
      }
    });
  };

  handleChangeDuration = e => {
    const { onChange } = this.props;
    let newVal = e.target.value;
    const numberDuration = Number(newVal && newVal.replace("_", "0"));
    if (numberDuration - Math.floor(numberDuration) > 0.59) {
      // 99.[9]9
      newVal = `${newVal.slice(0, 3)}5${newVal.slice(4)}`;
    }
    this.setState({ [e.target.name]: newVal }, () => {
      if (onChange) {
        onChange(this.state);
      }
    });
  };

  handleChangeDate = e => {
    const { onChange } = this.props;
    if (moment(e).isAfter(new Date())) {
      return;
    }
    this.setState({ date: e }, () => {
      if (onChange) {
        onChange({
          ...this.state,
          duration: this.state.duration ? this.state.duration : msToHMS(this.state.elapsed),
        });
      }
    });
  };
  render() {
    const { elapsed, started, time, duration, hiddenDuration } = this.state;
    const { errors } = this.props;
    const parsedElapsed = crazyConversionToHundred(elapsed);
    return (
      // <Layout displayFlex justifyBetween rowToColumn={1024}>
      <TimerContainer>
        <Layout displayFlex alignCenter rowToColumn={600} centerToStart={600}>
          <Layout spacingRight={20} spacingTop={10} spacingBottom={10}>
            Datum
          </Layout>
          <Layout spacingRight={20} spacingTop={10} spacingBottom={10}>
            <DatePicker value={this.state.date} onChange={this.handleChangeDate} error={errors && errors["date"]} />
          </Layout>
        </Layout>
        <TimerInputContainer style={{ width: "100%" }}>
          <Layout spacingRight={20} spacingTop={10} spacingBottom={10} fullWidth>
            <InputMask mask="99:99" value={time} name="time" onChange={this.handleChangeInput}>
              {inputProps => (
                <Layout displayFlex alignCenter rowToColumn={600} centerToStart={600}>
                  <Layout spacingRight={20} spacingTop={10} spacingBottom={10}>
                    Uhrzeit
                  </Layout>
                  <Layout spacingRight={20} fullWidth spacingTop={10} spacingBottom={10}>
                    <FormInput {...inputProps} noOffset placeholder="Uhrzeit (hh:mm)" error={errors && errors["time"]} />
                  </Layout>
                </Layout>
              )}
            </InputMask>
          </Layout>
          <Layout spacingRight={20} spacingTop={10} spacingBottom={10} fullWidth>
            <InputMask
              mask="99.99"
              value={!duration && !started && elapsed > 0 ? hiddenDuration : duration}
              name="duration"
              onChange={this.handleChangeDuration}
            >
              {inputProps => (
                <Layout displayFlex alignCenter rowToColumn={600} centerToStart={600}>
                  <Layout spacingRight={20} spacingTop={10} spacingBottom={10}>
                    Dauer
                  </Layout>
                  <Layout spacingRight={20} fullWidth spacingTop={10} spacingBottom={10}>
                    <FormInput
                      {...inputProps}
                      noOffset
                      error={errors && (errors["duration"] || errors["elapsed"])}
                      placeholder="Dauer (hh:mm)"
                    />
                  </Layout>
                </Layout>
              )}
            </InputMask>
          </Layout>
        </TimerInputContainer>
        <Layout displayFlex alignCenter spacingTop={10} spacingBottom={10}>
          <Layout spacingRight={20}>
            <Icon onClick={() => this.handleStart(new Date() - elapsed)}>timer</Icon>
          </Layout>
          <Layout spacingRight={20}>
            <Icon onClick={this.handleStop}>timer_off</Icon>
          </Layout>
          <Layout displayFlex alignCenter>
            <TimeSpinnerContainer>
              {parsedElapsed}
              <RotationStyle />
              <TimeSpinnerInner animating={started} />
            </TimeSpinnerContainer>
          </Layout>
        </Layout>
      </TimerContainer>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(Timer);
