import styled from "styled-components";
import { COLORS, BORDER_RADIUS, FONTWEIGHT } from "./constants";

export const FragenKatalogOuter = styled.div`
  margin: auto;
`;
export const FragenHeadOuter = styled.div`
  width: 100%;
  padding: 40px;
  background: #fff;
  > div {
    @media only screen and (max-width: 800px) {
      flex-flow: column;
    }
  }
  input {
    padding: 5px 18px;
    width: 50%;
    max-width: none;
    color: ${COLORS.GREEN_GREY_TWO};
    @media only screen and (max-width: 800px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
    &:first-child {
      margin-right: 40px;
      @media only screen and (max-width: 800px) {
        margin-right: 0;
      }
    }
  }
`;
export const BearbeitungsStatusOuter = styled.div`
  padding: 23px 40px;
  background: ${COLORS.LIGHT_GREY};
  border: 1px solid ${COLORS.LIGHT_GREY_TWO};
  @media only screen and (max-width: 900px) {
    padding: 23px 20px;
  }
  > div {
    vertical-align: middle;
    // align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 1200px) {
      flex-flow: column;
      align-items: flex-start;
    }
  }
  h3 {
    font-size: 1.5rem;
    color: ${COLORS.PINE};
    font-weight: ${FONTWEIGHT.FW_6};
    width: 35%;
    @media only screen and (max-width: 1420px) {
      width: 49%;
      font-size: 1.2rem;
    }
    @media only screen and (max-width: 1200px) {
      margin-bottom: 15px;
      width: 100%;
    }
  }
  .ProcessingOptions {
    width: 65%;
    float: right;
    display: flex;
    // align-items: flex-end;
    justify-content: space-between;
    @media only screen and (max-width: 1600px) {
      display: inline-block;
      width: auto;
    }
    @media only screen and (max-width: 900px) {
      width: 100%;
      > div > div {
        margin-right: 0px;
      }
    }
    select {
      min-width: 314px;
      background: #fff;
      padding: 0 17px;
      // margin-right: 18px;
      height: 39px;
      border-radius: ${BORDER_RADIUS.BR_1};
      border: 2px solid ${COLORS.WHITE};
      @media only screen and (max-width: 1420px) {
        min-width: 200px;
      }
      @media only screen and (max-width: 900px) {
        width: 100%;
        margin-right: 0;
        max-width: 100%;
        min-width: 100%;
        margin-bottom: 10px;
      }
    }
    > div {
      width: 100%;
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: 900px) {
        flex-flow: column;
        width: 100%;
      }
      > div {
        // background: #fff;
        border-radius: ${BORDER_RADIUS.BR_1};
        // width: 198px;
        @media only screen and (max-width: 1200px) {
          width: auto;
        }
        @media only screen and (max-width: 900px) {
          width: 100%;
          margin-bottom: 10px;
          .react-datepicker-wrapper {
            width: 100%;
          }
        }
      }
      > button {
        padding: 10px 20px 10px 45px;
        height: 40px;
        margin-left: 20px;
        max-width: 150px;
        min-width: 135px;
        position: relative;
        @media only screen and (max-width: 900px) {
          min-width: 100%;
          max-width: 100%;
        }
        i {
          position: absolute;
          top: 9px;
          left: 20px;
          border: none;
          padding-left: 0;
          font-size: 1.25rem;
          @media only screen and (max-width: 900px) {
            position: relative;
            top: 4px;
            left: 0;
            line-height: 12px;
            margin-right: 5px;
          }
        }
      }
    }
  }
`;
export const FragenListOuter = styled.div`
  padding: 40px;
  background: #fff;
  margin-bottom: 40px;
  @media only screen and (max-width: 900px) {
    padding: 20px;
    button {
      width: 100%;
    }
  }
`;
export const Question = styled.div`
  width: 100%;
  background: ${COLORS.LIGHT_GREY_TEN};
  border: 1px solid ${COLORS.LIGHT_GREY_NINE};
  box-shadow: 0px 2px 3px 0 rgba(118, 127, 106, 0.3);
  padding: 12px 23px;
  align-items: center;
  display: flex;
  font-size: 1.06rem;
  position: relative;
  color: ${COLORS.BLACK};
  cursor: pointer;
  margin-bottom: 14px;
  border-radius: ${BORDER_RADIUS.BR_1};
  font-weight: ${FONTWEIGHT.FW_6};
  span {
    width: 45px;
    @media only screen and (max-width: 800px) {
      width: 35px;
      font-size: 0.9rem;
    }
  }
  h5 {
    @media only screen and (max-width: 800px) {
      font-size: 0.9rem;
    }
  }
  i {
    position: absolute;
    right: 15px;
    top: 10px;
    color: ${COLORS.TURTLE_GREEN_TWO};
  }
`;
export const QuestionCopy = styled.div`
  padding: 30px 23px;
  font-size: 1rem;
  color: ${COLORS.BLACK};
  @media only screen and (max-width: 600px) {
    padding: 0 0 20px 0;
  }
  p {
    color: ${COLORS.BLACK};
    font-weight: 400;
  }
`;
export const ActionsOuter = styled.div`
  width: 100%;
  background: transparent;
  padding: 0 0 20px 0;
  button {
    max-width: 227px;
    height: 43px;
    border-radius: ${BORDER_RADIUS.BR_1};
    box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
    border: 1px solid ${COLORS.TURTLE_GREEN_TWO};
    padding: 11px 20px;
  }
  input {
    height: 43px;
    box-shadow: inset 0px 1px 10px 0 rgba(72, 84, 56, 0.21);
    background: ${COLORS.LIGHT_GREY};
    border: ${COLORS.LIGHT_GREY_EIGHT};
    color: ${COLORS.DARK_SAGE_THREE};
    font-size: 0.93rem;
    max-width: 350px;
  }
`;
export const InputHolder = styled.div`
  width: 85%;
  ${props => props.margin && `margin: ${props.margin}`}
`;
