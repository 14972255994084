import React, { Component } from "react";
import moment from "moment";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { ResponseWarning } from "../../styled/layout";

moment.locale("de");

const warningMessage = `Aktuell liegen zu dieser Ansicht keine Informationen vor`;
// const warningMessage = `Aktuell haben Sie noch keine Mandanten angelegt. Bitte legen Sie
// zunächst Ihre Mandanten an, um eine Übersicht über Ihre Mandanten
//  zu generieren.`;

class ResponseMessage extends Component {
  render() {
    return (
      <ResponseWarning>
        <div className="response success">
          <i className="material-icons">warning</i> {warningMessage}
        </div>
      </ResponseWarning>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(ResponseMessage);
