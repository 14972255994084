import { decorate, observable, action } from 'mobx';

class NotificationStore {
  notifications = []
  addNotification = (notification) => {
    // Temporarily solution
    if(this.notifications.length === 0){
      this.notifications.push(notification);
    }
  }

  removeNotification = (index) => {
    this.notifications.splice(index, 1);
  }
}

export default decorate(NotificationStore, {
  notifications: observable,
  addNotification: action,
  removeNotification: action,
});
