import React, { Component } from "react";
import { Layout } from "../../styled";
import { PageColumn, PageRow } from "../../styled/layout";
import {
  PaymentOptionsOuter,
  PaymentDetails,
  ColTwo,
  CreditCardOptions,
  DebitCardOptions,
  Captcha,
  AdditionalItems,
} from "../../styled/paymentoptions";
import { Speichem, Abbrechen } from "../../styled/buttons";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import FormInput from "../../components/Form/Input";
import CheckBox from "../Form/CheckBox";
import creditcard from "../../assets/images/creditcard.svg";
import DebitCard from "../../assets/images/wallet.png";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../lib/config";
import { browserHistory } from "../../routes/routes";

const INITIAL_STATE = {
  cardHolderName: "Kreditkarten-Inhaber",
  CardNumber: "Kreditkarten-Nummer",
  privacy: false,
  agb: false,
  agbPrivacy: "",
};
const PaymentMethod = props => (
  <div className="PaymentMethodItem">
    <p>
      <input type="radio" id={props.id} name={props.name} checked={props.checked} onChange={props.onChange} value={props.value} />
      <label for={props.for}>
        {" "}
        <h6>
          {" "}
          <i>
            <img src={props.img} alt={props.alt} />
          </i>{" "}
          <span>{props.label}</span>{" "}
        </h6>{" "}
      </label>
    </p>
  </div>
);
class PaymentOptions extends Component {
  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);

    this.state = {
      ...INITIAL_STATE,
      value: "",
      fields: {},
      errors: {},
      capcthaState: false,
      captchaMessage: "",
      selectedOption: "creditcard",
    };
  }

  routeChange() {
    browserHistory.push("/login");
    browserHistory.go();
  }

  onInputChage = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  handleCheckboxChange = name => e => {
    this.setState(state => ({
      [name]: !state[name],
    }));
  };

  click = () => {
    let errors = {};
    let fields = this.state;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    let paymentValid = true;
    let agbPrivacy = "";

    // if (!fields.capcthaState) {
    //   paymentValid = false;
    //   this.setState({ captchaMessage: "Captcha-validierung fehlgeschlagen!" });
    // }

    if (this.state.selectedOption === "creditcard") {
      if (!fields["cvv"] || fields["cvv"].trim() === "") {
        errors["cvv"] = "Pflichtfeld!";
        this.cvv.focus();
        paymentValid = false;
      }

      if (!fields["mmyy"]) {
        errors["mmyy"] = "Pflichtfeld!";
        this.mmyy.focus();
        paymentValid = false;
      }

      if (typeof fields["mmyy"] !== "undefined") {
        if (!fields["mmyy"].match(/^[0-9 /]+$/)) {
          paymentValid = false;
          errors["mmyy"] = "Nur Zahlen";
          this.mmyy.focus();
        }
      }

      if (typeof fields["cvv"] !== "undefined") {
        if (!fields["cvv"].match(/^[0-9]+$/)) {
          paymentValid = false;
          errors["cvv"] = "Nur Zahlen";
          this.cvv.focus();
        }
      }

      if (!fields["cardNumber"] || fields["cardNumber"].trim() === "") {
        errors["cardNumber"] = requiredError;
        this.cardNumber.focus();
        paymentValid = false;
      }

      if (typeof fields["cardNumber"] !== "undefined") {
        if (!fields["cardNumber"].match(/^[0-9]+$/)) {
          paymentValid = false;
          errors["cardNumber"] = "Nur Zahlen";
          this.cardNumber.focus();
        }
      }
    } else {
      if (!fields["bic"] || fields["bic"].trim() === "") {
        errors["bic"] = "Pflichtfeld!";
        this.bic.focus();
        paymentValid = false;
      }

      if (!fields["iban"] || fields["iban"].trim() === "") {
        errors["iban"] = "Pflichtfeld!";
        this.iban.focus();
        paymentValid = false;
      }

      if (!fields["bankinstitut"] || fields["bankinstitut"].trim() === "") {
        errors["bankinstitut"] = "Pflichtfeld!";
        this.bankinstitut.focus();
        paymentValid = false;
      }

      if (!fields["kontoinhaber"] || fields["kontoinhaber"].trim() === "") {
        errors["kontoinhaber"] = "Pflichtfeld!";
        this.kontoinhaber.focus();
        paymentValid = false;
      }
    }

    if (!this.state.agb || !this.state.privacy) {
      agbPrivacy = "Sie sollten die Datenschutzerklärung und die AGB akzeptieren!";
      paymentValid = false;
    }

    this.setState({ errors: errors, agbPrivacy });
    this.props.parentMethod(paymentValid);
  };

  onCaptchaChange = value => {
    if (value != null) {
      this.setState({ capcthaState: true, captchaMessage: "" });
    }
  };

  handleOnChange = field => e => {
    const value = e.target.value;

    this.setState({
      [field]: value,
      errors: { ...this.state.errors },
    });

    this.props.onChange(field, field === "mmyy" && value.length === 2 ? `${value}/` : value);
  };

  handleOptionChange = async changeEvent => {
    this.setState({
      selectedOption: changeEvent.target.value,
    });
    if (this.props.switchPaymentMethod) {
      this.props.switchPaymentMethod(changeEvent.target.value);
    }
  };

  render() {
    return (
      <div>
        <PaymentOptionsOuter>
          <PaymentMethod
            id="creditcard"
            for="creditcard"
            label="Kreditkarte"
            img={creditcard}
            alt="creditcard"
            value="creditcard"
            name="paymentoption"
            checked={this.state.selectedOption === "creditcard"}
            onChange={this.handleOptionChange}
          />
          {/* <PaymentMethod
            id="debitcard"
            for="debitcard"
            label="Lastschrift"
            img={DebitCard}
            alt="debitcard"
            value="debitcard"
            name="paymentoption"
            onChange={this.handleOptionChange}
            checked={this.state.selectedOption === "debitcard"}
          /> */}
        </PaymentOptionsOuter>
        <PaymentDetails>
          <Layout displayFlex column>
            {this.state.selectedOption === "creditcard" ? (
              <CreditCardOptions>
                <PageRow>
                  <label>{this.state.cardHolderName}</label>
                  <FormInput
                    required
                    placeholder={this.state.cardHolderName}
                    onChange={this.handleOnChange("cardHolder")}
                    error={this.state.errors["cardHolder"]}
                    ref={el => {
                      this.cardHolder = el;
                    }}
                  />
                </PageRow>
                <PageRow>
                  <label>{this.state.CardNumber}</label>
                  <FormInput
                    required
                    placeholder={this.state.CardNumber}
                    onChange={this.handleOnChange("cardNumber")}
                    error={this.state.errors["cardNumber"]}
                    ref={el => {
                      this.cardNumber = el;
                    }}
                  />
                </PageRow>
                <PageRow>
                  <ColTwo>
                    <Layout displayFlex column>
                      <PageColumn flex={2} left>
                        <label>MM /JJ</label>
                        <FormInput
                          required
                          placeholder="MM / JJ"
                          maxLength="5"
                          onChange={this.handleOnChange("mmyy")}
                          error={this.state.errors["mmyy"]}
                          ref={el => {
                            this.mmyy = el;
                          }}
                        />
                      </PageColumn>
                      <PageColumn flex={2} right>
                        <label>CVC</label>
                        <FormInput
                          placeholder="CVC"
                          maxLength="3"
                          required
                          onChange={this.handleOnChange("cvv")}
                          error={this.state.errors["cvv"]}
                          ref={el => {
                            this.cvv = el;
                          }}
                        />
                      </PageColumn>
                    </Layout>
                  </ColTwo>
                </PageRow>
              </CreditCardOptions>
            ) : (
              <DebitCardOptions>
                <Layout displayFlex column>
                  <PageRow>
                    <label>Konto-Inhaber</label>
                    <FormInput
                      required
                      onChange={this.handleOnChange("kontoinhaber")}
                      error={this.state.errors["kontoinhaber"]}
                      placeholder="Konto-Inhaber"
                      ref={el => {
                        this.kontoinhaber = el;
                      }}
                    />
                  </PageRow>
                  <PageRow>
                    <label>IBAN</label>
                    <FormInput
                      required
                      onChange={this.handleOnChange("iban")}
                      error={this.state.errors["iban"]}
                      placeholder="IBAN"
                      ref={el => {
                        this.iban = el;
                      }}
                    />
                  </PageRow>
                  <PageRow>
                    <label>BIC</label>
                    <FormInput
                      required
                      onChange={this.handleOnChange("bic")}
                      error={this.state.errors["bic"]}
                      placeholder="BIC"
                      ref={el => {
                        this.bic = el;
                      }}
                    />
                  </PageRow>
                  <PageRow>
                    <label>Bankinstitut</label>
                    <FormInput
                      required
                      onChange={this.handleOnChange("bankinstitut")}
                      error={this.state.errors["bankinstitut"]}
                      placeholder="Bankinstitut"
                      ref={el => {
                        this.bankinstitut = el;
                      }}
                    />
                  </PageRow>
                </Layout>
              </DebitCardOptions>
            )}
            <AdditionalItems>
              <PageRow>
                <CheckBox onClick={this.handleCheckboxChange("agb")} checked={this.state.agb} />
                <label>AGB akzeptieren</label>
              </PageRow>
              <PageRow>
                <CheckBox onClick={this.handleCheckboxChange("privacy")} checked={this.state.privacy} />
                <label>Datenschutzbestimmungen akzeptieren</label>
              </PageRow>
              <span className="agbError">{this.state.agbPrivacy}</span>
            </AdditionalItems>
            <Captcha>
              {/* <PageRow>
                <ReCAPTCHA sitekey={config.keys.captcha_site_key} onChange={this.onCaptchaChange} />
                <span>{this.state.captchaMessage}</span>
              </PageRow> */}
              <PageRow>
                <Abbrechen onClick={this.routeChange} margin="0px">
                  Abbrechen
                </Abbrechen>
                <Speichem onClick={this.click}>Kostenpflichtig bestellen</Speichem>
              </PageRow>
            </Captcha>
          </Layout>
        </PaymentDetails>
      </div>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(PaymentOptions);
