import moment from "moment";

export const activityHistoryOrderAdapter = {
  activity_type_id: {
    key: "row_1",
    order: 0,
    scale: 1,
    title: "Typ",
  },
  note_heading: {
    key: "row_2",
    order: 1,
    scale: 5,
    title: "Aktivität",
  },
  recording_date: {
    key: "row_3",
    order: 2,
    scale: 2,
    title: "Datum",
  },
};

export const activityHistoryHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

// const declareDocument = async (event, store, index) => {
//   event.stopPropagation();
//   const { documents, editDocument } = store;
//   const doc = documents.data[index];

//   const data = {
//     is_declared: !doc.is_declared,
//   };

//   await editDocument.fetch({
//     documentId: doc.document_id,
//     ...data
//   });
//   await documents.fetch();
//   console.log(store, index);
// };

export const activityHistoryRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          case "id":
            break;
          case "activity_type_id":
            let icon = null;
            switch (item[itemKey]) {
            case "appointment":
              icon = "chrome_reader_mode";
              break;
            case "correspondence":
              icon = "contact_mail";
              break;
            case "research":
              icon = "search";
              break;
            case "settlement_activity":
              icon = "euro_symbol";
              break;
            default:
            case "review":
              icon = "supervisor_account";
              break;
            }
            row[orderItem.order] = {
              key: `foo_${index}_${itemIndex}`,
              type: "icon",
              active: icon,
              inactive: icon,
              action: () => () => null,
              data: item[itemKey],
            };
            break;
          case "recording_date": {
            row[orderItem.order] = {
              key: `foo_${index}_${itemIndex}`,
              type: "text",
              data: moment(item[itemKey]).format("DD.MM.YYYY"),
            };
            break;
          }
          default: {
            row[orderItem.order] = {
              key: `foo_${index}_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          }
        }
      });
      rows[index] = row;
    });
  }
  return rows;
};
