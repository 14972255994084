import React from "react";
import { Layout, P } from "../../../styled";
import { ModalFooterOuter } from "../../../styled/actionmodalconfirmation";
import { Button } from "../../../styled/buttons";

const CancelLicence = ({ clientId, store }) => {
  const onCancelLicence = () => {
    store.cancelClientLicence.fetch({ clientId });
    store.clients.fetch();
    store.clientsList.fetch();
    store.clientStore.setSelectedClientId(null);
    store.modal.remove();
  };
  return (
    <>
      <Layout alignCenter justifyCenter column displayFlex fullWidth spacing={40}>
        <P>Wollen Sie die Lizenz wirklich kündigen?</P>
      </Layout>
      <Layout fullWidth alignCenter justifyCenter>
        <ModalFooterOuter>
          <Layout displayFlex justifyCenter rowToColumn={500}>
            <Button onClick={() => store.modal.remove()} color="grey">
              Abbrechen
            </Button>
            <Button onClick={onCancelLicence} color="red">
              Ja
            </Button>
          </Layout>
        </ModalFooterOuter>
      </Layout>
    </>
  );
};

export { CancelLicence };
