import React from "react";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";
import Axios from "axios";
import * as Yup from "yup";

import { Layout } from "../../../styled";
import { Button } from "../../../styled/buttons";
import { ModalContent, ModalFooterOuter } from "../../../styled/modal";
import { CreateNewPoliceOuter, PoliceModalFooterOuter } from "../../../styled/createnewpolice";
import FormInput from "../../../components/Form/Input";
import config from "../../../lib/config";
import CheckBox from "../../../components/Form/CheckBox";
import TextareaInput from "../../../components/Form/Textarea";
import { PrimaryInfo, PrimarySelectInfo } from "../../../styled/signup";
import Select from "../../../components/Form/Select";
import { FormInputLabel } from "../../../styled/form";
import { handleChangeMoneyInput, getMoneyNumber } from "../../../utils/helper";

class CreateNewPolice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      fields: {},
      police: "",
      notes: "",
      value: "",
      file: null,
      selected: false,
      beneficiary: "",
      persons: [],
      uploading: false,
    };

    this.upload = React.createRef();
  }

  async componentDidMount() {
    const {
      store: { client, personsListing },
    } = this.props;
    await personsListing.fetch({
      clientId: client.data.client_id,
    });
    let persons = [];
    if (personsListing.data !== null) {
      personsListing.data.map((person, index) => {
        if (person.is_beneficiary) persons.push(person);
      });
    }

    this.setState({
      persons,
    });
  }

  onUploadFile = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChange = field => e => {
    let value = null;
    if (typeof e === "string") {
      value = e;
    } else {
      value = e.target.value;
    }
    if (field === "value") {
      const newVal = handleChangeMoneyInput(e.target.value);
      if (newVal || newVal === "") {
        value = newVal;
      } else {
        return;
      }
    }
    this.setState({
      [field]: value,
      errors: { ...this.state.errors, [field]: "" },
    });
  };

  handleCheckboxChange = () => {
    this.setState({
      selected: !this.state.selected,
    });
  };

  handleValidation = () => {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";

    const schema = Yup.object().shape({
      police: Yup.string().required(requiredError),
    });

    try {
      schema.validateSync(fields, { abortEarly: false });
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(e => {
          errors[e.path] = e.message;
        });
      }
    }

    formIsValid = schema.isValidSync(fields);

    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (this[key]) {
        this[key].focus();
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  submit = async () => {
    const { police, notes, value, beneficiary, selected } = this.state;
    const {
      store: { clientStore },
    } = this.props;

    const file = this.upload.current.files[0];

    const {
      store: { client, getPolicies },
    } = this.props;

    if (!this.handleValidation()) {
      return false;
    }

    const token = window.localStorage.getItem(config.keys.local_storage_key);

    let body = new FormData();
    body.set("client_id", client.data.client_id);
    body.set("policy_name", police);
    body.set("policy_notes", notes);
    body.set("policy_value", value !== "" ? getMoneyNumber(value) : 0);
    body.set("beneficiary", beneficiary);
    body.set("is_asset", selected);
    body.set("client_id", clientStore.selectedClientId);
    body.set("has_file", file ? true : false);
    body.append("policy_document", file);
    this.setState({
      uploading: true,
    });

    Axios({
      method: "POST",
      url: config.endpoints.api + "/api/policy/create",
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    }).then(async () => {
      await getPolicies.fetch({
        clientId: client.data.client_id,
      });
      this.props.closeModal();
      this.setState({
        uploading: false,
      });
    });
  };

  getPersonName = beneficiaryId => {
    const person = this.state.persons.find(item => item.person_id === beneficiaryId && item.is_beneficiary);

    return !person ? "" : `${person.first_name || ""} ${person.last_name || ""}`;
  };

  render() {
    const {
      store: { personsListing },
    } = this.props;
    const { uploading } = this.state;
    let persons = [];

    if (!personsListing.data) {
      return null;
    }

    if (personsListing.data !== null) {
      personsListing.data.map((person, index) => {
        if (person.is_beneficiary) persons.push(person);
      });
    }

    return (
      <React.Fragment>
        <ModalContent>
          <CreateNewPoliceOuter>
            {/* <PrimaryInfo> */}
            <Layout column displayFlex alignStart>
              <PrimaryInfo fullWidth spacingBottom={20}>
                <Layout displayFlex fullWidth>
                  <FormInput
                    name="police"
                    error={this.state.errors["police"]}
                    ref={el => (this.police = el)}
                    label="Police"
                    onChange={this.handleChange("police")}
                    placeholder="Police"
                  />
                </Layout>
              </PrimaryInfo>
              {/* <PrimaryInfo fullWidth> */}
              <Layout row displayFlex fullWidth>
                <PrimaryInfo fullWidth spacingBottom={30} maxHeight={200}>
                  <TextareaInput label="Notizen" placeholder="Notizen" onChange={this.handleChange("notes")} value={this.state.notes} />
                </PrimaryInfo>
              </Layout>
              <PrimaryInfo fullWidth spacingBottom={20}>
                <Layout row displayFlex fullWidth>
                  <FormInput
                    label="Wert zuordnen"
                    onChange={this.handleChange("value")}
                    error={this.state.errors["value"]}
                    value={this.state.value}
                    ref={el => {
                      this.value = el;
                    }}
                    placeholder="Wert zuordnen"
                  />
                </Layout>
              </PrimaryInfo>
              <PrimaryInfo fullWidth spacingBottom={20}>
                <Layout row displayFlex fullWidth>
                  <FormInput ref={this.upload} onChange={this.onUploadFile} name="file" type="file" label="Dokument zuordnen" />
                </Layout>
              </PrimaryInfo>
              <Layout
                // style={{ padding: "0px 0px 25px 160px" }}
                row
                displayFlex
                fullWidth
                alignCenter
                offsetLeft={20}
                offsetBottom={20}
                spacingLeft={160}
                spacingBottom={25}
                mediaPadding={600}
              >
                <CheckBox onClick={this.handleCheckboxChange} checked={this.state.selected} />
                <FormInputLabel
                  maxWidth={300}
                  // style={{ paddingLeft: 20 }}
                >
                  Bestandteil Vermögensverzeichnis
                </FormInputLabel>
              </Layout>
              {/* </PrimaryInfo> */}
              <PrimarySelectInfo fullWidth spacingBottom={20}>
                <Layout row displayFlex fullWidth>
                  <FormInputLabel>Begünstigter</FormInputLabel>
                  <Select
                    noAdapter
                    spacingLeft={20}
                    label="test"
                    onChange={this.handleChange("beneficiary")}
                    options={persons.map(p => p.person_id)}
                    customAdapter={this.getPersonName}
                    // options={[]}
                    placeholder="Begünstigter"
                  />
                </Layout>
              </PrimarySelectInfo>
            </Layout>

            {/* </PrimaryInfo> */}
          </CreateNewPoliceOuter>
        </ModalContent>
        <ModalFooterOuter>
          <PoliceModalFooterOuter>
            <Button onClick={!uploading && this.submit} color="green">
              {uploading ? "Ihre Datei wird verarbeitet" : "Policen erstellen"}
              <i className="material-icons">ballot</i>
            </Button>
          </PoliceModalFooterOuter>
        </ModalFooterOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(CreateNewPolice);
