import _ from "lodash";
import React from "react";
import qs from "qs";
import { Layout, Icon } from "../../styled";
import { AssetTableContainer, AssetTableItem, AssetTableHeaderItem, AssetTableInput } from "../../styled/assets-table";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AssetTableRow } from "../../styled/assets-table";
import Select from "../Form/Select";
import TableFile from "../TableFile";
import { FilesContainer } from "../../styled/assets-table";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import CreateNewBeneficiary from "../Modal/CreateNewBeneficiary";
import { Button } from "../../styled/buttons";
import { FileUploadButton } from "../../styled/assets-table";
import { AssetUploadButton } from "../Form/UploadButton";
import config from "../../lib/config";
import Axios from "axios";
import DownloadingDiv from "../Form/Loader";

class DecliningList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetBeneficiaries: {},
      assetCategoryBeneficiaries: {},
      closedRows: {},
    };
  }
  componentDidMount() {
    const {
      store: { declining, clientStore, personsListing },
      // client
    } = this.props;
    declining.fetch({
      clientId: clientStore.selectedClientId,
    });
    personsListing.fetch({
      clientId: clientStore.selectedClientId,
    });
  }
  handleChangeAssetStatus = asset => status => {
    const {
      store: { declining, assetModifier, clientStore },
    } = this.props;

    assetModifier
      .fetch({
        assetId: asset.assetId,
        status,
      })
      .then(() => {
        declining.fetch({
          clientId: clientStore.selectedClientId,
        });
      });
  };
  handleChangeAssetDetermination = asset => resolution => {
    const {
      store: { declining, assetModifier, clientStore },
    } = this.props;

    assetModifier
      .fetch({
        assetId: asset.assetId,
        resolution,
      })
      .then(() => {
        declining.fetch({
          clientId: clientStore.selectedClientId,
        });
      });
  };
  handleChangeAssetCategory = assetCategory => body => {
    const {
      store: { assets, assetCategoryModifier, clientStore },
    } = this.props;

    assetCategoryModifier
      .fetch({
        assetCategoryId: assetCategory.asset_category_id,
        ...body,
      })
      .then(() => {
        assets.fetch({
          clientId: clientStore.selectedClientId,
        });
      });
  };
  handleChangeAssetBeneficiary = (asset, oldValue) => (beneficiary, beneficiary_index) => {
    const {
      store: { assets, assetModifier, clientStore, personsListing },
    } = this.props;

    let oldBeneficiaries = _.get(asset, "beneficiary", []);

    oldBeneficiaries = oldBeneficiaries.filter(v => {
      return v !== oldValue;
    });

    const beneficiaryId = personsListing.data[beneficiary_index].person_id;

    const beneficiaries = _.uniq([...oldBeneficiaries, beneficiaryId]);

    assetModifier
      .fetch({
        assetId: asset.assetId,
        beneficiary: beneficiaries.join(","),
      })
      .then(() => {
        this.setState({ assetBeneficiaries: {} });
        assets.fetch({
          clientId: clientStore.selectedClientId,
        });
      });
  };

  addNewBeneficiary = (asset, type) => () => {
    const {
      store: { assets, clientStore },
    } = this.props;
    const newState = {
      [type]: {
        [asset.assetId]: ["new_beneficiary"],
      },
    };

    this.setState({ [type]: newState });

    assets.fetch({
      clientId: clientStore.selectedClientId,
    });
  };

  handleFileChange = assetId => async e => {
    const {
      store: { assets, clientStore },
      // client
    } = this.props;

    const files = Array.from(e.target.files);
    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append(i, file);
    });

    // formData.append

    const fullUri = `${config.endpoints.api}/api/assets/asset/${assetId}/${clientStore.selectedClientId}/file`;
    const requestOptions = {
      method: "PUT",
      data: formData,
    };

    requestOptions.url = fullUri;
    const token = window.localStorage.getItem(config.keys.local_storage_key);
    if (token) {
      requestOptions.headers = {
        Authorization: `${token}`,
      };
    }

    await Axios(requestOptions);
    assets.fetch({
      clientId: clientStore.selectedClientId,
    });
  };

  handleDeleteFile = file => async event => {
    event.preventDefault();
    const {
      store: { deleteAssetFile, assets, clientStore },
    } = this.props;
    const response = window.confirm("Are you sure");
    if (response) {
      await deleteAssetFile.fetch({
        fileId: file.file_id,
      });
      assets.fetch({
        clientId: clientStore.selectedClientId,
      });
    }
  };

  getPersonName = beneficiaryId => {
    const {
      store: { personsListing },
    } = this.props;

    const person = personsListing.data.find(item => item.person_id === beneficiaryId);

    return !person ? "" : `${person.first_name || ""} ${person.last_name || ""}`;
  };

  removeBeneficiary = (asset, beneficiaryId) => e => {
    const {
      store: { assets, assetModifier, clientStore },
    } = this.props;
    if (asset.beneficiary.length === 0) {
      return this.setState({ assetBeneficiaries: {} });
    }
    const beneficiaries = _.get(asset, "beneficiary", []);
    const filtered = beneficiaries.filter(b => b !== beneficiaryId);

    assetModifier
      .fetch({
        assetId: asset.assetId,
        beneficiary: filtered.join(","),
      })
      .then(() => {
        assets.fetch({
          clientId: clientStore.selectedClientId,
        });
      });
  };

  removeCategoryBeneficiary = (assetCategory, beneficiaryId) => e => {
    const {
      store: { assets, assetCategoryModifier, clientStore },
    } = this.props;
    if (assetCategory.beneficiary.length === 0) {
      return this.setState({ assetBeneficiaries: {} });
    }
    const beneficiaries = _.get(assetCategory, "beneficiary", []);
    const filtered = beneficiaries.filter(b => b !== beneficiaryId);

    assetCategoryModifier
      .fetch({
        assetCategoryId: assetCategory.asset_category_id,
        beneficiary: filtered.join(","),
      })
      .then(() => {
        assets.fetch({
          clientId: clientStore.selectedClientId,
        });
      });
  };

  closeRow = categoryId => () => {
    this.setState(state => ({
      closedRows: {
        ...state.closedRows,
        [categoryId]: !state.closedRows[categoryId],
      },
    }));
  };

  render() {
    const {
      store: { declining, personsListing, sidebarOpen },
      customWidth,
    } = this.props;

    if ((declining.loading && !declining.loaded) || !declining.constructed) {
      return (
        <Layout style={{ marginTop: "20px" }}>
          <DownloadingDiv />
        </Layout>
      );
    }

    if (declining.data && declining.data.assets.length === 0) {
      return null;
    }

    const { assetBeneficiaries, assetCategoryBeneficiaries, closedRows } = this.state;

    let w = 0;
    const isWindows = config.isWindows;
    if (customWidth) {
      w = `max-width: ${customWidth}px;`;
    } else {
      w = sidebarOpen
        ? `max-width: calc(100vw - ${468 + (isWindows ? 19 : 2)}px);`
        : `max-width: calc(100vw - ${240 + (isWindows ? 19 : 2)}px);`;
    }
    return (
      <PerfectScrollbar>
        <AssetTableContainer mobile={w}>
          <AssetTableRow>
            {declining.constructed.header.map(({ title, ...rest }) => (
              <AssetTableHeaderItem {...rest}>{title}</AssetTableHeaderItem>
            ))}
          </AssetTableRow>
          {declining.constructed.sections.map((section, sectionIndex) => (
            <React.Fragment>
              <AssetTableRow heading>
                <AssetTableItem heading scale={0.85}>
                  <Icon type="material-icons" onClick={this.closeRow(section.title)}>
                    {!closedRows[section.title] ? "indeterminate_check_box" : "add_to_photos"}
                  </Icon>
                  {`${sectionIndex + 1} ${section.title}`}
                </AssetTableItem>
                <AssetTableItem heading align="end" scale={0.15}>
                  {Number(section.value || 0).toLocaleString("de-DE", { minimumFractionDigits: 2 })}
                </AssetTableItem>
              </AssetTableRow>
              {section.categories.map((cat, catIndex) => {
                if (cat.rows.length > 0) {
                  let catBeneficiary = [
                    ...cat.beneficiary,
                    ..._.get(assetCategoryBeneficiaries, `assetCategoryBeneficiaries.${cat.asset_category_id}`, []),
                  ];
                  if (catBeneficiary.length === 0) {
                    catBeneficiary = [""];
                  }

                  return (
                    <React.Fragment>
                      <AssetTableRow closed={closedRows[section.title]}>
                        <AssetTableItem scale={cat.scales[0]}>
                          <Icon type="material-icons" onClick={this.closeRow(cat.asset_category_id)}>
                            {!closedRows[cat.asset_category_id] ? "indeterminate_check_box" : "add_to_photos"}
                          </Icon>
                          {catIndex + 1}
                        </AssetTableItem>
                        <AssetTableItem scale={cat.scales[1]}>{cat.title}</AssetTableItem>
                        <AssetTableItem scale={cat.scales[2]} />
                        <AssetTableItem heading align="end" scale={cat.scales[3]}>
                          {Number(cat.value || 0).toLocaleString("de-DE", { minimumFractionDigits: 2 })}
                        </AssetTableItem>
                      </AssetTableRow>
                      {cat.rows.map((row, rowIndex) => (
                        <AssetTableRow closed={closedRows[section.title] || closedRows[cat.asset_category_id]}>
                          <AssetTableItem vAlign="top" align="end" scale={cat.scales[0]}>
                            {`${catIndex + 1}.${rowIndex + 1}`}
                          </AssetTableItem>
                          <AssetTableItem vAlign="top" small scale={cat.scales[1]}>
                            {row.title}
                          </AssetTableItem>
                          <AssetTableItem vAlign="top" scale={cat.scales[2]}>
                            <Layout displayFlex column fullWidth>
                              {row.fields && row.fields.inputs && (
                                <Layout fullWidth offsetBottom={20}>
                                  <Layout displayFlex column fullWidth>
                                    {row.fields && row.fields.inputs && (
                                      <Layout fullWidth offsetBottom={20}>
                                        {row.fields.inputs.map(input => (
                                          <Layout>{`${input.placeholder}: ${input.value}`}</Layout>
                                        ))}
                                      </Layout>
                                    )}
                                  </Layout>
                                </Layout>
                              )}
                              {row.fields.files && (
                                <Layout fullWidth>
                                  <Layout>
                                    <FilesContainer>
                                      {row.fields.files.map(file => (
                                        <TableFile download={file.title} href={`${file.src}`} target="_blank" title={file.title} />
                                      ))}
                                    </FilesContainer>
                                  </Layout>
                                </Layout>
                              )}
                            </Layout>
                          </AssetTableItem>
                          <AssetTableItem vAlign="top" align="end" scale={cat.scales[3]}>
                            {Number(row.value).toLocaleString("de-DE", { minimumFractionDigits: 2 })}
                          </AssetTableItem>
                        </AssetTableRow>
                      ))}
                    </React.Fragment>
                  );
                }
              })}
            </React.Fragment>
          ))}
        </AssetTableContainer>
      </PerfectScrollbar>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(DecliningList);
