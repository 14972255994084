import React from "react";
import { P } from "../../styled";
import { NotizenOuter } from "../../styled/MandantenDashboard";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";
import EditNotizen from "../Modal/Notizen/edit";

class Notizen extends React.Component {
  handleUpload = () => {
    const {
      store: { modal, clientStore },
    } = this.props;

    clientStore.clientNotes = this.props.data[0].note_description;

    modal.add({
      title: "Notizen bearbeiten",
      component: EditNotizen,
    });
  };

  render() {
    const { data } = this.props;

    return (
      <NotizenOuter>
        <div className="editNotizen">
          <i className="material-icons" onClick={this.handleUpload}>
            edit
          </i>
        </div>
        <P type="type-4">
          <i className="material-icons">file_copy</i>Notizen
        </P>
        <P type="type-4_1">{data[0].note_description}</P>
        <P type="type-6"></P>
      </NotizenOuter>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(Notizen);
