import React from "react";
import { Layout, Icon } from "../../styled";
import { FileContainer } from "../../styled/assets-table";
import { COLORS } from "../../styled/constants";

const TableFile = ({ title, onClick, ...rest }) => (
  <FileContainer {...rest}>
    <Layout displayFlex alignCenter flex={1}>
      <Icon>attachment</Icon>
      <span style={{ color: COLORS.GREYISH_BROWN_TWO }}>{title}</span>
    </Layout>
    {onClick && <Icon onClick={onClick}>close</Icon>}
  </FileContainer>
);

export default TableFile;
