import React from "react";
import { Layout } from "../../styled";
import { FragenHeadOuter } from "../../styled/questioncatalogue";
import { TextInput } from "../../styled/form";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

class FragenHead extends React.Component {
  async componentDidMount() {
    const {
      store: { client, clientStore },
    } = this.props;
    if (!clientStore.selectedClientId) {
      return; // TODO: redirect somewhere
    }
    try {
      await client.fetch({ clientId: clientStore.selectedClientId });
    } catch (e) {
      // TODO: redirect somewhere
    }
  }

  render() {
    const {
      store: { client, getUserFromToken },
    } = this.props;
    let namePrefix = client.data.salutation;
    let firstName = client.data.first_name;
    let lastName = client.data.last_name;
    let fullName = `${namePrefix} ${firstName} ${lastName}`;

    return (
      <FragenHeadOuter>
        <Layout displayFlex>
          <TextInput placeholder="Mandant (Name and First Name)" value={fullName} disabled />
          <TextInput placeholder="Vollmacht" />
        </Layout>
      </FragenHeadOuter>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(FragenHead);
