import _ from "lodash";
import Axios from "axios";
import moment from "moment";
import { store } from "../../App";
import config from "../../lib/config";

export const assetsRevisionOrderAdapter = {
  // asset_revision_id: {
  //   key: "asset_revision_id",
  //   order: 15,
  //   scale: 0,
  //   title: "Version Vermögensverzeichnis",
  // },
  revision_version: {
    key: "revision_version",
    order: 0,
    scale: 2,
    title: "Version Vermögensverzeichnis",
  },
  createdAt: {
    key: "createdAt",
    order: 1,
    scale: 3,
    title: "Eingefroren am / um / durch",
  },
  useRevision: {
    key: "useRevision",
    order: 2,
    scale: 2,
    title: "  ",
    notSortable: true,
  },
  download: {
    key: "download",
    order: 3,
    scale: 0.5,
    title: "Download Link",
    notSortable: true,
  },
  empty: {
    key: "empty",
    order: 4,
    scale: 0.5,
    title: "",
    notSortable: true,
  },
};
export const decliningRevisionOrderAdapter = {
  revision_version: {
    key: "revision_version",
    order: 0,
    scale: 2,
    title: "Version Vermögensverzeichnis",
  },
  createdAt: {
    key: "createdAt",
    order: 1,
    scale: 2,
    title: "Eingefroren am / um / durch",
  },
  download: {
    key: "download",
    order: 2,
    scale: 0.5,
    title: "download",
  },
  empty: {
    key: "empty",
    order: 3,
    scale: 0.1,
    title: "",
  },
};

export const assetsRevisionHeaderAdapter = order => {
  const sortedHeader = [];
  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

export const assetsRevisionRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      item.download = "item";
      item.empty = "item";
      item.useRevision = "item";

      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          case "empty":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "checkbox",
              checked: _.get(store, `assetsRevisions.selected.${item.asset_revision_id}`),
              action: () => () => store.assetsRevisions.select(item.asset_revision_id),
            };
            break;
          case "download": {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "link",
              data: `Link`,
              hasOnClick: true,
              onClick: () => () => download(item.asset_revision_id),
            };
            break;
          }
          case "useRevision": {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "blankButton",
              data: `Version kopieren und ergänzen`,
              revisionId: item.asset_revision_id,
            };
            break;
          }
          case "revision_version": {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: `Version ${item[itemKey]}`,
            };
            break;
          }
          case "createdAt": {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: `${moment(item[itemKey]).format("DD.MM.YYYY / HH:mm")} / ${item["revision_comment"]}`,
            };
            break;
          }
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          }
        }
      });
      rows[index] = row;
    });
  }
  return rows;
};

const download = elem => {
  const token = window.localStorage.getItem(config.keys.local_storage_key);

  return Axios({
    method: "POST",
    url: config.endpoints.api + `/api/assets/client/${store.clientStore.selectedClientId}/revisions/download`,
    responseType: "application/json",
    headers: { Authorization: `${token}` },
    data: { revisionIds: [elem] },
  }).then(res => {
    const array = res.data || [];
    array.forEach((uri, i) => {
      setTimeout(() => {
        window.open(`${config.endpoints.api}/reportdownload/${uri}`);
      }, i * 500);
    });

    store.declining.clearSelected();
  });
};

export const decliningRevisionRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      item.download = "item";
      item.empty = "item";

      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          case "empty":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "checkbox",
              checked: _.get(store, `declining.selected.${item.asset_revision_id}`),
              action: () => () => store.declining.select(item.asset_revision_id),
            };
            break;
          case "download": {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "link",
              data: `PDF Link`,
              hasOnClick: true,
              onClick: () => () => download(item.asset_revision_id),
            };
            break;
          }
          case "revision_version": {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: `Version ${item[itemKey]}`,
            };
            break;
          }
          case "createdAt": {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: `${moment(item[itemKey]).format("DD.MM.YYYY / HH:mm")} / ${item["revision_comment"]}`,
            };
            break;
          }
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          }
        }
      });
      rows[index] = row;
    });
  }
  return rows;
};
