import _ from "lodash";
import PromiseStore from "./PromiseStore";
import { decorate, computed, action, observable, toJS } from "mobx";
import { endpoints } from "../lib/endpoints";
import config from "../lib/config";
import uuidv4 from "uuid/v4";

const calculateValue = (rows, field) => {
  let value = 0;
  rows.forEach(row => {
    value += Number(row[field] || 0);
  });

  return value.toLocaleString("de-DE", { currency: "EUR" });
};

const generateValue = value => `${parseFloat(value || 0).toLocaleString("de-DE", { currency: "EUR" })}`;

class AssetEditStore extends PromiseStore {
  constructor(query, __, root) {
    super(query);
    this.root = root;

    let REVISION_ID = window.localStorage.getItem("CURRENT_REVISION_ID");

    this.REVISION_ID = null;

    if (REVISION_ID) {
      this.REVISION_ID = REVISION_ID;
    }
    this.edited = false;
    this.backup = null;
    this.changedFields = {};
    this.changedValues = {};
    this.beneficiaryAmounts = {};
    this.timeouts = {};
    this.extraAssets = [];
    this.updater = new PromiseStore(endpoints.update_asset_field);
    this.delete = new PromiseStore(endpoints.delete_asset_from_revision);
    this.hardDelete = new PromiseStore(endpoints.delete_asset);
    this.save = new PromiseStore(endpoints.save_asset_revision);
  }

  async fetch(data) {
    const response = await super.fetch(data);
    if (Object.keys(toJS(this.beneficiaryAmounts) || {}).length === 0) {
      this.beneficiaryAmounts = _.get(response, "data.data.beneficiaryValues");
    }

    const backup = _.cloneDeep(response.data);

    this.backup = backup;
  }

  selectRevision = revisionid => {
    if (this.root.tab) {
      try {
        this.root.tab(4)();
      } catch (___) {}
    }
    this.REVISION_ID = revisionid;
    window.localStorage.setItem("CURRENT_REVISION_ID", this.REVISION_ID);
    this.root.updateClients.fetch({
      clientId: this.root.clientStore.selectedClientId,
      user_updated: this.root.user.name + this.root.user.last_name,
      assets_updated_at: Date.now(),
    });
    this.extraAssets = [];
  };

  editBeneficiary = data => {
    const assets = [...(this.data.assets || []), ...this.extraAssets];
    const asset = assets.find(asset => asset.asset_id === data.assetId);
    if (asset) {
      asset.beneficiary = data.beneficiary;
    }
    this.root.updateClients.fetch({
      clientId: this.root.clientStore.selectedClientId,
      user_updated: this.root.user.name + this.root.user.last_name,
      assets_updated_at: Date.now(),
    });
  };

  onChangeFieldValue = (item, field) => e => {
    this.changedValues[`${item.assetId}_${field}`] = e.target.value;

    if (this.data.assets) {
      const ind = this.data.assets.findIndex(asset => {
        return asset.asset_id === item.assetId;
      });
      if (ind > -1) {
        this.data.assets[ind][field] = e.target.value;
      }
    }

    clearTimeout(this.timeouts[`${item.assetId}_${field}`]);

    this.timeouts[`${item.assetId}_${field}`] = setTimeout(async () => {
      const v = this.changedValues[`${item.assetId}_${field}`];

      await this.root.assetModifier.fetch({
        assetId: item.assetId,
        [field]: v || 0,
      });
      this.root.assets.fetch({
        clientId: this.root.clientStore.selectedClientId,
      });
      this.root.updateClients.fetch({
        clientId: this.root.clientStore.selectedClientId,

        user_updated: this.root.user.name + this.root.user.last_name,
        assets_updated_at: Date.now(),
      });
    });
  };

  onChangeInputValue = item => e => {
    this.changedFields[item.id] = e.target.value;

    clearTimeout(this.timeouts[item.id]);
    this.timeouts[item.id] = setTimeout(() => {
      this.updater.fetch({
        fieldId: item.id,
        value: this.changedFields[item.id],
      });
    }, 2000);
    this.root.updateClients.fetch({
      clientId: this.root.clientStore.selectedClientId,

      user_updated: this.root.user.name + this.root.user.last_name,
      assets_updated_at: Date.now(),
    });
  };

  onChangeBeneficiaryAmount = (row, beneficiary) => e => {
    const clientId = this.root.clientStore.selectedClientId;
    let value = e.target.value.replace(/\D/g, "");

    if (parseInt(value, 10) < 0) {
      value = 0;
    }
    if (!_.isObject(this.beneficiaryAmounts[clientId])) {
      this.beneficiaryAmounts[clientId] = {};
    }

    if (!_.isObject(this.beneficiaryAmounts[clientId][row.assetId])) {
      this.beneficiaryAmounts[clientId][row.assetId] = {};
    }
    this.beneficiaryAmounts[clientId][row.assetId][beneficiary] = value;

    let maxValue = Number(row.valueSold);

    Object.keys(this.beneficiaryAmounts[clientId][row.assetId]).forEach(k => {
      if (k !== beneficiary) {
        const singleValue = this.beneficiaryAmounts[clientId][row.assetId][k];
        maxValue -= singleValue;
      }
    });
    if (Number(value) > Number(maxValue)) {
      value = maxValue;
      this.beneficiaryAmounts[clientId][row.assetId][beneficiary] = value;
    }

    clearTimeout(this.timeouts[`${clientId.assetId}_${row.assetId}_${beneficiary}`]);

    this.timeouts[`${clientId.assetId}_${row.assetId}_${beneficiary}`] = setTimeout(() => {
      this.root.updateBeneficiaryAmount.fetch({
        clientId,
        assetId: row.assetId,
        beneficiaryId: beneficiary,
        amount: value,
      });

      const bV = this.data.beneficiaryValues;

      bV[clientId] = {
        ...bV[clientId],
        [row.assetId]: {
          ...(bV[clientId] ? bV[clientId][row.assetId] : {}),
          [beneficiary]: value,
        },
      };

      this.data.beneficiaryValues = bV;
      this.beneficiaryAmounts = bV;

      this._doUpdate(false, true, true, this.data, null, false);
    }, 300);
    this.root.updateClients.fetch({
      clientId: this.root.clientStore.selectedClientId,

      user_updated: this.root.user.name + this.root.user.last_name,
      assets_updated_at: Date.now(),
    });
  };

  onDeleteAsset = (asset, catIndex, rowIndex) => async () => {
    if (window.confirm("Bist du sicher?")) {
      const clientId = this.root.clientStore.selectedClientId;
      this.edited = true;

      const beneficiaryValues = this.data.beneficiaryValues[clientId];
      if (beneficiaryValues) {
        Object.keys(beneficiaryValues).forEach(bk => {
          if (bk === asset.assetId) {
            delete beneficiaryValues[bk];
          }
        });
        this.data.beneficiaryValues = {};
        this.data.beneficiaryValues[clientId] = beneficiaryValues;
      }
      this.data.assets = this.data.assets.filter(a => a.asset_id !== asset.assetId);
      this.extraAssets = this.extraAssets.filter(a => a.asset_id !== asset.assetId);

      if (!asset.frozen) {
        this.delete.fetch({
          clientId: this.root.clientStore.selectedClientId,
          revisionId: this.REVISION_ID,
          assetId: asset.assetId,
        });
      }

      this._doUpdate(false, true, true, this.data, null, false);
    }
    this.root.updateClients.fetch({
      clientId: this.root.clientStore.selectedClientId,

      user_updated: this.root.user.name + this.root.user.last_name,
      assets_updated_at: Date.now(),
    });
  };

  onAddAsset = async data => {
    await this.root.newAsset.fetch(data);

    const asset = Object.assign({}, toJS(this.root.newAsset.data));

    this.extraAssets.push({ ...asset, notFrozen: true });

    this.root.modal.remove();
    this.root.updateClients.fetch({
      clientId: this.root.clientStore.selectedClientId,

      user_updated: this.root.user.name + this.root.user.last_name,
      assets_updated_at: Date.now(),
    });
  };

  onSaveRevision = value => async () => {
    const clientId = this.root.clientStore.selectedClientId;
    const data = toJS(this.data);
    const extraAssets = toJS(this.extraAssets);
    data.assets = [...data.assets, ...extraAssets];

    await this.save.fetch({
      clientId,
      revisionId: data.revisionVersion,
      revision: data,
      comment: value,
    });

    this.REVISION_ID = null;

    window.localStorage.removeItem("CURRENT_REVISION_ID");
    await this.root.updateClients.fetch({
      clientId: this.root.clientStore.selectedClientId,

      user_updated: this.root.user.name + this.root.user.last_name,
      assets_updated_at: Date.now(),
    });
  };

  getBeneficiaryAmount = (assetId, beneficiaryId) => {
    this.root.updateClients.fetch({
      clientId: this.root.clientStore.selectedClientId,

      user_updated: this.root.user.name + this.root.user.last_name,
      assets_updated_at: Date.now(),
    });
    const clientId = this.root.clientStore.selectedClientId;
    return _.get(this, `beneficiaryAmounts.${clientId}.${assetId}.${beneficiaryId}`);
  };

  onClear = async () => {
    if (this.backup) {
      const promises = [];
      this.extraAssets.forEach(asset => {
        promises.push(
          this.hardDelete.fetch({
            assetId: asset.asset_id,
          }),
        );
      });

      await Promise.all(promises);
      this._doUpdate(false, true, true, this.backup.data, null, false);
      this.extraAssets = [];
      this.REVISION_ID = null;
      window.localStorage.removeItem("CURRENT_REVISION_ID");
      window.location.reload();
    }
    this.root.updateClients.fetch({
      user_updated: this.root.user.name + this.root.user.last_name,
      assets_updated_at: Date.now(),
    });
  };

  get constructed() {
    if (!this.data) {
      return null;
    }
    const header = [
      {
        title: "Vermögensgegenstände",
        large: true,
        scale: 0.4,
      },
      {
        title: "Wert in €",
        scale: 0.08,
      },
      {
        title: "Bestimmung",
        scale: 0.12,
      },
      {
        title: "Status",
        scale: 0.1,
      },
      {
        title: "Erwerber / Empfänger",
        scale: 0.15,
      },
      {
        title: "Veräußerter Wert in €",
        scale: 0.1,
      },
      {
        title: "Verändern",
        scale: 0.05,
      },
    ];

    const sections = [
      {
        title: "Aktiva",
        scale: 0.4,
        blanks: [0.12, 0.1, 0.15],
        value: 0,
        valueSold: 0,
        categories: [],
      },
      {
        title: "Passiva",
        scale: 0.4,
        blanks: [0.12, 0.1, 0.15],
        value: 0,
        valueSold: 0,
        categories: [],
      },
    ];

    this.data.assetCategories.forEach(assetCategory => {
      let index = 0;
      if (assetCategory.asset_category_type === "liability") {
        index = 1;
      }

      const categoryAssets = [...this.data.assets, ...this.extraAssets].filter(
        parsedAsset => parsedAsset.asset_category_id === assetCategory.asset_category_id,
      );

      sections[index].categories.push({
        asset_category_id: assetCategory.asset_category_id,
        title: assetCategory.asset_list_name,
        value: calculateValue(categoryAssets, "asset_value"),
        value_sold: calculateValue(categoryAssets, "asset_value_sold"),
        scales: [0.05, 0.1, 0.25, 0.08, 0.12, 0.1, 0.15, 0.1, 0.05],
        determination: assetCategory.determination,
        determination_placeholder: "Bestimmung wählen",
        determination_options: ["disposal", "donation", "legacy", "sell", "dispute", "administration"],
        status_placeholder: "Status",
        status_options: ["assigned", "open", "sold", "not_sold", "handing_over"],
        status: assetCategory.status,
        beneficiary: assetCategory.beneficiary ? assetCategory.beneficiary.split(",") : [],
        beneficiary_placeholder: "Begünstigter",
        beneficiary_options: (this.root.personsListing.data || []).filter(b => b.is_beneficiary).map(beneficiary => beneficiary.person_id),
        rows: categoryAssets
          .filter(
            asset => !asset.deleted && ((asset.resolution !== "sell" && asset.resolution !== "administration") || asset.status === "open"),
          )
          .map(asset => {
            const beneficiaryOptions = this.root.personsListing.data || [];
            return {
              assetId: asset.asset_id,
              title: asset.asset_name,
              value: this.changedValues[`${asset.asset_id}_asset_value`] || asset.asset_value,
              status_placeholder: "Status",
              status_options: ["assigned", "open", "sold", "not_sold", "handing_over"],
              status: asset.status,
              beneficiary: asset.beneficiary ? asset.beneficiary.split(",") : [],
              beneficiary_placeholder: "Begünstigter",
              beneficiary_options: beneficiaryOptions.filter(b => b.is_beneficiary).map(beneficiary => beneficiary.person_id),
              determination: asset.resolution,
              determination_placeholder: "Bestimmung wählen",
              determination_options: ["disposal", "donation", "legacy", "sell", "dispute", "administration"],
              valueSold: this.changedValues[`${asset.asset_id}_asset_value_sold`] || asset.asset_value_sold || 0,
              frozen: !asset.notFrozen,
              fields: {
                inputs: (asset.fields || []).map(field => ({
                  id: field.field_id,
                  name: field.field_id,
                  placeholder: field.title,
                  value: this.changedFields[field.field_id] || field.value || "",
                })),
                files: (asset.files || []).map(file => {
                  const n = file.file_url.split("/");
                  const title = n.length > 0 ? n[n.length - 1] : "File";
                  return {
                    ...file,
                    title,
                    src: `${config.endpoints.api}/${file.file_url}`,
                  };
                }),
              },
            };
          }),
      });
    });

    sections.forEach((s, si) => {
      let sValue = 0;
      let sValueSold = 0;
      s.categories.forEach((c, ci) => {
        let value = 0;
        let value_sold = 0;
        c.rows.forEach((r, ri) => {
          value += Number(r.value);
          value_sold += Number(r.valueSold);
        });

        sections[si].categories[ci].value = value;
        sections[si].categories[ci].value_sold = value_sold;

        sValue += Number(value);
        sValueSold += Number(value_sold);
      });

      sections[si].value = Number(sValue);
      sections[si].valueSold = Number(sValueSold);
    });

    return {
      header,
      sections,
    };
  }
}
export default decorate(AssetEditStore, {
  extraAssets: observable,
  edited: observable,
  changedFields: observable,
  REVISION_ID: observable,
  changedValues: observable,
  beneficiaryAmounts: observable,
  constructed: computed,
  onChangeInputValue: action,
  onChangeFieldValue: action,
  onDeleteAsset: action,
});
