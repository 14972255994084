import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";

import { P, Layout, Icon } from "../styled";
import UserProfileMandantenNumber from "../components/UserProfileCard/UserProfileMandatenNumber";
import ProgressSlider from "../components/ProgressSlider";
import DecliningList from "../components/AssetList/DecliningList";
import { LineBreak } from "../styled/sidebar";
import Userphoto from "../assets/images/empty.png";
import config from "../lib/config";

import ReactResizeDetector from "react-resize-detector";

class DecliningDirAssets extends Component {
  constructor(props) {
    super(props);

    const {
      store: { sidebarOpen },
      // client
    } = props;

    this._container = React.createRef();
    const isWindows = true; // config.isWindows;

    this.width = sidebarOpen ? window.innerWidth - (468 + (isWindows ? 19 : 2)) : window.innerWidth - (240 + (isWindows ? 19 : 2));
  }
  componentDidMount() {
    const {
      store: { client, clientStore },
      // client
    } = this.props;

    client.fetch({
      clientId: clientStore.selectedClientId,
    });
  }

  updateSizes = () => {
    if (this._container) {
      this.width = this._container.current.offsetWidth;

      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => this.forceUpdate(), 30);
    }
  };
  render() {
    const {
      store: { client, declining },
    } = this.props;

    const imageSource =
      client.data.profile_image_url === "uploads/empty.png" ? Userphoto : `${config.endpoints.api}/${client.data.profile_image_url}`;
    return (
      <React.Fragment>
        <Layout overflowHidden ref={this._container}>
          <ReactResizeDetector handleWidth handleHeight onResize={this.updateSizes} />
          <Layout
            boxShadow="type_1"
            displayFlex
            row
            alignCenter
            ustifyStart
            spacingLeft={55}
            spacingTop={40}
            spacingBottom={60}
            zIndex={100}
            relative
            background="white"
            mediaSidePadding={600}
          >
            {client.data && (
              <UserProfileMandantenNumber
                firstName={client.data.first_name}
                imageSource={imageSource}
                title={client.data.title || ""}
                lastName={client.data.last_name}
                mandantenNumber={client.data.client_number}
                birthday={client.data.birth_date}
                showDate={declining.data}
                updatedAt={declining.data ? declining.data.lastUpdate : null}
              />
            )}
          </Layout>
          <Layout displayFlex alignCenter justifyCenter style={{ borderBottom: "1px solid #e6e6e6" }}>
            <P type="type-5" spacingTop={40} spacingLeft={20} spacingRight={20} spacingBottom={20} italic>
              {`Das Nachlassverzeichnis beinhaltet den Aktiv- und Passivnachlass des Erblassers zum Zeitpunkt der Amtsannahme. Gegebenenfalls sind gesonderte Aufstellungen hinzuzufügen.`}
            </P>
          </Layout>

          {client.loaded && <DecliningList customWidth={this.width} client={client.data} />}
          {(!declining.data || declining.data.assets.length === 0) && (
            <React.Fragment>
              <Layout spacingTop={60} spacingBottom={24} displayFlex alignCenter justifyCenter>
                <P fontWeight="bold">Vermögensgegenstände</P>
              </Layout>
              <LineBreak />
            </React.Fragment>
          )}
        </Layout>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(DecliningDirAssets);
