import styled, { keyframes, css } from "styled-components";
import { P_1, P_1_1, P_2, P_3, P_4, P_4_1, P_5, P_3_1, P_6, P_7, P_8, P_9, COLORS } from "./constants";
import { BOX_SHADOW } from "./constants";
import LoadingImg from "../assets/images/loading.svg";

export const H = styled.p`
  font-family: Sansation;
  font-size: 29px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.93;
  letter-spacing: 0.3px;
  text-align: left;
  color: ${COLORS.PINE};
`;
export const P = styled.p`

  ${props => {
    switch (props.type) {
    case "type-2":
      return P_2;
    case "type-3":
      return P_3;
    case "type-3_1":
      return P_3_1;
    case "type-4":
      return P_4;
    case "type-4_1":
      return P_4_1;
    case "type-5":
      return P_5;
    case "type-6":
      return P_6;
    case "type-7":
      return P_7;
    case "type-8":
      return P_8;
    case "type-9":
      return P_9;
    case "type-10":
      return P_1_1;
    default:
      return P_1;
    }
  }}
  ${props => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${props => props.italic && `font-style: italic;`}
  ${props => props.textAlign && `text-align: ${props.textAlign};`}
  ${props => props.color && `color: ${props.color};`}
  // Spacing
  ${props => (props.spacingTop ? `padding-top: ${props.spacingTop}${props.unit || "px"};` : "")};
  ${props => (props.spacingBottom ? `padding-bottom: ${props.spacingBottom}${props.unit || "px"};` : "")};
  ${props => (props.spacingLeft ? `padding-left: ${props.spacingLeft}${props.unit || "px"};` : "")};
  ${props => (props.spacingRight ? `padding-right: ${props.spacingRight}${props.unit || "px"};` : "")};

  // Margin
  ${props => (props.offsetTop ? `margin-top: ${props.offsetTop}${props.unit || "px"};` : "")};
  ${props => (props.offsetBottom ? `margin-bottom: ${props.offsetBottom}${props.unit || "px"};` : "")};
  ${props => (props.offsetLeft ? `margin-left: ${props.offsetLeft}${props.unit || "px"};` : "")};
  ${props => (props.offsetRight ? `margin-right: ${props.offsetRight}${props.unit || "px"};` : "")};
  ${props => (props.marginAuto ? "margin: auto;" : "")};
`;

export const TitleH = styled.h1`
  font-size: 2.25rem;
  color: ${COLORS.PINE_THREE};
  font-weight: 300;
  font-family: Sansation;
  margin: 10px 0;
  display: inline-block;
  width: 100%;
  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
`;
export const LineBreak = styled.hr`
  width: 100%;
  margin: 10px 0;
  border: 1px solid ${COLORS.LIGHT_GREY_FOUR};
`;
// Layout

export const Layout = styled.div`

${props =>
    props.rowToColumn
      ? `
    @media (max-width: ${props.rowToColumn}px){
      flex-direction: column;
    }
  `
      : ""}

  // Flex
  ${props => (props.flex ? `flex: ${props.flex};` : "")}
  ${props => (props.displayFlex ? "display: flex;" : "")}
  //Display
  ${props => (props.inlineBlock ? "display: inline-block;" : "")}
  // Directions
  ${props => (props.row ? "flex-direction: row;" : "")}
  ${props => (props.rowReverse ? "flex-direction: row-reverse;" : "")}
  ${props => (props.column ? "flex-direction: column;" : "")}
  ${props => (props.columnReverse ? "flex-direction: column-reverse;" : "")}
  //Justify Items
  ${props => (props.justifyStart ? "justify-content: flex-start;" : "")}
  ${props => (props.justifyEnd ? "justify-content: flex-end;" : "")}
  ${props => (props.justifyCenter ? "justify-content: center;" : "")}
  ${props => (props.justifyAround ? "justify-content: space-around;" : "")}
  ${props => (props.justifyBetween ? "justify-content: space-between;" : "")}
  //Align Items
  ${props => (props.alignStart ? "align-items: flex-start;" : "")}
  ${props => (props.alignEnd ? "align-items: flex-end;" : "")}
  ${props => (props.alignCenter ? "align-items: center;" : "")}
  // Wrap Elements
  ${props => (props.flexWrap ? "flex-wrap: wrap;" : "")}
  ${props => (props.wrapReverse ? "flex-wrap: wrap-reverse;" : "")}
  // Spacing
  ${props => (props.spacingTop ? `padding-top: ${props.spacingTop}${props.unit || "px"};` : "")};
  ${props => (props.spacingBottom ? `padding-bottom: ${props.spacingBottom}${props.unit || "px"};` : "")};
  ${props => (props.spacingLeft ? `padding-left: ${props.spacingLeft}${props.unit || "px"};` : "")};
  ${props => (props.spacingRight ? `padding-right: ${props.spacingRight}${props.unit || "px"};` : "")};
      ${props => (props.spacing ? `padding: ${props.spacing}${props.unit || "px"};` : "")};
  // Margin
  ${props => (props.offsetTop ? `margin-top: ${props.offsetTop}${props.unit || "px"};` : "")};
  ${props => (props.offsetBottom ? `margin-bottom: ${props.offsetBottom}${props.unit || "px"};` : "")};
  ${props => (props.offsetLeft ? `margin-left: ${props.offsetLeft}${props.unit || "px"};` : "")};
  ${props => (props.offsetRight ? `margin-right: ${props.offsetRight}${props.unit || "px"};` : "")};
  ${props => (props.marginAuto ? "margin: auto;" : "")};

  // Dimenstions
  ${props => (props.fullWidth ? "width: 100%;" : "")};
  ${props => (props.minWidth ? `min-width: ${props.minWidth};` : "")};
  ${props => (props.width ? `width: ${props.width};` : "")};
  ${props => (props.maxWidth ? `max-width: ${props.maxWidth}${props.unit || "px"};` : "")};
  ${props => (props.height ? `height: ${props.height}${props.unit || "px"};` : "")}
  
  // Flex Basis
 ${props => (props.flexBasis ? `flex-basis: ${props.flexBasis}${props.unit || "px"};` : "")};
      
  // overflow
  ${props => (props.overflowHidden ? `overflow: hidden;` : "")};
      
  // position
  ${props => (props.relative ? `position: relative;` : "")};

  // z-index
  ${props => (props.zIndex ? `z-index: ${props.zIndex};` : "")};
  // background
  ${props => (props.background ? `background: ${props.background};` : "")};
  
  ${props => {
    switch (props.boxShadow) {
    case "border_only":
      return `
        border-bottom: solid 1px ${COLORS.LIGHT_GREY_TWO};
      `;
    case "type_1":
      return `
        border-bottom: solid 1px ${COLORS.LIGHT_GREY_TWO};
        box-shadow: ${BOX_SHADOW.BS_1};
      `;
    default:
      return "";
    }
  }}

  ${props => (props.opacity ? `opacity: ${props.opacity};` : "")}
  ${props =>
    props.rowToColumn
      ? `
    @media (max-width: ${props.rowToColumn}px){
      flex-direction: column;
    }
  `
      : ""}
  ${props =>
    props.centerToStart
      ? `
      @media (max-width: ${props.centerToStart}px){
        align-items: flex-start;
      }
    `
      : ""}
  ${props => props.scroll && ` overflow-x: scroll; z-index: 0; `}
  ${props => props.yScroll && ` overflow-y: auto; overflow-x: hidden;`}
  ${props =>
    props.mediaPadding
      ? `
    @media (max-width: ${props.mediaPadding}px){
      padding: 0px 10px;
    }
  `
      : ""}
  ${props =>
    props.mediaNoPadding
      ? `
    @media (max-width: ${props.mediaNoPadding}px){
      padding: 0px;
    }`
      : ""}
  ${props =>
    props.mediaSidePadding
      ? `
    @media (max-width: ${props.mediaSidePadding}px){
      padding-left: 10px;
      padding-right: 10px;
    }`
      : ""}
  ${props =>
    props.mediaPaddingMedium
      ? `
    @media (max-width: ${props.mediaPaddingMedium}px){
      padding: 0px 20px;
    }`
      : ""}
  ${props =>
    props.mediaPaddingLarge
      ? `
    @media (max-width: ${props.mediaPaddingLarge}px){
      padding: 0px 40px 0px 0px;
    }`
      : ""}
  ${props =>
    props.mediaSidePadding
      ? `
    @media (max-width: ${props.mediaSidePadding}px){
      padding-left: 10px;
      padding-right: 10px;
    }`
      : ""}  
  ${props =>
    props.mediaWidth
      ? `
    @media (max-width: ${props.mediaWidth}px){
      min-width: 0;
    }`
      : ""}
  ${props => (props.borderRight ? `border-right: ${props.borderRight}` : ``)}
  ${props => (props.selfAlignCenter ? "align-self: center;" : "")}
  ${props =>
    props.mediaCenter
      ? `
    @media (max-width: ${props.mediaCenter}px){
      align-items: center;
    }`
      : ""}
  ${props =>
    props.mediaFullWidth
      ? `
    @media only screen and (max-width: ${props.mediaFullWidth}px){
      width: 100%;
      max-width: none!important;
      min-width: auto;
    }`
      : ``}
  ${props =>
    props.mediaJustifyCenter
      ? `
    @media (max-width: ${props.mediaJustifyCenter}px){
      justify-content: center;
    }`
      : ""}      
  ${props =>
    props.mediaNoMargin
      ? `
    @media (max-width: ${props.mediaNoMargin}px){
      margin: 0px;
    }`
      : ""}  
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Icon = styled.i.attrs(props => ({
  className: props.type || "material-icons-outlined ",
}))`
  color: ${props => props.color || COLORS.TURTLE_GREEN_TWO};
  ${props => props.size && `font-size: ${props.size}px;`}
  ${props => props.isDisabled && `pointer-events: none;`}
  ${props =>
    props.onClick &&
    `
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
      opacity: 0.7;
    }
  `}
  ${props =>
    props.selected &&
    `
    color: ${COLORS.PINE}!important;
  `}
  ${props =>
    props.spinning &&
    css`
      animation: ${rotate} 2s linear infinite;
    `}
  ${props => (props.absolute ? `position: absolute;` : ``)}
   ${props => (props.floatRight ? `float: right;` : ``)}
  ${props => (props.margin ? `margin: ${props.margin}` : ``)}
  ${props => (props.pointer ? `cursor: pointer;` : ``)}
`;
export const LoadingDIv = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  vertical-align: middle;
  align-items: center;
  text-indent: -9999999999px;
  background: url(${LoadingImg}) no-repeat center center;
  padding: 30px;
`;
export const FormOuter = styled.div`
  > div {
    position: relative;
    label {
      line-height: 19px;
    }
  }
`;
export const Downloading = styled.div`
  text-align: center;
  display: flex;
  flex-flow: column;
  margin-bottom: 10px;
  min-width: 200px;
  position: relative;
  .fakeClose {
    position: absolute;
    right: 3px;
    top: -27px;
    background: #fff;
    width: 20px;
    height: 20px;
  }
  span {
    margin: 5px 0;
    color: ${COLORS.TURTLE_GREEN_TWO};
    font-size: 0.85rem;
    text-align: center;
  }
`;

export const Label = styled.label`
  @media only screen and (max-width: 600px) {
    font-size: 0.75rem;
  }
  ${props => (props.spacingTop ? `padding-top: ${props.spacingTop}${props.unit || "px"};` : "")};
  ${props => (props.spacingBottom ? `padding-bottom: ${props.spacingBottom}${props.unit || "px"};` : "")};
  ${props => (props.spacingLeft ? `padding-left: ${props.spacingLeft}${props.unit || "px"};` : "")};
  ${props => (props.spacingRight ? `padding-right: ${props.spacingRight}${props.unit || "px"};` : "")};
  ${props => (props.fontSize ? `font-size: ${props.fontSize}px;` : "")};
`;
export const LayoutIcon = styled.div`
    display: flex;
    width:100%;
    flex-direction: row;
    justify-content: flex-end
    position: absolute; 
    margin-top: 30%;
    padding-right: 10%; 
    cursor: pointer; 
    z-index: 9999;

    @media only screen and (max-width: 1200px) {
      margin-top: 170px;
      padding-left: 140px;
    
    }
                
 `;
