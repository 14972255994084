import styled from "styled-components";
import { COLORS, BORDER_RADIUS, LINEHEIGHT, FONTWEIGHT } from "../constants";

export const MandantenshboardOuter = styled.div`
  width: 100%;
  background: #fff;
`;
export const UserInfo = styled.div`
  padding: 45px 66px 30px 53px;
  width: 100%;
  @media screen and (max-width: 800px) {
    padding-right: 10px;
    padding-left: 30px;
  }
  p {
    margin-bottom: 50px;
    @media screen and (max-width: 1030px) {
      margin-bottom: 20px;
    }
  }
  > div {
    @media screen and (max-width: 1030px) {
      display: flex;
      flex-flow: column;
    }
  }
`;
export const UserPicture = styled.div`
  width: 95px;
  height: 95px;
  border-radius: ${BORDER_RADIUS.BR_6};
  @media screen and (max-width: 1030px) {
    margin-bottom: 20px;
  }
`;
export const InfoRight = styled.div`
  margin-left: 67px;
  width: 90%;
  @media screen and (max-width: 1030px) {
    margin-left: 0;
  }
  p {
    margin-bottom: 35px;
  }
  > div {
    > div {
      display: flex;
      flex-basis: 31%;
      margin-bottom: 30px;
      margin-right: 3%;
      @media screen and (max-width: 1030px) {
        flex-basis: 50%;
        margin-right: 0;
      }
      @media screen and (max-width: 500px) {
        flex-basis: 100%;
      }
      @media screen and (max-width: 1000px) {
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      p {
        margin-bottom: 0;
      }
      i {
        width: 35px;
        height: 35px;
        background: ${COLORS.SILVER};
        border-radius: ${BORDER_RADIUS.BR_6};
        text-align: center;
        line-height: ${LINEHEIGHT.LH_2};
        color: ${COLORS.NASTY_GREEN};
        font-size: 1rem;
        margin-right: 18px;
        min-width: 35px;
      }
    }
  }
`;
export const StatusBox = styled.div`
  width: 100%;
  padding: 45px 66px 30px 53px;
  @media screen and (max-width: 800px) {
    padding-right: 40px;
    padding-left: 30px;
  }
  > p {
    padding-left: 49px;
    @media screen and (max-width: 800px) {
      padding-left: 0;
      text-align: center;
    }
  }
  > div {
    @media screen and (max-width: 900px) {
      flex-flow: column;
    }
    > div {
      flex-basis: 40%;
      @media screen and (max-width: 900px) {
        flex-basis: 100%;
        margin-bottom: 30px;
      }
      > div {
        width: 80%;
        @media screen and (max-width: 900px) {
          width: 93%;
        }
      }
    }
    i {
      margin-right: 25px;
      color: ${COLORS.NASTY_GREEN};
    }
    p {
      margin-bottom: 17px;
    }
  }
  button {
    height: 43px;
    padding: 0px 15px;
    font-size: 0.9rem;
    font-weight: ${FONTWEIGHT.FW_6};
    border-radius: ${BORDER_RADIUS.BR_1};
    box-shadow: none;
    border: none;
    max-width: 270px;
    align-self: end;
    letter-spacing: 0;
    line-height: 40px;
    cursor: pointer;
    @media screen and (max-width: 900px) {
      width: 100%;
      max-width: 93%;
      margin-left: 40px;
      align-self: center;
    }
    i {
      color: #fff;
      margin-right: 13px;
      position: relative;
      top: 7px;
    }
    &:hover {
      background: ${COLORS.NASTY_GREEN};
    }
  }
`;
export const SelectStyle = styled.select`
  width: 100%;
  border: 2px solid ${COLORS.WHITE};
  height: 43px;
  padding: 10px 18px;
  color: ${COLORS.MEDIUM_GREY_TWO};
  font-size: 0.9rem;
`;
// display: unset;
export const TasksBoxOuter = styled.div`
  @media only screen and (max-width: 768px) {
    padding: 15px;
  }
  width: 100%;
  padding: 45px 66px 30px 53px;
  background: ${COLORS.OFF_WHITE};
  margin-top: -10px;
  p {
    margin-bottom: 30px;
    i {
      margin-right: 19px;
      color: ${COLORS.TURTLE_GREEN};
      position: relative;
      top: 4px;
    }
  }
  > div {
    @media screen and (max-width: 900px) {
      display: flex;
      flex-flow: column;
    }
  }
`;

const CALENDAR_STYLE = `

  border: none;

  .react-calendar__month-view__weekdays {
    border-bottom: 1px solid ${COLORS.MILITARY_GREEN};
    &__weekday {
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.08;
      letter-spacing: 0.1px;
      text-align: center;
      color: rgb(177, 177, 177);
    }
  }
  .react-calendar__navigation__prev-button {
    order: 2;
  }
  .react-calendar__navigation__next-button {
    order: 3;
  }
  .react-calendar__navigation__label {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: 0.1px;
    text-align: left;
    padding-left: 10px;
  }
  .react-calendar__month-view__days__day {
    border-radius: 50%;
    padding: 0;
    position: relative;
    * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.08;
      letter-spacing: 0.1px;
      text-align: center;
      color: ${COLORS.GREYISH_BROWN_TWO};
    }
    &:after {
      content: '';
      padding-top: 100%;
      display: block;
    }
  }
  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    background: ${COLORS.TURTLE_GREEN};
    &:focus {
      background: ${COLORS.TURTLE_GREEN};
    }
    &:hover {
      background: ${COLORS.TURTLE_GREEN};
    }
    * {
        font-size: 13px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.08;
        letter-spacing: 0.1px;
        text-align: center;
        color: rgb(255, 255, 255);
  
    }
  }
`;
export const DatePickerOuter = styled.div`
  width: 25%;
  box-shadow: 0px 1px 18px 0 rgba(82, 100, 58, 0.11);
  background: #fff;
  max-width: 307px;
  @media screen and (max-width: 900px) {
    // width: 65%;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    justify-content: center;
    display: flex;
  }

  .custom-calendar {
    ${CALENDAR_STYLE}
  }
`;
export const NextAppointmentOuter = styled.div`
  width: 40%;
  box-shadow: 0px 1px 18px 0 rgba(82, 100, 58, 0.11);
  // max-width: 487px;
  margin-left: 35px;
  background: #fff;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  position: relative;
  .loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  > div {
    > div {
      > div {
        &:nth-child(2n + 1) {
          background: ${COLORS.LIGHT_GREY_NINE};
          div {
            background: ${COLORS.LIGHT_GREY_NINE};
          }
        }
        &:nth-child(2n) {
          background: #fff;
          div {
            background: #fff;
          }
        }
        &:first-child {
          height: 100%;
          border: 1px solid ${COLORS.LIGHT_GREY_TEN};
          background: ${COLORS.LIGHT_GREY_NINE};
          color: ${COLORS.GREYISH_BROWN_TWO};
          border-radius: 5px 5px 0 0;
          div {
            background: ${COLORS.LIGHT_GREY_NINE};
          }
          > div {
            border: none;
            border-radius: 5px 0 0 0;
            &:last-child {
              border-radius: 0 5px 0 0;
            }
          }
        }
        > div {
          border: none;
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }

  table {
    width: 100%;
    border: none;
    outline: none;
    thead {
      background: ${COLORS.LIGHT_GREY_NINE};
      border: solid 1px ${COLORS.LIGHT_GREY_TEN};
      font-size: 0.9rem;
      font-weight: ${FONTWEIGHT.FW_6};
      letter-spacing: 0.2px;
      text-align: left;
      color: ${COLORS.GREYISH_BROWN_TWO};
      th {
        padding: 24px 21px 0 21px;
        height: 56px;
        i {
          display: inlne-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 4.5px 0 4.5px;
          position: relative;
          top: 12px;
          margin-left: 7px;
          border-color: ${COLORS.GREYISH_BROWN_TWO} transparent transparent transparent;
        }
      }
    }
    tbody {
      tr {
        padding: 0;
        border: 1px solid ${COLORS.LIGHT_GREY_ELEVEN};
        td {
          padding: 17px 21px;
          color: ${COLORS.GREYISH_BROWN_TWO};
          font-size: 0.9rem;
        }
        &:nth-child(2n + 1) {
          background: #fff;
        }
        &:nth-child(2n) {
          background: ${COLORS.LIGHT_GREY_NINE};
        }
      }
    }
  }
`;
export const NotizenOuter = styled.div`
  width: 35%;
  background: ${COLORS.OFF_WHITE};
  // max-width: 401px;
  padding: 20px;
  margin-left: 35px;
  border: 1px solid ${COLORS.WHITE_TWO};
  box-shadow: inset -2.4px -1.8px 16.6px 1.4px ${COLORS.BLACK_TWELVE};
  height: 337px;
  position: relative;
  .editNotizen {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: 34px;
    top: 22px;
    cursor: pointer;
    color: ${COLORS.TURTLE_GREEN};
  }
  &:hover {
    .editNotizen {
      opacity: 1;
      visibility: visible;
    }
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
  > p {
    color: ${COLORS.DARK_SAGE_THREE};
    margin-bottom: 27px;
    & + p {
      color: ${COLORS.DARK_SAGE_FOUR};
      margin-bottom: 30px;
      font-weight: ${FONTWEIGHT.FW_3};
      line-height: 22px;
      & + p {
        font-style: italic;
        margin-bottom: 0;
        color: ${COLORS.MEDIUM_GREY_TWO};
      }
    }
  }
`;
export const NewNotizenOuter = styled.div`
  width: 35%;
  background: ${COLORS.OFF_WHITE};
  max-width: 401px;
  padding: 20px;
  margin-left: 35px;
  border: 1px solid ${COLORS.WHITE_TWO};
  box-shadow: inset -2.4px -1.8px 16.6px 1.4px ${COLORS.BLACK_TWELVE};
  height: 337px;
  cursor: pointer;
  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
  > div {
    text-align: center;
    > p {
      text-align: center;
    }
  }
`;

export const ButtonActionsOuter = styled.div`
  width: 100%;
  padding: 80px 0 0 0;
  @media screen and (max-width: 600px) {
    padding: 20px 0 0 0;
  }
  button {
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  > div {
    @media screen and (max-width: 600px) {
      flex-flow: column;
    }
  }
`;
export const InputFieldOuter = styled.div`
  width: 67%;
  text-align: left;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 15px;
  }
  input {
    box-shadow: inset -0.8px -0.6px 10px 0 rgba(72, 84, 56, 0.21);
    height: 43px;
    background: ${COLORS.LIGHT_GREY};
    border: 1px solid ${COLORS.LIGHT_GREY_EIGHT};
    font-size: 0.9rem;
    color: ${COLORS.DARK_SAGE_FIVE};
    max-width: 351px;
    @media screen and (max-width: 900px) {
      max-width: 80%;
    }
    @media screen and (max-width: 600px) {
      max-width: 100%;
    }
  }
`;
export const NewItemBlock = styled.div`
  width: 100%;
  text-align: center;
  color: ${COLORS.TURTLE_GREEN};
  margin: 35px 0 0 0;
  a {
    color: ${COLORS.TURTLE_GREEN};
  }
  i {
    font-size: 35px;
    cursor: pointer;
  }
`;
export const NewAppointmentOuter = styled.div`
  width: 100%;
  box-shadow: 0px 1px 18px 0 rgba(82, 100, 58, 0.11);
  // max-width: 487px;
  margin-left: 0;
  background: #fff;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
  table {
    width: 100%;
    border: none;
    outline: none;
    thead {
      background: ${COLORS.LIGHT_GREY_NINE};
      border: solid 1px ${COLORS.LIGHT_GREY_TEN};
      font-size: 0.9rem;
      font-weight: ${FONTWEIGHT.FW_6};
      letter-spacing: 0.2px;
      text-align: left;
      color: ${COLORS.GREYISH_BROWN_TWO};
      th {
        padding: 24px 21px 0 21px;
        height: 56px;
        i {
          display: inlne-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 4.5px 0 4.5px;
          position: relative;
          top: 12px;
          margin-left: 7px;
          border-color: ${COLORS.GREYISH_BROWN_TWO} transparent transparent transparent;
        }
      }
    }
  }
  > div {
    padding: 30px 40px;
    img {
      min-width: 123px;
    }
    p {
      text-align: center;
      margin-bottom: 15px;
      margin-top: 50px;
    }
  }
  & + .newItemIcon {
    display: none;
  }
`;
