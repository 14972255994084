import React from "react";
import { Route, Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from "mobx-react-router";

import Login from "../routes/Login";
import Mandates from "../routes/Mandantendashboard";
import Wrapper from "../hoc/Wrapper";
// import UserInformations from "../routes/UserInformations";
import Activities from "../routes/Activities";
import Dokumente from "./Dokumente";
import Assets from "./Assets";
import Clients from "./Clients";
import Vorlagen from "./Vorlagen";
import Administration from "./Administration";
import { ResetGlobal } from "../styled/reset";
import Modal from "../components/Modal";
import QuestionCatalog from "./QuestionCatalog";
import Reports from "./Reports";
import MyOverview from "./MyOverview";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";
import Signup from "./SignUp";
import MandantList from "./MandantenList";
import MyInformation from "./MyInformation";
import Notification from "../components/Notification";
import Announcment from "./Announcement";
import Activate from "./Activate";

// import UserDashboard from "./MyOverview";
// import ActionModal from "./ActionModal";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";
import DecliningDirectory from "./DecliningDirectory";

export const routes = {
  ROOT: "/",
  // USER: "/user-information",
  DASHBOARD: "/dashboard",
  HOME: "/home",
  LOGIN: "/login",
  CLIENTS: "/clients",
  MANDATES_DASHBOARD: "/mandanten",
  ACTIVITIES: "/activities",
  DOKUMENTE: "/dokumente",
  VORLAGEN: "/vorlagen",
  ADMINISTRATION: "/administration",
  ASSETS: "/assets",
  DECLINING_DIRECTORY: "/nachlassverzeichnis",
  REPORTS: "/reports",
  MODAL: "/modal",
  QUESTIONCATALOG: "/questioncatalog",
  MYOVERVIEW: "/my-overview",
  // USERDETAIL:"/user-information",
  RESETPASSWORD: "/reset-password",
  FORGOTPASSWORD: "/forgot-password",
  SIGNUP: "/signup",
  MANDANT: "/mandant",
  MYINFORMATION: "/my-information",
  ANNOUNCMENT: "/announcment",
  ACTIVATE: "/activate",
};

export const browserHistory = createBrowserHistory();

const AUTHENTICATE_OPTIONS = {
  AUTHENTICATED: "AUTHENTICATED",
  NOT_AUTHENTICATED: "NOT_AUTHENTICATED",
  NULL: null,
};

class AppRouter extends React.Component {
  containerRef = React.createRef();
  containerObserver = new MutationObserver((...args) => this.onContainerMutateCallback(...args));
  interval = null;
  constructor(props) {
    super(props);
    this.history = syncHistoryWithStore(browserHistory, props.store.router);
    this.state = {
      authenticate: AUTHENTICATE_OPTIONS.NULL,
    };
  }

  componentDidMount() {
    this.initActions();
    window.addEventListener("click", this.handleWindowClick);
    this.initStuff();
  }

  componentWillUnmount() {
    this.containerObserver.disconnect();
    window.removeEventListener("click", this.handleWindowClick);
  }

  initStuff = () => {
    this.interval = setInterval(() => {
      if (this.containerRef.current) {
        this.containerObserver.observe(this.containerRef.current, { attributes: true, childList: true, subtree: true });
        window.clearInterval(this.interval);
      }
    }, 10);
  };

  handleWindowClick = () => {
    this.props.store.getRevisionFiles.testInactivity();
  };

  onContainerMutateCallback = (mutationList, observer) => {
    this.props.store.getRevisionFiles.testInactivity();
  };

  initActions = async () => {
    const { store } = this.props;
    try {
      await store.getUserFromToken.fetch();
      const currentUser = store.getUserFromToken.data;
      store.user.setCurrentUser(currentUser);
      store.clientStore.setSelectedClientId(currentUser.selected_client);
      if (currentUser.selected_client) {
        try {
          const selectedClient = await store.client.fetch({
            clientId: currentUser.selected_client,
          });
          store.clientStore.setSelectedClientId(selectedClient.data.data.client_id);
        } catch (e) {
          store.clientStore.setSelectesdClientId(null);
        }
      }
      this.setState({
        authenticate: AUTHENTICATE_OPTIONS.AUTHENTICATED,
      });
    } catch (e) {
      this.setState({
        authenticate: AUTHENTICATE_OPTIONS.NOT_AUTHENTICATED,
      });
    }
  };

  render() {
    const { authenticate } = this.state;
    if (this.props.store.getUserFromToken.loading || authenticate === AUTHENTICATE_OPTIONS.NULL) {
      return null;
    }

    return (
      <>
        <ResetGlobal />
        <Modal />
        <Notification />
        <div ref={this.containerRef}>
          <Router history={this.history}>
            {authenticate === AUTHENTICATE_OPTIONS.NOT_AUTHENTICATED && (
              <Switch>
                <Route path={routes.ROOT} exact component={Login} />
                <Route path={routes.LOGIN} exact component={Login} />
                <Route path={routes.RESETPASSWORD} exact component={ResetPassword} />
                <Route path={routes.FORGOTPASSWORD} exact component={ForgotPassword} />
                <Route path={routes.SIGNUP} exact component={Signup} />
                <Route path={routes.ANNOUNCMENT} exact component={Announcment} />
                <Route path={routes.ACTIVATE} exact component={Activate} />
                <Route render={() => <Redirect to={routes.LOGIN} />} />
              </Switch>
            )}
            {authenticate === AUTHENTICATE_OPTIONS.AUTHENTICATED && (
              <Wrapper>
                {/* <Route path={routes.USER} exact component={UserInformations} /> */}
                <Switch>
                  <Route path={routes.ROOT} exact component={MyOverview} />
                  <Route path={routes.MANDATES_DASHBOARD} exact component={Mandates} />
                  <Route path={routes.DASHBOARD} exact component={Wrapper} />
                  <Route path={routes.CLIENTS} exact component={Clients} />
                  <Route path={routes.ASSETS} exact component={Assets} />
                  <Route path={routes.DECLINING_DIRECTORY} exact component={DecliningDirectory} />
                  <Route path={routes.ACTIVITIES} exact component={Activities} />
                  <Route path={routes.DOKUMENTE} exact component={Dokumente} />
                  <Route path={routes.VORLAGEN} exact component={Vorlagen} />
                  <Route path={routes.ADMINISTRATION} exact component={Administration} />
                  <Route path={routes.REPORTS} exact component={Reports} />
                  <Route path={routes.QUESTIONCATALOG} exact component={QuestionCatalog} />
                  <Route path={routes.MYOVERVIEW} exact component={MyOverview} />
                  {/* <Route path={routes.USERDETAIL} exact component={UserDetails}/> */}
                  <Route path={routes.MANDANT} exact component={MandantList} />
                  <Route path={routes.MYINFORMATION} exact component={MyInformation} />
                  <Route render={() => <Redirect to={routes.MYOVERVIEW} />} />
                </Switch>
              </Wrapper>
            )}
          </Router>
        </div>
      </>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(AppRouter);
