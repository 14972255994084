import React from "react";
import InputMask from "react-input-mask";
import { Icon, Layout, P } from "../../styled";
import { PageColumn, PageFooter } from "../../styled/layout";
import { Speichem, Abbrechen } from "../../styled/buttons";
import { TextInput } from "../../styled/form";
import FormInput from "../Form/Input";
import Subheading from "../Layout/Subheading";
import Timer from "../Timer";
import CheckBox from "../Form/CheckBox";
import ActivityEditor from "../ActivityEditor";
import Table from "../Table";
import Select from "../Form/Select";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import CreateNewBeneficiary from "../Modal/CreateNewBeneficiary";
import NewsearchInput from "../NewFilterSearch";
import ResponseWarning from "../Response";
import debounce from "lodash/debounce";
import moment from "moment";
import { replaceStringWithNumber, handleChangeMoneyInput, getMoneyNumber, formatMoneyToString } from "../../utils/helper";
import * as Yup from "yup";
import config from "../../lib/config";
import Axios from "axios";

const INITIAL_STATE = {
  heading: "",
  billable: false,
  relevanceMonthly: false,
  relevanceQuarterly: false,
  hourlyRate: "",
  linkedPerson: "",
  timer: null,

  public_note: null,
  internal_note: null,
  billing_info: null,
  report_info: null,
  activityActionFlag: false,
  isEditable: true,
  user_timestamp: null,
  isUpdate: false,
  isHCalculated: false,
  uploading: false,

  selectItems: ["select-0"],
  linkedPersons: [],
  personsListingData: [],
};

export const TIMER_ID = "timer_review";
class Review extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };

    this.onSearchChange = debounce(this.onSearchChange, 150);
  }
  componentDidMount() {
    const {
      store: {
        assets,
        activityHistory,
        clientStore: { selectedClientId },
        personsListingCondition,
      },
      activeActivityId,
    } = this.props;

    assets.fetch({
      clientId: selectedClientId,
    });
    activityHistory.fetch({
      clientId: this.props.store.clientStore.selectedClientId,
    });
    personsListingCondition.fetch({
      clientId: this.props.store.clientStore.selectedClientId,
      isBeneficiary: true,
    });
    if (activeActivityId) {
      this.getActivityAndSetValues(activeActivityId);
    }
    if (this.state.isEditable) {
      this.props.store.activityStartVomInfo.onActivityIsSelectedClick(false);
    }
    this.handleLinkedPersonsData();
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.activeActivityId) {
      this.getActivityAndSetValues(nextProps.activeActivityId);
    }
    if (this.state.isEditable) {
      this.props.store.activityStartVomInfo.onActivityIsSelectedClick(false);
    }
    if (!this.state.linkedPersons) {
      this.setState({
        personsListingData: nextProps.store.personsListingCondition.data,
      });
    }
  };

  getActivityAndSetValues = async activeActivityId => {
    await this.props.store.getActivityById.fetch({
      activityId: activeActivityId,
    });
    const selectedActivity = this.props.store.getActivityById.data;
    this.setState({
      isUpdate: true,
      isHCalculated: selectedActivity.is_hours_calculated_in_sum,
      heading: selectedActivity.note_heading,
      billable: selectedActivity.is_billable,
      relevanceMonthly: selectedActivity.is_monthly_report,
      relevanceQuarterly: selectedActivity.is_quarterly_report,
      hourlyRate: selectedActivity.hourly_rate ? formatMoneyToString(selectedActivity.hourly_rate) : "",
      linkedPerson: selectedActivity.linked_person_data
        ? {
          name: `${selectedActivity.linked_person_data.first_name} ${selectedActivity.linked_person_data.last_name}`,
          value: selectedActivity.linked_person_data.beneficiary_id,
        }
        : "",
      timer: {
        date: moment(selectedActivity.recording_date).toDate(),
        time: selectedActivity.recording_time,
        duration: selectedActivity.recording_duration ? replaceStringWithNumber(selectedActivity.recording_duration) : 0,
        elapsed: selectedActivity.recording_duration ? Number(selectedActivity.recording_duration) * 60000 : 0,
        started: false,
      },
      public_note: selectedActivity.public_note ? JSON.parse(selectedActivity.public_note) : null,
      internal_note: selectedActivity.internal_note ? JSON.parse(selectedActivity.internal_note) : null,
      billing_info: selectedActivity.billing_info ? JSON.parse(selectedActivity.billing_info) : null,
      report_info: selectedActivity.report_info ? JSON.parse(selectedActivity.report_info) : null,
      activityActionFlag: !this.state.activityActionFlag,
      isEditable: false,
      user_timestamp: selectedActivity.user_timestamp,
      errors: {},
    });
  };

  addNewBeneficiary = () => {
    const {
      store: { modal },
      client,
    } = this.props;
    modal.add({
      title: "Weitere Person hinzufügen",
      component: CreateNewBeneficiary,
      props: {
        client,
        updateData: this.handleLinkedPersonsData,
      },
    });
  };

  handleLinkedPersonsData = async () => {
    const {
      store: { personsListingCondition },
    } = this.props;
    const { linkedPersons } = this.state;
    await personsListingCondition.fetch({
      clientId: this.props.store.clientStore.selectedClientId,
      isBeneficiary: true,
    });
    if (personsListingCondition.data) {
      const newData = personsListingCondition.data.filter(function(el) {
        return !linkedPersons.find(person => {
          return person.key === el.person_id;
        });
      });
      this.setState({
        personsListingData: newData,
      });
    }
  };

  handleCheckboxChange = name => () => {
    this.setState(state => ({
      [name]: !state[name],
    }));
  };

  handleLinkedPersonChange = (person, index) => {
    const { linkedPersons, personsListingData, linkedPerson } = this.state;
    linkedPersons.push(person);
    const newListingData = personsListingData.filter(function(personListing) {
      return personListing.person_id !== person.key;
    });

    this.setState({
      linkedPerson: person,
      linkedPersons,
      personsListingData: newListingData,
    });
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleInputChangeHourlyRate = event => {
    // let val = event.target.value;
    const value = handleChangeMoneyInput(event.target.value);
    if (!!value || value === "") {
      this.setState({ [event.target.name]: value });
    }
    // if (val !== "" && isNaN(Number(val))) {
    //   return null;
    // }
  };

  handleReset = () => {
    this.setState({
      ...INITIAL_STATE,
      activityActionFlag: !this.state.activityActionFlag,
      user_timestamp: "",
      errors: {},
      linkedPersons: [],
      selectItems: ["select-0"],
    });
    this.handleLinkedPersonsData();
    window.localStorage.removeItem(TIMER_ID);
  };

  handleSubmit = async () => {
    const {
      store: { newActivity, activityHistory, clientStore, updateActivity, activityStartVomInfo },
    } = this.props;

    const {
      timer,
      heading,
      billable,
      relevanceMonthly,
      relevanceQuarterly,
      hourlyRate,
      public_note,
      internal_note,
      billing_info,
      report_info,
      linkedPersons,
      user_timestamp,
    } = this.state;
    let activityTime = this.props.store.getActivityById.data ? this.props.store.getActivityById.data.recording_duration : 0;

    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    const schema = Yup.object().shape({
      heading: Yup.string().required(requiredError),
      billable: Yup.boolean(),
      hourlyRate: Yup.string().when("billable", {
        is: true,
        then: Yup.string().required(requiredError),
      }),
      timer: Yup.object().shape({
        date: Yup.date()
          .typeError(requiredError)
          .max(new Date())
          .required(requiredError),
        elapsed: Yup.number(),
        duration: Yup.string().when("elapsed", elapsed => {
          return elapsed === 0
            ? Yup.string()
              .notOneOf(["__.__"])
              .required(requiredError)
            : Yup.string().notRequired();
        }),
        time: Yup.string()
          .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]/, "Ungültiges Format")
          .notOneOf(["__:__"])
          .required(requiredError),
      }),
    });
    try {
      const x = schema.validateSync(
        {
          heading,
          billable,
          hourlyRate,
          timer,
        },
        { abortEarly: false },
      );
    } catch (e) {
      const errors = {};
      if (e.inner) {
        e.inner.forEach(e1 => {
          const splitted = e1.path.split(".");
          if (splitted.length > 1) {
            // here is the timer;
            errors.timer = errors.timer ? errors.timer : {};
            errors.timer[splitted[1]] = e1.message;
            return;
          }
          errors[e1.path] = e1.message;
        });
      }
      return this.setState({
        errors,
      });
    }
    let realTime = timer.duration;
    if (!!timer.duration) {
      realTime =
        Math.abs(Number(timer.duration) - activityTime) <= 0.01
          ? timer.duration * 1
          : Math.floor(timer.duration) * 60 + (timer.duration - Math.floor(timer.duration)) * 100;
    }
    const hoursOfTime = Number(timer.time[0]) * 10 + Number(timer.time[1]);
    const minutesOfTime = Number(timer.time[3]) * 10 + Number(timer.time[4]);
    const errors = {};
    if (isNaN(hoursOfTime) || isNaN(minutesOfTime)) {
      errors["time"] = "Time format";
      return this.setState({
        errors,
      });
    }
    if (hoursOfTime > 23 || minutesOfTime > 59) {
      errors["time"] = "Time format";
      return this.setState({
        errors,
      });
    }
    let persons = linkedPersons.map(person => person.value);
    const data = {
      activity_type_id: "review",
      recording_date: timer.date,
      recording_time: timer.time.replace(/_/g, "0"),
      recording_duration: timer.duration !== "" ? realTime : String((timer.elapsed / 60000).toFixed(2)) * 1,
      note_heading: heading,
      is_billable: billable,
      is_monthly_report: relevanceMonthly,
      is_quarterly_report: relevanceQuarterly,
      hourly_rate: getMoneyNumber(hourlyRate),
      public_note: public_note ? JSON.stringify(public_note) : null,
      internal_note: internal_note ? JSON.stringify(internal_note) : null,
      billing_info: billing_info ? JSON.stringify(billing_info) : null,
      report_info: report_info ? JSON.stringify(report_info) : null,
      linked_person: linkedPersons ? persons : null,
      client_id: clientStore.selectedClientId,
      user_timestamp:
        "" +
        moment(new Date()).format("DD.MM.YYYY, HH:mm, ") +
        this.props.store.getUserFromToken.data.first_name +
        " " +
        this.props.store.getUserFromToken.data.last_name,
      files: activityStartVomInfo.files,
    };
    this.setState({
      activityActionFlag: !this.state.activityActionFlag,
    });
    this.setState({
      uploading: true,
    });
    if (this.state.isUpdate) {
      const selectedActivity = this.props.store.getActivityById.data;
      const activityId = selectedActivity.activity_id;
      await updateActivity
        .fetch({
          ...data,
          activity_id: activityId,
          activityId,
        })
        .then(() => {
          this.setState({
            uploading: false,
          });
          this.handleReset();
        });
    } else if (!this.state.isUpdate)
      await newActivity.fetch(data).then(() => {
        this.setState({
          uploading: false,
        });
        this.handleReset();
      });
    await activityHistory
      .fetch({
        clientId: this.props.store.clientStore.selectedClientId,
      })
      .then(() => {
        this.setState({
          uploading: false,
        });
        this.handleReset();
      });
    if (activityStartVomInfo.files) this.handleFileCreate();
    this.handleReset();
  };

  handleFileCreate = async () => {
    const {
      store: { clientStore, activityStartVomInfo, newActivity },
    } = this.props;

    const files = Array.from(activityStartVomInfo.files);
    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append(i, file);
    });

    // formData.append
    const activityId = newActivity.newActivities.activity_id;
    const fullUri = `${config.endpoints.api}/api/activity/${activityId}/${clientStore.selectedClientId}/file`;
    const requestOptions = {
      method: "PUT",
      data: formData,
    };

    requestOptions.url = fullUri;
    const token = window.localStorage.getItem(config.keys.local_storage_key);
    if (token) {
      requestOptions.headers = {
        Authorization: `${token}`,
      };
    }
    await Axios(requestOptions);
    activityStartVomInfo.setActivityFiles([]);
  };

  handleTimerChange = data => {
    this.setState({ timer: data });
  };

  handlePersonAdd = () => {
    let { selectItems } = this.state;
    selectItems.push("select-" + selectItems.length);
    this.setState({
      selectItems,
    });
  };

  handlePersonRemove = index => {
    let { selectItems, linkedPersons } = this.state;
    // selectItems.splice(selectItems.indexOf('select-'+index), 1);
    selectItems.pop();
    linkedPersons.splice(index, 1);
    this.setState(
      {
        selectItems,
        linkedPersons,
      },
      () => this.handleLinkedPersonsData(),
    );
  };

  render() {
    const {
      heading,
      billable,
      relevanceMonthly,
      relevanceQuarterly,
      hourlyRate,
      linkedPerson,
      public_note,
      internal_note,
      billing_info,
      report_info,
      errors,
      uploading,

      selectItems,
      linkedPersons,
      personsListingData,
    } = this.state;
    const {
      store: { activityHistory, personsListingCondition },
    } = this.props;
    return (
      <Layout displayFlex row rowToColumn={1600}>
        <PageColumn flex={6} left>
          <Layout spacingLeft={50} spacingRight={50} mediaPadding={600}>
            <Subheading icon="supervisor_account">Besprechung</Subheading>
            <Layout offsetBottom={50}>
              <Timer
                value={this.state.timer}
                changeActionFlag={this.state.activityActionFlag}
                onChange={this.handleTimerChange}
                timerId={TIMER_ID}
                errors={errors && errors.timer}
              />
            </Layout>
            <P offsetBottom={25} type="type-8">
              Notizen erstellen
            </P>
            <Layout offsetBottom={60} relative>
              <FormInput
                placeholder="Überschrift Aktivität"
                name="heading"
                value={heading}
                onChange={this.handleInputChange}
                error={errors && errors["heading"]}
              />
            </Layout>
            <ActivityEditor
              onChange={this.onActivityEditorChange}
              notifyActionFlag={this.state.activityActionFlag}
              value={{
                public: public_note,
                internal: internal_note,
                billing: billing_info,
                report: report_info,
              }}
            />
            <Layout displayFlex row width={"100%"} offsetBottom={20} rowToColumn={1024}>
              <Layout flex={1} spacingRight={60} spacingLeft={10}>
                <Layout displayFlex alignCenter justifyBetween offsetBottom={30}>
                  <P type="type-4"> {"Abrechenbar"} </P>
                  <CheckBox onClick={this.handleCheckboxChange("billable")} checked={billable} />
                </Layout>
              </Layout>

              <Layout flex={1} spacingRight={60} spacingLeft={10}>
                <Layout displayFlex alignCenter justifyBetween offsetBottom={30}>
                  <P type="type-4"> {"Relevanz Monats-Bericht"} </P>
                  <CheckBox onClick={this.handleCheckboxChange("relevanceMonthly")} checked={relevanceMonthly} />
                </Layout>
                <Layout displayFlex alignCenter justifyBetween offsetBottom={30}>
                  <P type="type-4"> {"Relevanz Quartals-Bericht"} </P>
                  <CheckBox onClick={this.handleCheckboxChange("relevanceQuarterly")} checked={relevanceQuarterly} />
                </Layout>
              </Layout>

              <Layout flex={1}>
                <Layout relative>
                  <FormInput
                    noOffset
                    value={hourlyRate}
                    name="hourlyRate"
                    onChange={this.handleInputChangeHourlyRate}
                    placeholder="Stundenverrechnungssatz"
                    error={errors && errors["hourlyRate"]}
                  />
                </Layout>
              </Layout>
            </Layout>
            <Layout displayFlex alignStart justifyStart offsetBottom={60} rowToColumn={700} centerToStart={700}>
              <P type="type-4" offsetRight={20}>
                {" "}
                {"Person verknüpfen"}{" "}
              </P>
              <Layout offsetRight={10} maxWidth={340} fullWidth>
                {personsListingData &&
                  selectItems.map((select, index) => (
                    <Layout key={linkedPersons[index] ? linkedPersons[index].key : index} displayFlex>
                      <Select
                        noAdapter
                        withNameAndValue
                        changeActionFlag={this.state.activityActionFlag}
                        selected={linkedPersons[index] ? linkedPersons[index] : ""}
                        onChange={this.handleLinkedPersonChange}
                        options={personsListingData.map(a => {
                          return {
                            name: `${a.first_name} ${a.last_name}`,
                            value: a.person_id,
                            key: a.person_id,
                          };
                        })}
                        placeholder="Person / Organisation verknüpfen"
                        disableSelect={linkedPersons[index] ? true : false}
                      />
                      <Layout offsetLeft={10}>
                        <Icon onClick={() => this.handlePersonRemove(index)} type="material-icons">
                          delete
                        </Icon>
                      </Layout>
                    </Layout>
                  ))}
              </Layout>
              <Layout offsetRight={10}>
                <Icon
                  onClick={personsListingCondition.data && selectItems.length < personsListingCondition.data.length && this.handlePersonAdd}
                  type="material-icons"
                >
                  add
                </Icon>
              </Layout>
              <Layout offsetRight={10}>
                <Icon onClick={this.addNewBeneficiary} type="material-icons">
                  person_add
                </Icon>
              </Layout>
            </Layout>
          </Layout>
          <PageFooter>
            <Layout displayFlex row alignCenter justifyBetween rowToColumn={1024}>
              <TextInput
                name="user_timestamp"
                value={this.state.user_timestamp}
                onChange={this.handleInputChange}
                maxWidth={220}
                footer
                placeholder="User / Timestamp"
                disabled
              />
              <Layout alignCenter displayFlex rowToColumn={786}>
                <Abbrechen margin={"20px 15px"} onClick={this.handleReset}>
                  Abbrechen
                </Abbrechen>
                {(!this.state.isHCalculated || this.state.isEditable) && (
                  <Speichem onClick={!uploading && this.handleSubmit}>{uploading ? "Ihre Daten wird verarbeitet" : "Speichern"}</Speichem>
                )}
              </Layout>
            </Layout>
          </PageFooter>
        </PageColumn>
        <PageColumn flex={4}>
          <Layout spacingLeft={20} spacingRight={20} mediaPadding={600}>
            <Subheading icon="event">Historie</Subheading>
            <NewsearchInput onChange={this.onSearchChange} />
          </Layout>

          {activityHistory.constructedTable && activityHistory.constructedTable.rows[0] ? (
            <Table hideHeader hideFooter small tableStore={activityHistory} onClickRow={this.handleRowClick} />
          ) : (
            <ResponseWarning />
          )}
        </PageColumn>
      </Layout>
    );
  }

  handleRowClick = index => {
    const {
      store: { activityHistory },
    } = this.props;
    const selectedActivity = activityHistory.data[index];
    this.props.changeActiveId(selectedActivity.activity_type_id, selectedActivity.activity_id);

    this.props.store.activityStartVomInfo.onChangeDate(selectedActivity.recording_date);
    this.props.store.activityStartVomInfo.onActivityIsSelectedClick();

    this.handleSelectOnEditActivity(selectedActivity.linked_person);
  };

  handleSelectOnEditActivity = linkedPersonsIDs => {
    const {
      store: { personsListingCondition },
    } = this.props;

    let persons = linkedPersonsIDs.split(",");
    let selects = [];
    for (let i = 0; i < persons.length; i++) selects.push("select-" + i);

    const newData = personsListingCondition.data.filter(function(el) {
      return persons.find(personID => {
        return personID === el.person_id;
      });
    });
    const linkData = newData.map(a => {
      return {
        name: `${a.first_name} ${a.last_name}`,
        value: a.person_id,
        key: a.person_id,
      };
    });

    const newPersonListingData = personsListingCondition.data.filter(function(el) {
      return !newData.find(person => {
        return person.person_id === el.person_id;
      });
    });

    this.setState({
      selectItems: selects,
      personsListingData: newPersonListingData,
      linkedPersons: linkData,
    });
  };

  onActivityEditorChange = changed => {
    const { public: public_note, internal, billing, report } = changed;
    this.setState({
      public_note,
      internal_note: internal,
      billing_info: billing,
      report_info: report,
    });
  };

  onSearchChange = val => {
    this.props.store.activityHistory.fetch({
      search: val,
      clientId: this.props.store.clientStore.selectedClientId,
    });
  };
}

export default compose(
  inject("store"),
  observer,
)(Review);
