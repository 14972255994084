import styled from "styled-components";
import { COLORS, FONTWEIGHT } from "./constants";

export const CreateNewPoliceOuter = styled.div`
  width: 705px;
  padding: 25px 55px 0 0;
  h5 {
    text-decoration: underline;
    font-size: 1.12rem;
    font-weight: ${FONTWEIGHT.FW_6};
    color: ${COLORS.PINE};
    margin-right: 27px;
    @media only screen and (max-width: 600px) {
      margin-bottom: 15px;
    }
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0;
  }
`;

export const PoliceModalFooterOuter = styled.div`
  button {
    position: relative;
    min-width: 223px;
    padding-right: 20px;
    i {
      position: absolute;
      right: 23px;
      top: 15px;
    }
  }
`;
