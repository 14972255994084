import { TIMER_ID as ReviewTimerId } from '../components/Activity/Review';
import { TIMER_ID as AppointmentId } from '../components/Activity/Appointment';
import { TIMER_ID as CorrespondenceId } from '../components/Activity/Correspondence';
import { TIMER_ID as SettlementId } from '../components/Activity/Settlement';
import { TIMER_ID as ResearchId } from '../components/Activity/Research';


export const replaceStringWithNumber = (number, s = '0000.00') => {
  const n = number.toFixed(2);
  let sToReturn = String(n);
  while (sToReturn.length < s.length) {
    sToReturn = '0' + sToReturn;
  }

  return sToReturn;
}

export const ALL_TIMERS = [
  ReviewTimerId,
  AppointmentId,
  CorrespondenceId,
  SettlementId,
  ResearchId,
]

export const isTimerRunning = () => {
  let isRunning = false;

  ALL_TIMERS.forEach(timer => {
    if (isRunning) {
      return;
    }
    const storageTimeout = window.localStorage.getItem(`${timer}`);
    if (storageTimeout) {
      const values = JSON.parse(storageTimeout);
      isRunning = !values.isStopped
    }
  });

  return isRunning;
}






const moreThanTwoDecimals = number => {
  if (number.includes(",")) {
    const numberArray = number.split(",");
    if (numberArray[numberArray.length - 1].length > 2) return true;
  }

  return false;
};

export const handleChangeMoneyInput = (value) => {
  const realNumber = getMoneyNumber(value);
  // const value = event.target.value.replace(//,)
  if (value === ',') {
    return "0,";
  }
  if (value.split(',').length > 2) {
    return false;
  }
  if (!/[^0-9,.]/g.test(value)) {
    if (!moreThanTwoDecimals(value)) {
      return value !== "" &&
        value[value.length - 1] !== "," &&
        !(value.includes(",") && value[value.length - 1] === "0")
        ? realNumber.toLocaleString("de-DE")
        : value;
    }else if(value === ''){
      return '';
    }
  }

};

export const getMoneyNumber = value => typeof value === 'number' ? value : Number((value === ''? '0': value).replace(/[^0-9,.]/g, "").replace(/\./g, "").replace(",", "."));

export const formatMoneyToString = (money) => {
  if (typeof money === 'number') {
    return money.toLocaleString('de-DE', { minimumFractionDigits: 2 })
  }
  if (typeof money === 'string') {
    const newMoney = Number(money.replace(/[^0-9,.]/g, "").replace(/\./g, "").replace(",", "."));

    return newMoney.toLocaleString('de-DE', { minimumFractionDigits: 2 });
  }

  return money;
}

export const formatStringToMoney = (stringMoney) => {
  const realNumber = Number(stringMoney.replace(/[^0-9,.]/g, "").replace(/\./g, "").replace(",", "."))
  if (stringMoney === ',') {
    return "0,";
  }
  const lastIndexOfComma = stringMoney.lastIndexOf(',')
  if (stringMoney.split(',').length > 2) {
    return stringMoney.slice(0, lastIndexOfComma);
  }
  if (!/[^0-9,.]/g.test(stringMoney)) {
    return stringMoney !== "" &&
      stringMoney[stringMoney - 1] !== "," &&
      !(stringMoney.includes(",") && stringMoney[stringMoney.length - 1] === "0")
      ? realNumber.toLocaleString("de-DE")
      : stringMoney;
  }
}