import React from "react";
import { FragenKatalogOuter } from "../styled/questioncatalogue";
import FragenHead from "../components/QuestionCatalog/FragenHead";
import BearbeitungsStatus from "../components/QuestionCatalog/BearbeitungsStatus";
import FragenList from "../components/QuestionCatalog/FragenList";
import ActionsBottom from "../components/QuestionCatalog/ActionBottom";

const FragenKatalog = () => (
  <FragenKatalogOuter>
    <FragenHead />
    <BearbeitungsStatus />
    <FragenList />
    <ActionsBottom />
  </FragenKatalogOuter>
);

export default FragenKatalog;
