import React, { Fragment } from "react";
import Axios from "axios";
import { COLORS } from "../../styled/constants";
import { ToastsStore, ToastsContainer } from "react-toasts";

import { Icon, Layout, P } from "../../styled";
import { PageColumn, PageFooter, Callout, SumContainer, SumContainerCount, DateContainer } from "../../styled/layout";
import { Speichem, Abbrechen } from "../../styled/buttons";
import { TextInput } from "../../styled/form";
import Subheading from "../Layout/Subheading";
import Timer from "../Timer";
import CheckBox from "../Form/CheckBox";
import DatePicker from "../Form/DatePicker";
import Table from "../Table";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import CreateNewBeneficiary from "../Modal/CreateNewBeneficiary";
import FloatingAdd from "../FloatingAdd";
import { Button } from "../../styled/buttons";
import ResponseWarning from "../Response";
import moment from "moment";
import { replaceStringWithNumber, handleChangeMoneyInput, getMoneyNumber, formatMoneyToString } from "../../utils/helper";
import * as Yup from "yup";
import FormInput from "../Form/Input";
import config from "../../lib/config";

const today = new Date();

// eslint-disable-next-line
let previousRows1 = {};

const item = {
  is_item_billable: false, //itemIsChecked: false,
  description: "", //nameOfItem: "",
  value_of_item: 0, //valueOfItem: null
};

const bill = {
  bill_number: "",
  is_travel_expenses_sum_checked: false,
  is_hours_sum_checked: false,
  fromDate: today,
  toDate: today,
  sum: null,
  items: null,
  travel_expenses_sum: 0,
  hours_sum: 0,
  activity_price: 0,
  activity_calculated_in_price: false,
};
const act = {
  billable: false,
  recording_date: null,
  recording_time: null,
  recording_duration: null,
  timestamp: "",
};
const INITIAL_STATE = {
  heading: "",
  billable: false,
  relevanceMonthly: false,
  relevanceQuarterly: false,
  hourlyRate: "",
  linkedPerson: "",
  has_email: false,
  has_letter: false,
  has_phone: false,
  timer: null,
  act: act,
  bill: bill,
  arrayOfItems: [{ ...item }],
  activity_from_date: today,
  activity_to_date: today,
  activityActionFlag: false,
  isEditable: true,
  uploading: false,
};

export const TIMER_ID = "timer_settlement";
class Settlement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    previousRows1 = {};
    const {
      store: { assets, activityHistory },
      client,
      activeActivityId,
    } = this.props;
    assets.fetch({
      clientId: client.client_id,
    });
    activityHistory.fetch({
      clientId: this.props.store.clientStore.selectedClientId,
    });
    this.getAllBillsFromClient();

    if (activeActivityId) {
      this.getActivityAndSetValues(activeActivityId);
    }

    this.sendRequestForActivitiesBetweenDates(false, this.state && this.state.activity_from_date);
    if (this.state.isEditable) {
      this.props.store.activityStartVomInfo.onActivityIsSelectedClick(false);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeActivityId) {
      this.getActivityAndSetValues(nextProps.activeActivityId);
    }
    if (this.state.isEditable) {
      this.props.store.activityStartVomInfo.onActivityIsSelectedClick(false);
    }
  }

  getActivityAndSetValues = async activeActivityId => {
    await this.props.store.getActivityById.fetch({
      activityId: activeActivityId,
    });
    const selectedActivity = this.props.store.getActivityById.data;
    let tempBill, tempItems;
    this.props.store.billingAndItems.data &&
      this.props.store.billingAndItems.data.forEach(element => {
        if (element[0] && element[0].settlement_activity_id === selectedActivity.activity_id) {
          tempBill = element[0];
          tempItems = element[0].items;
        }
      });
    this.setState({
      heading: selectedActivity.note_heading,
      billable: selectedActivity.is_billable,
      relevanceMonthly: selectedActivity.is_monthly_report,
      relevanceQuarterly: selectedActivity.is_quarterly_report,
      hourlyRate: selectedActivity.hourly_rate ? formatMoneyToString(selectedActivity.hourly_rate) : "",
      has_email: selectedActivity.has_email,
      has_letter: selectedActivity.has_letter,
      has_phone: selectedActivity.has_phone,
      act: act,
      timer: {
        ...this.state.timer,
        date: moment(selectedActivity.recording_date).toDate(),
        time: selectedActivity.recording_time,
        duration: selectedActivity.recording_duration ? replaceStringWithNumber(selectedActivity.recording_duration) : 0,
        elapsed: selectedActivity.recording_duration ? Number(selectedActivity.recording_duration) * 60000 : 0,
        started: false,
      },
      act: {
        ...this.state.act,
        billable: selectedActivity.is_billable,
        recording_date: selectedActivity.recording_date,
        recording_duration: selectedActivity.recording_duration,
        recording_time: selectedActivity.recording_time,
        timestamp: selectedActivity.timestamp,
      },
      activityActionFlag: !this.state.activityActionFlag,
      bill: {
        ...tempBill,
        fromDate: tempBill && tempBill.from_date,
        toDate: tempBill && tempBill.to_date,
      },
      vomdate: moment(tempBill && tempBill.from_date).toDate(),
      bisdate: moment(tempBill && tempBill.to_date).toDate(),
      arrayOfItems: [...tempItems],
      isEditable: false,
    });
  };

  addNewBeneficiary = () => {
    const {
      store: { modal },
      client,
    } = this.props;
    modal.add({
      title: "Weitere Person hinzufügen",
      component: CreateNewBeneficiary,
      props: {
        client,
      },
    });
  };

  handleCheckboxChange = name => () => {
    this.setState(state => ({
      [name]: !state[name],
    }));
  };

  handleLinkedPersonChange = person => {
    this.setState({ linkedPerson: person });
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleReset = () => {
    this.setState({
      ...INITIAL_STATE,
      activityActionFlag: !this.state.activityActionFlag,
      arrayOfItems: [{ ...item }],
      user_timestamp: "",
      errors: {},
    });
    window.localStorage.removeItem(TIMER_ID);
  };

  handleSubmit = async () => {
    const {
      store: { newActivity, activityHistory, clientStore, changeActivitiesCalculated, activityStartVomInfo },
    } = this.props;

    const {
      timer,
      relevanceMonthly,
      relevanceQuarterly,
      hourlyRate,
      has_email,
      has_letter,
      has_phone,
      act,
      bill,
      arrayOfItems,
      vomdate,
      bisdate,
    } = this.state;
    let activityTime = this.props.store.getActivityById.data ? this.props.store.getActivityById.data.recording_duration : 0;
    let realTime = act.recording_duration;
    if (!!act.recording_duration) {
      realTime =
        Math.abs(Number(act.recording_duration) - activityTime) <= 0.01
          ? act.recording_duration * 1
          : Math.floor(act.recording_duration) * 60 + (act.recording_duration - Math.floor(act.recording_duration)) * 100;
    }

    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    const schema = Yup.object().shape({
      billable: Yup.boolean(),
      hourlyRate: Yup.string().when("billable", {
        is: true,
        then: Yup.string()
          .nullable()
          .required(requiredError),
      }),
      bill: Yup.object().shape({
        bill_number: Yup.string()
          .nullable()
          .required(requiredError),
      }),
      timer: Yup.object().shape({
        date: Yup.date()
          .typeError(requiredError)
          .max(new Date())
          .required(requiredError),
        elapsed: Yup.number(),
        duration: Yup.string().when("elapsed", elapsed => {
          return elapsed === 0
            ? Yup.string()
              .notOneOf(["__.__"])
              .required(requiredError)
            : Yup.string().notRequired();
        }),
        time: Yup.string()
          .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]/, "Ungültiges Format")
          .notOneOf(["__:__"])
          .required(requiredError),
      }),
      vomdate: Yup.date().required(requiredError),
      bisdate: Yup.date().required(requiredError),
    });

    let errors = {};

    try {
      schema.validateSync(
        {
          billable: act.billable,
          hourlyRate,
          timer,
          bill,
          vomdate,
          bisdate,
        },
        { abortEarly: false },
      );
    } catch (e) {
      if (e.inner) {
        e.inner.forEach(e1 => {
          const splitted = e1.path.split(".");
          if (splitted.length > 1) {
            // here is the timer;
            if (splitted[0] === "timer") {
              errors.timer = errors.timer ? errors.timer : {};
              errors.timer[splitted[1]] = e1.message;
            } else if (splitted[0] === "bill") {
              errors.bill = errors.bill ? errors.bill : {};
              errors.bill[splitted[1]] = e1.message;
            }
            return;
          }
          errors[e1.path] = e1.message;
        });
      }
      console.log("Error je:", e);
      return this.setState({
        errors,
      });
    }

    this.setState({
      errors,
    });

    const hoursOfTime = Number(timer.time[0]) * 10 + Number(timer.time[1]);
    const minutesOfTime = Number(timer.time[3]) * 10 + Number(timer.time[4]);
    if (isNaN(hoursOfTime) || isNaN(minutesOfTime)) {
      errors["time"] = "Time format";
      return this.setState({
        errors,
      });
    }
    if (hoursOfTime > 23 || minutesOfTime > 59) {
      errors["time"] = "Time format";
      return this.setState({
        errors,
      });
    }

    const data = {
      activity_type_id: "settlement_activity",
      recording_date: act.recording_date,
      recording_time: act.recording_time,
      recording_duration: act.recording_duration !== "" ? realTime : String((timer.elapsed / 60000).toFixed(2)),
      note_heading: `Honorar-Abrechung ${moment().format("MMMM Do YYYY")}`,

      is_billable: act.billable,
      is_monthly_report: relevanceMonthly,
      is_quarterly_report: relevanceQuarterly,
      has_email,
      has_letter,
      has_phone,
      hourly_rate: getMoneyNumber(hourlyRate),
      client_id: clientStore.selectedClientId,
      billing_data: {
        bill_number: bill.bill_number,
        from_date: bill.fromDate,
        to_date: bill.toDate,
        is_hours_sum_checked: bill.is_hours_sum_checked,
        hours_sum: bill.hours_sum,
        is_travel_expenses_sum_checked: bill.is_travel_expenses_sum_checked,
        travel_expenses_sum: bill.travel_expenses_sum,
        items: [...arrayOfItems.map(t => ({ ...t, value_of_item: getMoneyNumber(t.value_of_item) }))],
        activity_price: (Number(hourlyRate) * Number(realTime ? realTime : 0)) / 60,
        activity_calculated_in_price: act.billable,
      },
      user_timestamp:
        "" +
        moment(new Date()).format("DD.MM.YYYY, HH:mm, ") +
        this.props.store.getUserFromToken.data.first_name +
        " " +
        this.props.store.getUserFromToken.data.last_name,
      files: activityStartVomInfo.files,
    };

    let checkedBoxes = false;
    let sum = 0;
    if (data.billing_data.activity_calculated_in_price) {
      checkedBoxes = true;
      sum += Number(data.billing_data.activity_price);
    }
    if (data.billing_data.is_hours_sum_checked) {
      checkedBoxes = true;
      sum += Number(data.billing_data.hours_sum);
    }
    if (data.billing_data.is_travel_expenses_sum_checked) {
      checkedBoxes = true;
      sum += Number(data.billing_data.travel_expenses_sum);
    }
    data.billing_data.items.forEach(item => {
      if (item.is_item_billable) {
        checkedBoxes = true;
        sum += getMoneyNumber(item.value_of_item);
      }
    });
    if (checkedBoxes && sum > 0) {
      try {
        await activityHistory
          .fetch({
            clientId: this.props.store.clientStore.selectedClientId,
          })
          .then(() => {
            this.setState({
              uploading: false,
            });
          });
        await newActivity.fetch(data);
        const settlementId = newActivity.newActivities.activity_id;
        await changeActivitiesCalculated
          .fetch({
            clientId: this.props.store.clientStore.selectedClientId,
            startDate: bill.fromDate,
            endDate: bill.toDate,
            expensesBool: bill.is_travel_expenses_sum_checked,
            hoursBool: bill.is_hours_sum_checked,
            settlement_activity_id: settlementId,
          })
          .then(() => {
            this.setState({
              uploading: false,
            });
            this.handleReset();
            window.location.reload();
          });
        this.getAllBillsFromClient();
      } catch (err) {
        //
      }
      this.handleReset();
      this.forceUpdate();
    } else {
      ToastsStore.error("Sie können keine Null Euro Rechnung erstellen.");
    }
  };

  handleTimerChange = data => {
    // ACT DATE AND TIME INFO
    this.setState({
      timer: data,
      act: {
        ...this.state.act,
        recording_date: data.date,
        recording_time: data.time,
        recording_duration: data.duration,
      },
    });
  };
  handleChangeVomDate = async e => {
    //From Date
    if (e && this.state.bisdate) {
      await this.props.store.getClientExpensesByDates.fetch({
        clientId: this.props.store.clientStore.selectedClientId,
        startDate: e,
        endDate: this.state.bisdate,
      });
    }
    //
    const hoursSum = this.props.store.getClientExpensesByDates.data && this.props.store.getClientExpensesByDates.data.hoursSum;
    const travelExpensesSum = this.props.store.getClientExpensesByDates.data && this.props.store.getClientExpensesByDates.data.expensesSum;
    //
    this.setState({
      vomdate: e,
      bill: {
        ...this.state.bill,
        fromDate: e,
        hours_sum: hoursSum,
        travel_expenses_sum: travelExpensesSum,
      },
    });
  };
  handleChangeBisDate = async e => {
    //To Date
    if (e && this.state.vomdate) {
      await this.props.store.getClientExpensesByDates.fetch({
        clientId: this.props.store.clientStore.selectedClientId,
        endDate: e,
        startDate: this.state.vomdate,
      });
    }
    //
    const hoursSum = this.props.store.getClientExpensesByDates.data && this.props.store.getClientExpensesByDates.data.hoursSum;
    const travelExpensesSum = this.props.store.getClientExpensesByDates.data && this.props.store.getClientExpensesByDates.data.expensesSum;
    //
    this.setState({
      bisdate: e,
      bill: {
        ...this.state.bill,
        toDate: e,
        hours_sum: hoursSum,
        travel_expenses_sum: travelExpensesSum,
      },
    });
  };

  onRowInfoGetClick = async index => {
    const {
      store: { getReports, getRevisionFiles, assetRevisionCreate, clientStore },
    } = this.props;

    const { activity_from_date, activity_to_date } = this.state;

    const token = window.localStorage.getItem(config.keys.local_storage_key);
    Axios({
      method: "GET",
      url: config.endpoints.api + `/api/activity/report?clientId=${clientStore.selectedClientId}&billId=${index}`,
      responseType: "application/json",
      headers: { Authorization: `${token}` },
      data: {
        data: sumList,
        clientId: clientStore.selectedClientId,
      },
    }).then(res => {
      window.open(`${config.endpoints.api}/reportdownload/${res.data}`);
    });
  };

  getAllBillsFromClient = async () => {
    try {
      const {
        store: { billingAndItems },
      } = this.props;
      await billingAndItems.fetch({
        clientID: this.props.store.clientStore.selectedClientId,
      });
      previousRows1 = {};
      billingAndItems.data.forEach(element => {
        if (element.length == 0) {
          return;
        }
        const { items } = element[0];
        let valueOfBill = Number(this.getSumOfBill(items));
        if (element[0].is_hours_sum_checked) {
          valueOfBill += Number(element[0].hours_sum);
        }
        if (element[0].travel_expenses_sum) {
          valueOfBill += Number(element[0].travel_expenses_sum);
        }
        if (element[0].activity_calculated_in_price) {
          if (element[0].activity_price) valueOfBill += Number(element[0].activity_price);
        }
        previousRows1[element[0].id] = [
          {
            key: element[0].id + "?1",
            type: "text",
            data: moment(element[0].createdAt).format("DD.MM.YYYY"),
          },
          {
            key: element[0].id + "?2",
            type: "text",
            data: element[0].bill_number,
          },
          {
            key: element[0].id + "?3",
            type: "text",
            data: "" + valueOfBill.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " €",
          },
        ];
      });
      previousData.constructedTable.rows = previousRows1;
      this.forceUpdate();
    } catch (e) {
      //
    }
  };

  export = () => {
    const {
      store: { activitiesBetweenDates, clientStore },
    } = this.props;

    const { activity_from_date, activity_to_date } = this.state;

    const token = window.localStorage.getItem(config.keys.local_storage_key);

    Axios({
      method: "POST",
      url: config.endpoints.api + `/api/activity/export`,
      responseType: "application/json",
      headers: { Authorization: `${token}` },
      data: {
        sumList: sumList.map(t => ({ ...t, amount: getMoneyNumber(t.amount) })),
        clientId: clientStore.selectedClientId,
        activities: activitiesBetweenDates.data,
        from: activity_from_date,
        to: activity_to_date,
      },
    }).then(res => {
      window.open(`${config.endpoints.api}/reportdownload/${res.data}`);
    });
  };

  handleInputChangeHourlyRate = event => {
    const value = handleChangeMoneyInput(event.target.value);
    if (!!value || value === "") {
      this.setState({ [event.target.name]: value });
    }
  };
  render() {
    const { billable, billable_travel_expenses, billable_recordings, other_items, bill, errors, uploading } = this.state;
    const {
      store: { activityHistory, getClientExpensesByDates, activitiesBetweenDates },
    } = this.props;
    const hoursSum = !bill.fromDate && !bill.toDate ? "" : getClientExpensesByDates.data && getClientExpensesByDates.data.hoursSum;
    const travelExpensesSum =
      !bill.fromDate && !bill.toDate ? "" : getClientExpensesByDates.data && getClientExpensesByDates.data.expensesSum;

    return (
      <Layout displayFlex row rowToColumn={1550} mediaPadding={600}>
        <PageColumn flex={6} left>
          <Layout spacingLeft={50} spacingRight={50} mediaPadding={600}>
            <Subheading icon="euro_symbol">Aktivitäten Abrechnung</Subheading>
            <Callout>
              <Icon type="material-icons">info</Icon>
              {"Die Abrechnung nach Tabelle finden Sie unter Vorlagen"}
            </Callout>
            <Layout offsetBottom={50}>
              <Timer
                onChange={this.handleTimerChange}
                timerId={TIMER_ID}
                value={this.state.timer}
                changeActionFlag={this.state.activityActionFlag}
                errors={errors && errors.timer}
              />
            </Layout>

            <Layout displayFlex row width={"100%"} offsetBottom={20} rowToColumn={1024} alignCenter>
              <Layout spacingRight={60} spacingLeft={10}>
                <Layout displayFlex alignCenter justifyStart>
                  <Layout offsetRight={20}>
                    <CheckBox
                      checked={this.state.act.billable}
                      onClick={() => {
                        this.setState({
                          act: {
                            ...this.state.act,
                            billable: !this.state.act.billable,
                          },
                        });
                      }}
                    />
                  </Layout>
                  {"Abrechenbar"}
                </Layout>
              </Layout>
              <Layout>
                <Layout relative>
                  {this.state.act.billable && (
                    <FormInput
                      noOffset
                      value={this.state.hourlyRate}
                      name="hourlyRate"
                      onChange={this.handleInputChangeHourlyRate}
                      placeholder="Stundenverrechnungssatz"
                      error={errors && errors["hourlyRate"]}
                    />
                  )}
                </Layout>
              </Layout>
            </Layout>

            <P offsetBottom={20} type="type-8">
              Zeitraum für Aktivitäten-Abrechnung
            </P>
            <Layout displayFlex row alignCenter justifyBetween offsetBottom={80} rowToColumn={1024} centerToStart={1024}>
              <Layout displayFlex row>
                <Layout minWidth="50px">
                  <P type="type-4" offsetRight={17}>
                    vom
                  </P>
                </Layout>
                <Layout flex={2} spacingRight={17} offsetBottom={20} offsetRight={10} relative>
                  <DatePicker value={this.state.vomdate} onChange={this.handleChangeVomDate} error={errors && errors["vomdate"]} />
                </Layout>
              </Layout>
              <Layout displayFlex row>
                <Layout minWidth="50px">
                  <P type="type-4" offsetRight={17}>
                    bis
                  </P>
                </Layout>
                <Layout flex={2} spacingRight={17} offsetBottom={20} offsetRight={10} relative>
                  <DatePicker value={this.state.bisdate} onChange={this.handleChangeBisDate} error={errors && errors["bisdate"]} />
                </Layout>
              </Layout>
              <Layout flex={4} offsetBottom={20} offsetRight={10}>
                <Layout relative>
                  <FormInput
                    noOffset
                    placeholder="Rechnungsnumer**"
                    value={this.state.bill.bill_number}
                    onChange={e => {
                      this.setState({
                        bill: {
                          ...this.state.bill,
                          bill_number: e.target.value,
                        },
                      });
                    }}
                    error={errors && errors.bill && errors.bill.bill_number}
                  />
                </Layout>
                {/* <TextInput
                  placeholder="Rechnungsnumer**"
                  value={this.state.bill.bill_number}
                  onChange={e => {
                    this.setState({
                      bill: { ...this.state.bill, bill_number: e.target.value }
                    });
                  }}
                /> */}
              </Layout>
            </Layout>

            <P offsetBottom={20} type="type-8">
              Positionen für Aktivitäten-Abrechnung im gewählten Zeitraum
            </P>
            <Layout displayFlex row alignStart justifyBetween offsetBottom={50}>
              <Layout flex={2}>
                <Layout displayFlex alignCenter justifyStart offsetTop={10} offsetBottom={60}>
                  <Layout offsetRight={20}>
                    <CheckBox
                      checked={this.state.bill.is_travel_expenses_sum_checked}
                      onClick={() => {
                        this.setState({
                          bill: {
                            ...this.state.bill,
                            is_travel_expenses_sum_checked: !this.state.bill.is_travel_expenses_sum_checked,
                          },
                        });
                      }}
                    />
                  </Layout>
                  {"Reisekosten (als abrechenbar markiert)"}
                </Layout>
                <Layout displayFlex alignCenter justifyStart offsetBottom={37}>
                  <Layout offsetRight={20}>
                    <CheckBox
                      checked={this.state.bill.is_hours_sum_checked}
                      onClick={() => {
                        this.setState({
                          bill: {
                            ...this.state.bill,
                            is_hours_sum_checked: !this.state.bill.is_hours_sum_checked,
                          },
                        });
                      }}
                    />
                  </Layout>
                  {"Abrechenbare Summe aus Zeiterfassung (als abrechenbar markiert)"}
                </Layout>
                {this.state.arrayOfItems.map((item, index) => this.renderRowDescription(item, index))}

                <Layout displayFlex alignCenter justifyStart offsetBottom={30}>
                  <FloatingAdd onClick={this.onPlusClick} />
                </Layout>
              </Layout>
              <Layout flex={1}>
                <Layout displayFlex alignStart justifyStart offsetBottom={30}>
                  <TextInput
                    disabled
                    style={{ maxWidth: 250 }}
                    placeholder={
                      this.state.isEditable
                        ? "Beschreibung"
                        : `${
                          typeof this.state.bill.travel_expenses_sum === "number"
                            ? this.state.bill.travel_expenses_sum.toLocaleString("de-DE", { minimumFractionDigits: 2 })
                            : this.state.bill.travel_expenses_sum
                        } `
                    }
                    value={
                      typeof travelExpensesSum === "number"
                        ? travelExpensesSum.toLocaleString("de-DE", { minimumFractionDigits: 2 })
                        : travelExpensesSum
                    }
                    onChange={() => {
                      this.setState({
                        bill: {
                          ...this.state.bill,
                          travel_expenses_sum: travelExpensesSum,
                        },
                      });
                    }}
                  />
                </Layout>
                <Layout displayFlex alignCenter justifyStart offsetBottom={30}>
                  <TextInput
                    disabled
                    style={{ maxWidth: 250 }}
                    placeholder={
                      this.state.isEditable
                        ? "Beschreibung"
                        : `${
                          typeof this.state.bill.hours_sum === "number"
                            ? this.state.bill.hours_sum.toLocaleString("de-DE", { minimumFractionDigits: 2 })
                            : this.state.bill.hours_sum
                        } `
                    }
                    value={typeof hoursSum === "number" ? hoursSum.toLocaleString("de-DE", { minimumFractionDigits: 2 }) : hoursSum}
                    onChange={() => {
                      this.setState({
                        bill: { ...this.state.bill, hours_sum: hoursSum },
                      });
                    }}
                  />
                </Layout>
                {this.state.arrayOfItems.map((item, index) => this.renderRowValue(item, index))}
              </Layout>
            </Layout>
          </Layout>
          <Layout offsetBottom={60}>
            <PageFooter>
              <Layout displayFlex row alignCenter justifyBetween rowToColumn={1024}>
                <TextInput
                  disabled
                  value={this.state.user_timestamp}
                  maxWidth={220}
                  footer
                  placeholder="User / Timestamp"
                  onChange={e => {
                    this.setState({
                      act: { ...this.state.act, timestamp: e.target.value },
                      user_timestamp: e.target.value,
                    });
                  }}
                />
                <Layout alignCenter displayFlex rowToColumn={786}>
                  <Abbrechen margin={"20px 15px"} onClick={this.handleReset}>
                    Abbrechen
                  </Abbrechen>
                  {this.state.isEditable && (
                    <Speichem onClick={!uploading && this.handleSubmit}>{uploading ? "Ihre Daten wird verarbeitet" : "Speichern"}</Speichem>
                  )}
                </Layout>
              </Layout>
            </PageFooter>
          </Layout>
          <Layout fullWidth displayFlex alignCenter justifyCenter offsetBottom={30}>
            <P offsetBottom={20} type="type-8">
              Bisherige Aktivitäten- Abrechnungen
            </P>
          </Layout>
          {<Table hideHeader hideFooter small tableStore={previousData} onClickRow={this.onRowInfoGetClick} />}
        </PageColumn>
        <PageColumn flex={4}>
          <Layout spacingLeft={20} spacingRight={20} mediaPadding={600}>
            <Layout>
              <Subheading icon="date_range">Erfasste Zeiten</Subheading>
            </Layout>
            <DateContainer>
              <Layout displayFlex row selfAlignCenter>
                <Layout minWidth="50px">
                  <P type="type-4" offsetRight={17}>
                    vom
                  </P>
                </Layout>
                <Layout flex={2} spacingRight={17} offsetBottom={10} offsetRight={10}>
                  <DatePicker
                    value={this.state.activity_from_date}
                    onChange={e => {
                      this.setState({
                        activity_from_date: e,
                      });
                      this.sendRequestForActivitiesBetweenDates(false, e);
                    }}
                  />
                </Layout>
              </Layout>
              <Layout displayFlex row selfAlignCenter>
                <Layout minWidth="50px">
                  <P type="type-4" offsetRight={17}>
                    bis
                  </P>
                </Layout>
                <Layout flex={2} spacingRight={17} offsetBottom={10} offsetRight={10}>
                  <DatePicker
                    value={this.state.activity_to_date}
                    onChange={e => {
                      this.setState({
                        activity_to_date: e,
                      });
                      this.sendRequestForActivitiesBetweenDates(true, e);
                    }}
                  />
                </Layout>
              </Layout>
            </DateContainer>
          </Layout>
          {activitiesBetweenDates.constructedTable && activitiesBetweenDates.constructedTable.rows[0] ? (
            <Table hideHeader small tableStore={activitiesBetweenDates} onClickRow={this.handleRowClick} />
          ) : (
            <ResponseWarning />
          )}
          <Layout spacingLeft={20} spacingRight={20}>
            {sumList.map(item => (
              <SumContainer>
                <i className="material-icons">{item.icon}</i>
                <Layout flex={1} borderRight={`1px solid ${COLORS.LIGHT_GREY_TWO}`} minWidth="100px">
                  {item.title}
                </Layout>
                <SumContainerCount>{item.amount}</SumContainerCount>
              </SumContainer>
            ))}
          </Layout>
          <Layout displayFlex fullWidth alignCenter justifyEnd offsetBottom={20} offsetTop={20}>
            <Button
              onClick={this.export}
              color="green"
              style={{
                paddingTop: 13,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Exportieren
              <i style={{ marginLeft: 20 }} className="material-icons">
                save_alt
              </i>
            </Button>
          </Layout>
        </PageColumn>
        <ToastsContainer lightBackground store={ToastsStore} />
      </Layout>
    );
  }

  onPlusClick = () => {
    const { arrayOfItems } = this.state;
    this.setState({ arrayOfItems: [...arrayOfItems, { ...item }] });
    this.forceUpdate();
  };

  renderRowDescription = (itemOfArray, index) => {
    const { other_items, arrayOfItems } = this.state;
    let array = arrayOfItems;
    return (
      <Layout displayFlex alignCenter justifyStart offsetBottom={30}>
        <Layout offsetRight={20}>
          <CheckBox
            onClick={() => {
              array[index].is_item_billable = !itemOfArray.is_item_billable;
              this.setState({
                arrayOfItems: [...arrayOfItems],
              });
            }}
            checked={itemOfArray.is_item_billable}
          />
        </Layout>
        {"Sonstige Positionen"}
        <TextInput
          style={{ maxWidth: 250, marginLeft: 20 }}
          placeholder="Beschreibung"
          value={itemOfArray.description}
          onChange={e => {
            array[index].description = e.target.value;
            this.setState({
              arrayOfItems: [...array],
            });
          }}
        />
      </Layout>
    );
  };
  renderRowValue = (itemOfArray, index) => {
    const { other_items, arrayOfItems } = this.state;
    let array = arrayOfItems;
    return (
      <Layout displayFlex alignCenter justifyStart offsetBottom={30}>
        <TextInput
          style={{ maxWidth: 250 }}
          value={
            typeof itemOfArray.value_of_item === "number"
              ? itemOfArray.value_of_item === 0
                ? ""
                : itemOfArray.value_of_item.toLocaleString("de-DE", { minimumFractionDigits: 2 })
              : itemOfArray.value_of_item === ""
                ? null
                : itemOfArray.value_of_item
          }
          placeholder={
            typeof itemOfArray.value_of_item === "number"
              ? itemOfArray.value_of_item.toLocaleString("de-DE", { minimumFractionDigits: 2 })
              : itemOfArray.value_of_item
          }
          onChange={e => {
            const value = handleChangeMoneyInput(e.target.value);
            if (!!value || value === "") {
              array[index].value_of_item = value;
              this.setState({
                arrayOfItems: [...array],
              });
            }
          }}
        />
      </Layout>
    );
  };

  handleRowClick = index => {
    const {
      store: { activityHistory, activitiesBetweenDates },
    } = this.props;
    const selectedActivity = activitiesBetweenDates.data[index];
    this.props.changeActiveId(selectedActivity.activity_type_id, selectedActivity.activity_id);

    this.props.store.activityStartVomInfo.onChangeDate(selectedActivity.recording_date);
    this.props.store.activityStartVomInfo.onActivityIsSelectedClick();
  };

  getSumOfBill = array => {
    let sumOfBill = 0;
    array.forEach(element => {
      sumOfBill += element.is_item_billable ? getMoneyNumber(element.value_of_item) : Number(0);
    });
    return sumOfBill;
  };
  sendRequestForActivitiesBetweenDates = async (toDate, date) => {
    const { activity_from_date, activity_to_date } = this.state;
    const { activitiesBetweenDates, clientStore } = this.props.store;
    if (toDate) {
      if (!activity_from_date || !date) return;
      try {
        await activitiesBetweenDates.fetch({
          clientId: clientStore.selectedClientId,
          from: activity_from_date,
          to: date,
        });
      } catch (e) {
        //
      }
    } else if (!toDate) {
      if (!activity_to_date || !date) return;
      try {
        await activitiesBetweenDates.fetch({
          clientId: clientStore.selectedClientId,
          from: date,
          to: activity_to_date,
        });
      } catch (e) {
        //
      }
    }
    averageCalculatedSums(activitiesBetweenDates.data);
    this.forceUpdate();
  };
}

export default compose(
  inject("store"),
  observer,
)(Settlement);

const sumList = [
  {
    icon: "schedule",
    title: "Summe erfasster Minuten (gesamt)",
    amount: "8.75",
  },
  {
    icon: "chrome_reader_mode",
    title: "Summe erfasster Minuten (Aufgabe)",
    amount: "5",
  },
  {
    icon: "supervisor_account",
    title: "Summe erfasster Minuten (Besprechung)",
    amount: "1",
  },
  {
    icon: "contact_mail",
    title: "Summe erfasster Minuten (Korrespondenz)",
    amount: "0.5",
  },
  {
    icon: "search",
    title: "Summe erfasster Minuten (Recherche)",
    amount: "2",
  },
  {
    icon: "euro_symbol",
    title: "Summe erfasster Minuten (Abrechnung)",
    amount: "0.25",
  },
  {
    icon: "done_all",
    title: "Summe erfasster Minuten (Abrechenbar)",
    amount: "8",
  },
  {
    icon: "remove_circle",
    title: "Summe erfasster Minuten (Nicht Abrechenbar)",
    amount: "0.75",
  },
];

const previousHeader = [
  {
    key: "1",
    title: "Datum",
    scale: 1,
  },
  {
    key: "2",
    title: "Rechnungsnummer",
    scale: 1,
  },
  {
    key: "3",
    title: "Summe",
    scale: 1,
  },
];

const previousRows = {
  "0": [
    {
      key: "foo_1",
      type: "text",
      data: "02.03.2019",
    },
    {
      key: "foo_2",
      type: "text",
      data: "AOSJFLOd6564",
    },
    {
      key: "foo_3",
      type: "text",
      data: "300  €",
    },
  ],
  "1": [
    {
      key: "foo_1x",
      type: "text",
      data: "20.05.2019",
    },
    {
      key: "foo_2x",
      type: "text",
      data: "LOJWLFK6415",
    },
    {
      key: "foo_3x",
      type: "text",
      data: "500  €",
    },
  ],
};

const previousData = {
  constructedTable: {
    header: previousHeader,
    rows: previousRows1,
  },
};

function averageCalculatedSums(dataToCalculateFrom) {
  sumList.forEach(element => {
    element.amount = 0;
  });
  let sums = [];
  for (let i = 0; i < 8; i++) {
    sums.push(0);
  }
  dataToCalculateFrom &&
    dataToCalculateFrom.forEach(element => {
      sums[0] += Number(element.recording_duration);
      sums[0] = Number(Number(sums[0]).toFixed(2));
      if (element.activity_type_id == "appointment") {
        sums[1] += Number(element.recording_duration);
        sums[1] = Number(Number(sums[1]).toFixed(2));
      }
      if (element.activity_type_id == "review") {
        sums[2] += Number(element.recording_duration);
        sums[2] = Number(Number(sums[2]).toFixed(2));
      }
      if (element.activity_type_id == "correspondence") {
        sums[3] += Number(element.recording_duration);
        sums[3] = Number(Number(sums[3]).toFixed(2));
      }
      if (element.activity_type_id == "research") {
        sums[4] += Number(element.recording_duration);
        sums[4] = Number(Number(sums[4]).toFixed(2));
      }
      if (element.activity_type_id == "settlement_activity") {
        sums[5] += Number(element.recording_duration);
        sums[5] = Number(Number(sums[5]).toFixed(2));
      }
      if (element.is_billable) {
        sums[6] += Number(element.recording_duration);
        sums[6] = Number(Number(sums[6]).toFixed(2));
      }
      if (!element.is_billable) {
        sums[7] += Number(element.recording_duration);
        sums[7] = Number(Number(sums[7]).toFixed(2));
      }
    });
  let counter = 0;
  sumList.forEach(element => {
    element.amount = (sums[counter] ? sums[counter] : 0).toLocaleString("de-DE", { minimumFractionDigits: 2 });
    counter++;
  });
}
