/* eslint-disable indent */
import _ from "lodash";
import React from "react";
import qs from "qs";
import { Layout, Icon } from "../../styled";
import { AssetTableContainer, AssetTableItem, AssetTableHeaderItem, AssetTableInput } from "../../styled/assets-table";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AssetTableRow } from "../../styled/assets-table";
import Select from "../Form/Select";
import TableFile from "../TableFile";
import { FilesContainer } from "../../styled/assets-table";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import CreateNewBeneficiary from "../Modal/CreateNewBeneficiary";
import { Button } from "../../styled/buttons";
import { FileUploadButton } from "../../styled/assets-table";
import { AssetUploadButton } from "../Form/UploadButton";
import config from "../../lib/config";
import Axios from "axios";
import { adaptOption } from "./Distributed";
import { COLORS } from "../../styled/constants";
import { autorun } from "mobx";
import DownloadingDiv from "../Form/Loader";

class AssetsEditList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetBeneficiaries: {},
      assetCategoryBeneficiaries: {},
      closedRows: {},
    };
  }
  async componentDidMount() {
    const {
      store: { assetEdit, clientStore, personsListing },
      // client
    } = this.props;
    await personsListing.fetch({
      clientId: clientStore.selectedClientId,
    });
    assetEdit.fetch({
      clientId: clientStore.selectedClientId,
      revisionId: assetEdit.REVISION_ID,
    });
  }
  handleChangeAssetStatus = asset => status => {
    const {
      store: { assetEdit, assetModifier, clientStore },
    } = this.props;

    assetModifier
      .fetch({
        assetId: asset.assetId,
        status,
      })
      .then(() => {
        assetEdit.fetch({
          clientId: clientStore.selectedClientId,
          revisionId: assetEdit.REVISION_ID,
        });
      });
  };
  handleChangeAssetDetermination = asset => resolution => {
    const {
      store: { assetEdit, assetModifier, clientStore },
    } = this.props;

    assetModifier
      .fetch({
        assetId: asset.assetId,
        resolution,
      })
      .then(() => {
        assetEdit.fetch({
          clientId: clientStore.selectedClientId,
          revisionId: assetEdit.REVISION_ID,
        });
      });
  };
  handleChangeAssetCategory = assetCategory => body => {
    const {
      store: { assetEdit, assetCategoryModifier, clientStore },
    } = this.props;

    assetCategoryModifier
      .fetch({
        assetCategoryId: assetCategory.asset_category_id,
        ...body,
      })
      .then(() => {
        assetEdit.fetch({
          clientId: clientStore.selectedClientId,
          revisionId: assetEdit.REVISION_ID,
        });
      });
  };
  handleChangeAssetBeneficiary = (asset, oldValue) => (beneficiary, beneficiary_index) => {
    const {
      store: { assetEdit, assetModifier, clientStore, personsListing },
    } = this.props;

    let oldBeneficiaries = _.get(asset, "beneficiary", []);

    oldBeneficiaries = oldBeneficiaries.filter(v => {
      return v !== oldValue;
    });

    const beneficiaryId = personsListing.data[beneficiary_index].person_id;

    const beneficiaries = _.uniq([...oldBeneficiaries, beneficiaryId]);

    assetModifier
      .fetch({
        assetId: asset.assetId,
        beneficiary: beneficiaries.join(","),
      })
      .then(data => {
        this.setState({ assetBeneficiaries: {} });
        assetEdit.editBeneficiary({
          assetId: asset.assetId,
          beneficiary: beneficiaries.join(","),
        });
      });
  };

  addNewBeneficiary = (asset, type) => () => {
    const {
      store: { assetEdit, clientStore },
    } = this.props;
    const newState = {
      [type]: {
        [asset.assetId]: ["new_beneficiary"],
      },
    };

    this.setState({ [type]: newState });

    assetEdit.fetch({
      clientId: clientStore.selectedClientId,
      revisionId: assetEdit.REVISION_ID,
    });
  };

  handleFileChange = assetId => async e => {
    const {
      store: { assetEdit, clientStore },
      // client
    } = this.props;

    const files = Array.from(e.target.files);
    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append(i, file);
    });

    // formData.append

    const fullUri = `${config.endpoints.api}/api/assets/asset/${assetId}/${clientStore.selectedClientId}/file`;
    const requestOptions = {
      method: "PUT",
      data: formData,
    };

    requestOptions.url = fullUri;
    const token = window.localStorage.getItem(config.keys.local_storage_key);
    if (token) {
      requestOptions.headers = {
        Authorization: `${token}`,
      };
    }

    await Axios(requestOptions);
    assetEdit.fetch({
      clientId: clientStore.selectedClientId,
      revisionId: assetEdit.REVISION_ID,
    });
  };

  handleDeleteFile = file => async event => {
    event.preventDefault();
    const {
      store: { deleteAssetFile, assetEdit, clientStore },
    } = this.props;
    const response = window.confirm("Are you sure");
    if (response) {
      await deleteAssetFile.fetch({
        fileId: file.file_id,
      });
      assetEdit.fetch({
        clientId: clientStore.selectedClientId,
        revisionId: assetEdit.REVISION_ID,
      });
    }
  };

  getPersonName = beneficiaryId => {
    const {
      store: { personsListing },
    } = this.props;

    const person = personsListing.data.find(item => item.person_id === beneficiaryId);

    return !person ? "" : `${person.first_name || ""} ${person.last_name || ""}`;
  };

  removeBeneficiary = (asset, beneficiaryId) => e => {
    const {
      store: { assetEdit, assetModifier, clientStore },
    } = this.props;
    if (asset.beneficiary.length === 0) {
      return this.setState({ assetBeneficiaries: {} });
    }
    const beneficiaries = _.get(asset, "beneficiary", []);
    const filtered = beneficiaries.filter(b => b !== beneficiaryId);

    assetModifier
      .fetch({
        assetId: asset.assetId,
        beneficiary: filtered.join(","),
      })
      .then(() => {
        assetEdit.fetch({
          clientId: clientStore.selectedClientId,
          revisionId: assetEdit.REVISION_ID,
        });
      });
  };

  removeCategoryBeneficiary = (assetCategory, beneficiaryId) => e => {
    const {
      store: { assetEdit, assetCategoryModifier, clientStore },
    } = this.props;
    if (assetCategory.beneficiary.length === 0) {
      return this.setState({ assetBeneficiaries: {} });
    }
    const beneficiaries = _.get(assetCategory, "beneficiary", []);
    const filtered = beneficiaries.filter(b => b !== beneficiaryId);

    assetCategoryModifier
      .fetch({
        assetCategoryId: assetCategory.asset_category_id,
        beneficiary: filtered.join(","),
      })
      .then(() => {
        assetEdit.fetch({
          clientId: clientStore.selectedClientId,
          revisionId: assetEdit.REVISION_ID,
        });
      });
  };

  closeRow = categoryId => () => {
    this.setState(state => ({
      closedRows: {
        ...state.closedRows,
        [categoryId]: !state.closedRows[categoryId],
      },
    }));
  };

  moreThanTwoDecimals = number => {
    if (number.includes(",")) {
      const numberArray = number.split(",");
      if (numberArray[numberArray.length - 1].length > 2) return true;
    }

    return false;
  };

  onChangeFieldValue = (row, field, index, title) => event => {
    event.preventDefault();

    const {
      store: { assetEdit },
    } = this.props;

    const realNumber = Number(
      event.target.value
        .replace(/[^0-9,.]/g, "")
        .replace(/\./g, "")
        .replace(",", "."),
    );
    // const value = event.target.value.replace(//,)
    if (event.target.value === ",") {
      event.target.value = "0,";
    }
    if (event.target.value.split(",").length > 2) {
      return;
    }
    if (!/[^0-9,.]/g.test(event.target.value)) {
      if (!this.moreThanTwoDecimals(event.target.value)) {
        if (field === "asset_value") {
          this.setState({
            ["asset_value_" + title + index]:
              event.target.value !== "" &&
              event.target.value[event.target.value.length - 1] !== "," &&
              !(event.target.value.includes(",") && event.target.value[event.target.value.length - 1] === "0")
                ? realNumber.toLocaleString("de-DE", { minimumFractionDigits: 2 })
                : event.target.value,
          });
        } else {
          this.setState({
            ["asset_value_sold_" + title + index]:
              event.target.value !== "" &&
              event.target.value[event.target.value.length - 1] !== "," &&
              !(event.target.value.includes(",") && event.target.value[event.target.value.length - 1] === "0")
                ? realNumber.toLocaleString("de-DE", { minimumFractionDigits: 2 })
                : event.target.value,
          });
        }

        const finalString = realNumber;
        const finalValue = Number(finalString);

        assetEdit.onChangeFieldValue(row, field)({ target: { value: finalValue } });
      }
    }
  };

  render() {
    const {
      store: { assetEdit, personsListing, sidebarOpen },
    } = this.props;

    if ((assetEdit.loading && !assetEdit.loaded) || !assetEdit.constructed) {
      return (
        <Layout style={{ marginTop: "20px" }}>
          <DownloadingDiv />
        </Layout>
      );
    }

    if (assetEdit.data && assetEdit.data.assets.length === 0) {
      return null;
    }

    const { assetBeneficiaries, assetCategoryBeneficiaries, closedRows } = this.state;

    const isWindows = config.isWindows;

    return (
      <PerfectScrollbar>
        <AssetTableContainer
          mobile={
            sidebarOpen
              ? `max-width: calc(100vw - ${468 + (isWindows ? 19 : 2)}px)`
              : `max-width: calc(100vw - ${240 + (isWindows ? 19 : 2)}px)`
          }
        >
          <AssetTableRow>
            {assetEdit.constructed.header.map(({ title, ...rest }) => (
              <AssetTableHeaderItem {...rest}>{title}</AssetTableHeaderItem>
            ))}
          </AssetTableRow>
          {assetEdit.constructed.sections.map((section, sectionIndex) => (
            <React.Fragment>
              <AssetTableRow heading>
                <AssetTableItem heading scale={section.scale}>
                  <Icon type="material-icons" onClick={this.closeRow(section.title)}>
                    {!closedRows[section.title] ? "indeterminate_check_box" : "add_to_photos"}
                  </Icon>
                  {`${sectionIndex + 1} ${section.title}`}
                </AssetTableItem>
                <AssetTableItem heading align="end" scale={0.08}>
                  {Number(section.value || 0).toLocaleString("de-DE", { minimumFractionDigits: 2 })}
                </AssetTableItem>
                {section.blanks.map(blank => (
                  <AssetTableItem heading scale={blank} />
                ))}
                <AssetTableItem heading align="end" scale={0.1}>
                  {Number(section.valueSold || 0).toLocaleString("de-DE", { minimumFractionDigits: 2 })}
                </AssetTableItem>
                <AssetTableItem heading align="end" scale={0.05} />
              </AssetTableRow>
              {section.categories.map((cat, catIndex) => {
                if (cat.rows.length > 0) {
                  let catBeneficiary = [
                    ...cat.beneficiary,
                    ..._.get(assetCategoryBeneficiaries, `assetCategoryBeneficiaries.${cat.asset_category_id}`, []),
                  ];
                  if (catBeneficiary.length === 0) {
                    catBeneficiary = [""];
                  }
                  return (
                    <React.Fragment>
                      <AssetTableRow closed={closedRows[section.title]}>
                        <AssetTableItem scale={cat.scales[0]}>
                          <Icon type="material-icons" onClick={this.closeRow(cat.asset_category_id)}>
                            {!closedRows[cat.asset_category_id] ? "indeterminate_check_box" : "add_to_photos"}
                          </Icon>
                          {catIndex + 1}
                        </AssetTableItem>
                        <AssetTableItem scale={cat.scales[1]}>{cat.title}</AssetTableItem>
                        <AssetTableItem scale={cat.scales[2]} />
                        <AssetTableItem heading align="end" scale={cat.scales[3]}>
                          {Number(cat.value || 0).toLocaleString("de-DE", { minimumFractionDigits: 2 })}
                        </AssetTableItem>
                        <AssetTableItem heading scale={cat.scales[4]} />
                        <AssetTableItem heading scale={cat.scales[5]} />
                        <AssetTableItem heading scale={cat.scales[6]} />
                        <AssetTableItem heading align="end" scale={cat.scales[7]}>
                          {Number(cat.value_sold || 0).toLocaleString("de-DE", { minimumFractionDigits: 2 })}
                        </AssetTableItem>
                        <AssetTableItem heading align="end" scale={cat.scales[8]} />
                      </AssetTableRow>
                      {cat.rows.map((row, rowIndex) => (
                        <AssetTableRow closed={closedRows[section.title] || closedRows[cat.asset_category_id]}>
                          <AssetTableItem vAlign="top" align="end" scale={cat.scales[0]}>
                            {`${catIndex + 1}.${rowIndex + 1}`}
                          </AssetTableItem>
                          <AssetTableItem vAlign="top" small scale={cat.scales[1]}>
                            {row.title}
                          </AssetTableItem>
                          <AssetTableItem vAlign="top" scale={cat.scales[2]}>
                            {row.frozen ? (
                              <Layout fullWidth displayFlex alignEnd justifyEnd>
                                {row.fields && row.fields.inputs && (
                                  <Layout fullWidth offsetBottom={20}>
                                    {row.fields && row.fields.inputs && (
                                      <Layout fullWidth offsetBottom={20}>
                                        {row.fields.inputs.map(input => (
                                          <Layout>{`${input.placeholder}: ${input.value}`}</Layout>
                                        ))}
                                      </Layout>
                                    )}
                                  </Layout>
                                )}
                                {row.fields.files && (
                                  <Layout fullWidth>
                                    {row.fields.files.length > 0 && <Layout offsetBottom={10}>hochgeladene Dateien</Layout>}
                                    <Layout>
                                      <FilesContainer>
                                        {row.fields.files.map(file => (
                                          <TableFile download={file.title} href={`${file.src}`} target="_blank" title={file.title} />
                                        ))}
                                      </FilesContainer>
                                    </Layout>
                                  </Layout>
                                )}
                              </Layout>
                            ) : (
                              <Layout displayFlex column fullWidth>
                                <Layout fullWidth displayFlex alignEnd justifyEnd>
                                  <AssetUploadButton label="Datei hochladen" onChange={this.handleFileChange(row.assetId)} />
                                </Layout>
                                {row.fields && row.fields.inputs && (
                                  <Layout fullWidth offsetBottom={20}>
                                    {row.fields.inputs.map(input => (
                                      <AssetTableInput
                                        onChange={assetEdit.onChangeInputValue(input)}
                                        value={input.value}
                                        name={input.name}
                                        placeholder={input.placeholder}
                                      />
                                    ))}
                                  </Layout>
                                )}
                                {row.fields.files && (
                                  <Layout fullWidth>
                                    {row.fields.files.length > 0 && <Layout offsetBottom={10}>hochgeladene Dateien</Layout>}
                                    <Layout>
                                      <FilesContainer>
                                        {row.fields.files.map(file => (
                                          <TableFile
                                            download={file.title}
                                            href={`${file.src}`}
                                            target="_blank"
                                            title={file.title}
                                            onClick={this.handleDeleteFile(file)}
                                          />
                                        ))}
                                      </FilesContainer>
                                    </Layout>
                                  </Layout>
                                )}
                              </Layout>
                            )}
                          </AssetTableItem>
                          <AssetTableItem vAlign="top" align="end" scale={cat.scales[3]}>
                            {row.frozen ? (
                              Number(row.value).toLocaleString("de-DE", { minimumFractionDigits: 2 })
                            ) : (
                              <AssetTableInput
                                onChange={this.onChangeFieldValue(row, "asset_value", rowIndex, row.title)}
                                value={
                                  this.state["asset_value_" + row.title + rowIndex] !== undefined
                                    ? this.state["asset_value_" + row.title + rowIndex]
                                    : Number(row.value) === 0
                                    ? ""
                                    : (typeof row.value === "string" ? Number(row.value) : row.value).toLocaleString("de-DE", {
                                        minimumFractionDigits: 2,
                                      })
                                }
                                placeholder={Number(row.value).toLocaleString("de-DE", { minimumFractionDigits: 2 })}
                                name={"asset_value"}
                                assetInput
                              />
                            )}
                          </AssetTableItem>
                          <AssetTableItem vAlign="top" scale={cat.scales[4]}>
                            {row.frozen ? (
                              adaptOption(row.determination)
                            ) : (
                              <Select
                                placeholder={row.determination_placeholder}
                                value={row.determination}
                                options={row.determination_options}
                                onChange={this.handleChangeAssetDetermination(row)}
                              />
                            )}
                          </AssetTableItem>
                          <AssetTableItem vAlign="top" scale={cat.scales[5]}>
                            {row.frozen ? (
                              adaptOption(row.status)
                            ) : (
                              <Select
                                onChange={this.handleChangeAssetStatus(row)}
                                placeholder={row.status_placeholder ? row.status_placeholder : "Status"}
                                value={row.status}
                                options={row.status_options}
                              />
                            )}
                          </AssetTableItem>
                          <AssetTableItem vAlign="top" scale={cat.scales[6]}>
                            {row.frozen ? (
                              (row.beneficiary || []).map(b => (
                                <React.Fragment>
                                  {this.getPersonName(b)}
                                  <br />
                                </React.Fragment>
                              ))
                            ) : (
                              <Layout fullWidth displayFlex column>
                                {personsListing.data &&
                                  personsListing.data.length > 0 &&
                                  ([...row.beneficiary, ..._.get(assetBeneficiaries, `assetBeneficiaries.${row.assetId}`, [])] || []).map(
                                    b => (
                                      <React.Fragment>
                                        <Layout key={b} displayFlex alignCenter justifyCenter row>
                                          <Select
                                            noAdapter
                                            placeholder={row.beneficiary_placeholder ? row.beneficiary_placeholder : "Begünstigter"}
                                            value={b}
                                            customAdapter={this.getPersonName}
                                            skip={row.beneficiary}
                                            options={row.beneficiary_options}
                                            onChange={this.handleChangeAssetBeneficiary(row, b)}
                                          />
                                          {b && (
                                            <Icon
                                              onClick={this.removeBeneficiary(row, b)}
                                              style={{
                                                marginRight: 0,
                                                marginBottom: 10,
                                                marginLeft: 10,
                                              }}
                                            >
                                              close
                                            </Icon>
                                          )}
                                        </Layout>
                                        {!!b && b !== "new_beneficiary" && (
                                          <AssetTableInput
                                            value={assetEdit.getBeneficiaryAmount(row.assetId, b)}
                                            onChange={assetEdit.onChangeBeneficiaryAmount(row, b)}
                                            placeholder="0"
                                          />
                                        )}
                                      </React.Fragment>
                                    ),
                                  )}

                                {row.beneficiary_options.length >
                                  row.beneficiary.length + _.get(assetBeneficiaries, `assetBeneficiaries.${row.assetId}`, []).length && (
                                  <Layout displayFlex fullWidth spacingTop={10} alignCenter justifyCenter>
                                    <Icon onClick={this.addNewBeneficiary(row, "assetBeneficiaries")} type="material-icons">
                                      person_add
                                    </Icon>
                                  </Layout>
                                )}
                              </Layout>
                            )}
                          </AssetTableItem>
                          <AssetTableItem vAlign="top" align="end" scale={cat.scales[7]}>
                            {row.frozen ? (
                              Number(row.valueSold).toLocaleString("de-DE", { minimumFractionDigits: 2 })
                            ) : (
                              <AssetTableInput
                                onChange={this.onChangeFieldValue(row, "asset_value_sold", rowIndex, row.title)}
                                value={
                                  this.state["asset_value_sold_" + row.title + rowIndex] !== undefined
                                    ? this.state["asset_value_sold_" + row.title + rowIndex]
                                    : Number(row.valueSold) === 0
                                    ? ""
                                    : (typeof row.valueSold === "string" ? Number(row.valueSold) : row.valueSold).toLocaleString("de-DE", {
                                        minimumFractionDigits: 2,
                                      })
                                }
                                placeholder={Number(row.valueSold).toLocaleString("de-DE", { minimumFractionDigits: 2 })}
                                // onChange={assetEdit.onChangeFieldValue(row, "asset_value_sold")}
                                // value={Number(row.valueSold) === 0 ? "" : Number(row.valueSold).toLocaleString('de-DE')}
                                // placeholder={Number(row.valueSold).toLocaleString('de-DE')}
                                name={row.id}
                                assetInput
                              />
                            )}
                          </AssetTableItem>
                          <AssetTableItem vAlign="top" align="end" scale={cat.scales[8]}>
                            <i
                              style={{ color: "#9fa6ad", cursor: "pointer" }}
                              onClick={assetEdit.onDeleteAsset(row, catIndex, rowIndex)}
                              class="material-icons"
                            >
                              delete
                            </i>
                          </AssetTableItem>
                        </AssetTableRow>
                      ))}
                    </React.Fragment>
                  );
                }
              })}
            </React.Fragment>
          ))}
        </AssetTableContainer>
      </PerfectScrollbar>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(AssetsEditList);
