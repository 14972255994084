import fileDownload from "js-file-download";
import Axios from "axios";
import config from "../../lib/config";

const PERSONS = [];

export const policyOrderAdapter = {
  policy_name: {
    key: "row_1",
    order: 0,
    scale: 4,
    title: "Police",
  },
  policy_notes: {
    key: "row_2",
    order: 1,
    scale: 4,
    title: "Notizen",
  },
  policy_value: {
    key: "row_3",
    order: 2,
    scale: 4,
    title: "Wert zuordnen",
  },
  file_path: {
    key: "row_4",
    order: 3,
    scale: 1,
    title: "Dokument zuordnen",
  },
  is_asset_directory: {
    key: "row_5",
    order: 4,
    scale: 1,
    title: "Bestandteil Vermögensverzeichnis",
  },
  beneficiary_id: {
    key: "row_6",
    order: 5,
    scale: 1,
    title: "Begünstigter",
  },
};

export const policyHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

const updateBeneficiary = async (beneficiary, store, index, policyId) => {
  const { getPolicy, updatePolicy, assetModifier } = store;

  const clientId = store.client.data.client_id;

  await getPolicy
    .fetch({
      policyId: policyId,
    })
    .then(async res => {
      if (res.data && res.data.data) {
        const data = {
          policy_name: res.data.data.policy_name,
          policy_notes: res.data.data.policy_notes,
          policy_value: res.data.data.policy_value,
          client_id: res.data.data.client_id,
          file_path: res.data.data.file_path,
          is_asset_directory: res.data.data.is_asset_directory,
          policy_document: res.data.data.policy_document,
          file_path: res.data.data.file_path,
          beneficiary_id: beneficiary.beneficiary_id,
        };
        await updatePolicy.fetch({
          ...data,
          policy_id: policyId,
        });

        if (res.data && res.data.data && res.data.data.asset_id) {
          assetModifier.fetch({
            assetId: res.data.data.asset_id,
            beneficiary: beneficiary.beneficiary_id,
          });
        }
        store.getPolicies.fetch({
          clientId: clientId,
        });
      }
    });
};

const deleteFile = async (e, store, index, policyId) => {
  e.preventDefault();
  const { deletePolicyFile, getPolicies } = store;
  const clientId = store.client.data.client_id;

  const response = window.confirm("Are you sure");
  if (response) {
    await deletePolicyFile.fetch({
      policyId: policyId,
    });

    getPolicies.fetch({
      clientId: clientId,
    });
  }
};

const uploadDocument = async (e, store, index, file, upload, policyId) => {
  const { getPolicy } = store;

  const clientId = store.client.data.client_id;
  const token = window.localStorage.getItem(config.keys.local_storage_key);

  await getPolicy
    .fetch({
      policyId: policyId,
    })
    .then(async res => {
      if (res.data && res.data.data) {
        let body = new FormData();
        body.set("update_file", true);
        body.set("policy_id", policyId);
        body.set("client_id", res.data.data.client_id);
        body.append("policy_document", file);

        Axios({
          method: "PUT",
          url: config.endpoints.api + "/api/policy/update",
          data: body,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,
          },
        }).then(async () => {
          store.getPolicies.fetch({
            clientId: clientId,
          });
        });
      }
    });
};

const getFileName = filePath => {
  if (filePath === null) {
    return "";
  }
  const fileArray = filePath.split("/");

  return fileArray[fileArray.length - 1];
};

const isAsset = async (event, store, index, policyId, assetId) => {
  const { updatePolicy, deleteAsset } = store;
  const clientId = store.client.data.client_id;

  if (assetId === null) {
    await updatePolicy
      .fetch({
        create_asset: "true",
        client_id: clientId,
        policy_id: policyId,
      })
      .then(async () => {
        store.getPolicies.fetch({
          clientId: clientId,
        });
      });
  } else {
    await deleteAsset.fetch({
      assetId: assetId,
    });

    await updatePolicy
      .fetch({
        is_asset: null,
        policy_id: policyId,
        client_id: clientId,
      })
      .then(async () => {
        store.getPolicies.fetch({
          clientId: clientId,
        });
      });
  }
};

export const policyRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          case "id":
            break;
          case "is_asset_directory":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "checkbox",
              action: (store, index) => e => isAsset(e, store, index, item.policy_id, item.asset_id),
              checked: item[itemKey],
            };
            break;
          case "file_path": {
            const fileName = getFileName(item[itemKey]);
            if (item[itemKey] === "null") {
              row[orderItem.order] = {
                key: `foo_${itemIndex}`,
                type: "upload",
                active: "add_circle",
                action: (store, index, file, upload) => e => uploadDocument(e, store, index, file, upload, item.policy_id),
                data: item[itemKey],
              };
              break;
            } else {
              row[orderItem.order] = {
                key: `foo_${itemIndex}`,
                type: "file-tag",
                name: fileName,
                file_path: config.endpoints.api + "/" + item.file_path,
                title: fileName,
                download: config.endpoints.api + "/" + item.file_path,
                src: config.endpoints.api + "/" + item.file_path,
                deleteFile: (store, index) => e => deleteFile(e, store, index, item.policy_id),
              };
              break;
            }
          }
          case "beneficiary_id": {
            row[orderItem.order] = {
              key: `id_${index}`,
              type: "select-persons",
              beneficiaryId: item[itemKey],
              action: (store, index) => e => updateBeneficiary(e, store, index, item.policy_id),
              disabled: true,
              options: PERSONS.map(p => p.person_id),
              value: item[itemKey],
              noAdapter: true,
            };
            break;
          }
          case "policy_value": {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey] ? item[itemKey].toLocaleString("de-DE", { minimumFractionDigits: 2 }) : item[itemKey],
            };
            break;
          }
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          }
        }
      });
      rows[index] = row;
    });
  }
  return rows;
};
