import styled from "styled-components";
import { COLORS, FONTWEIGHT } from "./constants";

export const CreateNewUserOuter = styled.div`
  width: 813px;
  padding: 0 55px;
  margin-bottom: 30px;
  div {
    padding-top: 0;
    border-right: none;
    @media only screen and (max-width: 600px) {
      flex-flow: column;
    }
  }
  > div {
    > div {
      & + div {
        margin-left: 40px;
        label {
          width: 180px;
          font-size: 1rem;
          padding-bottom: 18px;
          white-space: nowrap;
          color: ${COLORS.GREYISH_BROWN_TWO};
          font-weight: ${FONTWEIGHT.FW_6};
        }
        @media only screen and (max-width: 600px) {
          margin-left: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 900px) {
    width: 700px;
    padding: 0 25px;
  }
  @media only screen and (max-width: 770px) {
    width: 600px;
    padding: 0 25px;
  }
  @media only screen and (max-width: 600px) {
    width: 400px;
    padding: 0 15px;
  }
`;
export const FormFieldItems = styled.div`
  width: 100%;
  padding-bottom: 30px;
  @media only screen and (max-width: 900px) {
    padding-bottom: 15px;
  }
  label {
    color: ${COLORS.GREYISH_BROWN_TWO};
    font-size: 1rem;
    width: 180px;
    padding-bottom: 18px;
    white-space: nowrap;
    font-weight: ${FONTWEIGHT.FW_6};
    @media only screen and (max-width: 900px) {
      padding-bottom: 10px;
    }
  }
  input {
    max-width: 411px;
    padding: 6px 11px;
    height: 43px;
  }
  select {
    padding: 0 17px;
  }
  .selectCustomStyle {
    height: 43px;
    > div {
      box-shadow: none;
      border: none;
      > div {
        &:first-child {
          max-width: 411px;
          padding: 6px 11px;
          height: 43px;
          border-radius: 5px;
          border: solid 2px rgb(214, 214, 214);
          span {
            font-weight: 400;
            font-size: 15px;
            color: #999;
          }
        }

        i {
          top: 5px;
        }
      }
    }
  }
`;
export const ModalActions = styled.div`
  width: 100%;
  margin-top: 20px;
  @media only screen and (max-width: 900px) {
    margin-top: 10px;
  }
  button {
    min-width: 154px;
    height: 49px;
    @media only screen and (max-width: 900px) {
      min-width: 100px;
      margin-right: 5px;
      padding: 7px 8px;
      font-size: 13px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;
export const IndividualClientAccessOuter = styled.div`
  width: 100%;
  padding: 30px 0;
  p {
    text-align: center;
    padding-bottom: 20px;
  }
  @media only screen and (max-width: 900px) {
    padding: 15px 0;
  }
`;
export const ModalFooterOuter = styled.div`
  background: transparent;
  padding: 42px 28px;
  text-align: right;
  width: 100%;
  button {
    min-width: 154px;
    height: 49px;
  }
  @media only screen and (max-width: 800px) {
    padding: 15px;
    button {
      min-width: 154px;
      height: 49px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    button {
      min-width: 154px;
      height: 49px;
      margin-right: 0;
    }
  }
`;
export const FormWrapper = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  > div {
    margin-bottom: 0;
    > div {
      margin-bottom: 21px;
    }
  }
  > div {
    > div {
      display: flex;
      flex-flow: column;
      width: 100%;
      align-items: flex-start;
      label {
        width: 100%;
        font-weight: 600;
        color: rgb(62, 62, 62);
        font-size: 1rem;
        padding-bottom: 18px;
        text-align: left;
        max-width: 100%;
        line-height: 18px;
        & + div {
          width: 100%;
        }
      }
    }
  }
`;
