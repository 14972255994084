import styled from "styled-components";
import { COLORS } from "./constants";

export const TabContainer = styled.div`
  @media only screen and (max-width: 768px) {
    overflow-y: scroll;
    z-index: 0;
    //   -ms-overflow-style: none;  // IE 10+
    //   scrollbar-width: none;  // Firefox
    //   ::-webkit-scrollbar {
    //     display: none;  // Safari and Chrome
    // }
    height: 80px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  border-bottom: 1px solid #cfcfcf;
  position: relative;
`;

export const Tab = styled.div`
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: 0.2px;
  text-align: left;
  transition: all 0.3s ease;
  height: 54px;
  color: ${COLORS.GREYISH_BROWN_TWO};
  padding: 0 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: ${COLORS.TURTLE_GREEN};
  }
  ${props =>
    props.selected &&
    `
    color: ${COLORS.LEAF};
  `}
`;

export const TabSlider = styled.div`
  height: 5px;
  background-color: ${COLORS.TURTLE_GREEN};
  position: absolute;
  bottom: -1px;
  transition: all 0.3s ease;
  left: ${props => props.left || 0}px;
  width: ${props => props.width || 0}px;
`;
