export const COLORS = {
  BLACK_13: "rgba(0, 0, 0, 0.13)",
  BLACK_TWELVE: "rgba(0,0,0,0.13)",
  CHARCOAL: "rgb(68, 75, 59)",
  COOL_GREY_TWO: "rgb(159, 166, 173)",
  COOL_GREY: "rgb(167, 172, 177)",
  DARK_SAGE_FIVE: "rgb(101,114,83)",
  DARK_SAGE_FOUR: "rgb(95,101,86)",
  DARK_SAGE_THREE: "rgb(78,83,72)",
  DARK_SAGE: "rgb(87,100,72)",
  GREEN_GREY: "rgb(114, 136, 95)",
  GREEN_GREY_TWO: "#7b8570",
  GREEN_GREY_THREE: "#88927c",
  GREENISH_GREY: "#b8c0ac",
  GREENISH_GREY_TWO: "#b0b8a3",
  GREY: "rgb(152, 157, 145)",
  GREYISH_BROWN_THREE: "#414141",
  GREYISH_BROWN_TWO: "rgb(62, 62, 62)",
  GREYISH_BROWN: "rgb(68, 68, 68)",
  LEAF: "rgb(109, 160, 46)",
  LIGHT_GREY_EIGHT: "rgb(215,220,208)",
  LIGHT_GREY_ELEVEN: "rgb(218,221,214)",
  LIGHT_GREY_FOUR: "rgb(241,241,241)",
  LIGHT_GREY_NINE: "rgb(228,233,221)",
  LIGHT_GREY_TEN: "rgb(222,226,215)",
  LIGHT_GREY_THREE: "rgb(219,224,213)",
  LIGHT_GREY_TWO: "rgb(220, 225, 214)",
  LIGHT_GREY_SIX: "#dfe3d9",
  LIGHT_GREY_SEVEN: "#e3e7dd",
  LIGHT_GREY_TWELVE: "#cdd4c4",
  LIGHT_GREY: "rgb(239, 242, 235)",
  MEDIUM_GREY_THREE: "rgb(94,96,91)",
  MEDIUM_GREY_TWO: "rgb(131,137,124)",
  MEDIUM_GREY: "rgb(115,123,106)",
  NASTY_GREEN_THREE: "#7aa745",
  NASTY_GREEN_TWO: "rgb(118,164,63)",
  NASTY_GREEN: "rgb(127,179,64)",
  OFF_WHITE: "rgb(247,247,247)",
  PINE_FOUR: "#364226",
  PINE_THREE: "rgb(36,42,28)",
  PINE_TWO: "rgb(66, 84, 45)",
  PINE: "rgb(62, 75, 46)",
  SAGE: "rgb(137,164,104)",
  SILVER: "rgb(233,235,236)",
  STEEL_GREY: "rgb(136,142,142)",
  TURTLE_GREEN_TWO: "rgb(134, 186, 71)",
  TURTLE_GREEN: "rgb(139, 191, 76)",
  WARM_GREY_TWO: "#838383",
  WARM_GREY: "rgb(116,116,116)",
  WHITE_FOUR: "rgb(236,236,236)",
  WHITE_THREE: "rgb(230,230,230)",
  WHITE_TWO: "rgb(232, 232, 232)",
  WHITE: "rgb(214, 214, 214)",
  WARM_GREY_FOUR: "rgb(152, 152, 152)",
  PINKISH_ORANGE: "rgb(255,112,67)",
  LICHEN: "rgb(151, 183, 111)",
  WHITE_SIX: "#d3d3d3",
  WHITE_SEVEN: "#dcdcdc",
  GREY_GREEN: "#959f88",
  GUNMETAL_20: "rgba(70, 84, 84, 0.2)",
  GREENISH_GREY_THREE: "rgb(65,65,65)",
  MEDIUM_GREY_FIVE: "rgb(118,125,110)",
  DARK_SAGE_SIX: "rgb(80,90,67)",
  DUSTY_ORANGE: "#f16c42",
  LIGHT_TAN: "#f8d3c7",
  LIGHT_GREY_THIRTEEN: "#e5edda",
  VERY_LIGHT_PINK: "#feeae3",
  GREYISH: "#a8a8a8",
  GREY_TWO: "#909885",
  LIGHT_GREY_FOURTEEN: "#e8ece2",
  LIGHT_GREY_FIFTEEN: "#f5f7f2",
  LIGHT_GREY_SEVENTEEN: "#eff2ec",
  LIGHT_GREY_SIXTEEN: "#e4e7e0",
  WHITE_EIGHT: "#eaeaea",
  TURTLE_GREEN_THREE: "#89bb4d",
  TOMATO: "#ef5b2e",
  LIGHT_GREY_EIGHTEEN: "#f4f7f0",
  AVOCADO_GREEN: "#66a21e",
  WHITE_FIVE: "#f7f7f7",
  MILITARY_GREEN: "rgba(82, 100, 58, 0.11)",
  BLACK: "#2f2f2f",
  LIGHT_GREY_NINETEEN: "#f2f3f0",
  CHARCOAL_TWO: "#2e312b",
  CHARCOAL_THREE: "#2b2e27",
  FULL_WHITE: "#fff",
};

export const P_1 = `
  font-family: Sansation;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${COLORS.CHARCOAL};
  @media only screen and (max-width:900px) {
    font-size:1.1rem;
   }
   @media only screen and (max-width:600px) {
   font-size:1rem;
   }
   @media only screen and (max-width:500px) {
   font-size:0.9rem;
   }
`;
export const P_1_1 = `
  font-family: Sansation;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${COLORS.CHARCOAL};
  @media only screen and (max-width:900px) {
    font-size:1.1rem;
   }
   @media only screen and (max-width:600px) {
   font-size:1rem;
   }
   @media only screen and (max-width:500px) {
   font-size:0.9rem;
   }
`;
export const P_2 = `
  font-family: Sansation;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: 0.2px;
  text-align: left;
  color: #2f2f2f;
   @media only screen and (max-width:600px) {
   font-size:0.8rem;
   }
   @media only screen and (max-width:500px) {
   font-size:0.7rem;
   }
`;

export const P_3 = `
  font-family: Sansation;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${COLORS.PINE_TWO};
   @media only screen and (max-width:600px) {
   font-size:0.8rem;
   }
   @media only screen and (max-width:500px) {
   font-size:0.7rem;
   }
  `;

export const P_3_1 = `
  font-family: Sansation;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: left;
  color: white;
   @media only screen and (max-width:600px) {
   font-size:0.8rem;
   }
   @media only screen and (max-width:500px) {
   font-size:0.7rem;
   }

`;

export const P_4 = `
  font-family: Sansation;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${COLORS.GREYISH_BROWN_TWO}
   @media only screen and (max-width:600px) {
   font-size:0.7rem;
   }
  `;

export const P_4_1 = `
  font-family: Sansation;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${COLORS.GREYISH_BROWN_TWO}
 @media only screen and (max-width:600px) {
   font-size:0.7rem;
   }
  `;

export const P_5 = `
  font-family: Sansation;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: 0.1px;
  text-align: left;
  color: ${COLORS.GREY};
`;
export const P_6 = `
  font-family: Sansation;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: 0.1px;
  text-align: left;
  color: ${COLORS.MEDIUM_GREY_THREE};
`;
export const P_7 = `
  font-family: Sansation;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: 0.1px;
  text-align: left;
  color: ${COLORS.PINE};
  @media only screen and (max-width:900px) {
    font-size:1.1rem;
   }
   @media only screen and (max-width:600px) {
   font-size:1rem;
   }
  //  @media only screen and (max-width:500px) {
  //  font-size:0.8rem;
  //  }
  `;
export const P_8 = `
  font-family: Sansation;
  font-size: 19px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${COLORS.PINE};
@media only screen and (max-width:900px) {
    font-size:1.1rem;
   }
   @media only screen and (max-width:600px) {
   font-size:1rem;
   }
   @media only screen and (max-width:500px) {
   font-size:0.8rem;
   }
  `;
export const P_9 = `
  font-family: Sansation;
  font-size: 23px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${COLORS.GREENISH_GREY_THREE};
  @media only screen and (max-width:900px) {
    font-size:1.1rem;
   }
   @media only screen and (max-width:600px) {
   font-size:1rem;
   }
   @media only screen and (max-width:500px) {
   font-size:0.9rem;
   }
  `;
export const BORDER_RADIUS = {
  BR_1: "5px",
  BR_2: "10px",
  BR_3: "15px",
  BR_4: "20px",
  BR_5: "25px",
  BR_6: "100%",
};

export const LINEHEIGHT = {
  LH_1: "normal",
  LH_2: "35px",
};

export const FONTWEIGHT = {
  FW_1: "100",
  FW_2: "200",
  FW_3: "300",
  FW_4: "400",
  FW_5: "500",
  FW_6: "600",
  FW_7: "700",
  FW_8: "800",
  FW_9: "900",
};

export const BOX_SHADOW = {
  BS_1: "0px 1px 18px 0 rgba(70, 84, 84, 0.2)",
  BS_2: "0px 1px 18px 0 rgba(70, 84, 84, 0.2)",
};
