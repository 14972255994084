import React from "react";
import { P, Layout } from "../../styled";
import {
  ProgressContainer,
  ProgressSliderOuter,
  ProgressBarCompleted,
  ProgressBar,
  ProgressText,
  ProgressHandle,
  ProgressStatusBox,
  ProgressStatusLabel,
  ProgressStatusData,
} from "../../styled/progress";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

const ProgressSlider = ({ completed, possible }) => {
  let safeStatus = 0;

  if (completed > 0 || possible > 0) {
    const status = completed / possible;

    safeStatus = status <= 1 ? status : 0;
  }

  return (
    <ProgressContainer>
      <P fontWeight="bold" offsetBottom={25}>
        Status Vermögensverteilung
      </P>
      <Layout displayFlex row alignCenter justifyCenter rowToColumn={900}>
        <Layout flex={1} fullWidth offsetBottom={20} offsetRight={15}>
          <ProgressSliderOuter>
            <Layout relative width="100%" flex={1}>
              <ProgressBar>
                <ProgressBarCompleted completed={safeStatus} />
                <ProgressHandle completed={safeStatus} />
              </ProgressBar>
            </Layout>
            <ProgressText>{`${(safeStatus * 100).toFixed(0)}%`}</ProgressText>
          </ProgressSliderOuter>
        </Layout>
        <ProgressStatusBox>
          <ProgressStatusLabel>Status Vermögensverteilung</ProgressStatusLabel>
          <ProgressStatusData>{`${completed}/${possible} Einträgen`}</ProgressStatusData>
        </ProgressStatusBox>
      </Layout>
    </ProgressContainer>
  );
};

export default compose(
  inject("store"),
  observer,
)(ProgressSlider);
