import React from "react";
import * as Yup from "yup";
import { Layout } from "../../../styled";
import { PageColumn } from "../../../styled/layout";
import { Button } from "../../../styled/buttons";
import { ModalFooterOuter, ModalContent } from "../../../styled/modal";
import { TextInput } from "../../../styled/form";
import CheckBox from "../../Form/CheckBox";
import { TravelExpensesOuter, FormFieldItems } from "../../../styled/travelexpenses";
import FormInput from "../../Form/Input";
import { handleChangeMoneyInput, getMoneyNumber } from "../../../utils/helper";

const TravelExpensesItems = props => (
  <FormFieldItems>
    <PageColumn>
      <Layout row displayFlex alignCenter>
        <i className="material-icons" style={{ maxWidth: "30px", width: "auto" }}>
          {" "}
          {props.iconname}
        </i>
        <Layout relative spacingLeft={8} fullWidth>
          <FormInput
            onChange={props.onChange}
            placeholder={props.placeholder}
            type="text"
            value={props.inputValue}
            name={props.inputName}
            error={props.error}
            errorOffset={5}
          />
        </Layout>
        <Layout row displayFlex spacingLeft={33} style={{ paddingBottom: "20px" }}>
          <CheckBox onClick={props.onCheckChange} checked={props.checkboxValue} name={props.is_billable_name} />
          <label>{props.label}</label>
        </Layout>
      </Layout>
    </PageColumn>
  </FormFieldItems>
);

class TravelExpenses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      driven_km: "",
      settlement_rate: "",
      is_drive_billable: false,
      overnight_expense: "",
      is_overnight_billable: false,
      rail_cost: "",
      is_rail_billable: false,
      parking_cost: "",
      is_parking_billable: false,
      entertainment_expense: "",
      is_entertainment_billable: false,
      other_expenses: "",
      is_other_expenses_billable: false,
      errors: {
        driven_km: "",
        settlement_rate: "",
        overnight_expense: "",
        rail_cost: "",
        parking_cost: "",
        entertainment_expense: "",
        other_expenses: "",
      },
    };
  }

  componentDidMount() {
    if (this.props.value) {
      const values = { ...this.props.value };
      const x = [
        "driven_km",
        "settlement_rate",
        "overnight_expense",
        "rail_cost",
        "parking_cost",
        "entertainment_expense",
        "other_expenses",
      ];
      x.forEach(t => {
        values[t] = typeof values[t] === "number" ? values[t].toLocaleString("de-DE", { minimumDigitsFractions: 2 }) : values[t];
      });
      this.setState({
        ...values,
      });
    }
    this.clearErrors();
  }

  onChange = (e, isCheckbox = false) => {
    let val = e.target.value;
    if (!isCheckbox) {
      try {
        const realVal = handleChangeMoneyInput(e.target.value);
        if (realVal || realVal === "") {
          val = realVal;
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    }
    this.setState({
      [e.target.name]: val,
    });
  };

  onChangeSR = (e, isCheckbox = false) => {
    let val = e.target.value;
    if (e.target.value[e.target.value.length - 1] == "," && e.target.name === "settlement_rate") {
      val = this.state.settlement_rate + ".";
      if (val.indexOf(".") == e.target.value.length - 1) {
        this.setState({
          [e.target.name]: val,
        });
        return null;
      }
    }
    if (!isCheckbox) {
      try {
        if (val !== "" && isNaN(Number(val))) {
          return null;
        }

        val = val === "" ? null : Number(val);
      } catch (e) {
        return null;
      }
    }
    this.setState({
      [e.target.name]: val,
    });
  };

  onCheckChange = name => () => {
    const value = this.onChange(
      {
        target: {
          name,
          value: !this.state[name],
        },
      },
      true,
    );
  };

  clearErrors = () => {
    this.setState({
      errors: {
        driven_km: "",
        settlement_rate: "",
        overnight_expense: "",
        rail_cost: "",
        parking_cost: "",
        entertainment_expense: "",
        other_expenses: "",
      },
    });
  };

  onSaveClick = () => {
    const values = {
      ...this.state,
    };

    const {
      driven_km,
      settlement_rate,
      is_drive_billable,
      overnight_expense,
      is_overnight_billable,
      rail_cost,
      is_rail_billable,
      parking_cost,
      is_parking_billable,
      entertainment_expense,
      is_entertainment_billable,
      other_expenses,
      is_other_expenses_billable,
    } = this.state;

    let requiredError = "Das Pflichtfeld darf nicht leer sein!";

    const schema = Yup.object().shape({
      is_drive_billable: Yup.boolean().nullable(),
      driven_km: Yup.string()
        .nullable()
        .when("is_drive_billable", is_drive_billable => {
          return is_drive_billable === true
            ? Yup.string().required(requiredError)
            : Yup.string()
              .nullable()
              .notRequired();
        }),
      settlement_rate: Yup.string()
        .nullable()
        .when("is_drive_billable", is_drive_billable => {
          return is_drive_billable === true
            ? Yup.string().required(requiredError)
            : Yup.string()
              .nullable()
              .notRequired();
        }),
      is_overnight_billable: Yup.boolean().nullable(),
      overnight_expense: Yup.string()
        .nullable()
        .when("is_overnight_billable", is_overnight_billable => {
          return is_overnight_billable === true
            ? Yup.string().required(requiredError)
            : Yup.string()
              .nullable()
              .notRequired();
        }),
      is_rail_billable: Yup.boolean().nullable(),
      rail_cost: Yup.string()
        .nullable()
        .when("is_rail_billable", is_rail_billable => {
          return is_rail_billable === true
            ? Yup.string().required(requiredError)
            : Yup.string()
              .nullable()
              .notRequired();
        }),
      is_parking_billable: Yup.boolean().nullable(),
      parking_cost: Yup.string()
        .nullable()
        .when("is_parking_billable", is_parking_billable => {
          return is_parking_billable === true
            ? Yup.string().required(requiredError)
            : Yup.string()
              .nullable()
              .notRequired();
        }),
      is_entertainment_billable: Yup.boolean().nullable(),
      entertainment_expense: Yup.string()
        .nullable()
        .when("is_entertainment_billable", is_entertainment_billable => {
          return is_entertainment_billable === true
            ? Yup.string().required(requiredError)
            : Yup.string()
              .nullable()
              .notRequired();
        }),
      is_other_expenses_billable: Yup.boolean().nullable(),
      other_expenses: Yup.string()
        .nullable()
        .when("is_other_expenses_billable", is_other_expenses_billable => {
          return is_other_expenses_billable === true
            ? Yup.string().required(requiredError)
            : Yup.string()
              .nullable()
              .notRequired();
        }),
    });

    try {
      const x = schema.validateSync(
        {
          is_drive_billable,
          driven_km,
          settlement_rate,
          is_overnight_billable,
          overnight_expense,
          is_rail_billable,
          rail_cost,
          is_parking_billable,
          parking_cost,
          entertainment_expense,
          is_entertainment_billable,
          other_expenses,
          is_other_expenses_billable,
        },
        { abortEarly: false },
      );
    } catch (e) {
      const errors = {};
      if (e.inner) {
        e.inner.forEach(e1 => {
          errors[e1.path] = e1.message;
        });
      }
      return this.setState({
        errors: errors,
      });
    }
    const x = ["driven_km", "settlement_rate", "overnight_expense", "rail_cost", "parking_cost", "entertainment_expense", "other_expenses"];
    x.forEach(t => {
      values[t] = values[t] === "" || values[t] === null ? null : getMoneyNumber(values[t]);
    });

    this.props.onSave(values);
    this.clearErrors();
    this.props.closeModal();
  };
  render() {
    const { closeModal } = this.props;
    const { errors } = this.state;
    return (
      <React.Fragment>
        <ModalContent>
          <TravelExpensesOuter>
            <Layout displayFlex column>
              <FormFieldItems>
                <Layout row displayFlex alignCenter>
                  <i className="material-icons" style={{ paddingBottom: "20px", maxWidth: "30px" }}>
                    {" "}
                    directions_car{" "}
                  </i>
                  <Layout row displayFlex alignCenter className="InputSplit">
                    <PageColumn flex={5} left>
                      {" "}
                      <Layout relative>
                        <FormInput
                          type="text"
                          placeholder="Gefahrene KM"
                          name="driven_km"
                          value={this.state.driven_km}
                          onChange={this.onChange}
                          error={errors && errors["driven_km"]}
                          errorOffset={-30}
                        />{" "}
                      </Layout>
                    </PageColumn>
                    <PageColumn flex={5} right>
                      {" "}
                      <Layout relative>
                        <FormInput
                          type="text"
                          placeholder="Pauschale"
                          name="settlement_rate"
                          value={this.state.settlement_rate}
                          onChange={this.onChange}
                          error={errors && errors["settlement_rate"]}
                          errorOffset={-30}
                        />{" "}
                      </Layout>
                    </PageColumn>
                  </Layout>
                  <Layout row displayFlex style={{ paddingBottom: "20px" }}>
                    <CheckBox
                      onClick={this.onCheckChange("is_drive_billable")}
                      checked={this.state.is_drive_billable}
                      name="is_drive_billable"
                    />
                    <label>Abrechenbar</label>
                  </Layout>
                </Layout>
              </FormFieldItems>
              <TravelExpensesItems
                iconname="hotel"
                placeholder="Übernachtungskosten"
                label="Abrechenbar"
                onChange={this.onChange}
                inputName="overnight_expense"
                is_billable_name="is_overnight_billable"
                inputValue={this.state.overnight_expense}
                onCheckChange={this.onCheckChange("is_overnight_billable")}
                checkboxValue={this.state.is_overnight_billable}
                error={errors && errors["overnight_expense"]}
              />
              <TravelExpensesItems
                iconname="directions_subway"
                placeholder="Bahnkosten"
                label="Abrechenbar"
                onChange={this.onChange}
                inputName="rail_cost"
                is_billable_name="is_rail_billable"
                inputValue={this.state.rail_cost}
                onCheckChange={this.onCheckChange("is_rail_billable")}
                checkboxValue={this.state.is_rail_billable}
                error={errors && errors["rail_cost"]}
              />
              <TravelExpensesItems
                iconname="local_parking"
                placeholder="Parkkosten"
                label="Abrechenbar"
                onChange={this.onChange}
                inputName="parking_cost"
                is_billable_name="is_parking_billable"
                inputValue={this.state.parking_cost}
                onCheckChange={this.onCheckChange("is_parking_billable")}
                checkboxValue={this.state.is_parking_billable}
                error={errors && errors["parking_cost"]}
              />
              <TravelExpensesItems
                iconname="restaurant"
                placeholder="Bewirtungskosten"
                label="Abrechenbar"
                onChange={this.onChange}
                inputName="entertainment_expense"
                is_billable_name="is_entertainment_billable"
                inputValue={this.state.entertainment_expense}
                onCheckChange={this.onCheckChange("is_entertainment_billable")}
                checkboxValue={this.state.is_entertainment_billable}
                error={errors && errors["entertainment_expense"]}
              />
              <TravelExpensesItems
                iconname="receipt"
                placeholder="Sonstige Kosten"
                label="Abrechenbar"
                onChange={this.onChange}
                inputName="other_expenses"
                is_billable_name="is_other_expenses_billable"
                inputValue={this.state.other_expenses}
                onCheckChange={this.onCheckChange("is_other_expenses_billable")}
                checkboxValue={this.state.is_other_expenses_billable}
                error={errors && errors["other_expenses"]}
              />
            </Layout>
          </TravelExpensesOuter>
        </ModalContent>
        <ModalFooterOuter>
          <Button onClick={closeModal} color="grey">
            Abbrechen
          </Button>
          <Button onClick={this.onSaveClick} color="green">
            Speichern
          </Button>
        </ModalFooterOuter>
      </React.Fragment>
    );
  }
}

export default TravelExpenses;
