import React, { createRef } from "react";
import { SidebarWrapper, Sidemenugroup, LogoHolder, SidebarPlaceholder } from "../../../styled/sidebar";
import ReactTooltip from "react-tooltip";

import LiveSearch from "./Search";
import NavMenu from "./Menu";
import SideMenu from "./SideMenu";
import UserInfo from "./UserInfo";
import { Link } from "react-router-dom";
// import Logo from "../../../assets/images/logo.PNG";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
class LeftSidebar extends React.Component {
  unlisten = null;
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
    this.sidebarRef = createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event) {
    if (this.sidebarRef && !this.sidebarRef.current.contains(event.target) && window.innerWidth <= 1440) {
      const {
        store: { sidebarOpen: open },
      } = this.props;
      if (open) {
        this.handleToggle();
      }
    }
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(location => {
      this.forceUpdate();
    });
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleToggle = () => {
    const { store } = this.props;
    store.toggleSidebar();
    this.setState({ open: !this.state.open });
  };
  handleCloseMenu = event => {
    if (window.innerWidth <= 1440) {
      const { store } = this.props;
      store.closeSidebar();
      this.setState({ open: false });
    }
  };
  render() {
    const checkRoute = route => {
      const { pathname } = window.location;
      return route === pathname ? "activeRoute" : "";
    };

    const {
      store: { sidebarOpen: open },
    } = this.props;

    // const { open } = this.state;
    return (
      <React.Fragment>
        <SidebarPlaceholder className={open ? "expanded" : "collapsed"} open={open} />
        <SidebarWrapper className={open ? "expanded" : "collapsed"} open={open} ref={this.sidebarRef}>
          <LogoHolder>{/* <img src={Logo} alt="logo"/> */}</LogoHolder>
          <SideMenu open={open} onPress={this.handleToggle} />
          <UserInfo onPress={this.handleToggle} open={open} />
          <Sidemenugroup onClick={this.handleCloseMenu} className={open ? "expanded" : "collapsed"}>
            <p data-tip="Meine Übersicht">
              <Link to="/my-overview" className={checkRoute("/my-overview")}>
                <i className="material-icons-outlined">people_outline</i>
                <span>Meine Übersicht</span>
              </Link>
            </p>
            <p data-tip="Meine Information">
              <Link to="/my-information" className={checkRoute("/my-information")}>
                <i className="material-icons-outlined">fingerprint</i>
                <span>Meine Information</span>
              </Link>
            </p>
            <p data-tip="Mandanten">
              <Link to="/mandant" className={checkRoute("/mandant")}>
                <i className="material-icons-outlined">dashboard</i>
                <span>Mandanten</span>
              </Link>
            </p>
            <p data-tip="Vorlagen">
              <Link to="/vorlagen" className={checkRoute("/vorlagen")}>
                <i className="material-icons-outlined">chrome_reader_mode</i>
                <span>Vorlagen</span>
              </Link>
            </p>
          </Sidemenugroup>
          <div onClick={!open ? this.handleToggle : null}>
            <LiveSearch />
          </div>
          <NavMenu />
          {!open && <ReactTooltip effect="solid" place="right" type="light" />}
        </SidebarWrapper>
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  inject("store"),
  observer,
)(LeftSidebar);
