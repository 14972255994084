import styled from "styled-components";
import { COLORS, BORDER_RADIUS, FONTWEIGHT } from "./constants";
import SearchIcon from "../assets/images/search.svg";

export const ClientPersonOuter = styled.div`
  width: 100%;
  background: #fff;
  > div {
    width: 100%;
    margin: auto;
  }
`;
export const UserFilterOptions = styled.div`
  width: 100%;
  padding: 47px 39px;
  @media screen and (max-width: 1300px) {
    padding: 30px 20px;
  }
  @media screen and (max-width: 1200px) {
    > div {
      flex-flow: column;
      align-items: flex-start;
    }
  }
`;
export const UserPicture = styled.div`
  border-radius: ${BORDER_RADIUS.BR_6};
  display: flex;
  align-items: center;
  width: 40%;
  @media screen and (max-width: 1200px) {
    margin-bottom: 20px;
    width: 100%;
  }
  @media screen and (max-width: 1030px) {
    margin-bottom: 20px;
  }
  img {
    margin-right: 25px;
    min-width: 69px;
    max-width: 69px;
    height: 69px;
    border-radius: ${BORDER_RADIUS.BR_6};
    @media screen and (max-width: 1300px) {
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
  h5 {
    color: ${COLORS.GREYISH_BROWN_TWO};
    font-size: 1.1rem;
    font-weight: ${FONTWEIGHT.FW_6};
    @media screen and (max-width: 1300px) {
      font-size: 0.9rem;
    }
  }
`;
export const FilterRight = styled.div`
  width: 80%;
  text-align: right;
  @media only screen and (max-width: 1200px) {
    text-align: left;
    width: 100%;
  }
  input {
    @media only screen and (max-width: 768px) {
      margin-bottom: 15px;
      max-width: 90% !important;
    }
    border: 2px solid ${COLORS.WHITE};
    max-width: 411px;
    border-radius: ${BORDER_RADIUS.BR_1};
    height: 43px;
    padding: 12px 16px 12px 50px;
    color: ${COLORS.GREEN_GREY};
    font-size: 0.9rem;
    margin-right: 20px;
    background: url(${SearchIcon}) no-repeat 5% 50%;
    background-size: 19px 19px;
    width: 100%;
    @media only screen and (max-width: 1500px) {
      max-width: 300px;
    }
    @media only screen and (max-width: 1210px) {
      max-width: 260px;
    }
    @media only screen and (max-width: 1210px) {
      max-width: 70%;
      background-position: 3% 50%;
    }
    @media only screen and (max-width: 1080px) {
      max-width: 63%;
    }
    @media only screen and (max-width: 950px) {
      max-width: 51%;
      padding-left: 35px;
    }
  }
  button {
    background: ${COLORS.TURTLE_GREEN_TWO};
    padding: 7px 24px;
    text-align: center;
    color: #fff;
    font-size: 0.9rem;
    font-weight: ${FONTWEIGHT.FW_6};
    height: 43px;
    line-height: 3px;
    border-radius: ${BORDER_RADIUS.BR_1};
    cursor: pointer;
    border: 1px solid ${COLORS.TURTLE_GREEN_TWO};
    box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
    @media only screen and (max-width: 950px) {
      padding: 7px 10px;
    }
    @media only screen and (max-width: 768px) {
      width: 90% !important;
    }
    i {
      font-size: 21px;
      margin-right: 8px;
      line-height: 0;
      position: relative;
      top: 6px;
    }
  }
`;
