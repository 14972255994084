import React from "react";
import { Icon } from "../../styled";
import { RadioContainer } from "../../styled/form";

class Radio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: this.props.selectedIndex,
    };
  }

  select = index => () => {
    this.setState({ selectedIndex: index });
    this.props.onClick(index);
  };

  render() {
    const { selectedIndex } = this.state;
    const { options } = this.props;
    return (
      <React.Fragment>
        {(options || []).map((item, index) => (
          <RadioContainer column>
            <Icon onClick={this.select(index)}>{index === selectedIndex ? `radio_button_checked` : `radio_button_unchecked`}</Icon>
            {item}
          </RadioContainer>
        ))}
      </React.Fragment>
    );
  }
}
export default Radio;
