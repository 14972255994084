import React from "react";
import { DatePickerContainer, DatePicker as StyledDatePicker, DatePickerIconContainer } from "../../styled/timer";
import { Icon } from "../../styled";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import { Error } from "../../styled/form";
import { Layout } from "../../styled";

registerLocale("de", de);

// const DatePicker = ({ value, onChange, error }) => (
//   <Layout>
//     <label className="errorStyleDP">
//       <DatePickerContainer>
//         <StyledDatePicker
//           selected={value}
//           onChange={onChange}
//           dateFormat="dd.MM.yyyy"
//           locale="de"
//           showMonthDropdown
//           showYearDropdown
//           scrollableYearDropdown
//           yearDropdownItemNumber={1}
//         />
//         <DatePickerIconContainer>
//           <Icon>date_range</Icon>
//         </DatePickerIconContainer>
//       </DatePickerContainer>
//       <Error textAlign="right" style={{ bottom: "auto", right: "auto", width: "auto", paddingTop: "3px" }}>
//         {error}
//       </Error>

//       {/* <span className="error">{error}</span> */}
//     </label>
//   </Layout>
// );

class DatePicker extends React.Component {
  inputRef = React.createRef();
  componentDidMount() {}

  render() {
    const { value, onChange, error } = this.props;
    return (
      <form autoComplete={"off"}>
        <Layout>
          <label className="errorStyleDP">
            <DatePickerContainer>
              <StyledDatePicker
                selected={value}
                onChange={onChange}
                dateFormat="dd.MM.yyyy"
                locale="de"
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={1}
              />
              <DatePickerIconContainer>
                <Icon>date_range</Icon>
              </DatePickerIconContainer>
            </DatePickerContainer>
            <Error textAlign="right" style={{ bottom: "auto", right: "auto", width: "auto", paddingTop: "3px" }}>
              {error}
            </Error>

            {/* <span className="error">{error}</span> */}
          </label>
        </Layout>
      </form>
    );
  }
}

export default DatePicker;
