import React from "react";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";

import { Layout, P } from "../../styled";
import { FragenListOuter, QuestionCopy, Question } from "../../styled/questioncatalogue";
import Accordion from "../Accordion";
import Radio from "../Form/Radio";
import { TextInput } from "../../styled/form";
import { Speichem } from "../../styled/buttons";

const CATEGORIES = [
  "Unterbringung",
  "Pflege / Gesundheit / persönliche Versorgung",
  "Kontakte / Personen",
  "Vermögen / Geld / Kontoführung",
  "Liquidität / Einkünfte / Bezüge / Renten",
  "Steuern und Abgaben",
  "Versicherungen / Verträge / Sonstige Rechte",
  "Haushalt / Fahrzeuge / Freizeit / Hobby",
  "Immobilien",
  "Unternehmen / Beteiligungen",
  "Zusatzfragen",
];

const QuestionItem = props => (
  <Question>
    <span>{props.itemnumber}</span>
    <h5>{props.question}</h5>
    {props.open ? <i className="material-icons">keyboard_arrow_down</i> : <i className="material-icons">keyboard_arrow_right</i>}
  </Question>
);

class FragenList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAccordion: "",
      questions: [],
    };
  }
  componentDidMount() {
    this.fetchData();
  }

  componentWillReceiveProps() {
    this.fetchData();
  }

  getNumberAndLetters = questionNo => {
    const objToReturn = {};
    const numberOfQuestion = Number(questionNo);
    if (isNaN(numberOfQuestion)) {
      objToReturn.letters = questionNo.match(/[a-zA-Z]+/)[0];
      objToReturn.number = Number(questionNo.replace(/[a-zA-Z]+/, ""));
    } else {
      objToReturn.number = Number(questionNo);
    }

    return objToReturn;
  };

  sortQuestions = questions => {
    const sortedQuestions = questions.sort((val1, val2) => {
      let number1 = this.getNumberAndLetters(val1.question_no);
      let number2 = this.getNumberAndLetters(val2.question_no);

      if (number1.number > number2.number) {
        return 1;
      }
      if (number1.number < number2.number) {
        return -1;
      }
      if (!number1.letters) {
        return -1;
      }

      if (!number2.letters) {
        return 1;
      }

      return number1.letters < number2.letters ? 1 : -1;
    });

    return sortedQuestions;
  };

  fetchData = async () => {
    const clientId = this.props.store.client.data && this.props.store.client.data.client_id;
    try {
      const response = await this.props.store.getAllQuestions.fetch({
        clientId,
      });

      const sorted = this.sortQuestions(response.data.data);

      this.setState({
        questions: sorted,
      });
    } catch (e) {
      this.setState({ questions: [] });
    }
  };

  handleOpenAccordion = type => () => {
    this.setState({ openAccordion: type === this.state.openAccordion ? "" : type });
  };

  hideShowQuestion = (questions, ids, answer) => {
    let indexes = [];
    ids.map((id, i) => {
      questions.map((question, index) => {
        if (question.question_no === id) {
          indexes.push(index);
        }
      });
    });

    return indexes;
  };

  conditioningQuestions = (questions, question) => {
    let filteredQuestions = questions;

    //show/hide text inputs if the question has any
    let foundQuestion = filteredQuestions.find(q => q.question_no === question.question_no + "a" && q.type === "text");
    if (foundQuestion) foundQuestion.show = question.answer === 0;

    const dependentQuestions = question.followUp.split(",");

    const indexes = this.hideShowQuestion(questions, dependentQuestions);

    indexes.map((id, index) => {
      filteredQuestions[id].show = question.question_no !== "125" ? question.answer === 0 : question.answer === 1;
    });

    return filteredQuestions;
  };

  onAnswerChange = index => event => {
    const { questions } = this.state;

    const q = questions[index];

    q.answer = event.target !== undefined ? event.target.value : event;

    questions[index] = q;

    const filteredQuestions = this.conditioningQuestions(questions, q);

    this.setState({
      questions: filteredQuestions,
    });
  };

  submitQuestions = async () => {
    const { updateQuestions } = this.props.store;
    try {
      await updateQuestions.fetch(this.state.questions);
    } catch (e) {
      console.log("Error", e);
    }
  };

  formatOptions = options => {
    let formattedOptions = [];

    let newOptions = options.split('"');
    formattedOptions.push(newOptions[1], newOptions[3]);

    return formattedOptions;
  };

  render() {
    const { openAccordion, questions } = this.state;

    return (
      <FragenListOuter>
        <Layout>
          {CATEGORIES.map((category, index) => (
            <Accordion
              key={index}
              open={openAccordion === category}
              onClickHeader={this.handleOpenAccordion(category)}
              titleComponent={() => <QuestionItem itemnumber={index} question={category} open={openAccordion === category} />}
            >
              {questions &&
                (questions || []).map(
                  (item, i) =>
                    item.category === category &&
                    (item.show || item.stayShown) && (
                      <QuestionCopy key={item.question_id}>
                        {!item.question_no.includes("a") && <P type="type-2">{item.title}</P>}

                        {item.type === "radio" && (
                          <Radio
                            options={this.formatOptions(item.options)}
                            selectedIndex={parseInt(item.answer)}
                            onClick={this.onAnswerChange(i)}
                          />
                        )}
                        {item.type === "text" && (
                          <TextInput name={`${item.question_id}`} value={item.answer} onChange={this.onAnswerChange(i)}></TextInput>
                        )}
                      </QuestionCopy>
                    ),
                )}
            </Accordion>
          ))}
        </Layout>

        <Layout>
          <Speichem onClick={this.submitQuestions}> Speichern </Speichem>
        </Layout>
      </FragenListOuter>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(FragenList);
