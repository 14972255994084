import styled from "styled-components";
import { COLORS, BOX_SHADOW } from "./constants";

export const ProgressContainer = styled.div`
  position: relative;
  z-index: 90;
  width: 100%;
  padding: 85px 45px;
  color: ${COLORS.PINE};
  background-color: #f5f6f3;
  box-shadow: 0px 1px 18px 0 rgba(70, 84, 84, 0.2);
  border-bottom: solid 1px ${COLORS.LIGHT_GREY_TWO};
  transition: all 0.2s ease;
`;

export const ProgressSliderOuter = styled.div`
  height: 58px;
  box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px ${COLORS.LIGHT_GREY_TWELVE};
  background-color: white;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  transition: all 0.2s ease;
`;
export const ProgressBarCompleted = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 10px;
  z-index: 200;
  background-color: rgb(91, 136, 38, 1);
  width: ${props => props.completed * 100}%;
  border-radius: 4px;
  transition: all 0.2s ease;
`;
export const ProgressBar = styled.div`
  height: 10px;
  background-color: rgb(91, 136, 38, 0.2);
  border-radius: 4px;
  width: 100%;
  width: 100%;
  z-index: 100;
  transition: all 0.2s ease;
`;
export const ProgressHandle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  box-shadow: 0px 3px 7px 0 rgba(53, 72, 29, 0.29);
  border: solid 1px rgb(192, 203, 179);
  position: absolute;
  left: 0;
  top: 50%;
  left: ${props => props.completed * 100}%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 300;
  transition: all 0.2s ease;
`;

export const ProgressText = styled.p`
  color: ${COLORS.NASTY_GREEN_THREE};
  padding-left: 21px;
  font-size: 19px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: 0.2px;
  text-align: left;
  transition: all 0.2s ease;
`;

export const ProgressStatusBox = styled.div`
  height: 58px;
  box-shadow: ${BOX_SHADOW.BS_1};
  border: solid 1px ${COLORS.TURTLE_GREEN_TWO};
  background-color: ${COLORS.TURTLE_GREEN};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin-bottom: 20px;
  transition: all 0.2s ease;
  @media only screen and (max-width: 900px) {
    width: 100%;
    margin: 0 15px 20px 0;
  }
`;
export const ProgressStatusLabel = styled.span`
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.08;
  letter-spacing: 0.1px;
  text-align: left;
  color: white;
  transition: all 0.2s ease;
  @media only screen and (max-width: 500px) {
    font-size: 11px;
  }
`;
export const ProgressStatusData = styled.span`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: 0.1px;
  text-align: left;
  color: ${COLORS.PINE_FOUR};
  transition: all 0.2s ease;
  @media only screen and (max-width: 500px) {
    font-size: 11px;
  }
`;
