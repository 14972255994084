import React, { Component } from "react";
import ReactDOM from "react-dom";

import { SelectInnerContainer, SelectContainer, SelectText, SelectOptionText, SelectOptions, SelectOptionItem } from "../../styled/select";
import { Icon } from "../../styled";
import { Error } from "../../styled/form";

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected: props.selected ? props.selected : null,
      itemWidth: 50,
    };

    this.node = React.createRef();

    (props.options || []).forEach(option => {
      this[`ref_${option}`] = React.createRef();
    });
  }

  componentDidMount() {
    let minWidth = 50;
    const { options } = this.props;
    options.forEach(option => {
      const w = ReactDOM.findDOMNode(this[`ref_${option}`].current).offsetWidth;
      minWidth = minWidth < w ? w : minWidth;
    });
    const nodeWidth = ReactDOM.findDOMNode(this.node.current).offsetWidth;

    if (this.props.beneficiaryId) {
      this.props.options.map((person, index) => {
        if (person.beneficiary_id === this.props.beneficiaryId) {
          this.setState({
            selected: this.props.options[index],
          });
        }
      });
    }

    this.setState({
      itemWidth: minWidth,
      nodeWidth,
    });
  }

  componentWillReceiveProps(nextProps) {
    // console.log("NEXT PROPSSSSS", nextProps.selected);
    if (nextProps.changeActionFlag !== this.props.changeActionFlag) {
      this.setState({
        selected: nextProps.selected,
      });
    }
  }

  handleToggle = () => {
    const { open } = this.state;
    if (open) {
      this.handleClose();
    } else {
      this.handleOpen();
    }
  };
  handleOpen = () => {
    document.addEventListener("click", this._onClickListener, false);
    this.setState({ open: true });
  };
  handleClose = () => {
    document.removeEventListener("click", this._onClickListener, false);
    this.setState({ open: false });
  };

  setSelected = (selected, index, skip, isAsset) => () => {
    // console.log(selected, index, skip, isAsset, this.props.asset)
    const shouldSkip = (skip || []).find(s => s === selected);

    if (shouldSkip) {
      return;
    }

    // console.log(status, determination, selected, this.props.asset)

    if (isAsset) {
      const status = this.props.status ? this.props.status : selected;
      const determination = !this.props.status ? this.props.asset.determination : selected;
      if (determination !== "unknown" && (status === "sold" || status === "assigned" || status === "handing_over")) {
        let germanStatus = "";
        switch (status) {
        case "sold":
          germanStatus = "Veräußert";
          break;
        case "assigned":
          germanStatus = "Zugeordnet";
          break;
        case "handing_over":
          germanStatus = "Übergeben";
          break;
        }
        if (!window.confirm(`Wert als “${germanStatus}” markieren? Wird in die Übersicht “Veräußertes Vermögen” verschoben`)) {
          return;
        }
      }
    }

    const { onChange } = this.props;
    document.removeEventListener("click", this._onClickListener, false);
    this.setState({ selected, open: false });
    if (onChange) {
      onChange(selected, index);
    }
  };

  _onClickListener = e => {
    if (!this.node.current.contains(e.target)) {
      this.handleClose();
      return;
    }
  };

  componentWillUnmount() {
    document.removeEventListener("click", this._onClickListener, false);
  }

  adaptOption = option => {
    const { noAdapter, withNameAndValue, customAdapter } = this.props;
    if (customAdapter) {
      return customAdapter(option);
    }
    if (withNameAndValue && option) {
      return option.name;
    }
    if (noAdapter) {
      return option;
    }
    switch (option) {
    case "assigned":
      return "Zugeordnet";
    case "open":
      return "Offen";
    case "sold":
      return "Veräußert";
    case "not_sold":
      return "Nicht veräußert";
    case "handing_over":
      return "Übergeben";
    case "disposal":
      return "Entsorgen";
    case "donation":
      return "Freigiebige Zuwendungen";
    case "legacy":
      return "Vermächtnis";
    case "sell":
      return "Verkaufen";
    case "dispute":
      return "Auseinandersetzen";
    case "administration":
      return "Verwaltung";
    case "asset":
      return "Aktiva";
    case "liability":
      return "Passiva";
    case "Rot":
      return "red";
    case "Blau":
      return "blue";
    case "Grün":
      return "green";
    case "Grau":
      return "gray";
    case "Schwarz":
      return "black";
    case "Gelb":
      return "yellow";
    default:
      return undefined;
    }
  };

  render() {
    const { options, placeholder, value, skip, disableSelect, isAsset, error, height, overflow } = this.props;

    const { open, itemWidth, selected, nodeWidth } = this.state;

    const safePlaceholder = this.adaptOption(value) || placeholder || "Select";
    // console.log("OPTIONS", options, selected, selected ? this.adaptOption(selected) : safePlaceholder);
    return (
      <SelectContainer ref={this.node} open={open} disabled={disableSelect}>
        <SelectInnerContainer onClick={this.handleToggle}>
          <SelectText>{selected ? this.adaptOption(selected) : safePlaceholder}</SelectText>
          <Icon size={16}>expand_more</Icon>
        </SelectInnerContainer>
        <SelectOptions minWidth={nodeWidth} open={open} overflow={overflow} height={height}>
          {(options || []).map((item, index) => (
            <SelectOptionItem
              elemWidth={itemWidth}
              minWidth={nodeWidth}
              key={item.key || item}
              disabled={item.disabled}
              onClick={this.setSelected(item, index, skip, isAsset)}
            >
              <SelectOptionText disabled={(skip || []).find(s => s === item)} ref={this[`ref_${item}`]}>
                {this.adaptOption(item)}
              </SelectOptionText>
            </SelectOptionItem>
          ))}
        </SelectOptions>
        <Error textAlign="right" type="type-5" className="form_input_error">
          {error}
        </Error>
      </SelectContainer>
    );
  }
}

export default Select;
