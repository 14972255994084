import React, { Component } from "react";
import { compose } from "recompose";
import { Layout } from "../../../styled";
import { inject, observer } from "mobx-react";
import { ConfirmationOuter } from "../../../styled/modal";
import { Button } from "../../../styled/buttons";
import { ModalFooterOuter } from "../../../styled/actionmodalconfirmation";

class ChangeAdminRoleWarning extends Component {
  render() {
    return (
      <Layout displayFlex alignCenter column>
        <ConfirmationOuter>
          <i className="material-icons">error</i>
          <h5>Sie haben keine Berechtigung, die Benutzerrolle zu ändern.</h5>
        </ConfirmationOuter>
      </Layout>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(ChangeAdminRoleWarning);
