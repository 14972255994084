import styled from "styled-components";
import { COLORS, BORDER_RADIUS } from "./constants";

export const UserInformation = styled.div`
  @media only screen and (max-width: 768px) {
    > div > div > div > div > div {
      display: flex;
      flex-direction: column;
      // overflow-y: scroll
      > div {
        width: 100%;
      }
    }
    padding: 0px !important;
  }
  width: 100%;
  padding: 45px 75px 0 75px;
  h6 {
    font-family: Sansation;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: 0.1px;
    padding-bottom: 12px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.11);
    text-align: left;
    color: ${COLORS.PINE};
    @media only screen and (max-width: 900px) {
      font-size: 1.1rem;
    }
  }
  @media only screen and (max-width: 1400px) {
    padding: 30px 20px 0 20px;
  }
  @media only screen and (max-width: 1050px) {
    > div {
      flex-flow: column;
      padding-bottom: 30px;
    }
  }
`;
// export const PrimaryInfo = styled.div`
//   padding-bottom: 60px;
//   display: flex;
//   > div {
//     .customlabel {
//       label {
//         line-height: 19px;
//         word-wrap: break-word;
//         letter-spacing: -0.5px;
//         margin-left: -13px;
//         max-width: 185px;
//       }
//     }
//     .customLineHeight {
//       label {
//         line-height: 19px;
//       }
//     }
//     > div {
//       max-height: 40px;
//       position: relative;
//       @media only screen and (max-width: 700px) {
//         max-height: none;
//         margin-bottom: 10px;
//         > div {
//           flex-flow: column;
//           align-items: flex-start;
//           label {
//             max-width: 100%;
//             width: 100%;
//             padding-right: 0;
//             text-align: left;
//           }
//           > div {
//             width: 100%;
//           }
//         }
//       }
//     }
//     > label {
//       width: 30%;
//       max-width: 170px;
//       @media only screen and (max-width: 1600px) {
//         width: 170px;
//       }
//       textarea {
//         width: 60%;
//       }
//     }
//   }
// `;
// export const DatePickerItem = styled.div`
//   width: 100%;
//   display: flex;
//   height: 44px !important;
//   > label {
//     max-width: 160px;
//     width: 100%;
//     &.errorStyleDP {
//       width: 74%;
//       position: relative;
//       height: 80px;
//       max-width: 100%;
//       span.error {
//         position: absolute;
//         right: 0;
//         bottom: 0;
//         width: 100%;
//       }
//     }
//   }
//   @media only screen and (max-width: 700px) {
//     width: auto;
//     position: relative;
//     > div {
//       position: relative;
//     }
//     .react-datepicker-wrapper {
//       width: auto;
//     }
//     i {
//       position: absolute;
//       right: 0;
//       top: 0;
//       width: 36px;
//       text-align: center;
//       border-left: 2px solid #ccc;
//       height: 34px;
//       line-height: 31px;
//     }
//   }
//   label {
//     font-size: 1rem;
//     color: ${COLORS.GREYISH_BROWN_TWO};
//     text-align: right;
//     padding-right: 20px;
//     line-height: 42px;
//     letter-spacing: 0.2px;
//     span {
//       color: ${COLORS.TOMATO};
//       font-size: 0.85rem !important;
//     }
//   }
//   @media only screen and (max-width: 700px) {
//     height: auto;
//     display: flex;
//     flex-flow: column;
//     label {
//       width: 100% !important;
//       max-width: 100% !important;
//       padding-right: 0;
//       text-align: left;
//     }
//   }
// `;
export const PrimaryInfo = styled.div`
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  > div {
    > div {
      max-height: 40px;
      position: relative;
      @media only screen and (max-width: 600px) {
        max-height: none;
        // margin-bottom: 10px;
        flex-direction: column;
        label {
          text-align: left;
        }
        > div {
          flex-flow: column;
          align-items: flex-start;
          label {
            // max-width: 100%;
            width: 100%;
            padding-right: 0;
            text-align: left;
          }
          > div {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            label {
              max-width: 100%;
              width: 100%;
              padding-right: 0;
              text-align: left;
            }
            > div {
              width: 100%;
            }
          }
        }
      }
    }
    label {
      width: 30%;
      max-width: 180px;
      min-width: 180px;
      &.errorStyleDP {
        // max-width: 100%;
        text-align: end;
        width: 100%;
      }
      @media only screen and (max-width: 1600px) {
        width: 178px;
        max-width: 180px;
      }
      @media only screen and (max-width: 700px) {
        text-align: right;
        padding-right: 20px;
      }
    }
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const DatePickerItem = styled.div`
  width: 100%;
  display: flex;
  > label {
    max-width: 170px;
    width: 38%;
    &.errorStyleDP {
      // width: 100% !important;
      padding-bottom: 20px;
      position: relative;
      // max-width: 61%;
      height: 65px;
      span.error {
        position: absolute;
        bottom: -16px;
        right: 0;
        width: 100%;
        line-height: 14px;
        white-space: pre-wrap;
      }
    }
  }
  @media only screen and (max-width: 700px) {
    width: auto;
    position: relative;
    > div {
      position: relative;
    }
    .react-datepicker-wrapper {
      width: auto;
    }
    // i {
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   width: 36px;
    //   text-align: center;
    //   border-left: 2px solid #ccc;
    //   height: 34px;
    //   line-height: 31px;
    // }
  }
  label {
    // font-size: 1rem;
    color: ${COLORS.GREYISH_BROWN_TWO};
    text-align: right;
    padding-right: 20px;
    line-height: 42px;
    letter-spacing: 0.2px;
    span {
      color: ${COLORS.TOMATO};
      font-size: 0.85rem !important;
    }
  }
  @media only screen and (max-width: 700px) {
    height: auto;
    display: flex;
    // flex-flow: column;
    label {
      // width: 100% !important;
      // max-width: 100% !important;
      padding-right: 0;
      text-align: left;
      font-size: 0.8rem !important;
    }
  }
`;
export const ActionsOuter = styled.div`
  width: 100%;
  background: transparent;
  padding: 0 0 20px 0;
  > div{
    align-items: center;
  }
  button {
    max-width: 227px;
    height: 53px;
    border-radius: ${BORDER_RADIUS.BR_1};
    box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
    // border: 1px solid ${COLORS.TURTLE_GREEN_TWO};
    padding: 11px 20px;
  }
  input {
    height:43px;
    box-shadow: inset 0px 1px 10px 0 rgba(72, 84, 56, 0.21);
    background: ${COLORS.LIGHT_GREY};
    border: ${COLORS.LIGHT_GREY_EIGHT};
    color: ${COLORS.DARK_SAGE_THREE};
    font-size: 0.93rem;
    max-width: 350px;
  }
`;
export const InputHolder = styled.div`
  width: 85%;
`;
export const PrimaryInfoTxt = styled.div`
  padding-bottom: 30px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  > div {
    width: 100%;
    > div {
      position: relative;
      > div {
        align-items: start;
      }
      @media only screen and (max-width: 700px) {
        max-height: none;
        // margin-bottom: 10px;
        > div {
          flex-flow: column;
          label {
            max-width: 100%;
            width: 100%;
            padding-right: 0;
            text-align: left;
          }
          > div {
            width: 100%;
          }
        }
      }
    }
    label {
      width: 30%;
      >max-width: 170px;
      @media only screen and (max-width: 1600px) {
        width: 180px;
      }
      textarea {
        width: 60%;
      }
    }
  }
`;
export const SetStatusOptions = styled.div`
  margin-bottom: 50px;
  > div {
    padding-bottom: 30px;
  }
  p {
    font-size: 14px;
    color: #5e605b;
    padding-left: 2.5%;
  }
`;

export const ExpiredButton = styled.button`
  background: ${COLORS.TURTLE_GREEN_TWO};
  border: 1px solid ${COLORS.TURTLE_GREEN_TWO};
  border-radius: ${BORDER_RADIUS.BR_1};
  box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
  text-align: center;
  min-width: 112px;
  font-size: 1rem;
  margin-right: 0;
  color: #fff;
  color: #fff;
  height: 40px;
  cursor: pointer;
  &:hover {
    background: ${COLORS.NASTY_GREEN_TWO};
  }
`;

export const ExpiredWrapper = styled.div`
  width: 50%;
  padding-left: 65px;
`;
