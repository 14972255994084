import Axios from "axios";
import _ from "lodash";
import config from "../../lib/config";

import { store } from "../../App";

export const reportsOrderAdapter = {
  report_name: {
    key: "row_1",
    order: 0,
    scale: 4,
    title: "Report",
  },
  report_category: {
    key: "row_2",
    order: 1,
    scale: 4,
    title: "Report-Kategorie",
  },
  report_notes: {
    key: "row_3",
    order: 2,
    scale: 4,
    title: "Notizen",
  },
  is_selected: {
    key: "row_4",
    order: 3,
    scale: 1,
    title: "Auswählen",
  },
  is_deleted: {
    key: "row_5",
    order: 4,
    scale: 1,
    title: "Löschen",
  },
};

export const reportsHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

const deleteReport = async (event, store, index) => {
  const token = window.localStorage.getItem(config.keys.local_storage_key);
  Axios({
    method: "DELETE",
    url: config.endpoints.api + `/api/report/deleteReport/${store.getReports.data[index].report_id}`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `${token}`,
    },
  }).then(() => {
    store.getReports.fetch({
      clientId: store.client.data.client_id,
    });
  });
};

export const reportsRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          case "id":
            break;
          case "is_selected":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "checkbox",
              checked: _.get(store, `getReports.selected.${item.report_id}`),
              action: () => () => store.getReports.select(item.report_id),
            };
            break;
          case "is_deleted": {
            row[orderItem.order] = {
              key: `id_${index}`,
              type: "icon",
              active: "delete",
              action: (store, index) => e => deleteReport(e, store, index),
              data: true,
            };
            break;
          }
          default: {
            let value = "";
            if (orderItem.title === "Report-Kategorie") {
              if (item[itemKey] === "yearly") value = "Jahresreport";
              if (item[itemKey] === "quaterly") value = "Quartalsreport";
              if (item[itemKey] === "individual") value = "Individueller Report";
            }
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: value === "" ? item[itemKey] : value,
            };
            break;
          }
          }
        }
      });
      rows[index] = row;
    });
  }
  return rows;
};
