import styled from "styled-components";
import { COLORS } from "./constants";

export const SearchBox = styled.div`
  width: 100%;
  margin: 40px 0 30px 0;
  padding: 0;
  display: flex;
  align-items: center;
`;
export const NewFilterSearchContainer = styled.div`
  height: 40px;
  border: solid 2px ${COLORS.WHITE_SEVEN};
  width: 100%;
  max-width: 460px;
  border-radius: 3px;
  padding: 0 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

export const NewItemSearchInput = styled.input`
  font-size: 0.9rem;
  height: 100%;
  line-height: 40px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.59;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${COLORS.GREY_GREEN};
  flex: 1;
  border: none;
  padding-left: 20px;
`;
