import React, { Component } from "react";
import moment from "moment";
import withPageWrapper from "../hoc/Page";
import { compose } from "recompose";
import { ResponseWarning } from "../styled/layout";
import { Layout } from "../styled";
import { inject, observer } from "mobx-react";
import Table from "../components/Table";
import { GraphImg } from "../styled/myoverview.jsx";
import TotalClients from "../components/UserDashboard/TotalClients";
import NextAppointment from "../components/UserDashboard/NextAppointment";
import NewsandEvents from "../components/UserDashboard/NewsandEvents";
import GraphItem from "../components/UserDashboard/Graph";
import config from "../lib/config";
import { browserHistory } from "./routes";
import { routes } from "./routes";

moment.locale("de");

const warningMessage = `Aktuell haben Sie noch keine Mandanten angelegt. Bitte legen Sie
zunächst Ihre Mandanten an, um eine Übersicht über Ihre Mandanten
 zu generieren.`;

class MyOverview extends Component {
  componentDidMount() {
    const {
      store: { userDashboard },
    } = this.props;
    userDashboard.fetch();
    const token = window.localStorage.getItem(config.keys.local_storage_key);
    if (!token) {
      browserHistory.replace(routes.LOGIN);
      browserHistory.go();
    }
  }

  render() {
    const {
      store: { userDashboard },
    } = this.props;

    return (
      <React.Fragment>
        <Layout>
          {userDashboard.constructedTable.rows[0] ? (
            <Table hideHeader small tableStore={userDashboard} />
          ) : (
            <ResponseWarning>
              <div className="response success">
                <i className="material-icons">warning</i> {warningMessage}
              </div>
            </ResponseWarning>
          )}
        </Layout>
        <TotalClients />
        <GraphImg>
          <GraphItem>
            <div id="container"></div>
          </GraphItem>
        </GraphImg>
        <NextAppointment />
        <NewsandEvents />
      </React.Fragment>
    );
  }
}

export default compose(
  withPageWrapper("Meine Übersicht"),
  inject("store"),
  observer,
)(MyOverview);
