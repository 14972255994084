import React, { Component } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import _ from "lodash";

import withTabsWrapper from "../hoc/Tabs";
import UserAdministration from "../components/Administration/UserAdministration";
import LicenseManagement from "../components/Administration/LicenseManagement";
import RoyaltyStatement from "../components/Administration/RoyaltyStatement";
import News from "../components/Administration/News";

const TABS_WITH_COMPONENTS = [
  {
    key: "tab_1",
    pageTitle: "Administration",
    title: "User Verwaltung",
    component: UserAdministration,
    hash: "#administration",
  },
  {
    key: "tab_2",
    pageTitle: "Administration",
    title: "Lizenzverwaltung",
    component: LicenseManagement,
    hash: "#licenses",
  },
  {
    key: "tab_3",
    pageTitle: "Administration",
    title: "Lizenzabrechnung",
    component: RoyaltyStatement,
    hash: "#royalty",
  },
  {
    key: "news",
    pageTitle: "Administration",
    title: "Neuigkeiten",
    component: News,
    hash: "#news",
  },
];

const CWith = withTabsWrapper(TABS_WITH_COMPONENTS)();

class Administration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: this.getActiveIndexByHashtag(),
    };
  }

  getActiveIndexByHashtag = () => {
    const indexOfHashtag = TABS_WITH_COMPONENTS.findIndex(t => t.hash === this.props.history.location.hash);
    return indexOfHashtag >= 0 ? indexOfHashtag : 0;
  };

  onTabChange = indexOfTab => {
    window.location.hash = TABS_WITH_COMPONENTS[indexOfTab].hash;
    this.setState({
      activeIndex: indexOfTab,
    });
  };

  render() {
    this.getActiveIndexByHashtag();
    return (
      <CWith
        activeIndex={this.state.activeIndex}
        afterTabContentChange={() => {
          this.setState({ changeTabContent: false });
        }}
        onTabChange={this.onTabChange}
      />
    );
  }
}

export default compose(
  observer,
  inject("store"),
)(Administration);
