import styled from "styled-components";
import { COLORS, BORDER_RADIUS, FONTWEIGHT } from "./constants";

export const SignUp = styled.div`
  max-width: 80%;
  margin: auto;
  box-shadow: 0px 1px 18px 0 rgba(70, 84, 84, 0.2);
  border: 1px solid ${COLORS.LIGHT_GREY_ELEVEN};
  background: #fff;
  padding: 40px 0;
  text-align: center;
  font-family: Sansation;
  margin-top: 25px;
  > p {
    text-align: center;
  }
`;
export const UserInformation = styled.div`
  width: 100%;
  padding: 45px 75px 0 75px;
  @media only screen and (max-width: 1400px) {
    padding: 30px 20px 0 20px;
  }
  @media only screen and (max-width: 1050px) {
    > div {
      flex-flow: column;
      padding-bottom: 30px;
    }
  }
`;
export const UserInformationRow = styled.div`
  display: flex;
  ${props =>
    props.rowToColumn
      ? ` @media only screen and (max-width: ${props.rowToColumn}px) {
      flex-direction: column;
    }`
      : ` @media only screen and (max-width: 900px) {
    flex-direction: column;
  }`}
  ${props =>
    props.mediaMarginTop
      ? ` @media only screen and (max-width: ${props.mediaMarginTop}px) {
    margin-top: 15px;
  }`
      : ``}
`;

export const UserInformationRowPass = styled.div`
  display: flex;
  ${props =>
    props.rowToColumn
      ? ` @media only screen and (max-width: ${props.rowToColumn}px) {
      flex-direction: column;
    }`
      : ` @media only screen and (max-width: 900px) {
    flex-direction: column;
  }`}
  ${props =>
    props.mediaMarginTop
      ? ` @media only screen and (max-width: ${props.mediaMarginTop}px) {
    margin-top: 15px;
  }`
      : ``}

    > div {
    > div {
      bottom: -30px;
      @media only screen and (max-width: 1255px) {
        bottom: -40px;
      }
      @media only screen and (max-width: 900px) {
        bottom: -20px;
      }
      @media only screen and (max-width: 500px) {
        bottom: -35px;
      }
    }
    &: last-child {
      > div {
        bottom: -16px;
      }
    }
  }
`;

export const DeviatingInvoiceAddress = styled.div`
  width: 100%;
  padding: 0 50px;
  @media only screen and (max-width: 1600px) {
    padding: 50px 20px;
  }
  @media only screen and (max-width: 600px) {
    > div {
      label {
        font-size: 0.85rem;
      }
    }
  }
  > div {
    width: 100% !important;
    height: auto;
    > label {
      max-width: none !important;
      width: auto !important;
      margin-right: 25px;
    }
  }
  p {
    padding-bottom: 12px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.11);
    width: 100%;
    margin-bottom: 30px;
  }
`;
export const InvoiceAddressForm = styled.div`
  width: 100%;
  > div {
    > div {
      > div {
        position: relative;
      }
    }
  }
  > label {
    width: 30%;
    max-width: 170px;
  }
  @media only screen and (max-width: 1050px) {
    > div {
      flex-flow: column;
      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
    @media only screen and (max-width: 700px) {
      > div {
        > div {
          > div {
            > div {
              flex-flow: column;
              label {
                width: 100%;
                text-align: left;
                max-width: 100%;
                padding-right: 0;
              }
              > div {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
`;
export const Order = styled.div`
  width: 100%;
  box-shadow: 0px 1px 8px 0 ${COLORS.GUNMETAL_20};
  border: 1px solid ${COLORS.LIGHT_GREY_SIXTEEN};
  margin: 30px 0;
  h5 {
    border: 1px solid ${COLORS.LIGHT_GREY_TEN};
    background: ${COLORS.LIGHT_GREY_SEVENTEEN};
    padding: 29px 33px;
    color: ${COLORS.BLACK};
    font-size: 1.43rem;
    font-weight: ${FONTWEIGHT.FW_6};
  }
  > div {
    padding: 30px 33px;
    @media only screen and (max-width: 600px) {
      padding: 10px;
    }
    > div {
      // padding: 0 0 25px 0;
      font-size: 1rem;
      color: ${COLORS.GREYISH_BROWN_TWO};
      border: none;
      span {
        float: right;
      }
      &:last-child {
        margin-top: 50px;
        padding: 30px 0 20px 0;
        border-top: 2px solid ${COLORS.LIGHT_GREY_SIXTEEN};
        font-size: 1.06rem;
        font-weight: ${FONTWEIGHT.FW_6};
      }
    }
  }
  & + div {
    display: block;
    button {
      float: right;
    }
  }
`;
export const DatePickerItem = styled.div`
  ${props => (props.fullWidth ? `width: 100%;` : `width: 50%;`)}
  display: flex;
  height: 44px !important;
  position: relative;
  margin-bottom: 21px;
  > label.errorStyleDP {
    // width: 100% !important;
    padding-bottom: 20px;
    position: relative;
    max-width: 70%;
    height: 65px;
    width: 100%;
    span.error {
      position: absolute;
      bottom: 0px;
      right: 0;
      width: 100%;
      line-height: 14px;
      white-space: pre-wrap;
      color: #ef5b2e;
      font-size: 0.85rem;
    }
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
    position: relative;
    // flex-direction: column;
    > div {
      position: relative;
    }
    .react-datepicker-wrapper {
      width: auto;
    }
    i {
      position: absolute;
      right: 0;
      top: 0;
      width: 36px;
      text-align: center;
      border-left: 2px solid #ccc;
      height: 34px;
      line-height: 31px;
      @media only screen and (max-width: 700px) {
        right: auto;
        top: auto;
      }
      @media only screen and (max-width: 500px) {
        border: none;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
  @media only screen and (max-width: 800px) {
    height: auto;
    display: flex;
    flex-direction: row;
    label {
      min-width: 180px;
      max-width: 180px;
    }
  }
`;
export const PrimaryInfo = styled.div`
  padding-bottom: 60px;
  ${props => (props.spacingBottom ? `padding-bottom: ${props.spacingBottom}${props.unit || "px"};` : "")};

  display: flex;
  flex-direction: column;
  ${props => (props.fullWidth ? "width: 100%;" : "")};
  > div {
    > div {
      max-height: 40px;
      ${props => (props.maxHeight ? `max-height: ${props.maxHeight}${props.unit || "px"};` : "")};
      position: relative;
      @media only screen and (max-width: 500px) {
        max-height: none;
        // margin-bottom: 10px;
        flex-direction: column;
        label {
          text-align: left;
        }
        > div {
          flex-flow: column;
          align-items: flex-start;
          label {
            max-width: 100%;
            width: 100%;
            padding-right: 0;
            text-align: left;
          }
          > div {
            width: 100%;
          }
        }
      }
    }
    label {
      width: 30%;
      max-width: 180px;
      min-width: 180px;
      &.errorStyleDP {
        max-width: 100%;
        text-align: end;
        width: 100%;
      }
      @media only screen and (max-width: 1600px) {
        width: 178px;
        max-width: 180px;
      }
    }
  }
`;
export const PrimarySelectInfo = styled.div`
  padding-bottom: 60px;
  ${props => (props.spacingBottom ? `padding-bottom: ${props.spacingBottom}${props.unit || "px"};` : "")};

  display: flex;
  flex-direction: column;
  ${props => (props.fullWidth ? "width: 100%;" : "")};
  > div {
    max-height: 40px;
    ${props => (props.maxHeight ? `max-height: ${props.maxHeight}${props.unit || "px"};` : "")};
    position: relative;
    @media only screen and (max-width: 500px) {
      max-height: none;
      // margin-bottom: 10px;
      flex-direction: column;
      label {
        text-align: left;
      }
      > div {
        flex-flow: column;
        align-items: flex-start;
        label {
          max-width: 100%;
          width: 100%;
          padding-right: 0;
          text-align: left;
        }
        > div {
          width: 100%;
        }
      }
    }
  }
  label {
    width: 30%;
    max-width: 180px;
    min-width: 180px;
    &.errorStyleDP {
      max-width: 100%;
      text-align: end;
      width: 100%;
    }
    @media only screen and (max-width: 1600px) {
      width: 178px;
      max-width: 180px;
    }
  }
`;
export const LicenseModalSelectionOptions = styled.div`
  width: 100%;
  padding: 60px 50px;
  text-align: left;
  .spacing {
    display: block;
    height: 20px;
    width: 100%;
  }
  @media only screen and (max-width: 1600px) {
    padding: 0 20px;
  }
  @media only screen and (max-width: 1400px) {
    > div {
      flex-flow: column;
      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  p {
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    margin-bottom: 40px;
    i {
      font-size: 1rem;
    }
  }
`;
export const AdditionalLicense = styled.div`
  width: 100%;
  margin: 0;
  .disabled{
    border: solid 1px ${COLORS.WHITE};
    border-radius: ${BORDER_RADIUS.BR_1};
    box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.15);
    width:100%;
    padding:10px 11px;
    height:36px;
    max-width:158px;
    font-weight:${FONTWEIGHT.FW_6};
    font-size:0.87rem;
    color:#999;
    opacity:0.4;
    position:relative;
    i{
      position:absolute;
      right:11px;
      top:9px;
    }
  }
  > div {
    margin-bottom: 0;
    > div {
      padding: 0 0 30px 0;
      border: none;
      display: flex;
      align-items: center;
        }
      }
      @media only screen and (max-width: 700px) {
        flex-flow: column;
        align-items: flex-start;
      }
      label {
        margin-left: 15px;
        width: 71%;
        @media only screen and (max-width: 600px) {
          // margin: 5px 0 5px 0;
          width: 100%;
          font-size: 0.75rem;
        }
      }
    }
  }
  label {
    color: ${COLORS.GREYISH_BROWN_TWO};
    // font-size: 1rem;
  }
  select {
    float: right;
    padding: 8px 19px;
    border: 2px solid ${COLORS.WHITE};
    border-radius: ${BORDER_RADIUS.BR_1};
    font-size: 0.9rem;
    font-weight: ${FONTWEIGHT.FW_6};
    color: ${COLORS.GREYISH_BROWN_THREE};
    height: 43px;
    min-width: 177px;
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }
  > div{

  }
`;
export const AdminLicenceField = styled.div`
  width: 100%;
  display: flex;
  label {
    display: inline-block;
  }
`;
export const NumberSelectHolder = styled.div`
  width: 25%;
  position: relative;
  max-width: 158px;
  .hasError {
    position: absolute;
    bottom: -20px;
    color: ${COLORS.TOMATO};
    right: 0;
    font-size: 0.85rem;
    font-weight: 400;
  }
`;
