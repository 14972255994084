import React from "react";
import * as Yup from "yup";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";
import { Icon, Layout, P } from "../../styled";
import { PageColumn, PageFooter } from "../../styled/layout";
import { Speichem, Abbrechen } from "../../styled/buttons";
import { TextInput } from "../../styled/form";
import FormInput from "../Form/Input";
import Subheading from "../Layout/Subheading";
import Timer from "../Timer";
import CheckBox from "../Form/CheckBox";
import DatePicker from "../Form/DatePicker";
import ActivityEditor from "../ActivityEditor";
import Table from "../Table";
import Select from "../Form/Select";
import CreateNewBeneficiary from "../Modal/CreateNewBeneficiary";
import NewsearchInput from "../NewFilterSearch";
import ResponseWarning from "../Response";
import debounce from "lodash/debounce";
import TravelExpenses from "../Modal/TravelExpenses";
import { replaceStringWithNumber, handleChangeMoneyInput, getMoneyNumber, formatMoneyToString } from "../../utils/helper";
import moment from "moment";
import config from "../../lib/config";
import Axios from "axios";
import { TravelExpensesOuter } from "../../styled/travelexpenses";

const INITIAL_STATE = {
  heading: "",
  billable: false,
  relevanceMonthly: false,
  relevanceQuarterly: false,
  hourlyRate: "",
  linkedPerson: "",
  timer: null,
  is_outside: false,
  task_open: false,
  task_open_date: new Date(),
  is_travel_expense_incurred: false,

  public_note: null,
  internal_note: null,
  billing_info: null,
  report_info: null,
  activityActionFlag: false,
  isEditable: true,
  isUpdate: false,
  isUpdate1: false,
  isHCalculated: false,
  isTravelCalculated: false,
  selectedActivityId: null,
  user_timestamp: null,
  uploading: false,
  travel_expenses: {
    driven_km: "",
    settlement_rate: "",
    total_travel_expense: "",
    is_drive_billable: false,
    overnight_expense: "",
    is_overnight_billable: false,
    rail_cost: "",
    is_rail_billable: false,
    parking_cost: "",
    is_parking_billable: false,
    entertainment_expense: "",
    is_entertainment_billable: false,
    other_expenses: "",
    is_other_expenses_billable: false,
    is_settled: false,
  },
  selectItems: ["select-0"],
  linkedPersons: [],
  personsListingData: [],
};

export const TIMER_ID = "timer_appointment";
class Appointment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };

    this.onSearchChange = debounce(this.onSearchChange, 150);
  }
  async componentDidMount() {
    const {
      store: { assets, activityHistory, clientTask, personsListingCondition },
      client,
      activeActivityId,
    } = this.props;

    assets.fetch({
      clientId: client.client_id,
    });
    activityHistory.fetch({
      clientId: this.props.store.clientStore.selectedClientId,
    });
    personsListingCondition.fetch({
      clientId: this.props.store.clientStore.selectedClientId,
      isBeneficiary: true,
    });
    if (activeActivityId) {
      this.getActivityAndSetValues(activeActivityId);
    }
    await clientTask.fetch({
      clientId: client.client_id,
    });
    if (this.state.isEditable) {
      this.props.store.activityStartVomInfo.onActivityIsSelectedClick(false);
    }
    this.handleLinkedPersonsData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeActivityId) {
      this.getActivityAndSetValues(nextProps.activeActivityId);
    }
    if (this.state.isEditable) {
      this.props.store.activityStartVomInfo.onActivityIsSelectedClick(false);
    }
    this.setState({
      personsListingData: nextProps.store.personsListingCondition.data,
    });
  }

  getActivityAndSetValues = async activeActivityId => {
    await this.props.store.getActivityById.fetch({
      activityId: activeActivityId,
    });
    const selectedActivity = this.props.store.getActivityById.data;
    this.setState({
      isUpdate1: true,
      isHCalculated: selectedActivity.is_hours_calculated_in_sum,
      isTravelCalculated: selectedActivity.is_travel_calculated_in_sum,
      heading: selectedActivity.note_heading,
      billable: selectedActivity.is_billable,
      relevanceMonthly: selectedActivity.is_monthly_report,
      relevanceQuarterly: selectedActivity.is_quarterly_report,
      is_outside: selectedActivity.is_outside,
      task_open: selectedActivity.has_task_open,
      task_open_date: new Date(selectedActivity.task_open_date),
      is_travel_expense_incurred: selectedActivity.is_travel_expense_incurred,
      hourlyRate: selectedActivity.hourly_rate ? formatMoneyToString(selectedActivity.hourly_rate) : "",
      linkedPerson: selectedActivity.linked_person_data
        ? {
          name: selectedActivity.linked_person_data.name,
          value: selectedActivity.linked_person_data.beneficiary_id,
        }
        : "",
      timer: {
        date: moment(selectedActivity.recording_date).toDate(),
        time: selectedActivity.recording_time,
        duration: selectedActivity.recording_duration ? replaceStringWithNumber(selectedActivity.recording_duration) : 0,
        elapsed: selectedActivity.recording_duration ? Number(selectedActivity.recording_duration) * 60000 : 0,
        started: false,
      },
      public_note: selectedActivity.public_note ? JSON.parse(selectedActivity.public_note) : null,
      internal_note: selectedActivity.internal_note ? JSON.parse(selectedActivity.internal_note) : null,
      billing_info: selectedActivity.billing_info ? JSON.parse(selectedActivity.billing_info) : null,
      report_info: selectedActivity.report_info ? JSON.parse(selectedActivity.report_info) : null,
      activityActionFlag: !this.state.activityActionFlag,
      travel_expenses: selectedActivity.activity_expense ? selectedActivity.activity_expense : {},
      isEditable: false,
      isUpdate: false,
      user_timestamp: selectedActivity.user_timestamp,
      errors: {},
    });
  };

  addNewBeneficiary = () => {
    const {
      store: { modal },
      client,
    } = this.props;
    modal.add({
      title: "Weitere Person hinzufügen",
      component: CreateNewBeneficiary,
      props: {
        client,
      },
    });
  };

  handleLinkedPersonsData = async () => {
    const {
      store: { personsListingCondition },
    } = this.props;
    const { linkedPersons } = this.state;
    await personsListingCondition.fetch({
      clientId: this.props.store.clientStore.selectedClientId,
      isBeneficiary: true,
    });
    const newData = personsListingCondition.data.filter(function(el) {
      return !linkedPersons.find(person => {
        return person.key === el.person_id;
      });
    });
    this.setState({
      personsListingData: newData,
    });
  };

  handleCheckboxChange = name => () => {
    this.setState(state => ({
      [name]: !state[name],
    }));
  };

  handleLinkedPersonChange = (person, index) => {
    const { linkedPersons, personsListingData, linkedPerson } = this.state;
    linkedPersons.push(person);
    const newListingData = personsListingData.filter(function(personListing) {
      return personListing.person_id !== person.key;
    });

    this.setState({
      linkedPerson: person,
      linkedPersons,
      personsListingData: newListingData,
    });
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleInputChangeHourlyRate = event => {
    const value = handleChangeMoneyInput(event.target.value);
    if (!!value || value === "") {
      this.setState({ [event.target.name]: value });
    }
  };

  handleReset = () => {
    this.setState({
      ...INITIAL_STATE,
      activityActionFlag: !this.state.activityActionFlag,
      user_timestamp: "",
      errors: {},
      linkedPersons: [],
      selectItems: ["select-0"],
    });
    this.handleLinkedPersonsData();
    window.localStorage.removeItem(TIMER_ID);
  };

  handleSubmit = async () => {
    const {
      store: { newActivity, activityHistory, clientStore, updateActivity, clientTask, activityStartVomInfo },
      client,
    } = this.props;

    const {
      timer,
      heading,
      billable,
      relevanceMonthly,
      relevanceQuarterly,
      hourlyRate,
      is_outside,
      task_open,
      task_open_date,
      is_travel_expense_incurred,
      public_note,
      internal_note,
      billing_info,
      report_info,
      linkedPersons,
      travel_expenses,
      user_timestamp,
    } = this.state;
    let activityTime = this.props.store.getActivityById.data ? this.props.store.getActivityById.data.recording_duration : 0;
    let realTime = timer.duration;
    if (!!timer.duration) {
      realTime =
        Math.abs(Number(timer.duration) - activityTime) <= 0.01
          ? timer.duration * 1
          : Math.floor(timer.duration) * 60 + (timer.duration - Math.floor(timer.duration)) * 100;
    }

    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    const schema = Yup.object().shape({
      heading: Yup.string().required(requiredError),
      billable: Yup.boolean(),
      hourlyRate: Yup.string().when("billable", {
        is: true,
        then: Yup.string().required(requiredError),
      }),
      timer: Yup.object().shape({
        date: Yup.date()
          .typeError(requiredError)
          .max(new Date())
          .required(requiredError),
        elapsed: Yup.number(),
        duration: Yup.string().when("elapsed", elapsed => {
          return elapsed === 0
            ? Yup.string()
              .notOneOf(["__.__"])
              .required(requiredError)
            : Yup.string().notRequired();
        }),
        time: Yup.string()
          .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]/, "Ungültiges Format")
          .notOneOf(["__:__"])
          .required(requiredError),
      }),
    });
    try {
      const x = schema.validateSync(
        {
          heading,
          billable,
          hourlyRate,
          timer,
        },
        { abortEarly: false },
      );
    } catch (e) {
      const errors = {};
      if (e.inner) {
        e.inner.forEach(e1 => {
          const splitted = e1.path.split(".");
          if (splitted.length > 1) {
            // here is the timer;
            errors.timer = errors.timer ? errors.timer : {};
            errors.timer[splitted[1]] = e1.message;
            return;
          }
          errors[e1.path] = e1.message;
        });
      }
      return this.setState({
        errors,
      });
    }

    const hoursOfTime = Number(timer.time[0]) * 10 + Number(timer.time[1]);
    const minutesOfTime = Number(timer.time[3]) * 10 + Number(timer.time[4]);
    const errors = {};
    if (isNaN(hoursOfTime) || isNaN(minutesOfTime)) {
      errors["time"] = "Time format";
      return this.setState({
        errors,
      });
    }
    if (hoursOfTime > 23 || minutesOfTime > 59) {
      errors["time"] = "Time format";
      return this.setState({
        errors,
      });
    }

    let persons = linkedPersons.map(person => person.value);
    const data = {
      activity_type_id: "appointment",
      recording_date: timer.date,
      recording_time: timer.time,
      recording_duration: timer.duration !== "" ? realTime : String((timer.elapsed / 60000).toFixed(2)) * 1,
      note_heading: heading,
      is_billable: billable,
      is_monthly_report: relevanceMonthly,
      is_quarterly_report: relevanceQuarterly,
      is_outside,
      has_task_open: task_open,
      task_open_date,
      is_travel_expense_incurred,
      hourly_rate: getMoneyNumber(hourlyRate),
      public_note: public_note ? JSON.stringify(public_note) : null,
      internal_note: internal_note ? JSON.stringify(internal_note) : null,
      billing_info: billing_info ? JSON.stringify(billing_info) : null,
      report_info: report_info ? JSON.stringify(report_info) : null,
      linked_person: linkedPersons ? persons : null,
      client_id: clientStore.selectedClientId,
      user_timestamp:
        "" +
        moment(new Date()).format("DD.MM.YYYY, HH:mm, ") +
        this.props.store.getUserFromToken.data.first_name +
        " " +
        this.props.store.getUserFromToken.data.last_name,
      files: activityStartVomInfo.files,
      has_document: false,
    };
    if (is_travel_expense_incurred) {
      data.travel_expenses = travel_expenses;
    }
    this.setState({
      uploading: true,
    });
    if (this.state.isUpdate1) {
      const selectedActivity = this.props.store.getActivityById.data;
      const activityId = selectedActivity.activity_id;
      await updateActivity
        .fetch({
          ...data,
          activity_id: activityId,
          activityId,
        })
        .then(() => {
          this.setState({
            uploading: false,
          });
          this.handleReset();
        });
    } else if (this.state.isUpdate) {
      const activityId = this.state.selectedActivityId;
      await updateActivity
        .fetch({
          ...data,
          activity_id: activityId,
          activityId,
        })
        .then(() => {
          this.setState({
            uploading: false,
          });
          this.handleReset();
        });
    } else {
      await newActivity.fetch(data).then(() => {
        this.setState({
          uploading: false,
        });
        this.handleReset();
      });
    }
    await activityHistory
      .fetch({
        clientId: clientStore.selectedClientId,
      })
      .then(() => {
        this.setState({
          uploading: false,
        });
        this.handleReset();
      });
    await clientTask
      .fetch({
        clientId: client.client_id,
      })
      .then(() => {
        this.setState({
          uploading: false,
        });
        this.handleReset();
      });
    if (activityStartVomInfo.files) this.handleFileCreate();
    this.handleReset();
  };

  handleFileCreate = async () => {
    const {
      store: { clientStore, activityStartVomInfo, newActivity },
    } = this.props;

    const files = Array.from(activityStartVomInfo.files);
    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append(i, file);
    });

    // formData.append
    const activityId = newActivity.newActivities.activity_id;
    const fullUri = `${config.endpoints.api}/api/activity/${activityId}/${clientStore.selectedClientId}/file`;
    const requestOptions = {
      method: "PUT",
      data: formData,
    };

    requestOptions.url = fullUri;
    const token = window.localStorage.getItem(config.keys.local_storage_key);
    if (token) {
      requestOptions.headers = {
        Authorization: `${token}`,
      };
    }
    await Axios(requestOptions);
    activityStartVomInfo.setActivityFiles([]);
  };

  handleTimerChange = data => {
    this.setState({ timer: data });
  };

  handleDateChange = date => {
    this.setState({ task_open_date: date });
  };

  handlePersonAdd = () => {
    let { selectItems } = this.state;
    selectItems.push("select-" + selectItems.length);
    this.setState({
      selectItems,
    });
  };

  handlePersonRemove = index => {
    let { selectItems, linkedPersons } = this.state;
    // selectItems.splice(selectItems.indexOf('select-'+index), 1);
    selectItems.pop();
    linkedPersons.splice(index, 1);
    this.setState(
      {
        selectItems,
        linkedPersons,
      },
      () => this.handleLinkedPersonsData(),
    );
  };
  render() {
    const {
      heading,
      billable,
      relevanceMonthly,
      relevanceQuarterly,
      hourlyRate,
      linkedPerson,
      is_outside,
      task_open,
      task_open_date,
      is_travel_expense_incurred,

      public_note,
      internal_note,
      billing_info,
      report_info,
      errors,
      uploading,

      selectItems,
      linkedPersons,
      personsListingData,
    } = this.state;
    const {
      store: { activityHistory, clientTask, personsListingCondition },
    } = this.props;

    return (
      <Layout displayFlex row rowToColumn={1600}>
        <PageColumn flex={6} left>
          <Layout spacingLeft={50} spacingRight={50} mediaPadding={600}>
            <Subheading icon="chrome_reader_mode">Aufgabe / Termin / Tätigkeit</Subheading>
            <Layout offsetBottom={50}>
              {!this.state.isHCalculated && (
                <Timer
                  value={this.state.timer}
                  changeActionFlag={this.state.activityActionFlag}
                  onChange={this.handleTimerChange}
                  timerId={TIMER_ID}
                  errors={errors && errors.timer}
                />
              )}
            </Layout>
            <P offsetBottom={60} type="type-8">
              Notizen erstellen
            </P>
            <Layout displayFlex>
              <Layout displayFlex alignCenter offsetBottom={30}>
                <Layout offsetRight={10}>{"Aufgabe offen"}</Layout>
                <CheckBox onClick={this.handleCheckboxChange("task_open")} checked={task_open} />
              </Layout>
              <Layout offsetLeft={10} offsetTop={-10} displayFlex alignStart justifyStart>
                {task_open && <DatePicker value={task_open_date} onChange={this.handleDateChange} />}
              </Layout>
            </Layout>

            <Layout offsetBottom={60} relative>
              <FormInput
                placeholder="Überschrift Aktivität"
                name="heading"
                value={heading}
                onChange={this.handleInputChange}
                error={errors && errors["heading"]}
              />
            </Layout>
            <ActivityEditor
              onChange={this.onActivityEditorChange}
              notifyActionFlag={this.state.activityActionFlag}
              value={{
                public: public_note,
                internal: internal_note,
                billing: billing_info,
                report: report_info,
              }}
            />
            <Layout displayFlex row width={"100%"} offsetBottom={60} rowToColumn={1024}>
              <Layout flex={1} spacingLeft={10} spacingRight={10}>
                <Layout displayFlex alignCenter justifyBetween offsetBottom={30}>
                  {"Außer Haus"}
                  <CheckBox onClick={this.handleCheckboxChange("is_outside")} checked={is_outside} />
                </Layout>
                <Layout displayFlex alignCenter justifyBetween offsetBottom={30}>
                  {"Abrechenbar"}
                  {!this.state.isHCalculated && <CheckBox onClick={this.handleCheckboxChange("billable")} checked={billable} />}
                </Layout>
              </Layout>

              <Layout flex={1} spacingLeft={10} spacingRight={10}>
                <Layout displayFlex alignCenter justifyBetween offsetBottom={30}>
                  {"Relevanz Monats-Bericht"}
                  <CheckBox onClick={this.handleCheckboxChange("relevanceMonthly")} checked={relevanceMonthly} />
                </Layout>
                <Layout displayFlex alignCenter justifyBetween offsetBottom={30}>
                  {"Relevanz Quartals-Bericht"}
                  <CheckBox onClick={this.handleCheckboxChange("relevanceQuarterly")} checked={relevanceQuarterly} />
                </Layout>
              </Layout>

              <Layout flex={1} spacingLeft={10} spacingRight={10}>
                <Layout displayFlex alignCenter justifyBetween offsetBottom={30}>
                  {"Reisekosten angefallen"}
                  {!this.state.isTravelCalculated && <CheckBox onClick={this.onTravelExpenseCheck} checked={is_travel_expense_incurred} />}
                  {!this.state.isTravelCalculated && is_travel_expense_incurred && <p onClick={this.openTravelExpense}>View</p>}
                </Layout>
                {!this.state.isHCalculated && (
                  <Layout relative>
                    <FormInput
                      noOffset
                      value={hourlyRate}
                      name="hourlyRate"
                      onChange={this.handleInputChangeHourlyRate}
                      placeholder="Stundenverrechnungssatz"
                      error={errors && errors["hourlyRate"]}
                    />
                  </Layout>
                )}
              </Layout>
            </Layout>
            <Layout displayFlex alignStart justifyStart offsetBottom={60} rowToColumn={700} centerToStart={700}>
              {/* {"Person verknüpfen"} */}
              <P type="type-4" offsetRight={20}>
                {" "}
                {"Person verknüpfen"}{" "}
              </P>
              <Layout offsetRight={10} maxWidth={340} fullWidth>
                {personsListingData &&
                  selectItems.map((select, index) => (
                    // console.log("--------------", index, select, personsListingData, "kkkkkkk", linkedPersons),
                    <Layout key={linkedPersons[index] ? linkedPersons[index].key : index} displayFlex>
                      <Select
                        noAdapter
                        withNameAndValue
                        changeActionFlag={this.state.activityActionFlag}
                        selected={linkedPersons[index] ? linkedPersons[index] : ""}
                        onChange={this.handleLinkedPersonChange}
                        options={personsListingData.map(a => {
                          return {
                            name: `${a.first_name} ${a.last_name}`,
                            value: a.person_id,
                            key: a.person_id,
                          };
                        })}
                        placeholder="Person / Organisation verknüpfen"
                        disableSelect={linkedPersons[index] ? true : false}
                      />
                      <Layout offsetLeft={10}>
                        <Icon onClick={() => this.handlePersonRemove(index)} type="material-icons">
                          delete
                        </Icon>
                      </Layout>
                    </Layout>
                  ))}
              </Layout>
              <Layout offsetRight={10}>
                <Icon
                  onClick={personsListingCondition.data && selectItems.length < personsListingCondition.data.length && this.handlePersonAdd}
                  type="material-icons"
                >
                  add
                </Icon>
              </Layout>
              <Layout offsetRight={10}>
                <Icon onClick={this.addNewBeneficiary} type="material-icons">
                  person_add
                </Icon>
              </Layout>
            </Layout>
          </Layout>
          <PageFooter>
            <Layout displayFlex row alignCenter justifyBetween rowToColumn={1024}>
              <TextInput
                disabled={this.state.isUpdate}
                name="user_timestamp"
                value={this.state.user_timestamp}
                onChange={this.handleInputChange}
                maxWidth={220}
                footer
                placeholder="User / Timestamp"
                disabled
              />
              <Layout alignCenter displayFlex rowToColumn={786}>
                <Abbrechen margin={"20px 15px"} onClick={this.handleReset}>
                  Abbrechen
                </Abbrechen>
                {((!this.state.isHCalculated && !this.state.isTravelCalculated) || this.state.isEditable) && (
                  <Speichem onClick={!uploading && this.handleSubmit}>
                    {uploading ? "Ihre Daten wird verarbeitet" : this.state.isUpdate ? "Aktualisierung" : "Speichern"}
                  </Speichem>
                )}
              </Layout>
            </Layout>
          </PageFooter>
        </PageColumn>
        <PageColumn flex={4}>
          <Layout spacingLeft={20}>
            <Subheading>Offene Aufgaben</Subheading>
          </Layout>
          {clientTask.constructedTable && clientTask.constructedTable.rows[0] ? (
            <Table hideHeader hideFooter small tableStore={clientTask} onClickRow={this.handleClientTaskRowClick} />
          ) : (
            <ResponseWarning />
          )}
          <Layout spacingLeft={20} spacingRight={20} mediaPadding={600}>
            <Subheading icon="date_range">Historie</Subheading>
            <NewsearchInput onChange={this.onSearchChange} />
          </Layout>
          {activityHistory.constructedTable && activityHistory.constructedTable.rows[0] ? (
            <Table hideHeader hideFooter small tableStore={activityHistory} onClickRow={this.handleRowClick} />
          ) : (
            <ResponseWarning />
          )}
        </PageColumn>
      </Layout>
    );
  }

  handleRowClick = index => {
    const {
      store: { activityHistory },
    } = this.props;
    const selectedActivity = activityHistory.data[index];
    this.props.changeActiveId(activityHistory.data[index].activity_type_id, selectedActivity.activity_id);

    this.props.store.activityStartVomInfo.onChangeDate(selectedActivity.recording_date);
    this.props.store.activityStartVomInfo.onActivityIsSelectedClick();
  };

  handleClientTaskRowClick = async index => {
    const {
      store: { getActivityById, clientTask },
    } = this.props;
    await getActivityById.fetch({
      activityId: clientTask.data[index].activity_id,
    });
    const selectedActivity = getActivityById.data;

    this.setState({
      heading: selectedActivity.note_heading,
      billable: selectedActivity.is_billable,
      relevanceMonthly: selectedActivity.is_monthly_report,
      relevanceQuarterly: selectedActivity.is_quarterly_report,
      is_outside: selectedActivity.is_outside,
      task_open: selectedActivity.has_task_open,
      task_open_date: new Date(selectedActivity.task_open_date),
      is_travel_expense_incurred: selectedActivity.is_travel_expense_incurred,
      hourlyRate: selectedActivity.hourly_rate ? replaceStringWithNumber(selectedActivity.hourly_rate) : "",
      linkedPerson: selectedActivity.linked_person_data
        ? {
          name: selectedActivity.linked_person_data.name,
          value: selectedActivity.linked_person_data.beneficiary_id,
        }
        : "",
      timer: {
        date: moment(selectedActivity.recording_date).toDate(),
        time: selectedActivity.recording_time,
        duration: selectedActivity.recording_duration,
      },
      public_note: selectedActivity.public_note ? JSON.parse(selectedActivity.public_note) : null,
      internal_note: selectedActivity.internal_note ? JSON.parse(selectedActivity.internal_note) : null,
      billing_info: selectedActivity.billing_info ? JSON.parse(selectedActivity.billing_info) : null,
      report_info: selectedActivity.report_info ? JSON.parse(selectedActivity.report_info) : null,
      activityActionFlag: !this.state.activityActionFlag,
      travel_expenses: selectedActivity.activity_expense ? selectedActivity.activity_expense : {},
      isUpdate: true,
      isEditable: true,
      selectedActivityId: selectedActivity.activity_id,
    });
  };

  onActivityEditorChange = changed => {
    const { public: public_note, internal, billing, report } = changed;
    this.setState({
      public_note,
      internal_note: internal,
      billing_info: billing,
      report_info: report,
    });
  };

  onSearchChange = val => {
    this.props.store.activityHistory.fetch({
      search: val,
      clientId: this.props.store.clientStore.selectedClientId,
    });
  };

  onTravelExpenseCheck = () => {
    const { is_travel_expense_incurred, travel_expenses } = this.state;
    if (!is_travel_expense_incurred) {
      this.props.store.modal.add({
        title: "Reisekosten erfassen",
        component: props => <TravelExpenses {...props} value={travel_expenses} onSave={this.onTravelExpenseSave} />,
      });
      this.openTravelExpense();
    }
    this.setState({
      is_travel_expense_incurred: !is_travel_expense_incurred,
    });
  };

  openTravelExpense = () => {
    const { travel_expenses } = this.state;
    this.props.store.modal.add({
      title: "Reisekosten erfassen",
      component: props => <TravelExpenses {...props} value={travel_expenses} onSave={this.onTravelExpenseSave} />,
    });
  };

  onTravelExpenseSave = values => {
    const { travel_expenses } = this.state;
    this.setState({
      travel_expenses: {
        ...travel_expenses,
        ...values,
      },
    });
  };
}

export default compose(
  inject("store"),
  observer,
)(Appointment);
