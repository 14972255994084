import styled from "styled-components";
import { COLORS, FONTWEIGHT } from "./constants";
import tick from "../assets/images/tick.png";

export const CustomCheckboxOuetr = styled.div`
  width: 18px;
  height: 18px;
  position: relative;
  [type="checkbox"] {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
    width: 18px;
    height: 18px;

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }

    // Box.
    & + label:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 18px;
      height: 18px;
      background: #fff;
      box-sizing: border-box;
      border: 2px solid ${COLORS.NASTY_GREEN};
      border-radius: 2px;
    }

    // Box hover
    &:hover + label:before {
      background: #fff;
    }

    // Box focus
    &:focus + label:before {
      // box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
      background: ${COLORS.NASTY_GREEN};
    }

    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: "";
      position: absolute;
      left: 1px;
      top: 1px;
      background: white;
      width: 16px;
      height: 16px;
      background: url(${tick}) no-repeat center center;
    }
  }
`;
