import React from "react";
import { Icon } from "../../styled";
import { CheckBoxContainer } from "../../styled/form";

const CheckBox = ({ onClick, checked, disabled }) => (
  <CheckBoxContainer disabled={disabled} onClick={onClick} {...{ checked }} className={checked ? "checked" : ""}>
    {checked && <Icon>check</Icon>}
  </CheckBoxContainer>
);

export default CheckBox;
