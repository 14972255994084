import React, { Component } from "react";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";

import { Layout } from "../styled";
import UserProfileMandantenNumber from "../components/UserProfileCard/UserProfileMandatenNumber";
import config from "../lib/config";
import Userphoto from "../assets/images/empty.png";
import Table from "../components/Table";

class DecliningRevisions extends Component {
  async componentDidMount() {
    const {
      store: { client, clientStore, decliningRevisions },
      // client
    } = this.props;

    client.fetch({
      clientId: clientStore.selectedClientId,
    });

    await decliningRevisions.fetch({
      clientId: clientStore.selectedClientId,
      declining: 1,
    });
    this.forceUpdate();
  }
  render() {
    const {
      store: { client, assets, decliningRevisions },
    } = this.props;
    const imageSource =
      client.data.profile_image_url === "uploads/empty.png" ? Userphoto : `${config.endpoints.api}/${client.data.profile_image_url}`;

    return (
      <React.Fragment>
        <Layout overflowHidden>
          <Layout
            boxShadow="type_1"
            displayFlex
            row
            alignCenter
            ustifyStart
            spacingLeft={55}
            spacingTop={40}
            spacingBottom={60}
            zIndex={100}
            relative
            background="white"
            mediaSidePadding={600}
          >
            {client.data && (
              <UserProfileMandantenNumber
                firstName={client.data.first_name}
                imageSource={imageSource}
                title={client.data.title || ""}
                lastName={client.data.last_name}
                mandantenNumber={client.data.client_number}
                birthday={client.data.birth_date}
                showDate={assets.data}
                updatedAt={assets.data ? assets.data.lastUpdate : null}
              />
            )}
          </Layout>
        </Layout>
        <Table tableStore={decliningRevisions} />
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(DecliningRevisions);
