import React from "react";
import styled from "styled-components";
import { COLORS } from "../../styled/constants";
import { Icon } from "../../styled";

const FloatingContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: ${COLORS.TURTLE_GREEN_TWO};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 8px 0 rgba(0, 0, 0, 0.17);
  transition: all 0.3s ease;
  cursor: pointer;

  i {
    color: white;
    font-size: 26px;
  }

  &:hover {
    box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0.17);
  }
`;

const FloatingAdd = ({ onClick }) => (
  <FloatingContainer onClick={onClick}>
    <Icon>add</Icon>
  </FloatingContainer>
);

export default FloatingAdd;
