import styled from "styled-components";
import { COLORS, BORDER_RADIUS, FONTWEIGHT } from "./constants";

export const AddNewQuestionOuter = styled.div`
  width: 705px;
  padding: 0 55px;
  h5 {
    text-decoration: underline;
    font-size: 1.12rem;
    font-weight: ${FONTWEIGHT.FW_6};
    color: ${COLORS.PINE};
    margin-right: 27px;
    @media only screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }
  > div {
    > div {
      border: none;
      padding: 0;
      @media only screen and (max-width: 600px) {
        > div {
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          h5 {
            margin-bottom: 20px;
            font-size: 1rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 800px) {
    width: 600px;
    padding: 0 25px;
  }
  @media only screen and (max-width: 650px) {
    width: 580px;
  }
  @media only screen and (max-width: 600px) {
    width: 500px;
  }
  @media only screen and (max-width: 540px) {
    width: 100%;
    min-width: 400px;
  }
  @media only screen and (max-width: 450px) {
    min-width: 300px;
  }
`;
export const FormFieldItems = styled.div`
  width: 100%;
  padding-bottom: 18px;
  label {
    color: ${COLORS.GREYISH_BROWN_TWO};
    font-size: 1rem;
    width: 180px;
    padding-right: 20px;
    white-space: nowrap;
  }
  input {
    max-width: 411px;
    padding: 6px 11px;
    height: 43px;
  }
  select {
    max-width: 246px;
    @media only screen and (max-width: 600px) {
      max-width: none;
    }
  }
  textarea {
    margin-top: 20px;
    border: 2px solid ${COLORS.WHITE};
    height: 130px;
    width: 100%;
    padding: 19px 21px;
    margin-bottom: 45px;
    font-size: 0.9rem;
    color: ${COLORS.MEDIUM_GREY_FIVE};
    border-radius: ${BORDER_RADIUS.BR_1};
    resize: none;
    @media only screen and (max-width: 600px) {
      margin-top: 0px;
      margin-bottom: 15px;
    }
  }
  .level1 {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    > div {
      width: 100%;
    }
    input[type="text"] {
      max-width: 210px;
    }
    label {
      color: ${COLORS.PINE};
      font-size: 0.9rem;
      font-weight: ${FONTWEIGHT.FW_6};
      margin-left: 17px;
    }
  }
  .level2 {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 34px;
    label {
      color: ${COLORS.PINE};
      font-size: 0.9rem;
      font-weight: ${FONTWEIGHT.FW_6};
      margin-left: 17px;
    }
    @media only screen and (max-width: 500px) {
      padding-left: 0;
    }
  }
`;
export const ResponseOption = styled.div`
  padding: 20px 0 0 0;
  .level1 {
    margin: 13px 0;
    input[type="text"] {
      max-width: 395px;
      color: ${COLORS.DARK_SAGE_SIX};
      font-weight: ${FONTWEIGHT.FW_6};
      @media only screen and (max-width: 600px) {
        max-width: 100%;
        font-size: 0.8rem;
      }
      @media only screen and (max-width: 500px) {
        font-size: 0.75rem;
      }
    }
    &.flexmobile {
      > div {
        @media only screen and (max-width: 600px) {
          flex-flow: column;
        }
      }
    }
  }
  .level2 {
    margin-top: 25px;
    &:last-child {
      margin-top: 0;
    }
    @media only screen and (max-width: 600px) {
      margin-top: 0;
    }
  }
  .QuestionSplitOuter {
    display: flex;
    width: 60%;
    @media only screen and (max-width: 600px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
export const QuestionModalFooterOuter = styled.div`
  button {
    position: relative;
    min-width: 223px;
    padding-right: 20px;
    i {
      position: absolute;
      right: 23px;
      top: 15px;
      @media only screen and (max-width: 600px) {
        top: 12px;
      }
    }
  }
`;
