export const endpoints = {
  get_test: {
    method: "GET",
    uri: "/api/app",
  },
  login: {
    method: "POST",
    uri: "/api/auth/login",
  },
  forgotpassword: {
    method: "POST",
    uri: "/api/admin/forgot_password",
  },
  resetpassword: {
    method: "POST",
    uri: "/api/admin/reset_password",
  },
  change_password: {
    method: "put",
    uri: "/api/admin/change_password",
  },
  signup: {
    method: "POST",
    uri: "/api/auth/signup",
  },
  mock: {
    method: "GET",
    uri: "/",
  },
  get_documents: {
    method: "GET",
    uri: "/api/document/list/{client_id}",
  },
  search_documents: {
    method: "GET",
    uri: "/api/document/search",
  },
  get_user_templates: {
    method: "GET",
    uri: "/api/template/list",
  },
  create_template: {
    method: "POST",
    uri: "/api/template/upload",
  },
  edit_document: {
    method: "PUT",
    uri: "/api/document/{documentId}",
  },
  // Clients
  get_client: {
    method: "GET",
    uri: "/api/clients/{clientId}",
  },
  get_all_clients: {
    method: "GET",
    uri: "/api/clients",
  },
  client_auto_search: {
    method: "GET",
    uri: "/api/clients/user/live_search",
  },
  client_age_phases: {
    method: "GET",
    uri: "/api/clients/phase/age",
    // uri: "v2/5d07355b3000008600051e17"
  },
  search_clients: {
    method: "GET",
    uri: "/api/clients/search/query",
  },
  // Assets
  get_assets: {
    method: "GET",
    uri: "/api/assets/client/{clientId}",
  },
  get_last_asset_revision: {
    method: "GET",
    uri: "/api/assets/client/{clientId}/revisions/last",
  },
  get_revision_files: {
    method: 'POST',
    uri: '/api/assets/client/{clientId}/revisions/download'
  },
  edit_asset: {
    method: "PUT",
    uri: "/api/assets/asset/{assetId}",
  },
  delete_asset: {
    method: "DELETE",
    uri: "/api/assets/asset/{assetId}",
  },
  new_asset: {
    method: "POST",
    uri: "/api/assets/client/{clientId}",
  },
  new_beneficiary: {
    method: "POST",
    uri: "/api/assets/client/{clientId}/asset/{assetId}/beneficiary",
  },
  new_beneficiary_without_asset: {
    method: "POST",
    uri: "/api/assets/client/{clientId}/beneficiary",
  },
  update_beneficiary_amount: {
    method: "PUT",
    uri: "/api/assets/asset/{assetId}/{clientId}/{beneficiaryId}/amount",
  },
  update_asset_field: {
    method: "PUT",
    uri: "/api/assets/fields/{fieldId}",
  },
  delete_asset_file: {
    method: "DELETE",
    uri: "/api/assets/asset/{fileId}/delete",
  },
  // Asset category
  edit_asset_category: {
    method: "PUT",
    uri: "/api/assets/asset_category/{assetCategoryId}",
  },
  new_asset_category: {
    method: "POST",
    uri: "/api/assets/client/{clientId}/category",
  },
  // Assets revisions
  get_assets_revisions: {
    method: "GET",
    uri: "/api/assets/client/{clientId}/revisions/list",
  },
  get_single_asset_revision: {
    method: "GET",
    uri: "/api/assets/client/{clientId}/revisions/{revisionId}",
  },
  save_asset_revision: {
    method: "POST",
    uri: "/api/assets/client/{clientId}/revisions/{revisionId}",
  },
  delete_asset_from_revision: {
    method: "DELETE",
    uri: "/api/assets/client/{clientId}/revisions/{revisionId}/{assetId}",
  },
  create_asset_revision: {
    method: "PUT",
    uri: "/api/assets/client/{clientId}",
  },
  // Activities
  new_activity: {
    method: "POST",
    uri: "/api/activity/create",
  },
  activity_history: {
    method: "GET",
    uri: "/api/activity/list",
  },
  //Licence
  license_management: {
    method: "GET",
    uri: "/api/admin/licence",
  },
  get_all_licences: {
    method: "GET",
    uri: "/api/admin/allLicences",
  },
  get_licence_count: {
    method: "GET",
    uri: "/api/admin/get_licence_count",
  },
  download_licence_invoice: {
    method: "GET",
    uri: "/api/admin/download_licence_invoice/{licenceInvoiceId}",
  },
  download_template: {
    method: "POST",
    uri: "/api/template/download_template",
  },
  user_administration: {
    method: "GET",
    uri: "/api/admin/get_users",
  },
  individual_client_access: {
    method: "GET",
    uri: "/api/clients/access/individual_clients",
  },
  //User
  add_user: {
    method: "POST",
    uri: "/api/admin/add_user",
  },
  user_dashboard: {
    method: "GET",
    // uri: "v2/5cf64b6b32000015298cd18e"
    uri: "/api/clients",
  },
  update_user: {
    method: "POST",
    uri: "/api/admin/update_user",
  },
  update_admin_role: {
    method: "put",
    uri: "/api/admin/change_user_role",
  },
  next_appointment: {
    method: "GET",
    uri: "/api/activity/task/list",
  },
  get_user: {
    method: "GET",
    uri: "/api/admin/get_user_details",
  },
  license_settlement: {
    method: "GET",
    uri: "/api/admin/get_stripe_transactions",
  },
  licence_purchase: {
    method: "POST",
    uri: "/api/admin/licence",
  },
  cancel_licence: {
    method: "put",
    uri: "/api/admin/licence",
  },
  user_signup: {
    method: "POST",
    uri: "/api/admin/user_sign_up",
  },
  add_new_client: {
    method: "POST",
    uri: "/api/clients",
  },
  update_client: {
    method: "put",
    uri: "/api/clients/{clientId}",
  },
  delete_client: {
    method: "put",
    uri: "/api/clients/delete/{clientId}",
  },
  create_client_notes: {
    method: "POST",
    uri: "/api/clients/notes",
  },
  update_client_notes: {
    method: "PUT",
    uri: "/api/clients/notes/update_notes",
  },
  get_client_notes: {
    method: "GET",
    uri: "/api/clients/notes/{clientId}",
  },
  get_persons: {
    method: "GET",
    uri: "/api/clients/person/{clientId}",
  },
  update_persons: {
    method: "PUT",
    uri: "/api/clients/update-person/{personId}",
  },
  create_persons: {
    method: "POST",
    uri: "/api/clients/person",
  },
  get_templates: {
    method: "GET",
    uri: "/api/clients/template",
  },
  get_client_task: {
    method: "GET",
    uri: "/api/activity/client/task/{clientId}",
  },
  get_me: {
    method: "GET",
    uri: "/api/auth/me",
  },
  get_activity_by_id: {
    method: "GET",
    uri: "/api/activity/{activityId}",
  },
  update_activity_by_id: {
    method: "PUT",
    uri: "/api/activity/update/{activityId}",
  },
  getBills: {
    method: "GET",
    uri: "/api/billing/{clientID}",
  },
  createBill: {
    method: "POST",
    uri: "/api/billing/create",
  },
  getClientExpenses: {
    method: "GET",
    uri: "/api/activity/client/{clientId}/client-expenses",
  },
  changeActivitiesCalculated: {
    method: "POST",
    uri: "/api/activity/client/change-calculated",
  },
  act_between_dates: {
    method: "GET",
    uri: "/api/activity/between",
  },
  get_persons_condition: {
    method: "GET",
    uri: "/api/clients/person-condition/{clientId}",
  },
  get_persons_search: {
    method: "GET",
    uri: "/api/clients/person-search/{clientId}",
  },
  get_fav_clients: {
    method: "GET",
    uri: "/api/clients/get_all_fav_client",
  },
  make_fav_client: {
    method: "POST",
    uri: "/api/clients/make_fav_client",
  },
  remove_fav_client: {
    method: "DELETE",
    uri: "/api/clients/remove_fav_client/{client_id}",
  },
  get_all_questions: {
    method: "GET",
    uri: "/api/question/getAllQuestions/{clientId}",
  },
  update_questions: {
    method: "POST",
    uri: "/api/question/updateQuestions",
  },
  add_question: {
    method: "POST",
    uri: "/api/question/addQuestion/{clientId}",
  },
  update_question: {
    method: "PUT",
    uri: "/api/question/update"
  },
  get_questions_by_category: {
    method: "GET",
    uri: "/api/question/getQuestions",
  },
  get_reports: {
    method: "GET",
    uri: "/api/report/getAllReports/{clientId}",
  },
  create_report: {
    method: "POST",
    uri: "/api/report/createReport/{clientId}",
  },
  delete_report: {
    method: "PUT",
    uri: "/api/report/deleteReport/{reportId}",
  },
  create_news: {
    method: "POST",
    uri: "/api/news/createNews",
  },
  get_news: {
    method: "GET",
    uri: "/api/news/getNews",
  },
  delete_news: {
    method: "DELETE",
    uri: "/api/news/delete/{newsId}"
  },
  edit_news: {
    method: "PUT",
    uri: "/api/news/edit/{newsId}"
  },
  create_policy: {
    method: "POST",
    uri: "/api/policy/create",
  },
  get_policies: {
    method: "GET",
    uri: "/api/policy/list/{clientId}",
  },
  activate_account: {
    method: "POST",
    uri: "/api/auth/activate",
  },
  get_policy: {
    method: "GET",
    uri: "/api/policy/get/{policyId}"
  },
  download_policy: {
    method: "POST",
    uri: "/api/policy/download_policy"
  },
  delete_policy_file: {
    method: "DELETE",
    uri: "/api/policy/delete/{policyId}",
  },
  update_policy: {
    method: "PUT",
    uri: "/api/policy/update"
  },
  search_policies: {
    method: "GET",
    uri: "/api/policy/search",
  },
  client_licence_create: {
    method: "POST",
    uri: "/api/new-clients/create",
  },
  client_licence_get: {
    method: "GET",
    uri: "/api/new-clients/find/{clientId}",
  },
  client_licence_extend: {
    method: "POST",
    uri: "/api/new-clients/extend",
  },
  client_licence_cancel: {
    method: "POST",
    uri: "/api/new-clients/cancel",
  }
};
