import React from "react";

import { Layout } from "../../styled";
import Axios from "axios";
import FileDownload from "js-file-download";
// import TextInput from '../Inputs/TextInput/Index';
import FormInput from "../Form/Input";
import ButtonActions from "../Mandates/ButtonActions";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AssetTableButton } from "../../styled/buttons";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { ToastsStore, ToastsContainer } from "react-toasts";
import config from "../../lib/config";
import { toJS } from "mobx";

class ReportFooter extends React.Component {
  downloadAssetDir = async () => {
    const {
      store: { getReports, getRevisionFiles, assetRevisionCreate, clientStore },
    } = this.props;

    const token = window.localStorage.getItem(config.keys.local_storage_key);

    const selected = toJS(getReports.selected || {});

    // const list = ["f8e2a0b0-c243-11e9-8f16-9d5ca31b68f4"];
    // const list = ["730e6b10-c214-11e9-a6c6-4d118c127b54", "c45b5cb0-c243-11e9-81c4-11aba0e0da7a"];
    const listFromSelected = Object.keys(selected)
      .filter(k => !!selected[k])
      .map(el => el);
    let list = [];
    getReports.data.forEach(element => {
      if (listFromSelected.includes(element.report_id)) {
        list.push(element.report_id);
      }
    });

    Axios({
      method: "POST",
      url: config.endpoints.api + `/api/report/getReport/download`,
      responseType: "application/json",
      headers: { Authorization: `${token}` },
      data: {
        reportIds: list.length > 0 ? list : undefined,
        clientId: clientStore.selectedClientId,
      },
    }).then(res => {
      // const array = ["1", "2"];
      const array = res.data || [];
      array.forEach((uri, i) => {
        setTimeout(() => {
          window.open(`${config.endpoints.api}/reportdownload/${uri}`);
        }, i * 500);
      });

      getReports.clearSelected();
    });
  };

  render() {
    return (
      <Layout offsetTop={20} displayFlex row alignEnd justifyEnd rowToColumn={1100} mediaCenter={1100}>
        <AssetTableButton style={{ height: 45 }} width={170} marginLeft={20} mediaFullWidth={1100} onClick={this.downloadAssetDir}>
          Herunterladen
          <i class="material-icons" style={{ marginLeft: 10 }}>
            save_alt
          </i>
        </AssetTableButton>
        <ToastsContainer lightBackground store={ToastsStore} />
      </Layout>
    );
  }
}

export default compose(
  observer,
  inject("store"),
)(ReportFooter);
