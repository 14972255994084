export const individualClientAccessOrderAdapter = {
  client_number: {
    key: "row_1",
    order: 0,
    scale: 2,
    title: "Mandanten-ID",
  },
  first_name: {
    key: "row_2",
    order: 1,
    scale: 2,
    title: "Vorname",
  },
  last_name: {
    key: "row_3",
    order: 2,
    scale: 1,
    title: "Nachname",
  },
  has_access: {
    key: "row_4",
    order: 3,
    scale: 1,
    title: "Zugriff",
  },
};

export const individualClientAccessHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

/*****modal*****/

export const individualClientAccessRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          case "options":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "select",
              options: item[itemKey],
            };
            break;
          case "has_access":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "checkbox",
              has_access: item.has_access,
              client_id: item["client_id"],
            };
            break;
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          }
        }
      });
      rows[index] = row;
    });
  }
  return rows;
};
