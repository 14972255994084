import styled, { createGlobalStyle } from "styled-components";
import { COLORS, BORDER_RADIUS, FONTWEIGHT } from "./constants";
import RootDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowIcon from "../assets/images/right.png";

export const DatePicker = styled(RootDatePicker)`
  color: ${COLORS.PINE};
  width: 100%;
  max-width: 395px;
  padding: 10px 11px;
  font-size: 13px !important;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 11px;
  text-align: left;
  border: none;
  flex: 1;
`;

export const DatePickerContainer = styled.div`
  border: solid 2px ${COLORS.WHITE};
  border-radius: ${BORDER_RADIUS.BR_1};
  display: flex;
  min-width: 180px;
  max-width: 200px;
  cursor: pointer;
  .react-datepicker__input-container {
    input {
      min-width: 115px;
    }
  }
  .react-datepicker {
    box-shadow: -2.2px 13.8px 38px 0 rgba(0, 0, 0, 0.27);
    border: none;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker-popper {
    .react-datepicker {
      width: 350px;
    }
    .react-datepicker__month-container {
      width: 100%;
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2.5rem;
      line-height: 2.4rem;
      font-size: 0.93rem;
      color: ${COLORS.GREYISH_BROWN_THREE};
    }
    .react-datepicker__day-name {
      color: ${COLORS.GREYISH_BROWN_THREE};
    }
    .react-datepicker__day--outside-month {
      color: ${COLORS.GREYISH};
    }
    .react-datepicker__day--today,
    .react-datepicker__day--selected {
      border-radius: ${BORDER_RADIUS.BR_5};
      background: ${COLORS.TURTLE_GREEN};
      color: #fff;
    }
  }
  .react-datepicker__header {
    background: ${COLORS.LIGHT_GREY_NINE};
    border: 1px solid ${COLORS.LIGHT_GREY_THREE};
    padding: 0;
    .react-datepicker__current-month {
      background: ${COLORS.TURTLE_GREEN_TWO};
      padding: 12px 0;
      color: #fff;
      font-size: 1.2rem;
      font-weight: ${FONTWEIGHT.FW_6};
    }
    .react-datepicker__day-names {
      color: ${COLORS.WARM_GREY_TWO};
      font-size: 0.93rem;
    }
  }
  .react-datepicker__navigation {
    // top: 15px;
    // height: 11px;
    width: 9px;
  }
  .react-datepicker__navigation--next {
    background: url(${ArrowIcon}) no-repeat 0 0;
    width: 13px;
    border: none;
    height: 19px;
  }
  .react-datepicker__navigation--previous {
    background: url(${ArrowIcon}) no-repeat 0 0;
    width: 13px;
    height: 19px;
    border: none !important;
    transform: rotate(180deg);
  }
  .react-datepicker__navigation--previous {
    border-style: solid;
    border-width: 4px 8px 4px 0;
    border-color: transparent #fff transparent transparent;
  }
  .react-datepicker__year-dropdown--scrollable {
    height: 90px;
  }
`;

export const DatePickerIconContainer = styled.div`
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: ${COLORS.TURTLE_GREEN_TWO};
  border-left: solid 2px ${COLORS.WHITE};
  @media only screen and (max-width: 500px) {
    padding: 0 20px;
  }
`;

export const TimeSpinnerContainer = styled.div`
  width: 55px;
  height: 55px;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.08;
  letter-spacing: 0.1px;
  text-align: center;
  color: ${COLORS.PINE};
  border: 2px solid ${COLORS.TURTLE_GREEN_TWO};
  border-radius: 27.5px;
  display: flex;
  position: relative;
`;

export const RotationStyle = createGlobalStyle`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

export const TimeSpinnerInner = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  right: 0;
  bottom: 0;
  width: 55px;
  height: 55px;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.08;
  letter-spacing: 0.1px;
  text-align: center;
  color: ${COLORS.PINE};
  border-radius: 27.5px;
  display: flex;
  ${props =>
    props.animating &&
    `
    animation: rotate 5s linear infinite;
  `}
  &:after {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 3.5px;
    content: "";
    background-color: ${COLORS.TURTLE_GREEN_TWO};
    top: 1px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export const DatepickerCustom = styled.div`
 position:relative;
 padding-right:22px;
 span{
  color:${COLORS.TOMATO};
  font-size:0.86rem;
  position:absolute;
  bottom:0;
  right:0;
  white-space:nowrap;
  width:100%;
}
// > label{
//   max-width:none !important;
//   width:100% !important;
//   display:inline-block;
//   padding-bottom:36px;
//   position:relative;
}
`;

export const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (min-width: 1600px) and (max-width: 2117px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  @media only screen and (min-width: 1220px) and (max-width: 1600px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 1220px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
`;

export const TimerInputContainer = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (min-width: 1600px) and (max-width: 1800px) {
    justify-content: space-between;
    width: 100%;
    > div {
      &: last-child {
        padding-right: 0;
      }
    }
  }
  @media only screen and (min-width: 600px) and (max-width: 1024px) {
    justify-content: space-between;
    width: 100%;
    > div {
      &: last-child {
        padding-right: 0;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: start;
    width: 100%;
    align-items: start;
    > div {
      width: 100%;
      padding-right: 0;
    }
  }
`;
