import React from "react";
import { compose } from "recompose";
import * as Yup from "yup";
import { inject, observer } from "mobx-react";

import { Layout } from "../../../styled";
import { Button } from "../../../styled/buttons";
import { ModalFooterOuter, ModalContent } from "../../../styled/modal";
import { PageColumn, ResponseMessage } from "../../../styled/layout";
import CheckBox from "../../Form/CheckBox";
import FormInput from "../../../components/Form/Input";
import TextareaInput from "../../../components/Form/Textarea";
import DatePicker from "../../../components/Form/DatePicker";
import FormSelect from "../../../components/Form/InputSelect";
import { PersonalInfoOuter, InfoFormWrapper, FormInputCustom, FormCheckboxCustom } from "../../../styled/personalinfo";
import { toJS } from "mobx";
import { browserHistory } from "../../../routes/routes";
import { FormInputLabel } from "../../../styled/form";
import { MobileInfo } from "../../../styled/modal";
import { DatePickerItem } from "../../../styled/signup";

const INITIAL_STATE = {
  dateOfBirth: "",
  isBeneficiary: false,
  salutation: null,
  firstName: null,
  place: null,
  email: null,
  lastName: null,
  streetAndNumber: null,
  postCode: null,
  nameAffix: null,
  phoneNumber: null,
  title: null,
  mobileNumber: null,
  category: null,
  briefInfo: null,
  updating: false,
};
class PersonalInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      errors: {},
      fields: {},
      uploading: false,
    };
  }

  componentDidMount() {
    const passedState = toJS(this.props.object);
    if (passedState) {
      this.setState({
        updating: true,
        salutation: passedState.salutation ? passedState.salutation : "",
        firstName: passedState.first_name ? passedState.first_name : "",
        place: passedState.place ? passedState.place : "",
        lastName: passedState.last_name ? passedState.last_name : "",
        streetAndNumber: passedState.street_and_number ? passedState.street_and_number : "",
        postCode: passedState.postcode ? passedState.postcode : "",
        nameAffix: passedState.name_affix ? passedState.name_affix : "",
        phoneNumber: passedState.phone_number ? passedState.phone_number : "",
        title: passedState.title ? passedState.title : "",
        mobileNumber: passedState.mobile_number ? passedState.mobile_number : "",
        dateOfBirth: passedState.birth_date ? new Date(passedState.birth_date) : "",
        category: passedState.category ? passedState.category : "",
        isBeneficiary: passedState.is_beneficiary ? passedState.is_beneficiary : false,
        briefInfo: passedState.breif_info ? passedState.breif_info : "",
        email: passedState.e_mail_address ? passedState.e_mail_address : "",
        errors: {
          salutation: "",
          firstName: "",
          place: "",
          lastName: "",
          streetAndNumber: "",
          postCode: "",
          nameAffix: "",
          phoneNumber: "",
          title: "",
          mobileNumber: "",
          dateOfBirth: "",
          category: "",
          isBeneficiary: "",
          briefInfo: "",
          email: "",
        },
      });
    }
    /*
    birth_date: "2019-07-31T22:00:00.000Z"
    breif_info: null
    category: null
    client_id: "a158af09-58f5-47d4-8c17-7e04a3e46c7a"
    createdAt: "2019-08-12T10:09:06.000Z"
    created_by_user: "db74d127-7145-484a-a8a8-ffd271baf40c"
    first_name: "Vorname"
    is_beneficiary: false
    last_name: "Nachname"
    mobile_number: null
    name_affix: null
    person_id: "73fbcecf-8531-456b-aa54-4b45d84346e8"
    phone_number: null
    place: "asdsadsadasdsa"
    postcode: "123456"
    salutation: "Herr"
    status: null
    street_and_number: "sdasdasdsadsa"
    title: "Titel"
    updatedAt: "2019-08-12T11:12:33.000Z"
    */
  }

  handleDateChange = data => {
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    if (new Date(data) >= new Date(yesterday)) {
      this.setState({
        dateOfBirth: "",
        dateOfBirthError: "Sie können kein Datum in der Zukunft oder heute eingeben!",
      });
      return;
    }
    this.setState({ dateOfBirth: new Date(data), dateOfBirthError: "" });
  };

  handleChange = field => e => {
    this.setState({
      [field]: e.target.value,
      errors: { ...this.state.errors, [field]: "" },
    });
  };

  handleSelected = category => value => {
    this.setState({ [category]: value });
  };

  handleCheckboxChange = name => () => {
    this.setState(state => ({
      [name]: !state[name],
    }));
  };

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    const schema = Yup.object().shape({
      lastName: Yup.string()
        .nullable()
        .required(requiredError),
      firstName: Yup.string()
        .nullable()
        .required(requiredError),
      // postCode: Yup.string()
      //   .nullable()
      //   .matches(/^[0-9]+$/, "Nur Zahlen")
      //   .required(requiredError),
      // place: Yup.string()
      //   .nullable()
      //   .required(requiredError),
      // streetAndNumber: Yup.string()
      //   .nullable()
      //   .required(requiredError),
      // salutation: Yup.string(requiredError)
      //   .nullable()
      //   .required(requiredError),
      // dateOfBirth: Yup.string()
      //   .nullable()
      //   .required(requiredError),
    });

    try {
      schema.validateSync(fields, { abortEarly: false });
    } catch (err) {
      err.inner.forEach(e => {
        errors[e.path] = e.message;
      });
    }

    formIsValid = schema.isValidSync(fields);

    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (this[key]) {
        this[key].focus();
      }
    }

    this.setState({ errors: errors });
    // if (!this.state.dateOfBirth) {
    //   this.setState({
    //     dateOfBirthError: requiredError,
    //   });
    //   formIsValid = false;
    // }
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    if (new Date(this.state.dateOfBirth) >= new Date(yesterday)) {
      this.setState({
        dateOfBirth: "",
        dateOfBirthError: "Sie können kein Datum in der Zukunft oder heute eingeben!",
      });
      return;
    }
    return formIsValid;
  }

  handleSubmit = async () => {
    const {
      store: {
        createPerson,
        personsListing,
        clientStore: { selectedClientId },
        updatePerson,
      },
    } = this.props;

    const {
      salutation,
      firstName,
      place,
      email,
      lastName,
      streetAndNumber,
      postCode,
      nameAffix,
      phoneNumber,
      title,
      mobileNumber,
      dateOfBirth,
      category,
      isBeneficiary,
      briefInfo,
    } = this.state;

    if (!this.handleValidation()) {
      return false;
    }

    this.setState({
      uploading: true,
    });
    const data = {
      client_id: selectedClientId,
      salutation: salutation,
      category: category,
      e_mail_address: email,
      first_name: firstName,
      last_name: lastName,
      name_affix: nameAffix,
      street_and_number: streetAndNumber,
      place: place,
      postcode: postCode,
      phone_number: phoneNumber,
      title: title,
      mobile_number: mobileNumber,
      birth_date: dateOfBirth === "" ? null : dateOfBirth,
      is_beneficiary: isBeneficiary,
      breif_info: briefInfo,
    };
    let responseMessage = "";
    if (this.state.updating) {
      this.setState({
        uploading: true,
      });
      responseMessage = updatePerson.fetch({
        personId: this.props.object.person_id,
        data,
      });
    } else {
      this.setState({
        uploading: true,
      });
      responseMessage = await createPerson.fetch(data);
    }
    if (responseMessage.data && responseMessage.data.data) {
      this.setState({
        uploading: false,
      });
      this.setState({
        message: (
          <div className="response success">
            {" "}
            <i className="material-icons">check_box</i> Nachricht erfolgreich gesendet.
          </div>
        ),
      });
      this.forceUpdate();
    } else {
      this.setState({
        uploading: false,
      });
      this.setState({
        message: (
          <div className="response">
            <i className="material-icons">error</i> Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
          </div>
        ),
      });
    }
    // setTimeout(
    //   function() {
    //     this.setState({ message: "" });
    //   }.bind(this),
    //   5000
    // );
    personsListing.fetch({
      clientId: selectedClientId,
    });
    this.props.closeModal();
    browserHistory.push("/mandanten#persons");
    browserHistory.go();
  };

  render() {
    const { closeModal } = this.props;
    const { uploading } = this.state;

    return (
      <React.Fragment>
        <ModalContent>
          <PersonalInfoOuter>
            <Layout displayFlex row rowToColumn={900}>
              <PageColumn flex={5} left>
                <InfoFormWrapper>
                  <MobileInfo>
                    <FormSelect
                      // required
                      noAdapter
                      label="Anrede"
                      onChange={this.handleSelected("salutation")}
                      options={["Frau", "Herr"].map(a => a)}
                      error={this.state.errors["salutation"]}
                      value={this.state.salutation}
                    />
                  </MobileInfo>
                  <MobileInfo>
                    <FormInput
                      label="Titel"
                      placeholder="Titel"
                      onChange={this.handleChange("title")}
                      error={this.state.errors["title"]}
                      value={this.state.title}
                      ref={el => {
                        this.title = el;
                      }}
                    />
                  </MobileInfo>
                  <MobileInfo>
                    <FormInput
                      required
                      label="Vorname"
                      placeholder="Vorname"
                      onChange={this.handleChange("firstName")}
                      error={this.state.errors["firstName"]}
                      value={this.state.firstName}
                      ref={el => {
                        this.firstName = el;
                      }}
                    />
                  </MobileInfo>
                  <MobileInfo>
                    <FormInput
                      required
                      label="Nachname"
                      placeholder="Nachname"
                      onChange={this.handleChange("lastName")}
                      error={this.state.errors["lastName"]}
                      value={this.state.lastName}
                      ref={el => {
                        this.lastName = el;
                      }}
                    />
                  </MobileInfo>
                  <MobileInfo>
                    <FormInput
                      label="PLZ"
                      placeholder="PLZ"
                      // required
                      onChange={this.handleChange("postCode")}
                      error={this.state.errors["postCode"]}
                      value={this.state.postCode}
                      ref={el => {
                        this.postCode = el;
                      }}
                    />
                  </MobileInfo>
                  <MobileInfo>
                    <FormInput
                      label="Telefonnummer"
                      placeholder="Telefonnummer"
                      onChange={this.handleChange("phoneNumber")}
                      error={this.state.errors["phoneNumber"]}
                      value={this.state.phoneNumber}
                      ref={el => {
                        this.phoneNumber = el;
                      }}
                    />
                  </MobileInfo>
                  <MobileInfo>
                    <FormInput
                      label="E-Mailadresse"
                      placeholder="E-Mailadresse"
                      onChange={this.handleChange("email")}
                      value={this.state.email}
                      error={this.state.errors["email"]}
                      ref={el => {
                        this.email = el;
                      }}
                    />
                  </MobileInfo>
                  <MobileInfo>
                    <FormCheckboxCustom>
                      <FormInputLabel>Begünstigter</FormInputLabel>
                      <CheckBox onClick={this.handleCheckboxChange("isBeneficiary")} checked={this.state.isBeneficiary} />
                    </FormCheckboxCustom>
                  </MobileInfo>
                </InfoFormWrapper>
              </PageColumn>
              <PageColumn flex={5}>
                <InfoFormWrapper>
                  <MobileInfo>
                    <FormInput
                      label="Kategorie"
                      placeholder="Kategorie"
                      onChange={this.handleChange("category")}
                      error={this.state.errors["category"]}
                      value={this.state.category}
                      ref={el => {
                        this.category = el;
                      }}
                    />
                  </MobileInfo>
                  <MobileInfo>
                    <FormInput
                      label="Namenszusatz"
                      placeholder="Namenszusatz"
                      onChange={this.handleChange("nameAffix")}
                      error={this.state.errors["nameAffix"]}
                      value={this.state.nameAffix}
                      ref={el => {
                        this.nameAffix = el;
                      }}
                    />
                  </MobileInfo>
                  <MobileInfo>
                    <FormInput
                      // required
                      label="Straße und Hausnr"
                      placeholder="Straße und Hausnr"
                      onChange={this.handleChange("streetAndNumber")}
                      error={this.state.errors["streetAndNumber"]}
                      value={this.state.streetAndNumber}
                      ref={el => {
                        this.streetAndNumber = el;
                      }}
                    />
                  </MobileInfo>
                  <MobileInfo>
                    <FormInput
                      // required
                      label="Ort"
                      placeholder="Ort"
                      onChange={this.handleChange("place")}
                      value={this.state.place}
                      error={this.state.errors["place"]}
                      ref={el => {
                        this.place = el;
                      }}
                    />
                  </MobileInfo>
                  <MobileInfo>
                    <FormInput
                      label="Mobilfunknummer"
                      placeholder="Mobilfunknummer"
                      onChange={this.handleChange("mobileNumber")}
                      value={this.state.mobileNumber}
                      error={this.state.errors["mobileNumber"]}
                      ref={el => {
                        this.mobileNumber = el;
                      }}
                    />
                  </MobileInfo>
                  <MobileInfo>
                    <DatePickerItem fullWidth>
                      <FormInputLabel className="error">
                        Geburtsdatum<span style={{ color: "#ef5b2e", fontSize: "0.85rem" }}></span>
                      </FormInputLabel>
                      <DatePicker value={this.state.dateOfBirth} onChange={this.handleDateChange} error={this.state.dateOfBirthError} />
                    </DatePickerItem>
                  </MobileInfo>
                  <MobileInfo>
                    <TextareaInput
                      label="Kurzinformation"
                      placeholder="Kurzinformation"
                      onChange={this.handleChange("briefInfo")}
                      value={this.state.briefInfo}
                      maxlength={56}
                      ref={el => {
                        this.briefInfo = el;
                      }}
                    />
                  </MobileInfo>
                </InfoFormWrapper>
              </PageColumn>
            </Layout>
          </PersonalInfoOuter>
        </ModalContent>
        <ResponseMessage>
          {" "}
          <div> {this.state.message} </div>{" "}
        </ResponseMessage>
        <ModalFooterOuter>
          <Button onClick={closeModal} color="grey">
            Abbrechen
          </Button>
          <Button onClick={!uploading && this.handleSubmit} color="green">
            {uploading ? "Ihre Datei wird verarbeitet" : "Speichern"}
          </Button>
        </ModalFooterOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(PersonalInfo);
