import styled from "styled-components";
import { COLORS } from "./constants";

export const UserInformation = styled.div`
  width: 100%;
  padding: 0;
  h6 {
    font-family: Sansation;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: 0.1px;
    padding-bottom: 12px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgb(0, 0, 0, 0.11);
    text-align: left;
    color: ${COLORS.PINE};
    @media only screen and (max-width: 900px) {
      font-size: 1.1rem;
    }
  }
  @media only screen and (max-width: 1400px) {
    padding: 30px 20px 0 20px;
  }
  @media only screen and (max-width: 1050px) {
    > div {
      flex-flow: column;
      padding-bottom: 30px;
    }
  }
`;
export const InputHolder = styled.div`
  width: 30%;
  float: left;
  input {
    height: 43px;
    box-shadow: inset 0px 1px 10px 0 rgba(72, 84, 56, 0.21);
    background: ${COLORS.LIGHT_GREY};
    border: ${COLORS.LIGHT_GREY_EIGHT};
    color: ${COLORS.DARK_SAGE_THREE};
    font-size: 0.93rem;
    max-width: 350px;
  }
`;
