import _ from "lodash";
import React, { Component } from "react";
import { compose } from "recompose";
import moment from "moment";
import { Layout } from "../../styled";
import { PageColumn } from "../../styled/layout";
import Table from "../Table";
import { inject, observer } from "mobx-react";
import ResponseWarning from "../../components/Response";
import { AppointmentOuter, DatePickerOuter } from "../../styled/myoverview";
import Calendar from "react-calendar";
import { COLORS } from "../../styled/constants";

class NextAppointment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: new Date(),
    };
  }
  componentDidMount() {
    const {
      store: { nextAppointment, clients },
    } = this.props;
    nextAppointment.fetch();
    clients.fetch();
  }

  handleDateChange = async date => {
    this.setState({ date: date });
  };

  daysUntilNext = (month, day) => {
    const tday = new Date(),
      y = tday.getFullYear(),
      next = new Date(y, month - 1, day);
    tday.setHours(0, 0, 0, 0);
    if (tday > next) next.setFullYear(y + 1);
    return Math.round((next - tday) / 8.64e7);
  };

  render() {
    const {
      store: { nextAppointment, clients },
    } = this.props;

    var year = new Date().getFullYear();

    const extraMappableData = (clients.data || []).map(c => {
      const birthday = _.get(c, "birth_date", null);
      // let birthday = client.data.birth_date;
      var year = new Date().getFullYear();
      const daysBetween = this.daysUntilNext(Number(moment(birthday).format("MM")), Number(moment(birthday).format("DD")));
      const nextBirthday = moment()
        .add(daysBetween, "days")
        .format("DD.MM.YYYY");
      if ((moment().isSame(birthday, "month") && moment().isAfter(birthday, "day")) || moment().isAfter(birthday, "month")) year++;
      // const tableElem = birthday
      //   ? moment(birthday)
      //     .year(year)
      //     .format("DD.MM.YYYY")
      //   : "";

      return [
        {
          key: "foo_4",
          type: "text",
          data: "Geburtstag Mandant",
        },
        {
          key: "foo_5",
          type: "text",
          data: nextBirthday,
        },
        {
          key: "foo_1",
          type: "text",
          // data: "1",
          data: c.client_number,
        },
        {
          key: "foo_2",
          type: "text",
          data: c.first_name,
        },
        {
          key: "foo_3",
          type: "text",
          data: c.last_name,
        },
      ];
    });

    return (
      <React.Fragment>
        <AppointmentOuter>
          <Layout displayFlex row>
            <PageColumn flex={5} left>
              {/* <DatePicker
                value={this.state.date}
                onChange={this.handleDateChange}
              /> */}
              <DatePickerOuter>
                <Calendar
                  locale="de-DE"
                  className="custom-calendar"
                  formatShortWeekday={(locale, date) =>
                    moment(date)
                      .format("dd")
                      .substr(0, 1)
                  }
                  tileContent={item => {
                    const data = [...(nextAppointment.data || []), ...(clients.data || [])];
                    const show = data.find(
                      elem =>
                        (elem.task_open_date && moment(elem.task_open_date).isSame(moment(item.date), "day")) ||
                        (elem.birth_date &&
                          moment(elem.birth_date)
                            .year(year)
                            .isSame(moment(item.date), "day")),
                    );
                    if (show && item.view === "month") {
                      return (
                        <div
                          style={{
                            position: "absolute",
                            top: 35,
                            left: "50%",
                            color: COLORS.TOMATO,
                          }}
                        >
                          *
                        </div>
                      );
                    }
                    return null;
                  }}
                  next2Label={null}
                  prev2Label={null}
                />
              </DatePickerOuter>
            </PageColumn>
            <PageColumn flex={7} right>
              {nextAppointment.constructedTable.rows[0] || Object.keys(extraMappableData).length > 0 ? (
                <Table
                  containerStyle={{ height: "100%", minHeight: 300 }}
                  footerStyle={{ margin: 0 }}
                  hideHeader
                  hideFooter
                  small
                  tableStore={nextAppointment}
                  extraMappableData={extraMappableData}
                  show={10}
                />
              ) : (
                <ResponseWarning />
              )}
            </PageColumn>
          </Layout>
        </AppointmentOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(NextAppointment);
