import React, { Component } from "react";
import { compose } from "recompose";
import { Layout } from "../../../styled";
import { inject, observer } from "mobx-react";
import { ConfirmationOuter } from "../../../styled/modal";
import { Button } from "../../../styled/buttons";
import { ModalFooterOuter } from "../../../styled/actionmodalconfirmation";

class ActionModalLicenceWarning extends Component {
  goToLicenses = () => {
    const {
      store: {
        router: { history },
      },
    } = this.props;

    history.replace("/administration#licenses");
    history.go();
    this.props.closeModal();
    // window.location.hash = "#liceses";
  };

  render() {
    const { closeModal } = this.props;
    return (
      <Layout displayFlex alignCenter column>
        <ConfirmationOuter>
          <i className="material-icons">error</i>
          <h5>Nicht genügend Lizenzen vorhanden</h5>
        </ConfirmationOuter>
        <ModalFooterOuter>
          <Button onClick={closeModal} color="grey">
            Abbrechen
          </Button>
          <Button onClick={this.goToLicenses} color="green">
            Upgrade-Lizenz
          </Button>
        </ModalFooterOuter>
      </Layout>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(ActionModalLicenceWarning);
