import React from "react";
import { ModalFooterOuter } from "../../../styled/modal";
import { Button } from "../../../styled/buttons";
import { Layout } from "../../../styled";
import FormInput from "../../Form/Input";
// import Axios from 'axios';
import { compose } from "recompose";
import { observer, inject } from "mobx-react";

class EditTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // document_name: '',
      // document_category: '',
      // document_notes: '',
      temFile: null,
      uploading: false,
    };

    this.upload = React.createRef();
  }

  // handleChange = (e) => {
  //   console.log(e.target.value, e.target.name);
  //   this.setState({
  //     [e.target.name]: e.target.value
  //   });
  // }

  // submit = () => {

  //   const {
  //     temFile,
  //   } = this.state;
  //   const file = this.upload.current.files[0]

  //   const body = new FormData();
  //   body.set('file_type', file.type.split('/')[1].toUpperCase());
  //   body.append('temFile', file);

  //   Axios({
  //     method: 'POST',
  //     // url: `http://localhost:5000/api/document/upload`,
  //     url: `${window.location.origin}/api/template/upload`,
  //     data: body,
  //     config: { headers: { 'Content-Type': 'multipart/form-data' } }
  //   }).then(() => {
  //     this.props.store.documents.fetch();
  //     this.props.closeModal();
  //   });
  // }

  render() {
    const { closeModal } = this.props;
    const { temFile, uploading } = this.state;

    return (
      <React.Fragment>
        <Layout spacingTop={40} fullWidth spacingLeft={40} spacingRight={40}>
          <FormInput ref={this.upload} onChange={this.handleChange} value={temFile} name="temFile" type="file" label="Datei" />
        </Layout>
        <ModalFooterOuter>
          <Button onClick={closeModal} color="grey">
            Abbrechen
          </Button>
          <Button onClick={!uploading && this.submit} color="green">
            {uploading ? "Ihre Datei wird verarbeitet" : "Speichern"}
          </Button>
        </ModalFooterOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(EditTemplate);
