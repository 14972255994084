import Axios from "axios";
import fileDownload from "js-file-download";

import config from "../../lib/config";

export const templateOrderAdapter = {
  template_name: {
    key: "row_0",
    order: 0,
    scale: 3,
    title: "Vorlage",
  },
  file_type: {
    key: "row_1",
    order: 1,
    scale: 1,
    title: "Dateityp",
  },
  description: {
    key: "row_2",
    order: 2,
    scale: 3,
    title: "Beschreibung",
  },
  download: {
    key: "row_4",
    order: 3,
    scale: 2,
    title: "Vorlage herunterladen",
  },
  delete: {
    key: "row_5",
    order: 4,
    scale: 1,
    title: "Löschen",
  },
};

export const templateHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

const deleteTemplate = async (event, store, template_id) => {
  const token = window.localStorage.getItem(config.keys.local_storage_key);
  Axios({
    method: "DELETE",
    url: config.endpoints.api + `/api/template/${template_id}`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `${token}`,
    },
  }).then(() => {
    store.userTemplates.fetch();
  });
};

const downloadTemplate = async (event, store, index, file_path) => {
  const { downloadTemplate, userTemplates } = store;
  const template = userTemplates.data.templates[index];
  let file = await downloadTemplate.fetch(template);
  let xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var myReader = new FileReader();
    myReader.readAsArrayBuffer(xhr.response);

    myReader.addEventListener("loadend", function(e) {
      var buffer = e.srcElement.result; //arraybuffer object
      if (file.data.data.template_name === undefined) {
        const fileName = file_path.split("/");
        fileDownload(buffer, fileName[fileName.length - 1]);
      } else {
        fileDownload(buffer, file.data.data.template_name);
      }
    });
  };
  const s = file.data.data.file_path;
  let sc = "";

  if (file_path.includes("default_templates")) {
    sc = file_path.slice(file_path.indexOf("default_templates"));
  } else {
    sc = s.slice(s.indexOf("uploads"));
  }

  xhr.open("GET", sc);
  xhr.send();

  xhr.responseType = "blob";
};

export const templateRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.templates.forEach((item, index) => {
      const row = [];
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem && itemKey !== "print") {
          switch (itemKey) {
          case "download": {
            row[orderItem.order] = {
              key: `id_${itemIndex}`,
              type: "icon",
              action: (store, index) => e => downloadTemplate(e, store, index, item.file_path),
              active: "cloud_download",
              data: item[itemKey],
            };
            break;
          }
          case "delete": {
            row[orderItem.order] = {
              key: `id_${itemIndex}`,
              type: "icon",
              active: "delete",
              // isDisabled: checkIfDefault(item.template_name),
              action: (store, index) => e => deleteTemplate(e, store, item.template_id),
              data: item[itemKey],
            };
            break;
          }
          default: {
            row[orderItem.order] = {
              key: `id_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          }
        }
      });
      rows[index] = row;
    });
  }

  return rows;
};
