import React, { Component } from "react";
import { Layout } from "../../styled";
import { compose } from "recompose";
import { AdministrationOuter } from "../../styled/administration";
import AddNewUser from "./FilterOptions";
import Table from "../../components/Table";
import { inject, observer } from "mobx-react";
import ResponseWarning from "../Response";
import CreateNewUser from "../../components/Modal/CreateNewUser";
import IndividualClients from "../../components/Modal/IndividualClients";
import AssignedLicensesSection from "./AssignedLicense";

class UserAdministration extends Component {
  componentDidMount() {
    const {
      store: { userAdministration },
    } = this.props;
    userAdministration.fetch();
  }

  onPressUpload = () => {
    const {
      store: { modal },
    } = this.props;
    modal.add({
      title: "Neuen User anlegen",
      component: CreateNewUser,
    });
  };

  handleSubmit = async () => {
    const {
      store: { userAdministration },
    } = this.props;
    await userAdministration.fetch();
    this.handleReset();
  };

  onAccessChange = async (event, store, index) => {
    const { userAdministration, modal } = store;
    const user = userAdministration.data[index];

    const data = {
      client_access: event === "Alle Kunden" ? "all" : event === "Individueller Mandantenzugriff" ? "individual" : "own",
    };

    if (event === "Individueller Mandantenzugriff") {
      modal.add({
        title: "Individueller Mandantenzugriff",
        component: IndividualClients,
        props: { user: user },
      });
    } else {
      await this.updateUser(data, user);
      window.location.reload();
    }
  };

  updateUser = async (data, user) => {
    const {
      store: { updateUser, userAdministration },
    } = this.props;

    await updateUser.fetch({
      user_id: user.id,
      ...data,
    });

    await userAdministration.fetch();
  };

  render() {
    const {
      store: { userAdministration },
    } = this.props;

    if (userAdministration.loading || !userAdministration.data) {
      return null;
    }

    return (
      <AdministrationOuter>
        <Layout displayFlex alignCenter>
          <AddNewUser onPressUpload={this.onPressUpload} />
        </Layout>
        {userAdministration.constructedTable.rows[0] ? (
          <Layout>
            <Table hideHeader hideFooter small onChange={this.onAccessChange} tableStore={userAdministration} />
          </Layout>
        ) : (
          <ResponseWarning />
        )}
        <AssignedLicensesSection />
      </AdministrationOuter>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(UserAdministration);
