import React from "react";
import { Downloading } from "../../styled";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";
import loadingImg from "../../assets/images/loading.svg";

class DownloadingDiv extends React.Component {
  render() {
    return (
      <Downloading>
        <div className="fakeClose"></div>
        <img src={loadingImg} alt="loading" />
        <span>Herunterladen</span>
      </Downloading>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(DownloadingDiv);
