import uuidv4 from "uuid/v4";

const environment = "production";
// const environment = "common";
// const environment = "development";
// const environment = "staging";

const isWindows = navigator.platform.indexOf("Win") > -1;

const configs = {
  common: {
    isWindows,
    environment,
    run: uuidv4(), // Unique ID for the duration of this app run
    endpoints: {
      api: "https://tvdev.frankwien.de",
    },
    keys: {
      local_storage_key: "@KSQ_TOKEN",
      captcha_site_key: "6LeFV7gUAAAAAEwNlXqs4pPsrfRKaOGkJxph65Zg",
      logout_timeout_key: "@KSQ_INACTIVE",
    },
  },
  development: {
    environment,
    run: uuidv4(), // Unique ID for the duration of this app run
    endpoints: {
      // api: "http://192.168.1.66:5000",
      // api: "http://192.168.1.37:5000",
      api: "http://localhost:5000",
    },
    keys: {
      local_storage_key: "@KSQ_TOKEN",
      captcha_site_key: "6LcZrrgUAAAAAPfmzHknHK3bt4PeQ9dhFHBO5d-9",
      logout_timeout_key: "@KSQ_INACTIVE",
    },
  },
  staging: {
    endpoints: {
      api: "https://tvdevsrv.frankwien.de",
    },
    keys: {
      local_storage_key: "@KSQ_TOKEN",
      captcha_site_key: "6LeFV7gUAAAAAEwNlXqs4pPsrfRKaOGkJxph65Zg",
      logout_timeout_key: "@KSQ_INACTIVE",
    },
  },
  production: {
    endpoints: {
      api: "https://login.protevos.de",
    },
    keys: {
      captcha_site_key: "6LcZrrgUAAAAAPfmzHknHK3bt4PeQ9dhFHBO5d-9",
      local_storage_key: "@KSQ_TOKEN",
      logout_timeout_key: "@KSQ_INACTIVE",
    },
  },
  mock: {
    endpoints: {
      api: "http://www.mocky.io/v2/5cc46e9d340000590076553c",
    },
  },
};

export default {
  ...configs["common"],
  ...configs[environment],
};
