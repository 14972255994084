import React from "react";
import { Layout, P } from "../../styled";
import { UserInfo } from "../../styled/mandates";
import UserInfoRight from "./UserInfoRight";

export const UserInformations = () => (
  <UserInfo>
    <P>Mandanteninformationen</P>
    <Layout displayFlex margconstantsinBottom={18}>
      <UserInfoRight />
    </Layout>
  </UserInfo>
);

export default UserInformations;
