import React from "react";
import { ButtonContainer } from "../../styled/editor";
import CheckBox from "./CheckBox";

const SelectButton = ({ onClick, selected, title, lastElement, checked }) => (
  <ButtonContainer onClick={onClick} selected={selected} lastElement={lastElement}>
    <CheckBox checked={checked === undefined ? selected : checked} />
    {title}
  </ButtonContainer>
);

export default SelectButton;
