import React from "react";
import PropTypes from "prop-types";
import { Layout } from "../../styled";
import { FormInputLabel, Error } from "../../styled/form";
import CustomSelect from "../Form/FormSelect";

const FormSelect = React.forwardRef(({ label, required, placeholder, error, ...rest }, ref) => (
  <Layout column flex={1} displayFlex justifyEnd offsetBottom={21}>
    <Layout displayFlex row flex={1} alignCenter>
      {label && (
        <FormInputLabel error={error}>
          {label}
          {required && <span>*</span>}
        </FormInputLabel>
      )}
      <Layout flex={1}>
        <CustomSelect ref={ref} placeholder={placeholder || label} {...rest} error={error} />
      </Layout>
    </Layout>
    <Error textAlign="right" type="type-5" className="form_input_error">
      {error}
    </Error>
  </Layout>
));

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
};

FormSelect.defaultProps = {
  required: false,
  placeholder: "",
  error: "",
};

export default FormSelect;
