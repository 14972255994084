import React, { Component } from "react";
import { Layout } from "../../../styled";
import { ConfirmationOuter } from "../../../styled/modal";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import DownloadingDiv from "../../Form/Loader";

class DownlaodingDokument extends Component {
  render() {
    return (
      <Layout displayFlex alignCenter column>
        <ConfirmationOuter>
          {/* <i class="material-icons">error</i>
          <h5>Sind Sie sicher, dass Sie diese Lizenz löschen wollen?</h5> */}
          <DownloadingDiv />
        </ConfirmationOuter>
        {/* <ModalFooterOuter>
          <Button onClick={closeModal} color="grey">
            Abbrechen
          </Button>
          <Button onClick={this.handleDelete} color="green">
            Löschen
          </Button>
        </ModalFooterOuter> */}
      </Layout>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(DownlaodingDokument);
