import React from "react";
import { Layout, Icon } from "../../styled";
import {
  NewItemSearchContainer,
  NewItemSearchInput,
  NewItemResultsContainer,
  NewItemResult,
  NewItemResultsStatic,
} from "../../styled/new-item";
import FloatingAdd from "../FloatingAdd";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import AddNewCatalogEntry from "../Modal/AddNewCatalogEntry";

class NewItemSearch extends React.Component {
  constructor(props) {
    super(props);

    this.node = React.createRef();

    this.state = {
      open: false,
      value: "",
      filteredAssets: [],
    };
  }

  componentDidMount() {
    const {
      store: { assets },
    } = this.props;
    document.addEventListener("click", this._onClickListener, false);

    this.setState({
      filteredAssets: assets.data ? assets.data.assetCategories : [],
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this._onClickListener, false);
  }

  handleFocus = () => {
    this.setState({ open: true });
    const {
      store: { assets },
    } = this.props;

    this.setState({
      filteredAssets: assets.data ? assets.data.assetCategories : [],
    });
  };

  handleTextChange = e => {
    const val = e.target.value;
    const {
      store: { assets },
    } = this.props;
    this.setState({
      value: val,
      filteredAssets: assets.data.assetCategories.filter(cat => {
        if (val === "") return true;

        const overCat = cat.asset_category_type.toLowerCase() === "asset" ? "Aktiva" : "Passiva";

        return (
          cat.asset_category_type.toLowerCase().indexOf(val.toLowerCase()) >= 0 ||
          cat.asset_list_name.toLowerCase().indexOf(val.toLowerCase()) >= 0 ||
          overCat.toLowerCase().indexOf(val.toLowerCase()) >= 0
        );
      }),
    });
  };

  _onClickListener = e => {
    if (!this.node.current.contains(e.target)) {
      this.setState({ open: false });
      return;
    }
  };

  addNewCatalogEntry = () => {
    const {
      store: { modal },
    } = this.props;
    modal.add({
      title: "Neuen Katalogeintrag erstellen",
      component: AddNewCatalogEntry,
    });
  };

  addNewAsset = category => () => {
    const {
      store: { modal },
    } = this.props;
    modal.add({
      title: "Neuen Katalogeintrag erstellen",
      component: AddNewCatalogEntry,
      props: {
        category,
      },
    });
  };

  render() {
    const { open, value, filteredAssets } = this.state;
    return (
      <div ref={this.node}>
        <NewItemSearchContainer>
          <Icon>search</Icon>
          <NewItemSearchInput
            value={value}
            placeholder="Vordefinierte Vermögensgegenstände durchsuchen"
            onFocus={this.handleFocus}
            onChange={this.handleTextChange}
          />
        </NewItemSearchContainer>
        <NewItemResultsContainer open={open}>
          <NewItemResultsStatic>
            {filteredAssets.map(cat => (
              <NewItemResult onClick={this.addNewAsset(cat)}>
                <Layout>{`${cat.asset_list_name} hinzufügen ${cat.asset_category_type === "asset" ? "(Aktiva)" : "(Passiva)"}`}</Layout>
              </NewItemResult>
            ))}
          </NewItemResultsStatic>
          <NewItemResult>
            <Layout flex={1}>Eigene Kategorie hinzufügen</Layout>
            <FloatingAdd onClick={this.addNewCatalogEntry} />
          </NewItemResult>
        </NewItemResultsContainer>
      </div>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(NewItemSearch);
