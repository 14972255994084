import styled from "styled-components";
import { COLORS } from "./constants";

export const UploadButton = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    color: white;
    display: inline-block;
    cursor: pointer;
    box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #d0d8c7;
    font-family: Sansation;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: 0.14px;
    text-align: left;
    color: ${COLORS.GREYISH_BROWN_TWO};
    padding: 12px 14px;
    line-height: 10px;
    border-radius: 4px;
    position: relative;
    padding-right: 40px;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;

    &:after {
      content: "";
      width: 30px;
      background-color: white;
      box-shadow: 0 0 5px 4px white;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 10;
    }
    i {
      position: absolute;
      right: -8px;
      top: 8px;
      color: ${COLORS.TURTLE_GREEN};
      transition: all 0.3s ease;
      z-index: 20;
    }
  }

  &:focus + label,
  & + label:hover {
    i {
      color: ${COLORS.GREENISH_GREY};
    }
  }

  &:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
  & + label * {
    pointer-events: none;
  }
`;
