import { decorate, observable, action, computed } from "mobx";

const FADE_DURATION = 300;
class ModalStore {
  visible = false;
  modalData = null;

  add = (modal) => {
    this.modalData = modal;
    this.visible = true;
  }

  remove = () => {
    this.visible = false;
    setTimeout(() => {
      this.modalData = null;
    }, FADE_DURATION);
  }

  get modal() {
    return this.modalData;
  }
  get isVisible() {
    return this.visible;
  }


}

export default decorate(ModalStore, {
  visible: observable,
  modalData: observable,
  add: action,
  modal: computed,
  isVisible: computed,
});
