import styled from "styled-components";
import { COLORS, BORDER_RADIUS, FONTWEIGHT } from "./constants";

export const TravelExpensesOuter = styled.div`
  width: 586px;
  padding: 0 55px;
  > div {
    > div {
      border: none;
      padding: 0 0 15px 0;
      align-items: center;
      @media only screen and (max-width: 680px) {
        padding-bottom: 15px;
      }
      div {
        padding-top: 0;
      }
    }
  }
  @media only screen and (max-width: 680px) {
    width: 100%;
    padding: 0 25px;
  }
  @media only screen and (max-width: 500px) {
    padding: 0 15px;
  }
  @media only screen and (max-width: 400px) {
    padding: 0 10px;
  }
`;
export const FormFieldItems = styled.div`
  width: 100%;
  padding-bottom: 18px;
  i {
    width: 57px;
    paddin-right: 30px;
    color: ${COLORS.NASTY_GREEN};
    font-size: 1.3rem;
    text-align: left;
    @media only screen and (max-width: 680px) {
      width: auto;
      margin-right: 10px;
    }
  }
  [type="text"] {
    max-width: 386px;
    margin-right: 30px;
    height: 39px;
    padding: 5px 11px;
    @media only screen and (max-width: 680px) {
      margin-right: 15px;
    }
    @media only screen and (max-width: 400px) {
      margin-right: 5px;
    }
  }
  label {
    color: ${COLORS.PINE};
    font-size: 0.9rem;
    font-weight: ${FONTWEIGHT.FW_6};
    padding-left: 16px;
    letter-spacing: 0.3px;
    @media only screen and (max-width: 650px) {
      padding-left: 7px;
      font-size: 0.7rem;
      letter-spacing: 0;
    }
  }
  .InputSplit {
    max-width: 70%;
    margin-right: 30px;
    @media only screen and (max-width: 680px) {
      display: flex;
      flex-flow: column;
      margin-right: 15px;
      input {
        margin-bottom: 10px;
      }
    }
    @media only screen and (max-width: 400px) {
      width: 177px;
      margin-right: 5px;
    }
    > div {
      width: 50%;
      border: none;
      @media only screen and (max-width: 680px) {
        width: 100%;
      }
      & + div {
        margin-left: 30px;
        @media only screen and (max-width: 680px) {
          margin-left: 0;
        }
      }
    }
  }
`;
