import styled from "styled-components";
import { COLORS, BORDER_RADIUS, FONTWEIGHT } from "./constants";

export const TesButtonStyle = styled.button`
  width: 100%;
  padding: 15px 20px;
  background-color: ${COLORS.TURTLE_GREEN};
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
  box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
  ${props => (props.maxWidthButton ? `max-width: ${props.maxWidthButton}${props.unit || "px"};` : "")};
  ${props => (props.default ? `background-color: ${COLORS.COOL_GREY_TWO} ;` : "")}
`;

export const Button = styled.button`
  ${props => {
    switch (props.color) {
    case "green":
      return `
        background: ${COLORS.TURTLE_GREEN_TWO};
        border: 1px solid ${COLORS.TURTLE_GREEN_TWO};
        box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
        &:hover {
          background: ${COLORS.NASTY_GREEN_TWO};
        }
      `;
    case "red":
      return `
        background: ${COLORS.TOMATO};
        border: 1px solid ${COLORS.TOMATO};
        box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
        &:hover {
          background: ${COLORS.PINKISH_ORANGE};
        }
      `;
    case "grey":
    default:
      return `
        background: ${COLORS.COOL_GREY};
        border: 1px solid ${COLORS.COOL_GREY_TWO};
        box-shadow: 0px 1px 6px 0 rgba(0, 0, 0, 0.18);
        &:hover {
          background: ${COLORS.MEDIUM_GREY_TWO};
        }
      `;
    }
  }};
  border-radius: ${BORDER_RADIUS.BR_1};
  padding: 15px 0;
  text-align: center;
  min-width: 193px;
  font-size: 1rem;
  font-weight: ${FONTWEIGHT.FW_6};
  margin-right: 20px;
  color: #fff;
  height: 53px;
  cursor: pointer;
  transition: all 0.3s ease;
  @media only screen and (max-width: 900px) {
    min-width: auto;
    height: auto;
    padding-left: 17px;
    padding-right: 17px;
    font-size: 14px;
  }
`;

export const Abbrechen = styled.button`
  @media only screen and (max-width: 768px) {
    margin-right: 10px;
  }
  background: ${COLORS.COOL_GREY};
  border: 1px solid ${COLORS.COOL_GREY_TWO};
  border-radius: ${BORDER_RADIUS.BR_1};
  box-shadow: 0px 1px 6px 0 rgba(0, 0, 0, 0.18);
  padding: 15px 0;
  text-align: center;
  min-width: 193px;
  font-size: 1rem;
  font-weight: ${FONTWEIGHT.FW_6};
  margin-right: 20px;
  color: #fff;
  height: 53px;
  cursor: pointer;
  &:hover {
    background: ${COLORS.MEDIUM_GREY_TWO};
  }
  ${props => props.margin && `margin: ${props.margin}`}
  ${props =>
    props.responsiveMargin
      ? `
      @media screen and (max-width: ${props.responsiveMargin}px) {
        margin: 10px!important;
      }
  `
      : ``}
`;
export const Speichem = styled.button`
  background: ${COLORS.TURTLE_GREEN_TWO};
  border: 1px solid ${COLORS.TURTLE_GREEN_TWO};
  border-radius: ${BORDER_RADIUS.BR_1};
  box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
  padding: 15px 0;
  text-align: center;
  min-width: 193px;
  font-size: 1rem;
  font-weight: ${FONTWEIGHT.FW_6};
  margin-right: 0;
  color: #fff;
  color: #fff;
  height: 53px;
  cursor: pointer;
  &:hover {
    background: ${COLORS.NASTY_GREEN_TWO};
  }
  ${props =>
    !!props.responsiveMargin
      ? `
    @media screen and (max-width: ${props.responsiveMargin}px) {
    margin: 10px!important;
  }
  `
      : ``}
  ${props => props.margin && `margin: ${props.margin}`}
  ${props =>
    props.mediaFullWidth
      ? `@media only screen and (max-width: ${props.mediaFullWidth}px){
          width: 100%;
        }`
      : ``}
`;

export const IconButtonContainer = styled.div`
  cursor: pointer;
  height: 41px;
  box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px ${COLORS.LIGHT_GREY_TWO};
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
  padding: 13px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.NASTY_GREEN};
  margin-right: 16px;
  transition: all 0.3s ease;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: 0.2px;
  text-align: left;
  white-space: nowrap;

  i {
    margin-left: 16px;
    font-size: 18px;
    line-height: 14px;
  }

  &:hover {
    background-color: ${COLORS.TURTLE_GREEN_TWO};
    border: 1px solid ${COLORS.TURTLE_GREEN};
    color: white;
  }

  ${props =>
    props.active &&
    `
    background-color: ${COLORS.TURTLE_GREEN};
    border: 1px solid ${COLORS.TURTLE_GREEN};
    color: white;
  `}

  ${props =>
    props.small &&
    `
    margin-right: 0;
    padding: 10px;
    height: 34px;
    font-size: 14px;
    color: ${COLORS.GREYISH_BROWN_THREE};
    i {
      margin-right: 0!important;
      color: ${COLORS.NASTY_GREEN};
    }
  `}
`;

export const AssetTableButton = styled.button`
  width: 143px;
  height: 34px;
  box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px ${COLORS.TURTLE_GREEN_TWO};
  background-color: ${COLORS.TURTLE_GREEN};
  font-family: Sansation;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: 0.14px;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  ${props =>
    props.mediaFullWidth
      ? `@media only screen and (max-width: ${props.mediaFullWidth}px){
      width: 100%;
      margin: 20px 0 0 0;
    }`
      : ``}
  ${props => (props.marginLeft ? `margin-left: ${props.marginLeft}px` : ``)}
  ${props => (props.width ? `width: ${props.width}px` : ``)}
`;
