import { compose } from "recompose";
import withTabsWrapper from "../hoc/Tabs";
import Dokumente from "../components/Dokumente/index";
import FragenKatalog from "../components/QuestionCatalog";
import Reports from "./Reports";
import Policen from "./Policen";
import ReportFooter from "../components/AssetList/ReportFooter";
import PolicyFooter from "../components/Policen/PolicyFooter";

export default compose(
  withTabsWrapper([
    {
      key: "tab_1",
      pageTitle: "Dokumente",
      title: "Dokumente",
      component: Dokumente,
    },
    {
      key: "tab_2",
      pageTitle: "Fragenkatalog",
      title: "Fragenkatalog",
      component: FragenKatalog,
    },
    {
      key: "tab_3",
      pageTitle: "Reports",
      title: "Reports",
      component: Reports,
      footer: ReportFooter,
    },
    {
      key: "tab_4",
      pageTitle: "Policen",
      title: "Policen",
      component: Policen,
      footer: PolicyFooter,
    },
  ]),
)(() => null);
