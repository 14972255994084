import moment from "moment";
import _ from "lodash";

export const clientTaskOrderAdapter = {
  type: {
    key: "row_1",
    order: 0,
    scale: 1,
    title: "Typ",
  },
  note_heading: {
    key: "row_2",
    order: 1,
    scale: 4,
    title: "Nächster Termin / Aufgabe",
  },
  task_open_date: {
    key: "row_3",
    order: 2,
    scale: 3,
    title: "Datum",
  },
  edit_icon: {
    key: "row_41",
    order: 3,
    scale: 1.1,
    title: "Bearbeiten",
  },
};
export const clientTaskHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

export const clientTaskRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];

        if (orderItem) {
          switch (itemKey) {
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          case "task_open_date": {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: moment(item[itemKey]).format("DD.MM.YYYY"),
            };
            break;
          }
          }
        }
      });
      if (!!row) {
        row[0] = {
          key: `foo___0`,
          type: "icon",
          active: "chrome_reader_mode",
          inactive: "chrome_reader_mode",
          action: () => () => null,
          data: "chrome_reader_mode",
        };
        row[3] = {
          key: `foo___3`,
          type: "icon",
          active: "create",
          inactive: "create",
          action: (rootStore, taskIndex) => async () => {
            const { activityHistory, clientStore, clientTask } = rootStore;

            await activityHistory.fetch({
              clientId: clientStore.selectedClientId,
            });

            // console.log("------>",activityHistory, "clientId", clientStore.selectedClientId);

            await clientTask.fetch({
              clientId: clientStore.selectedClientId,
            });

            // console.log("------>",clientTask, "clientId", clientStore.selectedClientId);

            const clientTaskSelectedId = clientTask.data[taskIndex].activity_id;
            const selectedActivity = activityHistory.data.find(item => item.activity_id === clientTaskSelectedId);

            // console.log("------> SA", selectedActivity, "--------> Task Index", taskIndex);

            rootStore.router.history.push(`/activities?activity_id=${selectedActivity.activity_id}#${selectedActivity.activity_type_id}`);
            rootStore.router.history.go();

            rootStore.activityStartVomInfo.onChangeDate(selectedActivity.recording_date);
            rootStore.activityStartVomInfo.onActivityIsSelectedClick();
          },
          data: "create",
        };
      }
      rows[index] = row;
    });
  }
  return rows;
};
