import _ from "lodash";
import PromiseStore from "./PromiseStore";
import { decorate, computed, action, observable, toJS } from "mobx";
import { endpoints } from "../lib/endpoints";
import config from "../lib/config";
import { replaceStringWithNumber, handleChangeMoneyInput, getMoneyNumber, formatMoneyToString } from "../utils/helper";

const calculateValue = (rows, field) => {
  let value = 0;
  rows.forEach(row => {
    value += Number(row[field] || 0);
  });
  // {parseFloat(asset.asset_value || 0).toLocaleString('de-DE', {currency: 'EUR'})

  return value.toLocaleString("de-DE", { currency: "EUR" });
};

const generateValue = value => `${parseFloat(value || 0).toLocaleString("de-DE", { currency: "EUR" })}`;
class AssetStore extends PromiseStore {
  constructor(query, __, root) {
    super(query);
    this.root = root;

    this.changedFields = {};
    this.changedValues = {};
    this.beneficiaryAmounts = {};
    this.timeouts = {};
    this.updater = new PromiseStore(endpoints.update_asset_field);
    this.newActivities = {};
    // this.settlement = {};
  }

  async fetch(data) {
    const response = await super.fetch(data);
    if (Object.keys(toJS(this.beneficiaryAmounts) || {}).length === 0) {
      this.beneficiaryAmounts = _.get(response, "data.data.beneficiaryValues");
    }
    if(response && response.data.activity_id){
      this.newActivities = response.data;
    }
  }

  onChangeFieldValue = (item, field) => e => {
    this.changedValues[`${item.assetId}_${field}`] = e.target.value;

    clearTimeout(this.timeouts[`${item.assetId}_${field}`]);

    this.timeouts[`${item.assetId}_${field}`] = setTimeout(async () => {
      const v = this.changedValues[`${item.assetId}_${field}`];
      await this.root.assetModifier.fetch({
        assetId: item.assetId,
        [field]: v || 0,
      });
      this.root.assets.fetch({
        clientId: this.root.clientStore.selectedClientId,
      });

      this.root.updateClients
        .fetch({
          clientId: this.root.clientStore.selectedClientId,
          user_updated: this.root.getUserFromToken.data.first_name + " " + this.root.getUserFromToken.data.last_name,
          assets_updated_at: new Date(),
        })
        .then(() => {
          this.root.client.fetch({
            clientId: this.root.clientStore.selectedClientId,
          });
        });
    });
  };

  onChangeInputValue = item => e => {
    this.changedFields[item.id] = e.target.value;

    clearTimeout(this.timeouts[item.id]);
    this.timeouts[item.id] = setTimeout(() => {
      this.updater.fetch({
        fieldId: item.id,
        value: this.changedFields[item.id],
      });

      this.root.updateClients
        .fetch({
          clientId: this.root.clientStore.selectedClientId,
          user_updated: this.root.getUserFromToken.data.first_name + " " + this.root.getUserFromToken.data.last_name,
          assets_updated_at: new Date(),
        })
        .then(() => {
          this.root.client.fetch({
            clientId: this.root.clientStore.selectedClientId,
          });
        });
    }, 2000);
  };

  onChangeBeneficiaryAmount = (row, beneficiary) => e => {
    const clientId = this.root.clientStore.selectedClientId;
    const realValue = handleChangeMoneyInput(e.target.value);
    let value = '';
    if(!!realValue || realValue === ''){
      value = realValue;
    }else{
      return;
    }

    if (getMoneyNumber(value) < 0) {
      value = '0,00';
      // value = 0;
    }
    if (!_.isObject(this.beneficiaryAmounts[clientId])) {
      this.beneficiaryAmounts[clientId] = {};
    }

    if (!_.isObject(this.beneficiaryAmounts[clientId][row.assetId])) {
      this.beneficiaryAmounts[clientId][row.assetId] = {};
    }
    this.beneficiaryAmounts[clientId][row.assetId][beneficiary] = value;

    let maxValue = Number(row.valueSold);

    Object.keys(this.beneficiaryAmounts[clientId][row.assetId]).forEach(k => {
      if (k !== beneficiary) {
        const singleValue = this.beneficiaryAmounts[clientId][row.assetId][k];
        maxValue -= getMoneyNumber(singleValue);
      }
    });
    if (getMoneyNumber(value) > getMoneyNumber(maxValue)) {
      value = maxValue.toLocaleString('de-DE', { minimumFractionDigits: 2 });
      this.beneficiaryAmounts[clientId][row.assetId][beneficiary] = value;
    }

    clearTimeout(this.timeouts[`${clientId.assetId}_${row.assetId}_${beneficiary}`]);

    this.timeouts[`${clientId.assetId}_${row.assetId}_${beneficiary}`] = setTimeout(() => {
      this.root.updateBeneficiaryAmount.fetch({
        clientId,
        assetId: row.assetId,
        beneficiaryId: beneficiary,
        amount: getMoneyNumber(value),
      });

      this.root.updateClients
        .fetch({
          clientId: this.root.clientStore.selectedClientId,
          user_updated: this.root.getUserFromToken.data.first_name + " " + this.root.getUserFromToken.data.last_name,
          assets_updated_at: new Date(),
        })
        .then(() => {
          this.root.client.fetch({
            clientId: this.root.clientStore.selectedClientId,
          });
        });
    }, 300);
  };

  getBeneficiaryAmount = (assetId, beneficiaryId) => {
    const clientId = this.root.clientStore.selectedClientId;
    let value = _.get(this, `beneficiaryAmounts.${clientId}.${assetId}.${beneficiaryId}`);

    if(typeof value === 'number') {
      value = Math.abs(value);
    }
    if(typeof value === 'string' && value.startsWith('-')) {
      value = value.substr(1)
    }
    return typeof value === 'number'? value.toLocaleString('de-DE', { minimumFractionDigits: 2 }): value;
  };

  get distributed() {
    if (!this.data) {
      return {
        header: [],
        sections: [],
      };
    }
    const header = [
      {
        title: "Vermögensgegenstände",
        large: true,
        scale: 0.55,
      },
      {
        title: "Wert in €",
        scale: 0.1,
      },
      {
        title: "Status",
        scale: 0.15,
      },
      {
        title: "Kaufer",
        scale: 0.1,
      },
      {
        title: "Veräußerter Wert in €",
        scale: 0.1,
      },
    ];

    const sections = [
      {
        title: "Entsorgtes Vermögen",
        scale: 1,
        blanks: [],
        categories: [],
      },
      {
        title: "Freigiebige Zuwendungen",
        scale: 1,
        blanks: [],
        categories: [],
      },
      {
        title: "Vermächtnis",
        scale: 1,
        blanks: [],
        categories: [],
      },
      {
        title: "Veräußertes Vermögen",
        scale: 1,
        blanks: [],
        categories: [],
      },
      {
        title: "Auseinandergesetztes Vermögen",
        scale: 1,
        blanks: [],
        categories: [],
      },
      {
        title: "Verwaltetes Vermögen",
        scale: 1,
        blanks: [],
        categories: [],
      },
    ];

    const resolutions = {
      disposal: [],
      donation: [],
      legacy: [],
      sell: [],
      dispute: [],
      administration: [],
    };

    (this.data.assets || []).forEach(asset => {
      if (asset.resolution && asset.resolution !== "unknown" && (asset.status === "sold" || asset.status === "assigned" || asset.status === "handing_over")) {
        resolutions[asset.resolution].push(asset);
      }
    });

    Object.keys(resolutions).forEach(resolution => {
      let index = 0;
      switch (resolution) {
      case "disposal":
        index = 0;
        break;
      case "donation":
        index = 1;
        break;
      case "legacy":
        index = 2;
        break;
      case "sell":
        index = 3;
        break;
      case "dispute":
        index = 4;
        break;
      case "administration":
        index = 5;
        break;
      default:
        break;
      }

      resolutions[resolution].forEach(asset => {
        const assetCategory = this.data.assetCategories.find(cat => asset.asset_category_id === cat.asset_category_id);

        const categoryAssets = this.data.assets.filter(parsedAsset => parsedAsset.asset_category_id === assetCategory.asset_category_id);
        let existingCat = sections[index].categories.find(c => c.title === assetCategory.asset_list_name);

        const pushable = {
          asset_category_id: assetCategory.asset_category_id,
          title: assetCategory.asset_list_name,
          value: 0,
          // value: calculateValue(categoryAssets, 'asset_value'),
          value_sold: 0,
          // value_sold: calculateValue(categoryAssets, 'asset_value_sold'),
          scales: [0.06, 0.19, 0.3, 0.1, 0.15, 0.1, 0.1],
          status: assetCategory.status,
          beneficiary: assetCategory.beneficiary ? assetCategory.beneficiary.split(",") : [],
          rows: categoryAssets
            .filter(a => {
              return !a.deleted && (a.resolution === resolution && (a.status === "sold" || a.status === "assigned" || a.status === "handing_over"));})
            .map(asset => {
              return {
                assetId: asset.asset_id,
                title: asset.asset_name,
                value: formatMoneyToString(Number(this.changedValues[`${asset.asset_id}_asset_value`] || asset.asset_value)),
                status_placeholder: "Status",
                status_options: ["assigned", "open", "sold", "not_sold", "handing_over"],
                status: asset.status,
                beneficiary: asset.beneficiary ? asset.beneficiary.split(",") : [],
                determination: asset.resolution,
                valueSold: formatMoneyToString(Number(this.changedValues[`${asset.asset_id}_asset_value_sold`] || asset.asset_value_sold || 0)),
                fields: {
                  inputs: (asset.fields || []).map(field => ({
                    id: field.field_id,
                    name: field.field_id,
                    placeholder: field.title,
                    value: this.changedFields[field.field_id] || field.value || "",
                  })),
                  files: (asset.files || []).map(file => {
                    const n = file.file_url.split("/");
                    const title = n.length > 0 ? n[n.length - 1] : "File";
                    return {
                      ...file,
                      title,
                      src: `${config.endpoints.api}/${file.file_url}`,
                    };
                  }),
                },
              };
            }),
        };
        if(!existingCat) {
          sections[index].categories.push(pushable);
        }

        sections.forEach((s, si) => {
          s.categories.forEach((c, ci) => {
            let value = 0;
            let value_sold = 0;
            c.rows.forEach((r, ri) => {
              value += getMoneyNumber(r.value);
              value_sold += getMoneyNumber(r.valueSold);
            });

            sections[si].categories[ci].value = value.toLocaleString('de-DE', { minimumFractionDigits: 2 });
            sections[si].categories[ci].value_sold = value_sold.toLocaleString('de-DE', { minimumFractionDigits: 2 });
          });
        });
      });
    });

    return {
      header,
      sections,
    };
  }

  get constructed() {
    if (!this.data) {
      return null;
    }
    const header = [
      {
        title: "Vermögensgegenstände",
        large: true,
        scale: 0.4,
      },
      {
        title: "Wert in €",
        scale: 0.08,
      },
      {
        title: "Bestimmung",
        scale: 0.12,
      },
      {
        title: "Status",
        scale: 0.1,
      },
      {
        title: "Erwerber / Empfänger",
        scale: 0.15,
      },
      {
        title: "Veräußerter Wert in €",
        scale: 0.1,
      },
      {
        title: "Verändern",
        scale: 0.05,
      },
    ];

    const sections = [
      {
        title: "Aktiva",
        scale: 0.4,
        blanks: [0.12, 0.10, 0.15],
        value: 0,
        valueSold: 0,
        categories: [],
      },
      {
        // title: '[TODO] German title for liability',
        title: "Passiva",
        scale: 0.4,
        blanks: [0.12, 0.10, 0.15],
        value: 0,
        valueSold: 0,
        categories: [],
      },
    ];

    this.data.assetCategories.forEach(assetCategory => {
      let index = 0;
      if (assetCategory.asset_category_type === "liability") {
        index = 1;
      }

      const categoryAssets = this.data.assets.filter(parsedAsset => parsedAsset.asset_category_id === assetCategory.asset_category_id);

      sections[index].categories.push({
        asset_category_id: assetCategory.asset_category_id,
        title: assetCategory.asset_list_name,
        value: calculateValue(categoryAssets, "asset_value"),
        value_sold: calculateValue(categoryAssets, "asset_value_sold"),
        scales: [0.05, 0.15, 0.2, 0.08, 0.12, 0.1, 0.15, 0.1, 0.05],
        determination: assetCategory.determination,
        determination_placeholder: "Bestimmung wählen",
        determination_options: ["disposal", "donation", "legacy", "sell", "dispute", "administration"],
        status_placeholder: "Status",
        status_options: ["assigned", "open", "sold", "not_sold", "handing_over"],
        status: assetCategory.status,
        beneficiary: assetCategory.beneficiary ? assetCategory.beneficiary.split(",") : [],
        beneficiary_placeholder: "Begünstigter",
        beneficiary_options: (this.root.personsListing.data || []).filter(b => b.is_beneficiary).map(beneficiary => beneficiary.person_id),
        rows: categoryAssets
          .filter(a => {
            
            return !a.deleted && (a.resolution === "unknown" || (a.status === "open" || a.status === "not_sold"));
          })
          .map(asset => {
            const beneficiaryOptions = this.root.personsListing.data || [];
            return {
              assetId: asset.asset_id,
              title: asset.asset_name,
              value: this.changedValues[`${asset.asset_id}_asset_value`] || asset.asset_value,
              status_placeholder: "Status",
              status_options: ["assigned", "open", "sold", "not_sold", "handing_over"],
              status: asset.status,
              beneficiary: asset.beneficiary ? asset.beneficiary.split(",") : [],
              beneficiary_placeholder: "Begünstigter",
              beneficiary_options: beneficiaryOptions.filter(b => b.is_beneficiary).map(beneficiary => beneficiary.person_id),
              determination: asset.resolution,
              determination_placeholder: "Bestimmung wählen",
              determination_options: ["disposal", "donation", "legacy", "sell", "dispute", "administration"],
              valueSold: this.changedValues[`${asset.asset_id}_asset_value_sold`] || asset.asset_value_sold || 0,
              fields: {
                inputs: (asset.fields || []).map(field => ({
                  id: field.field_id,
                  name: field.field_id,
                  placeholder: field.title,
                  value: this.changedFields[field.field_id] || field.value || "",
                })),
                files: (asset.files || []).map(file => {
                  const n = file.file_url.split("/");
                  const title = n.length > 0 ? n[n.length - 1] : "File";
                  return {
                    ...file,
                    title,
                    src: `${config.endpoints.api}/${file.file_url}`,
                  };
                }),
              },
            };
          }),
      });
    });

    sections.forEach((s, si) => {
      let sValue = 0;
      let sValueSold = 0;
      s.categories.forEach((c, ci) => {
        let value = 0;
        let value_sold = 0;
        c.rows.forEach((r, ri) => {
          value += Number(r.value);
          value_sold += Number(r.valueSold);
        });

        sections[si].categories[ci].value = value;
        sections[si].categories[ci].value_sold = value_sold;

        sValue += Number(value);
        sValueSold += Number(value_sold);
      });

      sections[si].value = Number(sValue);
      sections[si].valueSold = Number(sValueSold);
    });

    return {
      header,
      sections,
    };
  }

  onDeleteAsset = (asset, catIndex) => () => {
    const response = window.confirm('Are you sure?');
    if(response) {
      this.root.assetModifier.fetch({
        assetId: asset.assetId,
        deleted: true,
      });
      window.location.reload();
    }
  }
}
export default decorate(AssetStore, {
  changedFields: observable,
  changedValues: observable,
  beneficiaryAmounts: observable,
  // settlement: observable,
  // setSettlement: action,
  constructed: computed,
  onChangeInputValue: action,
  onChangeFieldValue: action,
  onDeleteAsset: action,
});
