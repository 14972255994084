import React from "react";
import { Hamburger, SidebarTitleController } from "../../../../styled/sidebar";
import MenuIcon from "../../../../assets/images/hamburger.png";

const SideMenu = ({ open, onPress }) => (
  <Hamburger onClick={onPress} className={open ? "expanded" : "collapsed"} open={open}>
    <img src={MenuIcon} alt="Menu" />
    <SidebarTitleController open={open} className={open ? "expanded" : "collapsed"}>
      <i>Menu</i>
    </SidebarTitleController>
  </Hamburger>
);

export default SideMenu;
