import React from "react";
import PropTypes from "prop-types";

const LoginBottomLink = ({ title, ...props }) => (
  <div className="listblocks">
    <a {...props}>{title}</a>
  </div>
);

LoginBottomLink.propTypes = {
  title: PropTypes.string.isRequired,
};

export default LoginBottomLink;
