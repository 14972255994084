import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";

import { P, Layout, Icon } from "../styled";
import UserProfileMandantenNumber from "../components/UserProfileCard/UserProfileMandatenNumber";
import ProgressSlider from "../components/ProgressSlider";
import NewItemSearch from "../components/NewItemSearch";
import DistributedAssetList from "../components/AssetList/Distributed";
import { LineBreak } from "../styled/sidebar";
import Userphoto from "../assets/images/empty.png";
import config from "../lib/config";

class AssetsAktuell extends Component {
  componentDidMount() {
    const {
      store: { client, clientStore },
      // client
    } = this.props;

    client.fetch({
      clientId: clientStore.selectedClientId,
    });
  }
  render() {
    const {
      store: { client, assets },
    } = this.props;
    let possible = 0;
    let completed = 0;
    if (assets.data) {
      assets.data.assets.forEach(asset => {
        if (!!asset.beneficiary && asset.status !== "open") {
          completed++;
        }
      });
      possible = assets.data.assets.length;
    }

    const imageSource =
      client.data.profile_image_url === "uploads/empty.png" ? Userphoto : `${config.endpoints.api}/${client.data.profile_image_url}`;

    return (
      <React.Fragment>
        <Layout overflowHidden>
          <Layout
            boxShadow="type_1"
            displayFlex
            row
            alignCenter
            ustifyStart
            spacingLeft={55}
            spacingTop={40}
            spacingBottom={60}
            zIndex={100}
            relative
            background="white"
            mediaSidePadding={600}
          >
            {client.data && (
              <UserProfileMandantenNumber
                firstName={client.data.first_name}
                imageSource={imageSource}
                title={client.data.title || ""}
                lastName={client.data.last_name}
                mandantenNumber={client.data.client_number}
                birthday={moment(client.data.birth_date).format("DD-MM-YYYY")}
                createdAt={moment(client.data.createdAt).format("DD-MM-YYYY")}
              />
            )}
          </Layout>
          <ProgressSlider possible={possible} completed={completed} />
          {client.loaded && <DistributedAssetList client={client.data} />}
          {(!assets.data || assets.data.assets.length === 0) && (
            <React.Fragment>
              <Layout spacingTop={60} spacingBottom={24} displayFlex alignCenter justifyCenter>
                <P fontWeight="bold">Vermögensgegenstände</P>
              </Layout>
              <LineBreak />
            </React.Fragment>
          )}
          {/* <Layout
            spacingTop={60}
            spacingBottom={70}
            displayFlex
            alignCenter
            justifyCenter
            width="100%"
          >
            <Layout
              displayFlex
              alignLeft
              justifyStart
              column
              flex={1}
              maxWidth={910}
            >
              <Layout
                offsetBottom={35}
                displayFlex
                row
                alignCenter
                justifyStart
              >
                <Icon type="material-icons">account_balance</Icon>
                <P spacingLeft={20} fontWeight="bold">
                  Neue Position erfassen
                </P>
              </Layout>
              <NewItemSearch />
            </Layout>
          </Layout> */}
        </Layout>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(AssetsAktuell);
