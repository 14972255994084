import styled from "styled-components";
import { COLORS } from "./constants";

export const ModalFooterOuter = styled.div`
  padding: 42px 28px;
  ${props => (props.floatRight ? "float: right;" : "")}
  ${props => (props.background ? "" : `background: ${COLORS.LIGHT_GREY};`)}
  button {
    position: relative;
    min-width: 170px;
  }
`;
