import moment from "moment";

export const nextAppointmentOrderAdapter = {
  note_heading: {
    key: "row_1",
    order: 0,
    scale: 1,
    title: "Nächster Termin",
  },
  task_open_date: {
    key: "row_2",
    order: 1,
    scale: 1,
    title: "Datum",
  },
  client_number: {
    key: "row_3",
    order: 2,
    scale: 1,
    title: "Mandanten ID",
  },
  first_name: {
    key: "row_4",
    order: 3,
    scale: 1,
    title: "Vorname",
  },
  last_name: {
    key: "row_5",
    order: 4,
    scale: 1,
    title: "Nachname",
  },
};

export const nextAppointmentHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

// const declareDocument = async (event, store, index) => {
//   event.stopPropagation();
//   const { documents, editDocument } = store;
//   const doc = documents.data[index];

//   const data = {
//     is_delete: !doc.is_delete
//   };

//   await editDocument.fetch({
//     documentId: doc.document_id,
//     ...data
//   });
//   await documents.fetch();
//   console.log(store, index);
// };

/*****modal*****/

export const nextAppointmentRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          case "task_open_date": {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: moment(item[itemKey]).format("DD.MM.YYYY"),
            };
            break;
          }
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          }
        }
      });
      rows[index] = row;
    });
  }
  return rows;
};
