import React, { Component } from "react";
import { Layout, P } from "../../styled";
import { compose } from "recompose";
import { AdministrationOuter, TableContent } from "../../styled/administration";
import RoyaltyStatementHeader from "./RoyaltyStatementHeader";
import Table from "../Table";
import { inject, observer } from "mobx-react";
import { ResponseWarning } from "../../styled/layout";

class UserAdministration extends Component {
  componentDidMount() {
    const {
      store: { licenseSettlement },
    } = this.props;
    licenseSettlement.fetch();
  }

  render() {
    const {
      store: { licenseSettlement },
    } = this.props;

    return (
      <AdministrationOuter>
        <Layout displayFlex alignCenter>
          <RoyaltyStatementHeader />
        </Layout>
        <TableContent>
          <P>Historie Lizenzabrechnungen</P>
          {licenseSettlement.constructedTable.rows[0] ? (
            <Table hideHeader hideFooter small tableStore={licenseSettlement} />
          ) : (
            <ResponseWarning>
              <div className="response success">
                <i className="material-icons">warning</i> {"Aktuell liegt noch keine Lizenzabrechnung vor"}
              </div>
            </ResponseWarning>
          )}
        </TableContent>
      </AdministrationOuter>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(UserAdministration);
