import React, { Component } from 'react';
import { Layout, Icon } from "../../styled";
import { COLORS } from '../../styled/constants';
import { NotificationContainer } from '../../styled/notification';

import { compose } from "recompose";
import { inject, observer } from "mobx-react";


class Notification extends Component {
  render() {
    const { store: { notificationsStore } } = this.props;

    if(notificationsStore.notifications.length === 0){
      return null;
    }
    return (
      <NotificationContainer>
        <Layout position="relative">
          <Layout opacity={0.3} displayFlex justifyEnd>
            <Icon
              onClick={() => notificationsStore.removeNotification(0)}
              className="material-icons"
              size={8}
              color={COLORS.FULL_WHITE}
            >
              clear
            </Icon>
          </Layout>
          <Layout 
            spacingTop={5}
            spacingBottom={12}
            spacingLeft={17}
            spacingRight={21}
          >
            <p>{notificationsStore.notifications[0]}</p>
          </Layout>
        </Layout>
      </NotificationContainer>
    );
  }
}


export default compose(
  inject("store"),
  observer
)(Notification);