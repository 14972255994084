import { decorate, action, observable } from "mobx";

import PromiseStore from "./PromiseStore";
import { endpoints } from "../lib/endpoints";
import config from "../lib/config";
import { routes } from "../routes/routes";
import { browserHistory } from "../routes/routes";

class UserStore {
  constructor(__, ___, root) {
    this.root = root;
    this.login = new PromiseStore(endpoints.login);
  }

  currentUser = null

  doLogin = async (values) => {
    try {
      const loginResponse = await this.login.fetch(values, this._handleLoginResponse);

      if (loginResponse.data.meta === 'User not found!') {
        return 'user';
      }

      if (loginResponse.data.meta === 'Invalid credentials!') {
        return 'password';
      }

      if (loginResponse.data.meta === 'User has been deactivated!') {
        return 'deactivated';
      }

      this.root.clientStore.setSelectedClientId(null);
      window.localStorage.setItem(config.keys.local_storage_key, loginResponse.headers.authentication);


      browserHistory.push(routes.MYOVERVIEW);
      browserHistory.go();
      return true;
    } catch (error) {
      console.log("error", error);
    }
  }
  setCurrentUser = (currentUser) => {
    this.currentUser = currentUser;
  }

  _handleLoginResponse = (data) => {
    console.log('AFTER LOGIN', data);
  }
}



export default decorate(UserStore, {
  doLogin: action,
  profileImage: observable,
  setLoggedUser: action,
  currentUser: observable,
});
