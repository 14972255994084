import {
  Copy,
  Copyright,
  FlexBox,
  FooterLinksOuter,
  ForgotPassword,
  FormBox,
  LandingImage,
  LoginBoxRight,
  LoginContainer,
  LoginFlex,
  LoginFooter,
  LoginImg,
  LoginWrapper,
  LogoImage,
} from "../styled/auth";

import LoginThemeImg from "../assets/images/login-image.jpg";
import Logo from "../assets/images/logo.PNG";
import React from "react";
import { TitleH } from "../styled";
import ForgotPasswordForm from "../components/ForgotPassword/Form";
import LoginBottomLink from "../components/Auth/LoginBottomLink";
import FooterLink from "../components/Auth/FooterLink";

function PasswordForgot() {
  return (
    <LoginWrapper>
      <LoginFlex>
        <LoginContainer>
          <FlexBox>
            <LoginImg>
              <a href="/home" className="mob-only">
                <LandingImage src={Logo} alt="Testamentsvollstreckung" />
              </a>
              <img src={LoginThemeImg} alt="Login" />
            </LoginImg>
            <LoginBoxRight>
              <LogoImage>
                <a href="/home" className="mob-hide">
                  <LandingImage src={Logo} alt="Testamentsvollstreckung" />
                </a>
              </LogoImage>
              <TitleH>Passwort vergessen?</TitleH>
              <Copy>Gib unten deine E-Mail-Adresse ein und wir senden dir einen Link, um dein Passwort zurückzusetzen.</Copy>
              <ForgotPasswordForm />
              <FormBox />
              <ForgotPassword>
                <LoginBottomLink href="/login" title="Zurück zum Einloggen" />
              </ForgotPassword>
            </LoginBoxRight>
          </FlexBox>
        </LoginContainer>
        <LoginFooter>
          <Copyright>&copy; Copyright Protevos. Alle Rechte vorbehalten.</Copyright>
          <FooterLinksOuter>
            <FooterLink target="_blank" href="https://innovation-wist.de/agb.html" title="AGB" children="AGB" />
            <FooterLink
              target="_blank"
              href="https://protevos.de/impressum.html"
              title="Datenschutz und Impressum"
              children="Datenschutz und Impressum"
            />
          </FooterLinksOuter>
        </LoginFooter>
      </LoginFlex>
    </LoginWrapper>
  );
}
export default PasswordForgot;
