import React from "react";
import { Layout } from "../../../styled";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";
import PaymentOptions from "../../Signup/PaymentOptions";
import { P } from "../../../styled";
import * as Yup from "yup";


class ChangeLicence extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      fields: {},
      errors: {},
    };
  }


  onInputChage = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    let schema = "";

    if (this.state.isAnotherAddress) {
      schema = Yup.object().shape({
        billingPostCode: Yup.string().required(requiredError),
        billingHouseNumber: Yup.string().required(requiredError),
        billingLastName: Yup.string().required(requiredError),
        billingPlace: Yup.string().required(requiredError),
        billingStreet: Yup.string().required(requiredError),
        billingFirstName: Yup.string().required(requiredError),
      });

      try {
        schema.validateSync(fields, { abortEarly: false });
      } catch (err) {
        if (err.inner) {
          err.inner.forEach(e => {
            errors[e.path] = e.message;
          });
        }
      }

      formIsValid = schema.isValidSync(fields);

      if (Object.keys(errors).length > 0) {
        const key = Object.keys(errors)[0];
        if (this[key]) {
          this[key].focus();
        }
      }

      this.setState({ errors: errors });
      return formIsValid;
    }

    return formIsValid;
  }
  handleSubmit = async paymentValid => {
    const {
      store: {
        licencePurchase,
        userDetials,
        licenseManagement,
        router: { history },
        licenceCount,
      },
    } = this.props;
  
    // userD licenseManagement.fetch();
  
    const {
      isAnotherAddress,
      billingFirstName,
      billingLastName,
      billingStreet,
      billingPlace,
      billingHouseNumber,
      billingPostCode,
      cardHolder,
      cardNumber,
      cvv,
      mmyy,
      adminLicenceCost,
      userLicenceCost,
      adminLicenceCount,
      userLicenceCount,
    } = this.state;
  
    const totalAmount = adminLicenceCost + userLicenceCost + userDetials.data.one_time_setup_fee;
    let netCost = totalAmount + (totalAmount * 19) / 100;
  
    if (!this.handleValidation() || !paymentValid) {
      return false;
    }
  
    const data = {
      card_holder: cardHolder,
      card_number: cardNumber,
      cvc: cvv,
      exp_month: mmyy ? mmyy[0] + mmyy[1] : "",
      exp_year: mmyy ? mmyy[3] + mmyy[4] : "",
      amount: Number(netCost.toFixed(0)),
      billing_postcode: billingPostCode,
      billing_place: billingPlace,
      billing_house_number: billingHouseNumber,
      billing_street: billingStreet,
      billing_last_name: billingLastName,
      billing_first_name: billingFirstName,
      is_different_address: isAnotherAddress,
      admin_licence_number: adminLicenceCount,
      user_licence_number: userLicenceCount,
    };
  
    await licencePurchase.fetch(data);
    licenseManagement.fetch();
    licenceCount.fetch();
    history.replace("/administration");
  
    return history.go();
  };

  render() {
      
    return (
      <React.Fragment>
        <Layout displayFlex spacingLeft={10} spacingRight={10} flex={1} width="100%" column>
          <P type="type-7">Auswahl Bezahlmethode</P>
          <PaymentOptions onChange={this.onInputChage} parentMethod={this.handleSubmit} />

        </Layout>

      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(ChangeLicence);
