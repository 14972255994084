import React from "react";
import _ from "lodash";
import { toJS } from "mobx";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

import { P, Layout, Icon } from "../../../styled";
import Select from "../../Form/Select";
import FormInput from "../../Form/Input";
import { ModalFooterOuter } from "../../../styled/modal";
import { ResponseMessage } from "../../../styled/layout";
import { Button } from "../../../styled/buttons";

class AddNewCatalogEntry extends React.Component {
  constructor(props) {
    super(props);
    let cat = null;
    if (props.category) {
      cat = toJS(props.category);
    }

    this.state = cat
      ? {
        overCategory: cat.asset_category_type,
        category: cat.asset_list_name,
        addingNew: false,
        newCatValue: "",
        uploading: false,
      }
      : {
        overCategory: null,
        category: null,
        addingNew: false,
        newCatValue: "",
        uploading: false,
      };
  }

  handleSelected = category => value => {
    if (value === "Neue Hauptkategorie") {
      this.setState({ addingNew: true });
    } else {
      this.setState({ [category]: value });
    }
  };

  resetAddNew = () => {
    this.setState({
      addingNew: false,
      selected: null,
      newAssetValue: "",
      newCatValue: "",
      asset_value: "",
      asset_value_sold: "",
    });
  };

  handleChangeCatValue = e => {
    this.setState({ newCatValue: e.target.value });
  };

  handleChangeNewAssetValue = e => {
    this.setState({ newAssetValue: e.target.value });
  };

  isValidForm = (overCategory, addingNew, possibleCategories, category, newCatValue, newAssetValue) => {
    const errorMessage = (
      <div className="response error">
        {" "}
        <i className="material-icons">check_box</i> Bitte alle Felder ausfüllen.
      </div>
    );
    if (overCategory === null) {
      this.setState({
        message: errorMessage,
      });
      return false;
    } else if (!addingNew) {
      if (possibleCategories.length > 0) {
        if (category === null) {
          this.setState({
            message: errorMessage,
          });
          return false;
        } else {
          if (newAssetValue === undefined || newAssetValue === "") {
            this.setState({
              message: errorMessage,
            });
            return false;
          }
        }
      } else {
        if (this.state.createNew === null) {
          this.setState({
            message: errorMessage,
          });
          return false;
        }
      }
    } else if (addingNew) {
      if (newCatValue === "" || newCatValue === undefined) {
        this.setState({
          message: errorMessage,
        });
        return false;
      } else {
        if (newAssetValue === undefined || newAssetValue === "") {
          this.setState({
            message: errorMessage,
          });
          return false;
        }
      }
    }

    return true;
  };

  submit = () => {
    const {
      store: { assets, newAsset, newAssetCategory, clientStore },
      closeModal,
      activeStore,
      onSubmit,
    } = this.props;
    const { overCategory, category, asset_value, asset_value_sold, addingNew, newCatValue, newAssetValue } = this.state;
    const possibleCategories = (activeStore || assets).data.assetCategories
      .filter(category => category.asset_category_type === overCategory)
      .map(cat => cat.asset_list_name);

    if (this.isValidForm(overCategory, addingNew, possibleCategories, category, newCatValue, newAssetValue)) {
      this.setState({
        uploading: true,
      });
      if (!addingNew) {
        const selectedCat = (activeStore || assets).data.assetCategories.filter(cat => cat.asset_list_name === category)[0];

        const data = {
          clientId: clientStore.selectedClientId,
          asset_category_id: selectedCat.asset_category_id,
          asset_name: newAssetValue,
          asset_value: asset_value || 0,
          asset_value_sold: asset_value_sold || 0,
          asset_type: _.snakeCase(category),
        };

        if (!activeStore) {
          newAsset.fetch(data).then(asset => {
            assets
              .fetch({
                clientId: clientStore.selectedClientId,
              })
              .then(() => {
                closeModal();
              });
          });
        } else {
          onSubmit(data);
        }
      } else {
        newAssetCategory
          .fetch({
            clientId: clientStore.selectedClientId,
            asset_category_type: overCategory,
            asset_list_name: newCatValue,
          })
          .then(createdCategory => {
            const { asset_category_id } = newAssetCategory.data;

            const data = {
              clientId: clientStore.selectedClientId,
              asset_category_id: asset_category_id,
              asset_name: newAssetValue,
              asset_value: asset_value || 0,
              asset_value_sold: asset_value || 0,
            };

            if (activeStore) {
              onSubmit(data);
            } else {
              newAsset.fetch(data).then(asset => {
                assets
                  .fetch({
                    clientId: clientStore.selectedClientId,
                  })
                  .then(() => {
                    closeModal();
                  })
                  .catch(e => {
                    closeModal();
                  });
              });
            }
          });
      }

      this.setState({
        uploading: false,
      });
    }
  };

  render() {
    const {
      store: { assets },
      closeModal,
      activeStore,
    } = this.props;
    const { overCategory, addingNew, category, newCatValue, newAssetValue, uploading } = this.state;
    const possibleCategories = (activeStore || assets).data.assetCategories
      .filter(category => category.asset_category_type === overCategory)
      .map(cat => cat.asset_list_name);

    let safeOverCategory = undefined;
    if (overCategory === "asset") {
      safeOverCategory = "Aktiva";
    }
    if (overCategory === "liability") {
      safeOverCategory = "Passiva";
    }

    return (
      <React.Fragment>
        <Layout column displayFlex fullWidth spacingLeft={40} spacingRight={40} spacingTop={40} spacingBottom={40}>
          <Layout minWidth="500px" displayFlex row alignCenter offsetBottom={20} mediaWidth={700} rowToColumn={500}>
            <Layout spacingRight={40} flex={1}>
              <P type="type-8">Überkategorie auswählen:</P>
            </Layout>
            <Layout width="200px">
              <Select
                onChange={this.handleSelected("overCategory")}
                placeholder={safeOverCategory || "wählen"}
                options={["asset", "liability"]}
                selected={overCategory}
              />
            </Layout>
          </Layout>
          {!addingNew ? (
            <React.Fragment>
              {possibleCategories.length > 0 ? (
                <Layout minWidth="500px" displayFlex row alignCenter offsetBottom={20} mediaWidth={700} rowToColumn={500}>
                  <Layout spacingRight={40} flex={1}>
                    <P type="type-8">Hauptkategorie auswählen:</P>
                  </Layout>
                  <Layout width="200px">
                    <Select
                      noAdapter
                      selected={category}
                      overflow={true}
                      height={true}
                      placeholder={category || "wählen"}
                      options={[...possibleCategories, "Neue Hauptkategorie"]}
                      onChange={this.handleSelected("category")}
                    />
                  </Layout>
                </Layout>
              ) : (
                <Layout minWidth="500px" displayFlex row alignCenter offsetBottom={20} mediaWidth={700} rowToColumn={500}>
                  <Layout spacingRight={40} flex={1}>
                    <P type="type-8">Hauptkategorie auswählen:</P>
                  </Layout>
                  <Layout width="200px">
                    <Select
                      overflow={true}
                      height={true}
                      noAdapter
                      placeholder="wählen"
                      options={["Neue Hauptkategorie"]}
                      onChange={this.handleSelected("createNew")}
                    />
                  </Layout>
                </Layout>
              )}
            </React.Fragment>
          ) : (
            <Layout minWidth="500px" displayFlex row alignCenter offsetBottom={20} mediaWidth={700} rowToColumn={500}>
              <Layout flex={1} displayFlex alignCenter justifyCenter>
                <FormInput value={newCatValue} onChange={this.handleChangeCatValue} placeholder="Bezeichnung Hauptkategorie" />
                <Icon onClick={this.resetAddNew} style={{ marginBottom: 21 }}>
                  close
                </Icon>
              </Layout>
            </Layout>
          )}
          {((addingNew && newCatValue !== "") || category) && (
            <React.Fragment>
              <Layout minWidth="500px" displayFlex row alignCenter mediaWidth={700}>
                <Layout flex={1}>
                  <FormInput value={newAssetValue} onChange={this.handleChangeNewAssetValue} placeholder="Bezeichnung Unterkategorie" />
                </Layout>
              </Layout>
            </React.Fragment>
          )}
          {this.state.message && (
            <ResponseMessage>
              {" "}
              <div> {this.state.message} </div>{" "}
            </ResponseMessage>
          )}
        </Layout>
        <ModalFooterOuter>
          <Button onClick={closeModal} color="grey">
            Abbrechen
          </Button>
          <Button onClick={!uploading && this.submit} color="green" style={{ marginRight: "0px" }}>
            {uploading ? "Ihre Datei wird verarbeitet" : "Speichern"}
          </Button>
        </ModalFooterOuter>
      </React.Fragment>
    );
  }
}
export default compose(
  inject("store"),
  observer,
)(AddNewCatalogEntry);
