import React from "react";
import { compose } from "recompose";
import * as Yup from "yup";
import { inject, observer } from "mobx-react";
import _ from "lodash";

import PaymentOptions from "../components/Signup/PaymentOptions";
import FormInput from "../components/Form/Input";
// import withPageWrapper from "../hoc/Page";
import Select from "../components/Form/Select";
import DatePicker from "../components/Form/DatePicker";
import CheckBox from "../components/Form/CheckBox";
import { Layout, P, Icon } from "../styled";
import Radio from "../components/Form/Radio";

import { routes } from "../routes/routes";
import { browserHistory } from "../routes/routes";
import FormSelect from "../components/Form/InputSelect";
import { FormInputLabel } from "../styled/form";
import {
  SignUp,
  Order,
  PrimaryInfo,
  LicenseModalSelectionOptions,
  AdditionalLicense,
  UserInformation,
  DeviatingInvoiceAddress,
  InvoiceAddressForm,
  DatePickerItem,
  AdminLicenceField,
  NumberSelectHolder,
  UserInformationRow,
  UserInformationRowPass,
} from "../styled/signup";
import { PageTitleContainer, PageTitle, PageRow, ResponseMessage } from "../styled/layout";
import { LineBreak, Label } from "../styled";

const INITIAL_STATE = {
  adminLicenceCost: 0,
  userLicenceCost: 0,
  adminLicenceCount: 0,
  newuserCount: 0,
  newuserLicenceCost: 0,
  newuserLicence: false,
  userLicenceCount: 0,
  licenceTotalCost: 0,
  adminLicence: false,
  userLicence: false,
  isAnotherAddress: false,
  billingFirstName: "",
  billingLastName: "",
  billingStreet: "",
  billingPlace: "",
  billingHouseNumber: "",
  billingPostCode: "",
  newPassword: "",
  confirmPassword: "",
  paymentMethod: "creditcard",
  email: null,
};

class UserSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      fields: {},
      errors: {},
      dateOfBirth: "",
      selectedLicence: 0,
    };
  }

  handleChange = field => e => {
    this.setState({
      [field]: e.target.value,
      errors: { ...this.state.errors, [field]: "" },
    });
  };

  handleCheckboxChange = name => () => {
    this.setState(state => ({
      [name]: !state[name],
    }));

    if (name === "adminLicence") {
      this.setState({ adminLicenceCount: 0, adminLicenceCost: 0 });
    } else if (name === "userLicence") {
      this.setState({ userLicenceCount: 0, userLicenceCost: 0 });
    }
  };

  handleSelected = category => value => {
    this.setState({ [category]: value });
    if (category === "newuserCount") {
      let userCost = 180 * value;
      this.setState({ newuserLicenceCost: userCost, newuserCount: value });
    }
    if (category === "userCount") {
      let userCost = 12 * 59 * value;
      this.setState({ userLicenceCost: userCost, userLicenceCount: value });
    } else if (category === "adminCount") {
      let adminCost = 12 * 99 * value;
      this.setState({
        adminLicenceCost: adminCost,
        adminLicenceCount: value,
      });
    }
  };

  onInputChage = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  handleDateChange = data => {
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    if (new Date(data) >= new Date(yesterday)) {
      this.setState({
        dateOfBirth: "",
        dateOfBirthError: "Sie können kein Datum in der Zukunft oder heute eingeben!",
      });
      return;
    }
    this.setState({ dateOfBirth: new Date(data), dateOfBirthError: "" });
  };

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    let regExp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-_=+])[a-zA-Z0-9!@#$%^&*-_=+]{12,20}$/;
    let schema = "";

    if (this.state.isAnotherAddress) {
      schema = Yup.object().shape({
        lastName: Yup.string().required(requiredError),
        firstName: Yup.string().required(requiredError),
        number: Yup.string()
          .matches(/\d/, "Muss mindestens eine Nummer eingeben")
          .required(requiredError),
        dateOfBirth: Yup.date(requiredError)
          .typeError(requiredError)
          .required(requiredError),
        postCode: Yup.string()
          .matches(/^[0-9]+$/, "Nur Zahlen")
          .required(requiredError),
        place: Yup.string().required(requiredError),
        street: Yup.string().required(requiredError),
        email: Yup.string()
          .required(requiredError)
          .email("E-Mail ist nicht gültig!")
          .nullable(),
        salutation: Yup.string(requiredError).required(requiredError),
        billingPostCode: Yup.string()
          .matches(/^[0-9]+$/, "Nur Zahlen")
          .required(requiredError),
        billingHouseNumber: Yup.string()
          .matches(/\d/, "Muss mindestens eine Nummer eingeben")
          .required(requiredError),
        billingLastName: Yup.string().required(requiredError),
        billingPlace: Yup.string().required(requiredError),
        billingStreet: Yup.string().required(requiredError),
        billingFirstName: Yup.string().required(requiredError),
      });
    } else {
      schema = Yup.object().shape({
        lastName: Yup.string().required(requiredError),
        firstName: Yup.string().required(requiredError),
        number: Yup.string()
          .matches(/\d/, "Muss mindestens eine Nummer eingeben")
          .required(requiredError),
        dateOfBirth: Yup.date(requiredError)
          .typeError(requiredError)
          .required(requiredError),
        postCode: Yup.string()
          .matches(/^[0-9]+$/, "Nur Zahlen")
          .required(requiredError),
        place: Yup.string().required(requiredError),
        street: Yup.string().required(requiredError),
        email: Yup.string()
          .required(requiredError)
          .email("E-Mail ist nicht gültig!")
          .nullable(),
        salutation: Yup.string().required(requiredError),
        newPassword: Yup.string()
          .matches(
            regExp,
            "Das Passwort muss aus mindestens 12-20 Zeichen bestehen und mindestens einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
          )
          .required(requiredError),
        confirmPassword: Yup.string().required(requiredError),
      });
    }

    try {
      schema.validateSync(fields, { abortEarly: false });
    } catch (err) {
      console.log(err);
      if (err.inner) {
        err.inner.forEach(e => {
          errors[e.path] = e.message;
        });
      }
    }

    formIsValid = schema.isValidSync(fields);

    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (this[key]) {
        if (key !== "dateOfBirth") {
          this[key].focus();
        } else {
          errors["dateOfBirth"] = "Das Pflichtfeld darf nicht leer sein!";
        }
      }
    }

    if (this.state.adminLicence && this.state.adminLicenceCount < 1) {
      errors["adminLicence"] = "Wert darf nicht null sein";
      formIsValid = false;
    }
    if (this.state.userLicence && this.state.userLicenceCount < 1) {
      errors["userLicence"] = "Wert darf nicht null sein";
      formIsValid = false;
    }

    if (fields["newPassword"] !== fields["confirmPassword"]) {
      formIsValid = false;
      errors["confirmPassword"] = "Bitte Überprüfen Sie Ihre Eingaben.!";
      this.confirmPassword.focus();
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit = async paymentValid => {
    const {
      store: { userSignup },
    } = this.props;

    const {
      salutation,
      firstName,
      street,
      place,
      email,
      lastName,
      newPassword,
      number,
      postCode,
      nameAffix,
      firm,
      phoneNumber,
      title,
      mobileNumber,
      dateOfBirth,
      isAnotherAddress,
      billingFirstName,
      billingLastName,
      billingStreet,
      billingPlace,
      billingHouseNumber,
      billingPostCode,
      cardHolder,
      cardNumber,
      cvv,
      mmyy,
      bic,
      iban,
      bankinstitut,
      kontoinhaber,
      adminLicenceCost,
      userLicenceCost,
      adminLicenceCount,
      userLicenceCount,
      newuserCount,
      selectedLicence,
      paymentMethod,
    } = this.state;

    const totalAmount = adminLicenceCost + userLicenceCost + 149;
    let netCost = totalAmount + (totalAmount * 19) / 100;

    if (!this.handleValidation() || !paymentValid) {
      return false;
    }

    const data = {
      salutation: salutation,
      licence_type: "admin",
      email: email,
      first_name: firstName,
      last_name: lastName,
      password: newPassword,
      company_name: firm,
      name_affix: nameAffix,
      street: street,
      number: number,
      place: place,
      postcode: postCode,
      phone_number: phoneNumber,
      title: title,
      mobile_number: mobileNumber,
      date_of_birth: dateOfBirth,
      card_holder: cardHolder,
      card_number: cardNumber,
      client_access: "all",
      cvc: cvv,
      exp_month: mmyy ? mmyy[0] + mmyy[1] : "",
      exp_year: mmyy ? mmyy[3] + mmyy[4] : "",
      amount: Number(netCost.toFixed(2)),
      bic: bic,
      iban: iban,
      bankinstitut: bankinstitut,
      kontoinhaber: kontoinhaber,
      admin_licence_number: selectedLicence === 0 ? adminLicenceCount + 1 : 1,
      user_licence_number: selectedLicence === 0 ? userLicenceCount : newuserCount,
      billing_postcode: billingPostCode,
      billing_place: billingPlace,
      billing_house_number: billingHouseNumber,
      billing_street: billingStreet,
      billing_last_name: billingLastName,
      billing_first_name: billingFirstName,
      is_different_address: isAnotherAddress,
      initial_admin_licence: true,
      payment_method: paymentMethod,
      payment_model: selectedLicence === 0 ? "old" : "new",
    };

    let responseMessage = await userSignup.fetch(data);

    if (responseMessage.data.meta === "User already exists with that email!") {
      const { errors } = this.state;
      errors["email"] = "Der User existiert bereits mit dieser E-Mail!";
      this.setState({ errors: errors });
    }
    if (responseMessage.status >= 200 && responseMessage.status <= 200) {
      this.setState({
        message: (
          <div className="response success">
            {" "}
            <i className="material-icons">check_box</i> Nachricht erfolgreich gesendet.
          </div>
        ),
      });
      browserHistory.push(routes.ANNOUNCMENT);
      browserHistory.go();
    } else {
      this.setState({
        message: (
          <div className="response">
            <i className="material-icons">error</i>{" "}
            {_.get(responseMessage, "data.data") || "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."}
          </div>
        ),
      });
    }
  };

  switchPaymentMethod = paymentMethod => {
    this.setState({ paymentMethod });
  };

  changeLicence = async index => {
    this.setState({ selectedLicence: index });
  };

  render() {
    const {
      adminLicenceCost,
      userLicenceCost,
      userLicenceCount,
      newuserCount,
      adminLicenceCount,
      adminLicence,
      userLicence,
      isAnotherAddress,
      newuserLicence,
      selectedLicence,
      newuserLicenceCost,
    } = this.state;

    let totalAmount = adminLicenceCost + 1188 + userLicenceCost + 149;

    const isOldPayment = selectedLicence === 0;
    if (!isOldPayment) {
      totalAmount = newuserLicenceCost + 149 + 300;
    }

    let netCost = totalAmount + (totalAmount * 19) / 100;

    return (
      <React.Fragment>
        <SignUp>
          <PageTitleContainer>
            <PageTitle>Benutzer-Registrierung</PageTitle>
          </PageTitleContainer>
          <UserInformation>
            <PrimaryInfo>
              {/* <Layout
                displayFlex
                spacingLeft={20}
                spacingRight={20}
                flex={1}
                width="100%"
                column
              > */}
              <UserInformationRow>
                <FormSelect
                  required
                  noAdapter
                  label="Anrede"
                  onChange={this.handleSelected("salutation")}
                  options={["Frau", "Herr"].map(a => a)}
                  error={this.state.errors["salutation"]}
                  placeholder="Anrede"
                  ref={el => {
                    this.salutation = el;
                  }}
                />
                <FormInput label="Namenszusatz" onChange={this.handleChange("nameAffix")} placeholder="Namenszusatz" />
              </UserInformationRow>
              <UserInformationRow>
                <FormInput label="Titel" onChange={this.handleChange("title")} placeholder="Titel" />
                <FormInput label="Firma" onChange={this.handleChange("firm")} placeholder="Firma" />
              </UserInformationRow>

              <UserInformationRow>
                <FormInput
                  label="Vorname"
                  placeholder="Vorname"
                  onChange={this.handleChange("firstName")}
                  required
                  error={this.state.errors["firstName"]}
                  ref={el => {
                    this.firstName = el;
                  }}
                />
                <FormInput
                  required
                  label="Nachname"
                  onChange={this.handleChange("lastName")}
                  placeholder="Nachname"
                  error={this.state.errors["lastName"]}
                  ref={el => {
                    this.lastName = el;
                  }}
                />
              </UserInformationRow>
              <UserInformationRow>
                <FormInput
                  label="Straße"
                  placeholder="Straße"
                  onChange={this.handleChange("street")}
                  required
                  error={this.state.errors["street"]}
                  ref={el => {
                    this.street = el;
                  }}
                />
                <FormInput
                  label="Hausnr"
                  onChange={this.handleChange("number")}
                  placeholder="Hausnr"
                  required
                  error={this.state.errors["number"]}
                  ref={el => {
                    this.number = el;
                  }}
                />
              </UserInformationRow>

              <UserInformationRow>
                <FormInput
                  label="Ort"
                  placeholder="Ort"
                  required
                  onChange={this.handleChange("place")}
                  error={this.state.errors["place"]}
                  ref={el => {
                    this.place = el;
                  }}
                />
                <FormInput
                  required
                  label="PLZ"
                  placeholder="PLZ"
                  onChange={this.handleChange("postCode")}
                  error={this.state.errors["postCode"]}
                  ref={el => {
                    this.postCode = el;
                  }}
                />
              </UserInformationRow>

              <UserInformationRow>
                <FormInput label="Telefonnummer" onChange={this.handleChange("phoneNumber")} placeholder="Telefonnummer" />
                <FormInput label="Mobilfunknummer" placeholder="Mobilfunknummer" onChange={this.handleChange("mobileNumber")} />
              </UserInformationRow>

              {/* <span>{this.state.errors["salutation"]}</span> */}
              <UserInformationRowPass>
                <FormInput
                  type="password"
                  label="Passwort"
                  placeholder="Passwort"
                  onChange={this.handleChange("newPassword")}
                  required
                  error={this.state.errors["newPassword"]}
                  ref={el => {
                    this.newPassword = el;
                  }}
                />
                <FormInput
                  type="password"
                  label="Passwort bestätigen"
                  placeholder="Passwort bestätigen"
                  onChange={this.handleChange("confirmPassword")}
                  required
                  error={this.state.errors["confirmPassword"]}
                  ref={el => {
                    this.confirmPassword = el;
                  }}
                />
              </UserInformationRowPass>
              <UserInformationRow style={{ marginTop: "20px" }}>
                <FormInput
                  name="email"
                  label="E-Mailadresse"
                  placeholder="E-Mailadresse"
                  onChange={this.handleChange("email")}
                  required
                  error={this.state.errors["email"]}
                  ref={el => {
                    this.email = el;
                  }}
                />

                <DatePickerItem>
                  <FormInputLabel>
                    Geburtsdatum<span>*</span>
                  </FormInputLabel>
                  <DatePicker
                    value={this.state.dateOfBirth}
                    onChange={this.handleDateChange}
                    error={this.state.errors["dateOfBirth"]}
                    ref={el => {
                      this.dateOfBirth = el;
                    }}
                  />
                </DatePickerItem>
              </UserInformationRow>

              {/* </Layout> */}
            </PrimaryInfo>
          </UserInformation>
          <DeviatingInvoiceAddress>
            <P type="type-7">Abweichende Rechnungsanschrift</P>
            <Layout displayFlex>
              <P type="type-4_1">Abweichende Rechnungsanschrift</P>
              <CheckBox onClick={this.handleCheckboxChange("isAnotherAddress")} checked={isAnotherAddress} />
            </Layout>
            {isAnotherAddress ? (
              <InvoiceAddressForm>
                <Layout displayFlex spacingTop={30}>
                  <Layout displayFlex flex={1} spacingLeft={20} spacingRight={20} width="100%" column>
                    <FormInput
                      required
                      label="Vorname"
                      placeholder="Vorname"
                      onChange={this.handleChange("billingFirstName")}
                      error={this.state.errors["billingFirstName"]}
                      ref={el => {
                        this.billingFirstName = el;
                      }}
                    />
                    <FormInput
                      required
                      label="Straße"
                      placeholder="Straße"
                      onChange={this.handleChange("billingStreet")}
                      error={this.state.errors["billingStreet"]}
                      ref={el => {
                        this.billingStreet = el;
                      }}
                    />
                    <FormInput
                      required
                      label="Ort"
                      onChange={this.handleChange("billingPlace")}
                      placeholder="Ort"
                      error={this.state.errors["billingPlace"]}
                      ref={el => {
                        this.billingPlace = el;
                      }}
                    />
                  </Layout>
                  <Layout displayFlex flex={1} spacingLeft={20} spacingRight={20} width="100%" column>
                    <FormInput
                      required
                      label="Nachname"
                      placeholder="Nachname"
                      onChange={this.handleChange("billingLastName")}
                      error={this.state.errors["billingLastName"]}
                      ref={el => {
                        this.billingLastName = el;
                      }}
                    />
                    <FormInput
                      required
                      label="Hausnummer"
                      onChange={this.handleChange("billingHouseNumber")}
                      placeholder="Hausnummer"
                      error={this.state.errors["billingHouseNumber"]}
                      ref={el => {
                        this.billingHouseNumber = el;
                      }}
                    />
                    <FormInput
                      required
                      label="PLZ"
                      placeholder="PLZ"
                      onChange={this.handleChange("billingPostCode")}
                      error={this.state.errors["billingPostCode"]}
                      ref={el => {
                        this.billingPostCode = el;
                      }}
                    />
                  </Layout>
                </Layout>
              </InvoiceAddressForm>
            ) : (
              ""
            )}
          </DeviatingInvoiceAddress>
          <LicenseModalSelectionOptions>
            <Layout displayFlex>
              <Layout displayFlex spacingRight={50} flex={1} width="100%" column>
                <P type="type-7">Auswahl Lizenzmodell</P>
                <Radio
                  column
                  options={[
                    <React.Fragment>
                      <P type="type-7">Standard Lizenzmodell</P>
                      <AdditionalLicense>
                        <Layout displayFlex column>
                          <AdminLicenceField>
                            <CheckBox checked={true} />
                            <Label offsetLeft={15}>Administrator Lizenz ( 1 Jahr Laufzeit )</Label>
                          </AdminLicenceField>
                          <PageRow>
                            <CheckBox onClick={this.handleCheckboxChange("userLicence")} checked={userLicence} />
                            <Label>Weitere User Lizenzen ( 1 Jahr Laufzeit ) </Label>
                            <NumberSelectHolder>
                              {userLicence ? (
                                <Select
                                  noAdapter
                                  onChange={this.handleSelected("userCount")}
                                  options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(a => a)}
                                  placeholder="0"
                                />
                              ) : (
                                <div className="disabled">
                                  0 <Icon size={16}>expand_more</Icon>
                                </div>
                              )}
                              <span className="hasError">{this.state.errors["userLicence"]}</span>
                            </NumberSelectHolder>
                          </PageRow>
                          <PageRow>
                            <CheckBox onClick={this.handleCheckboxChange("adminLicence")} checked={adminLicence} />
                            <label>Weitere Admin-Lizenzen ( 1 Jahr Laufzeit )</label>
                            <NumberSelectHolder>
                              {adminLicence ? (
                                <Select
                                  noAdapter
                                  onChange={this.handleSelected("adminCount")}
                                  options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(a => a)}
                                  placeholder="0"
                                />
                              ) : (
                                <div className="disabled">
                                  0 <Icon size={16}>expand_more</Icon>
                                </div>
                              )}
                              <span className="hasError">{this.state.errors["adminLicence"]}</span>
                            </NumberSelectHolder>
                          </PageRow>
                        </Layout>
                      </AdditionalLicense>
                    </React.Fragment>,
                    <React.Fragment>
                      <P type="type-7">Fallbasiertes Lizenzmodell</P>
                      <AdditionalLicense>
                        <Layout displayFlex column>
                          <AdminLicenceField>
                            <CheckBox checked={true} />
                            <Label offsetLeft={15}>Basislizenz ( 1 Jahr Laufzeit )</Label>
                          </AdminLicenceField>
                          <PageRow>
                            <CheckBox onClick={this.handleCheckboxChange("newuserLicence")} checked={newuserLicence} />
                            <label>Optional: Weitere User lizenzen ( 1 Jahr Laufzeit )</label>
                            <NumberSelectHolder>
                              {newuserLicence ? (
                                <Select
                                  noAdapter
                                  onChange={this.handleSelected("newuserCount")}
                                  options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(a => a)}
                                  placeholder="0"
                                />
                              ) : (
                                <div className="disabled">
                                  0 <Icon size={16}>expand_more</Icon>
                                </div>
                              )}
                              <span className="hasError">{this.state.errors["newuserLicence"]}</span>
                            </NumberSelectHolder>
                          </PageRow>
                        </Layout>
                      </AdditionalLicense>
                    </React.Fragment>,
                  ]}
                  selectedIndex={this.state.selectedLicence}
                  onClick={this.changeLicence}
                />

                <Order>
                  <h5>Bestellung</h5>
                  <Layout displayFlex column>
                    <PageRow>
                      <Label>1 x Setup Gebühren (Einmalig)</Label>{" "}
                      <span>
                        <Label>149.00 €</Label>{" "}
                      </span>
                    </PageRow>
                    <PageRow>
                      {isOldPayment ? <Label>1 x Administrator Lizenz (Erforderlich)</Label> : <Label>1 x Basislizenz</Label>}{" "}
                      <span> {isOldPayment ? <Label>1188.00 € </Label> : <Label>300.00 € </Label>}</span>
                    </PageRow>

                    {isOldPayment && (
                      <PageRow>
                        {" "}
                        <Label>{adminLicenceCount} x Admin-Lizenz (Jährlich) </Label>
                        <span>
                          {" "}
                          <Label>{adminLicenceCost.toLocaleString("de-DE", { minimumFractionDigits: 2 })} € </Label>
                        </span>
                      </PageRow>
                    )}
                    <PageRow>
                      <Label>{isOldPayment ? userLicenceCount : newuserCount} x User-Lizenz (Jährlich) </Label>
                      <span>
                        <Label>
                          {isOldPayment
                            ? userLicenceCost.toLocaleString("de-DE", { minimumFractionDigits: 2 })
                            : newuserLicenceCost.toLocaleString("de-DE", { minimumFractionDigits: 2 })}{" "}
                          €{" "}
                        </Label>
                      </span>
                    </PageRow>
                    <LineBreak />
                    {/* <PageRow>- Rabatt-Code <span>XXX € </span></PageRow> */}
                    <div className="spacing" />
                    <PageRow>
                      <Label>{isOldPayment ? "Gesamtbetrag" : "Rechnungsbetrag"} netto</Label>
                      <span>
                        <Label>{totalAmount.toLocaleString("de-DE", { minimumFractionDigits: 2 })} € </Label>
                      </span>
                    </PageRow>
                    <PageRow>
                      <Label> zzgl. 19% gesetzlicher Mehrwertsteuer</Label>

                      <span>
                        {" "}
                        <Label>{((totalAmount * 19) / 100).toLocaleString("de-DE", { minimumFractionDigits: 2 })} € </Label>
                      </span>
                    </PageRow>
                    <PageRow>
                      <Label>{isOldPayment ? "Gesamtbetrag" : "Rechnungsbetrag"} brutto </Label>
                      <span>
                        <Label>{Number(netCost).toLocaleString("de-DE", { minimumFractionDigits: 2 })} € </Label>
                      </span>
                    </PageRow>
                  </Layout>
                </Order>
              </Layout>
              <Layout displayFlex spacingLeft={50} flex={1} width="100%" column>
                <P type="type-7">Auswahl Bezahlmethode</P>
                <PaymentOptions
                  switchPaymentMethod={this.switchPaymentMethod}
                  onChange={this.onInputChage}
                  parentMethod={this.handleSubmit}
                />
              </Layout>
            </Layout>
            <ResponseMessage>
              {" "}
              <div> {this.state.message} </div>{" "}
            </ResponseMessage>
          </LicenseModalSelectionOptions>
        </SignUp>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(UserSignUp);
