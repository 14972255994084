import styled from "styled-components";
import { COLORS, BORDER_RADIUS, FONTWEIGHT } from "./constants";

export const AdministrationOuter = styled.div`
  width: 100%;
  background: #fff;
  > div {
    width: 100%;
    margin: auto;
  }
`;
export const UserFilterOptions = styled.div`
  width: 100%;
  padding: 21px 39px;
  border-bottom: 1px solid rgb(220, 225, 214);
  > div {
    @media only screen and (max-width: 600px) {
      flex-flow: column;
      align-items: center;
      p {
        margin-bottom: 15px;
      }
    }
  }
  p {
    white-space: nowrap;
    color: ${COLORS.GREYISH_BROWN_TWO};
  }
  @media only screen and (max-width: 600px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;
export const FilterRight = styled.div`
  width: 100%;
  text-align: right;
  button {
    background: ${COLORS.TURTLE_GREEN_TWO};
    padding: 7px 24px;
    text-align: center;
    color: ${COLORS.WHITE_FOUR};
    font-size: 0.9rem;
    height: 43px;
    line-height: 3px;
    border-radius: ${BORDER_RADIUS.BR_1};
    cursor: pointer;
    border: 1px solid ${COLORS.TURTLE_GREEN_TWO};
    box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
    i {
      font-size: 19px;
      margin-left: 8px;
      line-height: 0;
      position: relative;
      top: 4px;
    }
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`;
export const TemplatePagination = styled.div`
  padding: 24px 39px;
  text-align: right;
  color: ${COLORS.GREEN_GREY_THREE};
  font-size: 0.87rem;
  width: 100%;
  @media only screen and (max-width: 600px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;
export const AssignedLicenses = styled.div`
  padding: 40px 39px;
  > div {
    @media only screen and (max-width: 800px) {
      display: flex;
      flex-flow: column;
    }
  }
  div {
    border: none;
    padding-top: 0;
  }
  p {
    span {
      display: inline-block;
      width: 105px;
      margin-right: 25px;
      i {
        float: right;
      }
    }
    &:first-child {
      margin-bottom: 30px;
      color: ${COLORS.GREYISH_BROWN_TWO};
    }
    &:last-child {
      margin-top: 15px;
    }
  }
  button {
    margin-top: 60px;
    @media only screen and (max-width: 650px) {
      min-width: 120px;
    }
    @media only screen and (max-width: 490px) {
      min-width: 100px;
      font-size: 13px;
      height: 40px;
      line-height: 15px;
      padding: 5px 0;
      margin-right: 5px;
    }
  }
  > div {
    > div {
      &:last-child {
        text-align: right;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;
export const RoyaltyStatementOptions = styled.div`
  width: 100%;
  padding: 21px 39px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  > div {
    @media only screen and (max-width: 600px) {
      flex-flow: column;
      align-items: start;
      p {
        margin-bottom: 15px;
      }
    }
  }
  p {
    white-space: nowrap;
    color: ${COLORS.GREYISH_BROWN_TWO};
  }
  @media only screen and (max-width: 600px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`;
export const RightOptions = styled.div`
  // width: 100%;
  text-align: left;
  margin-top: 10px;
  p {
    font-weight: normal;
  }
  button {
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`;
export const SelectedBillingPeriod = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between
  margin: 35px 0 20px 0;
  align-items: baseline;
  > p {
    font-weight: 400;
    font-size: 1.01rem;
    margin-right: 2rem;
    line-height: 50px;
  }
  div {
    @media only screen and (max-width: 900px) {
      flex-direction: column;
    }
    > p {
      margin-right: 2rem;
      display: flex;
      > div {
        margin-right: 2rem;
        font-size: 0.93rem;
        @media only screen and (max-width: 600px) {
          font-size: 0.7rem;
        }
      }
      input {
        margin-left: 15px;
      }
    }
  }
  @media only screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
  }
`;
export const TableContent = styled.div`
  width: 100%;
  padding: 34px 0 0 0;
  > p {
    padding: 0 36px 27px 36px;
    font-weight: ${FONTWEIGHT.FW_6};
  }
`;

export const AdministrationInfo = styled.div`
  padding-bottom: 20px;
  display: flex;
  ${props => (props.fullWidth ? "width: 100%;" : "")};
  > div {
    width: 100%;
    > input {
      width: 50%;
    }
    > textarea {
      width: 50%; 
    }
    label {
      width: 15%;
      font-size: 18px;
      &.errorStyleDP {
        max-width: 100%;
        text-align: end;
        width: 100%;
      }
    }
    ${props => (props.maxHeight ? `max-height: ${props.maxHeight}${props.unit || "px"};` : "")};
    position: relative;
    @media only screen and (max-width: 700px) {
        max-height: none;
        margin-bottom: 10px;
        flex-direction: column;
        > input {
          width: 100%;
        }
        > textarea {
          width: 100%; 
        }
       label {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
`;
