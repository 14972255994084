import React, { Component } from "react";
// import _ from "lodash";
import * as Yup from "yup";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";

import { FormBox, LoginButton, PasswordBox } from "../../../styled/auth";
import PasswordIcon from "../../../assets/images/password.png";
import { ResponseMessageForgot } from "../../../styled/layout";
import LoginInput from "../../../components/Form/LoginInput";
import { routes } from "../../../routes/routes";
import { browserHistory } from "../../../routes/routes";
import { Icon } from "../../../styled";

const INITIAL_STATE = {
  inputType: "password",
  inputTypeConfirm: "password",
};

class PasswordResetForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      fields: {},
      errors: {},
    };
  }
  state = {
    otp: "",
    newpassword: "",
    confirmpassword: "",
  };

  handleChange = field => e => {
    this.setState({
      [field]: e.target.value,
      errors: {
        ...this.state.errors,
        [field]: "",
      },
    });
  };

  onInputChage = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    let regExp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-_=+])[a-zA-Z0-9!@#$%^&*-_=+]{12,20}$/;
    const schema = Yup.object().shape({
      otp: Yup.string(requiredError),
      confirmpassword: Yup.string(requiredError),
      newpassword: Yup.string().matches(regExp, {
        // message: "Das Passwort muss im gültigen Format vorliegen",
        message:
          "Das Passwort muss aus mindestens 12-20 Zeichen bestehen und mindestens einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
        path: "newpassword",
      }),
    });

    try {
      schema.validateSync(fields, { abortEarly: false });
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(e => {
          errors[e.path] = e.message;
        });
      }
    }

    formIsValid = schema.isValidSync(fields);

    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (this[key]) {
        this[key].focus();
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit = async () => {
    const {
      store: { resetPassword },
    } = this.props;

    const { otp, confirmpassword, newpassword } = this.state;

    if (!this.handleValidation()) {
      return false;
    }

    const data = {
      token: otp,
      password: confirmpassword,
    };

    if (newpassword !== confirmpassword) {
      this.setState({
        message: (
          <div className="response">
            <i className="material-icons">error</i> Your passwords must be the same!
          </div>
        ),
      });

      return false;
    }

    let responseMessage = await resetPassword.fetch(data);

    if (responseMessage.data.data) {
      browserHistory.replace(routes.LOGIN);
      browserHistory.go();
    } else {
      this.setState({
        message: (
          <div className="response">
            <i className="material-icons">error</i> Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut
          </div>
        ),
      });
    }
  };
  showHide = async () => {
    this.setState({
      inputType: this.state.inputType === "password" ? "text" : "password",
    });
  };
  showHideConfirm = async () => {
    this.setState({
      inputTypeConfirm: this.state.inputTypeConfirm === "password" ? "text" : "password",
    });
  };

  render() {
    return (
      <React.Fragment>
        <FormBox>
          <LoginInput
            type="text"
            placeholder="Einmal-Code"
            name="otp"
            id="otp"
            label="Einmal-Code"
            img={PasswordIcon}
            onChange={this.handleChange("otp")}
            error={this.state.errors["otp"]}
          />
          <PasswordBox>
            <LoginInput
              type={this.state.inputType}
              placeholder="Neues Passwort"
              name="newpassword"
              id="newpassword"
              label="Neues Passwort"
              img={PasswordIcon}
              icon=""
              onChange={this.handleChange("newpassword")}
              error={this.state.errors["newpassword"]}
            />
            <span className="password__show" onClick={this.showHide}>
              <Icon>{this.state.inputType === "password" ? "visibility" : "visibility_off"}</Icon>
            </span>
          </PasswordBox>
          <PasswordBox>
            <LoginInput
              type={this.state.inputTypeConfirm}
              placeholder="Passwort bestätigen"
              name="confirmpassword"
              id="confirmpassword"
              label="Passwort bestätigen"
              img={PasswordIcon}
              icon=""
              onChange={this.handleChange("confirmpassword")}
              error={this.state.errors["confirmpassword"]}
            />
            <span className="password__show" onClick={this.showHideConfirm}>
              <Icon>{this.state.inputTypeConfirm === "password" ? "visibility" : "visibility_off"}</Icon>
            </span>
          </PasswordBox>
          <LoginButton onClick={this.handleSubmit} type="submit">
            Passwort zurücksetzen
          </LoginButton>
        </FormBox>
        <ResponseMessageForgot>
          {" "}
          <div> {this.state.message} </div>{" "}
        </ResponseMessageForgot>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(PasswordResetForm);
