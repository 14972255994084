import React from "react";
import { Layout, P } from "../styled";
class Announcment extends React.Component {
  render() {
    return (
      <Layout displayFlex flex={1} alignCenter justifyCenter height="100%">
        <P>Bitte überprüfen Sie Ihre E-Mail, um das Profil zu bestätigen.</P>
      </Layout>
    );
  }
}
export default Announcment;
