import DecliningRevisions from "./DecliningRevisions";
import { compose } from "recompose";
import withTabsWrapper from "../hoc/Tabs";

import AssetFooter from "../components/AssetList/AssetFooter";
import DecliningDirAssets from "./DecliningDirAssets";
import DecliningDirFooter from "../components/AssetList/DecliningDirFooter";

export default compose(
  withTabsWrapper([
    {
      key: "tab_1",
      pageTitle: "Nachlassverzeichnis",
      title: "Aktuell",
      component: DecliningDirAssets,
      footer: DecliningDirFooter,
    },
    {
      key: "tab_2",
      pageTitle: "Nachlassverzeichnis",
      title: "Vorgängerversionen",
      component: DecliningRevisions,
      footer: DecliningDirFooter,
    },
  ]),
)(() => null);
