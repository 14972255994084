import styled from "styled-components";
import { COLORS, BORDER_RADIUS } from "./constants";

export const SelectContainer = styled.div`
  border: solid 1px transparent;
  width: 100%;
  position: relative;
  flex: 1;
  margin-bottom: 10px;
  ${props => (props.disabled ? `pointer-events: none;` : "")}
  ${props =>
    !props.open &&
    `
    border: solid 1px ${COLORS.WHITE};
    border-radius: ${BORDER_RADIUS.BR_1};
    box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.15);
  `}
`;

export const SelectInnerContainer = styled.div`
  width: 100%;
  padding: 10px 11px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 11px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  cursor: pointer;
  transition: all 0.2s ease;
  ${props =>
    props.colorProps &&
    `
    background-color: ${props.colorProps.background};
    border-radius: 4px;
    color: white;
  `}
  span {
    width: calc(100% - 15px);
    overflow: hidden;
  }
  i {
    position: absolute;
    top: 0px;
    right: 2px;
    height: 28px;
    padding-right: 10px;
    width: 19px;
    line-height: 34px;
    border-radius: 5px;
    ${props =>
    !props.colorProps &&
      `
      background: white;
      box-shadow: -12px 0 5px -7px white;
    `}
    margin-right: 0 !important;
  }
`;

export const SelectText = styled.span`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: 0.1px;
  text-align: left;
  white-space: nowrap;
  transition: all 0.2s ease;
  position: absolute;
  color: ${COLORS.MEDIUM_GREY_TWO};
  ${SelectInnerContainer}:hover & {
    color: #999999;
  }
  ${props =>
    props.colorProps &&
    `
    color: ${props.colorProps.color};
  `}
`;

export const SelectOptionText = styled.span`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: 0.1px;
  text-align: left;
  white-space: nowrap;
  transition: all 0.2s ease;
  position: absolute;
  color: ${COLORS.GREYISH_BROWN_THREE};
  ${SelectInnerContainer}:hover & {
    color: #999999;
  }
  ${props =>
    props.disabled &&
    `
    color: ${COLORS.MEDIUM_GREY_TWO};
  `}
`;

export const SelectOptions = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  border: solid 1px ${COLORS.WHITE};
  border-radius: ${BORDER_RADIUS.BR_1};
  box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
  ${props => (props.overflow ? `overflow: scroll;` : "overflow: hidden;")}
  transform: translateY(-34px);
  opacity: 0;
  pointer-events: none;
  ${props =>
    props.height &&
    `height: 240px;
  `}
  transition: all 0.2s ease;
  ${props =>
    props.open &&
    `
    opacity: 1;
    pointer-events: all;
    transform: translateY(0px);
  `}
  ${props =>
    props.minWidth &&
    `
    min-width: ${props.minWidth}px;
  `}
`;

export const SelectOptionItem = styled.div`
  height: 34px;
  min-height: 34px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: ${COLORS.GREYISH_BROWN_THREE};
  transition: all 0.2s ease;
  cursor: pointer;
  width: ${props => (props.elemWidth || 50) + 30}px;
  ${props =>
    props.minWidth &&
    `
    min-width: ${props.minWidth}px;
  `}
  &:hover {
    background: ${COLORS.LIGHT_GREY};
  }
`;
