import styled from "styled-components";
import { COLORS } from "./constants";

export const LoginWrapper = styled.div`
  width: 100%;
  height: 100vh;
  vertical-align: middle;
  display: flex;
`;
export const LoginFlex = styled.div`
  margin: auto;
  max-width: 1213px;
  width: 100%;
  // @media screen and (max-width: 1250px) {
  //   height: 100%;
  // }
`;
export const LoginContainer = styled.div`
  width: 100%;
  border: solid 1px #e7ebeb;
  box-shadow: 0px 1px 18px 0 rgba(70, 84, 84, 0.14);
  background-color: ${COLORS.OFF_WHITE};
  @media screen and (max-width: 800px) {
    padding-bottom: 70px;
  }
`;

export const FlexBox = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: 800px) {
    flex-flow: column;
  }
`;
export const LoginImg = styled.div`
  text-align: center;
  padding: 70px 45px;
  @media screen and (max-width: 1250px) {
    width: 50%;
    padding: 70px 10px;
  }
  @media screen and (max-width: 800px) {
    width: 92%;
    margin: auto;
    padding: 0px;
    padding-top: 30px;
    img {
      max-width: 93%;
    }
  }
  .mob-only {
    display: none;
    @media screen and (max-width: 800px) {
      display: block;
      img {
        max-width: 100%;
      }
    }
  }
`;

export const LoginBoxRight = styled.div`
  padding: 70px 108px 83px 20px;
  text-align: left;
  width: 50%;
  @media screen and (max-width: 1250px) {
    padding-right: 40px;
  }
  @media screen and (max-width: 800px) {
    padding: 0 10px 10px;
    width: 100%;
    max-width: 70%;
    margin: auto;
    .mob-hide {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    max-width: 80%;
  }
  @media screen and (max-width: 400px) {
    max-width: 100%;
  }
`;
export const LogoImage = styled.div`
  width: 100%;
  display: block;
  text-align: left;
`;
export const LandingImage = styled.img`
  max-width: 100%;
  margin-bottom: 10px;
`;

export const ForgotPassword = styled.div`
  width: 100%;
  padding-top: 30px;
  @media screen and (max-width: 800px) {
    padding-top: 10px;
    text-align: center;
  }
  .listblocks {
    display: inline-block;
    margin-right: 40px;
    @media screen and (max-width: 1250px) {
      margin-right: 20px;
      margin-left: 20px;
    }
    a {
      color: ${COLORS.MEDIUM_GREY};
      font-size: 0.875rem;
      font-weight: 400;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const LoginFooter = styled.div`
  width: 100%;
  margin: 12px 0 0 0;
  padding: 0 12px;
  @media screen and (max-width: 500px) {
    padding: 0;
    display: flex;
    flex-flow: column-reverse;
    text-align: center;
  }
`;

export const FooterLinksOuter = styled.div`
  float: right;
  display: flex;
  @media screen and (max-width: 500px) {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
  }
  .FooterLinks {
    margin-right: 35px;
    line-height: 7px;
    @media screen and (max-width: 500px) {
      margin-right: 10px;
      display: inline-block;
    }
    a {
      font-size: 0.75rem;
      color: ${COLORS.STEEL_GREY};
      font-weight: normal;
      letter-spacing: 0.2px;
      position: relative;
      padding-bottom: 0px;
      line-height: 5px;
      display: inline-block;
      &:after {
        content: "";
        height: 1px;
        width: 0%;
        position: absolute;
        left: 0;
        bottom: -7px;
        background: ${COLORS.NASTY_GREEN};
        transition: all 0.8s ease;
      }
      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const FormBox = styled.div`
  margin-top: 40px;
  width: 100%;
  list-style: none;
  @media screen and (max-width: 800px) {
    margin-top: 40px;
  }
  > div {
    width: 100%;
    margin-bottom: 20px;
    list-style: none;
    box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.18);
    border: solid 1px #dbe0d5;
    background: ${COLORS.OFF_WHITE};
    padding: 12px 25px 12px 69px;
    height: 68px;
    position: relative;
    div.error {
      color: ${COLORS.TOMATO};
      font-size: 0.85rem;
      position: absolute;
      width: 100%;
      text-align: right;
      bottom: -16px;
      right: 0;
    }
    @media screen and (max-width: 800px) {
      text-align: left;
      margin-bottom: 10px;
    }
    &.active {
      background: ${COLORS.LIGHT_GREY_THREE};
      label {
        color: ${COLORS.MEDIUM_GREY};
      }
    }
    i {
      position: absolute;
      left: 0;
      top: 0;
      width: 67px;
      height: 68px;
      text-align: center;
      vertical-align: middle;
      line-height: 68px;
    }
    span {
      position: absolute;
      right: 25px;
      top: 25px;
      cursor: pointer;
    }
    > label {
      display: inline-block;
      width: 100%;
      border: none;
      background: none;
      font-size: 0.81rem;
      font-weight: 300;
      padding-bottom: 6px;
      color: ${COLORS.NASTY_GREEN};
      text-align: left;
      max-width: none;
      padding-right: 0;
      line-height: 15px;
      & + div + div {
        bottom: -18px;
      }
    }
    input {
      width: 100%;
      border: none;
      outline: none;
      font-size: 0.93rem;
      font-weight: 600;
      background: ${COLORS.OFF_WHITE};
      &[type="text"] {
        letter-spacing: 1px;
      }
      @media screen and (max-width: 1250px) {
        padding-right: 45px;
      }
      &[type="password"] {
        letter-spacing: 7px;
        @media screen and (max-width: 1250px) {
          letter-spacing: 0;
        }
      }
    }
    &:focus,
    &:active,
    &:focus-within {
      border-bottom: 3px solid ${COLORS.NASTY_GREEN};
    }
    &:[type=number]::-webkit-inner-spin-button, 
    &:[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      margin: 0;
    }
  }
`;

export const LoginButton = styled.button`
  background: ${COLORS.NASTY_GREEN};
  height: 44px;
  box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.18);
  border: solid 1px #dbe0d5;
  padding: 10px 47px;
  color: ${COLORS.OFF_WHITE};
  line-height: 20px;
  font-size: 1.12rem;
  display: inline-block;
  cursor: pointer;
  margin-top: 30px;
  @media screen and (max-width: 800px) {
    width: 100%;
    height: 51px;
  }
`;

export const Copy = styled.p`
  color: ${COLORS.PINE_THREE};
  font-size: 0.875rem;
  font-family: Sansation;
  font-weight: 300;
  margin: 1px 0;
`;

export const Copyright = styled.p`
  font-size: 0.75rem;
  color: ${COLORS.STEEL_GREY};
  font-weight: normal;
  letter-spacing: 0.2px;
  float: left;
`;
export const LogoutButton = styled.button`
  color: ${COLORS.TOMATO};
  font-size: 13px;
  font-weight: bold;
  margin: 5px 0;
  cursor: pointer;
  color: ${COLORS.TOMATO};
  border: none;
  outline: none;
  background: none;
  i {
    font-size: 16px;
    position: relative;
    top: 4px;
    font-weight: 500;
  }
`;
export const PasswordBox = styled.div`
  position: relative;
  label {
    font-size: 0.81rem;
    font-weight: 300;
    color: ${COLORS.NASTY_GREEN};
  }
  .password__show {
    position: absolute;
    right: 65px;
    top: 0;
    i {
      font-size: 21px;
      color: #999;
    }
  }
`;
