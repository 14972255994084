import React from "react";
import { Layout, P } from "../../styled";
import { LicenseManagementOuter, AssignedLicenses, OrderLicense } from "../../styled/licensemanagement";
import LicenseManagementHeader from "./LicenseManagementHeader";
import DeviatingInvoiceAddress from "./DeviatingInvoiceAddress";
import Table from "../Table";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import ResponseWarning from "../Response";

const INITIAL_STATE = {};

class LicenseManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    const {
      store: { licenseManagement, licenceCount, allLicenseManagement },
    } = this.props;
    licenseManagement.fetch();
    allLicenseManagement.fetch();
    licenceCount.fetch();
  }

  onInputChage = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  handleSubmit = async () => {
    const {
      store: { licenseManagement, allLicenseManagement },
    } = this.props;
    await licenseManagement.fetch();
    await allLicenseManagement.fetch();
    this.handleReset();
  };

  handleReset = () => this.setState(INITIAL_STATE);
  render() {
    const {
      store: { licenseManagement, licenceCount, allLicenseManagement, getUserFromToken },
    } = this.props;
    let licences = licenseManagement;

    if (licenceCount.loading || !licenceCount.data) {
      return null;
    }

    if (getUserFromToken.data && getUserFromToken.data.payment_model === "new") {
      licences = allLicenseManagement;
    }

    return (
      <LicenseManagementOuter>
        <Layout displayFlex alignCenter>
          <LicenseManagementHeader />
        </Layout>
        {licences.constructedTable.rows[0] ? (
          <Layout displayFlex alignCenter>
            <Table hideHeader hideFooter small tableStore={licences} />
          </Layout>
        ) : (
          <ResponseWarning />
        )}
        <AssignedLicenses>
          <Layout displayFlex rowToColumn={700}>
            <P type="type-4">Zugeordnete Lizenzen :</P>
            <P type="type-5">
              Administrator : {licenceCount.data.usedAdminCount} von {licenceCount.data.totalAdminCount}
            </P>
            <P type="type-5">
              User : {licenceCount.data.usedUserCount} von {licenceCount.data.totalUserCount}
            </P>
          </Layout>
        </AssignedLicenses>
        <OrderLicense>Lizenz bestellen</OrderLicense>
        <DeviatingInvoiceAddress onChange={this.onInputChage} parentMethod={this.handleSubmit} />
      </LicenseManagementOuter>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(LicenseManagement);
