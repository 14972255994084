import React, { Component } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

import { Layout, Icon } from "../../styled";
import { Overlay, ModalBox, ModalHeaderBox } from "../../styled/modal";

class Modal extends Component {
  // openModal = () => {
  //   this.props.store.modal.add({
  //     title: "Informationen zur Person",
  //     component: PersonalInfo
  //   });
  // openModal = () => {
  //   this.props.store.modal.add({
  //     title: "Reisekosten erfassen",
  //     component: TravelExpenses
  //   });
  // openModal = () => {
  //   this.props.store.modal.add({
  //     title: "Erfolg",
  //     component: ActionModalSuccess
  //   });
  // openModal = () => {
  //   this.props.store.modal.add({
  //     title: "Neuen User anlegen",
  //     component: CreateNewUser
  //   });
  // openModal = () => {
  //   this.props.store.modal.add({
  //     title: "Neuen Katalogeintrag erstellen",
  //     component: NewCatalogEntry
  //   });

  render() {
    const {
      store: { modal },
    } = this.props;

    const modalProps = (modal.modalData && modal.modalData.props) || {};
    return (
      <React.Fragment>
        <Overlay visible={modal.isVisible}>
          <ModalBox visible={modal.isVisible}>
            <Layout displayFlex column alignCenter yScroll>
              <Icon onClick={this.props.store.modal.remove} className="material-icons">
                clear
              </Icon>
              {modal.modalData && (
                <React.Fragment>
                  <ModalHeaderBox className={modal.modalData.title.length <= 0 ? "inactive" : ""}>{modal.modalData.title}</ModalHeaderBox>
                  <modal.modalData.component {...modalProps} closeModal={this.props.store.modal.remove} />
                </React.Fragment>
              )}
            </Layout>
          </ModalBox>
        </Overlay>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(Modal);
