import styled from "styled-components";
import { COLORS } from "./constants";

export const UserProfileImageContainer = styled.div`
  display: flex;
  // width: 350px;
  margin-right: 100px;
  > div {
    h5 {
      white-space: nowrap;
    }
  }
`;
export const UserProfileText = styled.p`
  font-family: Sansation;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${COLORS.GREYISH_BROWN_TWO};
`;

export const UserProfileImage = styled.img`
  @media only screen and (max-width: 768px) {
    background-color: red;
  }
  width: 69px;
  height: 69px;
  border-radius: 34.5px;
  overflow: hidden;
  margin-right: 29px;
`;

export const MandatenNumberLabel = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.08;
  letter-spacing: 0.1px;
  text-align: left;
  color: ${COLORS.WARM_GREY_TWO};
  display: flex;
  ${props =>
    typeof props.rowToColumn === "number"
      ? `
    flex-direction: column;
    @media all and (max-width: ${props.columnToRow}px)){
      flex-direction: column;
    }
  `
      : ""}
`;

export const MandatenNumber = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${COLORS.GREYISH_BROWN_TWO};
`;

export const MandatenIcon = styled.div`
  margin-right: 17px;
  i {
    font-size: 20px;
    color: ${COLORS.LEAF};
    height: 20px;
    width: 20px;
    margin-top: 5px;
  }
`;
