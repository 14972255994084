import React from "react";
import _ from "lodash";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";

import { Layout } from "../../../styled";
import { Button } from "../../../styled/buttons";
import { ModalContent, ModalFooterOuter } from "../../../styled/modal";
import DatePicker from "../../Form/DatePicker";
import { CreateNewReportOuter, FormFieldItems, ReportContent, ReportModalFooterOuter, FromTo } from "../../../styled/createnewreport";
import CheckBox from "../../Form/CheckBox";
import { ActionMessage } from "../../../styled/actionmessage";
import { DatePickerItem } from "../../../styled/myinformation";
import TextareaInput from "../../Form/Textarea";
import moment from "moment";
import { PrimaryInfo } from "../../../styled/signup";
import { Label } from "../../../styled";
import { InfoContainer } from "../../../styled/layout";

class CreateNewReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      von: "",
      bis: "",
      quartalsreport: false,
      jahresreport: false,
      individual: false,
      offentlich: false,
      intern: false,
      abrechnungsinfo: false,
      berichtsinfo: false,
      report_notes: "",
      showDates: false,
      hasContent: false,
    };
  }

  isEquivalent(a, b) {
    var aProps = Object.getOwnPropertyNames(a);

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.isEquivalent(prevState, this.state)) {
      this.checkIfReportHasContent();
    }
  }

  checkIfReportHasContent = () => {
    const {
      von,
      bis,
      quartalsreport,
      jahresreport,
      individual,
      offentlich,
      intern,
      abrechnungsinfo,
      berichtsinfo,
      report_notes,
    } = this.state;

    if (
      von === "" &&
      bis === "" &&
      !quartalsreport &&
      !individual &&
      !offentlich &&
      !intern &&
      !abrechnungsinfo &&
      !berichtsinfo &&
      !jahresreport &&
      report_notes === ""
    ) {
      this.setState({ hasContent: false });
    } else {
      this.setState({ hasContent: true });
    }
  };

  handleVonChange = e => {
    this.setState({
      von: new Date(e),
    });
  };

  handleBisChange = e => {
    this.setState({
      bis: new Date(e),
    });
  };

  handleChange = e => {
    this.setState({
      report_notes: e.target.value,
    });
  };

  handleCheckbox = name => () => {
    this.setState(state => ({
      [name]: !state[name],
    }));
  };

  handleCheckboxChange = name => () => {
    if (name === "individual") {
      this.setState(state => ({
        individual: !state.individual,
        quartalsreport: false,
        jahresreport: false,
        showDates: !state.showDates,
      }));
    } else if (name === "jahresreport") {
      this.setState(state => ({
        jahresreport: !state.jahresreport,
        quartalsreport: false,
        individual: false,
        showDates: false,
      }));
    } else {
      this.setState(state => ({
        quartalsreport: !state.quartalsreport,
        jahresreport: false,
        individual: false,
        showDates: false,
      }));
    }
  };

  getFirstDate = async (flag, number) => {
    const day = 1;
    const hour = 2;
    const minutes = 0;
    const seconds = 0;

    if (flag) {
      const month = 0;
      const date = new Date(Number(number), month, day, hour - 1, minutes, seconds);
      return date.toISOString();
    } else {
      const year = moment().format("YYYY");
      let month = 0;
      if (number === "01" || number === "02" || number === "03" || number === "04") month = 0;
      else if (number === "05" || number === "06" || number === "07" || number === "08") month = 4;
      else if (number === "09" || number === "10" || number === "11" || number === "12") month = 8;
      const date = new Date(Number(year), month, day, hour, minutes, seconds);
      return date.toISOString();
    }
  };

  getLastDate = async (flag, number) => {
    let day = 31;
    const hour = 2;
    const minutes = 0;
    const seconds = 0;

    if (flag) {
      const month = 11;
      const date = new Date(Number(number), month, day, hour - 1, minutes, seconds);
      return date.toISOString();
    } else {
      const year = moment().format("YYYY");
      let month = 0;
      if (number === "01" || number === "03") {
        month = 3;
        day = 31;
      } else if (number === "04") {
        month = 3;
        day = 30;
      } else if (number === "02") {
        month = 3;
        day = 28;
      } else if (number === "05" || number === "07" || number === "08") {
        month = 7;
        day = 31;
      } else if (number === "06") {
        month = 7;
        day = 30;
      } else if (number === "10" || number === "12") {
        month = 11;
        day = 31;
      } else if (number === "09" || number === "11") {
        month = 11;
        day = 30;
      }
      const date = new Date(Number(year), month, day, hour, minutes, seconds);
      return date.toISOString();
    }
  };

  submit = async () => {
    const {
      quartalsreport,
      jahresreport,
      von,
      bis,
      report_notes,
      offentlich,
      intern,
      abrechnungsinfo,
      berichtsinfo,
      individual,
    } = this.state;
    const {
      store: { client, createReport, getReports },
    } = this.props;
    if (!(quartalsreport || jahresreport || individual)) return null;
    let firstDate = moment().toISOString();
    let lastDate = moment().toISOString();
    if (quartalsreport) {
      firstDate = await this.getFirstDate(false, moment().format("MM"));
      lastDate = await this.getLastDate(false, moment().format("MM"));
    } else if (jahresreport) {
      firstDate = await this.getFirstDate(true, moment().format("YYYY"));
      lastDate = await this.getLastDate(true, moment().format("YYYY"));
    } else {
      firstDate = von === "" ? moment().toISOString() : moment(von.setHours(2)).toISOString();
      lastDate = bis === "" ? moment().toISOString() : moment(bis.setHours(2)).toISOString();
    }
    const data = {
      from: moment(firstDate).toISOString(),
      to: moment(lastDate).toISOString(),
      report_notes: report_notes,
      has_public: offentlich,
      has_report_info: berichtsinfo,
      has_settlement: abrechnungsinfo,
      has_internal: intern,
      report_category: quartalsreport ? "quaterly" : jahresreport ? "yearly" : "individual",
    };

    await createReport.fetch({
      clientId: client.data.client_id,
      ...data,
    });

    await getReports.fetch({
      clientId: client.data.client_id,
    });

    this.props.closeModal();
  };

  render() {
    const {
      quartalsreport,
      jahresreport,
      individual,
      offentlich,
      abrechnungsinfo,
      intern,
      berichtsinfo,
      showDates,
      hasContent,
    } = this.state;

    return (
      <React.Fragment>
        <ModalContent>
          <CreateNewReportOuter>
            <FormFieldItems>
              <Layout column displayFlex alignCenter>
                <Layout displayFlex column fullWidth>
                  <Layout row displayFlex alignCenter offsetBottom={30}>
                    <CheckBox onClick={this.handleCheckboxChange("quartalsreport")} checked={quartalsreport} />
                    <Label spacingLeft={20}>Quartalsreport ( letztes Quartal )</Label>
                  </Layout>
                  <Layout row displayFlex alignCenter offsetBottom={30}>
                    <CheckBox onClick={this.handleCheckboxChange("jahresreport")} checked={jahresreport} />
                    <Label spacingLeft={20}>Jahresreport ( letztes Jahr )</Label>
                  </Layout>
                  <Layout row displayFlex alignCenter offsetBottom={30}>
                    <CheckBox onClick={this.handleCheckboxChange("individual")} checked={individual} />
                    <Label spacingLeft={20}>Individueller Report ( Zeitraum wählen )</Label>
                  </Layout>
                </Layout>
              </Layout>
            </FormFieldItems>
            <Layout displayFlex column>
              {showDates && (
                <FromTo>
                  <Layout row displayFlex alignCenter>
                    <DatePickerItem>
                      <label>von</label>
                      <DatePicker dropdownMode="select" value={this.state.von} onChange={this.handleVonChange} />
                    </DatePickerItem>
                    <DatePickerItem>
                      <label>bis</label>
                      <DatePicker value={this.state.bis} onChange={this.handleBisChange} />
                    </DatePickerItem>
                  </Layout>
                </FromTo>
              )}

              <FormFieldItems>
                <h5>Inhalte für Report wählen</h5>
              </FormFieldItems>

              <FormFieldItems>
                <ReportContent>
                  <Layout row displayFlex>
                    <div className="level1">
                      <Layout row displayFlex alignCenter>
                        <CheckBox onClick={this.handleCheckbox("offentlich")} checked={offentlich} />
                        <Label spacingLeft={20}> Öffentlich </Label>
                      </Layout>
                    </div>
                    <div className="level1">
                      <Layout row displayFlex alignCenter>
                        <CheckBox onClick={this.handleCheckbox("intern")} checked={intern} />
                        <Label spacingLeft={20}> Intern </Label>
                      </Layout>
                    </div>
                  </Layout>
                  <Layout row displayFlex>
                    <div className="level1">
                      <Layout row displayFlex alignCenter>
                        <CheckBox onClick={this.handleCheckbox("abrechnungsinfo")} checked={abrechnungsinfo} />
                        <Label spacingLeft={20}> Abrechnungsinfo </Label>
                      </Layout>
                    </div>
                    <div className="level1">
                      <Layout row displayFlex alignCenter>
                        <CheckBox onClick={this.handleCheckbox("berichtsinfo")} checked={berichtsinfo} />
                        <Label spacingLeft={20}> Berichtsinfo </Label>
                      </Layout>
                    </div>
                  </Layout>
                  <Layout row displayFlex>
                    <div className="level1">
                      <Layout row displayFlex alignCenter>
                        <InfoContainer fullWidth spacingBottom={20} maxHeight={200}>
                          <TextareaInput
                            label="Notizen"
                            placeholder="Notizen"
                            onChange={this.handleChange}
                            ref={el => {
                              this.report_notes = el;
                            }}
                          />
                        </InfoContainer>
                      </Layout>
                    </div>
                  </Layout>
                </ReportContent>
              </FormFieldItems>
              {!hasContent && (
                <ActionMessage message="error">
                  <i className="material-icons">error</i>
                  Bitte wählen Sie den Inhalt für den Report
                </ActionMessage>
              )}
            </Layout>
          </CreateNewReportOuter>
        </ModalContent>
        <ModalFooterOuter>
          <ReportModalFooterOuter>
            <Button onClick={this.submit} color="green">
              Report erstellen
              <i className="material-icons">ballot</i>
            </Button>
          </ReportModalFooterOuter>
        </ModalFooterOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(CreateNewReport);
