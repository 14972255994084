import React, { Component } from "react";
import { P, Layout } from "../../styled";
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
import { NewNotizenOuter } from "../../styled/MandantenDashboard";
import NewNotizenImage from "../../assets/images/new-notizen.png";
import AddNewNotizen from "../Modal/Notizen";

class NewNotizen extends Component {
  handleUpload = () => {
    const {
      store: { modal },
    } = this.props;

    modal.add({
      title: "Neue Notizen hinzufügen",
      component: AddNewNotizen,
    });
  };

  render() {
    return (
      <NewNotizenOuter>
        <P type="type-4">
          <i className="material-icons">file_copy</i>Notizen
        </P>
        <Layout onClick={this.handleUpload}>
          <img src={NewNotizenImage} alt="New Notizen" />
          <P type="type-4">Keine Notizen angelegt</P>
        </Layout>
      </NewNotizenOuter>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(NewNotizen);
