import React, { Component } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { PageColumn } from "../../styled/layout";
import { Layout, P } from "../../styled";
import { AssignedLicenses } from "../../styled/administration";

class AssignedLicense extends Component {
  componentDidMount() {
    const {
      store: { licenceCount },
    } = this.props;
    licenceCount.fetch();
  }

  render() {
    const {
      store: { licenceCount },
    } = this.props;
    if (licenceCount.loading || !licenceCount.data) {
      return null;
    }
    const adminTotal = licenceCount.data.superUserAdminCount + licenceCount.data.totalAdminCount;
    const userTotal = licenceCount.data.superUserUserCount + licenceCount.data.totalUserCount;
    let adminUsed = licenceCount.data.usedAdminCountSuper;
    let userUsed = licenceCount.data.usedUserCountSuper;
    adminUsed += licenceCount.data.totalAdminCount > 0 ? licenceCount.data.usedAdminCount : 0;
    userUsed += licenceCount.data.totalUserCount > 0 ? licenceCount.data.usedUserCount : 0;
    return (
      <AssignedLicenses>
        <Layout displayFlex>
          <PageColumn flex={4} left>
            <P type="type-3">Zugeordnete Lizenzen</P>
            <P type="type-4">
              {" "}
              <span>
                Administrator <i>:</i>
              </span>{" "}
              {adminUsed} von {adminTotal}
            </P>
            <P type="type-4">
              {" "}
              <span>
                User <i>:</i>{" "}
              </span>{" "}
              {userUsed} von {userTotal}
            </P>
          </PageColumn>
          <PageColumn flex={9} right>
            {/* <Abbrechen> Abbrechen </Abbrechen>
            <Speichem> Speichem </Speichem> */}
          </PageColumn>
        </Layout>
      </AssignedLicenses>
    );
  }
}
export default compose(
  inject("store"),
  observer,
)(AssignedLicense);

/*
{licenceCount.data.usedAdminCount + licenceCount.data.usedAdminCountSuper} von{" "}
{licenceCount.data.totalAdminCount + licenceCount.data.superUserAdminCount}


{licenceCount.data.usedUserCount + licenceCount.data.usedUserCountSuper} von{" "}
{licenceCount.data.totalUserCount + licenceCount.data.superUserUserCount}
*/
