import React, { Component } from "react";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

import { Layout, P } from "../../../styled";
import { Button } from "../../../styled/buttons";
import { ModalContent } from "../../../styled/modal";
import { IndividualClientAccessOuter } from "../../../styled/createnewuser";
import { ModalFooterOuter } from "../../../styled/actionmodalconfirmation";
import ResponseWarning from "../../../components/Response";
import CustomTable from "../../../components/Table/ClientAccess";

class IndividualClients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      individualClients: [],
    };
  }

  componentDidMount() {
    const {
      user,
      store: { individualClientAccess },
    } = this.props;
    individualClientAccess.fetch({
      user_id: user.id,
    });
  }

  handleSubmit = async () => {
    const {
      user,
      store: { updateUser, userAdministration, individualClients },
    } = this.props;

    const data = {
      user_id: user.id,
      client_access: "individual",
      individual_clients: this.state.individualClients,
    };

    await updateUser.fetch({
      ...data,
    });
    userAdministration.fetch();
    this.props.closeModal();
    window.location.reload();
  };

  onCheckboxClick = (data, item, access) => {
    if (access) {
      const row = {
        client_id: item.client_id,
        delete: true,
      };

      data.push(row);
    }
    this.setState({ individualClients: data });
  };

  render() {
    const {
      closeModal,
      store: { individualClientAccess },
    } = this.props;

    return (
      <React.Fragment>
        <ModalContent>
          <IndividualClientAccessOuter>
            {individualClientAccess.constructedTable.rows[0] ? (
              <Layout>
                <CustomTable
                  show={10}
                  tableStore={individualClientAccess}
                  onCheckboxClick={this.onCheckboxClick}
                  small
                  data={individualClientAccess.constructedTable}
                />
              </Layout>
            ) : (
              <ResponseWarning />
            )}
          </IndividualClientAccessOuter>
          <ModalFooterOuter floatRight background>
            <Button onClick={closeModal} color="grey">
              Abbrechen
            </Button>
            <Button onClick={this.handleSubmit} color="green">
              Speichern
            </Button>
          </ModalFooterOuter>
        </ModalContent>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(IndividualClients);
