import React from "react";
import { compose } from "recompose";
import { Layout } from "../../styled";
import MandantenUserInformation from "./MandantenUserInformation";
import UpdateStatus from "./CurrentStatus";
import DatesAndTasks from "./DatesAndTasks";
import { PageRow } from "../../styled/layout";

const Mandantenshboard = () => (
  <Layout displayFlex column marginAuto>
    <PageRow>
      <MandantenUserInformation />
    </PageRow>
    <PageRow>
      <UpdateStatus />
    </PageRow>
    {/* <MandantenshboardOuter> */}
    <DatesAndTasks />
    {/* </MandantenshboardOuter> */}
  </Layout>
);

export default compose()(Mandantenshboard);
