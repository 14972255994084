import React, { Component } from "react";
import moment from "moment";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { P, Layout } from "../../styled";
import { TasksBoxOuter, DatePickerOuter } from "../../styled/MandantenDashboard";
import NextAppointment from "./NextAppointment";
import Notizen from "./Notizen";
import NewNotizen from "./NewNotizen";
import Calendar from "react-calendar";
import { COLORS } from "../../styled/constants";

moment.locale("de");

class TasksandDates extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      store: {
        getClientNotes,
        activityHistory,
        clientStore: { selectedClientId },
      },
    } = this.props;

    getClientNotes.fetch({
      clientId: selectedClientId,
    });

    activityHistory.fetch({
      clientId: selectedClientId,
    });
  }

  render() {
    const {
      store: { getClientNotes, activityHistory, client },
    } = this.props;

    const c = client.data || {};

    if (getClientNotes.loading || !getClientNotes.data) {
      return null;
    }

    const year = new Date().getFullYear();
    return (
      <React.Fragment>
        <TasksBoxOuter>
          <P type="type-7">
            {" "}
            <i className="material-icons">date_range</i>
            Termine und Aufgaben
          </P>
          <Layout displayFlex>
            <DatePickerOuter>
              <Calendar
                locale="de-DE"
                className="custom-calendar"
                formatShortWeekday={(locale, date) =>
                  moment(date)
                    .format("dd")
                    .substr(0, 1)
                }
                tileContent={item => {
                  const data = [...(activityHistory.data || []), c];
                  const show = data.find(
                    elem =>
                      (elem.task_open_date && moment(elem.task_open_date).isSame(moment(item.date), "day")) ||
                      (elem.birth_date &&
                        moment(elem.birth_date)
                          .year(year)
                          .isSame(moment(item.date), "day")),
                  );
                  if (show && item.view === "month") {
                    return (
                      <div
                        style={{
                          position: "absolute",
                          top: 35,
                          left: "50%",
                          color: COLORS.TOMATO,
                        }}
                      >
                        *
                      </div>
                    );
                  }
                  return null;
                }}
                value={new Date()}
                next2Label={null}
                prev2Label={null}
              />
            </DatePickerOuter>
            <NextAppointment />
            {getClientNotes.data.length > 0 ? <Notizen data={getClientNotes.data} /> : <NewNotizen />}
          </Layout>
          {/* <ButtonActions /> */}
        </TasksBoxOuter>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(TasksandDates);
