import React, { Component } from "react";
import _ from "lodash";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import * as Yup from "yup";

import { FormBox, LoginButton, PasswordBox } from "../../../styled/auth";
import UsernameIcon from "../../../assets/images/user.png";
import PasswordIcon from "../../../assets/images/password.png";
import { ResponseMessageForgot } from "../../../styled/layout";
import { Icon } from "../../../styled";

const INITIAL_STATE = {
  inputType: "password",
};

const LoginFormInput = props => (
  <div>
    <i>
      <img src={props.img} alt={props.label} />
    </i>
    <span>
      {" "}
      <img src={props.icon} alt="" />{" "}
    </span>
    <label htmlFor="username">{props.label}</label>
    <input {...props} />
    <div className="error">{props.error}</div>
  </div>
);

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      fields: {},
      errors: {},
    };
  }
  state = {
    email: "",
    password: "",
  };

  handleChange = field => e => {
    this.setState({
      [field]: e.target.value,
      errors: { ...this.state.errors, [field]: "" },
    });
  };

  onInputChage = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  handleValidation() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    let requiredError = "Das Pflichtfeld darf nicht leer sein!";
    const schema = Yup.object().shape({
      email: Yup.string()
        .email("E-Mail ist nicht gültig!")
        .required(requiredError),
      password: Yup.string().required(requiredError),
    });

    try {
      schema.validateSync(fields, { abortEarly: false });
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(e => {
          errors[e.path] = e.message;
        });
      }
    }

    formIsValid = schema.isValidSync(fields);

    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (this[key]) {
        this[key].focus();
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit = async () => {
    const values = _.pick(this.state, ["email", "password"]);

    if (!this.handleValidation()) {
      return false;
    }

    let responseMessage = await this.props.store.user.doLogin(values);

    if (responseMessage === "user") {
      this.setState({
        message: (
          <div className="response">
            <i className="material-icons">error</i> Bitte überprüfen Sie Ihren Benutzernamen
          </div>
        ),
      });
    }

    if (responseMessage === "password") {
      this.setState({
        message: (
          <div className="response">
            <i className="material-icons">error</i> Bitte überprüfen Sie Ihr Passwort
          </div>
        ),
      });
    }

    if (responseMessage === "deactivated") {
      this.setState({
        message: (
          <div className="response">
            <i className="material-icons">error</i> Der Benutzer wurde deaktiviert!
          </div>
        ),
      });
    }

    if (!responseMessage) {
      this.setState({
        message: (
          <div className="response">
            <i className="material-icons">error</i> Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut
          </div>
        ),
      });
    }
    // setTimeout(
    //   function() {
    //     this.setState({ message: "" });
    //   }.bind(this),
    //   5000
    // );
  };

  showHide = async () => {
    this.setState({
      inputType: this.state.inputType === "password" ? "text" : "password",
    });
  };

  render() {
    const { email, password } = this.state;
    return (
      <React.Fragment>
        <FormBox>
          <LoginFormInput
            name="email"
            placeholder="Email"
            id="email"
            type="text"
            label="Benutzername"
            img={UsernameIcon}
            value={email}
            onChange={this.handleChange("email")}
            error={this.state.errors["email"]}
          />
          <PasswordBox>
            <LoginFormInput
              type={this.state.inputType}
              placeholder="Passwort"
              name="password"
              id="password"
              label="Passwort"
              img={PasswordIcon}
              // icon={PasswordView}
              value={password}
              // onChange={this.handleChange}
              onChange={this.handleChange("password")}
              error={this.state.errors["password"]}
            />
            <span className="password__show" onClick={this.showHide}>
              <Icon>{this.state.inputType === "password" ? "visibility" : "visibility_off"}</Icon>
            </span>
          </PasswordBox>
          <LoginButton onClick={this.handleSubmit} type="submit">
            Login
          </LoginButton>
        </FormBox>
        <ResponseMessageForgot>
          {" "}
          <div> {this.state.message} </div>{" "}
        </ResponseMessageForgot>
      </React.Fragment>
    );
  }
}

export default compose(
  inject("store"),
  observer,
)(LoginForm);
