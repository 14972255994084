import styled from "styled-components";
import { COLORS } from "./constants";

export const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-all;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px ${COLORS.LIGHT_GREY_SIX};
  background-color: ${COLORS.LIGHT_GREY};
  margin-bottom: 60px;
  overflow: hidden;
  &:last-child {
    background: white;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 53px;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: 0.2px;
  text-align: left;
  color: ${props => (props.selected ? `white` : COLORS.DARK_SAGE_THREE)};
  cursor: pointer;
  padding: 0 4%;
  transition: all 0.3s ease;
  ${props => !props.lastElement && `border-right: 1px solid ${COLORS.LIGHT_GREY_SIX};`}

  ${props =>
    props.selected &&
    `
    background: #a8bc7c;
    background: -moz-linear-gradient(top, #a8bc7c 0%, #6da02e 100%);
    background: -webkit-linear-gradient(top, #a8bc7c 0%,#6da02e 100%);
    background: linear-gradient(to bottom, #a8bc7c 0%,#6da02e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a8bc7c', endColorstr='#6da02e',GradientType=0 );
    `}
    &:hover {
    background: #a8bc7c;
    background: -moz-linear-gradient(top, #a8bc7c 0%, #6da02e 100%);
    background: -webkit-linear-gradient(top, #a8bc7c 0%, #6da02e 100%);
    background: linear-gradient(to bottom, #a8bc7c 0%, #6da02e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a8bc7c', endColorstr='#6da02e',GradientType=0 );
    color: white;
  }
`;

export const CheckBoxContainer = styled.div`
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${COLORS.GREEN_GREY};
  margin-right: 15px;
  ${props =>
    props.checked &&
    `
    box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.11);
    border: solid 2px white;
    background: white;
  `}

  i {
    font-size: 17px;
  }
`;

export const EditorInputContainer = styled.div`
  width: 100%;
  border: 1px solid white;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.09);

  .align-right div {
    text-align: right;
  }
  .align-center div {
    text-align: center;
  }
  .align-left div {
    text-align: left;
  }
  .align-justify div {
    text-align: justify;
  }

  ul.public-DraftStyleDefault-ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1 em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
    li {
      display: list-item;
    }
  }
  ol.public-DraftStyleDefault-ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
    li {
      display: list-item;
    }
  }
`;

export const EditorInput = styled.div`
  position: relative;
  .public-DraftEditorPlaceholder-inner {
    position: absolute;
    color: #aaaaaa;
    pointer-events: none;
  }
  div.DraftEditor-root {
    background-color: white;
    border: 2px solid ${COLORS.GREENISH_GREY_TWO};
    width: 100%;
    min-height: 300px;
    padding: 30px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: 0.1px;
    color: ${COLORS.GREEN_GREY_TWO};
  }
`;

export const EditorFormatBlock = styled.div`
  padding: 14px 23px 10px 23px;
  border-right: 1px solid ${COLORS.LIGHT_GREY_SIX};
  i {
    color: #79806f;
    font-size: 20px;
    height: 20px;
    margin-right: 16px;
    cursor: pointer;
    transition: all 0.1s ease;
    &:hover {
      opacity: 0.5;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ActivityEditorContainer = styled.div`
  display: flex;
  // @media only screen and (min-width: 1400px) and (max-width: 1800px) {
  //   width: 90%;
  // }
`;
