import React, { Component } from "react";
import { LineBreak, Sidemenugroup } from "../../../../styled/sidebar";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import { ToastsContainer, ToastsStore } from "react-toasts";
import ReactTooltip from "react-tooltip";

const checkRoute = route => {
  const { pathname } = window.location;

  return route === pathname ? "activeRoute" : "";
};

const NAVIGATION_MENU = [
  {
    icon: "line_style",
    text: "Mandant",
    linkTo: "/mandanten",
    key: "Mandant",
  },
  {
    icon: "library_books",
    text: "Aktivitäten",
    linkTo: "/activities",
    key: "Aktivitäten",
  },
  {
    icon: "business",
    text: "Vermögen",
    linkTo: "/assets",
    key: "Vermögen",
  },
  {
    icon: "collections",
    text: "Nachlassverzeichnis",
    linkTo: "/nachlassverzeichnis",
    key: "Nachlassverzeichnis",
  },
  {
    icon: "list_alt",
    text: "Dokumente",
    linkTo: "/dokumente",
    key: "Dokumente",
  },
];

class NavMenu extends Component {
  unlisten = null;
  componentDidMount() {
    const {
      store: { declining, clientStore },
    } = this.props;
    this.unlisten = this.props.history.listen(() => this.forceUpdate());
    declining.fetch({
      clientId: clientStore.selectedClientId,
    });
  }
  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }
  handleCloseMenu = () => {
    if (window.innerWidth <= 1440) {
      const { store } = this.props;
      store.closeSidebar();
      this.setState({ open: false });
    }
  };

  render() {
    const {
      store: {
        user,
        clientStore: { selectedClientId },
        declining: { beneficiaryAmounts, getBeneficiaryAmount },
      },
    } = this.props;
    const isDisabled = !selectedClientId;
    return (
      <div>
        <ToastsContainer lightBackground store={ToastsStore} />
        <LineBreak />
        <Sidemenugroup onClick={this.handleCloseMenu} disabled={isDisabled}>
          {NAVIGATION_MENU.map(item => (
            <p data-tip={item.text}>
              <Link
                style={{
                  color: isDisabled || (item.key === "Nachlassverzeichnis" && !beneficiaryAmounts) ? "rgb(160,160,160)" : "rgb(87,100,72)",
                }}
                key={item.key}
                className={isDisabled || (item.key === "Nachlassverzeichnis" && !beneficiaryAmounts) ? "" : checkRoute(item.linkTo)}
                to={isDisabled || (item.key === "Nachlassverzeichnis" && !beneficiaryAmounts) ? "#" : item.linkTo}
                onClick={() => {
                  if (isDisabled) {
                    // this.props.store.notificationsStore.addNotification("Aktuell liegen zu dieser Ansicht keine Informationen vor");
                    // ToastsStore.error("Aktuell liegen zu dieser Ansicht keine Informationen vor");
                    ToastsStore.error("Bitte wählen Sie einen Mandanten");
                  }
                }}
              >
                <i className="material-icons-outlined">{item.icon}</i>
                <span>{item.text}</span>
              </Link>
            </p>
          ))}
        </Sidemenugroup>
        <LineBreak />
        <Sidemenugroup onClick={this.handleCloseMenu}>
          <p data-tip="Administration">
            <Link
              to={user.currentUser.licence_type !== "admin" ? "#" : "/administration"}
              className={user.currentUser.licence_type !== "admin" ? "" : checkRoute("/administration")}
            >
              <i className="material-icons-outlined">next_week</i>
              <span>Administration</span>
            </Link>
          </p>
        </Sidemenugroup>
      </div>
    );
  }
}
export default compose(
  withRouter,
  inject("store"),
  observer,
)(NavMenu);
