import moment from "moment";
import PersonalBriefInfo from "../../components/Modal/PersonalInfo/personBriefInfo";

export const personListingOrderAdapter = {
  last_name: {
    key: "row_1",
    order: 0,
    scale: 2,
    title: "Nachname",
  },
  first_name: {
    key: "row_2",
    order: 1,
    scale: 2,
    title: "Vorname",
  },
  category: {
    key: "row_3",
    order: 2,
    scale: 1.5,
    title: "Kategorie",
  },
  is_beneficiary: {
    key: "row_4",
    order: 3,
    scale: 1,
    title: "Beg.",
  },
  postcode: {
    key: "row_5",
    order: 4,
    scale: 1,
    title: "PLZ",
  },
  place: {
    key: "row_6",
    order: 5,
    scale: 1.65,
    title: "Ort",
  },
  street_and_number: {
    key: "row_7",
    order: 6,
    scale: 2,
    title: "Straße",
  },
  birth_date: {
    key: "row_8",
    order: 7,
    scale: 1,
    title: "Geburtsdatum",
  },
  phone_number: {
    key: "row_9",
    order: 8,
    scale: 1.5,
    title: "Telefon",
  },
  breif_info: {
    key: "row_10",
    order: 9,
    scale: 1.5,
    title: "Kurz.",
  },
  person_id: {
    key: "id",
    order: 10,
    scale: 0,
    title: "ID",
    hide: true,
  },
};

export const personListingHeaderAdapter = order => {
  const sortedHeader = [];

  Object.keys(order).forEach(key => {
    if (order[key].hide) return;
    const item = order[key];
    sortedHeader[item.order] = item;
  });

  return sortedHeader;
};

const getBriefInfo = async (event, store, index) => {
  const { modal, clientStore, personsListing } = store;
  const person = personsListing.data.find(person => person.personId === index);
  clientStore.personBriefInfo = person.breif_info;
  modal.add({
    title: "Person Kurzinfo",
    component: PersonalBriefInfo,
  });
};

const onClientSelect = async (event, store, client_id) => {
  const { clientStore, updateUser, user, declining } = store;
  const clientId = client_id;
  clientStore.setSelectedClientId(clientId);
  declining.fetch({
    clientId: clientStore.selectedClientId,
  });
  await updateUser.fetch({
    id: user.currentUser.id,
    selected_client: clientId,
  });
};

const changeBeneficiary = async (event, store, index) => {
  const {
    personsListing,
    updatePerson,
    clientStore: { selectedClientId },
  } = store;
  const person = personsListing.data[index];

  const data = {
    is_beneficiary: !person.is_beneficiary,
  };

  await updatePerson.fetch({
    personId: person.person_id,
    ...data,
  });
  await personsListing.fetch({
    clientId: selectedClientId,
  });
};

export const personListingRowAdapter = (data, order) => {
  const rows = {};

  if (data) {
    data.forEach((item, index) => {
      const row = [];
      Object.keys(item).forEach((itemKey, itemIndex) => {
        const orderItem = order[itemKey];
        if (orderItem) {
          switch (itemKey) {
          case "id":
            break;
          case "breif_info":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "icon",
              active: "info",
              action: (store, index) => e => getBriefInfo(e, store, item.person_id),
              data: item[itemKey],
            };
            break;
          case "is_beneficiary":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "checkbox",
              action: (store, index) => e => changeBeneficiary(e, store, item.person_id),
              checked: item[itemKey],
            };
            break;
          case "birth_date":
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "test",
              data: moment(item[itemKey]).format("DD.MM.YYYY") === "Invalid date" ? "" : moment(item[itemKey]).format("DD.MM.YYYY"),
            };
            break;
          case "person_id": {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "id",
              data: item[itemKey],
            };
            break;
          }
          default: {
            row[orderItem.order] = {
              key: `foo_${itemIndex}`,
              type: "text",
              data: item[itemKey],
            };
            break;
          }
          }
        }
      });
      rows[index] = row;
    });
  }
  return rows;
};
